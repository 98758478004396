import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {Button, Modal, ModalBody, ModalFooter, Table} from "reactstrap";

import {useAccount, useMsal} from "@azure/msal-react";

const PublishOverrideModal = ({
  publishOverrideModal,
  setPublishOverrideModal,
  publishOverrideDetails,
  onPublish,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  return (
    <Modal
      size="md"
      isOpen={publishOverrideModal}
      className="modal-dialog-centered"
    >
      <ModalBody className="text-center">
        <p className="mb-[2px]"></p>
        <p className=" mb-0">
          Publishing this version will override existing rules below
        </p>
        <div className="mt-4">
          <Table responsive size="sm" borderless>
            <tbody>
              {publishOverrideDetails?.map((version, index) => (
                <tr key={index}>
                  <td>{`Version - ${version?.version}`}</td>
                  <td>{version?.validFrom}</td>
                  <td>{version?.remarks}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
              onClick={() => {
                setPublishOverrideModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
              onClick={() => {
                onPublish(true);
                setPublishOverrideModal(false);
              }}
              onKeyDown={() => {
                onPublish(true);
                setPublishOverrideModal(false);
              }}
            >
              Override and publish
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

PublishOverrideModal.propTypes = {
  publishOverrideModal: PropTypes.bool,
  publishOverrideDetails: PropTypes.array,

  setPublishOverrideModal: PropTypes.func,
  onPublish: PropTypes.func,
};

export default PublishOverrideModal;
