import React from "react";
import {posDocTypes} from "content/navigations";
import {GetInTouch, SectionType} from "modules/common";
import Title from "modules/common/Title";
import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router";
import Tabs from "./Tabs";

const PoSManagement = () => {
  const {country} = useParams();
  return (
    <>
      <Helmet>
        <title>Upload documents - PoS</title>
      </Helmet>
      <Title title="Proof of Sustainability (PoS)" />
      <div className="px-6 py-8 lg:px-10">
        <div className="mb-4">
          <Tabs country={country} />
        </div>
        <SectionType
          items={posDocTypes.map((item) => {
            return {...item, link: `${item.link}/${country}`};
          })}
        />
        <GetInTouch />
      </div>
    </>
  );
};

export default PoSManagement;
