import PropTypes from "prop-types";
import {useMemo, useEffect} from "react";
import {useQuery} from "@apollo/client";
import {useFormContext, useWatch} from "react-hook-form";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {GET_SPLIT_BATCH_IDS} from "graphql/coprocessing/batches";
import PIData from "../../Shared/PIData";
import extractBatchIdDetails from "../extractBatchIdDetails";
import {DHDSDateTime, Form} from "../FormElements";

const {keys, labels: fieldLabels} = ADD_BATCH_FORM;

export const SplitBatchFields = ({tankNumber, isSplit = false}) => {
  const {control, setValue} = useFormContext();

  const {loading, data} = useQuery(GET_SPLIT_BATCH_IDS, {
    variables: {tankNumber},
    fetchPolicy: "network-only",
    skip: !isSplit || !tankNumber,
  });

  const splitIdOptions = useMemo(
    () =>
      data?.bioLcCoproUsBatchesApi?.body?.split_batch_ids?.map(
        ({next_split, batch_id, tank_certified_datez}) => ({
          value: next_split,
          label: batch_id,
          tank_certified_datez,
        }),
      ) ?? [],
    [data],
  );

  const nextSplitId = useWatch({control, name: keys.batchId});
  const {batchCode, suffix} = extractBatchIdDetails(nextSplitId);

  useEffect(() => {
    const foundItem = splitIdOptions.find(
      (option) => option.value === nextSplitId,
    );

    setValue(keys.batchCode, batchCode);
    setValue(keys.tankCertifiedDate, foundItem?.tank_certified_datez);
  }, [batchCode, setValue, splitIdOptions, nextSplitId]);

  return (
    <div className="row g-6 mb-4">
      <Form.Select
        isLoading={loading}
        label={fieldLabels.ogBatchId}
        options={splitIdOptions}
        name={keys.batchId}
      />
      <Form.Field label={`Split ${suffix} batch ID`}>
        {/* Display currently set split batch ID */}
        <p>{nextSplitId}</p>
      </Form.Field>
    </div>
  );
};

const StepThree = () => {
  const {getValues} = useFormContext();

  const [isSplit, tankNo, operatingCondition] = getValues([
    keys.splitBatch,
    keys.tankNo,
    keys.opCondition,
  ]);

  if (isSplit) {
    return <SplitBatchFields isSplit={isSplit} tankNumber={tankNo} />;
  }

  return (
    <div className="row g-6 mb-4">
      {operatingCondition === OP_CONDITIONS.BY_PASSED ? (
        <DHDSDateTime
          fieldLabels={{
            [keys.batchStartTime]: "Start time",
            [keys.batchEndTime]: "End time",
          }}
        />
      ) : (
        <DHDSDateTime />
      )}
      <PIData />
    </div>
  );
};

export default StepThree;

SplitBatchFields.propTypes = {
  isSplit: PropTypes.bool,
  tankNumber: PropTypes.string,
};
