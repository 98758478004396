import PropTypes from "prop-types";
import {ShipmentLabel, ShipmentField} from "./ShipmentFieldElements";

const ShipmentFieldWrapper = ({index, shipment, field, fieldFunc}) => {
  const modifiedField = {...field, errorKey: `${field.key}_${index}`};
  return (
    <div key={field.label} className="min-w-[50%] px-3 py-1">
      <ShipmentField key={field.label} data-test="copro-shipment-fields">
        <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
          {field.label}
        </ShipmentLabel>
        {fieldFunc(shipment, modifiedField, index)}
      </ShipmentField>
    </div>
  );
};

ShipmentFieldWrapper.propTypes = {
  index: PropTypes.number,
  shipment: PropTypes.object,
  field: PropTypes.object,
  fieldFunc: PropTypes.func,
};

export default ShipmentFieldWrapper;
