import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, Input, Label, FormGroup} from "reactstrap";

const RadioButton = ({
  control,
  name,
  error,
  rules,
  type = "checkbox",
  disabled = false,
  yesLabel,
  noLabel,
}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const yes = {checked: value === true};
        const no = {checked: value === false};
        return (
          <>
            <div>
              <FormGroup
                check
                className="form-check-alt form-check-md !mb-0 py-3 !items-start !flex flex-row gap-x-3"
              >
                <Input
                  innerRef={ref}
                  id={`${name}-${yesLabel}`}
                  type={type}
                  data-test={`${name}-${yesLabel}`}
                  invalid={!!error}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.checked)}
                  {...yes}
                />
                <Label check for={`${name}-${yesLabel}`}>
                  {yesLabel}
                </Label>
              </FormGroup>
              <FormGroup
                check
                className="form-check-alt form-check-md !mb-0 py-3 text-left"
              >
                <Input
                  id={`${name}-${noLabel}`}
                  data-test={`${name}-${noLabel}`}
                  type={type}
                  invalid={!!error}
                  disabled={disabled}
                  onChange={(e) => onChange(!e.target.checked)}
                  {...no}
                />
                <Label check for={`${name}-${noLabel}`}>
                  {noLabel}
                </Label>
              </FormGroup>
            </div>
            {error && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

RadioButton.propTypes = {
  type: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
};
export default RadioButton;
