import React, {useState, useEffect} from "react";
import tw, {styled} from "twin.macro";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import powerBICommonMethods from "utils/helpers/powerBICommonMethods";
import DashboardLayout from "modules/common/DashboardLayout";

const BVCReport = () => {
  const [accessTokenPowerBI, setAccessToken] = useState();

  useEffect(() => {
    async function fetchPowerBIAccessToken() {
      const accessTokenPBI = await powerBICommonMethods.getPowerBIAPIToken();
      setAccessToken(accessTokenPBI);
    }
    fetchPowerBIAccessToken();
  }, []);

  const headerbreadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: "Reporting",
      link: "/product-metrics",
    },
  ];

  return (
    <DashboardLayout pageTitle="BVC Report" breadcrumbs={headerbreadcrumbItems}>
      <PageLayout data-test="power-bi-base-layout">
        <ReportContainer>
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              id: "17a3d44c-6179-434c-9a65-5c7eaa8a36cb",
              embedUrl:
                "https://app.powerbi.com/reportEmbed?reportId=17a3d44c-6179-434c-9a65-5c7eaa8a36cb",
              accessToken: accessTokenPowerBI,
              tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
              settings: {
                panes: {
                  pageNavigation: {
                    position: models.PageNavigationPosition.Left,
                  },
                  filters: {
                    expanded: false,
                  },
                },
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },

                background: models.BackgroundType.Transparent,
              },
              width: "100%",
              height: "100vh",
            }}
            cssClassName="h-screen"
          />
        </ReportContainer>
      </PageLayout>
    </DashboardLayout>
  );
};

export default BVCReport;

const ReportContainer = styled.div`
  height: 100vh;
  maxheight: 100vmin;
  position: relative;
`;

const PageLayout = tw.div`
  flex flex-col gap-2 pb-10 
`;
