import React, {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import SAF_INCOMING_AND_OUTGOING_COUNT from "graphql/saf/GetDocumentsCount";
import {useQuery} from "@apollo/client";

import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {useAppSetting} from "providers/appSetting";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import Layout from "../components/Layout";
import SafTabs from "../components/SafTabs";
import IncomingDocumentComponent from "../components/incoming-documents/IncomingDocumentComponent";
import OutgoingDocuments from "../components/OutgoingDocuments";
import {saf_urls} from "../constants/common";
import generateBreadcrumbItems from "../utils";

const SAFDocumentPage = () => {
  const {appSetting} = useAppSetting();

  const {country} = useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_SAF);

  const [searchParams] = useSearchParams();
  const tab = searchParams.get("activeTab") ?? "1";
  const [activeTab, setActiveTab] = useState(tab);

  const {
    data,
    loading,
    refetch: refetchCount,
  } = useQuery(SAF_INCOMING_AND_OUTGOING_COUNT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const optionalItems = [
    {text: "Document manager", link: saf_urls[`${country}DocumentPage`]},
    {
      text: activeTab === "1" ? "Incoming documents" : "Outgoing documents",
      link: saf_urls[`${country}DocumentPage`],
    },
  ];

  const breadcrumbItems = generateBreadcrumbItems(
    country,
    saf_urls,
    optionalItems,
  );

  return (
    <Layout title="Documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full my-3">
          <SafTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            incomingDocCount={data?.bioLcGetInOutDocsCount?.inboundCount}
            outgoingDocCount={data?.bioLcGetInOutDocsCount?.outboundCount}
            loading={
              loading ||
              siteReferenceData?.siteReferenceId === undefined ||
              divisionData?.divisionId === undefined
            }
          />
        </div>
      </div>
      {activeTab === "1" && (
        <IncomingDocumentComponent refetchCount={refetchCount} />
      )}
      {activeTab === "2" && <OutgoingDocuments />}
    </Layout>
  );
};

export default SAFDocumentPage;
