import PropTypes from "prop-types";
import {uniqBy} from "lodash";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";
import RadioFlag from "modules/common/BinaryFlag";

const AdditionalInfo = ({mbLocations, status, transportEmissions}) => {
  const {
    register,
    formState: {errors},
    watch,
    control,
  } = useFormContext();
  const seperationText = "#-#";
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const emissionForTransport = watch("emissionForTransport");
  const isCompleted = status === "completed";
  const isAddAdditionalInfo = status === "additional_info";

  const uniqueLocations = uniqBy(mbLocations ?? [], "location_code");

  const uniqueTransportEmissions = uniqBy(transportEmissions ?? [], (v) =>
    [v.sending_location_code, v.receiving_location_code, v.mot_name].join(),
  );

  const uniqueLocationCodes = [
    ...new Set(mbLocations.map((obj) => obj.location_code)),
  ];

  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="emissionForTransport" className="fw-normal">
          Do you need to add emissions for transport?
        </Label>
        <RadioFlag
          type="radio"
          control={control}
          name="emissionForTransport"
          error={errors.emissionForTransport}
          disabled={isCompleted || isAddAdditionalInfo}
        />
      </FormGroup>
      {emissionForTransport && (
        <FormGroup>
          <Label for="transportationEmissionRoute" className="fw-normal">
            Transportation route and type
          </Label>
          <Input
            type="select"
            id="transportationEmissionRoute"
            data-test="transportationEmissionRoute"
            {...computeProps("transportationEmissionRoute", {
              required: "Please select transportation type and route",
            })}
            invalid={!!errors.transportationEmissionRoute}
            disabled={isCompleted || isAddAdditionalInfo}
          >
            {uniqueTransportEmissions?.map((transportEmission) => {
              if (
                uniqueLocationCodes?.includes(
                  transportEmission?.receiving_location_code,
                )
              ) {
                return (
                  <option
                    key={transportEmission.transportation_emission_id}
                    value={`${transportEmission?.sending_location_code}${seperationText}${transportEmission?.receiving_location_code}${seperationText}${transportEmission?.mot_name}`}
                  >
                    {transportEmission.sending_location_name} -{" "}
                    {transportEmission.receiving_location_name} (
                    {transportEmission.mot_name})
                  </option>
                );
              }
              return null;
            })}
          </Input>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="mbLocation" className="fw-normal">
          Mass balance
        </Label>
        <Input
          type="select"
          id="mbLocation"
          data-test="mbLocation"
          {...computeProps("mbLocation", {
            required: "Please select an option",
          })}
          invalid={!!errors.mbLocation}
          disabled={isCompleted || isAddAdditionalInfo}
        >
          <option value="">Select an option</option>
          {uniqueLocations?.map((mbLocation) => (
            <option
              key={mbLocation.location_id}
              value={mbLocation.location_code}
            >
              {mbLocation.location_name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="invoiceProviderName" className="fw-normal">
          Invoice number
        </Label>
        <Input
          type="text"
          id="invoiceProviderName"
          data-test="invoiceProviderName"
          {...computeProps("invoiceProviderName")}
          invalid={!!errors.invoiceProviderName}
          disabled={isCompleted}
          maxLength={100}
        />
      </FormGroup>
      <FormGroup>
        <Label for="vesselAtHuelva" className="fw-normal">
          Vessel name
        </Label>
        <Input
          type="text"
          id="vesselAtHuelva"
          data-test="vesselAtHuelva"
          {...computeProps("vesselAtHuelva")}
          invalid={!!errors.vesselAtHuelva}
          disabled={isCompleted}
          maxLength={50}
        />
      </FormGroup>
      <FormGroup>
        <Label for="notes" className="fw-normal">
          Notes
        </Label>
        <Input
          type="textarea"
          rows="4"
          id="observationNotes"
          data-test="observationNotes"
          {...computeProps("observationNotes")}
          invalid={!!errors.observationNotes}
          disabled={isCompleted}
          maxLength={250}
        />
        {errors.observationNotes && (
          <FormFeedback>{errors.observationNotes.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

AdditionalInfo.propTypes = {
  mbLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  transportEmissions: PropTypes.arrayOf(
    PropTypes.shape({
      transportation_emission_id: PropTypes.number,
      sending_location_name: PropTypes.string,
      receiving_location_name: PropTypes.string,
      receiving_location_code: PropTypes.string,
      mot_name: PropTypes.string,
    }),
  ),
};
export default AdditionalInfo;
