import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import PropTypes from "prop-types";
import {useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import DropdownCustomizeItem from "./DropdownCustomizeItem";
import Droppable from "./Droppable";

const CustomizeTable = ({columns, onColumnChange}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleColumnChange = (column) => {
    const updatedCols = columns.map((c) => {
      return {...c, visible: !column.visible};
    });

    onColumnChange(
      updatedCols.map((c) => (c.header === column.header ? column : c)),
      column,
    );
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (active.id === over.id) {
      return;
    }
    const reorderedColumns = [...columns];
    const activeIndex = reorderedColumns.findIndex(
      (c) => c.header === active.id,
    );
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.header === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);
    onColumnChange(reorderedColumns);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="tertiary" className="rounded-0 ms-0 border" caret>
        Customize columns
        <Down24 className="btn-icon-suffix" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu">
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          {columns?.map(
            (column) =>
              column.header !== "" && (
                <Droppable key={column.header} id={column.header}>
                  <DropdownCustomizeItem
                    column={column}
                    onChange={handleColumnChange}
                  />
                </Droppable>
              ),
          )}
        </DndContext>
      </DropdownMenu>
    </Dropdown>
  );
};

CustomizeTable.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
};

export default CustomizeTable;
