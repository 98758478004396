import PropTypes from "prop-types";
import {StyledButton, StyledLabel} from "./styles.js";

const Button = ({
  label,
  icon,
  reverse,
  type = "primary",
  size = "large",
  htmlType = "button",
  ...props
}) => {
  return (
    <StyledButton
      size={size}
      type={htmlType}
      btnType={type}
      label={label}
      icon={icon}
      reverse={reverse}
      {...props}
    >
      {icon}
      <StyledLabel>{label}</StyledLabel>
    </StyledButton>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.node,
  type: PropTypes.oneOf(["primary", "secondary", "tertiary"]),
  size: PropTypes.oneOf(["large", "medium"]),
  reverse: PropTypes.bool,
  htmlType: PropTypes.string,
};

export default Button;
