import PropTypes from "prop-types";
import {Popover} from "reactstrap";
import {isEmpty, sortBy} from "lodash";
import {useRef, useEffect, Fragment, useState} from "react";
import truckImg from "assets/images/svg/truck.svg";
import vesselImg from "assets/images/svg/vessel.svg";
import {Link} from "react-router-dom";
import "./index.css";
import {
  DEFAULT_FIFO_HYPERLINK,
  DEFAULT_HEADER_SAP_CONTRACT_ITEM,
  DEFAULT_HEADER_SUPPLIER,
} from "./PopoverDefaultHeaders";

const ConsumedQuantityPopover = ({
  defaultValues,
  consumedQuantityData,
  moreDetails,
  popoverId,
  index,
  onPopoverToggle,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const toggle = () => {
    setIsOpen((prev) => !prev);
    onPopoverToggle(index);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!popoverRef.current.contains(event.target)) {
        setIsOpen(false);
        onPopoverToggle(index);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleDocumentClick);
    } else {
      document.removeEventListener("mousedown", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isOpen, index, onPopoverToggle]);

  const routeDetailsSorted = sortBy(
    moreDetails?.routeDetail?.route ?? [],
    "seqNumber",
    "asc",
  );

  const getPopoverMaxWidth = () => {
    const routeLength = routeDetailsSorted?.length;
    if (routeLength <= 3) return 550;
    return 600 + (routeLength - 3) * 187;
  };

  const getDefaultHeader = (val) => {
    if (isEmpty(val)) return "-";
    return val;
  };

  return (
    <Popover
      placement="bottom-start"
      popperClassName="tooltip-light tooltip-lg"
      className="consumed-quantity-breakdown-popover "
      style={{width: `${getPopoverMaxWidth()}px`}}
      target={popoverId}
      isOpen={isOpen}
      toggle={toggle}
      innerRef={popoverRef}
    >
      <div className="flex flex-row" style={{marginBottom: "24.5px"}}>
        <div className="flex items-start justify-start w-1/2 data-bs-title-p-small-text-black-light">
          {getDefaultHeader(moreDetails?.dealNumber)}
        </div>
        <div className="flex items-end justify-end w-1/2 asmallfont-weight-light-text-right">
          <Link
            to="/copro-spain/spain/fifo-feedstock"
            style={{color: "rgba(17, 17, 17, 0.64)"}}
            state={{
              costCalcObject: {
                costCalcMonth: defaultValues?.periodMonth,
                costCalcYear: defaultValues?.periodYear,
                costCalcFeedstockMaterialId: defaultValues?.materialId,
                costCalcFeedstockMaterialShortName:
                  defaultValues?.materialShortName,
                costCalcCurrencyUnit: defaultValues?.currencyUnit,
                costCalcUnitRate: defaultValues?.unitRate,
                data: {
                  consumedQuantityData,
                  sapMaterialCode: moreDetails?.sapMaterialCode,
                  sapNCCode: moreDetails?.sapNCCode,
                  documentNumber: moreDetails?.documentNumber,
                  documentItemNumber: moreDetails?.documentItemNumber,
                  locationName: moreDetails?.locationName,
                  locationId: moreDetails?.locationId,
                },
              },
            }}
          >
            {DEFAULT_FIFO_HYPERLINK}
          </Link>
        </div>
      </div>

      <div className="flex flex-row gap-4 pr-3 pb-2">
        <div className="flex flex-col flex-grow text-left first-header">
          <div className="border-b text-left flex-grow breakdown-item-header">
            <span className="">{DEFAULT_HEADER_SUPPLIER}</span>
            <span>{"\u00A0"}</span>
          </div>
          <div className="flex flex-row pt-2 flex-grow justify-between breakdown-item-value">
            <span className="py-1 text-left">
              {getDefaultHeader(moreDetails.supplier)}
            </span>
          </div>
        </div>
        <div className="flex flex-col flex-grow text-left">
          <div className="border-b text-left flex-grow breakdown-item-header">
            <span className="">{DEFAULT_HEADER_SAP_CONTRACT_ITEM}</span>
            <span>{"\u00A0"}</span>
          </div>
          <div className="flex flex-row pt-2 flex-grow justify-between breakdown-item-value">
            <span className="py-1 text-left">{moreDetails.contractNo}</span>
          </div>
        </div>
      </div>
      {moreDetails?.routeDetailsFlag === true && (
        <div className="flex flex-col justify-start items-stretch w-full gap-4">
          <div className="flex flex-col gap-4 w-full route-details">
            <div className="flex flex-col w-full pl-[30px]">
              <div className="flex items-center my-3">
                {routeDetailsSorted?.map((item, index) => {
                  const routeLength = routeDetailsSorted?.length;

                  return (
                    <Fragment key={item.seqNumber}>
                      <div className="flex flex-col">
                        <div className="flex justify-start h-20">
                          {!isEmpty(item?.plantName) && (
                            <span className="text-black ml-[-125px] w-60 inline-block self-end capitalize">
                              {item?.plantName}
                            </span>
                          )}
                          {isEmpty(item?.plantName) && (
                            <span className="">{"\u00A0"}</span>
                          )}
                        </div>
                        <div className="flex items-center">
                          <div className="route-location-indicator"></div>
                          <div
                            className={`route-location-connector ${
                              index === routeLength - 1
                                ? "invisible"
                                : "visible"
                            }`}
                          ></div>
                          <div
                            className={`${
                              item.mot === null ? "route-mot-null" : "route-mot"
                            } ${
                              index === routeLength - 1
                                ? "invisible"
                                : "visible"
                            } flex`}
                          >
                            {item?.mot?.toLowerCase() === "truck" && (
                              <img src={truckImg} alt="truck" />
                            )}
                            {item.mot?.toLowerCase() === "vessel" && (
                              <img src={vesselImg} alt="vessel" />
                            )}
                            {item.mot === null && (
                              <h1 className="text-white m-0">?</h1>
                            )}
                          </div>
                          <div
                            className={`route-location-connector ${
                              index === routeLength - 1
                                ? "invisible"
                                : "visible"
                            }`}
                          ></div>
                        </div>
                        <div
                          className={`flex justify-center mt-3 ${
                            index === routeLength - 1 ? "invisible" : "visible"
                          }`}
                        >
                          {!isEmpty(item?.motName) && (
                            <span
                              style={{textAlign: "center"}}
                              className="text-black text-ellipsis overflow-hidden whitespace-nowrap w-60"
                            >
                              {item?.motName}
                            </span>
                          )}
                          {isEmpty(item?.motName?.trim()) && (
                            <span
                              style={{textAlign: "center"}}
                              className="text-ellipsis overflow-hidden whitespace-nowrap w-60"
                            >
                              {isEmpty(item?.mot)
                                ? "[Missing mode of transport]"
                                : "[Missing Name]"}
                            </span>
                          )}
                        </div>

                        <div
                          className={`flex justify-center mt-2 ${
                            item?.bioverseFlag && index !== routeLength - 1
                              ? "visible"
                              : "invisible"
                          }`}
                        ></div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Popover>
  );
};

ConsumedQuantityPopover.propTypes = {
  defaultValues: PropTypes.string.isRequired,
  consumedQuantityData: PropTypes.string.isRequired,
  moreDetails: PropTypes.array.isRequired,
  popoverId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onPopoverToggle: PropTypes.func,
};
export default ConsumedQuantityPopover;
