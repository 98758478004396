import {useState, useMemo} from "react";
import debounce from "lodash/debounce";

function useDebouncedCallback(initialValue, callback, delay) {
  const [value, setValue] = useState(initialValue);

  const debouncedCallback = useMemo(() => {
    return debounce((newValue) => {
      callback(newValue);
    }, delay);
  }, [callback, delay]);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    debouncedCallback(e.target);
  };

  return [value, handleInputChange];
}

export default useDebouncedCallback;
