// sets width of the bar chart based on the amount of digits displayed
export const getBarWidthEstimate = (
  gasValue,
  dieselValue,
  totalValue,
  additionalValue = null,
) => {
  let width;
  const digitsDisplayed =
    (gasValue?.toString().length || 0) +
    (dieselValue?.toString().length || 0) +
    (totalValue?.toString().length || 0) +
    (additionalValue?.toString().length || 0);
  const isWCCreditsGraph = additionalValue !== null;

  switch (true) {
    case digitsDisplayed === 3:
      width = 110;
      break;
    case digitsDisplayed <= 9:
      width = 120;
      break;
    case digitsDisplayed > 9 && digitsDisplayed < 15 && isWCCreditsGraph:
      width = 170;
      break;
    case digitsDisplayed >= 15 && digitsDisplayed < 20 && !isWCCreditsGraph:
      width = 170;
      break;
    case digitsDisplayed >= 15 && digitsDisplayed < 20 && isWCCreditsGraph:
      width = 215;
      break;
    case digitsDisplayed >= 20 && digitsDisplayed < 25:
      width = 215;
      break;
    case digitsDisplayed >= 25 && !isWCCreditsGraph:
      width = 215;
      break;
    case digitsDisplayed >= 25 && isWCCreditsGraph:
      width = 235;
      break;
    default:
      width = 150;
  }

  return width;
};

export const getGraphBorder = (current) =>
  current ? "inventory-current-border" : "inventory-border";

export const getBarCharts = (
  firstValue,
  secondValue,
  additionalValue,
  totalValue,
  isWestCoast,
) =>
  isWestCoast
    ? [
        {
          bar1: {value: firstValue},
          bar2: {value: secondValue},
          bar3: {value: additionalValue},
          total: {value: totalValue},
        },
      ]
    : [
        {
          bar1: {value: firstValue},
          bar2: {value: secondValue},
          total: {value: totalValue},
        },
      ];
