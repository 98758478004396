import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import "./index.scss";

const InputNumeric = React.forwardRef(
  (
    {
      className = "",
      max = Infinity,
      min = -Infinity,
      allowNegative = false,
      decimals = 0,
      decimalFormat = "Imperial Style",
      ...props
    },
    ref,
  ) => {
    const allowedFn = (inputObj) => {
      const {value} = inputObj;
      if ((value <= max && value >= min) || value === "-") {
        return true;
      }
      return false;
    };

    return (
      <NumberFormat
        className={["bp-input-numeric", className].join(" ")}
        getInputRef={ref}
        allowNegative={allowNegative}
        isAllowed={allowedFn}
        decimalScale={decimals}
        autoComplete="off"
        decimalSeparator={decimalFormat === "European Style" ? "," : "."}
        {...props}
      />
    );
  },
);

InputNumeric.propTypes = {
  className: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  allowNegative: PropTypes.bool,
  decimals: PropTypes.number,
  decimalFormat: PropTypes.string,
};

export default InputNumeric;
