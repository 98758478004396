import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import Section from "modules/co-processing/components/Shared/CoProSection";
import TaskManager from "modules/co-processing/components/Shared/TaskManager";
import CreditGenViewMode from "modules/co-processing/components/CreditGen/CreditGenDataView";

const CreditGenPage = () => {
  return (
    <div className="co-processing-container overflow-x-auto">
      <h1 className="pb-6 mx-[40px] fs-2 border-b-[1.5px]">
        {PAGE_TITLE.CREDIT_GENERATION}
      </h1>

      <TaskManager task_type="credit-gen" />

      <Section>
        <CreditGenViewMode />
      </Section>
    </div>
  );
};

export default CreditGenPage;
