import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, Input, Label, FormGroup} from "reactstrap";

const CustomRadioButton = ({
  control,
  name,
  error,
  rules,
  type = "checkbox",
  disabled = false,
  selectedValue,
}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const yes = {checked: value === true};
        const no = {checked: value === false};
        return (
          <>
            <div>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}Yes`}
                  type={type}
                  data-test={`${name}Yes`}
                  invalid={!!error}
                  disabled={disabled}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    selectedValue(e.target.checked);
                  }}
                  {...yes}
                />
                <Label check for={`${name}Yes`}>
                  Yes
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}No`}
                  data-test={`${name}No`}
                  type={type}
                  invalid={!!error}
                  disabled={disabled}
                  onChange={(e) => {
                    onChange(!e.target.checked);
                    selectedValue(!e.target.checked);
                  }}
                  {...no}
                />
                <Label check for={`${name}No`}>
                  No
                </Label>
              </FormGroup>
            </div>
            {error && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

CustomRadioButton.propTypes = {
  type: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.func,
};
export default CustomRadioButton;
