import tw from "twin.macro";
import {TITLES} from "modules/co-processing/constants/batches";
import {
  PAGE_TITLE,
  PI_Vision_URL,
} from "modules/co-processing/constants/coProcessing";
import Section from "modules/co-processing/components/Shared/CoProSection";
import TaskManager from "modules/co-processing/components/Shared/TaskManager";
import ViewHistoricalBtn from "modules/co-processing/components/Historical/ViewHistoricalBtn";
import BatchDetailsViewMode from "modules/co-processing/components/Batches/BatchDetailsViewMode";
import {BatchTypeProvider} from "modules/co-processing/context/BatchTypeContext";
import {RefetchProvider} from "modules/co-processing/context/RefetchContext";
import "../../components/index.scss";

/* px-7 will pull from _utilities.scss and overwrite padding */
const PaddedContainer = tw.div`pl-7 pr-7 bg-[#fafafa]`;
const BatchesPage = () => {
  return (
    <BatchTypeProvider>
      <RefetchProvider>
        <div className="co-processing-container overflow-x-auto">
          <HeaderSection>
            <PageTitle>{PAGE_TITLE.BATCHES}</PageTitle>
            <ViewHistoricalBtn viewType="batches" />
            <hr className="border-b-[1.5px]" />
          </HeaderSection>

          <TaskManager task_type="batches" />

          <Section title={TITLES.BATCH_DETAILS}>
            <BatchDetailsViewMode />
          </Section>

          <Section title={TITLES.PI_DATA}>
            <PaddedContainer>
              <div
                className="min-h-[450px]"
                data-test="pi-iframe-container-batches"
              >
                <iframe
                  className="w-full min-h-screen"
                  src={PI_Vision_URL}
                  title="PIVisionSRC"
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </PaddedContainer>
          </Section>
        </div>
      </RefetchProvider>
    </BatchTypeProvider>
  );
};

export default BatchesPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageTitle = tw.h1`text-[1.8125rem] -mb-5`;
