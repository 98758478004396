import {useFormContext, Controller} from "react-hook-form";
import PropTypes from "prop-types";
import Fuse from "fuse.js";

import FormField from "modules/common/FormField";
import Select from "modules/common/Select";
import Input from "modules/common/Input";
import {useEffect, useMemo, useState} from "react";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {GET_PLANT_REGISTRY_NUMBERS} from "graphql/gy-enablement/masterDetails/TaxPlantRegistry";
import {useQuery} from "@apollo/client";

const {Option} = Select;

const buildFuseOptions = (keys = []) => ({
  threshold: 0.0,
  includeScore: true,
  ignoreLocation: true,
  keys,
});

const TaxPlantRegistryForm = ({data}) => {
  const {
    formState: {errors},
    watch,
    setValue,
  } = useFormContext();
  const watchPlant = watch("plant");
  const {data: registryNumberData} = useQuery(GET_PLANT_REGISTRY_NUMBERS);
  const {data: optionsDataPayload, loading} = useQuery(GET_FIELD_OPTIONS);

  const [plantFilteredOptions, setPlantFilteredOptions] = useState([]);
  const [edGroupFilteredOptions, setEdGroupFilteredOptions] = useState([]);

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;

  const companyCodeOptions = optionsData?.companyCode?.values || [];
  const legalEntityOptions = optionsData?.legalEntity?.values || [];
  const taxLiabilityOptions = optionsData?.taxLiability?.values || [];

  const registrationNumberOptions =
    registryNumberData?.getExcerciseRegistryRequest;

  const plantOptions = useMemo(
    () => optionsData?.plant?.values || [],
    [optionsData],
  );
  const eDGroupOptions = useMemo(
    () => optionsData?.eDGroup?.values || [],
    [optionsData],
  );

  const plantFuse = useMemo(() => {
    return new Fuse(plantOptions, buildFuseOptions(["name"]));
  }, [plantOptions]);

  const edGroupFuse = useMemo(() => {
    return new Fuse(eDGroupOptions, buildFuseOptions(["name"]));
  }, [eDGroupOptions]);

  useEffect(() => {
    // When data is available set filtered options with all values
    // This will display a full dropdown at select dropdowns
    setPlantFilteredOptions(plantOptions);
    setEdGroupFilteredOptions(eDGroupOptions);
  }, [plantOptions, eDGroupOptions]);

  useEffect(() => {
    if (watchPlant) {
      setValue(
        "registrationNumber",
        data?.id !== null && data?.registrationNumber
          ? data?.registrationNumber
          : registrationNumberOptions?.find((o) => o.plantCode === watchPlant)
              ?.registryNumber,
      );
    }
  }, [
    setValue,
    watchPlant,
    registrationNumberOptions,
    data?.registrationNumber,
    data?.id,
  ]);

  const searchPlant = (searchText) =>
    setPlantFilteredOptions(
      !searchText
        ? plantOptions // Return all list if no search
        : plantFuse.search(searchText).map(({item}) => item),
    );

  const searchEdGroup = (searchText) =>
    setEdGroupFilteredOptions(
      !searchText
        ? eDGroupOptions // Return all list if no search
        : edGroupFuse.search(searchText).map(({item}) => item),
    );

  return (
    <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4">
      <FormField
        label="Company Code"
        errorMessage={errors.companyCode?.message}
      >
        <Controller
          name="companyCode"
          defaultValue={data?.companyCode}
          render={({field}) => (
            <Select
              {...field}
              placeholder="Company Code"
              data-test="taxplant-registry-edition-input-company-code"
            >
              {companyCodeOptions.map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField label="Plant" errorMessage={errors.plant?.message}>
        <Controller
          name="plant"
          defaultValue={data?.plant?.toString()}
          render={({field}) => (
            <Select
              {...field}
              showSearch
              loading={loading}
              placeholder="Type to search..."
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchPlant}
              data-test="taxplant-registry-edition-input-plant"
            >
              {plantFilteredOptions.map((d) => (
                <Option key={d.code}>{d.name}</Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField
        label="Excise Registration Number"
        errorMessage={errors.registrationNumber?.message}
      >
        <Controller
          name="registrationNumber"
          render={({field}) => (
            <Input
              {...field}
              placeholder="Excise Registration Number"
              maxLength={250}
              autoComplete="off"
              disabled={!watchPlant}
              data-test="taxplant-registry-edition-input-registration-number"
            />
          )}
        />
      </FormField>

      <FormField label="ED Group" errorMessage={errors.eDGroup?.message}>
        <Controller
          name="eDGroup"
          defaultValue={data?.eDGroup}
          render={({field}) => (
            <Select
              {...field}
              loading={loading}
              showSearch
              placeholder="Type to search..."
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={searchEdGroup}
              data-test="taxplant-registry-edition-input-ed-group"
            >
              {edGroupFilteredOptions.map((d) => (
                <Option key={d.code}>{d.name}</Option>
              ))}
            </Select>
          )}
        />
      </FormField>
      <FormField
        label="Legal Entity"
        errorMessage={errors.legalEntity?.message}
      >
        <Controller
          name="legalEntity"
          defaultValue={data?.legalEntity}
          render={({field}) => (
            <Select
              {...field}
              placeholder="Legal Entity"
              data-test="taxplant-registry-edition-input-legal-entity"
            >
              {legalEntityOptions.map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField
        label="Tax Liability AS"
        errorMessage={errors.taxLiability?.message}
      >
        <Controller
          name="taxLiability"
          defaultValue={data?.taxLiability}
          render={({field}) => (
            <Select
              {...field}
              data-test="manual-adjustment-edition-input-tax-liability"
              placeholder="Tax Liability AS"
            >
              {taxLiabilityOptions.map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>
      <FormField label="MOT Scope" errorMessage={errors.mot?.message}>
        <Controller
          name="mot"
          defaultValue={data?.mot}
          render={({field}) => (
            <Select
              {...field}
              placeholder="MOT Scope"
              data-test="manual-adjustment-edition-input-mot"
            >
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          )}
        />
      </FormField>
      <FormField
        label="Main Customs Office"
        errorMessage={errors.mainCustomsOffice?.message}
      >
        <Controller
          name="mainCustomsOffice"
          defaultValue={data?.mainCustomsOffice}
          render={({field}) => (
            <Input
              {...field}
              placeholder="Main Customs Office"
              maxLength={250}
              autoComplete="off"
              data-test="taxplantregistry-form-input-mainCustomsOffice"
            />
          )}
        />
      </FormField>
    </div>
  );
};

TaxPlantRegistryForm.propTypes = {
  data: PropTypes.object,
};

export default TaxPlantRegistryForm;
