import {gql} from "@apollo/client";

// Fetch query for MB Balance Group table on Config center(/configuration-center/mb-balance-group)
export const MB_BALANCE_GROUP_GET_QUERY = gql`
  query bioLcGetMbBalanceGroupData {
    bioLcGetMbBalanceGroupData(event: {}) {
      mbBalanceGroupData {
        mbBalanceGroupId
        mbBalanceGroupName
        mbBalanceGroupDescription
      }
    }
  }
`;

export default {MB_BALANCE_GROUP_GET_QUERY};
