import {gql} from "@apollo/client";

export const CHERRY_POINT_METRICS = gql`
  query getProductMerices {
    getUsageMetrics {
      data {
        bol {
          count
          timeSaved
          timeSavedUnit
        }
        invoice {
          count
          timeSaved
          timeSavedUnit
        }
        shipmentRecord {
          count
          timeSaved
          timeSavedUnit
        }
        total {
          timeSaved
          timeSavedUnit
          workHoursConsidered
        }
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const TANK_VOLUME = gql`
  query getTankVolume {
    getTankUsageShipmentVolume {
      data {
        items {
          totalType
          totalValue
          yearMonth
        }
      }
      statusCode
    }
  }
`;

export const CEEMAS_MOT_METRICS = gql`
  query getCeemasProductMetrics {
    getCeemasProductMetrics {
      statusCode
      message
      data {
        daysSaved
        automatedBalanceGroup
        unreconciledBalanceGroup
      }
    }
  }
`;
