import React from "react";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";
import {useNavigate, Link} from "react-router-dom";
import ArrowRight from "../../../assets/images/svg/arrow-right.svg";
import ErrorSettings from "../../../assets/images/svg/error-settings.svg";
import Error from "../../../assets/images/svg/error.svg";

import "./index.scss";

const NotFoundPage = () => {
  toast.dismiss();

  return (
    <>
      <Helmet>
        <title>Page not found!</title>
      </Helmet>
      <div className="bp-error-page-not-found">
        <div className="bp-error-page-not-found-content-details">
          <div className="bp-error-page-not-found-content">
            <img src={Error} className="w-10 h-10" alt="Error"></img>
            <br></br>
            <label
              data-test="error-page-header-code"
              className="label-header-error-code"
            >
              404
            </label>{" "}
            <label
              data-test="error-page-header-code-message"
              className="label-header-error-code"
            >
              Page not found
            </label>{" "}
            <br></br>
            <label data-test="error-page-header" className="label-header-error">
              Oops! Sorry about that
            </label>
            <br></br>
            <p
              data-test="error-page-description"
              className="error-content-link"
            >
              If you need you can always{" "}
              <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5MzUzNjg3MDQwMCJ9/all">
                Contact Us
              </a>
              . Otherwise click below to get back to Home!
            </p>
            <div className="bp-button-container">
              <Link
                data-test="bp-error-page-home-button"
                to="/"
                className="link-home-button"
              >
                GO HOME
                <img src={ArrowRight} alt="GO HOME" />
              </Link>
            </div>
          </div>
        </div>
        <div className="bp-error-page-not-found-content-image">
          <img src={ErrorSettings} alt="Error Settings" />
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
