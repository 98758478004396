import PropTypes from "prop-types";
import React, {useMemo, useState, useCallback} from "react";
import {useQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import {COPRO_US_GET_WATERFALL_TRUCK_RACK_SHIPMENTS} from "graphql/coprocessing/shipments";
import "./index.scss";

const ShipmentItem = ({shipment, onChange, isSelected}) => {
  const {date, shipment_id} = shipment;
  return (
    <FormGroup
      check
      className="shipment-item form-check-alt form-check-reverse"
    >
      <Label check for={`checkbox-${shipment_id}`} className="shipment-label">
        <span>{shipment_id}</span>
        <span className="shipment-date">
          {date ? displayDate(date) : "N/A"}
        </span>
      </Label>

      <Input
        checked={isSelected}
        id={`checkbox-${shipment_id}`}
        type="checkbox"
        onChange={() => onChange(shipment_id)}
      />
    </FormGroup>
  );
};
ShipmentItem.propTypes = {
  shipment: PropTypes.object,
  onChange: PropTypes.func,
  isSelected: PropTypes.bool,
};

const BulkAllocationResetModal = ({isModalOpen, toggleModal, shipmentId}) => {
  const [selectedShipmentsIds, setSelectedShipmentsIds] = useState([]);
  const navigate = useNavigate();

  const {loading, data} = useQuery(
    COPRO_US_GET_WATERFALL_TRUCK_RACK_SHIPMENTS,
    {
      variables: {dtnShipmentId: shipmentId},
      fetchPolicy: "network-only",
      skip: !isModalOpen || !shipmentId, // Only fetch data when modal is open
    },
  );

  const truckRackShipments = useMemo(() => {
    if (data?.bioLcCoproUsShipmentsApi?.body) {
      return data.bioLcCoproUsShipmentsApi.body.shipments?.map((shipment) => ({
        shipment_id: shipment.shipment_id,
        date: shipment?.shipment_date,
        totalActualVolume: shipment?.parts[0]?.total_actual_volume ?? 0,
      }));
    }
    return [];
  }, [data]);

  const toggleShipmentSelection = useCallback((shipmentId) => {
    setSelectedShipmentsIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(shipmentId)) {
        return prevSelectedIds.filter((id) => id !== shipmentId);
      }
      return [...prevSelectedIds, shipmentId];
    });
  }, []);

  const handleContinue = () => {
    if (selectedShipmentsIds.length === 0) return;

    const selectedShipments = {};
    selectedShipmentsIds.forEach((id) => {
      const shipment = truckRackShipments.find(
        ({shipment_id}) => shipment_id === id,
      );

      if (shipment) {
        selectedShipments[id] = {...shipment, bols: {}};
      }
    });

    // Navigate to the reset-allocation page with the selected shipments
    navigate("/co-processing/shipments/reset-allocation", {
      state: {selectedShipments},
    });
  };

  const handleCancel = () => {
    setSelectedShipmentsIds([]);
    toggleModal();
  };

  return (
    <Modal modalClassName="bulk-reset__modal" isOpen={isModalOpen}>
      <ModalHeader close={<Close onClick={handleCancel} />}>
        <div className="fs-5">Bulk allocation reset</div>
      </ModalHeader>
      <ModalBody>
        <span className="select-shipments-label">
          Select truck rack shipments for allocation reset. This will remove all
          selected allocation for each shipment.
        </span>

        <span className="shipment-id-title"> Shipment ID </span>

        <div className="shipment-list">
          {loading && (
            <div className="spinner">
              <SpinnerLoading />
            </div>
          )}
          {!loading && truckRackShipments.length > 0 && (
            <>
              {truckRackShipments.map((shipment) => (
                <ShipmentItem
                  key={shipment.shipment_id}
                  shipment={shipment}
                  onChange={toggleShipmentSelection}
                  isSelected={selectedShipmentsIds.includes(
                    shipment.shipment_id,
                  )}
                />
              ))}
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="reset-btn-group">
          <Button color="standard-outline" onClick={handleCancel}>
            {BUTTONS.CANCEL}
          </Button>
          <Button
            color="standard-outline"
            onClick={handleContinue}
            disabled={selectedShipmentsIds?.length === 0}
          >
            {BUTTONS.CONTINUE}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default BulkAllocationResetModal;

BulkAllocationResetModal.propTypes = {
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  shipmentId: PropTypes.string,
};
