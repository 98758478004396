export const TITLES = {
  REVIEW_EMAIL_AND_SEND: "Review email and send",
  SUBMIT_BATCHES_FOR_EMTS: "Submit batches for EMTS credits",
};

export const TABLE_TABS = {
  EMTS_SUB: "EMTS Submission",
  STATE_SUB: "State submission",
  COMPLETE: "Complete",
};

export const PLACE_HOLDERS = {
  PRODUCTION_DATE: "Production date",
};

export const creditGenHeaders = {
  credit_gen_state: "Status",
  tank_number: "Days until submission date",
  batch_id: "Batch ID",
  batch_volume: "Batch volume",
  credits_quantity: "RIN quantity",
  prod_date: "Production date",
  animal_fat_percent: "Animal fats %",
  soy_percent: "Soy %",
  canola_percent: "Canola %",
  dco_percent: "DCO %",
};

export const BUTTONS = {
  BACK: "Back",
  CANCEL: "Cancel",
  CONTINUE: "Continue",
  PREPARE_SUB: "Prepare submission",
  SUBMIT_AND_SEND: "Submit and Send",
};

export const LABELS = {
  ADDITIONAL_MESSAGE: "Additional message",
  SYSTEM_MESSAGE: "System message",
  ENSURE_DOC_READY_TO_BE_SUBMITTED:
    "I ensure that this document is ready to be submitted.",
  RECIPIENTS: "Recipients",
};

export const MESSAGES = {
  INVALID_EMAIL_FORMAT: "Invalid email format.",
};
