import PropTypes from "prop-types";
import {Button, ModalBody, Modal, ModalHeader} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {SAVE_READY_BATCH} from "graphql/coprocessing/batches";
import {Close} from "@bphxd/ds-core-react";
import {
  BUTTONS,
  READY_BATCHES_MODAL_TEXT,
  ERRORS,
  FEEDSTOCK_TOAST,
} from "modules/co-processing/constants/batches";

const AllocateBatchModal = ({batches, modal, setModal, refetch}) => {
  const [allocateReadyBatches] = useLazyQuery(SAVE_READY_BATCH, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      const response = result?.bioLcCoproUsApi;
      if (response?.statusCode === 200) {
        setTimeout(() => {
          refetch();
        }, 500);
        toast.success(
          <div className="flex flex-col pl-3 pt-5">
            <span className="row">{`${FEEDSTOCK_TOAST.SUCCESS_1} ${response?.body?.ready_batches?.length} ${FEEDSTOCK_TOAST.SUCCESS_2}`}</span>
            <span className="row text-sm pb-2">
              {FEEDSTOCK_TOAST.SUCCESS_DETAIL}
            </span>
          </div>,
        );
      } else {
        toast.error(response?.error || ERRORS.FAILED_ALLOCATION);
      }
    },
  });

  const close = () => {
    setModal(false);
  };

  const onSaveDetail = () => {
    allocateReadyBatches();
    close();
  };

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={modal}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={() => close()} />}
        >
          {READY_BATCHES_MODAL_TEXT.HEADER}
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="text-sm mb-4 px-6 pt-4">
            <span className="font-bold">
              {READY_BATCHES_MODAL_TEXT.SUBHEADER}
            </span>
            <br />
            <span>{READY_BATCHES_MODAL_TEXT.BODY}</span>
          </div>
          <ul className="list-none text-2xl">
            {batches?.map((batch, index) => (
              <li key={index} className="py-3">
                {batch}
              </li>
            ))}
          </ul>
          <div className="flex flex-row w-full border border-t mt-20">
            <Button
              className="w-1/2 !text-gray-400"
              color=""
              onClick={() => close()}
            >
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 !px-2.5"
              color=""
              onClick={() => onSaveDetail()}
            >
              {BUTTONS.CONFIRM_ALLOCATION}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

AllocateBatchModal.propTypes = {
  batches: PropTypes.array,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default AllocateBatchModal;
