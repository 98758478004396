import CherrypointPreviewPopup from "modules/reports/components/Cherrypoint/CherrypointPreviewPopup";
import {updateCherrypointApi} from "graphql/cherrypoint";
import DetailedModal from "modules/common/Table/ActionsMenu/DetailedModal";
import moment from "moment";
import CherrypointEditForm from "../components/Cherrypoint/CherrypointEditForm";
import {
  dateSorterExt,
  numberSorterExt,
  stringSorterExt,
} from "../../common/Table/sorters";
import {cherrypointSchema} from "../components/Cherrypoint/schema";

export const refinaryColumns = [
  {
    title: "Batch ID",
    dataIndex: "batchId",
    key: "batchId",
    width: 150,
    selected: true,
    allowEdit: false,
    showInPreview: true,
    inputType: "input",
    ...stringSorterExt("batchId"),
  },
  {
    title: "Item Description",
    dataIndex: "itemDescription",
    key: "itemDescription",
    width: 180,
    selected: true,
    allowEdit: false,
    showInPreview: true,
    inputType: "input",
    ...stringSorterExt("itemDescription"),
  },
  {
    title: "Delivery Slip/BOL#",
    width: 150,
    dataIndex: "deliverySlipBOL",
    key: "deliverySlipBOL",
    selected: true,
    allowEdit: false,
    showInPreview: true,
    inputType: "number",
    render: (text, record) => (
      <CherrypointPreviewPopup title={text} record={record} isInvoice={false} />
    ),
    ...numberSorterExt("deliverySlipBOL"),
  },
  {
    title: "Actual Ship Date",
    width: 150,
    dataIndex: "shipDate",
    key: "shipDate",
    selected: true,
    allowEdit: false,
    inputType: "date",
    showInPreview: true,
    ...dateSorterExt("shipDate"),
  },
  {
    title: "Beginning Balance",
    width: 150,
    dataIndex: "begBalance",
    key: "begBalance",
    selected: true,
    allowEdit: false,
    inputType: "number",
    showInPreview: true,
    ...numberSorterExt("begBalance"),
  },
  {
    title: "Invoice #",
    width: 100,
    dataIndex: "invoiceNo",
    key: "invoiceNo",
    selected: true,
    showInPreview: true,
    allowEdit: false,
    inputType: "input",
    render: (text, record) => (
      <CherrypointPreviewPopup title={text} record={record} isInvoice />
    ),
    ...stringSorterExt("invoiceNo"),
  },
  {
    title: "Density",
    dataIndex: "density",
    key: "density",
    width: 100,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    inputType: "number",
    ...numberSorterExt("density"),
  },
  {
    title: "BBLS",
    dataIndex: "bbl",
    key: "bbl",
    width: 100,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    inputType: "number",
    ...numberSorterExt("bbl"),
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 40,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    inputType: "input",
    render: (_, record, index, currentDataSource, dateFormat) => {
      return (
        <DetailedModal
          mode="edit-row"
          schema={cherrypointSchema}
          tableState={{data: currentDataSource}}
          formContent={<CherrypointEditForm data={record} />}
          record={record}
          onSubmit={async (values) => {
            const {
              __typename,
              weightAnimalFatsMT,
              weightUsedCookingOilMT,
              weightSoybeanOilMT,
              weightCanolaOilMT,
              weightDistillersCornOilMT,
              weightOtherOilMT,
              itemDescription,
              shipDate,
              begBalance,
              units,
              invoiceNo,
              shipTime,
              tankId,
              action,
              actionDate,
              density,
              bblToMt,
              isSoftDelete,
              bbl,
              ...restValues
            } = values;

            const {
              data: {updateShipmentRefineryDataList: response},
            } = await updateCherrypointApi({
              ...restValues,
            });
            return response;
          }}
          dataIndex={index}
          getModalTitle={() => "Edit Following Item"}
          getDataWithDates={(trackingData) => ({
            ...trackingData,
            shipDate: trackingData?.shipDate
              ? moment(trackingData.shipDate, dateFormat)
              : "",
          })}
        />
      );
    },
  },
];

export const dateFormatForUK = "DD/MM/YYYY";

export default {
  refinaryColumns,
};
