import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import "./index.css";

const Layout = ({title, breadcrumbs, subtitle, children}) => {
  return (
    <div>
      <div className="px-6 lg:px-10 pt-5">
        <Breadcrumb>
          {breadcrumbs.map((item, index) => (
            <BreadcrumbItem
              key={index}
              active={index === breadcrumbs.length - 1}
            >
              {index === breadcrumbs.length - 1 ? (
                <span>{item.text}</span>
              ) : (
                <Link to={item.link}>{item.text}</Link>
              )}
            </BreadcrumbItem>
          ))}
        </Breadcrumb>
        <h1 className="fs-1 h1 fw-light mb-0">{title}</h1>
        {subtitle}
      </div>
      {children}
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  breadcrumbs: PropTypes.array.isRequired,
  children: PropTypes.node,
};
export default Layout;
