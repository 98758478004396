import {Tooltip} from "antd";

export const labelColor = (param) => {
  const falseColor = param === "FALSE" ? "text-red-500" : "text-orange";
  const color = param === "TRUE" ? "text-green-500" : falseColor;
  return color;
};

export const labelColorVariance = (param) => {
  const falseColor = param > 0 ? "text-red-500" : "text-yellow-400";
  const color = param === 0 ? "text-green-500" : falseColor;
  return color;
};

export const labelBlueColor = (valueEng, valueOther) => {
  return (
    <div>
      <Tooltip title={`${valueEng} (${valueOther})`} color="grey">
        <span> {valueEng}</span>
        <span style={{color: "blue"}}> ({valueOther})</span>
      </Tooltip>
    </div>
  );
};

export default {labelColor, labelColorVariance, labelBlueColor};
