/* eslint-disable jsx-a11y/click-events-have-key-events */
import {React} from "react";
import PropTypes from "prop-types";

const arrowStyles = {
  outerSpan: ({changedValue}) =>
    [
      "inline-flex flex-col top-0.5",
      "items-center align-baseline",
      "relative tracking-normal",
      changedValue ? "visible" : "invisible",
    ].join(" "),
  innerSpan: ({isVarianceOver4Percent}) =>
    [
      "h-3 text-[10px] leading-tight tracking-tighter pt-1",
      isVarianceOver4Percent ? "text-[#FC532E]" : "text-[#000000]",
    ].join(" "),
};

const isNull = (v) => {
  if (typeof v === "undefined" || v === null) return true;
  return false;
};

const ArrowIndicatorIcon = ({isVarianceOver4Percent, isPositive}) => {
  return (
    <svg
      className={isPositive ? "rotate-180" : "rotate-0"}
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.995 14.882.804.5h14.382L7.995 14.882z"
        fill={isVarianceOver4Percent ? "#ab0000" : "#111"}
      />
    </svg>
  );
};
ArrowIndicatorIcon.propTypes = {
  isPositive: PropTypes.bool,
  isVarianceOver4Percent: PropTypes.bool,
};

const ForecastArrowWidget = ({
  changedValue,
  isVarianceOver4Percent = false,
  updateVariance,
}) => {
  const isPositive = changedValue > 0;
  if (isNull(changedValue)) return null;

  const handleClick = (event) => {
    if (typeof updateVariance === "function") {
      updateVariance(event, changedValue);
    } else {
      console.warn("updateVariance function not provided.");
    }
  };

  return (
    <span
      className={arrowStyles.outerSpan({changedValue})}
      data-test="inventory-arrow-widget"
      onClick={handleClick}
    >
      <ArrowIndicatorIcon
        isVarianceOver4Percent={isVarianceOver4Percent}
        isPositive={isPositive}
      />
      <span
        className={arrowStyles.innerSpan({isVarianceOver4Percent})}
        data-test="arrow-value"
      >
        {isPositive && `+`}
        {changedValue?.toLocaleString("en-US")}
      </span>
    </span>
  );
};

ForecastArrowWidget.propTypes = {
  changedValue: PropTypes.number,
  updateVariance: PropTypes.func,
  isVarianceOver4Percent: PropTypes.bool,
};

export default ForecastArrowWidget;
