import axios from "axios";
import {LOGIN_REQUEST, msalInstance, graphConfig} from "providers/Msal";

const getMSGraphAPIToken = async () => {
  // Get account (Assuming there is always one)
  const account = msalInstance.getAllAccounts()?.[0];

  // Get Token with Graph API as audience
  const requestObject = {
    ...LOGIN_REQUEST,
    account,
    scopes: [`${graphConfig.graphMeEndpoint}User.Read`],
  };
  let accessTokenGraphAPI = null;
  try {
    // Get the Stored token or a new one refreshing it)
    const {accessToken} = await msalInstance.acquireTokenSilent(requestObject);
    accessTokenGraphAPI = accessToken;
  } catch {
    // If refresh token expired or other reasons, do full login  by popup
    const {accessToken} = await msalInstance.acquireTokenPopup(requestObject);
    accessTokenGraphAPI = accessToken;
  }
  return accessTokenGraphAPI;
};

const getProfilePhoto = async (accessTokenGraphAPI) => {
  let imageBlobURL = null;
  if (accessTokenGraphAPI) {
    await axios
      .get(`${graphConfig.graphMeEndpoint}v1.0/me/photo/$value`, {
        headers: {Authorization: `Bearer ${accessTokenGraphAPI}`},
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(response.data);
        imageBlobURL = blobUrl;
      })
      .catch((error) => {
        imageBlobURL = null;
      });
  }
  return imageBlobURL;
};

const getUserCountry = async (accessTokenGraphAPI) => {
  let userCountry = null;
  if (accessTokenGraphAPI) {
    await axios
      .get(`${graphConfig.graphMeEndpoint}v1.0/me/country`, {
        headers: {Authorization: `Bearer ${accessTokenGraphAPI}`},
        responseType: "json",
      })
      .then((response) => {
        userCountry = response?.data?.value;
      })
      .catch((error) => {
        userCountry = null;
      });
  }
  return userCountry;
};

const getUserBasicDetails = async (accessTokenGraphAPI) => {
  let userDetails = null;
  if (accessTokenGraphAPI) {
    await axios
      .get(`${graphConfig.graphMeEndpoint}v1.0/me/`, {
        headers: {Authorization: `Bearer ${accessTokenGraphAPI}`},
        responseType: "json",
      })
      .then((response) => {
        userDetails = response?.data;
      })
      .catch((error) => {
        userDetails = null;
      });
  }
  return userDetails;
};
export default {
  getProfilePhoto,
  getMSGraphAPIToken,
  getUserBasicDetails,
  getUserCountry,
};
