import PropTypes from "prop-types";
import React, {Fragment} from "react";
import Collapse from "modules/forecasting/components/CollapsiblePanel";
import {transformEastCoastPPData} from "modules/forecasting/helpers/computeEastCoastData";
import CombinedObligatedVolumeTable from "modules/forecasting/components/CombinedObligatedVolumeTable";
import GraphSection from "modules/forecasting/components/GraphSection/GraphSection";
import PublishedViewBarChart from "modules/forecasting/components/GraphSection/PublishedViewBarChart";
import YearTotalBarChart from "modules/forecasting/components/GraphSection/YearTotalBarChart";
import YearTotalCreditsGraph from "modules/forecasting/components/GraphSection/YearTotalCreditsGraph";
import {codeToForecastTypes} from "modules/forecasting/constants/inventory";
import kpiDataAndLabels from "modules/forecasting/helpers/getKPIData";
import Header from "modules/forecasting/components/InventoryPageHeader";

export const isCreditType = (type) =>
  !!(type && ["rin", "credits"].includes(type));

export const isDeficitType = (type) =>
  type && !!["rvo", "deficits"].includes(type);

export function mapPublishedToKPIs({forecastType, region, variance}) {
  const {kpiLabel, kpiMessage1, kpiMessage2} = kpiDataAndLabels(
    null,
    isCreditType(forecastType) ? "rin" : "rvo",
    region,
    "PUBLISHED",
  );

  return {
    label: kpiLabel,
    message1: kpiMessage1,
    message2: kpiMessage2,
    value1: variance?.published_vs_actual ?? "N/A",
    value2: variance?.published_vs_actual_bbl ?? "N/A",
  };
}

const PublishedViewByForecastType = ({
  region,
  activeForecastType,
  data = {},
  renderKPI = () => {},
}) => {
  const publishedView = codeToForecastTypes(activeForecastType).reduce(
    (memo, forecastType) => {
      const {body, variance} = data?.[`${forecastType}DataApi`] ?? {};

      return {
        ...memo,
        [forecastType]: {
          kpiData: mapPublishedToKPIs({variance, region, forecastType}),
          pageData: transformEastCoastPPData(body, forecastType),
        },
      };
    },
    {},
  );

  return (
    <>
      <div
        className="col-span-full flex flex-col md:flex-row gap-2 pt-4"
        data-test="kpi-group"
      >
        {codeToForecastTypes(activeForecastType).map((forecastType) => (
          <Fragment key={forecastType}>
            {renderKPI(publishedView?.[forecastType]?.kpiData)}
          </Fragment>
        ))}
      </div>

      {activeForecastType && region ? (
        <Header region={region} forecastType={activeForecastType} />
      ) : null}

      {codeToForecastTypes(activeForecastType).map((forecastType) => (
        <Collapse initialOpenIds={[forecastType]} key={forecastType}>
          <Collapse.Item id={forecastType}>
            <Collapse.ItemHeader>
              <h2 className="text-left">{forecastType}</h2>
            </Collapse.ItemHeader>

            <Collapse.ItemContent>
              <GraphSection
                view="publishedView"
                isWestCoast={region === "wc"}
                isCredits={isCreditType(forecastType)}
              >
                {
                  {
                    [isCreditType(forecastType)]: (
                      <YearTotalCreditsGraph
                        fullYearData={publishedView?.credits?.pageData}
                      />
                    ),

                    [isDeficitType(forecastType)]: (
                      <YearTotalBarChart
                        pageData={publishedView?.deficits?.pageData || []}
                      />
                    ),
                  }.true
                }

                <PublishedViewBarChart
                  pageData={publishedView?.[forecastType]?.pageData || []}
                />
              </GraphSection>

              <CombinedObligatedVolumeTable
                forecastType={forecastType}
                isPublishedView
                pageData={publishedView?.[forecastType].pageData ?? []}
                region={region}
              />
            </Collapse.ItemContent>
          </Collapse.Item>
        </Collapse>
      ))}
    </>
  );
};

PublishedViewByForecastType.propTypes = {
  data: PropTypes.shape({
    creditsDataApi: PropTypes.object,
    deficitsDataApi: PropTypes.object,
  }),
  region: PropTypes.string,
  renderKPI: PropTypes.func,
  activeForecastType: PropTypes.oneOf(["", "rin", "rvo"]).isRequired,
};

export default PublishedViewByForecastType;
