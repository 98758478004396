import PropTypes from "prop-types";
import {LEGEND, MEASUREMENTS} from "../constants/inventory";

const RinLegend = ({isWestCoast = false}) => (
  <div
    id="forecast-inventory-legend"
    data-test="inventory-legend"
    className="flex col-span-1 items-center"
  >
    <div
      id="forecast-inventory-legend-gas-label"
      className="inventory-legend-label py-2"
    >
      {LEGEND.D4} {MEASUREMENTS.RINS}
    </div>
    <div
      id="forecast-inventory-legend-gas-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-gas"
    ></div>
    {isWestCoast && (
      <div
        id="forecast-inventory-legend-additional-label"
        className="inventory-legend-label pl-5 py-2"
      >
        {LEGEND.D5} {MEASUREMENTS.RINS}
      </div>
    )}
    {isWestCoast && (
      <div
        id="forecast-inventory-legend-additional-icon"
        data-test="inventory-legend-color"
        className="inventory-legend-element legend-additional"
      ></div>
    )}
    <div
      id="forecast-inventory-legend-diesel-label"
      className="inventory-legend-label pl-5 py-2"
    >
      {LEGEND.D6} {MEASUREMENTS.RINS}
    </div>
    <div
      id="forecast-inventory-legend-diesel-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-diesel"
    ></div>
    <div
      id="forecast-inventory-legend-total-label"
      className="inventory-legend-label pl-5 py-2"
    >
      {LEGEND.TOTAL} {MEASUREMENTS.RINS}
    </div>
    <div
      id="forecast-inventory-legend-total-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-total"
    ></div>
  </div>
);

RinLegend.propTypes = {
  isWestCoast: PropTypes.bool,
};

export default RinLegend;
