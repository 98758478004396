import {Spin} from "antd";
import {isUndefined} from "lodash";
import PropTypes from "prop-types";
import Card from "../Card";
import "./index.scss";

export const CardValueGroup = ({className, children, title, subHeading}) => (
  <Card
    className={["bp-card-value-group", className].join(" ")}
    data-test="card-value-group"
  >
    {(title || subHeading) && (
      <div>
        <h1>{title}</h1>
        <h2>{subHeading}</h2>
      </div>
    )}
    <div className="bp-card-value-group-content">{children}</div>
  </Card>
);

export const CardValue = ({
  className,
  icon: Icon,
  value,
  subHeading,
  ...props
}) => (
  <Card
    className={["bp-card-value", className].join(" ")}
    data-test="card-value"
    {...props}
  >
    <Icon />
    <dl>
      <dd>{isUndefined(value) ? <Spin size="small" /> : value}</dd>
      <dt>{subHeading}</dt>
    </dl>
  </Card>
);

CardValue.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subHeading: PropTypes.string.isRequired,
};

CardValueGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  subHeading: PropTypes.string,
};
