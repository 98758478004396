import {gql} from "@apollo/client";

export const GET_VERSION_DETAILS = gql`
  query GetVersionBlockDetails($ruleVersionId: String) {
    bioLcComplianceRuleEngineGetRuleVersionBlocks(
      event: {ruleVersionId: $ruleVersionId}
    ) {
      error
      ruleDetails {
        countryCode
        ruleMasterId
        ruleName
      }
      statusCode
      versionDetails {
        remarks
        status
        validFrom
        validTo
        version
      }
      versionBlocks {
        ruleVersionId
        ruleVersionBlockLinkId
        ruleBlockMasterId
        configuration
        index
        bvAuditCreatedDatez
        bvAuditChangedDatez
        bvAuditChangedBy
        bvAuditCreatedBy
      }
    }
  }
`;

export default GET_VERSION_DETAILS;
