import {useLocation, Link} from "react-router-dom";
import Layout from "../components/Layout";
import "./view-document.css";

const ViewOutgoingDocumentPage = () => {
  const location = useLocation();
  const docParams = location.state;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Document manager", link: "/copro-spain/spain"},
    {
      text: "Outgoing",
      link: "/copro-spain/spain/document-manager/outgoing-documents",
    },
    {
      text: docParams?.certificateNumber,
    },
  ];

  return (
    <div className="flex flex-col view-doc__max-scroll bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title="View Document"
            breadcrumbs={breadcrumbItems}
            subtitle=""
          >
            <div className="flex justify-end ">
              <Link
                data-test="back-button"
                to="/copro-spain/spain/document-manager/outgoing-documents"
                className="flex justify-center p-2  border-2 border-gray-500 text-black rounded w-16 hover:opacity-50"
              >
                Back
              </Link>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {docParams?.certificatePdfUrl != null &&
          docParams?.certificatePdfUrl?.includes("file-not-found") ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={docParams?.certificatePdfUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewOutgoingDocumentPage;
