import PropTypes from "prop-types";
import {RESET_POPOVER} from "modules/co-processing/constants/shipments";
import DeletePopover from "../Shared/DeletePopover";

const ShipmentsBolResetPopover = ({
  showPopover,
  setShowPopover,
  resetFunction,
}) => {
  const handleReset = () => {
    resetFunction();
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleReset}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="reset-allocation-button"
      header={RESET_POPOVER.HEADER}
      message={RESET_POPOVER.MESSAGE}
      cancel={RESET_POPOVER.CANCEL}
      confirm={RESET_POPOVER.YES}
      idToDelete=""
    />
  );
};

ShipmentsBolResetPopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  resetFunction: PropTypes.func,
};

export default ShipmentsBolResetPopover;
