import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {COPRO_US_DELETE_DISCARDED} from "graphql/coprocessing/shipments";
import {DELETE_DISCARDED_POPOVER, ERRORS} from "../../constants/shipments";
import DeletePopover from "../Shared/DeletePopover";

const ShipmentsPermanentDiscardPopover = ({
  showPopover,
  setShowPopover,
  refetchDiscardedShipments,
  shipmentID,
}) => {
  const [deleteBatch] = useLazyQuery(COPRO_US_DELETE_DISCARDED, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
        setTimeout(() => {
          refetchDiscardedShipments();
        }, 500);
      } else {
        toast.error(
          result?.bioLcCoproUsShipmentsApi?.error || ERRORS.FAILED_DELETE,
        );
      }
    },
  });

  const handleDelete = () => {
    deleteBatch({
      variables: {shipment_id: shipmentID},
    });
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="shipments-discarded-kebab-menu"
      header={DELETE_DISCARDED_POPOVER.HEADER}
      message={DELETE_DISCARDED_POPOVER.MESSAGE}
      cancel={DELETE_DISCARDED_POPOVER.CANCEL}
      confirm={DELETE_DISCARDED_POPOVER.YES}
      idToDelete={shipmentID}
    />
  );
};

ShipmentsPermanentDiscardPopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  refetchDiscardedShipments: PropTypes.func,
  shipmentID: PropTypes.string,
};

export default ShipmentsPermanentDiscardPopover;
