import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const SEND_CERTIFICATE_EMAIL = gql`
  mutation bioLcOutgoingCertEmail(
    $changedBy: String
    $recordsToBeSent: [String]
  ) {
    bioLcOutgoingCertEmail(
      event: {changedBy: $changedBy, recordsToBeSent: $recordsToBeSent}
    ) {
      msg
      statusCode
    }
  }
`;

export const SendCertificateEmailAPI = (data) =>
  client.mutate({
    mutation: SEND_CERTIFICATE_EMAIL,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuGetCertificateOutbound"],
  });

export default {SEND_CERTIFICATE_EMAIL, SendCertificateEmailAPI};
