import {styled} from "twin.macro";
import {Button} from "reactstrap";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {BUTTONS, COPRO_URL} from "modules/co-processing/constants/coProcessing";

const ViewHistoricalBtn = ({viewType}) => {
  const navigate = useNavigate();

  return (
    <StyledButton
      color="tertiary"
      disabled={!viewType}
      onClick={() => navigate(`${COPRO_URL}/historical/${viewType}`)}
      type="button"
    >
      {BUTTONS.VIEW_HISTORICAL} {viewType}
    </StyledButton>
  );
};

ViewHistoricalBtn.propTypes = {
  viewType: PropTypes.oneOf(["batches", "shipments"]),
};

export default ViewHistoricalBtn;

const StyledButton = styled(Button)`
  border-color: black;
  border-radius: 0;
  margin-left: auto;
  &:hover {
    color: white;
    background-color: black;
  }
`;
