import {gql} from "@apollo/client";

// Fetch query for Business Partner table on Config center(/configuration-center/business-partner)
export const BUSINESS_PARTNER_GET_QUERY = gql`
  query bioLcGetBusinessPartnerApi {
    bioLcGetBusinessPartnerApi(event: {}) {
      businessPartnerData {
        businessPartnerId
        businessPartnerLongName
        businessPartnerShortName
        sourceCustomerId
        sourceVendorId
      }
    }
  }
`;

export default {BUSINESS_PARTNER_GET_QUERY};
