import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import PropTypes from "prop-types";
import {useState} from "react";
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap";
import {COPROCCESSED} from "modules/GlobalMassBalance/constants";
import DropdownCustomizeItem from "./DropdownCustomizeItem";
import Droppable from "./Droppable";

const CustomizeTable = ({columns, onColumnChange}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleColumnChange = (column, index) => {
    const newColumns = columns.map((item, i) => {
      if (i !== index) {
        return item;
      }

      return {
        ...item,
        columns: item.columns.map((c) =>
          c.accessorKey === column.accessorKey ? column : c,
        ),
      };
    });

    onColumnChange(newColumns);
  };

  const handleDragEnd = (event, index) => {
    const {active, over} = event;
    if (!active || !over) {
      return;
    }
    if (active.id === over.id) {
      return;
    }
    const _columns = columns[index];
    const header = _columns?.header;
    const newColumns = _columns?.columns;
    const reorderedColumns = [...newColumns];
    const activeIndex = reorderedColumns.findIndex(
      (c) => c.header === active.id,
    );
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.header === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);

    const newColumnData = {header, columns: reorderedColumns};

    const updatedColumns = columns.map((item, i) => {
      if (i !== index) {
        return item;
      }
      return newColumnData;
    });

    onColumnChange(updatedColumns);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const headerWithMaxColumns = columns.reduce((max, current) => {
    return current.columns.length > max.columns.length ? current : max;
  }, columns[0]).header;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="tertiary" className="rounded-0 ms-0 border" caret>
        Customize columns
        <Down24 className="btn-icon-suffix" />
      </DropdownToggle>
      <DropdownMenu
        className={`max-h-[500px] overflow-auto w-[550px] ${
          dropdownOpen ? "!flex" : ""
        } `}
      >
        {columns.map(
          (topColumn, index) =>
            topColumn.header !== COPROCCESSED && (
              <div>
                <p className="mt-[31px] ml-5 text-black">{topColumn.header}</p>
                <DndContext
                  onDragEnd={(event) => {
                    handleDragEnd(event, index);
                  }}
                  modifiers={[restrictToVerticalAxis]}
                >
                  {topColumn?.columns.map(
                    (column) =>
                      column.header !== "" && (
                        <div
                          key={column.header}
                          className={`${
                            columns.length > 1 &&
                            headerWithMaxColumns === topColumn.header &&
                            columns[0].header === topColumn.header
                              ? "border-r-2"
                              : ""
                          } ${
                            columns.length > 1 &&
                            headerWithMaxColumns === topColumn.header &&
                            columns[0].header !== topColumn.header
                              ? "border-l-2"
                              : ""
                          }`}
                        >
                          <Droppable key={column.header} id={column.header}>
                            <DropdownCustomizeItem
                              column={column}
                              onChange={(col) => {
                                handleColumnChange(col, index);
                              }}
                            />
                          </Droppable>
                        </div>
                      ),
                  )}
                </DndContext>
              </div>
            ),
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomizeTable.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
};

export default CustomizeTable;
