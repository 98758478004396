import React, {useEffect, useState} from "react";
import {Table, Input} from "antd";
import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {DEAL_NUMBER_UPDATE} from "graphql/forecast-data";
import {toast} from "react-toastify";
import isEmpty from "lodash/isEmpty";
import Collapse from "modules/forecasting/components/CollapsiblePanel";
import {SearchOutlined} from "@ant-design/icons";
import generateSaveVersionsData, {
  getLogCount,
  getColumns,
  getGroup,
  getDataSource,
} from "../helpers/generateSaveVersionsData";
import {
  CANCEL,
  ERRORS,
  SAVE_DEAL_NUMBER,
  SUCCESS_SAVE,
  TABLE_TITLE,
} from "../constants/inventory";
import {useForecastFilter} from "../context/ForecastingFilterContext";
import {logFetchDefaults} from "../helpers/getQueryVariables";

const SavedVersionsTable = ({
  data = [],
  loading = true,
  fetchAuditLog,
  type,
  search,
  setSearch,
  resetLogs = undefined,
  setResetLogs = undefined,
}) => {
  const {appliedFilters = {}} = useForecastFilter();
  const {region: selectedRegion, forecastType: selectedType = ""} =
    appliedFilters;

  const [tableData, setTableData] = useState([]);
  const [currentLength, setCurrentLength] = useState(0);
  const [count, setCount] = useState(0);
  const [toSubmit, setToSubmit] = useState([]);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    fetchAuditLog();
  }, [fetchAuditLog]);

  // resets the current page to the first one if search text is removed or search is performed
  useEffect(() => {
    if (!search || (search && count)) {
      setCurrent(1);
    }
  }, [search, count]);

  useEffect(() => {
    const generatedData = generateSaveVersionsData(data, type);
    const isEmptyData = isEmpty(data);
    const isEmptyGenerated = isEmpty(generatedData);

    if (!isEmptyData && isEmptyGenerated) {
      setTableData([]);
      setCount(0);
    } else if (!isEmptyGenerated) {
      const logCount = getLogCount(data);

      setTableData((prevState) => {
        if (prevState.length === currentLength && !resetLogs) {
          return [...prevState, ...generatedData];
        }

        if (setResetLogs !== undefined) {
          setResetLogs(false);
        }

        setCurrent(1);
        return generatedData;
      });

      setCount(logCount);
    }
  }, [data, type, currentLength, resetLogs, setResetLogs]);

  useEffect(() => {
    if (type === "Published" && tableData.length) {
      setToSubmit([]);
      const rowsToSubmit = tableData.filter(
        (row) => row.value && row.dealNumber !== row.value,
      );

      const hasError = rowsToSubmit.some((row) => row.error);

      if (!hasError) {
        setToSubmit(rowsToSubmit);
      }
    }
  }, [tableData, type]);

  const dataSource = getDataSource(loading, tableData);

  // input change function for published views table
  const handleInputChange = (receivedValues) => {
    const updatedTableData = tableData.map((row) => {
      if (row.key === receivedValues.key) {
        return {...row, ...receivedValues};
      }

      return row;
    });

    setTableData(updatedTableData);
  };

  const columns = getColumns(type, handleInputChange);

  const group = getGroup(type);

  // Cancels all changes made to the published views table and resets deal numbers
  const handleCancel = () => {
    setTableData((prevState) => {
      return prevState.map((record) => {
        const updatedRecord = {...record, error: false};
        if (record.value !== record.dealNumber) {
          updatedRecord.value = updatedRecord.dealNumber;
        }
        return updatedRecord;
      });
    });
  };

  const [submitQuery, {data: dataRes, errors: errorsRes}] = useLazyQuery(
    DEAL_NUMBER_UPDATE,
    {
      // Only cache-and-network calls onCompleted on subsequent calls with same variables
      // https://github.com/apollographql/apollo-client/issues/9338
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length < 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        } else if (dataRes != null) {
          toast.success(SUCCESS_SAVE);
          setToSubmit([]);
          setCurrent(1);
          fetchAuditLog();
        }
      },
    },
  );

  // Submits all changes made to the published views table
  const handleSubmit = () => {
    const toSubmitFormatted = toSubmit.map((row) => ({
      key: row.uuid,
      dealNumber: row.value,
    }));

    submitQuery({
      fetchPolicy: "network-only",
      variables: {
        updateDealNumber: true,
        values: toSubmitFormatted,
      },
    });
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Collapse initialOpenIds={["logs"]}>
      <Collapse.Item key="logs" id="logs">
        <Collapse.ItemHeader>
          {type === "Saved" ? (
            <div className="flex justify-between items-center flex-col py-4 md:py-2 gap-y-2 md:flex-row">
              <p className="pl-0" data-test="table-title">
                {TABLE_TITLE.SAVED_VERSIONS}
              </p>
              <div data-test="saved-search-div">
                <span
                  onClick={handleClick}
                  onKeyDown={handleClick}
                  onMouseDown={handleClick}
                >
                  <Input
                    data-test="saved-search-input"
                    className="w-56 rounded"
                    size="large"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    prefix={<SearchOutlined />}
                  />
                </span>
              </div>
            </div>
          ) : (
            <h2 data-test="table-title">{TABLE_TITLE.PUBLISHED_VIEWS}</h2>
          )}
        </Collapse.ItemHeader>
        <Collapse.ItemContent>
          <Table
            data-test="saved-versions-table"
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            bordered
            scroll={{x: "max-content"}}
            pagination={{
              pageSizeOptions: ["10", "20", "50", "100"],
              showSizeChanger: true,
              showPrevNextJumpers: true,
              position: ["bottomLeft"],
              current,
              defaultPageSize: 10,
              total: count,
              onChange: (page, pageSize) => {
                setCurrent(page);

                if (type === "Published") {
                  handleCancel();
                }

                const expectedCount = page * pageSize;
                const dataLength = dataSource.length;
                const fetch_after = dataSource[dataLength - 1]?.timestamp;
                const search_text = isEmpty(search) ? null : search;

                if (dataLength !== count) {
                  // Fetches more data if the user on the page with the latest data loaded
                  if (expectedCount === dataLength) {
                    setCurrentLength(dataLength);

                    fetchAuditLog({
                      variables: logFetchDefaults({
                        group,
                        region: selectedRegion,
                        search_text,
                        fetch_after,
                        forecast_type: selectedType,
                      }),
                    });
                  }

                  // Fetches more data if the user is on a page with no data loaded
                  if (expectedCount > dataLength) {
                    setCurrentLength(dataLength);
                    const offset = expectedCount - dataLength;
                    if (resetLogs) {
                      setResetLogs(false);
                    }

                    fetchAuditLog({
                      variables: logFetchDefaults({
                        group,
                        region: selectedRegion,
                        search_text,
                        fetch_after,
                        offset,
                        forecast_type: selectedType,
                      }),
                    });
                  }
                }
              },
            }}
          />
          {type === "Published" ? (
            <div className="col-span-full">
              <div className="w-full lg:w-2/3 flex h-12 items-center mt-2 sm:mt-0">
                <button
                  disabled={!toSubmit.length}
                  key="save-deal-number-button"
                  type="submit"
                  data-test="save-deal-number-button"
                  className="px-3 py-2 my-1 text-sm font-bold text-center text-white uppercase rounded hover:opacity-80 bg-bp-green-800 disabled:bg-gray-300"
                  onClick={handleSubmit}
                >
                  {SAVE_DEAL_NUMBER}
                </button>
                <button
                  key="cancel-deal-number-button"
                  type="button"
                  data-test="cancel-deal-number-button"
                  className="px-5 py-2 my-1 text-lg text-center text-blue-500 underline hover:text-blue-500"
                  onClick={handleCancel}
                >
                  {CANCEL}
                </button>
              </div>
            </div>
          ) : null}
        </Collapse.ItemContent>
      </Collapse.Item>
    </Collapse>
  );
};

SavedVersionsTable.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  fetchAuditLog: PropTypes.func,
  type: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func,
  resetLogs: PropTypes.bool,
  setResetLogs: PropTypes.func,
};

export default SavedVersionsTable;
