import tw from "twin.macro";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import ShipmentView from "modules/co-processing/components/Shipments/ShipmentView";
import ViewHistoricalBtn from "modules/co-processing/components/Historical/ViewHistoricalBtn";
import DataView from "modules/co-processing/components/Shipments/DataView";
import RecycleBinButton from "modules/co-processing/components/RecycleBin/RecycleBinButton";
import "../../components/index.scss";

const ShipmentsPage = () => {
  return (
    <div className="co-processing-container overflow-x-auto">
      <HeaderSection>
        <PageHeader>{PAGE_TITLE.SHIPMENTS}</PageHeader>
        <div className="flex flex-row justify-end space-x-3">
          <RecycleBinButton />
          <ViewHistoricalBtn viewType="shipments" />
        </div>
        <hr className="border-b-[1.5px]" />
      </HeaderSection>

      <DataView />
      <PaddedContainer>
        <ShipmentView />
      </PaddedContainer>
    </div>
  );
};

export default ShipmentsPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageHeader = tw.h1`text-[1.8125rem] -mb-5`;
const PaddedContainer = tw.div`pl-7 pr-7 bg-[#fafafa]`;
