import {Trash24} from "@bphxd/ds-core-react/lib/icons";
import _ from "lodash";
import BatchUnlinkPopover from "modules/co-processing/components/Shipments/BatchUnlinkPopover";
import CopyToClipboard from "modules/co-processing/components/Shipments/CopyToClipboard";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import {
  ALLOCATED_FIELD,
  BUTTONS,
  ERRORS,
  NON_RENEWABLE,
  SHIPMENTS_FIELDS_LEFT_COLUMN,
  SHIPMENTS_FIELDS_RIGHT_COLUMN,
  SHIPMENTS_LABELS,
  SHIPMENT_FIELD_KEYS,
  SHIPMENT_TYPE,
  SPLIT_SUB_TYPE,
} from "modules/co-processing/constants/shipments";
import calculateRemainingVolume from "modules/co-processing/helpers/calculateRemainingVolume";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import isNonRenewableShipment from "modules/co-processing/helpers/getRenewableStatus";
import shipmentDataFormatter from "modules/co-processing/helpers/shipmentDataFormatter";
import {shipmentLabelFormatter} from "modules/co-processing/helpers/valueFormatter";
import PropTypes from "prop-types";
import {Fragment, useEffect, useState} from "react";
import {Button} from "reactstrap";
import BatchesStatusChip from "../Shared/BatchesStatusChip";
import {
  ShipmentField,
  ShipmentLabel,
  ShipmentValue,
} from "./ShipmentFieldElements";
import ShipmentFieldWrapper from "./ShipmentFieldWrapper";

const truckStartEndDateTimeFields = [
  {
    label: "Shipment start time",
    dateKey: "load_start_time",
  },
  {
    label: "Shipment end time",
    dateKey: "load_end_time",
  },
];

const shipmentStartEndDateTimeFields = [
  {
    label: "Shipment start time",
    dateKey: "shipment_start_date",
  },
  {
    label: "Shipment end time",
    dateKey: "shipment_end_date",
  },
];

const ShipmentCard = ({
  data,
  handleChange: handleShipmentChange,
  handleErrors,
  handleLoading,
  refreshData,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(null);

  const formattedData = shipmentDataFormatter(data);

  const initializeActualVolumes = () => {
    if (formattedData[0]?.shipment_type === "TRUCK_RACK") {
      return {
        truckRackActualVolume: formattedData[0]?.total_actual_volume ?? 0,
        actualVolumes: [],
      };
    }
    return {
      truckRackActualVolume: 0,
      actualVolumes: formattedData.map(
        (shipment) => shipment.total_actual_volume ?? 0,
      ),
    };
  };

  const [{truckRackActualVolume, actualVolumes}, setActualVolumes] = useState(
    initializeActualVolumes,
  );

  const isTruckRack = data.shipment_type?.toLowerCase().includes("truck");
  const isBackToUnit = data.shipment_type
    ?.toLowerCase()
    .includes("back_to_unit");
  const isSplit = formattedData?.length > 1;

  useEffect(() => {
    const totalEstimatedVolume = data?.total_estimated_volume;
    const totalActualVolume = isTruckRack
      ? truckRackActualVolume
      : parseFloat(_.sum(actualVolumes).toFixed(2));

    if (totalActualVolume > totalEstimatedVolume) {
      handleErrors("shipment_actual_sum", ERRORS.EXCEEDS_ESTIMATED_VOLUME);
    } else {
      handleErrors("shipment_actual_sum", "");
    }
  }, [actualVolumes, isTruckRack, truckRackActualVolume, handleErrors, data]);

  const handleChange = (event, id) => {
    handleShipmentChange(event, {shipment_id: id});
  };

  const onRemove = (event, batch) => {
    event.stopPropagation();
    setSelectedBatch({
      name: batch.batch_id,
      id: batch.batch_allocation_id,
    });
    setShowPopover(true);
  };

  const defaultInput = (value, metric) => {
    const formattedValue =
      value != null ? `${value?.toLocaleString()} ${metric ?? ""}` : "N/A";

    return <ShipmentValue>{formattedValue}</ShipmentValue>;
  };

  const updateActualVolume = (value, partIndex) => {
    const parsedValue = parseFloat(value);

    if (isTruckRack) {
      // For TRUCK_RACK, all parts share the same total_actual_volume
      setActualVolumes((state) => ({
        ...state,
        truckRackActualVolume: parsedValue,
      }));
      return;
    }

    // For other shipment types, update the specific part's volume
    const newVolumes = actualVolumes.map((volume, index) =>
      partIndex === index ? parsedValue : volume,
    );

    setActualVolumes((prevVol) => ({...prevVol, actualVolumes: newVolumes}));
  };

  const getShipmentDateTimeFields = (shipment, index, dateFields) => {
    return (
      <Fragment key={`${formattedData?.shipment_id}-${index}`}>
        {dateFields.map(({label, dateKey}, index) => (
          <div
            key={`${dateKey}-${index}`}
            className="w-full flex-1 min-w-[50%] px-3 py-1"
          >
            <ShipmentField key={dateKey} data-test="copro-shipment-fields">
              <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                {label}
              </ShipmentLabel>
              <ShipmentValue>
                {shipment[dateKey] ? displayDate(shipment[dateKey]) : "N/A"}
              </ShipmentValue>
            </ShipmentField>
          </div>
        ))}
      </Fragment>
    );
  };

  const getFieldType = (shipmentData, fieldDetails, index) => {
    if (
      fieldDetails.key === SHIPMENT_FIELD_KEYS.BATCH_ID &&
      isNonRenewableShipment(shipmentData)
    ) {
      return (
        <div className="flex flex-row justify-between items-center">
          <div>{defaultInput(NON_RENEWABLE)}</div>
        </div>
      );
    }
    if (
      fieldDetails.key === SHIPMENT_FIELD_KEYS.BATCH_ID &&
      !!shipmentData[fieldDetails.key]
    ) {
      return (
        <div className="flex flex-row justify-between items-center">
          <div>{defaultInput(shipmentData[fieldDetails.key])}</div>
          <div id={`copro-us-unlink-batch-${shipmentData.batch_allocation_id}`}>
            <Button
              onClick={(event) => onRemove(event, shipmentData)}
              className="ml-2 bg-white border-0 text-black"
              disabled={shipmentData.shipment_status === "Committed"}
            >
              <span>{BUTTONS.DELETE}</span>
              <Trash24 className="!text-black cursor-pointer" color="black" />
            </Button>
          </div>
        </div>
      );
    }
    switch (fieldDetails.type) {
      case "input":
        return (
          <ShipmentValue>
            <MetricInput
              value={shipmentData[fieldDetails.key]}
              details={fieldDetails}
              showMetric={!!fieldDetails.metric}
              maxValue={calculateRemainingVolume(
                data,
                index,
                isTruckRack,
                actualVolumes,
              )}
              onChange={handleChange}
              reportErrors={handleErrors}
              setChecking={handleLoading}
              shipmentId={shipmentData.shipment_id}
              updateActualVolume={(value) => updateActualVolume(value, index)}
              isCommitted={shipmentData.shipment_status === "Committed"}
            />
          </ShipmentValue>
        );
      case "date":
        return (
          <ShipmentValue>
            {displayDate(shipmentData[fieldDetails.key], true)}
          </ShipmentValue>
        );
      case "formattedText": {
        const value = shipmentData[fieldDetails.key];
        return <ShipmentValue>{shipmentLabelFormatter(value)}</ShipmentValue>;
      }
      default:
        return defaultInput(
          shipmentData?.[fieldDetails.key],
          fieldDetails.metric,
        );
    }
  };

  const getParentShipmentInfo = (key) => {
    return {shipment_id: data.shipment_id, [key]: data?.parts?.[0]?.[key]};
  };

  const sumVolume = (parts, key) => {
    return parts.reduce((total, part) => total + (part[key] || 0), 0);
  };

  const totalEstimatedField = SHIPMENTS_FIELDS_RIGHT_COLUMN.find(
    (field) => field.key === SHIPMENT_FIELD_KEYS.TOTAL_ESTIMATED_SHIP_VOL,
  );
  const parcelField = SHIPMENTS_FIELDS_LEFT_COLUMN.find(
    (field) => field.key === SHIPMENT_FIELD_KEYS.PARCEL_GRADE,
  );

  const totalActualField = SHIPMENTS_FIELDS_RIGHT_COLUMN.find(
    (field) => field.key === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL,
  );

  const batchField = SHIPMENTS_FIELDS_LEFT_COLUMN.find(
    (field) => field.key === SHIPMENT_FIELD_KEYS.BATCH_ID,
  );

  return (
    <div data-test="copro-shipment-card" className="flex flex-col">
      <div data-test="copro-shipment-part" className="pt-3">
        {isSplit && (
          <div className="flex flex-wrap px-3 pb-6">
            <div className="w-full flex flex-wrap mb-4">
              <div className="w-full md:w-1/2 flex-1 min-w-[50%]">
                <ShipmentField data-test="copro-shipment-field-total">
                  <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                    {totalEstimatedField.label}
                  </ShipmentLabel>
                  <div className="min-w-[120px]">
                    {getFieldType(formattedData[0], totalEstimatedField)}
                  </div>
                </ShipmentField>
              </div>
              <div className="w-full md:w-1/2 flex-1 min-w-[50%]">
                <ShipmentField data-test="copro-shipment-field-parcel-grade">
                  <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                    {parcelField.label}
                  </ShipmentLabel>
                  {getFieldType(formattedData[0], parcelField)}
                </ShipmentField>
              </div>
            </div>
            {isTruckRack && (
              <div className="w-full flex flex-wrap mb-4">
                <div className="w-full  md:w-1/2 flex-1 min-w-[50%]">
                  <ShipmentField
                    className="min-w-[50%]"
                    data-test="copro-shipment-field-actual"
                  >
                    <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                      {totalActualField.label}
                    </ShipmentLabel>
                    {getFieldType(
                      getParentShipmentInfo(totalActualField.key),
                      totalActualField,
                    )}
                  </ShipmentField>
                </div>
                <div className="w-full md:w-1/2 flex-1 min-w-[50%]">
                  <ShipmentField
                    className="min-w-[50%]"
                    data-test="copro-shipment-field-allocated"
                  >
                    <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                      {ALLOCATED_FIELD.label}
                    </ShipmentLabel>
                    {defaultInput(
                      sumVolume(
                        data?.parts,
                        SHIPMENT_FIELD_KEYS.TOTAL_ALLOCATED_VOL,
                      ),
                      ALLOCATED_FIELD.metric,
                    )}
                  </ShipmentField>
                </div>
                <div className="w-full  md:w-1/2 flex-1 min-w-[50%]">
                  <ShipmentField
                    className="min-w-[50%]"
                    data-test="copro-shipment-field-batch"
                  >
                    <ShipmentLabel className="min-w-[120px] w-[120px] pb-3">
                      {batchField.label}
                    </ShipmentLabel>
                    {getFieldType(formattedData[0], batchField)}
                  </ShipmentField>
                </div>
              </div>
            )}
          </div>
        )}
        {formattedData?.map((shipment, shipmentIndex) => {
          return (
            <div
              data-test={`copro-shipment-part-item-${shipmentIndex}`}
              key={shipmentIndex}
            >
              {isSplit && (
                <>
                  <div
                    data-test="copro-shipment-part-header"
                    className="flex flex-row items-center mb-3 ml-2"
                  >
                    <BatchesStatusChip value={shipment.shipment_status} small />
                    <div data-test="copro-shipment-part-title" className="ml-2">
                      {shipment.shipment_id}
                    </div>
                    <CopyToClipboard text={shipment.shipment_id} />
                  </div>
                  <div className="flex flex-row border-b ml-2"></div>
                </>
              )}
              <div
                key={`left-column-${shipmentIndex}`}
                className="flex flex-wrap py-5"
              >
                <div className="w-full flex-1">
                  {SHIPMENTS_FIELDS_LEFT_COLUMN.map((field, index) => {
                    if (isSplit && field.key === "parcel_grade") {
                      return null;
                    }

                    if (isTruckRack) {
                      if (isSplit && field.key === SHIPMENT_FIELD_KEYS.BATCH_ID)
                        return null;

                      if (field.key === SHIPMENT_FIELD_KEYS.SHIPMENT_DATE) {
                        return getShipmentDateTimeFields(
                          shipment,
                          index,
                          truckStartEndDateTimeFields,
                        );
                      }
                    }

                    if (
                      (shipment.shipment_type === SHIPMENT_TYPE.VESSEL ||
                        shipment.shipment_type === SHIPMENT_TYPE.PIPELINE) &&
                      field.key === SHIPMENT_FIELD_KEYS.SHIPMENT_DATE
                    ) {
                      return getShipmentDateTimeFields(
                        shipment,
                        index,
                        shipmentStartEndDateTimeFields,
                      );
                    }

                    if (
                      field.key === SHIPMENT_FIELD_KEYS.SHIPMENT_TYPE &&
                      shipment.shipment_type === SHIPMENT_TYPE.VESSEL &&
                      shipment.sub_type === SPLIT_SUB_TYPE.LINE_SET
                    ) {
                      const typeFieldFunc = () => (
                        <ShipmentValue>Vessel - Line set</ShipmentValue>
                      );
                      return (
                        <ShipmentFieldWrapper
                          key={index}
                          index={shipmentIndex}
                          shipment={shipment}
                          field={field}
                          fieldFunc={typeFieldFunc}
                        />
                      );
                    }

                    return (
                      <ShipmentFieldWrapper
                        key={index}
                        index={shipmentIndex}
                        shipment={shipment}
                        field={field}
                        fieldFunc={getFieldType}
                      />
                    );
                  })}
                </div>
                <div className="w-full flex-1">
                  {SHIPMENTS_FIELDS_RIGHT_COLUMN.map((field, fieldIndex) => {
                    const skipKeys = [
                      SHIPMENT_FIELD_KEYS.TOTAL_ESTIMATED_SHIP_VOL,
                    ];
                    if (isSplit && skipKeys.includes(field.key)) {
                      return null;
                    }
                    if (
                      isSplit &&
                      isTruckRack &&
                      field.key === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL
                    ) {
                      return null;
                    }
                    if (
                      isBackToUnit &&
                      field.key === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL
                    ) {
                      return null;
                    }

                    const modifiedField = {...field};
                    const modifiedShipment = {...shipment};
                    if (
                      !isSplit &&
                      field.label === SHIPMENTS_LABELS.ALLOCATED_VOL
                    ) {
                      modifiedField.label =
                        SHIPMENTS_LABELS.TOTAL_ALLOCATED_VOL;
                    }
                    if (
                      isBackToUnit &&
                      field.label === SHIPMENTS_LABELS.TOTAL_ESTIMATED_SHIP_VOL
                    ) {
                      modifiedField.label = SHIPMENTS_LABELS.SHIPMENT_VOL;
                    }

                    if (
                      fieldIndex === SHIPMENTS_FIELDS_RIGHT_COLUMN.length - 1 &&
                      !isTruckRack
                    ) {
                      return null;
                    }
                    return (
                      <ShipmentFieldWrapper
                        key={fieldIndex}
                        index={shipmentIndex}
                        shipment={modifiedShipment}
                        field={modifiedField}
                        fieldFunc={getFieldType}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {!!showPopover && (
        <BatchUnlinkPopover
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          popoverID={`copro-us-unlink-batch-${selectedBatch?.id}`}
          refreshData={refreshData}
          shipmentName={selectedBatch?.name}
          shipmentID={selectedBatch?.id}
        />
      )}
    </div>
  );
};

ShipmentCard.propTypes = {
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleLoading: PropTypes.func,
  refreshData: PropTypes.func,
};

export default ShipmentCard;
