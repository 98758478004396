import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const ColumnItemFilterList = ({column, onChange, columnFields}) => {
  const [selected, setSelected] = useState(new Set(column.filterValues));
  const [filterFields, setFilterFields] = useState([]);

  const handleSelect = (field, e) => {
    if (e.target.checked) {
      selected.add(field);
    } else {
      selected.delete(field);
    }

    setSelected(new Set([...selected]));
    onChange({...column, filterValues: [...selected]});
  };

  useEffect(() => {
    setFilterFields(columnFields);
  }, [columnFields, column]);

  return (
    <div className="flex flex-col py-2 pl-4 pr-2 gap-2">
      {filterFields.map((field, i) => (
        <div className="flex w-100">
          <div className="mr-auto pr-4">
            <span>{field}</span>
          </div>
          <div>
            <Input
              type="checkbox"
              checked={selected.has(field)}
              onChange={(e) => handleSelect(field, e)}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

ColumnItemFilterList.propTypes = {
  columnFields: PropTypes.array,
  column: PropTypes.object,
  onChange: PropTypes.func,
};
export default ColumnItemFilterList;
