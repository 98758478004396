/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const CREDIT_FIELDS = gql`
  fragment creditFields on ForecastDataApiObligationItems {
    d4_current
    d4_previous
    d5_current @skip(if: $isEast)
    d5_previous @skip(if: $isEast)
    d6_current
    d6_previous
  }
`;
export const SHARED_DEFICITS_FIELDS = gql`
  fragment sharedDeficitsFields on ForecastDataApiObligationItems {
    obligated_blended_production
    production_diesel
    other_volumes_excluded_gas
    other_volumes_excluded_diesel
  }
`;
export const WEST_DEFICITS_FIELDS = gql`
  fragment westDeficitsFields on ForecastDataApiObligationItems {
    alaska_exports_diesel
    heating_oil_diesel
    inter_area_movements_exports_diesel
    marine_shipments_diesel
    supply_term_military_sales_diesel
    renewable_diesel
    non_obligated_export_gas
  }
`;
export const DEFICITS_FIELDS = gql`
  fragment deficitFields on ForecastDataApiObligationItems {
    ...sharedDeficitsFields
    ...westDeficitsFields @skip(if: $isEast)
  }
  ${WEST_DEFICITS_FIELDS}
  ${SHARED_DEFICITS_FIELDS}
`;

export const VARIANCE_FIELDS = gql`
  fragment varianceFields on ForecastDataApiVarianceBody {
    forecast_vs_actual
    forecast_vs_actual_bbl
    published_vs_actual
    published_vs_actual_bbl
    published_vs_forecast
    published_vs_forecast_bbl
  }
`;

export const FORECAST_DATA = gql`
  query GetForecastQuery($op: String, $year: Int) {
    bioLcForecastApi(event: {op: $op, year: $year}) {
      statusCode
      error
      body {
        obligations {
          diesel {
            values {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
            }
            publishedValues {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
              otherVolumesExcludedDiesel
            }
            publishedForecast {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
              otherVolumesExcludedDiesel
            }
            savedValues {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
              otherVolumesExcludedDiesel
            }
            previousForecast {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
            }
            forecastedValues {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
            }
            publishedActuals {
              militaryShipments
              production
              exports
              alaskaExports
              heatingOil
              marineShipments
              renewableDiesel
              otherVolumesExcludedDiesel
            }
            date {
              year
              month
            }
          }
          gas {
            values {
              production
              exports
            }
            publishedValues {
              production
              exports
              otherVolumesExcludedGas
            }
            publishedForecast {
              production
              exports
              otherVolumesExcludedGas
            }
            savedValues {
              production
              exports
              otherVolumesExcludedGas
            }
            previousForecast {
              production
              exports
            }
            forecastedValues {
              production
              exports
            }
            publishedActuals {
              production
              exports
              otherVolumesExcludedGas
            }
            date {
              year
              month
            }
          }
        }
        info {
          forecastDate
          actualsUpdatedDate
          variance {
            publishedVsForecast
            publishedVsForecastBbl
            publishedVsActual
            publishedVsActualBbl
            forecastVsActual
            forecastVsActualBbl
          }
        }
      }
    }
  }
`;

export const FORECAST_CREDITS_DATA = gql`
  query GetForecastCreditsQuery(
    $region: String
    $year: Int
    $forecastType: String
  ) {
    bioLcForecastDataApi(
      event: {region: $region, year: $year, forecastType: $forecastType}
    ) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            d4_current
            d4_previous
            d5_current
            d5_previous
            d6_current
            d6_previous
          }
          type
        }
      }
      info {
        actualsUpdatedDate
      }
      variance {
        forecast_vs_actual
        forecast_vs_actual_bbl
        published_vs_actual
        published_vs_actual_bbl
        published_vs_forecast
        published_vs_forecast_bbl
      }
    }
  }
`;

export const FORECAST_EAST_DATA = gql`
  query GetForecastEastQuery($region: String, $year: Int) {
    bioLcForecastDataApi(event: {region: $region, year: $year}) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            ...sharedDeficitsFields
          }
          type
        }
      }
      variance {
        ...varianceFields
      }
      info {
        actualsUpdatedDate
      }
    }
  }
  ${SHARED_DEFICITS_FIELDS}
  ${VARIANCE_FIELDS}
`;

export const FORECAST_TOTAL_DATA = gql`
  query CombinedMonthTotal($year: Int) {
    creditsDataApi: bioLcForecastDataApi(
      event: {forecastType: "rin", region: "wc,ec", year: $year}
    ) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            d4_total
            d5_total
            d6_total
          }
          type
        }
      }
      variance {
        ...varianceFields
      }
      info {
        actualsUpdatedDate
      }
    }

    deficitsDataApi: bioLcForecastDataApi(
      event: {forecastType: "rvo", region: "wc,ec", year: $year}
    ) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            gas_total
            diesel_total
          }
          type
        }
      }
      variance {
        ...varianceFields
      }
      info {
        actualsUpdatedDate
      }
    }
  }
  ${VARIANCE_FIELDS}
`;

export const USER_FORECAST_AUDIT_DATA = gql`
  query GetForecastAuditLogQuery(
    $group: String
    $region: String
    $search_text: String
    $fetch_after: Float
    $offset: Int
    $forecast_type: String
  ) {
    bioLcForecastAuditLogService(
      event: {
        group: $group
        region: $region
        search_text: $search_text
        fetch_after: $fetch_after
        offset: $offset
        forecast_type: $forecast_type
      }
    ) {
      statusCode
      error
      count
      data {
        comments
        payload
        dateString
        name
        region
        program
        dealNumber
        transactionType
        uuid
        timestamp
        forecast_type
      }
    }
  }
`;

export const USER_FORECAST_DATA = gql`
  query PostUserForecastQuery(
    $userInfo: String
    $comments: String
    $values: [bioLcForecastUserDataValueChange]
    $region: String
  ) {
    bioLcForecastUserDataUploader(
      event: {
        userinfo: $userInfo
        comments: $comments
        values: $values
        region: $region
      }
    ) {
      body
      statusCode
    }
  }
`;

export const USER_PUBLISH_DATA = gql`
  query PostUserForecastMutation(
    $toPublish: Boolean
    $comments: String
    $userInfo: String
    $update_type: String
    $values: [bioLcForecastUserDataValueChange]
    $region: String
  ) {
    bioLcForecastUserDataUploader(
      event: {
        toPublish: $toPublish
        comments: $comments
        userinfo: $userInfo
        update_type: $update_type
        values: $values
        region: $region
      }
    ) {
      body
      statusCode
    }
  }
`;

export const DEAL_NUMBER_UPDATE = gql`
  query PostUserForecastDealNumberQuery(
    $updateDealNumber: Boolean
    $values: [bioLcForecastUserDataValueChange]
  ) {
    bioLcForecastUserDataUploader(
      event: {updateDealNumber: $updateDealNumber, values: $values}
    ) {
      body
      statusCode
    }
  }
`;

export const FORECAST_USER_API_DATA = gql`
  query GetForecastUserDataQuery(
    $year: Int
    $region: String
    $forecast_type: String
  ) {
    bioLcForecastUserApi(
      event: {year: $year, region: $region, forecast_type: $forecast_type}
    ) {
      statusCode
      body {
        date {
          year
          month
        }
        obligationItems {
          type
          obligationValues {
            d4_current
            d4_previous
            d5_current
            d5_previous
            d6_current
            d6_previous
            alaska_exports_diesel
            heating_oil_diesel
            inter_area_movements_exports_diesel
            marine_shipments_diesel
            supply_term_military_sales_diesel
            non_obligated_export_gas
            renewable_diesel
            obligated_blended_production
            production_diesel
            other_volumes_excluded_gas
            other_volumes_excluded_diesel
          }
        }
      }
    }
  }
`;

export const SEND_FORECAST_EMAIL = gql`
  query SendForecastEmailQuery {
    forecastEmailService {
      body
      statusCode
    }
  }
`;

// Multiple queries in a single network request. Simpler code in the component.
// No need to deal with permutations of multiple queries being in loading, error, or data available state.
export const CREDITS_AND_DEFICITS_QUERY = gql`
  query ForecastCreditsAndDeficits(
    $region: String
    $year: Int
    $isEast: Boolean = true
  ) {
    creditsDataApi: bioLcForecastDataApi(
      event: {forecastType: "rin", region: $region, year: $year}
    ) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            ...creditFields
          }
          type
        }
      }
      variance {
        ...varianceFields
      }
      info {
        actualsUpdatedDate
      }
    }

    deficitsDataApi: bioLcForecastDataApi(
      event: {forecastType: "rvo", region: $region, year: $year}
    ) {
      statusCode
      error
      body {
        date {
          month
          year
        }
        obligationItems {
          obligationValues {
            ...deficitFields
          }
          type
        }
      }
      variance {
        ...varianceFields
      }
      info {
        actualsUpdatedDate
        forecastUpdatedDate
      }
    }
  }
  ${CREDIT_FIELDS}
  ${DEFICITS_FIELDS}
  ${VARIANCE_FIELDS}
`;

export default {
  FORECAST_DATA,
  USER_FORECAST_DATA,
  SEND_FORECAST_EMAIL,
};
