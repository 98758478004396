import React from "react";
import {FormGroup, Input, Label} from "reactstrap";

const CoproDetail = () => {
  return (
    <div className="flex flex-col h-[260px] justify-start items-stretch w-full gap-4">
      <div className="flex flex-row justify-between items-center h-[2.2rem]"></div>
      <div>
        <FormGroup>
          <Label for="processingUnit" className="!mb-[6px]">
            Processing unit
          </Label>
          <Input
            className="bg-white mb-[40px] !text-[13px]"
            type="select"
            id="processingUnit"
            data-test="processingUnit"
            placeholder="Please choose from list"
          >
            <option value="">Please choose from list</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="yieldPercent" className="!mb-[6px]">
            Yield %
          </Label>
          <Input
            className="bg-white !text-[13px]"
            type="select"
            id="yieldPercent"
            data-test="yieldPercent"
            placeholder="Please choose from list"
          >
            <option value="">Please choose from list</option>
          </Input>
        </FormGroup>
      </div>
    </div>
  );
};

export default CoproDetail;
