import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects, {
  useRuleValidateOutputColumns,
} from "./validationError";

const Calculate = ({
  selectedItem,
  selectDatasetList,
  selectedDataset,
  selectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchCalculateInputFormula = watch("formula");
  const watchCalculateOutputColumnName = watch("output_column");

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      formula: watchCalculateInputFormula,
      output_column: watchCalculateOutputColumnName,
    });
  }, [
    selectedItem,
    watchCalculateInputFormula,
    watchCalculateOutputColumnName,
    updateConfigurationInBlocks,
  ]);

  useEffect(() => {
    // configuration values are set here

    if (selectedItem && selectedItem?.configuration) {
      setValue("formula", selectedItem?.configuration?.formula);
      setValue("output_column", selectedItem?.configuration?.output_column);
    } else {
      setValue("formula", "");
      setValue("output_column", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  useRuleValidateOutputColumns({
    field: "output_column",
    value: watchCalculateOutputColumnName,
    selectedTemplateItems,
    clearErrors,
    setError,
    setFocus,
  });

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="formula" className="fw-normal ">
          Formula
        </Label>
        <Input
          type="text"
          id="formula"
          {...computeProps("formula", {
            required: "Please enter formula",
          })}
          invalid={!!errors.formula}
          maxLength={100}
          placeholder="Input formula"
          disabled={isDisabled}
        />
        {errors.formula && (
          <FormFeedback>{errors.formula.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="output_column" className="fw-normal ">
          Output column name
        </Label>
        <Input
          type="text"
          id="output_column"
          {...computeProps("output_column", {
            required: "Please enter formula",
            pattern: {
              value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
              message:
                "Column name must start with a letter or underscore, and can only contain letters, digits, and underscores",
            },
          })}
          invalid={!!errors.output_column}
          maxLength={31}
          placeholder="Enter name"
          disabled={isDisabled}
        />
        {errors.output_column && (
          <FormFeedback>{errors.output_column.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

Calculate.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default Calculate;
