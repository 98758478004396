import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import useNotification from "modules/common/Notification/useNotification";

export const NotificationContext = React.createContext();

export const NotificationProvider = ({children}) => {
  const [isRefreshButtonDisabled, setRefreshButtonDisabled] = useState(false);
  const [triggerNotification, clearNotification, notificationProps] =
    useNotification();

  const values = useMemo(
    () => ({
      triggerNotification,
      clearNotification,
      notificationProps,
      isRefreshButtonDisabled,
      setRefreshButtonDisabled,
    }),
    [
      clearNotification,
      notificationProps,
      triggerNotification,
      isRefreshButtonDisabled,
      setRefreshButtonDisabled,
    ],
  );

  return (
    <NotificationContext.Provider value={values}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NotificationProvider;
