import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import "./index.css";
import DownloadButton from "../DownloadButton/DownloadButton";

const Layout = ({title, breadcrumbs, subtitle, children}) => {
  return (
    <div
      className="bp-core bg-light-gray-100 px-10 pt-5 pb-40"
      data-test="dashboard-layout"
    >
      <Breadcrumb>
        {breadcrumbs.map((item, index) => (
          <BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
            <Link to={item.link}>{item.text}</Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <div className="d-inline-block w-100">
        <h1 className="fs-1 fw-light">{title}</h1>
        <DownloadButton />
      </div>
      {subtitle}
      <div className="bp-core-hr bg-light-gray-600 mb-0" />
      {children}
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  breadcrumbs: PropTypes.array.isRequired,
  children: PropTypes.node,
};
export default Layout;
