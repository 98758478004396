import {gql} from "@apollo/client";
import {client} from "providers/Apollo";
import {FETCH_POLICY_NETWORK_ONLY} from "constants/config";

export const GET_CERTIFICATE_DETAILS = gql`
  query bioLcCoproEuMassBalanceGetAutoPopulatedData(
    $templateType: String
    $certificationSystem: String
    $locationCode: String
    $vesselName: String
    $dispatchDate: String
    $inboundEuQtyId: String
    $motRecipient: String
    $sdNumber: String
    $issueDate: String
  ) {
    bioLcCoproEuMassBalanceGetAutoPopulatedData(
      event: {
        templateType: $templateType
        certificationSystem: $certificationSystem
        locationCode: $locationCode
        vesselName: $vesselName
        dispatchDate: $dispatchDate
        inboundEuQtyId: $inboundEuQtyId
        motRecipient: $motRecipient
        sdNumber: $sdNumber
        issueDate: $issueDate
      }
    ) {
      data {
        templatePdfUrl
        supplierAddress
        supplier
        shippingAddress
        isSameSupplierShippingAddress
        generatedCertificateId
        certificationNumber
        euRedCompliantFlag
        isccCompliantFlag
      }
      statusCode
      message
    }
  }
`;

export const GET_EU_COUNTRIES = gql`
  query EUGetCountries {
    bioLcCoproEuGetCountries {
      code
      name
    }
  }
`;

export const getCertificateDetailsAPI = async (data) => {
  return client.query({
    query: GET_CERTIFICATE_DETAILS,
    variables: {
      ...data,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
  });
};

export default {GET_CERTIFICATE_DETAILS, GET_EU_COUNTRIES};
