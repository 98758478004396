import {
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {Info24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {TASKS_CARDS} from "../constants/receipts";

export const NotificationsContainer = ({children}) => {
  return (
    <UncontrolledButtonDropdown size="sm">
      {children}
    </UncontrolledButtonDropdown>
  );
};
NotificationsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export const NotificationsList = ({header, children, onClearNotifications}) => {
  return (
    <DropdownMenu
      className="dropdown-menu-sm dropdown-menu-scroll border border-primary mt-2"
      style={{width: "320px"}}
    >
      <DropdownItem header className="text-center">
        {header}
      </DropdownItem>

      <DropdownItem divider />

      {children}

      {onClearNotifications ? (
        <Button
          color="light"
          block
          onClick={onClearNotifications}
          size="lg"
          className="px-0 pt-2 mx-auto py-1 whitespace-nowrap rounded-0"
        >
          {TASKS_CARDS.CLEAR_NOTIFICATIONS}
        </Button>
      ) : null}
    </DropdownMenu>
  );
};
NotificationsList.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  onClearNotifications: PropTypes.func,
};

export const NotificationToggle = ({children}) => (
  <DropdownToggle
    tag="div"
    data-test="task-notification-toggle"
    className="flex flex-col hover:cursor-pointer position-relative"
  >
    {children}
  </DropdownToggle>
);
NotificationToggle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Notification = ({children, ...props}) => (
  <>
    <DropdownItem
      {...props}
      data-test="notification-item"
      className="text-default align-items-start text-wrap py-3 px-0"
    >
      <Info24 className="text-info flex-shrink-0" />
      <div className="flex-grow-1 ms-1 x5-me-1">{children}</div>
    </DropdownItem>
    <DropdownItem divider />
  </>
);
Notification.propTypes = {
  children: PropTypes.node.isRequired,
};
