import {gql} from "@apollo/client";

export const VERSION_LIST_DISPLAY = gql`
  query VersionPageDispalyList($ruleMasterId: String) {
    bioLcComplianceRulesEngineGetRuleVersions(
      event: {ruleMasterId: $ruleMasterId}
    ) {
      error
      statusCode
      countryName
      ruleName
      versions {
        version
        bvAuditChangedDatez
        bvAuditCreatedDatez
        validFrom
        validTo
        ruleVersionId
        remarks
        status
      }
    }
  }
`;

const GET_EU_COUNTRIES = gql`
  query EUGetCountries {
    bioLcCoproEuGetCountries {
      code
      name
    }
  }
`;

export const ADD_RULE_VERSION = gql`
  mutation AddVersion($event: CreateUpdateRuleVersionRequest!) {
    bioLcComplianceRulesEngineCreateUpdateVersion(event: $event) {
      error
      ruleVersions {
        bvAuditCreatedDatez
        bvAuditChangedDatez
        remarks
        validFrom
        validTo
        ruleVersionId
        version
      }
      ruleVersionId
      statusCode
    }
  }
`;

export const DELETE_RULE_VERSION = gql`
  mutation DeleteRuleVersion(
    $isPublishActionConfirmed: Boolean
    $remarks: String
    $ruleMasterId: String
    $ruleVersionId: String
    $userId: String
    $validFrom: String
  ) {
    bioLcComplianceRulesEngineCreateUpdateVersion(
      event: {
        isPublishActionConfirmed: $isPublishActionConfirmed
        operation: "delete"
        remarks: $remarks
        ruleMasterId: $ruleMasterId
        ruleVersionId: $ruleVersionId
        userId: $userId
        validFrom: $validFrom
      }
    ) {
      error
      ruleVersions {
        bvAuditCreatedDatez
        bvAuditChangedDatez
        remarks
        validFrom
        validTo
        ruleVersionId
        version
      }
      statusCode
    }
  }
`;

export const EDIT_RULE_VERSION = gql`
  mutation EditRuleVersion(
    $isPublishActionConfirmed: Boolean
    $remarks: String
    $ruleMasterId: String
    $ruleVersionId: String
    $userId: String
    $validFrom: String
  ) {
    bioLcComplianceRulesEngineCreateUpdateVersion(
      event: {
        isPublishActionConfirmed: $isPublishActionConfirmed
        operation: "update"
        remarks: $remarks
        ruleMasterId: $ruleMasterId
        ruleVersionId: $ruleVersionId
        userId: $userId
        validFrom: $validFrom
      }
    ) {
      error
      ruleVersions {
        bvAuditCreatedDatez
        bvAuditChangedDatez
        remarks
        validFrom
        validTo
        ruleVersionId
        version
      }
      statusCode
    }
  }
`;

export default {VERSION_LIST_DISPLAY};
