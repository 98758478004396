import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const ALLOCATION_MUTATION = gql`
  mutation bioLcAllocation($event: bioLcAllocationRequest!) {
    bioLcAllocation(event: $event) {
      statusCode
      error
    }
  }
`;

export const AllocationAPI = (data) =>
  client.mutate({
    mutation: ALLOCATION_MUTATION,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetAvailableIncomingRecordsApi",
      "bioLcGetAllocationSalesApi",
    ],
  });

export default {ALLOCATION_MUTATION, AllocationAPI};
