import React from "react";

import {useQuery} from "@apollo/client";
import {LOCATION_YIELD_DETAILS_GET_QUERY} from "graphql/GlobalConfigCenter/LocationYieldDetails";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {LOCATION_YIELD_DETAILS_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {LOCATION_YIELD_DETAILS_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const LocationYieldDetailsConfigPage = () => {
  const {data, loading} = useQuery(LOCATION_YIELD_DETAILS_GET_QUERY);

  return (
    <Layout
      title={LOCATION_YIELD_DETAILS_CONFIG_PAGE.title}
      description={LOCATION_YIELD_DETAILS_CONFIG_PAGE.description}
      tableColumns={LOCATION_YIELD_DETAILS_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetLocationYieldDetailsApi.locationYieldDetailsData
      }
    />
  );
};

export default LocationYieldDetailsConfigPage;
