import Heading from "DLS/Typography/Heading";
import PropTypes from "prop-types";

const Title = ({title}) => {
  return (
    <div className="px-6 h-14 bg-gray-100 lg:px-10 flex items-center gap-x-2">
      <Heading
        level={5}
        className="text-xl font-bold text-indigo-500 m-0"
        data-test="page-title"
      >
        {title}
      </Heading>
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Title;
