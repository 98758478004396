import React from "react";
import {useParams} from "react-router-dom";
import {startCase} from "lodash";
import {setCountryDetails} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import Layout from "../components/Layout";
import IncomingDocumentComponent from "../components/IncomingDocuments/IncomingDocumentComponent";

const DocumentManagerDashboard = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Document manager", link: "/doc-manager"},
  ];

  return (
    <Layout title="Documents" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <IncomingDocumentComponent />
      </div>
    </Layout>
  );
};

export default DocumentManagerDashboard;
