import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {COPRO_US_SHIPMENTS_API_UNLINK_SHIPMENT} from "graphql/coprocessing/shipments";
import {
  UNLINK_POPOVER,
  ERRORS,
  UNLINK_SUCCESS,
} from "../../constants/shipments";
import DeletePopover from "../Shared/DeletePopover";

const BatchUnlinkPopover = ({
  showPopover,
  setShowPopover,
  shipmentName,
  shipmentID,
  popoverID,
  refreshData,
}) => {
  const [unlinkShipment] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_UNLINK_SHIPMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (result) => {
        if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
          setTimeout(() => {
            toast.success(UNLINK_SUCCESS);
            refreshData();
            setShowPopover(false);
          }, 800);
        } else {
          toast.error(ERRORS.FAILED_DELETE);
          setShowPopover(false);
        }
      },
    },
  );

  const handleDelete = () => {
    unlinkShipment({variables: {allocation_ids: [shipmentID]}});
  };

  return (
    <DeletePopover
      handleDelete={handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget={popoverID}
      name={shipmentName}
      header={UNLINK_POPOVER.HEADER}
      message={UNLINK_POPOVER.MESSAGE}
      cancel={UNLINK_POPOVER.CANCEL}
      confirm={UNLINK_POPOVER.YES}
      idToDelete={shipmentID}
    />
  );
};

BatchUnlinkPopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  shipmentName: PropTypes.string,
  shipmentID: PropTypes.string,
  popoverID: PropTypes.string,
  refreshData: PropTypes.func,
};

export default BatchUnlinkPopover;
