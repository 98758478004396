import moment from "moment";
import {useMemo, useState} from "react";
import {Datepicker} from "@bphxd/ds-core-react";
import {Button, Popover, PopoverHeader, PopoverBody} from "reactstrap";
import {
  useDateRange,
  INITIAL_RANGE,
} from "modules/co-processing/context/DateRangeContext";
import {useStatus} from "modules/co-processing/context/StatusContext";
import {SCHEDULE_POPOVER} from "modules/co-processing/constants/receipts";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import StickyHeader from "./StickyHeader";

const formatDateToDisplay = (date) =>
  moment(date, "YYYY-MM-DD").format("MMM D, YYYY");

const mapRangeToDateDisplayed = (dateRange) => {
  if (dateRange.from === dateRange.to) {
    return `${formatDateToDisplay(dateRange.from)}`;
  }

  return Object.values(dateRange).map(formatDateToDisplay).join(" thru ");
};

const StickyDatePicker = () => {
  const {dateRange, dateChangeHandler, setIsScheduled} = useDateRange();
  const {isDraft, isRowSelected, isSchedulableRecords} = useStatus();
  const [showPopover, setShowPopover] = useState(false);

  const options = useMemo(
    () => ({
      allowInput: false,
      altInput: true,
      allowInvalidPreload: false,
      altFormat: "M j, Y", // M Textual three letters Jan–Dec | j Numeric day, w/o leading zeros 1–31
      dateFormat: "Y-n-j",
      defaultDate: Object.values(INITIAL_RANGE ?? {}),
      enableTime: false,
      onChange: (selectedDates, dateStr) =>
        selectedDates?.length === 2 ? dateChangeHandler(selectedDates) : null,
      onClose: (selectedDates, dateStr, instance) => {
        // restricts user from unselecting dates
        if (selectedDates?.length === 1) {
          instance.setDate(Object.values(INITIAL_RANGE));
          return dateChangeHandler(Object.values(INITIAL_RANGE));
        }
        return null;
      },
      mode: "range",
    }),
    [dateChangeHandler],
  );

  const handleSchedule = () => {
    setShowPopover(false);
    setIsScheduled(true);
  };

  return (
    <StickyHeader>
      <div className="col-span-full h-14 receipts__dateRange">
        <label className="receipts__dateLabel" data-test="date-range-label">
          {mapRangeToDateDisplayed(dateRange)}
        </label>

        <div className="receipts__datePicker">
          <Datepicker
            className="receipts__dateInput hover:cursor-pointer focus:text-white focus:border-none"
            options={options}
            name="Datepicker"
            data-test="sticky-date-picker"
          />
        </div>

        <Button
          className="rounded-0 enabled:border-black disabled:border-gray-300"
          color="standard-secondary"
          id="popover-schedule"
          onClick={() => setShowPopover(!showPopover)}
          disabled={
            !isSchedulableRecords || !dateRange || !isDraft || !isRowSelected
          }
        >
          {BUTTONS.SCHEDULE}
        </Button>
        <Popover
          popperClassName="schedule-popover"
          innerClassName="schedule-popover-inner"
          placement="bottom"
          hideArrow
          isOpen={showPopover && !!dateRange}
          target="popover-schedule"
        >
          <PopoverHeader className="pt-3.5 text-center font-semibold">
            {SCHEDULE_POPOVER.HEADER}
          </PopoverHeader>
          <PopoverBody>
            <div className="text-slate-300 text-center border-b px-3 pb-3">
              {SCHEDULE_POPOVER.MESSAGE}
            </div>
            <div className="flex justify-between p-3">
              <Button
                className="border-black w-[75px]"
                color="standard-tertiary"
                onClick={() => setShowPopover(false)}
              >
                {SCHEDULE_POPOVER.NO}
              </Button>
              <Button
                className="schedule-btn border-black w-[75px]"
                color=""
                onClick={handleSchedule}
              >
                {SCHEDULE_POPOVER.YES}
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    </StickyHeader>
  );
};

export default StickyDatePicker;
