import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {UOM_CONVERSION} from "graphql/config-center/UomConversion.js";
import {useUserSettings} from "providers/userSettings/context.js";

import RTable from "../components/Table/index.js";
import {UOM_CONVERSION_COLUMNS} from "../components/Table/Columns.js";

const UomConversion = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => UOM_CONVERSION_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(UOM_CONVERSION);

  return (
    <RTable
      columns={columns}
      data={loading ? [] : data?.bioLcCoproConfigCenterUomConversion.Records}
      tableName="UoM conversion"
    />
  );
};

export default UomConversion;
