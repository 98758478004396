import React from "react";

import {useQuery} from "@apollo/client";
import {COUNTRY_VARIATION_GET_QUERY} from "graphql/GlobalConfigCenter/CountryVariation";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {COUNTRY_VARIATION_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {COUNTRY_VARIATION_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const CountryVariationConfigPage = () => {
  const {data, loading} = useQuery(COUNTRY_VARIATION_GET_QUERY);

  return (
    <Layout
      title={COUNTRY_VARIATION_CONFIG_PAGE.title}
      isGlobal
      description={COUNTRY_VARIATION_CONFIG_PAGE.description}
      tableColumns={COUNTRY_VARIATION_COLUMNS}
      tableData={
        loading ? [] : data?.bioLcGetCountryVariationData.countryVariationData
      }
    />
  );
};

export default CountryVariationConfigPage;
