// Default Headers for Bank Content
export const DEFAULT_EUROPEAN_CENTRAL_BANK = "EUROPEAN CENTRAL BANK";
export const DEFAULT_EUR_CURRENCY_HEADER = "EUR";
export const DEFAULT_USD_CURRENCY_HEADER = "USD";

// Default Headers for CostParametersCurrencyComponent
export const DEFAULT_S_AND_P_GLOBAL_PLATTS_HEADER = "S&P GLOBAL - PLATTS";
export const DEFAULT_GAS_OIL_HEADER = "GAS OIL";
export const DEFAULT_CURRENCY_PLATT_HEADER = "0.1/CIF/MED ($/t)";
export const DEFAULT_ULSD_HEADER = "ULSD";
