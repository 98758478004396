import PropTypes from "prop-types";
import {TabPane} from "reactstrap";
import SpinnerLoading from "modules/common/SpinnerLoading";
import ComplianceScenarioAccordion from "./ComplianceScenarioAccordion";

const Pane = ({
  tabId,
  isLoading,
  data,
  columns,
  onMore,
  onManualAdjustment,
  country,
}) => {
  const creditsData = data?.scenarioGroups.filter(
    (scenarioGroup, _) => scenarioGroup.scenarioType === "Credit",
  );
  const deficitsData = data?.scenarioGroups.filter(
    (scenarioGroup, _) => scenarioGroup.scenarioType === "Deficit",
  );

  const accordionTitle =
    creditsData && creditsData?.length ? "Credits" : "Deficits";

  return (
    <TabPane tabId={tabId}>
      {isLoading ? (
        <div className="flex items-center justify-center h-96">
          <SpinnerLoading />
        </div>
      ) : (
        <>
          <ComplianceScenarioAccordion
            scenarioList={creditsData}
            columns={columns}
            scenarioType="Credit"
            onMore={onMore}
            onManualAdjustment={onManualAdjustment}
            title={accordionTitle === "Credits" ? "" : "Credits"}
            country={country}
          />
          <ComplianceScenarioAccordion
            scenarioList={deficitsData}
            columns={columns}
            scenarioType="Deficit"
            onMore={onMore}
            onManualAdjustment={onManualAdjustment}
            title={accordionTitle === "Deficits" ? "" : "Deficits"}
            country={country}
          />
        </>
      )}
    </TabPane>
  );
};

Pane.propTypes = {
  tabId: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  columns: PropTypes.array,
  onMore: PropTypes.func,
  onManualAdjustment: PropTypes.func,
  country: PropTypes.string,
};

export default Pane;
