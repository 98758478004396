import {
  ADD_SHIPMENT,
  SHIPMENTS,
  BIO_FEEDSTOCK_RECEIPTS,
  ADD_BIO_FEEDSTOCK_RECEIPT,
  ADD_BATCH,
  BATCHES,
  EDIT_SHIPMENT_RECORD,
  EDIT_BIOFEEDSTOCK_RECORD,
  EDIT_BATCHES_RECORD,
} from "graphql/tank-usage";

import {EDIT_SHIPMENT_REFINERY, SHIPMENTS_REFINARY} from "graphql/cherrypoint";
import * as LABELS from "constants/cherrypointLabels";

export const TankUsageTypes = {
  TYPE_BIOFEEDSTOCK: "bioFeedStock",
  TYPE_BATCHES: "batches",
  TYPE_SHIPMENTS: "shipments",
  TYPE_SHIPMENT_REFINARY: "shipmentRefinery",
  TYPE_DELETED: "deleted",
  TYPE_TRUCK_RACK_COMPLETED: "truckRackCompleted",
  TYPE_TRUCK_RACK_RINS_Q: "truckRackRINSQ",
  TYPE_TRUCK_RACK_NON_RINS_Q: "truckRackNonRINSQ",
  TYPE_POS_UK: "posUK",
  TYPE_POS_PL: "posPL",
  TYPE_POS_LUX: "posLUX",
};

export const TankUsageAddQueries = {
  shipments: [ADD_SHIPMENT, SHIPMENTS],
  bioFeedStock: [ADD_BIO_FEEDSTOCK_RECEIPT, BIO_FEEDSTOCK_RECEIPTS],
  batches: [ADD_BATCH, BATCHES],
};

export const TankUsageEditQueries = {
  shipments: [EDIT_SHIPMENT_RECORD, SHIPMENTS],
  bioFeedStock: [EDIT_BIOFEEDSTOCK_RECORD, BIO_FEEDSTOCK_RECEIPTS],
  batches: [EDIT_BATCHES_RECORD, BATCHES],
  shipmentRefinery: [EDIT_SHIPMENT_REFINERY, SHIPMENTS_REFINARY],
  truckRackCompleted: [EDIT_SHIPMENT_REFINERY, SHIPMENTS_REFINARY],
};

export const TABLE_DROPDOWN_VALUES = [
  {
    titleDropdown: "TRUE",
    valueDropdown: "TRUE",
  },
  {
    titleDropdown: "FALSE",
    valueDropdown: "FALSE",
  },
  {
    titleDropdown: "PARTIAL",
    valueDropdown: "PARTIAL",
  },
];

export const BATCHES_OPERATING_CONDITION = [
  {
    title: "By-Passed",
    value: "By-Passed",
  },
  {
    title: "Normal",
    value: "Normal",
  },
  {
    title: "Normal-UnitRecirc",
    value: "Normal-UnitRecirc",
  },
  {
    title: "Shut-Down",
    value: "Shut-Down",
  },
  {
    title: "Start-Up",
    value: "Start-Up",
  },
];

export const SHIPMENT_TYPE_DROPDOWN = [
  {
    title: "Pipeline",
    value: "Pipeline",
  },
  {
    title: "Marine Barge/Vessel",
    value: "Marine Barge/Vessel",
  },
  {
    title: LABELS.TRUCK_RACK_TK72,
    value: "TK72-",
  },
  {
    title: LABELS.TRUCK_RACK_TK73,
    value: "TK73-",
  },
  {
    title: LABELS.TRUCK_RACK_TK74,
    value: "TK74-",
  },
  {
    title: "Back to Unit/Misc",
    value: "Back to Unit/Misc",
  },
  {
    title: "Inventory Transfer",
    value: "Inventory Transfer",
  },
  {
    title: "Ending Inventory",
    value: "Ending Inventory",
  },
];

export const SHIPMENT_TYPE_CHECK_DROPDOWN = [
  LABELS.TRUCK_RACK_TK72,
  LABELS.TRUCK_RACK_TK73,
  LABELS.TRUCK_RACK_TK74,
];

export const SHIPMENT_TYPE_VALUE_CHECK_DROPDOWN = ["TK72-", "TK73-", "TK74-"];

export const SHIPMENT_TYPE_TEMP = [
  {
    title: LABELS.TRUCK_RACK_TK72,
    value: "TK72-",
  },
  {
    title: LABELS.TRUCK_RACK_TK73,
    value: "TK73-",
  },
  {
    title: LABELS.TRUCK_RACK_TK74,
    value: "TK74-",
  },
];

export const BOOLEAN_DROPDOWN = [
  {
    titleDropdown: "TRUE",
    valueDropdown: "TRUE",
  },
  {
    titleDropdown: "FALSE",
    valueDropdown: "FALSE",
  },
];

export const BATCHES_YES_NO_VALUE = [
  {
    titleDropdown: "Yes",
    valueDropdown: "Yes",
  },
  {
    titleDropdown: "No",
    valueDropdown: "No",
  },
];

export default TankUsageTypes;
