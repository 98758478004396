export const instructions = [
  {
    id: 1,
    items: [
      "Please upload pdf copy biofeedstock invoice(s) delivered to Cherry Point",
      "Single or multiple uploads can be done at once",
      "Please include Invoice # in file title",
    ],
  },
  {
    id: 2,
    items: [
      "Please ensure the latest shipment tracking template, provided below, is being used",
      "Upload copy of shipment tracking report for each biofeedstock contract",
      "Please upload an updated tracking report whenever an invoice is sent to bp",
    ],
  },
  {
    id: 3,
    items: [
      "Please upload pdf copy of BOL(s) delivered to Cherry Point",
      "Single or multiple uploads can be done at once",
      "Please include BOL # in file title",
    ],
  },
];

export const posCertificateInstructions = [
  {
    id: 1,
    items: ["Please upload the pdf copy of the PoS certificate"],
  },
];

export const productMetricesIntructions = [
  {
    id: 1,
    items: [
      "Entering Invoice details into Excel and placing into storage (value X)",
      "Entering BOL details into Excel and placing into storage (value Y)",
      "Entering FS Receipt details into Excel and placing into storage (value Z)",
      "To calculate",
      "((No. of Invoices loaded) x (Value X)) + ((No. of BOLs loaded) x (Value Y)) + ((No. of FS Receipts loaded) x (Value Z)",
      "Multiple this value by $1000 (FTE day rate)",
    ],
  },
];

export const c14UploadIntruction =
  "Please ensure the C14 test result document name matches the Batch ID prior to uploading it.";

export default {
  instructions,
  productMetricesIntructions,
};
