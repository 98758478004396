import {getUniqueList} from "./posUKFilterHelpers";

export const getMasterFilter = (filters) => {
  const masterfilter = Object.keys(filters).find((key) => filters[key].length);
  return masterfilter || "";
};

export const getIsNoActiveFilter = (filters) => {
  return !Object.keys(filters).find((key) => filters[key].length);
};

export const getDefaultFilterOptions = ({
  supplierMap,
  certificateTypeMap,
  feedstockTypeMap,
  fuelTypeMap,
}) => {
  if (!supplierMap) {
    return {supplier: [], certificateType: [], feedstockType: [], fuelType: []};
  }
  return {
    supplier: Object.keys(supplierMap),
    certificateType: Object.keys(certificateTypeMap),
    feedstockType: Object.keys(feedstockTypeMap),
    fuelType: Object.keys(fuelTypeMap),
  };
};

export const getFilterMap = (filterData) => {
  if (!filterData) {
    return {};
  }
  const {certificateType} = filterData;
  const feedstockTypeMap = {};
  const fuelTypeMap = {};
  const supplierMap = {};
  const certificateTypeMap = {};

  certificateType?.forEach(({title: certificateName, supplier}) => {
    supplier?.forEach(({title: supplierName, fuelType}) => {
      fuelType?.forEach(({title: fuelTypeName, feedstockType}) => {
        if (certificateName in certificateTypeMap) {
          certificateTypeMap[certificateName].supplier = getUniqueList([
            ...certificateTypeMap[certificateName].supplier,
            supplierName,
          ]);
          certificateTypeMap[certificateName].fuelType = getUniqueList([
            ...certificateTypeMap[certificateName].fuelType,
            fuelTypeName,
          ]);
          certificateTypeMap[certificateName].feedstockType = getUniqueList([
            ...certificateTypeMap[certificateName].feedstockType,
            ...feedstockType,
          ]);
        } else {
          certificateTypeMap[certificateName] = {};
          certificateTypeMap[certificateName].supplier = [supplierName];
          certificateTypeMap[certificateName].fuelType = [fuelTypeName];
          certificateTypeMap[certificateName].feedstockType = feedstockType;
        }

        if (supplierName in supplierMap) {
          supplierMap[supplierName].certificateType = getUniqueList([
            ...supplierMap[supplierName].certificateType,
            certificateName,
          ]);
          supplierMap[supplierName].fuelType = getUniqueList([
            ...supplierMap[supplierName].fuelType,
            fuelTypeName,
          ]);
          supplierMap[supplierName].feedstockType = getUniqueList([
            ...supplierMap[supplierName].feedstockType,
            ...feedstockType,
          ]);
        } else {
          supplierMap[supplierName] = {};
          supplierMap[supplierName].certificateType = [certificateName];
          supplierMap[supplierName].fuelType = [fuelTypeName];
          supplierMap[supplierName].feedstockType = feedstockType;
        }

        if (fuelTypeName in fuelTypeMap) {
          fuelTypeMap[fuelTypeName].certificateType = getUniqueList([
            ...fuelTypeMap[fuelTypeName].certificateType,
            certificateName,
          ]);
          fuelTypeMap[fuelTypeName].supplier = getUniqueList([
            ...fuelTypeMap[fuelTypeName].supplier,
            supplierName,
          ]);
          fuelTypeMap[fuelTypeName].feedstockType = getUniqueList([
            ...fuelTypeMap[fuelTypeName].feedstockType,
            ...feedstockType,
          ]);
        } else {
          fuelTypeMap[fuelTypeName] = {};
          fuelTypeMap[fuelTypeName].certificateType = [certificateName];
          fuelTypeMap[fuelTypeName].supplier = [supplierName];
          fuelTypeMap[fuelTypeName].feedstockType = feedstockType;
        }

        feedstockType?.forEach((feedstockTypeItem) => {
          if (feedstockTypeItem in feedstockTypeMap) {
            feedstockTypeMap[feedstockTypeItem].certificateType = getUniqueList(
              [
                ...feedstockTypeMap[feedstockTypeItem].certificateType,
                certificateName,
              ],
            );
            feedstockTypeMap[feedstockTypeItem].supplier = getUniqueList([
              ...feedstockTypeMap[feedstockTypeItem].supplier,
              supplierName,
            ]);
            feedstockTypeMap[feedstockTypeItem].fuelType = getUniqueList([
              ...feedstockTypeMap[feedstockTypeItem].fuelType,
              fuelTypeName,
            ]);
          } else {
            feedstockTypeMap[feedstockTypeItem] = {};
            feedstockTypeMap[feedstockTypeItem].certificateType = [
              certificateName,
            ];
            feedstockTypeMap[feedstockTypeItem].supplier = [supplierName];
            feedstockTypeMap[feedstockTypeItem].fuelType = [fuelTypeName];
          }
        });
      });
    });
  });

  return {
    supplierMap,
    certificateTypeMap,
    feedstockTypeMap,
    fuelTypeMap,
  };
};

export default getFilterMap;
