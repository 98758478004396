import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {Close} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import {useMsal, useAccount} from "@azure/msal-react";
import {getSiteDetails, getDivisionData} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {WriteOffAPI} from "graphql/es-co-processing/mass-balance/writeOff";
import {SchemaWriteOff} from "./schemaWriteOff";
import WriteOffDetails from "./WriteOffDetails";

const WriteOffModal = ({isVisibleModal, rowData}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const divisionCode = "COPRO";
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  return (
    <div>
      <Modal
        size="md"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Write Off
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <Form
            id="write-off-form"
            schema={SchemaWriteOff}
            onSubmit={async (values) => {
              setProcessing(true);
              const {data: response} = await WriteOffAPI({
                ...values,
                reason: values.writeOffreason,
                inboundEuQtyId: rowData?.inboundRecord?.inboundEuQtyId,
                sdNumber: rowData?.inboundRecord?.sdNumber,
                createdBy: account.username,
                siteReferenceId: siteReferenceData?.siteReferenceId,
                divisionId: divisionData?.divisionId,
              });

              if (
                response?.bioLcCoproEuMassBalanceWriteOff?.statusCode === 200
              ) {
                setProcessing(false);
                setModalVisible(false);
                toast.success(
                  response?.bioLcCoproEuMassBalanceWriteOff?.message ??
                    "Write off successful",
                );
              } else {
                setProcessing(false);
                toast.error(
                  response?.bioLcCoproEuMassBalanceWriteOff?.message ??
                    "Something went wrong, failed to write off",
                );
              }
              return response;
            }}
          >
            <WriteOffDetails data={rowData} />
          </Form>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                form="write-off-form"
                type="submit"
                disabled={isProcessing}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Confirm write off
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

WriteOffModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
};

export default WriteOffModal;
