import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import SpinnerLoading from "modules/common/SpinnerLoading";
import PropTypes from "prop-types";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {Table} from "reactstrap";

import {isEqual} from "lodash";
import Filter from "modules/GlobalMassBalance/components/Filter/Filter";
import {PINNED_COLUMNS_LIST} from "modules/GlobalMassBalance/constants";
import {
  checkFilter,
  customDateFilterFn,
  customDateFilterFnOutgoing,
} from "modules/GlobalMassBalance/utils";
import {useUserSettings} from "providers/userSettings";
import "./index.scss";

const getSortOpacity = (isSorted, sortDirection) => {
  if (!isSorted || (isSorted && isSorted !== sortDirection)) {
    return "opacity-20";
  }
  return "";
};

const DocumentTable = ({
  data,
  columns,
  loading,
  showFilter,
  columnFilter,
  setColumnFilter,
}) => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const [selectedRow, setSelectedRow] = useState("");
  const [selectedHighlightRow, setSelectedHighlightRow] = useState("");
  const [previousFilters, setPreviousFilters] = useState([]);
  const [recentFilter, setRecentFilter] = useState([{column: "", value: ""}]);

  useEffect(() => {
    if (isEqual(columnFilter, previousFilters)) {
      return;
    }

    const changedFilter = columnFilter.filter((newFilter) => {
      const oldFilter = previousFilters.find((f) => f.id === newFilter.id);
      return !oldFilter || oldFilter.value !== newFilter.value;
    })[0];

    if (changedFilter) {
      setRecentFilter(changedFilter);
    }

    setPreviousFilters([...columnFilter]);
  }, [columnFilter, previousFilters]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      dateRangeFilterFn: (row, columnId, value) =>
        customDateFilterFn(row, columnId, value, dateFormat),
    },
    state: {
      columnFilters: columnFilter,
      columnPinning: {
        right: PINNED_COLUMNS_LIST,
      },
    },
    onColumnFiltersChange: setColumnFilter,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    if (loading) {
      table.resetExpanded();
      setSelectedHighlightRow("");
    }
  }, [loading, table]);

  const INCOMING_COLUMN_IDS = useMemo(() => {
    return columns[0].columns.map((col) => col.accessorKey);
  }, [columns]);

  const INCOMING_COLUMN_FILTER_IDS = useMemo(() => {
    return columns[0].columns
      .filter((col) => col.filterFn === "massBalanceCustomFilterFunction")
      .map((col) => col.accessorKey);
  }, [columns]);

  const INCOMING_COLUMN_DATE_FILTER_IDS = useMemo(() => {
    return columns[0].columns
      .filter((col) => col.filterFn === "dateRangeFilterFnOutgoing")
      .map((col) => col.accessorKey);
  }, [columns]);

  const OUTGOING_COLUMN_IDS = useMemo(() => {
    return columns[1].columns.map((col) => col.accessorKey);
  }, [columns]);

  const isIncomingFilter = useMemo(() => {
    return columnFilter.some((obj) => INCOMING_COLUMN_IDS.includes(obj.id));
  }, [columnFilter, INCOMING_COLUMN_IDS]);

  const totalData = table.getPrePaginationRowModel().rows.length;

  const isDataAvailable = checkFilter(
    data,
    columnFilter,
    isIncomingFilter,
    INCOMING_COLUMN_FILTER_IDS,
    INCOMING_COLUMN_DATE_FILTER_IDS,
  );

  const statusRightPosition =
    totalData > 0 && isDataAvailable && !loading ? "55px" : "30px";

  const buildRows = useCallback(
    (row, flexRender) => {
      let inboundRecords = row.original?.inboundRecords ?? [{}];
      if (isIncomingFilter) {
        columnFilter.forEach((filter) => {
          const key = filter?.id;
          const value = filter?.value;
          if (INCOMING_COLUMN_FILTER_IDS.includes(key)) {
            inboundRecords = inboundRecords.filter((record) =>
              record[key]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            );
          }
          if (INCOMING_COLUMN_DATE_FILTER_IDS.includes(key)) {
            inboundRecords = inboundRecords.filter((record) =>
              customDateFilterFnOutgoing(record[key], value, dateFormat),
            );
          }
        });
      }

      if (inboundRecords.length === 0) {
        return null;
      }

      return (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => {
            if (OUTGOING_COLUMN_IDS.includes(cell.column.id)) {
              return (
                <td
                  key={cell.id}
                  className={`${cell.index > 0 ? "d-none" : ""} ${
                    cell.getContext().column.columnDef.dataType === "number" &&
                    "text-right"
                  }`}
                  style={{
                    verticalAlign: inboundRecords.length > 1 ? "top" : "middle",
                    padding: "10px 15px",
                    minWidth: cell.column.id === "actions" ? "auto" : "150px",
                    whiteSpace: "nowrap",
                    borderLeft:
                      OUTGOING_COLUMN_IDS[0] === cell.column.id
                        ? "1px solid #eee"
                        : "",
                    position: cell.column.getIsPinned() ? "sticky" : "relative",
                    right: cell.column.getIsPinned()
                      ? cell.column.id === "status"
                        ? statusRightPosition
                        : "0px"
                      : "auto",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            }
            if (INCOMING_COLUMN_IDS.includes(cell.column.id)) {
              const {row, ...context} = cell.getContext();
              return (
                <td
                  key={cell.id}
                  style={{
                    verticalAlign: "middle",
                    minWidth: cell.column.id === "actions" ? "auto" : "150px",
                  }}
                  className="width-td"
                >
                  {inboundRecords.map((record, index) => {
                    const rowKey = `${row.id}_${index}`;
                    return (
                      <tr
                        key={index}
                        className={`fixed-height-tr ${
                          selectedRow === row.id + index &&
                          inboundRecords.length > 1
                            ? "!border-b !border-b-[#bbb]"
                            : ""
                        } ${
                          selectedHighlightRow === rowKey ? "!bg-[#eef0f5]" : ""
                        }`}
                        style={{
                          borderBottom:
                            index < inboundRecords.length - 1
                              ? "1px solid #eee"
                              : "",
                          padding: "0px 15px",
                          display: "flex",
                          justifyContent:
                            cell.getContext().column.columnDef.dataType ===
                            "number"
                              ? "flex-end"
                              : "flex-start",
                          maxHeight: "200px",
                          // borderRight:
                          //   INCOMING_COLUMN_IDS[
                          //     INCOMING_COLUMN_IDS.length - 1
                          //   ] === cell.column.id
                          //     ? "1px solid #eee"
                          //     : "",
                        }}
                        onMouseEnter={() => setSelectedRow(row.id + index)}
                        onMouseLeave={() => setSelectedRow("")}
                      >
                        <td
                          style={{
                            alignItems: "center",
                            verticalAlign: "middle",
                            padding: "15px 0px",
                            whiteSpace: "nowrap",
                            zIndex: "auto",
                            display:
                              cell.column.id === "actions" ? "flex" : "block",
                          }}
                          className="fixed-height-tr"
                        >
                          {flexRender(cell.column.columnDef.cell, {
                            ...context,
                            row: {
                              ...row,
                              original: {
                                ...row.original,
                                inboundRecords: record,
                              },
                              highlight: () => {
                                setSelectedHighlightRow(rowKey);
                              },
                            },
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </td>
              );
            }
            return null;
          })}
        </tr>
      );
    },
    [
      INCOMING_COLUMN_DATE_FILTER_IDS,
      INCOMING_COLUMN_FILTER_IDS,
      INCOMING_COLUMN_IDS,
      OUTGOING_COLUMN_IDS,
      columnFilter,
      dateFormat,
      isIncomingFilter,
      selectedHighlightRow,
      selectedRow,
      statusRightPosition,
    ],
  );

  const recentFilterMessage = useMemo(() => {
    if (typeof recentFilter?.value === "object") {
      return `${recentFilter?.value[0]} - ${recentFilter?.value[1]}`;
    }
    return recentFilter?.value;
  }, [recentFilter]);

  return (
    <Table hover className="border-b border-b-[#eee] !mb-0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {header.column.getCanSort() ? (
                  <th
                    className="sort-header"
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      borderLeft:
                        OUTGOING_COLUMN_IDS[0] === header.column.id
                          ? "1px solid #eee"
                          : "",
                      minWidth:
                        header.column.id === "actions" ? "auto" : "150px",
                      position: header.column.getIsPinned()
                        ? "sticky"
                        : "relative",
                      // top: header.column.getIsPinned() ? "0px" : "auto",
                      right: header.column.getIsPinned()
                        ? header.column.id === "status"
                          ? statusRightPosition
                          : "0px"
                        : "auto",
                      zIndex: header.column.getIsPinned() ? "5" : "auto",
                    }}
                  >
                    {INCOMING_COLUMN_IDS.includes(header.column.id) ? (
                      <div
                        className={`d-flex align-items-center ${
                          header.getContext().column.columnDef.dataType ===
                            "number" && "justify-content-end"
                        }`}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`d-flex align-items-center ${
                          header.getContext().column.columnDef.dataType ===
                            "number" && "justify-content-end"
                        }`}
                        onClick={header.column.getToggleSortingHandler()}
                        onKeyDown={() => {}}
                      >
                        <div className="mr-[5px]">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                        <div>
                          <TriangleUp16
                            className={`mb-[-5px] ${getSortOpacity(
                              header.column.getIsSorted(),
                              "asc",
                            )}`}
                          />
                          <TriangleDown16
                            className={`mt-[-5px] ${getSortOpacity(
                              header.column.getIsSorted(),
                              "desc",
                            )}`}
                          />
                        </div>
                      </div>
                    )}
                    {header.column.getCanFilter() && showFilter ? (
                      <div className="mt-[10px]">
                        <Filter column={header.column} data={data} />
                      </div>
                    ) : null}
                  </th>
                ) : (
                  <th
                    className={`top-header ${
                      header.column.columnDef.key === "actions"
                        ? "global-actions"
                        : ""
                    }`}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      borderLeft:
                        columns[1].header === header?.column?.id
                          ? "1px solid #eee"
                          : "",
                      position:
                        header.column.getIsPinned() &&
                        PINNED_COLUMNS_LIST.includes(header.column.id)
                          ? "sticky"
                          : "relative",
                      // top:
                      //   header.column.getIsPinned() &&
                      //   PINNED_COLUMNS_LIST.includes(header.column.id)
                      //     ? "0px"
                      //     : "auto",
                      right:
                        header.column.getIsPinned() &&
                        PINNED_COLUMNS_LIST.includes(header.column.id)
                          ? header.column.id === "status"
                            ? statusRightPosition
                            : "0px"
                          : "auto",
                      zIndex: header.column.getIsPinned() ? "5" : "auto",
                    }}
                  >
                    <span className="top-header-text">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </span>
                  </th>
                )}
              </Fragment>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {loading && (
          <tr className="h-[calc(100vh-591px)]">
            <td colSpan="100%">
              <div className="flex items-center justify-center ">
                <SpinnerLoading />
              </div>
            </td>
          </tr>
        )}
        {!loading &&
          table
            .getRowModel()
            .rows.map((row) => (
              <Fragment key={row.id}>{buildRows(row, flexRender)}</Fragment>
            ))}
        {!loading && (totalData === 0 || !isDataAvailable) && (
          <tr className="h-[calc(100vh-688px)]">
            <td
              colSpan="100%"
              className="text-[16px] !text-[#111111] text-center !pt-[110px]"
            >
              {columnFilter.length > 0 ? (
                <>
                  <p className="text-[26px]">
                    No results found matching &apos;
                    {recentFilterMessage}&apos;
                  </p>
                  <p className="text-[18px] font-light tracking-[0.2px] leading-[1.78]">
                    Please try a different keyword
                  </p>
                </>
              ) : (
                <span>
                  There are currently no incoming or outgoing <br />{" "}
                  transactions recorded for this mass balance period.
                </span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

DocumentTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  showFilter: PropTypes.bool,
  columnFilter: PropTypes.array,
  setColumnFilter: PropTypes.func,
};

export default DocumentTable;
