import {React} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import tw from "twin.macro";
import {UncontrolledTooltip} from "reactstrap";
import VarianceInfoToolTip from "./VarianceInfoToolTip";
import {isCurrentMonth, isFutureMonth} from "../helpers/dateUtils";
import mapKeyToToolTipLabel from "../helpers/mapKeyToToolTipLabel";

const ArrowIcon = ({isOverFourPercent, isVariancePositive, tooltipId}) => {
  return (
    <span id={tooltipId}>
      <svg
        className={`pt-0.5 ${isVariancePositive ? "rotate-180" : "rotate-0"}`}
        data-test="arrow-widget-icon"
        width="15"
        height="15"
        viewBox="0 0 15 15"
      >
        <path
          d="M7.995 14.882.804.5h14.382L7.995 14.882z"
          fill={isOverFourPercent ? "#ab0000" : "#111"}
        />
      </svg>
    </span>
  );
};
ArrowIcon.propTypes = {
  isVariancePositive: PropTypes.bool,
  isOverFourPercent: PropTypes.bool,
  tooltipId: PropTypes.string,
};

export const ArrowIndicatorWithTooltip = ({
  variance,
  obligationDate,
  row,
  isPublishedView = false,
}) => {
  if (!variance || !row) return null;

  const systemValue = row?.[`${obligationDate}_systemValue`] ?? 0;
  const variancePercent = row?.[`${obligationDate}_variance_percentage`] || 0;

  const cellData = {
    actualValue: row?.[`${obligationDate}`],
    displayDate: moment(obligationDate, "YYYY-MM")
      .format("MMM ‘YY")
      .toUpperCase(),
    variance,
    variancePercent,
    systemValue,
    dateType:
      !isPublishedView &&
      (isCurrentMonth(obligationDate) || isFutureMonth(obligationDate)),
    ...mapKeyToToolTipLabel(row),
  };

  const displayValue = variance?.toLocaleString("en-US");
  const isVariancePositive = variance > 0;
  const isOverFourPercent = Math.abs(variancePercent) > 4;

  const tooltipId = `${row?.key}-${obligationDate}`;

  return (
    <>
      <span
        className="inline-flex flex-col relative items-center align-baseline"
        data-test="arrow-widget-with-tooltip"
      >
        <ArrowIcon
          tooltipId={tooltipId}
          isOverFourPercent={isOverFourPercent}
          isVariancePositive={isVariancePositive}
        />
        <IndicatorValue
          data-test="indicator-value"
          className={isOverFourPercent ? "is-red" : null}
        >
          {isVariancePositive ? `+${displayValue}` : displayValue}
        </IndicatorValue>
      </span>
      <UncontrolledTooltip placement="right" target={tooltipId}>
        {variance && <VarianceInfoToolTip cellData={cellData} />}
      </UncontrolledTooltip>
    </>
  );
};

ArrowIndicatorWithTooltip.propTypes = {
  obligationDate: PropTypes.string,
  row: PropTypes.object,
  variance: PropTypes.number,
  isPublishedView: PropTypes.bool,
};

export default ArrowIndicatorWithTooltip;

const IndicatorValue = tw.div`
  h-3 
  leading-tight tracking-tighter 
  text-[#000000] text-[10px]
  [&.is-red]:text-[#FC532E]
`;
