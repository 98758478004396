export const mapUpdatedValues = (data, prevTableData) => {
  return data.map((item, i) => ({
    ...item,
    ...(prevTableData[i]?.updatedForecast && {
      updatedForecast: prevTableData[i]?.updatedForecast,
    }),
  }));
};

export const setRowUpdatedForecast = (table, updatedRecord) => {
  return table.map((row) => {
    if (row.key === updatedRecord.key) {
      return updatedRecord;
    }
    return row;
  });
};

export default mapUpdatedValues;
