import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const ASSIGN_TO_SALE = gql`
  mutation AssignToSale($event: bioLcCoproEuMassBalanceAssignRequest!) {
    bioLcCoproEuMassBalanceAssign(event: $event) {
      message
      status
      statusCode
      certificatePdfLink
    }
  }
`;

export const AssignToSaleAPI = (data) =>
  client.mutate({
    mutation: ASSIGN_TO_SALE,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuMassBalanceAllRecords"],
  });

export default {
  ASSIGN_TO_SALE,
  AssignToSaleAPI,
};
