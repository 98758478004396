import {gql} from "@apollo/client";

export const USER_SETTINGS = gql`
  query getUserSettings {
    getUserPreference {
      message
      statusCode
      totalCount
      userSettings {
        dateFormat
        decimalFormat
      }
      errors {
        code
        message
      }
    }
  }
`;

export const SET_USER_SETTINGS = gql`
  mutation setUserSettings($date_format: String, $decimal_format: String) {
    UpdateUserPreference(
      event: {date_format: $date_format, decimal_format: $decimal_format}
    ) {
      errors {
        code
        message
      }
      message
      statusCode
      totalCount
    }
  }
`;

export default {USER_SETTINGS, SET_USER_SETTINGS};
