export const getFuseOptions = (keys) => {
  const fuseOptions = {
    keys,
    threshold: 0.0,
    includeScore: true,
    ignoreLocation: true,
    distance: 100,
    minMatchCharLength: 1,
    maxPatternLength: 64,
  };
  return fuseOptions;
};

export default {getFuseOptions};
