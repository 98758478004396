import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import CheckboxFlag from "modules/common/BinaryFlag";
import UnitOfMeasure from "./UnitOfMeasure";

const GeneralInformation = ({countries, status, rawMaterialTypes = []}) => {
  const {
    register,
    formState: {errors},
    control,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const productTypeItems = ["Pure Raw material", "Refined Raw Material"];

  const chainOfCustody = ["Mass Balance", "Physical Segregation"];
  const splitDetails = watch("splitDetails");
  const isDisabled = status === "completed" || status === "additional_info";
  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="productType" className="fw-normal">
          Type of product
        </Label>
        <Input
          type="select"
          id="productType"
          data-test="productType"
          {...computeProps("productType", {
            required: "Please enter type of product",
          })}
          invalid={!!errors.productType}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>
          {productTypeItems.map((productTypeItem) => (
            <option key={productTypeItem} value={productTypeItem}>
              {productTypeItem}
            </option>
          ))}
        </Input>
        {errors.productType && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialType" className="fw-normal">
          Type of raw material
        </Label>

        <Input
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          {...computeProps("rawMaterialType", {
            required: "Please enter type of raw material",
          })}
          invalid={!!errors.rawMaterialType}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>

          {rawMaterialTypes.map((p) => (
            <option key={p.issc_desc} value={p.issc_desc}>
              {p.issc_desc}
            </option>
          ))}
        </Input>

        {errors.rawMaterialType && (
          <FormFeedback>{errors.rawMaterialType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfo" className="fw-normal">
          Additional information
        </Label>
        <Input
          type="textarea"
          rows="3"
          id="additionalInfo"
          data-test="additionalInfo"
          {...computeProps("additionalInfo")}
          invalid={!!errors.additionalInfo}
          maxLength={250}
          disabled={isDisabled}
        />
      </FormGroup>
      {(!splitDetails || splitDetails?.length === 0) && (
        <FormGroup>
          <Label for="rawMaterialOriginCountryName" className="fw-normal">
            Country of origin (of the origin raw material)
          </Label>
          <Input
            type="select"
            id="rawMaterialOriginCountryName"
            data-test="rawMaterialOriginCountryName"
            {...computeProps("rawMaterialOriginCountryName", {
              required: "Please enter country of origin",
            })}
            invalid={!!errors.rawMaterialOriginCountryName}
            disabled={isDisabled}
          >
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </Input>
          {errors.rawMaterialOriginCountryName && (
            <FormFeedback>
              {errors.rawMaterialOriginCountryName.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      <FormGroup>
        <Label for="feedstockQty" className="fw-normal">
          Quantity (t)
        </Label>
        <Input
          type="number"
          id="feedstockQty"
          data-test="feedstockQty"
          {...computeProps("feedstockQty", {
            required: "Please enter quantity",
            pattern: {
              value: /^(\d{1,10}(\.\d{1,5})?)$/,
              message:
                "Value must be number with (max 10 digits before and 5 digits after decimal point)",
            },
          })}
          rules={{
            validate: (value) =>
              value > 0 ? true : "Please enter a valid quantity",
          }}
          invalid={!!errors.feedstockQty}
          disabled={isDisabled}
        />

        {errors.feedstockQty && (
          <FormFeedback>{errors.feedstockQty.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="feedstockQtyUom" className="fw-normal">
          Unit of measure
        </Label>
        <UnitOfMeasure
          id="feedstockQtyUom"
          name="feedstockQtyUom"
          control={control}
          error={errors.feedstockQtyUom}
          disabled={isDisabled}
          rules={{
            validate: (value) =>
              value === null || value === ""
                ? "Please select unit of measure"
                : true,
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="isccCompliantFlag" className="fw-normal">
          ISCC compliant material
        </Label>
        <CheckboxFlag
          id="isccCompliantFlag"
          name="isccCompliantFlag"
          control={control}
          error={errors.isccCompliantFlag}
          rules={{
            validate: (value) =>
              value == null ? "Please select an option" : true,
          }}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="euRedCompliantFlag" className="fw-normal">
          EU RED compliant material
        </Label>
        <CheckboxFlag
          id="euRedCompliantFlag"
          name="euRedCompliantFlag"
          control={control}
          error={errors.euRedCompliantFlag}
          rules={{
            validate: (value) =>
              value == null ? "Please select an option" : true,
          }}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="chainOfCustodyOption" className="fw-normal">
          Chain of custody
        </Label>
        <Input
          type="select"
          id="chainOfCustodyOption"
          data-test="chainOfCustodyOption"
          {...computeProps("chainOfCustodyOption")}
          invalid={!!errors.chainOfCustodyOption}
          disabled={isDisabled}
        >
          {chainOfCustody.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
      </FormGroup>
    </div>
  );
};
GeneralInformation.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  rawMaterialTypes: PropTypes.arrayOf(PropTypes.object),
};
export default GeneralInformation;
