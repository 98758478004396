import PropTypes from "prop-types";

const Section = ({children, title, bgColorVariant = "white"}) => (
  <div>
    <section data-test={`section-${title}`}>
      <h2 className="pt-7 pl-7 fs-4 grid justify-items-start mb-4">
        <span data-test="badge-title">{title}</span>
      </h2>
      <div className={`bg-${bgColorVariant}`}>{children}</div>
    </section>
  </div>
);

Section.propTypes = {
  bgColorVariant: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default Section;
