import PropTypes from "prop-types";

const Tab = ({fill}) => {
  return (
    <div
      style={{
        width: 16,
        height: 16,
      }}
    >
      <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.333.165a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333Zm0 5.666a2.167 2.167 0 1 1 0 4.334 2.167 2.167 0 0 1 0-4.334Zm0 5.667a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333ZM8 .165a2.167 2.167 0 1 1 0 4.333A2.167 2.167 0 0 1 8 .165ZM8 5.83a2.167 2.167 0 1 1 0 4.334A2.167 2.167 0 0 1 8 5.83Zm0 5.667a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333ZM13.667.165a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333Zm0 5.666a2.167 2.167 0 1 1 0 4.334 2.167 2.167 0 0 1 0-4.334Zm0 5.667a2.167 2.167 0 1 1 0 4.333 2.167 2.167 0 0 1 0-4.333Z"
          fill={fill}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
};

Tab.propTypes = {
  fill: PropTypes.string.isRequired,
};

export default Tab;
