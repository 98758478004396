export const certificationSystemsDataCommon = [
  {
    id: "ISCC EU",
    label: "ISCC EU",
    templateTypes: [
      {
        id: "ISCC PoS 2.6",
        label: "ISCC PoS 2.6",
      },
      {
        id: "ISCC SD 2.6",
        label: "ISCC SD 2.6",
      },
    ],
  },
  {
    id: "ISCC Plus",
    label: "ISCC Plus",
    templateTypes: [
      {
        id: "BP Marketing",
        label: "BP Marketing",
      },
      {
        id: "ISCC Plus 3.5.1",
        label: "ISCC Plus 3.5.1",
      },
    ],
  },
];

export const certificationSystemsDataTepsa = [
  {
    id: "ISCC EU",
    label: "ISCC EU",
    templateTypes: [
      {
        id: "ISCC SD 2.6",
        label: "ISCC SD 2.6",
      },
    ],
  },
];

export default {certificationSystemsDataCommon, certificationSystemsDataTepsa};
