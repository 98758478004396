import {gql} from "@apollo/client";

// Fetch query for national registry table on Config center(/configuration-center/national-registry)
export const NATIONAL_REGISTRY_GET_QUERY = gql`
  query bioLcGetNationalRegistry {
    bioLcGetNationalRegistry(event: {}) {
      nationalRegistryData {
        countryCode
        countryId
        countryName
        divisionCode
        divisionId
        forestryCriteria
        landCriteria
        materialCode
        systemName
        soilCriteria
        nationalRegistryId
        nationalRegistryCode
        materialLongName
        materialId
      }
    }
  }
`;

export default {NATIONAL_REGISTRY_GET_QUERY};
