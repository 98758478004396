import {ReactTable, Datepicker} from "@bphxd/ds-core-react";
import {Nav, NavItem, NavLink, Input} from "reactstrap";
import {
  creditGenHeaders,
  TABLE_TABS,
  PLACE_HOLDERS,
} from "../../constants/creditGen";
import buildColumns from "../../helpers/buildColumns";
import EMTSSubmissionModal from "./EMTSSubmission/SubmissionModal";
import "./index.scss";

const CreditGenDataView = () => {
  const dataColumns = buildColumns(creditGenHeaders);

  return (
    <div className="credit-gen-table-container">
      <div className="credit-gen-tab-row pl-7">
        <Nav tabs>
          <NavItem>
            <NavLink data-test="credit-gen-nav-link" active>
              {TABLE_TABS.EMTS_SUB}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink data-test="credit-gen-nav-link" disabled>
              {TABLE_TABS.STATE_SUB}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink data-test="credit-gen-nav-link" disabled>
              {TABLE_TABS.COMPLETE}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        data-test="credit-gen-details-toggle-container"
        className="credit-gen-table bg-light p-7"
      >
        <div
          data-test="credit-gen-actions-buttons"
          className="flex justify-between pb-6"
        >
          <div className="flex gap-3 justify-between">
            <div className="max-w-32">
              <Input
                className="credit-gen-status-option"
                type="select"
                disabled
              >
                <option>Statuses</option>
              </Input>
            </div>
            <div className="w-44">
              <Datepicker
                data-test="credit-gen-datepicker"
                className="credit-gen-date-picker"
                placeholder={PLACE_HOLDERS.PRODUCTION_DATE}
                options={{
                  allowInput: false,
                  onChange: () => {},
                }}
                disabled
              />
            </div>
          </div>

          <div className="flex mr-3">
            <EMTSSubmissionModal columnDefs={dataColumns} />
          </div>
        </div>
        <ReactTable
          responsive
          columns={dataColumns}
          data={{}}
          enableSorting
          rowSelection
        />
      </div>
    </div>
  );
};

export default CreditGenDataView;
