import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import {
  BATCH_SUMMARY_COLUMNS,
  OP_CONDITIONS,
} from "modules/co-processing/constants/batches";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import useModal from "modules/co-processing/helpers/useModal";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import EditBatchModal from "./EditModal";
import "./index.scss";

const BatchSummaryDisplay = ({batchDetails, refetch}) => {
  const {toggleModal} = useModal("editBatchModal");

  const [fieldConfig, setFieldConfig] = useState({});
  const [columns, setColumns] = useState(BATCH_SUMMARY_COLUMNS);
  const isByPass = batchDetails.operating_condition === OP_CONDITIONS.BY_PASSED;
  const hasMultipleSampleIds =
    batchDetails?.source_sample_code?.split(",")?.length > 1;

  useEffect(() => {
    if (isByPass) {
      setColumns((prevColumns) =>
        prevColumns.map((col) => {
          if (col.key === "batch_start_time") {
            return {...col, label: "Start time"};
          }
          if (col.key === "batch_end_time") {
            return {...col, label: "End time"};
          }
          return col;
        }),
      );
    }

    if (hasMultipleSampleIds) {
      setColumns((prevColumns) =>
        prevColumns.map((col) => {
          if (col.key === "batch_id") {
            return {...col, editable: true};
          }
          return col;
        }),
      );
    }
  }, [isByPass, hasMultipleSampleIds]);

  const renderFormField = (item) => {
    const value = batchDetails?.[item.key];
    return item.type === "date" ? (
      <span data-test="copro-batch-date-text">
        {value ? displayDate(value) : "N/A"}
      </span>
    ) : (
      <span data-test="copro-batch-text">{value}</span>
    );
  };

  const openModal = (field) => {
    setFieldConfig(() => ({
      ...field,
      batchResourceId: batchDetails?.batch_resource_id,
      value: batchDetails?.[field.key],
      modalOpen: true,
    }));
    toggleModal();
  };

  const getModalHeader = (key) => {
    if (key === "batch_id") return "Change Batch ID";
    if (key === "batch_notes") return "Edit note";

    return `Edit ${key?.replace(/_/g, " ")}`;
  };

  return (
    <div className="grid grid-cols-2 gap-4 pt-7 pb-8">
      {columns.map((field) => (
        <div key={field.key} className="pb-10">
          <div data-test="batch-detail-body-item" className="text-xs pb-4">
            <span className="batch-input-container">
              {field.label}
              {field.editable && (
                <Edit24
                  data-test="batch-detail-edit-icon"
                  className="cursor-pointer"
                  onClick={() => openModal(field)}
                />
              )}
            </span>
          </div>
          <div className="text-xl">{renderFormField(field)}</div>
        </div>
      ))}
      <EditBatchModal
        details={fieldConfig}
        refetch={refetch}
        onClose={() => {
          setFieldConfig((prevDetails) => ({
            ...prevDetails,
            modalOpen: false,
          }));
          toggleModal();
        }}
        modalHeader={getModalHeader(fieldConfig.key)}
        label={fieldConfig.label}
        field={fieldConfig}
        batchDetails={batchDetails}
      />
    </div>
  );
};

BatchSummaryDisplay.propTypes = {
  batchDetails: PropTypes.object,
  refetch: PropTypes.func,
};

export default BatchSummaryDisplay;
