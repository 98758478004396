import React from "react";
import "./index.scss";

const Empty = () => <div className="checklist-icon" data-test="empty"></div>;

const PartialIcon = () => {
  return (
    <div className="checklist-icon partial" data-test="partial-icon">
      <svg width="16" height="8" viewBox="0 8 16 8" data-test="partial-check">
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M0 0h16v16H0z"></path>
          <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
        </g>
      </svg>
    </div>
  );
};

const SuccessIcon = () => {
  return (
    <div className="checklist-icon success" data-test="success-icon">
      <svg width="16" height="16" viewBox="0 0 16 16" data-test="success-check">
        <g fill="none" fillRule="evenodd">
          <path fill="none" d="M0 0h16v16H0z"></path>
          <path d="M3.8 8.6 6.2 11l6-6" stroke="currentColor"></path>
        </g>
      </svg>
    </div>
  );
};

export {Empty, PartialIcon, SuccessIcon};
