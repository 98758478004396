import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext, Controller} from "react-hook-form";
import {Steps, Step} from "@bphxd/ds-core-react";

const StepsAssignToSale = ({activeStep, setActiveStep}) => {
  const {
    formState: {errors},
  } = useFormContext();

  const [cerificationValidationStatus, setCerificationValidationStatus] =
    useState("normal");

  const [ProductValidationStatus, setProductValidationStatus] =
    useState("normal");

  const [RecipientValidationStatus, setRecipientValidationStatus] =
    useState("normal");

  useEffect(() => {
    if (errors.certificationSystem || errors.templateType || errors.issueDate) {
      setCerificationValidationStatus("error");
    } else {
      setCerificationValidationStatus("normal");
    }
  }, [errors, errors.certificationSystem, errors.templateType]);

  useEffect(() => {
    if (
      errors.productType ||
      errors.uom ||
      errors.quantity ||
      errors.fossilFuelComparator
    ) {
      setProductValidationStatus("error");
    } else {
      setProductValidationStatus("normal");
    }
  }, [
    errors,
    errors.productType,
    errors.uom,
    errors.quantity,
    errors.fossilFuelComparator,
  ]);

  useEffect(() => {
    if (
      errors.recipientName ||
      errors.recipientAddress ||
      errors.recipientReceiptAddress ||
      errors.modeOfTransportation ||
      errors.vesselName ||
      errors.dispatchDate
    ) {
      setRecipientValidationStatus("error");
    } else {
      setRecipientValidationStatus("normal");
    }
  }, [
    errors,
    errors.recipientName,
    errors.recipientAddress,
    errors.recipientReceiptAddress,
    errors.modeOfTransportation,
    errors.vesselName,
    errors.dispatchDate,
  ]);

  return (
    <div className="my-3">
      {" "}
      <Steps activeStep={activeStep}>
        <Step
          label="Certification"
          onClick={() => setActiveStep(0)}
          status={cerificationValidationStatus}
        />
        <Step
          label="Product"
          onClick={() => setActiveStep(1)}
          status={ProductValidationStatus}
        />
        <Step
          label="Recipient"
          onClick={() => setActiveStep(2)}
          status={RecipientValidationStatus}
        />
      </Steps>
    </div>
  );
};

StepsAssignToSale.propTypes = {
  activeStep: PropTypes.string,
  setActiveStep: PropTypes.func,
};

export default StepsAssignToSale;
