import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const REVERT = gql`
  mutation Revert(
    $inboundEuQtyId: String!
    $outboundEuQtyId: String!
    $sdNumber: String!
    $createdBy: String!
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcCoproEuMassBalanceRevert(
      event: {
        inboundEuQtyId: $inboundEuQtyId
        outboundEuQtyId: $outboundEuQtyId
        sdNumber: $sdNumber
        createdBy: $createdBy
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      message
      status
      statusCode
    }
  }
`;

export const RevertAPI = (data) =>
  client.mutate({
    mutation: REVERT,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuMassBalanceAllRecords"],
  });

export default {
  REVERT,
  RevertAPI,
};
