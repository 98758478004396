export const docStatus = {
  ready_to_assign: {
    message: "Ready to assign",
    className: " disabled:text-white bg-[#ECFAFE]",
  },
  requires_attention: {
    message: "Requires attention",
    className: "disabled:text-white bg-[#FFEDF0]",
  },
  ready_to_accept: {
    message: "Ready to accept",
    className: "disabled:text-white bg-[#FFFFF0]",
  },
  rejected: {
    message: "Rejected",
    className: "disabled:text-white bg-[#ededed]",
  },
  failed_to_process: {
    message: "Rejected",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  assigned: {
    message: "Completed",
    className: "disabled:text-white bg-[#E6FFF2]",
  },
  additional_info: {
    message: "Additional info",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  attention: {
    message: "Attention",
    className: "disabled:text-white bg-[#e6fff2]",
  },
  completed: {
    message: "Completed",
    className: "disabled:text-white bg-[#E6FFF2]",
  },
};

export default {docStatus};
