import React from "react";
import PropTypes from "prop-types";
import {WarningOutlined} from "@ant-design/icons";
import "./index.css";

const InvoicePreviewContent = ({documentResponse, invoiceDetails, loading}) => {
  return (
    <div className="w-full h-full">
      {documentResponse?.url === "" && documentResponse?.url_download === "" ? (
        <div
          data-test="error-content"
          className="flex flex-col items-center justify-center w-full h-full bg-white"
        >
          <WarningOutlined
            className="flex items-center"
            style={{color: "gray", fontSize: 50}}
          />
          <div className="items-center text-xl">
            No pdf found for this record!
          </div>
        </div>
      ) : (
        <div className="w-full h-full">
          <iframe
            hidden={
              loading ||
              (documentResponse?.url === "" &&
                documentResponse?.url_download === "")
            }
            className="flex w-full h-full"
            key="invoice-preview-content"
            data-test="cherrypoint-dashboard-invoice-popup-modal-iframe"
            width="100%"
            border="0"
            height="100%"
            src={documentResponse?.url}
            title={invoiceDetails.itemDescription}
          ></iframe>
        </div>
      )}
    </div>
  );
};

InvoicePreviewContent.propTypes = {
  documentResponse: PropTypes.object,
  invoiceDetails: PropTypes.object,
  loading: PropTypes.bool,
};

export default InvoicePreviewContent;
