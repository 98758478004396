import React from "react";
import PropTypes from "prop-types";
import tw, {styled} from "twin.macro";

const FilterWrapper = ({children}) => (
  <StyledFilterSection data-test="forecast-filter-section">
    <GreenGradientBackground />
    <StyledBarWrapper>
      <StyledFilterBar data-test="forecast-filter-bar">
        {children}
      </StyledFilterBar>
    </StyledBarWrapper>
  </StyledFilterSection>
);

export default FilterWrapper;

FilterWrapper.propTypes = {
  children: PropTypes.node,
};

const StyledFilterSection = tw.div`relative bg-gray-50`;

const GreenGradientBackground = styled.div`
  background-image: linear-gradient(
    to right,
    #408b40 -1%,
    #49a147 62%,
    #c8d500 112%
  );

  ${tw`absolute left-0 right-0 z-0 h-36`};
`;

const StyledBarWrapper = tw.div`relative pt-10 px-6 lg:px-8`;

const StyledFilterBar = tw.div`
  bg-white rounded-md shadow-xl
  content-center justify-evenly
  pt-8 pb-10 px-6
  md:flex-row flex flex-col flex-wrap
`;
