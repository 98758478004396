import PropTypes from "prop-types";
import {Card, CardBody} from "reactstrap";
import {
  SHIPPING_CARD_COLUMNS,
  SHIPMENTS_LABEL_COUNT,
} from "modules/co-processing/constants/batches";
import DataGridItem from "./DataGridItem";

const ShippingDetailsCard = ({title, testId, shipments}) => {
  return (
    <div className="mb-8" data-test={testId}>
      <Card className="card-border rounded-6">
        <CardBody>
          <div className="text-[26px]">{title}</div>
          <div className="grid grid-cols-2 gap-20">
            {shipments?.map((shipment, index) => (
              <div data-test="data-grid" key={index}>
                <div className="text-sm pt-6 pb-2 border-b-2">{`${SHIPMENTS_LABEL_COUNT} ${
                  index + 1
                }`}</div>
                <div data-test="data-grid-column">
                  {SHIPPING_CARD_COLUMNS.map((item, index) => (
                    <DataGridItem
                      key={index}
                      data={shipment?.[item.key] ?? ""}
                      metric={item.metric ?? ""}
                      label={item.label}
                      type={item.type}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

ShippingDetailsCard.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
  shipments: PropTypes.array,
};

export default ShippingDetailsCard;
