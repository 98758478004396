import React from "react";
import {Route, Routes, Outlet} from "react-router-dom";
import ForecastPageRootLayout from "modules/forecasting/components/ForecastingRootPageLayout";
import InventoryData from "modules/forecasting/pages/west-coast/WestCoastData";
import InventoryUnpublished from "modules/forecasting/pages/west-coast/WestCoastUnpublished";
import InventoryPublished from "modules/forecasting/pages/west-coast/WestCoastPublished";
import ECData from "modules/forecasting/pages/east-coast/EastCoastData";
import ECUnpublished from "modules/forecasting/pages/east-coast/EastCoastUnpublished";
import ECPublished from "modules/forecasting/pages/east-coast/EastCoastPublished";
import NotFoundPage from "modules/common/ErrorPages/NotFoundPage";
import {ForecastingFiltersProvider} from "modules/forecasting/context/ForecastingFilterContext";
import {ForecastingDateProvider} from "modules/forecasting/context/ForecastingDateContext";
import LandingPage from "./pages/landing-page";
import {ForecastingFeatureFlagProvider} from "./context/ForecastingFeatureFlag";

export default () => (
  <ForecastingFeatureFlagProvider>
    <ForecastingDateProvider>
      <ForecastingFiltersProvider>
        <Routes>
          <Route element={<ForecastPageRootLayout />}>
            <Route index element={<LandingPage />} />
            <Route path="/ec-forecast-data" element={<ECData />} />
            <Route
              path="/ec-forecast-unpublished"
              element={<ECUnpublished />}
            />
            <Route path="/ec-forecast-published" element={<ECPublished />} />
            <Route path="/wc-forecast-data" element={<InventoryData />} />
            <Route
              path="/wc-forecast-unpublished"
              element={<InventoryUnpublished />}
            />
            <Route
              path="/wc-forecast-published"
              element={<InventoryPublished />}
            />
          </Route>

          <Route path="*" element={<NotFoundPage />} />
        </Routes>

        <Outlet />
      </ForecastingFiltersProvider>
    </ForecastingDateProvider>
  </ForecastingFeatureFlagProvider>
);
