import moment from "moment-timezone";
import {NO_BATCH_AVAILABLE} from "modules/co-processing/constants/batches";

const convertToISO = (date) => moment(date).format();
const convertPSTtoUTC = (dateObj) => {
  if (dateObj === null) return null;
  const date = new Date(dateObj);

  const invdate = new Date(
    date.toLocaleString("en-US", {
      timeZone: "US/Pacific",
    }),
  );

  const diff = date.getTime() - invdate.getTime();
  return moment(new Date(date.getTime() + diff)).utc();
};

const convertUTCtoPST = (date) => moment(date).tz("US/Pacific");
const convertToString = (date) => moment(date).format("YYYY-MM-DD");

const setInitialData = (data, inputs) => {
  const initialData = {};
  inputs.forEach(({key}) => {
    let dataObject = data[key] ?? "";
    if (key === "date_received" || key === "date_submitted") {
      dataObject = convertToISO(data[key]);
    }
    initialData[key] = dataObject;
  });
  return initialData;
};

const setInitialErrors = (data, inputs) => {
  const initialErrors = {};
  inputs.forEach(({key}) => {
    initialErrors[key] = !data[key];
  });
  return initialErrors;
};

const getPDFErrors = (data, inputs) => {
  const pdfErrors = [];
  inputs.forEach(({key}) => {
    if (!data[key]) pdfErrors.push(key);
  });
  return pdfErrors;
};

const getUpdates = (pdfErrors, formData) => {
  const updates = [];
  pdfErrors.forEach((key) =>
    updates.push({
      column: key,
      value: formData[key],
    }),
  );
  return updates;
};

const getErrorState = (errors, updatedKey, updatedValue) => {
  const updatedErrors = errors;
  if (updatedKey) updatedErrors[updatedKey] = updatedValue === "";
  const remainingErrors = Object.keys(updatedErrors).filter(
    (key) => updatedErrors[key],
  );
  return remainingErrors.length > 0;
};

const getBatchIDs = (batchIds) => {
  const ids = batchIds?.map((item) => item.batch_id) ?? [];
  ids.push(NO_BATCH_AVAILABLE);
  return ids;
};

const isBatchIdUnavailable = (formData) =>
  formData?.batch_id === NO_BATCH_AVAILABLE;

export {
  setInitialErrors,
  setInitialData,
  getErrorState,
  getUpdates,
  getPDFErrors,
  convertToString,
  convertToISO,
  convertPSTtoUTC,
  convertUTCtoPST,
  getBatchIDs,
  isBatchIdUnavailable,
};
