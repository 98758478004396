import {useLocation, useNavigate} from "react-router-dom";
import Select from "modules/common/Select";
import {countryData} from "./PoSCountryData";

const getCountry = (path) => {
  const pathMap = {
    "/pos-management/uk": "UK",
    "/pos-management/nl": "NL",
    "/pos-management/nl/nabisy": "NL",
    "/pos-management/pl": "PL",
    "/pos-management/lux": "LU",
  };

  return pathMap[path] || "";
};

const navigatePage = (navigate, value) => {
  const navigationMap = {
    UK: "/pos-management/uk",
    NL: "/pos-management/nl",
    PL: "/pos-management/pl",
    LU: "/pos-management/lux",
  };

  navigate(navigationMap[value]);
};

const CountryDropdown = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {pathname} = location;
  return (
    <div className="flex flex-wrap">
      <Select
        value={getCountry(pathname)}
        defaultActiveFirstOption
        showArrow
        style={{width: 162}}
        data-test="dropdown-pos-selection"
        size="small"
        onChange={(val) => {
          navigatePage(navigate, val);
        }}
      >
        {countryData?.map((item) => (
          <Select.Option
            data-test={`country-${item.title}`}
            key={item.value}
            value={item.value}
          >
            <div className="flex items-center min-w-max gap-x-2">
              <img className="w-5 h-5" src={item.icon} alt={item.value} />
              {item.title}
            </div>
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

CountryDropdown.propTypes = {};

export default CountryDropdown;
