import * as yup from "yup";

export const masterNETSCO2CostsSchemaAddEdit = yup
  .object()
  .shape({
    companyCode: yup.string().label("Company code").required(),
    complianceYear: yup.string().label("Compliance Year").required(),
    certificateCosts: yup.string().label("Certificate Costs").required(),
    // description: yup.string().label("Description").max(500),
  })
  .required();

export const masterNETSCO2CostsSchemaDelete = yup.object().shape({
  reason: yup.string().label("Comments").max(250),
});
