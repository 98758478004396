import {styled} from "twin.macro";
import {Button} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {Trash24} from "@bphxd/ds-core-react/lib/icons";
import {BUTTONS, COPRO_URL} from "modules/co-processing/constants/coProcessing";

const StyledButton = styled(Button)`
  border-color: black;
  border-radius: 0;
  margin-left: auto;
  &:hover {
    color: white;
    background-color: black;
  }
`;

const RecycleBinButton = () => {
  const navigate = useNavigate();

  return (
    <StyledButton
      color="tertiary"
      onClick={() => navigate(`${COPRO_URL}/shipments/recycle-bin/`)}
      type="button"
    >
      <span className="mt-1">{BUTTONS.RECYCLE_BIN}</span>
      <Trash24 />
    </StyledButton>
  );
};
export default RecycleBinButton;
