import PropTypes from "prop-types";
import {Spinner} from "reactstrap";
import "./index.scss";

const InventoryWrapper = ({children, loading = false}) => (
  <div
    data-test="inventory-wrapper"
    className="flex flex-col px-6 gap-2 pb-10 lg:px-10"
  >
    {loading ? (
      <div className="pt-10">
        <Spinner
          style={{width: "6rem", height: "6rem"}}
          className="flex mx-auto"
        />
      </div>
    ) : (
      <div>{children}</div>
    )}
  </div>
);

InventoryWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
};

export default InventoryWrapper;
