import {gql} from "@apollo/client";

// Fetch query for production emission table on Config center(/configuration-center/product-emissions)
export const GET_PRODUCTION_EMISSION_NEW_QUERY = gql`
  query bioLcGetProductionEmissionNew {
    bioLcGetProductionEmissionNew(event: {}) {
      error
      statusCode
      productionEmissionNewResponse {
        countryCode
        countryId
        countryName
        divisionCode
        divisionId
        divisionName
        emissionFactor
        emissionUom
        locationCode
        locationId
        locationLongName
        locationShortName
        locationType
        processingUnitCode
        processingUnitId
        validFrom
        validStatus
        validTo
      }
    }
  }
`;

export default {GET_PRODUCTION_EMISSION_NEW_QUERY};
