import React from "react";
import PropTypes from "prop-types";
import FormField from "modules/common/FormField";
import Select from "modules/common/Select";

const ValueTypeDropdown = ({dropdownValue, currentMonth, onTypeChange}) => (
  <FormField
    className="w-fit mt-8"
    data-test="dropdown-value-type"
    label={`How would you like to publish your ${currentMonth} edits?`}
  >
    <Select
      data-test="input-value-type"
      defaultValue="forecast"
      onChange={onTypeChange}
      options={[
        {value: "forecast", label: "Forecast"},
        {value: "true-up", label: "True-up"},
      ]}
      value={dropdownValue}
    />
  </FormField>
);

ValueTypeDropdown.propTypes = {
  dropdownValue: PropTypes.string,
  currentMonth: PropTypes.string,
  onTypeChange: PropTypes.func,
};

export default ValueTypeDropdown;
