import {styled} from "twin.macro";
import PropTypes from "prop-types";
import {Table} from "antd";
import {formatMonthNameShort as formatMonth} from "modules/forecasting/helpers/dateUtils";
import {
  TABLE_TITLE,
  LandingTableLabels,
} from "modules/forecasting/constants/inventory";
import EmptyData from "modules/forecasting/components/EmptyData";
import {creditsFieldType, deficitsFieldType} from "./utils";

const getColumns = (dateList, type) => {
  const baseColumn = [
    {
      align: "left",
      dataIndex: "label",
      title: TABLE_TITLE.getTitle(type),
      key: "measurementLabel",
      fixed: "left",
      width: type === "credits" ? 250 : 300,
    },
  ];

  dateList.forEach((dataIndex, i) => {
    baseColumn.push({
      key: i,
      align: "right",
      dataIndex,
      onCell: (record) => ({
        "data-test": record.key + "-" + dataIndex,
      }),
      render: (_, record) => record?.[dataIndex]?.toLocaleString() ?? "",
      title: formatMonth(dataIndex),
    });
  });

  return baseColumn;
};

/**
 * @param {creditsFieldType | deficitsFieldType} yearData
 * @param {string|null} categoryKey Credit: d4_total | d5_total | d6_total | total_obligation & Deficit: gas_total |total_diesel | total_obligation.
 * @return {object} Returns a table record object with key as the month ID & value as published actuals or forecast for future months example:
 *  { 2024-9: 5000, 2024-10: 9000, key: d4_total, label: "D4 - Biomass-Based Diesel" }
 */
const pageDataToRows =
  (yearData) =>
  ([categoryKey, label]) => {
    const totalsByMonth = yearData.reduce((acc, {date, ...monthTotals}) => {
      return {
        ...acc,
        [`${date.year}-${date.month}`]:
          monthTotals?.[categoryKey]?.actualValue ?? 0,
      };
    }, {});

    return {
      ...totalsByMonth,
      key: categoryKey,
      label,
    };
  };

const TotalValuesTable = ({loading, pageData, forecastType = "credits"}) => {
  const categoryKeyLabelPairs =
    Object.entries(LandingTableLabels?.[forecastType?.toUpperCase()]) ?? [];

  if (!pageData?.length) {
    return <EmptyData />;
  }

  return (
    <StyledTable
      columns={getColumns(
        pageData.map(({date} = {}) => `${date.year}-${date.month}`),
        forecastType,
      )}
      data-test="total-values-table"
      dataSource={categoryKeyLabelPairs.map(pageDataToRows(pageData)) ?? []}
      loading={loading}
      pagination={false}
      scroll={{x: "max-content"}}
      className="total-table"
    />
  );
};

export default TotalValuesTable;

TotalValuesTable.propTypes = {
  loading: PropTypes.bool,
  forecastType: PropTypes.any,
  pageData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape(creditsFieldType),
      PropTypes.shape(deficitsFieldType),
    ]),
  ),
};

const theadBorderRadius = 2;
const tbodyBorderRadius = 2;
const spaceBorderColor = "#D2D2D4";
const backgroundColor = "#fff";
const headerBackgroundColor = "#EDEDED";

const StyledTable = styled(Table)`
  &.ant-table-wrapper {
    background: ${backgroundColor};
    padding: 0px;
    border: none;
    border-collapse: collapse;

    .ant-table-thead {
      > tr {
          th {
            background: ${headerBackgroundColor};
            border-bottom: 1px solid ${spaceBorderColor};
            font-family: "Roboto Black", sans-serif;
            text-transform: uppercase;
            padding: 5px;
            font-weight: 700 !important;
            font-size: 14px !important;

            &:nth-of-type(2) {  
              padding-left: 0px;  
            }

            &:first-of-type {
              text-transform: capitalize;
              font-size: 24px !important;
              text-align: left;
              padding-left: 15px;
              padding-right: 0px;
              border-top-left-radius: ${theadBorderRadius}px;
              border-bottom-left-radius: ${theadBorderRadius}px;
              white-space: nowrap;
            }

            &:last-of-type {
              padding-right: 24px;
              border-top-right-radius: ${theadBorderRadius}px;
              border-bottom-right-radius: ${theadBorderRadius}px;
            }

          }
          
           > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
            display: none;
          }
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          font-size: 14px;
          background: ${backgroundColor};
          border-bottom: 1px solid ${spaceBorderColor};
          min-width: 100px;
          padding-left: 30px;

          &:first-of-type {
            font-size: 15px;
            color: #3f3e45;
            font-weight: 900;
            padding-left: 15px;
            white-space: nowrap;
            border-top-left-radius: ${tbodyBorderRadius}px;
            border-bottom-left-radius: ${tbodyBorderRadius}px;
          }

          &:nth-of-type(2) {  
            padding-left: 0px;  
          }

          &:last-child {
            padding-right: 20px;
            border-top-right-radius: ${tbodyBorderRadius}px;
            border-bottom-right-radius: ${tbodyBorderRadius}px;
          }
        }

        &:last-child {
          td {
            color: #3f3e45;
            font-weight: bold;
            font-family: "Roboto Black",sans-serif;

            &:first-of-type {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
`;
