import {gql} from "@apollo/client";

const GET_ALL_RECORDS = gql`
  query bioLcCoproEuMassBalanceAllRecords(
    $mbLocationCode: String
    $materialCode: String
    $mbCycle: String
    $filterStatus: [String]
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcCoproEuMassBalanceAllRecords(
      event: {
        mbLocationCode: $mbLocationCode
        materialCode: $materialCode
        mbCycle: $mbCycle
        filterStatus: $filterStatus
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      error
      statusCode
      data {
        inboundRecord {
          appliedProcessingUnit
          transportationLossEnableFlag
          yieldEnableFlag
          inboundEuQtyId
          certificateInboundEuId
          sdNumber
          sdNumberSplit
          feedstockQty
          mbCycle
          mbLocation
          mbLocationId
          mbLocationCode
          originCountryName
          ghgEec
          ghgEl
          ghgEp
          ghgEtd
          ghgEu
          ghgEsca
          ghgEccs
          ghgEccr
          ghgEee
          ghgTotal
          ghgEmissionIn
          ghgEmissionPercentageIn
          ghgEmissionOut
          ghgEmissionPercentageOut
          bioProductStatus
          physicalReceiptDate
          qtyProcessedIn
          qtyProcessedOut
          materialCode
          supplierAddress
          supplierName
          appliedYieldRatio
          appliedYieldType
          fromLocationId
          fromLocationCode
          calculationSteps
          inboundDocStatusId
          motName
          vesselName
          unitToBeDisplayed
          transportationLossFactor
        }
        outboundRecords {
          outboundEuQtyId
          sdNumber
          sdNumberSplit
          fromLocationId
          fromLocationCode
          recipientName
          recipientAddress
          recipientReceiptAddress
          physicalReceiptDate
          dispatchDate
          certificationSystem
          certificateNumber
          contractNumber
          productType
          uomCode
          ghgEec
          ghgEl
          ghgEp
          ghgEtd
          ghgEu
          ghgEsca
          ghgEccs
          ghgEccr
          ghgEee
          ghgTotal
          ghgEmissionIn
          ghgEmissionPercentageIn
          ghgEmissionOut
          ghgEmissionPercentageOut
          productStatus
          qtyAllocated
          qtyAllocatedMt
          quantityInM3
          materialId
          materialCode
          motName
          motOut
          saleYear
          saleQtr
          saleMonth
          saleStatus
          certificateUrlPdf
          certificateUrlXls
          templateType
          remarks
          calculationSteps
          unitToBeDisplayed
          issueDate
          ghgSavingPotential
          savingPotentialTooltip
        }
      }
    }
  }
`;

export default GET_ALL_RECORDS;
