import Button from "DLS/Button";
import {UploadOutlined} from "@ant-design/icons";
import {useCallback, useRef, useState} from "react";
import {toast} from "react-toastify";
import {MdFileUpload, MdOutlineFileUpload} from "react-icons/md";
import {PoSErrorModal} from "../StatusModals/ErrorModal";
import LoadingModal from "../StatusModals/LoadingModal";
import SuccessModal from "../StatusModals/SuccessModal";
import PreviewModal from "./PreviewModal";

export default (onSubmit, onUploadCompleted, inputProps = {}) => {
  // TODO: Want to use this component in batches?
  // You can add a onValidate prop that must return a text, that
  // text could be shown as text on the box and disable the submit button.
  const inputRef = useRef(null);
  const [file, setFile] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleFileChange = useCallback((e) => {
    if (!e.target.files.length) {
      return;
    }
    setFile(e.target.files[0]);
    e.target.value = null;
    setPreviewVisible(true);
  }, []);

  const handleButtonClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      // Must return just a status code (number)
      const statusCode = await onSubmit(file);

      switch (statusCode) {
        case 200:
        case 204:
          // Succesfully
          onUploadCompleted();
          setShowSuccessModal(true);
          break;
        default:
          // Failed
          setShowErrorModal(true);
      }
    } catch (err) {
      console.error(err);
      // Unexpected error
      setShowErrorModal(true);
    }
    setPreviewVisible(false);
    setSubmitting(false);
    setFile(undefined);
  };

  const markup = (
    <>
      <SuccessModal
        title="Successfully Imported"
        visible={showSuccessModal}
        description="Thank You. Your file was uploaded successfully"
        showCrossArrow={false}
        backLink="/pos-management/uk"
        onCancel={() => setShowSuccessModal(false)}
        onBack={() => setShowSuccessModal(false)}
        onUpload={() => {
          setShowSuccessModal(false);
          handleButtonClick();
        }}
      />
      <PoSErrorModal
        title="Error"
        visible={showErrorModal}
        onCancel={() => setShowErrorModal(false)}
      />
      <LoadingModal title="Upload in progress" visible={submitting} />
      <PreviewModal
        file={file}
        onCancel={() => setPreviewVisible(false)}
        onSubmit={handleSubmit}
        visible={previewVisible}
        submitting={submitting}
      />
      <input
        data-test="hidden-file-input"
        ref={inputRef}
        type="file"
        onChange={handleFileChange}
        accept="application/pdf"
        multiple={false}
        {...inputProps}
        hidden
      />
      <Button
        data-test="upload-button"
        size="medium"
        icon={<MdOutlineFileUpload />}
        onClick={handleButtonClick}
      >
        Upload document
      </Button>
    </>
  );

  // Use handleButtonClick for custom buttons
  return [markup, handleButtonClick];
};
