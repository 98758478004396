import React from "react";
import PropTypes from "prop-types";
import {Table} from "antd";
import {generateToPublishTableData} from "modules/forecasting/helpers/generateToPublishTableData";

const ToPublishModalTable = ({
  monthData,
  obligation,
  keyValuePairs,
  checkboxState,
  handleChange,
  handleChangeMonth,
  label,
}) => {
  const {columns, dataSource} = generateToPublishTableData(
    monthData,
    keyValuePairs,
    checkboxState,
    obligation,
    handleChange,
    handleChangeMonth,
  );

  const locale = {
    emptyText: "No data",
  };

  return (
    <div className="border-1 rounded-lg col-span-2" key={obligation}>
      <div className="flex flex-col justify-between px-5 py-4 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
        <p className="table-title font-bold">{label}</p>
      </div>
      <Table
        locale={locale} // Displays "No data" message instead of an empty box with icon
        data-test={`to-publish-table-${obligation}`}
        columns={dataSource.length ? columns : []}
        dataSource={dataSource}
        pagination={false}
        scroll={{x: "max-content"}}
      />
    </div>
  );
};

ToPublishModalTable.propTypes = {
  monthData: PropTypes.arrayOf(
    PropTypes.shape({
      monthName: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      year: PropTypes.number.isRequired,
      month: PropTypes.number.isRequired,
    }),
  ).isRequired,
  obligation: PropTypes.string,
  keyValuePairs: PropTypes.object,
  checkboxState: PropTypes.object,
  handleChange: PropTypes.func,
  handleChangeMonth: PropTypes.func,
  label: PropTypes.string,
};

export default ToPublishModalTable;
