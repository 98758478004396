import moment from "moment";

const BooleanMap = {true: "Yes", false: "No"};
const DATE_FORMAT = "DD/MM/YYYY hh:mm A";

const handleNullOrUndefined = (value, formatterFn = (v) => v) =>
  value == null || value === undefined ? "N/A" : formatterFn(value);

const formatDate = (value) =>
  moment(value, DATE_FORMAT, true).isValid()
    ? value
    : moment(value).format(DATE_FORMAT);

const booleanToYesOrNo = (value) => BooleanMap[value.toLowerCase()] || value;

const formatNumber = (value) =>
  typeof value === "number"
    ? new Intl.NumberFormat("en-US").format(Math.round(value))
    : value;

const format = {
  bool: (value) => handleNullOrUndefined(value, booleanToYesOrNo),
  date: (value) => handleNullOrUndefined(value, formatDate),
  number: (value) => handleNullOrUndefined(value, formatNumber),
  default: handleNullOrUndefined,
};

const dateTimeKeysList = [
  "batch_start_time",
  "batch_end_time",
  "tank_certified_datez",
  "c14_test_date",
  "shipment_date",
  "load_start_time",
  "load_end_time",
];

const changeDateFormat = (value, outputFormat) => {
  const dateTimeObj = moment(value, DATE_FORMAT, true);
  return dateTimeObj.isValid() ? dateTimeObj.format(outputFormat) : value;
};

export const RECALL_POPOVER = {
  HEADER: "Confirm Recall",
  MESSAGE:
    "Are you sure you want to move this batch and shipment link back to Ready to Commit status?",
  YES: "Confirm",
  CANCEL: "Cancel",
};

export const ERRORS = {
  FAILED_RECALL:
    "Failed to move the batch and shipment link back to Ready to Commit status",
};

export {
  changeDateFormat,
  dateTimeKeysList,
  format,
  formatDate,
  handleNullOrUndefined,
};
