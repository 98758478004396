export const MODAL_TITLE = "Request revision";

export const MODAL_BODY = {
  line1:
    "Download the revision document to specify your changes, then email them to gbioversedataenablement@bp.com",
  line2: "If approved, you will receive confirmation within 24 hours.",
};

export const MODAL_BUTTON = {
  primaryAction: "Download revision document",
  secondaryAction: "Cancel",
};
