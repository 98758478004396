/* eslint-disable import/prefer-default-export */
// More to add

import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const GET_TAX_PLANT_REGISTRY = gql`
  query GetTaxPlantRegistryQueryAurora {
    getTaxPlantRegistryAurora {
      data {
        id
        companyCode
        eDGroup
        eDGroupName
        legalEntity
        mot
        plant
        registrationNumber
        taxLiability
        plantName
        mainCustomsOffice
      }
      statusCode
      errors {
        code
        message
      }
    }
  }
`;

export const CREATE_TAX_PLANT_REGISTRY = gql`
  mutation CreateTaxPlantRegistryAurora(
    $record: TaxPlantRegistryAuroraRequest!
  ) {
    createTaxPlantRegistryAurora(event: $record) {
      data {
        taxLiability
        registrationNumber
        legalEntity
        mot
        eDGroup
        companyCode
        plant
        mainCustomsOffice
      }
      statusCode
      errors {
        code
        message
      }
    }
  }
`;

export const UPDATE_TAX_PLANT_REGISTRY = gql`
  mutation UpdateTaxPlantRegistryAurora(
    $record: updateTaxPlantRegistryAuroraRequest!
  ) {
    updateTaxPlantRegistryAurora(event: $record) {
      data {
        taxLiability
        registrationNumber
        legalEntity
        mot
        eDGroup
        companyCode
        plant
        mainCustomsOffice
      }
      statusCode
      errors {
        code
        message
      }
    }
  }
`;

export const DELETE_TAX_PLANT_REGISTRY = gql`
  mutation DeleteTaxPlantRegistryAurora(
    $record: deleteTaxPlantRegistryAuroraRequest!
  ) {
    deleteTaxPlantRegistryAurora(event: $record) {
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const GET_PLANT_REGISTRY_NUMBERS = gql`
  query GetExcerciseRegistryRequest {
    getExcerciseRegistryRequest {
      plantCode
      plantName
      registryNumber
    }
  }
`;

export const deleteTaxPlantRegistryApi = (data) =>
  client.mutate({
    mutation: DELETE_TAX_PLANT_REGISTRY,
    variables: {
      record: data,
    },
  });

export const createTaxPlantRegistryApi = (data) =>
  client.mutate({
    mutation: CREATE_TAX_PLANT_REGISTRY,
    variables: {
      record: data,
    },
    refetchQueries: ["GetTaxPlantRegistryQueryAurora"],
  });

export const updateTaxPlantRegistryApi = (data) =>
  client.mutate({
    mutation: UPDATE_TAX_PLANT_REGISTRY,
    variables: {
      record: data,
    },
    refetchQueries: ["GetTaxPlantRegistryQueryAurora"],
  });
