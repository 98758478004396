import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects from "./validationError";

const WriteData = ({
  selectedItem,
  writeDatasetList,
  selectedDataset,
  selectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchWriteDataDataset = watch("table_name");
  const watchWriteDataResultColumn = watch("result_column");
  const configurationData = selectedTemplateItems.map(
    (item) => item.configuration,
  );

  const outputColumns = configurationData
    ?.map((config) => config?.output_column)
    ?.filter(
      (output) => output !== undefined && output !== null && output !== "",
    );

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      table_name: watchWriteDataDataset,
      result_column: watchWriteDataResultColumn,
    });
  }, [
    selectedItem,
    watchWriteDataDataset,
    watchWriteDataResultColumn,
    updateConfigurationInBlocks,
  ]);

  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      setValue("table_name", selectedItem?.configuration?.table_name);
      setValue("result_column", selectedItem?.configuration?.result_column);
    } else {
      setValue("table_name", "");
      setValue("result_column", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });
  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="table_name" className="fw-normal mb-4">
          Dataset
        </Label>
        <Input
          type="select"
          id="table_name"
          data-test="table_name"
          {...computeProps("table_name", {
            required: "Please select a dataset",
          })}
          invalid={!!errors.table_name}
          disabled={isDisabled}
        >
          <option value="">Select dataset</option>
          {writeDatasetList?.map((datasetItem) => (
            <option key={datasetItem?.dataset} value={datasetItem?.dataset}>
              {datasetItem?.dataset}
            </option>
          ))}
        </Input>
        {errors.table_name && (
          <FormFeedback className="mt-2">
            {errors.table_name?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="result_column" className="fw-normal mb-4">
          Result Column
        </Label>
        <Input
          type="select"
          id="result_column"
          data-test="result_column"
          {...computeProps("result_column", {
            required: "Please select a result column",
          })}
          invalid={!!errors.result_column}
          disabled={isDisabled}
        >
          <option value="">Select column</option>
          {outputColumns?.map((outputColumn) => (
            <option key={outputColumn} value={outputColumn}>
              {outputColumn}
            </option>
          ))}
        </Input>
        {errors.result_column && (
          <FormFeedback className="mt-2">
            {errors.result_column?.message}
          </FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

WriteData.propTypes = {
  selectedItem: PropTypes.object,
  writeDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default WriteData;
