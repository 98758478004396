import {useDraggable} from "@dnd-kit/core";
import React, {useState} from "react";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {
  DragHandleBars24,
  Filter24,
  Right24,
  Up24,
} from "@bphxd/ds-core-react/lib/icons";
import {Collapse, FormGroup, Input} from "reactstrap";
import ColumnUtils from "./ColumnUtils";
import FilterColumn from "./FilterColumn";
import ColumnItemFilterDate from "./ColumnItemFilterDate";
import ColumnItemFilterList from "./ColumnItemFilterList";

const {complianceStatus} = ColumnUtils;

const ColumnItem = ({
  column,
  scenarioNumber,
  onChange,
  filters,
  columns,
  options,
  columnMap,
}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: column.key,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  const handleChange = (event) => {
    const {checked} = event.currentTarget;
    if (column.key === "subType" && !column.queryKey && checked) {
      const bioTypeColumn = columns?.filter(
        (item) => item.key === "bioType" && item.visible && item.filterable,
      );
      if (bioTypeColumn?.length === 0) {
        toast.error(
          "Please select Bio Type first, Bio Type is required to show Sub Type",
        );
        return false;
      }
    }
    if (column.key === "bioType" && !column.queryKey && !checked) {
      const subTypeColumn = columns?.filter(
        (item) =>
          item.key === "subType" &&
          item.visible &&
          item.filterable &&
          !item.queryKey,
      );
      if (subTypeColumn?.length > 0) {
        toast.error(
          "Please unselect Sub Type first, Bio Type is required to show Sub Type",
        );
        return false;
      }
    }
    onChange({...column, visible: checked});
    return true;
  };

  const columnFields = (key) => {
    switch (key) {
      case "bioType":
        return options.bioType;
      case "subType":
        return options.subType;
      case "complianceStatus":
        return complianceStatus;
      case "legalEntity":
        return options.legalEntity;
      default:
        return [];
    }
  };
  const [opened, setOpened] = useState(false);
  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        className="flex align-items-center m-1 cursor-pointer"
        onClick={() => setOpened(!opened)}
        onKeyDown={() => setOpened(!opened)}
      >
        {column.filterable && (
          <>
            <span className="d-inline">
              {opened ? (
                <Up24 className="d-inline" />
              ) : (
                <Right24 className="d-inline" />
              )}
            </span>
            <span className="font-normal mr-auto">
              {column.customizeName || columnMap[column.key]}
              {filters.filterByColumns
                .map((col) => col.columnName)
                .includes(column.key) && (
                <Filter24 className="d-inline-block" />
              )}
            </span>
            <FormGroup className="form-check-reverse flex-grow-1" switch>
              <Input
                id="switch-alt-example-1"
                type="switch"
                checked={column.visible}
                onClick={(event) => event.stopPropagation()}
                onChange={handleChange}
                disabled={column.disabled}
              />
            </FormGroup>
            <DragHandleBars24 {...attributes} {...listeners} />
          </>
        )}
      </div>
      <Collapse key={column.key} isOpen={opened}>
        {column.filterable && column.searchType === "text" && opened && (
          <FilterColumn
            column={column}
            onChange={onChange}
            scenarioNumber={scenarioNumber}
            filters={{...filters}}
            columns={columns}
          />
        )}

        {column.filterable && column.searchType === "date" && opened && (
          <ColumnItemFilterDate column={column} onChange={onChange} />
        )}
        {column.filterable && column.searchType === "list" && opened && (
          <ColumnItemFilterList
            column={column}
            onChange={onChange}
            columnFields={columnFields(column.key)}
          />
        )}
      </Collapse>
    </>
  );
};

ColumnItem.propTypes = {
  column: PropTypes.object,
  scenarioNumber: PropTypes.string,
  onChange: PropTypes.func,
  filters: PropTypes.object.isRequired,
  columns: PropTypes.array,
  options: PropTypes.object,
  columnMap: PropTypes.object,
};

export default ColumnItem;
