import * as yup from "yup";
import {ArrowLeftOutlined} from "@ant-design/icons";
import FormField from "modules/common/FormField";
import Modal from "modules/common/Modal";
import {useState} from "react";
import PropTypes from "prop-types";
import Menu from "modules/common/Menu";
import Textarea from "modules/common/Textarea";
import {Controller, useFormContext} from "react-hook-form";
import Form from "modules/common/Form";
import {Spin} from "antd";
import Heading from "DLS/Typography/Heading";
import {toast} from "react-toastify";
import Button from "modules/common/Button";
import PreviewTable from "./PreviewTable";

// DELETE ITEM FOR COPRO PAGES

// THIS IS A COPY OF DeleteItem COMPONENT THAT CONTAINS BUTTONS
// WITH SAME STYLE AS CO-PROCESSING ALSO HANDLE THE SUBMISSION IN A
// DIFFERENT WAY USING JUST TOAST AND DOESNT BOTHER ABOUT ERROR WITHIN THE MODAL

const DeleteFormContent = () => {
  const {
    formState: {errors},
  } = useFormContext();

  return (
    <FormField
      className="mt-4"
      label="Add Comments"
      errorMessage={errors.comments?.message}
    >
      <Controller
        name="comments"
        render={({field}) => (
          <Textarea
            {...field}
            placeholder="Add Comments"
            data-test="comments"
            maxLength={250}
            showCount
          />
        )}
      />
    </FormField>
  );
};

const coProDeleteFormSchema = yup
  .object()
  .shape({
    comments: yup.string().label("Add comments").required(),
  })
  .required();

const DeleteItemCoPro = ({disabled, onSubmit, columns, data}) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setSubmitAllowed(false);
    setSubmitting(true);
    await onSubmit(values);
    setDeleteModalVisible(false);
    toast.success("Deleted successfully.");
    setSubmitting(false);
    setSubmitAllowed(true);
  };

  return (
    <>
      {/* MODAL */}
      <Modal
        onCancel={() => setDeleteModalVisible((prevValue) => !prevValue)}
        visible={isDeleteModalVisible}
        title="Delete - Following Items"
        footer={
          <div className="flex flex-col items-start w-full gap-2 pt-8 border-t border-gray-100 sm:flex-row">
            <Button
              icon={<ArrowLeftOutlined />}
              type="secondary"
              data-test="delete-modal-back-btn"
              onClick={() => setDeleteModalVisible((prevValue) => !prevValue)}
            >
              Back
            </Button>
            <Button
              data-test="delete-modal-submit-btn"
              htmlType="submit"
              form="delete-form"
              disabled={!isSubmitAllowed}
            >
              {isSubmitting ? <Spin size="small" /> : "Submit"}
            </Button>
          </div>
        }
        destroyOnClose
        closable
      >
        <PreviewTable
          tableTitle="Preview"
          columns={columns}
          data={[data].flat()} // Force array casting
        />
        <div className="flex flex-col mb-10 mt-14">
          <Heading level={5}>Justification Reason</Heading>
          <p className="mt-1 text-indigo-500">
            Please provide the justification reason in the comment box
          </p>
        </div>
        <Form
          schema={coProDeleteFormSchema}
          onSubmit={handleSubmit}
          id="delete-form"
        >
          <DeleteFormContent />
        </Form>
      </Modal>

      {/* ACTION */}
      <Menu.Item
        disabled={disabled}
        key="table-action-delete"
        data-test="table-action-delete"
        onClick={() => setDeleteModalVisible(true)}
      >
        Delete
      </Menu.Item>
    </>
  );
};

DeleteItemCoPro.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DeleteItemCoPro;
