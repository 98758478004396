import Menu from "modules/common/Menu";
import PropTypes from "prop-types";
import {useRef, useMemo} from "react";
import {CSVLink} from "react-csv";
import {toast} from "react-toastify";
import fileNames from "utils/helpers/uniqueFileName.js";

const LocalCSVItem = ({data, columns, filename}) => {
  const csvLink = useRef();
  const header = useMemo(
    () =>
      columns
        .filter(({key}) => key !== "operation")
        .map(({key, title}) => ({label: title, key})),
    [columns],
  );

  const handleCSVDownload = () => {
    if (data.length > 0) {
      csvLink.current.link.click();
    } else {
      toast.error("No data available to export");
    }
  };

  return (
    <>
      {data && data?.length >= 1 && data?.indexOf(undefined) === -1 && (
        <CSVLink
          data-test="csvlink"
          className="dashboard-export-csv-file"
          headers={header}
          ref={csvLink}
          data={data}
          filename={fileNames.uniqueCsvFile(filename)}
          target="_self"
        />
      )}

      {/* ACTION */}
      <Menu.Item
        key="table-action-download-csv"
        data-test="table-action-download-csv"
        onClick={handleCSVDownload}
      >
        Export/Download to CSV
      </Menu.Item>
    </>
  );
};

LocalCSVItem.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filename: PropTypes.string.isRequired,
};

export default LocalCSVItem;
