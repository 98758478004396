import PropTypes from "prop-types";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import Datepicker from "../../Datepicker";

const SupplierAndRecipientInfo = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const recipientRecord = data?.locationDetails?.find(
    (obj) => obj.location_code === data?.recipientName,
  );

  useEffect(() => {
    setValue("supplierName", data?.supplier);
    setValue("supplierAddress", data?.supplierAddress);
    setValue("certificationSystem", data?.certificationSystem);
    setValue("certificateNumber", data?.certificationNumber);
    setValue("recipientName", recipientRecord?.legal_entity);
    setValue("recipientAddress", data?.recipientAddress);
    setValue("contractNumber", data?.contractNumber);
    setValue("recipientReceiptAddress", data?.shippingAddress);
    setValue("receiptReceivingPointAddress", data?.recipientReceiptAddress);
    setValue("dispatchDate", new Date(data?.dispatchDate));
  }, [setValue, data, recipientRecord]);
  return (
    <div className="flex flex-col gap-4">
      <FormGroup>
        <Label for="supplierName" className="fw-bold">
          Name of supplier
        </Label>

        <Input
          {...computeProps("supplierName")}
          type="text"
          id="supplierName"
          name="supplierName"
          data-test="supplierName"
          invalid={!!errors.supplierName}
          maxLength={50}
          disabled
        />

        <FormFeedback>{errors.supplierName?.message}</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="supplierAddress" className="fw-bold">
          Address of supplier
        </Label>

        <Input
          {...computeProps("supplierAddress")}
          type="text"
          id="supplierAddress"
          name="supplierAddress"
          data-test="supplierAddress"
          invalid={!!errors.supplierAddress}
          maxLength={200}
          disabled
        />

        {errors.supplierAddress && (
          <FormFeedback>{errors.supplierAddress?.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="certificationSystem" className="fw-bold">
          Certification system
        </Label>
        <Input
          {...computeProps("certificationSystem")}
          type="text"
          id="certificationSystem"
          name="certificationSystem"
          data-test="certificationSystem"
          invalid={!!errors.certificationSystem}
          maxLength={200}
          disabled
        />

        {errors.certificationSystem && (
          <FormFeedback>{errors.certificationSystem?.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="certificateNumber" className="fw-bold">
          Certificate number
        </Label>

        <Input
          {...computeProps("certificateNumber")}
          type="text"
          id="certificateNumber"
          data-test="certificateNumber"
          invalid={!!errors.certificateNumber}
          maxLength={100}
          disabled
        />

        {errors.certificateNumber && (
          <FormFeedback>{errors.certificateNumber.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientName" className="fw-bold">
          Name of recipient
        </Label>

        <Input
          {...computeProps("recipientName")}
          type="text"
          id="recipientName"
          data-test="recipientName"
          invalid={!!errors.recipientName}
          maxLength={50}
          disabled
        />

        {errors.recipientName && (
          <FormFeedback>{errors.recipientName.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-bold">
          Address of recipient
        </Label>

        <Input
          {...computeProps("recipientAddress")}
          type="text"
          id="recipientAddress"
          data-test="recipientAddress"
          invalid={!!errors.recipientAddress}
          maxLength={200}
          disabled
        />

        {errors.recipientAddress && (
          <FormFeedback>{errors.recipientAddress.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="contractNumber" className="fw-bold">
          Contract number
        </Label>

        <Input
          {...computeProps("contractNumber")}
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          invalid={!!errors.contractNumber}
          maxLength={50}
          disabled
        />
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-bold">
          Address of dispatch/shipping point
        </Label>

        <Input
          {...computeProps("recipientReceiptAddress")}
          type="text"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          invalid={!!errors.recipientReceiptAddress}
          maxLength={200}
          disabled
        />
      </FormGroup>
      {data?.templateType === "ISCC PLUS 3.5.1" &&
        data?.certificationSystem === "ISCC PLUS" && (
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("sameAsAddressOfSupplier")}
              type="checkbox"
              id="sameAsAddressOfSupplier"
              data-test="sameAsAddressOfSupplier"
            />

            <Label check for="sameAsAddressOfSupplier">
              Same as address of supplier
            </Label>
          </FormGroup>
        )}

      <FormGroup>
        <Label for="receiptReceivingPointAddress" className="fw-bold">
          Address of receipt/receiving point
        </Label>

        <Input
          {...computeProps("receiptReceivingPointAddress")}
          type="text"
          id="receiptReceivingPointAddress"
          data-test="receiptReceivingPointAddress"
          invalid={!!errors.recipientReceiptAddress}
          maxLength={200}
          disabled
        />
      </FormGroup>
      {data?.templateType === "ISCC Plus 3.5.1" &&
        data?.certificationSystem === "ISCC Plus" && (
          <FormGroup
            check
            inline
            className="form-check-alt form-check-lg !mb-0"
          >
            <Input
              {...computeProps("sameAsAddressOfRecipient")}
              type="checkbox"
              id="sameAsAddressOfRecipient"
              data-test="sameAsAddressOfRecipient"
            />

            <Label check for="sameAsAddressOfRecipient">
              Same as address of recipient
            </Label>
          </FormGroup>
        )}

      <FormGroup>
        <Label for="dispatchDate" className="fw-bold">
          Date of dispatch
        </Label>
        <Datepicker
          id="dispatchDate"
          name="dispatchDate"
          control={control}
          disabled
          error={errors.dispatchDate}
          rules={{required: "Please enter date of dispatch"}}
        />

        <FormFeedback>{errors?.uniqueNumber?.message}</FormFeedback>
      </FormGroup>
    </div>
  );
};

SupplierAndRecipientInfo.propTypes = {
  data: PropTypes.object,
};

export default SupplierAndRecipientInfo;
