import PropTypes from "prop-types";
import React from "react";

const HighLightedText = (props) => {
  const {value, globalFilter} = props;

  if (!globalFilter || !value) {
    return <span>{value}</span>;
  }

  globalFilter.replace(/[.*+?^${}|[\]\\] /g, "\\$&");

  const parts = value.toString().split(new RegExp(`(${globalFilter})`, "gi"));

  return parts.map((part, index) => (
    <span
      key={index}
      style={
        part.toLowerCase() === globalFilter.toLowerCase()
          ? {fontWeight: "bold", color: "black"}
          : null
      }
    >
      {part}
    </span>
  ));
};

HighLightedText.propTypes = {
  value: PropTypes.string,
  globalFilter: PropTypes.string,
};

export default HighLightedText;
