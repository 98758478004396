import PropTypes from "prop-types";
import {Dropdown} from "antd";
import {DownOutlined} from "@ant-design/icons";
import Menu from "../../Menu";

const GeneralActionsMenu = ({children}) => {
  return (
    <Dropdown
      overlay={<Menu className="bg-white shadow-none w-fit">{children}</Menu>}
      placement="bottomLeft"
    >
      <button
        data-test="general-actions-menu"
        className="flex items-center w-24 p-2 text-sm text-white bg-gray-800 rounded hover:bg-opacity-50 gap-x-3"
        type="button"
      >
        ACTIONS
        {/* TECHDEBT: FIX INLINE STYLE WITH TAILWIND */}
        <DownOutlined
          style={{
            color: "white",
            fontSize: 12,
          }}
        />
      </button>
    </Dropdown>
  );
};

GeneralActionsMenu.propTypes = {
  children: PropTypes.node,
};

export default GeneralActionsMenu;
