import React from "react";
import CombinedObligatedVolumeTable from "modules/forecasting/components/CombinedObligatedVolumeTable";
import KPICardSection from "modules/forecasting/components/KPICardSection";
import ForecastGraphSet from "modules/forecasting/components/ForecastGraphSet";
import {pageDataToGraph} from "modules/forecasting/helpers/computeGraphData";
import {useSelectedDate} from "modules/forecasting/context/ForecastingDateContext";
import {useActiveFilters} from "modules/forecasting/context/ForecastingFilterContext";
import {transformWestCoastData} from "modules/forecasting/helpers/computeEastCoastData";
import {isCreditType} from "modules/forecasting/helpers/forecastFilterUtils";
import InventoryWrapper from "modules/forecasting/components/InventoryWrapper";
import ViewByForecastType, {
  mapKPIToDataView,
} from "modules/forecasting/components/ViewByForecastType";
import TotalCharts from "modules/forecasting/components/GraphSection/TotalCharts";
import GraphSection from "modules/forecasting/components/GraphSection/GraphSection";
import useCreditsAndDeficits from "modules/forecasting/helpers/useCreditsAndDeficits";
import "../../components/index.scss";

const InventoryData = () => {
  const {monthIndex} = useSelectedDate();
  const {year} = useActiveFilters();
  const {data, loading} = useCreditsAndDeficits({region: "wc", year}) || {};

  return (
    <InventoryWrapper loading={loading}>
      <ViewByForecastType
        data={data}
        region="wc"
        transformerFn={transformWestCoastData}
        renderKPI={({state, forecastTypes}) =>
          forecastTypes.map((forecastType) => (
            <KPICardSection
              key={forecastType}
              {...mapKPIToDataView({
                forecastType,
                variance: state?.[forecastType].variance,
              })}
            />
          ))
        }
      >
        {/* forecastTypeName: credits | deficits */}
        {({forecastTypeName, pageData}) => {
          const {graphValues = []} = pageDataToGraph(pageData);

          return (
            <React.Fragment key={forecastTypeName}>
              <GraphSection
                view="dataView"
                isCredits={isCreditType(forecastTypeName)}
                isWestCoast
              >
                <TotalCharts forecastType={forecastTypeName} data={pageData} />

                <ForecastGraphSet
                  isCredits={isCreditType(forecastTypeName)}
                  isWestCoast={isCreditType(forecastTypeName) || false}
                  fullYearData={graphValues || []}
                  graphData={
                    graphValues?.slice(monthIndex, monthIndex + 4) || []
                  }
                />
              </GraphSection>

              <CombinedObligatedVolumeTable
                forecastType={forecastTypeName}
                pageData={pageData?.slice(monthIndex, monthIndex + 4)}
                region="wc"
              />
            </React.Fragment>
          );
        }}
      </ViewByForecastType>

      {/* TODO: uncomment when email pipeline for production has been completed */}
      {/* <div className="w-2/3 h-36">
          <button
            disabled={isSubmitDisabled}
            key="forecast-button-submit"
            type="submit"
            data-test="forecast-submit-button"
            className="px-3 py-2 my-8 text-sm font-bold text-center text-black uppercase border-2 border-black rounded hover:opacity-40"
            onClick={submitForecastData}
          >
            {SUBMIT_ORDER}
          </button>
        </div> */}
    </InventoryWrapper>
  );
};

export default InventoryData;
