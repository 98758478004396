import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {useUserSettings} from "providers/userSettings/context.js";
import {PRODUCT_DDV_DETAILS} from "graphql/config-center/ProductDDVDetails.js";
import RTable from "../components/Table/index.js";
import {PRODUCT_DDV_DETAILS_COLUMNS} from "../components/Table/Columns.js";

const ProductDDVDetails = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => PRODUCT_DDV_DETAILS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(PRODUCT_DDV_DETAILS);

  return (
    <RTable
      columns={columns}
      data={
        loading
          ? []
          : data?.bioLcCoproConfigCenterProductDefaultValueDetails.Records
      }
      tableName="Product Default Value Details"
    />
  );
};
export default ProductDDVDetails;
