import _ from "lodash";

export const ACTIONS = {
  CHANGE_OPTION: "CHANGE_OPTION",
  SET_OPTIONS: "SET_OPTIONS",
};

export const INITIAL_STATE = {
  dataSource: [],
  options: {
    grade: [],
    gradeSubType: [],
    location: [],
    businessUnit: [],
  },
  currentValues: {},
};

const extractUniqueValuesByKey = (array, key, filterValues = {}) => {
  return _.chain(array)
    .filter(filterValues)
    .uniqBy(key)
    .map(key)
    .compact() // Remove empty strings
    .value();
};

const reducer = (state, {type, payload}) => {
  switch (type) {
    case ACTIONS.SET_OPTIONS: {
      // Find unique values per filter key and set state
      const {data: dataSource} = payload;
      const keys = Object.keys(INITIAL_STATE.options);
      const optionsResult = {};

      keys.forEach((key) => {
        optionsResult[key] = extractUniqueValuesByKey(dataSource, key);
      });

      // Set data source, and default options
      return {
        ...state,
        dataSource,
        options: {...state.options, ...optionsResult},
      };
    }

    case ACTIONS.CHANGE_OPTION: {
      const {name, value} = payload;
      const {dataSource, currentValues} = state;
      const optionsResult = {};

      // Extract default options
      const newCurrentValues = _.omitBy(
        {...currentValues, [name]: value},
        _.isUndefined,
      );

      const keys = Object.keys(INITIAL_STATE.options);
      keys.forEach((key) => {
        if (key === "grade" || key === "businessUnit") return;
        optionsResult[key] = extractUniqueValuesByKey(
          dataSource,
          key,
          _.omit(newCurrentValues, [key]), // For each filter, never include himself in the filter
        );
      });

      if (!optionsResult.location.includes(newCurrentValues.location)) {
        delete newCurrentValues.location;
      }
      if (!optionsResult.gradeSubType.includes(newCurrentValues.gradeSubType)) {
        delete newCurrentValues.gradeSubType;
      }

      keys.forEach((key) => {
        if (key === "grade" || key === "businessUnit") return;
        optionsResult[key] = extractUniqueValuesByKey(
          dataSource,
          key,
          _.omit(newCurrentValues, [key]), // For each filter, never include himself in the filter
        );
      });

      // Set default options for those not changed
      return {
        ...state,
        options: {...state.options, ...optionsResult},
        currentValues: newCurrentValues,
      };
    }

    default:
      return state;
  }
};

export default reducer;
