const computeMonthTotalWithVariance = function (monthData = []) {
  if (!monthData.length) return {};

  const totalActualsPerMonth = monthData.reduce(
    (memo, product) => product.actualValue + memo,
    0,
  );
  const totalSystemValuesPerMonth = monthData.reduce(
    (memo, product) => product.systemValue + memo,
    0,
  );

  const variance = totalActualsPerMonth - totalSystemValuesPerMonth ?? 0;

  const getVariancePercentage = () => {
    if (variance) {
      return Math.round((variance / totalActualsPerMonth) * 100);
    }
    if (!variance || totalSystemValuesPerMonth === totalActualsPerMonth) {
      return 0;
    }
    return variance && !totalActualsPerMonth ? 100 : -100;
  };
  const variancePercentage = getVariancePercentage();

  return {
    actualValue: totalActualsPerMonth,
    systemValue: totalSystemValuesPerMonth,
    variance,
    variancePercentage,
  };
};

export default computeMonthTotalWithVariance;
