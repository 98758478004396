import tw from "twin.macro";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import RecycleBinTable from "modules/co-processing/components/RecycleBin/RecycleBinTable";
import "../../components/index.scss";

const RecycleBinPage = () => {
  return (
    <div className="co-processing-container overflow-x-auto">
      <HeaderSection>
        <PageHeader>{PAGE_TITLE.RECYCLE_BIN}</PageHeader>
        <hr className="border-b-[1.5px]" />
      </HeaderSection>
      <PaddedContainer>
        <RecycleBinTable />
      </PaddedContainer>
    </div>
  );
};

export default RecycleBinPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageHeader = tw.h1`text-[1.8125rem] mb-5`;
const PaddedContainer = tw.div`pl-7 pr-7 bg-[#fafafa]`;
