import PropTypes from "prop-types";
import {useState} from "react";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Menu from "modules/common/Menu";
import Modal from "modules/common/Modal";
import Form from "modules/common/Form";
import Notification from "modules/common/Notification";
import useNotification from "modules/common/Notification/useNotification";
import {Spin} from "antd";
import Button from "DLS/Button";

const modeData = {
  add: {
    titlePrefix: "Add",
    itemLabel: "Add a new record",
    menuItemKey: "table-action-add",
    succesfulNotificationText: "Created successfully.",
    submitButtonLabel: "Submit",
  },
  edit: {
    titlePrefix: "Edit",
    itemLabel: "Edit",
    menuItemKey: "table-action-edit",
    succesfulNotificationText: "Updated successfully.",
    submitButtonLabel: "Save changes",
  },
  clone: {
    titlePrefix: "Clone",
    itemLabel: "Clone",
    menuItemKey: "table-action-clone",
    succesfulNotificationText: "Cloned successfully.",
    submitButtonLabel: "Submit",
  },
};

const EditionItem = ({formContent, mode, schema, modalTitle, onSubmit}) => {
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [timerId, setTimerId] = useState();
  const [isEditionModalVisible, setEditionModalVisible] = useState(false);
  const [triggerNotification, clearNotification, notificationProps] =
    useNotification();

  const {
    titlePrefix,
    itemLabel,
    menuItemKey,
    submitButtonLabel,
    succesfulNotificationText,
  } = modeData[mode];

  const handleSubmit = async (values) => {
    setSubmitAllowed(false);
    setSubmitting(true);
    try {
      const response = await onSubmit(values);
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          setSubmitting(false);
          setSubmitAllowed(true);
          triggerNotification("error", response.errors[0].message);
          break;
        default:
          // Theorically succesfully
          setSubmitting(false);
          triggerNotification("success", succesfulNotificationText);
          setTimerId(setTimeout(() => setEditionModalVisible(false), 10000));
      }
    } catch (err) {
      console.error(err);
      // Unexpected graphql error
      setSubmitting(false);
      setSubmitAllowed(true);
      triggerNotification("error", "Something went wrong, please try later.");
    }
  };

  return (
    <>
      {/* EDITION MODAL */}
      <Modal
        data-test="edition-modal"
        visible={isEditionModalVisible}
        title={`${titlePrefix} ${modalTitle}`}
        onCancel={() => setEditionModalVisible((prevValue) => !prevValue)}
        afterClose={() => {
          // Due modal is not unmounted when changing visibility: restart UI
          clearNotification();
          clearTimeout(timerId);
          setTimerId(null);
          setSubmitAllowed(true);
        }}
        footer={
          <>
            <div className="flex flex-col w-full gap-2 pt-8 border-t border-gray-100 sm:flex-row">
              <Button
                label="Back"
                icon={<ArrowLeftOutlined />}
                type="secondary"
                data-test="edition-modal-back-btn"
                onClick={() =>
                  setEditionModalVisible((prevValue) => !prevValue)
                }
              />
              <Button
                label={isSubmitting ? <Spin size="small" /> : submitButtonLabel}
                data-test="edition-modal-submit-btn"
                form="edition-form"
                htmlType="submit"
                disabled={!isSubmitAllowed}
              />
            </div>

            {/* SUBMIT NOTIFICATION */}
            <Notification
              {...notificationProps}
              className="mt-8"
              onClose={() => clearNotification()}
            />
          </>
        }
        destroyOnClose
        closable
      >
        <Form id="edition-form" schema={schema} onSubmit={handleSubmit}>
          {formContent}
        </Form>
      </Modal>

      {/* ACTION */}
      <Menu.Item
        key={menuItemKey}
        data-test={menuItemKey}
        onClick={() => setEditionModalVisible(true)}
      >
        {itemLabel}
      </Menu.Item>
    </>
  );
};

EditionItem.propTypes = {
  formContent: PropTypes.node.isRequired,
  modalTitle: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["edit", "add", "clone"]).isRequired,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

export default EditionItem;
