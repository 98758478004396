import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {PRODUCTION_EMISSIONS} from "graphql/config-center/ProductionEmissions.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {PRODUCTION_EMISSIONS_COLUMNS} from "../components/Table/Columns.js";

const ProductionEmissions = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => PRODUCTION_EMISSIONS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(PRODUCTION_EMISSIONS);

  return (
    <RTable
      columns={columns}
      data={loading ? [] : data?.bioLcCoproConfigCenterProductEmission.Records}
      tableName="Production emissions"
    />
  );
};
export default ProductionEmissions;
