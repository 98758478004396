import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const MBSummaryCard = ({
  period,
  divisionName,
  periodStatus,
  opening,
  incoming,
  outgoing,
  available,
  mbPeriodValidityFlag,
  redirectTo,
}) => {
  const periodStatusClass =
    periodStatus === "OPEN" ? "card-status-open" : "card-status-closed";
  return (
    <div className="card-container" onClick={redirectTo} onKeyDown={() => {}}>
      <div className="card-header">
        <h4 className="card-header-heading">{`${period} - ${divisionName}`}</h4>
        <div className="flex">
          {mbPeriodValidityFlag && (
            <span className="card-status card-status-error mr-[10px]">
              ERROR
            </span>
          )}
          <span className={`card-status ${periodStatusClass}`}>
            {periodStatus}
          </span>
        </div>
      </div>
      <div className="card-divider" />
      <div className="grid grid-cols-4 gap-4 text-center ml-[15px]">
        <div className="card-body-item">
          <h4 className="card-body-item-header">Opening m³</h4>
          <p className="card-body-item-count">{opening.toFixed(3)}</p>
        </div>
        <div>
          <h4 className="card-body-item-header">Incoming m³</h4>
          <p className="card-body-item-count">{incoming.toFixed(3)}</p>
        </div>
        <div>
          <h4 className="card-body-item-header">Outgoing m³</h4>
          <p className="card-body-item-count">{outgoing.toFixed(3)}</p>
        </div>
        <div>
          <h4 className="card-body-item-header">Available m³</h4>
          <p className="card-body-item-count">{available.toFixed(3)}</p>
        </div>
      </div>
    </div>
  );
};

MBSummaryCard.propTypes = {
  period: PropTypes.string.isRequired,
  divisionName: PropTypes.string.isRequired,
  periodStatus: PropTypes.string.isRequired,
  opening: PropTypes.number.isRequired,
  incoming: PropTypes.number.isRequired,
  outgoing: PropTypes.number.isRequired,
  available: PropTypes.number.isRequired,
  mbPeriodValidityFlag: PropTypes.bool.isRequired,
  redirectTo: PropTypes.func.isRequired,
};

export default MBSummaryCard;
