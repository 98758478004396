import TankUsageTypes from "utils/helpers/tankUsageTypes";
import {POS_DOWNLOAD_FILE} from "graphql/pos";
import {DOWNLOAD_DOCUMENT} from "graphql/cherrypoint";
import {DOWNLOAD_C14} from "graphql/tank-usage";

export const getVariables = (
  type,
  invoiceDetails,
  isInvoice,
  countryCode,
  test_result,
) => {
  let variables = {};
  if (type === TankUsageTypes.TYPE_BATCHES) {
    variables = {
      c14file: test_result,
    };
  } else if (
    type === TankUsageTypes.TYPE_POS_UK ||
    type === TankUsageTypes.TYPE_POS_PL ||
    type === TankUsageTypes.TYPE_POS_LUX
  ) {
    variables = {
      countryCode,
      fileName: invoiceDetails?.pdfFileName,
    };
  } else {
    variables = {
      type: isInvoice ? "invoice" : "billoflanding",
      invoice: isInvoice ? `${invoiceDetails.invoiceNo}` : "",
      billoflanding: isInvoice ? "" : `${invoiceDetails.deliverySlipBOL}`,
    };
  }
  return variables;
};

export const getQuery = (type) => {
  let query = "";
  if (type === TankUsageTypes.TYPE_BATCHES) {
    query = DOWNLOAD_C14;
  } else if (
    type === TankUsageTypes.TYPE_POS_UK ||
    type === TankUsageTypes.TYPE_POS_PL ||
    type === TankUsageTypes.TYPE_POS_LUX
  ) {
    query = POS_DOWNLOAD_FILE;
  } else {
    query = DOWNLOAD_DOCUMENT;
  }
  return query;
};

export const getDocumentResponse = (type, data) => {
  let documentResponse = "";
  if (type === TankUsageTypes.TYPE_BATCHES) {
    documentResponse = data?.tankusage_c14_batches_download_url;
  } else if (
    type === TankUsageTypes.TYPE_POS_UK ||
    type === TankUsageTypes.TYPE_POS_PL ||
    type === TankUsageTypes.TYPE_POS_LUX
  ) {
    documentResponse = data?.posFileDownloadURL;
  } else {
    documentResponse = data?.supportingDocumentURL;
  }

  return documentResponse;
};

export const getTitle = (type, invoiceDetails, isInvoice, test_result) => {
  let title = "";
  if (type === TankUsageTypes.TYPE_BATCHES) {
    title = test_result;
  } else if (type === TankUsageTypes.TYPE_POS_UK) {
    title = invoiceDetails?.posId;
  } else if (type === TankUsageTypes.TYPE_POS_PL) {
    title = invoiceDetails?.uniqueNumberOfSustainabilityDeclaration;
  } else if (type === TankUsageTypes.TYPE_POS_LUX) {
    title = invoiceDetails?.posId;
  } else {
    title = isInvoice
      ? invoiceDetails.invoiceNo
      : invoiceDetails.deliverySlipBOL;
  }
  return title;
};

export default {getVariables};
