import {useState} from "react";
import PropTypes from "prop-types";
import {Duplicate16} from "@bphxd/ds-core-react/lib/icons";
import {Popover, PopoverBody} from "reactstrap";
import {
  COPIED,
  CLIPBOARD_TOOLTIP_TIMER,
} from "modules/co-processing/constants/shipments";

const CopyToClipboard = ({text}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onCopied = async (event) => {
    event.stopPropagation();
    await navigator.clipboard.writeText(text);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), CLIPBOARD_TOOLTIP_TIMER);
  };

  return (
    <div className="copy-container">
      <Duplicate16
        data-test="shipments-duplicate-icon"
        id="copy-icon"
        className="text-gray-700 cursor-pointer"
        onClick={(event) => onCopied(event)}
      />
      <Popover
        popperClassName="copied-popover"
        innerClassName="copied-popover-inner"
        data-test="shipments-duplicate-tooltip"
        placement="top"
        isOpen={tooltipOpen}
        target="copy-icon"
      >
        <PopoverBody>{COPIED}</PopoverBody>
      </Popover>
    </div>
  );
};

CopyToClipboard.propTypes = {
  text: PropTypes.string,
};

export default CopyToClipboard;
