import {gql} from "@apollo/client";
import moment from "moment";
import {client} from "providers/Apollo";
import * as DATEFORMATS from "constants/dateformats";

export const DOWNLOAD_C14 = gql`
  query downloadC14($c14file: String) {
    tankusage_c14_batches_download_url(event: {c14file: $c14file}) {
      statusCode
      url
      url_download
    }
  }
`;

//
// BIOFEED STOCK
//

export const ADD_BIO_FEEDSTOCK_RECEIPT = gql`
  mutation AddBioFeedstockMutation($data: CreateTankUsageBioFeedStockRequest!) {
    createTankUsageBioFeedStock(event: $data) {
      data {
        comments
        batchId
        summaryReportReferenceBOL
        owbBatchId
        vendorSalesContractId
        shipDate
        quantityReceived
        owbIdVendorBPPNA
        owbIdBPPNABPWCF
        vendorTank
        contractCompositeDensity
        contractDesityVolume
        batchRenewableFeedstockDensity
        vendorInvoice
        batchDensityVolume
        mass
        portionOfContractAnimalFats
        portionOfContractUsedCookingOil
        portionOfContractSoybeanOil
        portionOfContractCanolaOil
        portionOfContractDistillersCornOil
        portionOfContractOther
        volumeAnimalFats
        volumeUsedCookingOil
        volumeSoybeanOil
        volumeCanolaOil
        volumeDistillersCornOil
        volumeOther
        batchAssignmentCount
        batchOWBAssignmentCount
        summaryReportReferenceBatchOWB
        oWBContractMonth
        batchC14Date
        c14Month
        OWBQTR
        C14QTR
        comments
        isSoftDelete
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const BIO_FEEDSTOCK_RECEIPTS = gql`
  query bioFeedstockReceipts(
    $fromDate: String
    $toDate: String
    $page: Int
    $perPage: Int
    $openSearch: String
  ) {
    getTankUsageBiofeedstockReceipt(
      event: {
        fromDate: $fromDate
        page: $page
        perPage: $perPage
        toDate: $toDate
        openSearch: $openSearch
      }
    ) {
      statusCode
      totalCount
      data {
        items {
          C14QTR
          batchAssignmentCount
          OWBQTR
          batchC14Date
          batchDensityVolume
          batchId
          batchOWBAssignmentCount
          c14Month
          batchRenewableFeedstockDensity
          comments
          contractCompositeDensity
          contractDesityVolume
          isSoftDelete
          mass
          oWBContractMonth
          owbBatchId
          owbIdVendorBPPNA
          owbIdBPPNABPWCF
          portionOfContractCanolaOil
          portionOfContractAnimalFats
          portionOfContractDistillersCornOil
          portionOfContractOther
          portionOfContractSoybeanOil
          portionOfContractUsedCookingOil
          quantityReceived
          shipDate
          summaryReportReferenceBOL
          summaryReportReferenceBatchOWB
          tankUsageBiofeedstockReceiptId
          vendorSalesContractId
          vendorInvoice
          vendorTank
          volumeAnimalFats
          volumeCanolaOil
          volumeDistillersCornOil
          volumeOther
          volumeSoybeanOil
          volumeUsedCookingOil
        }
      }
    }
  }
`;

export const EDIT_BIOFEEDSTOCK_RECORD = gql`
  mutation editbiofeedstockMutation(
    $data: [UpdateTankUsageBioFeedStockSingleRequest!]
  ) {
    updateTankUsageBioFeedStockList(
      event: {updateTankUsageBioFeedStockList: $data}
    ) {
      data {
        C14QTR
        OWBQTR
        batchAssignmentCount
        batchC14Date
        batchDensityVolume
        batchId
        batchOWBAssignmentCount
        c14Month
        batchRenewableFeedstockDensity
        contractCompositeDensity
        comments
        contractDesityVolume
        isSoftDelete
        mass
        oWBContractMonth
        owbBatchId
        owbIdBPPNABPWCF
        owbIdVendorBPPNA
        portionOfContractAnimalFats
        portionOfContractCanolaOil
        portionOfContractDistillersCornOil
        portionOfContractOther
        portionOfContractSoybeanOil
        portionOfContractUsedCookingOil
        quantityReceived
        shipDate
        summaryReportReferenceBOL
        summaryReportReferenceBatchOWB
        vendorInvoice
        vendorSalesContractId
        vendorTank
        volumeAnimalFats
        volumeCanolaOil
        volumeDistillersCornOil
        volumeOther
        volumeSoybeanOil
        volumeUsedCookingOil
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const updateBioFeedStockRecord = async (data) => {
  const oWBContractMonth =
    data.oWBContractMonth !== ""
      ? moment(data.oWBContractMonth).format("YYYY-MM")
      : "";
  const c14Month =
    data.c14Month !== "" ? moment(data.c14Month).format("YYYY-MM") : "";
  const shipDate =
    data.shipDate !== ""
      ? moment(data.shipDate).format(DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH)
      : "";
  const batchC14Date =
    data.batchC14Date !== ""
      ? moment(data.batchC14Date).format(DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH)
      : "";
  const OWBQTR = data.OWBQTR !== "" ? moment(data.OWBQTR).format("YYYY-Q") : "";
  const C14QTR = data.C14QTR ? moment(data.C14QTR).format("YYYY-Q") : "";

  const newModifiedState = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== null),
  );
  const {
    __typename,
    isSoftDelete,
    owbBatchId,
    vendorSalesContractId,
    quantityReceived,
    batchId,
    summaryReportReferenceBOL,
    ...it
  } = newModifiedState;
  return client.mutate({
    mutation: EDIT_BIOFEEDSTOCK_RECORD,
    variables: {
      data: [
        {
          ...it,
          shipDate,
          oWBContractMonth,
          batchC14Date,
          OWBQTR,
          C14QTR,
          c14Month,
        },
      ],
    },
    refetchQueries: ["bioFeedstockReceipts"],
  });
};

//
// BATCHES
//

export const BATCHES = gql`
  query batches(
    $token: TankUsageBatchesDataNextTokenRequest
    $fromDate: String
    $toDate: String
  ) {
    getTankUsageBatches(
      event: {fromDate: $fromDate, toDate: $toDate, next_token: $token}
    ) {
      data {
        items {
          batchID
          c14Rfs
          c14TestResultDate
          comments
          dateISO
          isSoftDelete
          dhdsBatchStartTimeDate
          dhdsBatchFinishTimeDate
          operatingCondition
          percentModernCarbonPMC
          primary_key_col
          submitted
          renewableFeedstockCheck
          tankCertifiedTimeDate
          tankNumber
          test_result
          tankVolumeCertificationBBL
          totalVolumeOfBatchRemainingBBL
          dhdsFsbbl
          dhdsFslb
          dhdsFsmt
          c14lcfs
          dhdsFeed {
            coldRecircRenewableFeedBbl
            coldRecircRenewableFeedSUSDBbl
            totalFeedBbl
            productionUnitRundownBbl
            productionHotDieselRecircBbl
            productionColdRecircBbl
            coldRecircRenewableFeedLb
            coldRecircRenewableFeedSUSDLb
            totalFeedLb
            productionUnitRundownLb
            productionHotDieselRecircLb
            productionColdRecircLb
            rundownDensityLbPerKgal
          }
        }
      }
      statusCode
      lastEvaluatedKey {
        PK
        SK
      }
    }
  }
`;

export const ADD_BATCH = gql`
  mutation AddBatchMutation($data: CreateTankUsageBatchesRequest!) {
    createTankUsageBatches(event: $data) {
      data {
        comments
        batchID
        tallowConcentrationAdjustmentTankLevelTimeDate
        tankCertifiedTimeDate
        dhdsBatchStartTimeDate
        dhdsBatchFinishTimeDate
        tankNumber
        submitted
        operatingCondition
        tankVolumeCertificationBBL
        totalVolumeOfBatchRemainingBBL
        percentModernCarbonPMC
        tallowConcentrationAdjustmentBBL
        rinGAL
        c14TestResultDate
        renewableFeedstockCheck
        comments
        isSoftDelete
        dhdsFslb
        dhdsFsbbl
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const EDIT_BATCHES_RECORD = gql`
  mutation editBatchesMutation($data: [UpdateTankUsageBatchesSingleRequest!]) {
    updateTankUsageBatchesList(event: {updateTankUsageBatchesList: $data}) {
      errors {
        message
        code
      }
      message
      statusCode
    }
  }
`;

export const addBatch = async (data) => {
  const c14TestResultDate =
    data.c14TestResultDate !== null &&
    data.c14TestResultDate !== "" &&
    data.c14TestResultDate !== undefined
      ? moment(data.c14TestResultDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
        )
      : "";
  const dhdsBatchStartTimeDate =
    data.dhdsBatchStartTimeDate !== null &&
    data.dhdsBatchStartTimeDate !== "" &&
    data.dhdsBatchStartTimeDate !== undefined
      ? moment(data.dhdsBatchStartTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  const dhdsBatchFinishTimeDate =
    data.dhdsBatchFinishTimeDate !== null &&
    data.dhdsBatchFinishTimeDate !== "" &&
    data.dhdsBatchFinishTimeDate !== undefined
      ? moment(data.dhdsBatchFinishTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  const tankCertifiedTimeDate =
    data.tankCertifiedTimeDate !== null &&
    data.tankCertifiedTimeDate !== "" &&
    data.tankCertifiedTimeDate !== undefined
      ? moment(data.tankCertifiedTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  return client.mutate({
    mutation: ADD_BATCH,
    variables: {
      data: {
        ...data,
        c14TestResultDate,
        dhdsBatchStartTimeDate,
        dhdsBatchFinishTimeDate,
        tankCertifiedTimeDate,
      },
    },
    refetchQueries: ["batches"],
  });
};

export const updateBatchRecord = async (data) => {
  const c14TestDate =
    data.c14TestResultDate !== null &&
    data.c14TestResultDate !== "" &&
    data.c14TestResultDate !== undefined
      ? moment(data.c14TestResultDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
        )
      : "";
  const startTime =
    data.dhdsBatchStartTimeDate !== null &&
    data.dhdsBatchStartTimeDate !== "" &&
    data.dhdsBatchStartTimeDate !== undefined
      ? moment(data.dhdsBatchStartTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  const endTime =
    data.dhdsBatchFinishTimeDate !== null &&
    data.dhdsBatchFinishTimeDate !== "" &&
    data.dhdsBatchFinishTimeDate !== undefined
      ? moment(data.dhdsBatchFinishTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  const tankCertifiedDateTimeValue =
    data.tankCertifiedTimeDate !== null &&
    data.tankCertifiedTimeDate !== "" &&
    data.tankCertifiedTimeDate !== undefined
      ? moment(data.tankCertifiedTimeDate).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "";
  const newModifiedState = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== null),
  );
  const {
    __typename,
    operatingCondition,
    test_result,
    c14Rfs,
    totalVolumeOfBatchRemainingBBL,
    dateISO,
    primary_key_col,
    tankVolumeCertificationBBL,
    tallowConcentrationAdjustmentTankLevelTimeDate,
    tallowConcentrationAdjustmentBBL,
    isSoftDelete,
    rinGAL,
    dhdsFsmt,
    c14lcfs,
    ...it
  } = newModifiedState;
  return client.mutate({
    mutation: EDIT_BATCHES_RECORD,
    variables: {
      data: [
        {
          ...it,
          c14TestResultDate: c14TestDate,
          tankCertifiedTimeDate: tankCertifiedDateTimeValue,
          dhdsBatchStartTimeDate: startTime,
          dhdsBatchFinishTimeDate: endTime,
        },
      ],
    },
    refetchQueries: ["batches"],
  });
};

//
// SHIPMENTS
//

export const DELETE_SHIPMENT = gql`
  mutation DeleteShipmentMutation($data: [UpdateTankUsageShipmentInput]) {
    multipleUpdateTankUsageShipment(inputs: $data) {
      batchId
      shipmentId
      qualifiedVolume
      dateTimeFinish
      totalPotentialCredits
      totalShippedR100
      dateTimeStart
      shipmentType
      commentsLoadPlan
      isSoftDelete
      date
      RINSQualified
      shipmentBeforeBatchStart
      shippedVolume
      totalShippedR100LCFS
      summaryReportReference
      dateISO
      shippedQualifiyingR100
    }
  }
`;

export const EDIT_SHIPMENT_RECORD = gql`
  mutation editShipmentMutation(
    $data: [UpdateTankUsageShipmentSingleRequest!]
  ) {
    updateTankUsageShipmentList(event: {updateTankUsageShipmentList: $data}) {
      data {
        batchId
        RINSQualified
        comments
        commentsLoadPlan
        dateISO
        date
        dateTimeFinish
        dateTimeStart
        isSoftDelete
        qualifiedVolume
        shipmentBeforeBatchStart
        shipmentId
        shippedQualifiyingR100
        shipmentType
        shippedVolume
        summaryReportReference
        totalShippedR100
        totalPotentialCredits
        totalShippedR100LCFS
      }
      errors {
        message
        code
      }
      message
      statusCode
    }
  }
`;

export const ADD_SHIPMENT = gql`
  mutation AddShipmentMutation($data: CreateTankUsageShipmentRequest!) {
    createTankUsageShipment(event: $data) {
      data {
        RINSQualified
        batchId
        commentsLoadPlan
        date
        dateISO
        dateTimeFinish
        dateTimeStart
        isSoftDelete
        qualifiedVolume
        shipmentId
        shipmentBeforeBatchStart
        shipmentType
        shippedQualifiyingR100
        shippedVolume
        summaryReportReference
        totalPotentialCredits
        totalShippedR100
        totalShippedR100LCFS
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const SHIPMENTS = gql`
  query shipments($token: String) {
    listTankUsageShipment(
      filter: {isSoftDelete: {eq: false}}
      limit: 1000
      nextToken: $token
    ) {
      items {
        comments
        batchId
        shipmentId
        qualifiedVolume
        dateTimeFinish
        totalPotentialCredits
        totalShippedR100
        dateTimeStart
        shipmentType
        commentsLoadPlan
        isSoftDelete
        date
        RINSQualified
        shipmentBeforeBatchStart
        shippedVolume
        totalShippedR100LCFS
        summaryReportReference
        dateISO
        shippedQualifiyingR100
        totalVolumeOfBatchRemainingBBL
      }
      nextToken
    }
  }
`;

export const updateShipmentRecord = async (data) => {
  const dateTimeStartValue =
    data.dateTimeStart !== null && data.dateTimeStart !== ""
      ? moment(data.dateTimeStart).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "N/A";
  const dateTimeFinishValue =
    data.dateTimeFinish !== null && data.dateTimeFinish !== ""
      ? moment(data.dateTimeFinish).format(
          DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
        )
      : "N/A";

  const newModifiedState = Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== null),
  );
  const {
    __typename,
    date,
    shipmentType,
    dateISO,
    isSoftDelete,
    totalVolumeOfBatchRemainingBBL,
    ...it
  } = newModifiedState;
  return client.mutate({
    mutation: EDIT_SHIPMENT_RECORD,
    variables: {
      data: [
        {
          ...it,
          dateTimeStart: dateTimeStartValue,
          dateTimeFinish: dateTimeFinishValue,
        },
      ],
    },
    refetchQueries: ["shipments"],
  });
};

export const addShipment = async (data) => {
  const {
    shipmentType,
    fullShipmentId,
    shipmentId,
    RINSQualified,
    dateTimeStart,
    dateTimeFinish,
    date,
    ...rest
  } = data;
  const dateTimeStartValue =
    dateTimeStart !== null && dateTimeStart !== ""
      ? moment(dateTimeStart).format(DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A)
      : "N/A";

  const dateTimeFinishValue =
    dateTimeFinish !== null && dateTimeFinish !== ""
      ? moment(dateTimeFinish).format(DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A)
      : "N/A";
  return client.mutate({
    mutation: ADD_SHIPMENT,
    variables: {
      data: {
        ...rest,
        date: moment(date).format(DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH),
        shipmentType: shipmentType.label,
        shipmentId: fullShipmentId,
        RINSQualified: RINSQualified?.value,
        dateTimeStart: dateTimeStartValue,
        dateTimeFinish: dateTimeFinishValue,
        isSoftDelete: false,
      },
    },
    refetchQueries: ["shipments"],
  });
};

export const AddBioFeedStockReceiptAPI = async (data) => {
  return client.mutate({
    mutation: ADD_BIO_FEEDSTOCK_RECEIPT,
    variables: {
      data: {
        ...data,
        shipDate:
          data.shipDate !== null && data.shipDate !== ""
            ? moment(data.shipDate).format(
                DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
              )
            : "",

        oWBContractMonth:
          data?.oWBContractMonth && data?.oWBContractMonth !== "N/A"
            ? moment(data.oWBContractMonth).format("YYYY-MM")
            : "",
        batchC14Date:
          data?.batchC14Date && data?.batchC14Date !== "N/A"
            ? moment(data.batchC14Date).format(
                DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
              )
            : "",
        c14Month:
          data?.c14Month && data?.c14Month !== "N/A"
            ? moment(data.c14Month).format("YYYY-MM")
            : "",
        OWBQTR:
          data?.OWBQTR && data?.OWBQTR !== "N/A"
            ? moment(data.OWBQTR).format("YYYY-[Q]Q")
            : "",
        C14QTR:
          data?.C14QTR && data?.C14QTR !== "N/A"
            ? moment(data.C14QTR).format("YYYY-[Q]Q")
            : "",
        summaryReportReferenceBOL: `${data.batchId}::${data.batchAssignmentCount}`,
      },
    },
    refetchQueries: ["bioFeedstockReceipts"],
  });
};
