import {gql} from "@apollo/client";

export const GET_RULE_BLOCK_CONVERSION_LIST = gql`
  query GetConversionList {
    bioLcComplianceRuleEngineGetConvertUnitsBlock {
      convertUnitsBlock {
        conversion_factor_name
        conversion_factor
      }
      statusCode
      error
    }
  }
`;

export default GET_RULE_BLOCK_CONVERSION_LIST;
