import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {PRODUCTION_BASELINE_EMISSIONS} from "graphql/config-center/ProductionBaselineEmissions.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {PRODUCTION_BASELINE_EMISSIONS_COLUMNS} from "../components/Table/Columns.js";

const ProductionBaselineEmissions = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => PRODUCTION_BASELINE_EMISSIONS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(PRODUCTION_BASELINE_EMISSIONS);
  return (
    <RTable
      columns={columns}
      data={
        loading
          ? []
          : data?.bioLcCoproConfigCenterProductBaselineEmissionDetails.Records
      }
      tableName="Product baseline emissions"
    />
  );
};
export default ProductionBaselineEmissions;
