import {Spinner} from "reactstrap";

const TaskLoading = () => {
  return (
    <div
      data-test="task-manager-loading"
      className="mx-[40px] bg-white pb-[62px] pt-8 flex items-baseline"
    >
      <span className="fs-5">Getting tasks</span>
      <div>
        <Spinner
          style={{width: ".25rem", height: ".25rem", marginLeft: ".25rem"}}
          type="grow"
        />{" "}
        <Spinner style={{width: ".25rem", height: ".25rem"}} type="grow" />{" "}
        <Spinner style={{width: ".25rem", height: ".25rem"}} type="grow" />
      </div>
    </div>
  );
};

export default TaskLoading;
