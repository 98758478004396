import React, {Fragment, useMemo} from "react";
import PropTypes from "prop-types";
import {
  codeToForecastTypes,
  KPI,
  TIMESTAMP_TEXT,
  FORECAST_TYPE,
  SOURCE_DATA_TYPES,
} from "modules/forecasting/constants/inventory";
import {useActiveFilters} from "modules/forecasting/context/ForecastingFilterContext";
import {
  isCreditType,
  isDeficitType,
} from "modules/forecasting/helpers/forecastFilterUtils";
import Collapse from "modules/forecasting/components/CollapsiblePanel";
import Header from "modules/forecasting/components/InventoryPageHeader";
import {
  BannerWrapper,
  TimeStamp,
} from "modules/forecasting/components/TimeStampLabel";

const ViewByForecastType = ({
  children,
  data,
  region,
  renderKPI,
  transformerFn,
}) => {
  const {forecastType: activeForecastType} = useActiveFilters();
  const forecastTypes = useMemo(
    () => codeToForecastTypes(activeForecastType),
    [activeForecastType],
  );

  const state = forecastTypes.reduce((memo, forecastType) => {
    const {body, info, variance} = data?.[`${forecastType}DataApi`] ?? {};

    return {
      ...memo,
      [forecastType]: {
        info,
        variance,
        pageData: transformerFn(body, forecastType),
      },
    };
  }, {});

  return (
    <>
      <div
        className="col-span-full flex flex-col md:flex-row gap-2 pt-4"
        data-test="kpi-group"
      >
        {renderKPI({state, forecastTypes})}
      </div>

      {/* Credits displays no banners as all the data is generated by users or BioVerse. */}
      {activeForecastType === FORECAST_TYPE.RIN || !region ? null : (
        <BannerWrapper>
          <label>{FORECAST_TYPE.DEFICITS} :</label>

          {/* Deficits displays two banners, one for Actuals Source Data, another for Forecast Source Data. */}
          <div
            data-test="info-list-grid"
            className="flex flex-row md:grid md:grid-cols-2 md:divide-x md:gap-2 divide-black leading-3"
          >
            {Object.values(SOURCE_DATA_TYPES).map((dataType) => {
              const updatedType = `${dataType}UpdatedDate`; // updatedType -> actuals||forecast + UpdatedDate.

              return (
                <TimeStamp
                  style={{paddingLeft: 10}}
                  key={dataType}
                  date={state?.deficits?.info?.[updatedType] ?? "N/A"} // -> info.actualsUpdatedDate||info.forecastUpdatedDate
                  label={TIMESTAMP_TEXT[dataType.toUpperCase()]}
                />
              );
            })}
          </div>
        </BannerWrapper>
      )}

      {forecastTypes.map((forecastTypeName) => (
        <Fragment key={forecastTypeName}>
          {activeForecastType && region ? (
            // header expects forecastType prop as 'rin' || 'rvo' not 'credits' or 'deficits'
            <Header region={region} forecastType={activeForecastType} />
          ) : null}

          <Collapse initialOpenIds={forecastTypes}>
            <Collapse.Item id={forecastTypeName} key={forecastTypeName}>
              <Collapse.ItemHeader>
                <h2 className="text-left">{forecastTypeName}</h2>
              </Collapse.ItemHeader>

              <Collapse.ItemContent>
                {children({
                  forecastTypeName,
                  pageData: state[forecastTypeName]?.pageData,
                })}
              </Collapse.ItemContent>
            </Collapse.Item>
          </Collapse>
        </Fragment>
      ))}
    </>
  );
};
ViewByForecastType.propTypes = {
  data: PropTypes.shape({
    creditsDataApi: PropTypes.object,
    deficitsDataApi: PropTypes.object,
  }),
  children: PropTypes.func,
  region: PropTypes.oneOf(["ec", "wc"]),
  renderKPI: PropTypes.func,
  transformerFn: PropTypes.func,
};
ViewByForecastType.defaultProps = {
  data: {},
  children: () => {},
  transformerFn: () => {},
  renderKPI: () => {},
};

export default React.memo(ViewByForecastType);

export const mapKPIToDataView = ({forecastType, variance = {}}) => {
  const kpiLabelsByType = (type) =>
    ({
      [isCreditType(type)]: KPI.CREDITS,
      [isDeficitType(type)]: KPI.DEFICIT,
    }).true;

  const KPI_ = kpiLabelsByType(forecastType);

  return {
    label: KPI_.DATA_PAGE_LABEL,
    message1: KPI_.DATA_VARIANCE_PERCENT,
    message2: KPI_.DATA_VARIANCE_VALUE,
    value1: variance?.forecast_vs_actual ?? "N/A",
    value2: variance?.forecast_vs_actual_bbl ?? "N/A",
  };
};
