import UKFlag from "assets/images/flags/flg_unitedkingdom_uk.png";
import GYFlag from "assets/images/flags/flg_germany_gy.png";
import ATFlag from "assets/images/flags/flg_austria_at.png";
import FRFlag from "assets/images/flags/flg_france_fr.png";
import LUFlag from "assets/images/flags/flg_luxembourg_lu.png";
import NLFlag from "assets/images/flags/flg_netherlands_nl.png";
import PLFlag from "assets/images/flags/flg_poland_pl.png";
import ESFlag from "assets/images/flags/flg_spain_es.png";
import USFlag from "assets/images/flags/flg_usa_us.png";

export const countries = [
  {
    countryCode: "UK",
    img: UKFlag,
    countryName: "United Kingdom",
    companyCode: "DE01",
  },
  {
    countryCode: "GY",
    img: GYFlag,
    countryName: "Germany",
    companyCode: "NA",
  },
  {
    countryCode: "AT",
    img: ATFlag,
    countryName: "Austria",
    companyCode: "AT0H",
  },
  {
    countryCode: "FR",
    img: FRFlag,
    countryName: "France",
    companyCode: "NA",
  },
  {
    countryCode: "LU",
    img: LUFlag,
    countryName: "Luxemberg",
    companyCode: "NA",
  },
  {
    countryCode: "NL",
    img: NLFlag,
    countryName: "Netherlands",
    companyCode: "NL01",
  },
  {
    countryCode: "PL",
    img: PLFlag,
    countryName: "Poland",
    companyCode: "NA",
  },
  {
    countryCode: "ES",
    img: ESFlag,
    countryName: "Spain",
    companyCode: "NA",
  },
  {
    countryCode: "US",
    img: USFlag,
    countryName: "United States",
    companyCode: "NA",
  },
];

export default {countries};
