import PropTypes from "prop-types";
import React from "react";

const ColumnHighlight = ({
  value,
  columnFilter,
  hasEllipsis = false,
  isCertificate = false,
}) => {
  let showValue = value;

  if (isCertificate) {
    // To handle undefined value
    const stringLength = value ? value.length : 0;
    const subStr =
      stringLength > 14 ? value?.substring(stringLength - 14) : value;

    const subWithEllipsis =
      stringLength > 14 && hasEllipsis ? ".." + subStr : subStr;

    showValue = value ? subWithEllipsis : value;
  }

  if (!columnFilter || !value) {
    return <span>{showValue}</span>;
  }

  columnFilter.replace(/[.*+?^${}|[\]\\] /g, "\\$&");

  let parts = showValue.toString().split(new RegExp(`(${columnFilter})`, "gi"));

  if (parts.length === 1) {
    parts = value.toString().split(new RegExp(`(${columnFilter})`, "gi"));
  }

  return parts.map((part, index) => (
    <span
      key={index}
      style={
        part.toLowerCase() === columnFilter.toLowerCase()
          ? {color: "black", backgroundColor: "yellow"}
          : null
      }
    >
      {part}
    </span>
  ));
};

ColumnHighlight.propTypes = {
  value: PropTypes.string,
  columnFilter: PropTypes.string,
  hasEllipsis: PropTypes.bool,
  isCertificate: PropTypes.bool,
};

export default ColumnHighlight;
