import {Close} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Datepicker from "../components/Datepicker";

const EditVersionModalDisplay = ({
  showUploadModal,
  setUploadModal,
  initialComment,
  initialValidFrom,
  onSubmit,
}) => {
  const {
    register,
    control,
    formState: {errors, isValid},
    setValue,
    getValues,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  // lookout for any pre-filled comment and valid from date

  useEffect(() => {
    if (initialComment) {
      setValue("comment", initialComment);
    }

    // valid from is mandatory field hence prefilled with current date and provide an option for user to change date through date picker

    if (initialValidFrom) {
      setValue("validFrom", new Date(initialValidFrom));
    } else {
      setValue("validFrom", new Date());
    }
  }, [initialComment, initialValidFrom, setValue]);

  return (
    <div>
      <Modal
        isOpen={showUploadModal}
        className="modal-dialog-centered"
        style={{width: "351px"}}
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 text-lg"
          close={
            <Close
              onClick={() => {
                setUploadModal(false);
              }}
            />
          }
        >
          Edit version
        </ModalHeader>
        <ModalBody className="py-0">
          <Form
            id="upload-form"
            onSubmit={async (values, event) => {
              onSubmit({
                inputValue: getValues("comment"),
                startDate: getValues("validFrom"),
              });

              setUploadModal(false);
            }}
          >
            <div className="w-full flex flex-col gap-7">
              <span className="text-sm"></span>

              <FormGroup>
                <Label for="comment" className="fw-medium text-m">
                  Comment{" "}
                  <span className="p-2 text-sm text-gray-500">optional</span>
                </Label>
                <Input
                  type="input"
                  placeholder="Enter comment"
                  id="comment"
                  name="comment"
                  invalid={!!errors.comment}
                  maxLength={1000}
                  {...computeProps("comment", {
                    required: "Comment cannot exceed 1000 characters",
                  })}
                />
                {errors.comment && (
                  <FormFeedback className="mt-2 !block">
                    {errors.comment.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="validFrom" className="fw-medium text-sm">
                  Valid from
                </Label>
                <Datepicker
                  id="validFrom"
                  name="validFrom"
                  control={control}
                  error={errors.validFrom}
                  placeholder="Valid from"
                  rules={{
                    required: "Please provide valid from date",
                  }}
                />

                {errors.validFrom && (
                  <FormFeedback className="mt-2 !block">
                    {errors.validFrom.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <ModalFooter className="p-0 d-block mt-5">
                <div className="row g-0 m-0 modal-footer-row">
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                      onClick={() => {
                        setUploadModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 bg-transparent text-default"
                      type="submit"
                      disabled={!isValid}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditVersionModalDisplay.propTypes = {
  showUploadModal: PropTypes.bool,
  setUploadModal: PropTypes.func,
  initialComment: PropTypes.string,
  initialValidFrom: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default EditVersionModalDisplay;
