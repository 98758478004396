import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import FormField from "../FormField";
import DatePicker from "../DatePicker";

const DateFilter = ({label, name, ...props}) => {
  const {control} = useFormContext();

  return (
    <FormField label={label}>
      <Controller
        control={control}
        name={name}
        render={({field}) => <DatePicker {...field} {...props} />}
      />
    </FormField>
  );
};

DateFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default DateFilter;
