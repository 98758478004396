import tw from "twin.macro";
import {useLocation} from "react-router-dom";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import {SHIPMENT_LABELS} from "modules/co-processing/constants/shipments";
import BatchesStatusChip from "modules/co-processing/components/Shared/BatchesStatusChip";
import ShipmentSummary from "modules/co-processing/components/ShipmentDetails/ShipmentSummary";
import DataView from "modules/co-processing/components/Shipments/DataView";

const SectionHeader = tw.div`text-[26px] pr-3 font-light`;
const ShipmentDetails = () => {
  const {state} = useLocation();
  const shipmentId = state?.id;
  const shipmentDetails = state?.details;

  return (
    <div data-test="shipments-details-screen" className="pl-10 pr-10 pb-20">
      <div className="pb-[3rem] fs-2 font-light border-b-[1.5px]">
        {PAGE_TITLE.SHIPMENT_DETAILS}
      </div>
      <div className="mt-4">
        <DataView />
      </div>

      <div className="flex flex-row py-[4rem]">
        <SectionHeader>{SHIPMENT_LABELS.STATUS}</SectionHeader>
        <BatchesStatusChip value={shipmentDetails?.shipment_status} small />
      </div>
      <ShipmentSummary id={shipmentId} />
    </div>
  );
};

export default ShipmentDetails;
