import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

// delete version shown up on UI when user wants to delete an existing rule version.

const DeleteVersionModal = ({showDeleteModal, setDeleteModal, onDelete}) => {
  return (
    <Modal
      isOpen={showDeleteModal}
      toggle={() => setDeleteModal(false)}
      className="modal-dialog-centered"
      style={{width: "296px", height: "140px"}}
    >
      <ModalHeader
        className="justify-content-center bg-transparent pb-2 font-[#11111191
] "
        style={{fontSize: "20px", textAlign: "center", color: "#000000"}}
      >
        Delete version
      </ModalHeader>
      <ModalBody
        className="text-center"
        style={{padding: "0px 20px 20px 20px", fontSize: "16px"}}
      >
        Are you sure you want to delete this version?
      </ModalBody>
      <ModalFooter className="p-0">
        <div className="w-100">
          <div className="font-bold flex justify-evenly">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-3 opacity-60 opacity-100-hover bg-transparent"
              onClick={() => setDeleteModal(false)}
              style={{
                color: "#000",
                backgroundColor: "#f8f9fa",
                borderColor: "#f8f9fa",
              }}
            >
              Cancel
            </Button>
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
              onClick={onDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DeleteVersionModal.propTypes = {
  showDeleteModal: PropTypes.bool.isRequired,
  setDeleteModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteVersionModal;
