import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useState} from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import RecallPopover from "./RecallPopover";
import {useHistoricalRefetch} from "./RefetchHistoricalContext";

const DropdownOption = ({icon: Icon, label, onClick}) => (
  <DropdownItem onClick={onClick}>
    {Icon && <Icon />}
    <span className="pl-3.5">{label}</span>
  </DropdownItem>
);

DropdownOption.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const HistoricalDropdown = ({shipment}) => {
  const batchID = [shipment?.batch_id];

  const [showPopover, setShowPopover] = useState(false);

  const {refetch} = useHistoricalRefetch();

  const onRecallCommitted = () => {
    setShowPopover(true);
  };

  const dropdownOptionList = [
    {
      label: "Recall",
      onClick: () => onRecallCommitted(),
    },
  ];

  return (
    <>
      <UncontrolledDropdown data-test="kebab-menu" direction="up">
        <DropdownToggle
          data-test="historical-kebab-menu"
          color="standard-quartenary"
          className="!px-0"
        >
          <Kebab24
            id="historical-kebab-menu"
            data-test="historical-kebab-menu-icon"
          />
        </DropdownToggle>
        <DropdownMenu container="body">
          {dropdownOptionList?.map((option, index) => (
            <DropdownOption
              data-test="historical-dropdown-option"
              key={index}
              icon={option.icon}
              label={option.label}
              onClick={option.onClick}
              id="popover-delete"
            />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <RecallPopover
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        batchId={batchID}
        refetch={refetch}
        popoverTarget="historical-kebab-menu"
      />
    </>
  );
};

HistoricalDropdown.propTypes = {
  shipment: PropTypes.any,
};

export default HistoricalDropdown;
