import {useState} from "react";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import FullScreenModal from "modules/common/FullscreenModal.js";
import {BUTTONS, TITLES} from "modules/co-processing/constants/creditGen";
import useModal from "modules/co-processing/helpers/useModal";
import PrepareSubmission from "./PrepareSubmissionForm";
import ReviewEmailAndSubmit from "./ReviewEmailAndSubmit";

const SubmissionModal = ({submissionData, columnDefs}) => {
  const {isModalOpen, toggleModal, closeModal} = useModal("submissionModal");

  const [isSubmissionConfirmed, setIsSubmissionConfirmed] = useState(false);
  const confirmSubmission = () => setIsSubmissionConfirmed(true);
  const resetConfirmation = () => setIsSubmissionConfirmed(false);

  return (
    <>
      <Button
        color="secondary"
        className="credit-gen-prepare-btn"
        onClick={toggleModal}
      >
        {BUTTONS.PREPARE_SUB}
      </Button>

      <FullScreenModal
        contentClassName="submission__modal-content"
        isOpen={isModalOpen}
        toggle={closeModal}
        title={TITLES.SUBMIT_BATCHES_FOR_EMTS}
      >
        {isSubmissionConfirmed ? (
          <ReviewEmailAndSubmit
            backToPrepareSubmission={resetConfirmation}
            sendSubmission={() => {
              resetConfirmation();
              toggleModal();
            }}
          />
        ) : (
          <PrepareSubmission
            submissionData={submissionData ?? []}
            tableColumns={columnDefs ?? []}
            onCancel={closeModal}
            onConfirmSubmission={confirmSubmission}
          />
        )}
      </FullScreenModal>
    </>
  );
};

SubmissionModal.propTypes = {
  submissionData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  columnDefs: PropTypes.array,
};

export default SubmissionModal;
