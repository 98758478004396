import React from "react";
import Tab from "modules/common/Svg/Tab";
import PropTypes from "prop-types";
import {NavLink, useLocation} from "react-router-dom";

const linkDefault =
  "flex items-center gap-x-2 rounded border px-3 py-1 font-medium hover:text-gray-500 hover:opacity-50 ";
const linkActive = " bg-indigo-500 text-white";
const linkInActive = "border-indigo-500 bg-white text-indigo-500";

export const posNLTabData = [
  {
    label: "ISCC PoS",
    to: "/pos-management/nl",
    "data-test": "pos-dashboard-nl-iscc",
  },
  {
    label: "Nabisy PoS",
    to: "/pos-management/nl/nabisy",
    "data-test": "pos-dashboard-nl-nabisy",
  },
  {
    label: "Upload documents",
    to: "/pos-management/upload-documents/nl",
    "data-test": "pos-dashboard-nl-nabisy",
  },
];

export const posLuxTabData = [
  {
    label: "Proof of Sustainability",
    to: "/pos-management/lux",
    "data-test": "pos-dashboard",
  },
];

// This tabs are still used in Upload, will be removed when upload is moved to button
const Tabs = ({country}) => {
  const location = useLocation();
  const {pathname} = location;
  const getLinkStatus = ({isActive}) =>
    linkDefault + (isActive ? linkActive : linkInActive);

  return (
    <div
      data-test="tabs"
      className="flex flex-col flex-wrap sm:flex-row gap-y-2 sm:gap-x-3"
    >
      <NavLink
        hidden={country === "nl"}
        data-test="pos-dashboard"
        to={`/pos-management/${country}`}
        className={getLinkStatus}
        end
      >
        <Tab
          fill={pathname === `/pos-management/${country}` ? "#fff" : "#161038"}
        />
        Proof of Sustainability
      </NavLink>
      <NavLink
        hidden={country !== "nl"}
        data-test="pos-dashboard-nl-iscc"
        to="/pos-management/nl"
        className={getLinkStatus}
        end
      >
        <Tab fill={pathname === "/pos-management/nl" ? "#fff" : "#161038"} />
        ISCC PoS
      </NavLink>
      <NavLink
        hidden={country !== "nl"}
        data-test="pos-dashboard-nl-nabisy"
        to="/pos-management/nl/nabisy"
        className={getLinkStatus}
        end
      >
        <Tab
          fill={pathname === "/pos-management/nl/nabisy" ? "#fff" : "#161038"}
        />
        Nabisy PoS
      </NavLink>
      <NavLink
        data-test="pos-upload-documents"
        to={`/pos-management/upload-documents/${country}`}
        className={getLinkStatus}
        end
      >
        <Tab
          fill={
            pathname === `/pos-management/upload-documents/${country}`
              ? "#fff"
              : "#161038"
          }
        />
        Upload documents
      </NavLink>
    </div>
  );
};

Tabs.propTypes = {
  country: PropTypes.string.isRequired,
};

export default Tabs;
