import React, {useState, useMemo, useEffect} from "react";
import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@apollo/client";
import {Input} from "antd";
import Fuse from "fuse.js";
import "modules/common/Menu/index.scss";
import TableWidget from "modules/common/Table/TableWidget";
import useRowSelection from "modules/common/Table/useRowSelection";
import {POS_PL_FILTER_LIST, downloadExportFile, POS_PL_LIST} from "graphql/pos";

import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import GeneralActionsMenu from "modules/common/Table/ActionsMenu/GeneralActionsMenu";
import DownloadAnnualItem from "modules/common/Table/ActionsMenu/DownloadAnnualItem";
import DownloadXLSItem from "modules/common/Table/ActionsMenu/DownloadXLSXItem";
import {ErrorModalBasic} from "modules/common/StatusModals/ErrorModal";
import usePoSExtractor from "modules/common/usePoSExtractor";
import useUploadDocument from "modules/common/useUploadDocument/useUploadDocument";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {singleDateSort} from "modules/common/Table";
import {uploadPoSFile} from "graphql/file-upload";
import {getFuseOptions} from "utils/helpers/fuseConfig";
import {getPosPLColumns} from "./content/posColumns";
import CountryDropdown from "./CountryDropdown";
import getFilterMap, {
  getDefaultFilterOptions,
  getIsNoActiveFilter,
} from "./posPLFilterHelpers";
import PoSPolandDashboardFilter from "./PoSPolandDashboardFilter";

const defaultFilterMap = {
  supplier: false,
  certificateType: false,
  feedstockType: false,
  fuelType: false,
};

const activeFiltersDefault = {
  supplier: [],
  certificateType: [],
  feedstockType: [],
  fuelType: [],
};

const PoSPolandDashboard = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const posPLColumns = getPosPLColumns(decimalFormat);
  const [activefilters, setActiveFilters] = useState(activeFiltersDefault);
  const [search, setSearch] = useState("");
  const [masterFilter, setMasterFilter] = useState("");
  const [pageNumber, setPage] = useState(1);
  const [exportError, setExportError] = useState(false);

  const {data: initialFilterData, loading: filterLoading} = useQuery(
    POS_PL_FILTER_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const filterData = initialFilterData?.getPOSFilterList?.data?.POL;
  const filterMap = getFilterMap(filterData);
  const defaultFilterOptions = getDefaultFilterOptions(filterMap);

  const [filterOptions, setFilterOptions] = useState(
    getDefaultFilterOptions(getFilterMap(filterData)),
  );

  useEffect(() => {
    setFilterOptions(getDefaultFilterOptions(getFilterMap(filterData)));
  }, [filterData]);

  const isNoActiveFilter = getIsNoActiveFilter(activefilters);

  const {data, loading, refetch} = useQuery(POS_PL_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const handleDocumentSubmit = (file) => {
    return uploadPoSFile("###", file, "pol");
  };

  const [uploadDocumentButton] = useUploadDocument(
    handleDocumentSubmit,
    refetch,
  );

  const {RefreshButton} = usePoSExtractor({country: "POL"}, () => {
    refetch();
  });

  const posData = useMemo(
    () => data?.getPOSRecordList?.data?.POL ?? [],
    [data?.getPOSRecordList?.data?.POL],
  );

  const [selectedRows, rowSelection] = useRowSelection(posData, "posId");

  const formatedData = applyDateFormat(
    posData,
    ["dateOfIssuance", "receiptDate"],
    dateFormat,
    "DD/MM/YYYY",
    [],
    decimalFormat,
    3,
  );

  const keys = [
    "supplier",
    "typeOfProduct",
    "typeOfRawMaterial",
    "certificateType",
    "uniqueNumberOfSustainabilityDeclaration",
    "countryOfOrigin",
    "countryCode",
  ];

  const fuseOptions = getFuseOptions(keys);

  const dataFuse = new Fuse(formatedData, fuseOptions);
  const items = search
    ? dataFuse.search(search).map(({item}) => item)
    : formatedData;

  return (
    <BasePageLayout
      pageTitle="Proof of Sustainability (PoS)"
      rightContent={<CountryDropdown />}
      tabsData={null}
    >
      <BasePageLayoutContent>
        <div className="flex flex-col justify-between px-5 py-4 mt-10 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
          <div className="flex flex-col items-start sm:items-center sm:flex-row gap-y-2 sm:gap-x-3">
            <GeneralActionsMenu>
              <DownloadXLSItem
                data={selectedRows}
                onDownload={async (rows) => {
                  const {
                    data: {posDataExport: response},
                  } = await downloadExportFile(null, rows, "POL", dateFormat);
                  return response;
                }}
              />
              <DownloadAnnualItem
                pageTitle="Please select the year for which you need to download the PoS data"
                format="xlsx"
                onDownload={async (year) => {
                  const {
                    data: {posDataExport: response},
                  } = await downloadExportFile(year, null, "POL", dateFormat);
                  return response;
                }}
              />
            </GeneralActionsMenu>
            <PoSPolandDashboardFilter
              activefilters={activefilters}
              setActiveFilters={setActiveFilters}
              masterFilter={masterFilter}
              setFilterOptions={setFilterOptions}
              defaultFilterOptions={defaultFilterOptions}
              isNoActiveFilter={isNoActiveFilter}
              filterOptions={filterOptions}
              setPage={setPage}
              refetch={refetch}
              activeFiltersDefault={activeFiltersDefault}
              setMasterFilter={setMasterFilter}
              defaultFilterMap={defaultFilterMap}
              filterLoading={filterLoading}
              filterMap={filterMap}
            />
          </div>
          <div
            data-test="container-search-item"
            className="flex items-center gap-x-3"
          >
            {uploadDocumentButton}
            {RefreshButton}
            <Input
              data-test="table-input-search"
              className="w-56 rounded"
              size="large"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <TableWidget
          columns={posPLColumns.map((column) =>
            singleDateSort(column, dateFormat),
          )}
          data={items}
          rowKey={(obj) => obj.posId}
          loading={loading}
          pagination={{
            current: pageNumber,
            onChange: (page) => {
              setPage(page);
            },
          }}
          rowSelection={rowSelection}
          data-test="pos-pldashboard"
        />
        <ErrorModalBasic
          visible={exportError}
          title="No data is available to export"
          onCancel={() => setExportError(false)}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default PoSPolandDashboard;
