import React, {useState, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import {Menu} from "antd";
import {debounce} from "lodash";

const DebouncedTextArea = ({keyValue, value, placeholder, onChange, reset}) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    if (reset) {
      setText("");
    }
  }, [reset, setText]);

  const debounceInput = useMemo(
    () =>
      debounce((target) => {
        onChange({target});
      }, 800),
    [onChange],
  );

  const handleDebouncedInput = ({target}) => {
    setText(target.value);

    debounceInput(target);
  };

  return (
    <Menu.Item key={keyValue}>
      <div className="h-16">
        <textarea
          name={keyValue}
          data-test={`forecast-textarea-${keyValue}`}
          className="w-72 h-full px-2 border border-gray-300 border-solid rounded"
          value={text}
          style={{resize: "none"}}
          onChange={(e) => handleDebouncedInput(e)}
          onKeyDown={(e) => e.stopPropagation()}
          placeholder={placeholder}
          maxLength="250"
        />
      </div>
    </Menu.Item>
  );
};

DebouncedTextArea.propTypes = {
  keyValue: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  reset: PropTypes.bool,
};

export default DebouncedTextArea;
