import React from "react";

import {useQuery} from "@apollo/client";
import {BUSINESS_PARTNER_ADDRESS_GET_QUERY} from "graphql/GlobalConfigCenter/businessPartnerAddress";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {BUSINESS_PARTNER_ADDRESS_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {BUSINESS_PARTNER_ADDRESS_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const BusinessPartnerAddressConfigPage = () => {
  const {data, loading} = useQuery(BUSINESS_PARTNER_ADDRESS_GET_QUERY);

  return (
    <Layout
      title={BUSINESS_PARTNER_ADDRESS_CONFIG_PAGE.title}
      isGlobal
      description={BUSINESS_PARTNER_ADDRESS_CONFIG_PAGE.description}
      tableColumns={BUSINESS_PARTNER_ADDRESS_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetBusinessPartnerAddress.businessPartnerAddress
      }
    />
  );
};

export default BusinessPartnerAddressConfigPage;
