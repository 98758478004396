import PropTypes from "prop-types";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormFeedback,
} from "reactstrap";
import {useForm} from "react-hook-form";
import {Close} from "@bphxd/ds-core-react";

const RemoveFromBalancePopup = ({
  isOpen,
  onCancel = () => {},
  onConfirm = () => {},
  data,
}) => {
  const methods = useForm({
    defaultValues: {notes: ""},
    mode: "onTouched",
    shouldFocusError: true,
  });
  const {
    register,
    formState: {errors},
    trigger,
    getValues,
  } = methods;
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const handleRemove = async () => {
    const isValid = await trigger("notes", {shouldFocus: true});
    if (!isValid) return;
    onConfirm(getValues("notes"));
  };
  return (
    <Modal isOpen={isOpen} className="modal-dialog-centered">
      <ModalHeader close={<Close onClick={() => onCancel()} />}>
        Remove from balance
      </ModalHeader>
      <hr className="opacity-100 border-top border-1" />
      <ModalBody>
        <Form className="flex flex-col gap-8 w-full">
          {data?.outboundRecords?.productStatus === "CARRY FORWARD" &&
            data?.inboundRecord?.fromLocationCode === null && (
              <p className="text-sm text-red-500">
                <b>NOTE</b> : Removing this balance will result in reopening the
                prior period
              </p>
            )}
          <FormGroup>
            <Label for="notes" className="fw-bold">
              Notes
            </Label>
            <Input
              type="textarea"
              id="notes"
              {...computeProps("notes", {
                required: "Please enter a reason for removing from balance",
              })}
              invalid={!!errors.notes}
              maxLength={150}
              rows={3}
            />
            {errors.notes && (
              <FormFeedback>{errors.notes.message}</FormFeedback>
            )}
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              type="button"
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => onCancel()}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              type="button"
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent fw-bold"
              onClick={handleRemove}
            >
              Confirm remove balance
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

RemoveFromBalancePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  data: PropTypes.object.isRequired,
};

export default RemoveFromBalancePopup;
