import React from "react";
import {ReactComponent as Error} from "assets/images/svg/error.svg";
import {ReactComponent as Warning} from "assets/images/svg/warning.svg";
import {ReactComponent as Success} from "assets/images/svg/success.svg";
import PropTypes from "prop-types";

const icons = {
  FALSE: <Error title="FALSE" />,
  PARTIAL: <Warning title="PARTIAL" />,
  TRUE: <Success title="TRUE" />,
};

const StatusIcons = ({status}) => icons[status] || null;

StatusIcons.propTypes = {
  status: PropTypes.string,
};

export default StatusIcons;
