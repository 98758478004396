import * as yup from "yup";

export default yup
  .object()
  .shape({
    country: yup.string().label("Country"),
    complianceScheme: yup.string().label("Compliance Scheme").required(),
    compliancePeriod: yup.string().label("Compliance Period").required(),
    complianceFromDate: yup.string().label("From Date"),
    complianceToDate: yup.string().label("To Date"),

    complianceType: yup.string().label("Compliance Type"),
    bioType: yup.string().label("Bio Type"),
    subType: yup.string().label("Sub-Type"),
    legalEntity: yup.string().label("Legal Entity"),
  })
  .required();
