import {useLocation} from "react-router-dom";
import "../../components/index.scss";
import {
  PAGE_TITLE,
  PAGE_SUBTITLE,
} from "modules/co-processing/constants/coProcessing";
import MissingReceiptsForm from "modules/co-processing/components/Receipts/MissingReceiptsForm";
import MissingBatchesForm from "modules/co-processing/components/Batches/MissingBatchesForm";
import DocumentViewer from "modules/co-processing/components/Shared/DocumentViewer";

const DocumentPage = () => {
  const {state, pathname} = useLocation();

  return (
    <div className="grid grid-cols-2 co-processing-document overflow-x-auto">
      <div className="col-span-1 mx-[40px]">
        <div className="mt-2 pb-8">
          <div className="text-[32px]">{PAGE_TITLE.MISSING_INFO}</div>
          <div>{PAGE_SUBTITLE.MISSING_INFO}</div>
        </div>
        {!pathname.includes("batches") && (
          <MissingReceiptsForm id={state?.id} />
        )}
        {pathname.includes("batches") && <MissingBatchesForm id={state?.id} />}
      </div>
      <div className="col-span-1">
        <DocumentViewer url={state?.url} />
      </div>
    </div>
  );
};

export default DocumentPage;
