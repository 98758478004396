import Heading from "DLS/Typography/Heading";
import PropTypes from "prop-types";
import "./index.scss";

const PreviewTable = ({tableTitle, columns, data}) => (
  <div className="bp-preview-table" data-test="preview-table">
    <div className="table-header">
      <Heading level={4}>{tableTitle}</Heading>
    </div>
    <div className="scrollable-container">
      <table>
        <thead>
          <tr className="header-row">
            {columns?.map(({dataIndex, title, showInPreview}) =>
              showInPreview === false ? null : <th key={dataIndex}>{title}</th>,
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((dataRow) => (
            <tr className="data-row" key={dataRow.key}>
              {columns?.map(({dataIndex, showInPreview, render, width}) => {
                const renderItem = render
                  ? render(dataRow[dataIndex])
                  : dataRow[dataIndex];

                if (showInPreview === false) {
                  return null;
                }
                return (
                  <td key={dataIndex} style={{width}}>
                    {renderItem}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

PreviewTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default PreviewTable;
