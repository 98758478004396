import {React} from "react";
import PropTypes from "prop-types";
import {
  ADD_COMMENTS,
  PLACEHOLDER_TEXT,
} from "modules/forecasting/constants/inventory";

const CommentSection = ({comments, updateComments}) => {
  return (
    <div className="w-full">
      <div className="pt-6 pb-1 add-comments-styles">{ADD_COMMENTS}</div>
      <div className="w-full lg:w-2/3 h-36">
        <textarea
          data-test="edit-forecast-textarea"
          className="w-full h-full px-2 border border-gray-300 border-solid rounded"
          value={comments}
          style={{resize: "none"}}
          onChange={(e) => updateComments(e.target.value)}
          placeholder={PLACEHOLDER_TEXT.ADD_COMMENTS}
          maxLength="1000"
        />
      </div>
    </div>
  );
};

CommentSection.propTypes = {
  comments: PropTypes.string,
  updateComments: PropTypes.func,
};

export default CommentSection;
