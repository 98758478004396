import {Menu as AMenu} from "antd";
import PropTypes from "prop-types";
import SideArrowIcon from "assets/images/svg/side-arrow-icon.svg";
import DownArrowIcon from "assets/images/svg/down-arrow-icon.svg";

import "./index.scss";

const Menu = ({children, className = "", ...props}) => (
  <div className={["bp-menu", className].join(" ")}>
    <AMenu inlineIndent={0} mode="inline" {...props}>
      {children}
    </AMenu>
  </div>
);

const Item = ({children, ...props}) => (
  <AMenu.Item {...props}>{children}</AMenu.Item>
);

const SubMenuExpandIcon = ({isOpen}) => {
  const icon = isOpen ? DownArrowIcon : SideArrowIcon;
  return <img src={icon} alt="expand/collapse icon" />;
};

SubMenuExpandIcon.propTypes = {
  isOpen: PropTypes.bool,
};

const SubMenu = ({children, ...props}) => (
  <AMenu.SubMenu expandIcon={SubMenuExpandIcon} {...props}>
    {children}
  </AMenu.SubMenu>
);

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
};

Menu.SubMenu = SubMenu;
Menu.Item = Item;
export default Menu;
