import PropTypes from "prop-types";
import {Input} from "reactstrap";

// Helper function to get the contract number or deal number
const getItem = (type, element) => {
  return type === "Incoming"
    ? element?.original?.contract_number
    : element?.original?.deal_number;
};

// Helper function to get the balance group
const getBalanceGroup = (element) => {
  return element?.original?.balance_group_name;
};

// Helper function to get the UOM
const getUOM = (type, element) => {
  return type === "Incoming" && element?.original?.product_qty_uom;
};

// Helper function to count selected rows with contract number
const countSelectedRowsWithContractNumber = (
  rowList,
  selectedValue,
  selectedBalanceGroup,
  selectedUOM,
  type,
) => {
  return rowList.reduce((count, element) => {
    const item = getItem(type, element);
    const checkBalanceGroup = getBalanceGroup(element);
    const checkUOM = getUOM(type, element);

    if (
      (!item && !element.getIsSelected()) ||
      (item === selectedValue &&
        checkBalanceGroup === selectedBalanceGroup &&
        checkUOM === selectedUOM &&
        !element.getIsSelected())
    ) {
      return count + 1;
    }

    return count;
  }, 0);
};

// Helper function to handle other count
const otherCountHandler = (
  rowList,
  selectedValue,
  selectedBalanceGroup,
  selectedUOM,
  type,
) => {
  return rowList.reduce((count, element) => {
    const item = getItem(type, element);
    const checkBalanceGroup = getBalanceGroup(element);
    const checkUOM = getUOM(type, element);

    if (
      item === selectedValue &&
      checkBalanceGroup === selectedBalanceGroup &&
      checkUOM === selectedUOM
    ) {
      return count + 1;
    }

    return count;
  }, 0);
};

// Helper function to check if all selected rows are empty
const isAllSelectedRowsEmpty = (table, type) => {
  return table
    .getSelectedRowModel()
    .rows?.every((item) => getItem(type, item) === undefined);
};

// Helper function to get selected row data
const getSelectedRowData = (table, type) => {
  return table
    .getSelectedRowModel()
    .rows?.filter((item) => getItem(type, item) !== undefined);
};

// Helper function to check header
const headerChecked = (table, type) => {
  const rowList = table.getRowModel().rows;
  const isAllEmptySelected = isAllSelectedRowsEmpty(table, type);
  const selectedRowData = getSelectedRowData(table, type);

  const selectedValue = getItem(type, selectedRowData?.[0]);
  const selectedBalanceGroup = getBalanceGroup(selectedRowData?.[0]);
  const selectedUOM = getUOM(type, selectedRowData?.[0]);

  const count = countSelectedRowsWithContractNumber(
    rowList,
    selectedValue,
    selectedBalanceGroup,
    selectedUOM,
    type,
  );

  return (
    !isAllEmptySelected &&
    (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) &&
    !(count > 0)
  );
};

const TableCheckbox = ({table, type, otherRows, otherData}) => {
  const isOtherData = Object.keys(otherRows)
    .map((index) => Number(index))
    .map((index) => otherData[index]);

  const selectedOtherData =
    type === "Incoming"
      ? isOtherData?.find((item) => item?.deal_number !== undefined)
          ?.deal_number
      : isOtherData?.find((item) => item?.contract_number !== undefined)
          ?.contract_number;

  const rowList = table.getRowModel().rows;

  const selectedRowData = getSelectedRowData(table, type);

  const isAllEmptySelected = isAllSelectedRowsEmpty(table, type);

  const selectedValue = getItem(type, selectedRowData?.[0]);
  const selectedBalanceGroup = getBalanceGroup(selectedRowData?.[0]);
  const selectedUOM = getUOM(type, selectedRowData?.[0]);

  const count = countSelectedRowsWithContractNumber(
    rowList,
    selectedValue,
    selectedBalanceGroup,
    selectedUOM,
    type,
  );

  const otherCount = otherCountHandler(
    otherData,
    selectedOtherData,
    selectedBalanceGroup,
    selectedUOM,
    type === "Incoming" ? "Purchase" : "Incoming",
  );

  const handleChange = () => {
    rowList.forEach((element) => {
      const checkElement = getItem(type, element);
      const checkBalanceGroup = getBalanceGroup(element);
      const checkUOM = getUOM(type, element);

      if (count === 0 && element.getIsSelected()) {
        element.toggleSelected(false);
      } else if (
        (!checkElement && !element.getIsSelected()) ||
        (((checkElement === selectedValue && !element.getIsSelected()) ||
          (checkElement === selectedOtherData && !element.getIsSelected())) &&
          checkBalanceGroup === selectedBalanceGroup &&
          checkUOM === selectedUOM)
      ) {
        element.toggleSelected();
      }
    });
  };

  return (
    <div className="d-flex align-items-center">
      <Input
        type="checkbox"
        checked={headerChecked(table, type)}
        onChange={handleChange}
        disabled={
          isAllEmptySelected ||
          (otherCount === 0 &&
            !table.getIsSomeRowsSelected() &&
            !table.getIsAllRowsSelected())
        }
      />
    </div>
  );
};

TableCheckbox.propTypes = {
  table: PropTypes.object,
  type: PropTypes.string,
  otherRows: PropTypes.object,
  otherData: PropTypes.array,
};

export default TableCheckbox;
