import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Alert32} from "@bphxd/ds-core-react/lib/icons";
import {useFormContext} from "react-hook-form";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Label,
  Accordion,
  Input,
  FormFeedback,
} from "reactstrap";

import Datepicker from "../../Datepicker";
import SupplierAndRecipientInfo from "./SupplierAndRecipientInfo";
import GeneralInformation from "./GeneralInformationV351";
import "../generateCertificate.css";
import ChainofCustody from "./ChainofCustody";
import SustainabilityCriteria from "./SustainabilityCriteria";
import VoluntaryAddons from "./VoluntaryAddons";
import InformationforDeliveries from "./InformationforDeliveries";

const GenerateCertificatePageISCCplusV351 = ({data}) => {
  const {
    formState: {errors},
    register,
    control,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [open, setOpen] = useState([]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const invalidTabs = {
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  };

  useEffect(() => {
    setValue("uniqueNumber", data?.generatedCertificateId);
    setValue("issueDate", new Date(data?.issueDate));
  }, [data, setValue]);

  return (
    <div>
      <FormGroup className="pb-6">
        <Label for="uniqueNumber" className="fw-light">
          Unique number of sustainability declaration
        </Label>

        <Input
          {...computeProps("uniqueNumber")}
          type="text"
          id="uniqueNumber"
          name="uniqueNumber"
          invalid={!!errors.uniqueNumber}
          maxLength={100}
          disabled
        />

        <FormFeedback>{errors?.uniqueNumber?.message}</FormFeedback>
      </FormGroup>
      <FormGroup className="pb-6">
        <Label for="issueDate" className="fw-light">
          Date of Issuance
        </Label>

        <Datepicker
          id="issueDate"
          name="issueDate"
          control={control}
          error={errors.issueDate}
          rules={{required: "Please enter date of issuance"}}
          disabled
        />

        <FormFeedback>{errors?.issueDate?.message}</FormFeedback>
      </FormGroup>
      <Accordion open={open} {...{toggle}} flush>
        <AccordionItem>
          <AccordionHeader targetId="supplier">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.supplier && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">
                Supplier and recipient information
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="supplier" className="mb-2 mt-6">
            <SupplierAndRecipientInfo data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="general">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.general && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">General information</div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="general" className="mb-2 mt-6">
            <GeneralInformation countries={data?.countries ?? []} data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="chainofCustody">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.certification && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">Chain of Custody</div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="chainofCustody" className="mb-2 mt-6">
            <ChainofCustody data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="sustainabilityCriteria">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.certification && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">Sustainability criteria</div>
            </div>
          </AccordionHeader>
          <AccordionBody
            accordionId="sustainabilityCriteria"
            className="mb-2 mt-6"
          >
            <SustainabilityCriteria data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="voluntaryAddons">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.certification && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">Voluntary add-ons</div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="voluntaryAddons" className="mb-2 mt-6">
            <VoluntaryAddons data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="informationforDeliveries">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.certification && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">
                Information for deliveries of biofuels to Japan
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody
            accordionId="informationforDeliveries"
            className="mb-2 mt-6"
          >
            <InformationforDeliveries data={data} />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

GenerateCertificatePageISCCplusV351.propTypes = {
  data: PropTypes.object,
};

export default GenerateCertificatePageISCCplusV351;
