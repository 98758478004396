import moment from "moment";
import {DDV, TDV, parseToFloatNumber} from "../components/view-document/utils";

export const getViewDocumentDetailsACCEPT = (
  data,
  splitDetails,
  emissionForTransport,
  account,
  divisionId,
  siteReferenceId,
  docId,
  dateFormat,
  document,
) => {
  return {
    divisionId,
    siteReferenceId,
    userAction: "ACCEPT",
    userId: account.username,
    rejectionReason: "",
    certificateInboundEuId: docId,

    replacePreviousVersion: data.replacePreviousVersion,
    previousVersionId: data.previousVersionId,
    additionalNotes: data.additionalNotes,
    documentData: {
      dateOfIssuance: moment(data.dateOfIssuance, dateFormat).format(
        "YYYY-MM-DD HH:mm:ss.0 ZZ",
      ),
      supplierName: data.supplierName,
      supplierAddress: data.supplierAddress,
      certificationSystem: data.certificationSystem,
      certificateNumber: data.certificateNumber,

      sdNumber: document?.sdNumber,
      sdNumberNew: data.sdNumber,
      sdNumberOld: document?.sdNumber,

      replacePreviousVersion: data.replacePreviousVersion,

      recipientName: data.recipientName,
      recipientAddress: data.recipientAddress,
      contractNumber: data.contractNumber,
      supplierDispatchAddress: data.supplierDispatchAddress,
      recipientReceiptAddress: data.recipientReceiptAddress,
      materialDispatchDatez: moment(
        data.materialDispatchDatez,
        dateFormat,
      ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
      bioProductProducer: data.bioProductProducer,
      bioProductUser: data.bioProductUser,
      invoiceProviderName: data.invoiceProviderName,
      disaggregatedDefaultValueOilFlag: data.disaggregatedDefaultValueOilFlag,
      disaggregatedDefaultValueSoiln2oFlag:
        data.disaggregatedDefaultValueSoiln2oFlag,

      bonusCo229gManureUsedFlag: data.bonusCo229gManureUsedFlag,
      bonusCo245gManureUsedFlag: data.bonusCo245gManureUsedFlag,
      supplychainIncentiveReceivedFlag: data.supplychainIncentiveReceivedFlag,
      supplychainIncentiveReceivedName: data.supplychainIncentiveReceivedName,
      vesselAtHuelva: data.vesselAtHuelva,
      productType: data.productType,
      rawMaterialType: data.rawMaterialType,
      additionalInfo: data.additionalInfo,

      rawMaterialOriginCountryName: data.rawMaterialOriginCountryName,

      feedstockQty: parseToFloatNumber(data.feedstockQty ?? "0"),
      feedstockQtyUom: data.feedstockQtyUom,

      productQty: parseToFloatNumber(data.productQty ?? "0"),
      productQtyUom: data.productQtyUom,

      euRedCompliantFlag: data.euRedCompliantFlag,
      wasteOrAnimalByProductPermitNumber:
        data.wasteOrAnimalByProductPermitNumber,
      supportForFuelProductionOrFuelPrecursorReceivedFlag:
        data.supportForFuelProductionOrFuelPrecursorReceivedFlag,
      ifYesSpecifySupportNatureAndScheme:
        data.ifYesSpecifySupportNatureAndScheme,
      isccCompliantFlag: data.isccCompliantFlag,
      chainOfCustodyOption: data.chainOfCustodyOption,
      materialSustainabilityCriteriaFlag:
        data.materialSustainabilityCriteriaFlag,
      agriculturalBiomassIntermediateCropFlag:
        data.agriculturalBiomassIntermediateCropFlag,
      agriculturalBiomassLowRiskFlag: data.agriculturalBiomassLowRiskFlag,
      rawMaterialWasteOrResidueFlag: data.rawMaterialWasteOrResidueFlag,
      defaultValueAppliedFlag: data.defaultValueAppliedFlag,
      productEnergyContent: data.productEnergyContent,
      nuts2Region: data.nuts2Region,
      motDistanceKm: parseToFloatNumber(data.motDistanceKm ?? "0"),
      ghgEmissionPercentageOut: parseToFloatNumber(
        data.ghgEmissionPercentageOut ?? "0",
      ),
      mbBalanceGroupId: data.mbBalanceGroupId,
      mbLocationGroupId: data.mbLocationGroupId,
      userInstallationDate: moment(
        data.userInstallationDate,
        dateFormat,
      ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
      producerInstallationDate: moment(
        data.producerInstallationDate,
        dateFormat,
      ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
      ghgEec: data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEec),
      ghgEl: data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEl ?? 0),
      ghgEp: data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEp ?? 0),
      ghgEtd:
        data.ghgTotal === TDV
          ? 0
          : data.ghgEtd === DDV
          ? data.ghgEtd
          : parseToFloatNumber(data.ghgEtd ?? "0"),
      ghgEu: data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEu ?? 0),
      ghgEsca:
        data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEsca ?? 0),
      ghgEccs:
        data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEccs ?? 0),
      ghgEccr:
        data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEccr ?? 0),
      ghgEee: data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEee ?? 0),
      ghgTotal:
        data.ghgTotal === TDV || data.ghgTotal === DDV
          ? data.ghgTotal
          : parseToFloatNumber(data.ghgTotal ?? "0"),
      physicalReceiptDate: moment(data.physicalReceiptDate, dateFormat).format(
        "YYYY-MM-DD HH:mm:ss.0 ZZ",
      ),
      ghgEmissionPercentageIn: parseToFloatNumber(
        data.ghgEmissionPercentageIn ?? "0",
      ),

      transportationEmissionId: data.transportationEmissionId,
      transportationEmissionRoute: data.transportationEmissionRoute,

      splitDetails: splitDetails?.map((split) => ({
        ...split,
        feedstockQty: parseToFloatNumber(split.feedstockQty ?? "0"),
        mbLocation: data.mbLocationGroupId,
        physicalReceiptDate: moment(
          split.physicalReceiptDate,
          dateFormat,
        ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
        transportationEmissionId: emissionForTransport
          ? data.transportationEmissionId
          : null,
        transportationEmissionRoute: emissionForTransport
          ? data.transportationEmissionRoute
          : null,

        ghgEec:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEec ?? "0"),
        ghgEl:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEl ?? "0"),
        ghgEp:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEp ?? "0"),
        ghgEtd:
          split.ghgTotal === TDV
            ? 0
            : split.ghgEtd === DDV
            ? split.ghgEtd
            : parseToFloatNumber(split.ghgEtd ?? "0"),
        ghgEu:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEu ?? "0"),
        ghgEsca:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEsca ?? "0"),
        ghgEccs:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEccs ?? "0"),
        ghgEccr:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEccr ?? "0"),
        ghgEee:
          split.ghgTotal === TDV ? 0 : parseToFloatNumber(split.ghgEee ?? "0"),
        ghgTotal:
          split.ghgTotal === TDV || split.ghgTotal === DDV
            ? split.ghgTotal
            : parseToFloatNumber(split.ghgTotal ?? "0"),
      })),
    },
  };
};

export const getViewDocumentDetailsREJECT = (
  data,
  splitDetails,
  emissionForTransport,
  account,
  divisionId,
  siteReferenceId,
  docId,
  dateFormat,
) => {
  return {
    divisionId,
    siteReferenceId,

    userId: account.username,
    certificateInboundEuId: docId,
    // previousVersionSdNumber: null,
    rejectionReason: data.rejectionReason,
    replacePreviousVersion: false,
    additionalNotes: data.rejectionReasonNotes,
    userAction: "REJECT",
  };
};

export default {getViewDocumentDetailsACCEPT, getViewDocumentDetailsREJECT};
