import PropTypes from "prop-types";
import {Table} from "reactstrap";
import {CircleCheck32} from "@bphxd/ds-core-react/lib/icons";
import {
  TABLE_LABELS,
  METRICS,
} from "modules/co-processing/constants/bulkAdjustment";
import BulkAdjustmentBatchPopover from "./BulkAdjustmentBatchPopover";

const BulkAdjustmentBatchesTable = ({batches, shipments}) => {
  return (
    <div
      data-test="bulk-adjustment-batches-table"
      className="overflow-x-auto w-full"
    >
      <Table>
        <thead>
          <tr>
            {batches?.map((batch, index) => (
              <th key={index} className="min-w-[145px]">
                <div
                  data-test={`bulk-adjustment-batch-element-${batch.batch_id}`}
                  id={`bulk-adjustment-popover-batch-id-${index}`}
                  className="cursor-pointer underline"
                >
                  {batch.batch_id}
                </div>
                <div className="text-gray-500">
                  {TABLE_LABELS.REMAINING_VOL}
                </div>
                <div>
                  {batch.remaining_volume} {METRICS.BBL}
                </div>
                <BulkAdjustmentBatchPopover batch={batch} index={index} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {shipments?.map((shipment, shipmentIndex) => (
            <tr key={shipmentIndex}>
              {batches?.map((batch, batchIndex) => (
                <td key={batchIndex} className="h-[65px]">
                  {batch?.shipments?.find(
                    (s) => s.shipment_id === shipment.shipment_id,
                  ) ? (
                    <CircleCheck32
                      color="neutral"
                      className="flex justify-center items-center"
                    />
                  ) : (
                    ""
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

BulkAdjustmentBatchesTable.propTypes = {
  batches: PropTypes.array,
  shipments: PropTypes.array,
};

export default BulkAdjustmentBatchesTable;
