import "./style.scss";
import {Table} from "antd";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {useUserSettings} from "providers/userSettings";
import {sortDefaultCondition} from "./sorters";

const TableWidget = ({
  columns,
  data,
  rowKey,
  loading,
  rowSelection,
  pagination,
  ...tableProp
}) => {
  const {userSettings = {dateFormat: "MM/DD/YYYY"}} = useUserSettings();

  const [sorterTable, setSorter] = useState({});

  const currentDataSource = useMemo(() => {
    const {columnKey, order} = sorterTable;
    if (columnKey && order) {
      const newData = [...data];
      const currentColumn = columns.find((col) => col.dataIndex === columnKey);
      newData.sort((a, b) => {
        return sortDefaultCondition(
          a,
          b,
          columnKey,
          order,
          currentColumn.inputType,
          currentColumn.dateFormat,
        );
      });
      return newData;
    }
    return data;
  }, [data, sorterTable, columns]);

  const handleChange = (sorter) => {
    setSorter(sorter);
  };

  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      return {
        ...col,
        render: (text, record, index) =>
          col.render
            ? col.render(
                text,
                record,
                index,
                currentDataSource,
                userSettings.dateFormat,
              )
            : text,
      };
    });
  }, [currentDataSource, columns, userSettings.dateFormat]);

  return (
    <div data-test="table">
      <Table
        rowKey={rowKey}
        rowSelection={rowSelection ?? null}
        columns={updatedColumns}
        dataSource={data}
        onChange={handleChange}
        loading={loading}
        pagination={{
          ...pagination,
          pageSizeOptions: ["10", "20", "50", "100"],
          showSizeChanger: true,
          showPrevNextJumpers: true,
          position: ["bottomLeft"],
          defaultPageSize: 20,
        }}
        scroll={{y: "100vh"}}
        {...tableProp}
      />
    </div>
  );
};

TableWidget.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  rowKey: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  rowSelection: PropTypes.object,
  pagination: PropTypes.object,
};

export default TableWidget;
