import PropTypes from "prop-types";
import {useState} from "react";
import {toast} from "react-toastify";
import {useLazyQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {
  Button,
  ModalBody,
  ModalFooter,
  Modal,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  BUTTONS,
  ADD_BATCH_FORM,
  STEPS,
  INITIAL_BATCH,
} from "modules/co-processing/constants/batches";
import {SAVE_BATCH} from "graphql/coprocessing/batches";
import {convertPSTtoUTC} from "modules/co-processing/helpers/formBuilder";
import {FormSummary, Form} from "./FormElements";
import {
  StepIndicator,
  StepTwo,
  StepThree,
  SaveBatch,
  TankNumber,
} from "./Steps";
import SuccessView from "./SuccessView";
import "./index.scss";
import useBatchModal from "./useModal";
import {useSplitBatch} from "./Context/SplitBatchContext";

const CURRENT_FORM_STEP = {
  [STEPS.INITIAL]: <TankNumber />,
  [STEPS.SECOND]: <StepTwo />,
  [STEPS.THIRD]: <StepThree />,
  [STEPS.SAVE]: <SaveBatch />,
};

const AddBatchModal = ({refetchBatches}) => {
  const {isModalOpen, openModal, closeModal} = useBatchModal();
  const {splitBatch} = useSplitBatch();
  const [batch, setBatch] = useState(INITIAL_BATCH);
  const [formStep, setFormStep] = useState(STEPS.INITIAL);

  const onNext = () => setFormStep(formStep + 1);
  const onBack = () => setFormStep(formStep - 1);

  const resetForm = () => {
    setBatch(INITIAL_BATCH);
    setFormStep(STEPS.INITIAL);
  };

  const [saveBatch, {called, loading: saving}] = useLazyQuery(SAVE_BATCH, {
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        refetchBatches();
        onNext();
      } else {
        toast.error(
          `${result?.bioLcCoproUsBatchesApi?.error ?? "Failed to save batch."}`,
        );
      }
      setBatch(INITIAL_BATCH);
    },
  });

  const handleSaveError = (error) => {
    closeModal();
    console.error("Failed to save batch", error);
    toast.error(`Failed to save batch: ${error.message}`);
  };

  const saveBatchDetails = async (batchFormValues) => {
    if (formStep === STEPS.SAVE) {
      const {valveOpen, latest_end_date, batch_tag, ...batchDetails} =
        batchFormValues;

      batchDetails.batch_start_time = convertPSTtoUTC(
        batchDetails.batch_start_time,
      );
      batchDetails.batch_end_time = convertPSTtoUTC(
        batchDetails.batch_end_time,
      );
      batchDetails.tank_certified_datez = convertPSTtoUTC(
        batchDetails.tank_certified_datez,
      );

      // Concatenate tag to batch_id if available
      const {tank_number, batch_code} = batchDetails;
      if (batch_tag) {
        batchDetails.batch_id = `${tank_number?.toLowerCase()}.${batch_tag}.${batch_code}`;
      }

      await saveBatch({
        variables: {batch_details: batchDetails},
        onError: (error) => handleSaveError(error),
      });
    } else {
      setBatch((prev) => ({...prev, ...batchFormValues}));
      onNext();
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const batchFormValues = {...batch, ...formData};
      await saveBatchDetails(batchFormValues);
    } catch (error) {
      handleSaveError(error);
    }
  };

  const isFullScreen =
    (formStep === STEPS.THIRD && !splitBatch) ||
    (formStep === STEPS.SAVE && splitBatch);

  return (
    <>
      <Button
        color="tertiary"
        className="bg-white rounded-0 ml-auto"
        onClick={openModal}
      >
        {BUTTONS.ADD_BATCH}
      </Button>

      <Modal
        className="lg:px-40"
        contentClassName="rounded-0"
        isOpen={isModalOpen()}
        fullscreen={isFullScreen}
        size="lg"
      >
        <ModalHeader
          className="border border-b rounded-0"
          close={<Close onClick={closeModal} />}
        >
          <div className="pl-3 fs-5">{ADD_BATCH_FORM.title}</div>
        </ModalHeader>

        <ModalBody className="p-0">
          {formStep === STEPS.SUCCESS ? (
            <SuccessView onReset={resetForm} closeModal={closeModal} />
          ) : (
            <Form defaultValues={batch} onSubmit={handleSubmit}>
              <StepIndicator currentStep={formStep} />
              <FormSummary formStep={formStep} />
              {CURRENT_FORM_STEP[formStep]}
            </Form>
          )}
        </ModalBody>

        {formStep < STEPS.SUCCESS ? (
          <ModalFooter>
            <div className="addBatch__btn-group">
              {formStep > STEPS.INITIAL ? (
                <Button color="light" onClick={onBack}>
                  {BUTTONS.BACK}
                </Button>
              ) : null}

              <Button
                type="submit"
                form="add_batch_form"
                color="primary"
                disabled={saving} // Disable button when mutation is in progress
              >
                {called && saving && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                {formStep < STEPS.SAVE ? BUTTONS.CONTINUE : BUTTONS.SAVE_BATCH}
              </Button>
            </div>
          </ModalFooter>
        ) : null}
      </Modal>
    </>
  );
};

AddBatchModal.propTypes = {
  refetchBatches: PropTypes.func,
};

export default AddBatchModal;
