import React from "react";
import PropTypes from "prop-types";
import {INDICATOR_CAPTION} from "../constants/inventory";

const IndicatorCaption = ({view, isWestCoast = true, isCredits = false}) => (
  <div className="flex pr-2">
    <span
      data-test="indicator-caption"
      className="pt-1 text-sm  text-[#89888c] font-normal leading-relaxed"
    >
      {view === "dataView" &&
        isWestCoast &&
        !isCredits &&
        INDICATOR_CAPTION.DATA_SCREEN}
      {view === "dataView" &&
        !(isWestCoast && !isCredits) &&
        INDICATOR_CAPTION.EAST_COAST_DATA_SCREEN}
      {view === "publishedView" && INDICATOR_CAPTION.PUBLISHED_SCREEN}
      {view === "unpublishedView" && INDICATOR_CAPTION.UNPUBLISHED_SCREEN}
    </span>
  </div>
);

IndicatorCaption.propTypes = {
  view: PropTypes.oneOf(["dataView", "publishedView", "unpublishedView"])
    .isRequired,
  isWestCoast: PropTypes.bool,
  isCredits: PropTypes.bool,
};

export default IndicatorCaption;
