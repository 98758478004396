import PropTypes from "prop-types";
import {useForm, useController} from "react-hook-form";
import {ReactTable} from "@bphxd/ds-core-react";
import {Button, Label, Input, FormGroup, FormFeedback} from "reactstrap";
import {BUTTONS, LABELS} from "modules/co-processing/constants/creditGen";

const ConfirmSubmissionCheckbox = ({control}) => {
  const {field: checkBoxField, fieldState} = useController({
    control,
    name: "isSubmissionConfirmed",
    rules: {required: true},
    shouldUnregister: true,
  });
  const {invalid} = fieldState;

  return (
    <div className="submission__checkbox">
      <FormGroup check>
        <Input
          checked={checkBoxField.value}
          data-test="isSubmissionConfirmed"
          id="isSubmissionConfirmed"
          type="checkbox"
          innerRef={checkBoxField.ref}
          {...checkBoxField}
        />
        <Label check for="isSubmissionConfirmed">
          {LABELS.ENSURE_DOC_READY_TO_BE_SUBMITTED}
        </Label>
      </FormGroup>

      <FormFeedback
        style={{
          display: "block",
          visibility: invalid ? "visible" : "hidden",
        }}
      >
        Please check box to confirm that the document is ready to be submitted.
      </FormFeedback>
    </div>
  );
};
ConfirmSubmissionCheckbox.propTypes = {
  control: PropTypes.object,
};

const PrepareSubmission = ({
  submissionData,
  tableColumns,
  onCancel,
  onConfirmSubmission,
}) => {
  const {control, handleSubmit, reset} = useForm({
    mode: "onSubmit",
    defaultValues: {isSubmissionConfirmed: false},
  });

  const handleCancel = () => {
    onCancel();
    reset();
  };

  return (
    <form
      className="submission__form"
      onSubmit={handleSubmit(onConfirmSubmission)}
    >
      <h3>
        Bioverse has prepared the EMTS template for [Current Month and Year].
        <br />
        Please review that the document is ready to be submitted.
      </h3>
      <ReactTable
        columns={tableColumns}
        data={submissionData ?? []}
        enableSorting
      />
      <ConfirmSubmissionCheckbox control={control} />

      <div className="submission__btn-group">
        <Button color="standard-tertiary" onClick={handleCancel}>
          {BUTTONS.CANCEL}
        </Button>
        <Button color="primary" type="submit">
          {BUTTONS.CONTINUE}
        </Button>
      </div>
    </form>
  );
};

PrepareSubmission.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirmSubmission: PropTypes.func.isRequired,
  tableColumns: PropTypes.array,
  submissionData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default PrepareSubmission;
