import {useLazyQuery} from "@apollo/client";
import {Remove32} from "@bphxd/ds-core-react/lib/icons";
import {UPDATE_BATCH} from "graphql/coprocessing/batches";
import {BATCH_UPDATE_MODAL} from "modules/co-processing/constants/batches";
import PropTypes from "prop-types";
import {useState} from "react";
import {toast} from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  ModalFooter,
} from "reactstrap";

const EditC14Modal = ({onClose, field, batchId, refetch, previousValue}) => {
  const [selectedOption, setSelectedOption] = useState(previousValue);

  const [updateBatch] = useLazyQuery(UPDATE_BATCH, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        toast.success(BATCH_UPDATE_MODAL.UPDATE_SUCCESS);
        refetch();
        onClose();
      } else {
        toast.error(
          `"${BATCH_UPDATE_MODAL.UPDATE_FAILURE} - ${result?.bioLcCoproUsBatchesApi?.error}"`,
        );
      }
    },
  });

  return (
    <Modal id="edit-modal" isOpen size="sm" data-test="edit-modal">
      <ModalHeader
        data-test="edit-modal-header"
        id="edit-modal-header"
        close={<Remove32 onClick={onClose} />}
      >
        {BATCH_UPDATE_MODAL.EDIT} {field}
      </ModalHeader>
      <ModalBody
        id="edit-modal-body"
        className="pb-0"
        data-test="edit-modal-body"
      >
        <span>
          <Label className="mr-5">{field}</Label>
          <Input
            type="checkbox"
            onChange={(e) => setSelectedOption(e.target.checked)}
            checked={selectedOption}
            className="mb-11 mt-1"
          />
        </span>
      </ModalBody>
      <ModalFooter>
        <div className="edit-modal-buttons w-100">
          <Button className="btn btn-light rounded-0 w-50" onClick={onClose}>
            {BATCH_UPDATE_MODAL.CANCEL}
          </Button>
          <Button
            className="btn btn-light rounded-0 w-50"
            onClick={() => {
              updateBatch({
                variables: {
                  batch_id: batchId,
                  updates: [
                    {
                      column: "c14_detail_id",
                      value: selectedOption ? "NOT_APPLICABLE" : null,
                    },
                    {
                      column: "batch_status",
                      value: selectedOption ? "not_qualified" : "tracked_out",
                    },
                  ],
                },
              });
            }}
          >
            {BATCH_UPDATE_MODAL.SAVE}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

EditC14Modal.propTypes = {
  onClose: PropTypes.func,
  field: PropTypes.string,
  batchId: PropTypes.string,
  refetch: PropTypes.func,
  previousValue: PropTypes.any,
};

export default EditC14Modal;
