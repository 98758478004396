import * as yup from "yup";

export const SchemaAssigntoTransfer = yup
  .object()
  .shape({
    quantity: yup.string().label("Quantity").required(),
    transferLocation: yup.string().label("Transfer Location").required(),
    modeOfTransportation: yup
      .string()
      .label("Mode Of Transportation")
      .required(),
    dispatchDate: yup.string().label("Date of transfer").required(),
  })
  .required();

export default SchemaAssigntoTransfer;
