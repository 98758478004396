import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {flexRender} from "@tanstack/react-table";
import {lowerCase} from "lodash";
import Filter from "modules/GlobalMassBalance/components/Filter/Filter";
import {massBalanceType} from "modules/GlobalMassBalance/constants";
import SpinnerLoading from "modules/common/SpinnerLoading";
import PropTypes from "prop-types";
import {Fragment, useMemo} from "react";
import {useParams} from "react-router-dom";
import Checkbox from "./components/Checkbox";

const getSortOpacity = (isSorted, sortDirection) => {
  if (!isSorted || (isSorted && isSorted !== sortDirection)) {
    return "opacity-20";
  }
  return "";
};
export const getPinningStyle = (column, data = undefined) => {
  const rightPx = data && data.length === 0 ? 0 : 6;
  const isPinned = column.getIsPinned();
  return {
    left: isPinned === "left" ? `${column.getStart("left") + 0}px` : undefined,
    right:
      isPinned === "right"
        ? `${column.getStart("right") + rightPx}px`
        : undefined,
    position: isPinned ? "sticky" : "relative",
    width: column.getSize(),
    zIndex: isPinned ? 1 : null,
  };
};

const createCheckboxColumn = (type) => ({
  header: () => <div style={{backgroundColor: "#fafafa"}}> </div>,
  accessorKey: "checkbox",
  enableSorting: false,
  size: 25,
  cell: ({row, table}) => <Checkbox row={row} table={table} type={type} />,
});

const createConditionalCheckboxColumn = (type) => ({
  ...createCheckboxColumn(type),
  cell: ({row, table}) => <Checkbox row={row} table={table} type={type} />,
});

export const getColumns = (columns, enableRowSelection, type) => {
  if (!enableRowSelection) {
    return columns;
  }

  const newColumn =
    type === "Incoming"
      ? createCheckboxColumn(type)
      : createConditionalCheckboxColumn(type);

  return type === "Incoming"
    ? [newColumn, ...columns]
    : [...columns, newColumn];
};

export const placeholderHeader = (header) => {
  return (
    <th
      key={header.id}
      colSpan={header.colSpan}
      aria-label="placeholder"
      style={{backgroundColor: "white", borderBottomColor: "#eee"}}
    />
  );
};
export const nonSortableHeader = (header) => {
  return (
    <th
      // align-top for row selection and row options columns so positioning is same as <td>
      className="align-top"
      key={header.id}
      colSpan={header.colSpan}
      style={{
        ...getPinningStyle(header.column),
        backgroundColor: header.column.id === "checkbox" ? "#fafafa" : "white",
        borderBottomColor: "#eee",
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};
export const SortableHeader = ({header, showFilter, data}) => {
  const {id, colSpan, column} = header;
  const {getToggleSortingHandler, getIsSorted, getCanFilter} = column;
  const isNumber = header.getContext().column.columnDef.dataType === "number";

  return (
    <th
      key={id}
      colSpan={colSpan}
      style={{
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        backgroundColor: "#fafafa",
        borderBottomColor: "#eee",
        minWidth: "180px",
        ...getPinningStyle(column, data),
      }}
    >
      <div
        className={`d-flex align-items-center ${
          isNumber && "justify-content-end"
        }`}
        onClick={getToggleSortingHandler()}
        onKeyDown={() => {}}
      >
        <div className="mr-[5px]">
          {flexRender(column.columnDef.header, header.getContext())}
        </div>
        <div>
          <TriangleUp16
            className={`mb-[-5px] ${getSortOpacity(getIsSorted(), "asc")}`}
          />
          <TriangleDown16
            className={`mt-[-5px] ${getSortOpacity(getIsSorted(), "desc")}`}
          />
        </div>
      </div>
      {getCanFilter() && showFilter ? (
        <div className="mt-[10px]">
          <Filter column={header.column} data={data} isParentKey={false} />
        </div>
      ) : null}
    </th>
  );
};

SortableHeader.propTypes = {
  header: PropTypes.object,
  showFilter: PropTypes.bool,
  data: PropTypes.array,
};

export const sortableHeader = (header, showFilter, data) => (
  <SortableHeader header={header} showFilter={showFilter} data={data} />
);

export const renderTableHeaders = (table, showFilter, data) => {
  return table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <Fragment key={header.id}>
          {header.isPlaceholder
            ? placeholderHeader(header)
            : header.column.getCanSort()
            ? sortableHeader(header, showFilter, data)
            : nonSortableHeader(header)}
        </Fragment>
      ))}
    </tr>
  ));
};

const EmptyRow = ({totalData}) => {
  const calculatedHeight = 815 + totalData * 52;
  return <tr style={{height: `calc(100vh - ${calculatedHeight}px)`}}></tr>;
};

EmptyRow.propTypes = {
  totalData: PropTypes.number,
};
const LoadingRow = () => (
  <tr className="h-[360px]">
    <td colSpan="100%">
      <div className="flex items-center justify-center ">
        <SpinnerLoading />
      </div>
    </td>
  </tr>
);
const DataRow = ({row, selectedRow, type}) => (
  <tr key={row.id} style={{height: "52px"}}>
    {row.getVisibleCells().map((cell) => {
      const isNumber = cell.getContext().column.columnDef.dataType === "number";
      const isSelected = selectedRow(cell);

      return (
        <td
          key={cell.id}
          className={isNumber ? "text-right" : ""}
          style={{
            backgroundColor: isSelected ? "#eef0f5" : undefined,
            whiteSpace: "nowrap",
            ...getPinningStyle(cell.column),
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      );
    })}
  </tr>
);

DataRow.propTypes = {
  row: PropTypes.object,
  selectedRow: PropTypes.func,
  type: PropTypes.string,
};

export const renderTableBody = (
  table,
  loading,
  totalData,
  selectedRow,
  type,
) => {
  if (loading) return <LoadingRow />;
  return (
    totalData > 0 &&
    table
      .getRowModel()
      .rows.map((row) => (
        <DataRow row={row} selectedRow={selectedRow} type={type} />
      ))
  );
};
export const renderEmptyStates = (
  loading,
  totalData,
  type,
  isIncomingFilter,
  isOutgoingFilter,
  setColumnFilter,
  otherData,
  recentFilter,
) => {
  return (
    <>
      {!loading && totalData !== 0 && totalData < 7 && (
        <EmptyRow totalData={totalData} />
      )}
      {!loading &&
        totalData === 0 &&
        ((type === "Incoming" && isIncomingFilter) ||
          (type === "Outgoing" && isOutgoingFilter)) && (
          <NoMatchFilterRow
            type={type}
            setColumnFilter={setColumnFilter}
            recentFilter={recentFilter}
          />
        )}
      {!loading &&
        totalData === 0 &&
        ((type === "Incoming" && !isIncomingFilter) ||
          (type === "Outgoing" && !isOutgoingFilter)) &&
        otherData.length !== 0 && (
          <AllAllocatedRow type={type} totalData={totalData} />
        )}
    </>
  );
};

const NoMatchFilterRow = ({type, setColumnFilter, recentFilter}) => {
  const recentFilterMessage = useMemo(() => {
    if (typeof recentFilter?.value === "object") {
      return `${recentFilter?.value[0]} - ${recentFilter?.value[1]}`;
    }
    return recentFilter?.value;
  }, [recentFilter]);

  return (
    <tr className="h-[calc(100vh-785px)]">
      <td
        colSpan="100%"
        className="text-[16px] !text-[#111111] text-center !pt-[110px]"
      >
        <p className="text-[26px]">
          No results found matching &apos;
          {recentFilterMessage}&apos;
        </p>
        <p className="text-[18px] font-light tracking-[0.2px] leading-[1.78]">
          Please try a different keyword
        </p>
      </td>
    </tr>
  );
};

NoMatchFilterRow.propTypes = {
  type: PropTypes.string,
  setColumnFilter: PropTypes.func,
  recentFilter: PropTypes.object,
};

const AllAllocatedRow = ({type, totalData}) => {
  const {type: mbType} = useParams();
  return (
    <tr className="h-[calc(100vh-785px)]">
      <td colSpan="100%">
        <div className="flex items-center flex-col justify-center mt-10 ml-10 mr-10 text-center">
          {type === "Incoming" ? (
            <>
              <p className="w-[400px] mb-[30px] text-base text-[#111]">
                It appears that you have successfully allocated all available
                purchases to outgoing transaction.
              </p>
              <p className="w-[400px] text-base text-[#111]">
                If you are missing any expected purchases in the system, please
                contact{" "}
                <span className="underline cursor-pointer">
                  BioVerse support team
                </span>{" "}
                for assistance.
              </p>
            </>
          ) : (
            <>
              {lowerCase(mbType) === lowerCase(massBalanceType.TR) && (
                <>
                  <p className="w-[400px] mb-[30px] text-base text-[#111]">
                    It appears that all available outgoing transaction have been
                    successfully allocated.
                  </p>
                  <p className="w-[400px] text-base text-[#111]">
                    If you are missing any expected outgoing transaction in the
                    system, please contact{" "}
                    <span className="underline cursor-pointer">
                      BioVerse support team
                    </span>{" "}
                    for assistance.
                  </p>
                </>
              )}
              {lowerCase(mbType) === lowerCase(massBalanceType.TRS) && (
                <p className="w-[400px] mb-[30px] text-base text-[#111]">
                  It appears that all available outgoing transactions have been
                  successfully allocated.
                </p>
              )}
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

AllAllocatedRow.propTypes = {
  type: PropTypes.string,
  totalData: PropTypes.number,
};
