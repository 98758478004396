import {gql} from "@apollo/client";

export const GET_RULE_BLOCKS = gql`
  query GetRuleBlocks {
    bioLcComplianceRuleEngineGetRuleBlocks {
      blocks {
        blockCode
        blockName
        ruleBlockMasterId
      }
      error
      statusCode
    }
  }
`;

export default GET_RULE_BLOCKS;
