import {
  DeleteItem,
  EditionItem,
  RowActionsMenu,
} from "modules/common/Table/ActionsMenu";
import StatusIcons from "content/StatusIcons";
import {
  deleteTaxPlantRegistryApi,
  GET_TAX_PLANT_REGISTRY,
  updateTaxPlantRegistryApi,
  createTaxPlantRegistryApi,
} from "graphql/gy-enablement/masterDetails/TaxPlantRegistry";
import {stringSorter, stringSorterExt} from "modules/common/Table";
import {client} from "providers/Apollo";
import masterTaxPlantRegistry from "../schemas/masterTaxPlantRegistry";
import TaxPlantRegistryForm from "../components/masterDetails/TaxPlantRegistry/TaxPlantRegistryForm";

export const masterTaxPlantRegistryColumns = [
  {
    title: "Company Code",
    dataIndex: "companyCode",
    key: "companyCode",
    ...stringSorter("companyCode"),
  },
  {
    title: "Legal Entity",
    dataIndex: "legalEntity",
    key: "legalEntity",
    ...stringSorter("legalEntity"),
  },
  {
    title: "Excise Registration Number",
    dataIndex: "registrationNumber",
    key: "registrationNumber",
    ...stringSorter("registrationNumber"),
  },
  {
    title: "Plant Name",
    dataIndex: "plantName",
    key: "plantName",
    ...stringSorter("plantName"),
  },
  {
    title: "Tax Liability AS",
    dataIndex: "taxLiability",
    key: "taxLiability",
    ...stringSorter("taxLiability"),
  },
  {
    title: "ED Group",
    dataIndex: "eDGroupName",
    key: "eDGroupName",
    ...stringSorter("eDGroupName"),
  },
  {
    title: "MOT Scope",
    dataIndex: "mot",
    key: "mot",
    sortDirections: ["ascend", "descend"],
    render: (value) => (
      <div className="flex justify-center">
        <StatusIcons status={value?.toString().toUpperCase()} />
      </div>
    ),
  },
  {
    title: "Main Customs Office",
    dataIndex: "mainCustomsOffice",
    width: 200,
    ellipsis: true,
    key: "mainCustomsOffice",
    ...stringSorterExt("mainCustomsOffice"),
    render: (mainCustomsOffice) => (
      <div className="div-dashboard-table-long-text">{mainCustomsOffice}</div>
    ),
  },
  {
    title: "Actions",
    key: "operation",
    fixed: "right",
    align: "center",
    width: 100,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    render: (data) => (
      <RowActionsMenu>
        <EditionItem
          mode="edit"
          schema={masterTaxPlantRegistry}
          modalTitle="Tax Plant Registry Details"
          formContent={<TaxPlantRegistryForm data={data} />}
          onSubmit={async (values) => {
            const {
              data: {updateTaxPlantRegistryAurora: response},
            } = await updateTaxPlantRegistryApi({id: data.id, ...values});
            return response;
          }}
        />
        <DeleteItem
          columns={masterTaxPlantRegistryColumns}
          data={data}
          // We refetch on 'onModalClose' to avoid getting
          // the modal closed when row is removed.
          onModalClose={(isSubmitted) =>
            isSubmitted &&
            client.refetchQueries({
              include: [GET_TAX_PLANT_REGISTRY],
            })
          }
          onSubmit={async (values) => {
            const {
              data: {deleteTaxPlantRegistryAurora: response},
            } = await deleteTaxPlantRegistryApi({id: data.id, ...values});
            return response;
          }}
        />
        <EditionItem
          mode="clone"
          schema={masterTaxPlantRegistry}
          modalTitle="Tax Plant Registry Details"
          formContent={<TaxPlantRegistryForm data={{...data, id: null}} />}
          onSubmit={async (values) => {
            const {
              data: {createTaxPlantRegistryAurora: response},
            } = await createTaxPlantRegistryApi(values);
            return response;
          }}
        />
      </RowActionsMenu>
    ),
  },
];
export default masterTaxPlantRegistryColumns;
