import PropTypes from "prop-types";
import {createContext, useContext, useMemo} from "react";

const RefetchContext = createContext(null);

export const useHistoricalRefetch = () => {
  const context = useContext(RefetchContext);

  if (!context) {
    throw new Error(
      "useHistoricalRefetch must be used within a HistoricalRefetchProvider",
    );
  }

  return context;
};

export const HistoricalRefetchProvider = ({children, refetch, loading}) => {
  const refetchValue = useMemo(() => ({refetch, loading}), [refetch, loading]);

  return (
    <RefetchContext.Provider value={refetchValue}>
      {children}
    </RefetchContext.Provider>
  );
};

HistoricalRefetchProvider.propTypes = {
  children: PropTypes.node.isRequired,
  refetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
