import {Button as AButton} from "antd";
import PropTypes from "prop-types";
import "./index.scss";

const typeClasses = {
  primary: "--primary",
  secondary: "--secondary",
  common: "--common",
  sm1: "--sm1",
};

const Button = ({
  children,
  type = "primary",
  className = "",
  icon,
  ...props
}) => (
  <AButton
    className={[
      "bp-button",
      `${icon ? "--has-icon" : ""}`,
      typeClasses[type],
      className,
    ].join(" ")}
    icon={icon}
    {...props}
  >
    {children}
  </AButton>
);

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "common", "sm1"]),
  icon: PropTypes.node,
};

export default Button;
