import React from "react";
import Modal from "modules/common/Modal";
import PropTypes from "prop-types";
import Success from "assets/images/svg/success.svg";
import Button from "DLS/Button";

const SuccessModal = ({
  title,
  visible,
  onCancel,
  description = "Thank you. Your file was uploaded successfully",
  showCrossArrow = true,
  onBack,
  onUpload,
}) => {
  return (
    <Modal
      data-test="success-modal"
      visible={visible}
      title={
        <>
          <img
            data-test="status-icon"
            className="w-12 h-12 mb-5"
            src={Success}
            alt=""
          />
          {title}
        </>
      }
      footer={null}
      onCancel={onCancel}
      closable={showCrossArrow}
      destroyOnClose
      width="42%"
    >
      <p className="pb-10">{description}</p>

      {onUpload ? (
        <div className="flex flex-col items-start w-full gap-2 pt-8 sm:flex-row">
          <Button
            className="w-full"
            label="Upload another certificate"
            type="secondary"
            data-test="upload-another-file"
            onClick={onUpload}
          />
          <Button
            type="primary"
            className="w-full"
            data-test="back-button"
            onClick={onBack}
            label="Back to dashboard"
          />
        </div>
      ) : (
        <Button
          label="Close"
          type="secondary"
          data-test="download"
          className="py-3 font-bold border-2 cursor-pointer px-14 text-indigo-500 border-indigo-500 hover:opacity-50"
          onClick={onCancel}
        />
      )}
    </Modal>
  );
};

SuccessModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  description: PropTypes.string,
  showCrossArrow: PropTypes.bool,
  onUpload: PropTypes.func,
};

export default SuccessModal;
