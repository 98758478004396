import PropTypes from "prop-types";
import {Table} from "antd";

const DashboardTable = ({
  rowSelection,
  columns,
  data,
  isLoading,
  rowKey,
  ...tableProps
}) => {
  return (
    <div className="flex items-center" id="div-dashboard-table">
      <Table
        data-test="dashboard-data-table"
        rowKey={rowKey}
        rowSelection={rowSelection}
        columns={columns}
        loading={isLoading}
        dataSource={data}
        pagination={{
          showSizeChanger: true,
          total: data?.length,
          showTotal: (total) => `Total ${total} items`,
          position: ["bottomLeft"],
          defaultPageSize: 10,
          pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
        }}
        scroll={{x: "max-content"}}
        {...tableProps}
      />
    </div>
  );
};

DashboardTable.propTypes = {
  rowSelection: PropTypes.object,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rowKey: PropTypes.string.isRequired,
};

export default DashboardTable;
