import PropTypes from "prop-types";
import {Modal, ModalBody, ModalFooter, Button} from "reactstrap";

const ConfirmationPopup = ({
  isOpen,
  toggle = () => {},
  onConfirm = () => {},
  title,
  message,
  cancelLabel = "Cancel",
  confirmLabel = "Confirm",
  onCancel = () => {},
}) => {
  return (
    <Modal
      className="w-72 modal-dialog-centered"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody className="flex flex-col w-full justify-center items-start gap-4 mb-4">
        <div className="text-center w-full">
          <h4 className="fw-bold">{title}</h4>
        </div>
        <div className="text-center w-full fs-6">{message}</div>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => onCancel()}
            >
              {cancelLabel}
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent fw-bold"
              onClick={() => onConfirm("Yes")}
            >
              {confirmLabel}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string.isRequired,
  message: PropTypes.node,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
};
export default ConfirmationPopup;
