import {useCallback, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import {useLazyQuery, useQuery} from "@apollo/client";
import {
  COPRO_US_GET_SHIPMENT_BOLS,
  COPRO_US_RESET_TRUCK_RACK_ALLOCATION,
} from "graphql/coprocessing/shipments";
import {
  ALLOCATION_RESET_SUCCESS,
  TITLES,
} from "modules/co-processing/constants/shipments";
import BulkAllocationResetTable from "modules/co-processing/components/Shipments/BulkReset/BulkAllocationResetTable";
import AllocationResetButtons from "modules/co-processing/components/Shipments/BulkReset/ResetActionButtons";
import Loading from "modules/common/SpinnerLoading";

const BulkAllocationResetPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [selectedShipments, setSelectedShipments] = useState(
    location.state?.selectedShipments || {bols: {}},
  );

  const shipmentIds = useMemo(
    () => Object.keys(selectedShipments),
    [selectedShipments],
  );

  const [allocationInfo, setAllocationInfo] = useState({});
  const allocatedTruckRackIds = useMemo(
    () => Object.keys(allocationInfo),
    [allocationInfo],
  );

  const {loading, data} = useQuery(COPRO_US_GET_SHIPMENT_BOLS, {
    variables: {shipmentIds},
    skip: !shipmentIds || shipmentIds?.length === 0,

    onCompleted: (data) => {
      const initialAllocation = {};
      const shipmentBOLs =
        data?.bioLcCoproUsShipmentsApi?.body?.shipment_bols || [];

      shipmentBOLs?.forEach(({shipment_id, bols = []}) => {
        initialAllocation[shipment_id] = {};
        bols.forEach((BOL) => {
          initialAllocation[shipment_id][BOL?.dtn_bol_layer_id] =
            BOL?.truck_lifting_volume ?? 0;
        });
      });

      setAllocationInfo(initialAllocation);
    },
  });

  const onSave = useCallback(() => {
    setSelectedShipments({});
    navigate("/co-processing/shipments");
  }, [navigate]);

  const [resetTruckRackAllocation, {loading: loadingReset}] = useLazyQuery(
    COPRO_US_RESET_TRUCK_RACK_ALLOCATION,
    {
      onCompleted: (response) => {
        const data = response?.bioLcCoproUsShipmentsApi;
        if (data?.error || data?.statusCode !== 200) {
          toast.error(data.error);
          return;
        }
        toast.success(ALLOCATION_RESET_SUCCESS);
        onSave();
      },
    },
  );

  const handleCheckboxChange = (shipmentId, bolId, truckLiftingVolume) => {
    setAllocationInfo((prevAllocations) => {
      const newState = {...prevAllocations};

      if (newState[shipmentId]?.[bolId]) {
        delete newState[shipmentId][bolId];
      } else {
        newState[shipmentId][bolId] = truckLiftingVolume;
      }

      return newState;
    });
  };

  const handleSaveAndClose = useCallback(() => {
    const updatedInfo = Object.entries(allocationInfo)?.map(([id, BOLData]) => {
      return {shipment_id: id, bols: Object.keys(BOLData)};
    });

    resetTruckRackAllocation({
      variables: {allocationInfo: updatedInfo},
    });
  }, [allocationInfo, resetTruckRackAllocation]);

  const shipmentBOLs =
    data?.bioLcCoproUsShipmentsApi?.body?.shipment_bols || [];

  const hasBOLEntries = useMemo(() => {
    return Object.values(allocationInfo).some(
      (shipment) => Object.keys(shipment).length > 0,
    );
  }, [allocationInfo]);

  const isSaveDisabled = !hasBOLEntries || loadingReset;

  return (
    <div className="overflow-x-auto px-7">
      <h1 className="text-[1.8125rem] pt-3">{TITLES.SHIPMENTS}</h1>
      <hr className="border-b-[1.5px] mb-6" />

      <div
        className="flex flex-col space-y-14"
        data-test="reset-allocation-section"
      >
        <div className="flex justify-between">
          <div
            className="text-[26px] pr-3 font-light"
            data-test="allocation-reset-header"
          >
            {TITLES.BULK_ALLOCATION_RESET}
          </div>
          <AllocationResetButtons
            isSaveDisabled={isSaveDisabled}
            onCancel={() => navigate("/co-processing/shipments")}
            onSave={handleSaveAndClose}
          />
        </div>

        {loading && (
          <div className="flex justify-center h-40">
            <Loading />
          </div>
        )}

        {!loading && allocatedTruckRackIds?.length > 0 && (
          <BulkAllocationResetTable
            allocationInfo={allocationInfo}
            BOLData={shipmentBOLs?.flatMap((item) => item.bols)}
            selectedShipments={Object.values(selectedShipments)}
            handleCheckboxChange={handleCheckboxChange}
          />
        )}
      </div>
    </div>
  );
};

export default BulkAllocationResetPage;
