import * as yup from "yup";

export const SchemaGenerateCertificatePOS = yup
  .object()
  .shape({
    //  uniqueNumber: yup.string().label("Unique Number").required(),
  })
  .required();

export const SchemaGenerateCertificateBPMarketing = yup
  .object()
  .shape({
    //  uniqueNumber: yup.string().label("Unique Number").required(),
  })
  .required();

export default {
  SchemaGenerateCertificatePOS,
  SchemaGenerateCertificateBPMarketing,
};
