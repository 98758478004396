import {gql} from "@apollo/client";

export const TRANSACTION_API = gql`
  query bioLcGetMassBalanceTransactionData(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceTransactionData(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      mbTransactionData {
        outboundRecords {
          vesselNameOutgoing
          status
          transportSystemOutgoing
          quantityOutMt
          recipient
          document
          ghgSavingsOutgoing
          ghgTotalMjOutgoing
          issuanceDateOutgoing
          mbOutboundQtyEuId
          outboundType
          product
          quantityOutM3
        }
        inboundRecord {
          actualizedQuantityM3
          certified
          actualizedQuantityMt
          coProcessedQuantityMt
          countryOfOrigin
          ghgSavings
          ghgTotalMj
          incomingDocument
          issuanceDate
          invoiceNumber
          mbInboundQtyEuId
          mbBalanceGroupId
          mbLocationGroupId
          physicalReceiptDate
          productQtyM3
          productQtyMt
          rawMaterial
          receivedQuantityMt
          supplier
          transportLg
          transportSystem
          vesselName
          ghgEec
          ghgEl
          ghgEp
          ghgEtd
          ghgEu
          ghgEsca
          ghgEccs
          ghgTotal
          certificateInboundEuId
          mbPeriodStatusId
          fromMbLocationGroupId
          actualizationId
        }
      }
    }
  }
`;

export default TRANSACTION_API;
