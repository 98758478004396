import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {uniqBy, orderBy, isEmpty} from "lodash";
import moment from "moment";
import {FormGroup, Label, Input, Button, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";
import "./index.css";

const seperationText = "#-#";

const FeedstockFilterContent = ({
  defaultValues,
  filterDynamicData,
  handleFilterSubmit,
  handleFilterClear,
}) => {
  const {
    register,
    watch,
    setValue,
    formState: {errors},
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchLocation = watch("location");
  const watchFeedstock = watch("feedstock");
  const watchPeriodMonth = watch("periodMonth");
  const watchPeriodYear = watch("periodYear");

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  const yearListItems = useMemo(() => {
    const yearItems = filterDynamicDataResult?.map((item) => ({
      fifoYear: item.fifoYear,
    }));
    const uniqueYearItems = uniqBy(yearItems, "fifoYear");
    return orderBy(uniqueYearItems, "fifoYear", "desc");
  }, [filterDynamicDataResult]);

  const monthListItems = useMemo(() => {
    const monthItems = filterDynamicDataResult
      ?.filter(
        (o) =>
          o.fifoYear ===
          (isEmpty(watchPeriodYear) ? o.fifoYear : watchPeriodYear),
      )
      ?.map((item) => ({
        fifoMonth: item.fifoMonth,
      }));

    const uniqueMonthItems = uniqBy(monthItems, "fifoMonth");
    return orderBy(uniqueMonthItems, "fifoMonth", "desc");
  }, [filterDynamicDataResult, watchPeriodYear]);

  const locationList = useMemo(() => {
    const locList = filterDynamicDataResult
      ?.filter(
        (o) =>
          o.fifoYear ===
            (isEmpty(watchPeriodYear) ? o.fifoYear : watchPeriodYear) &&
          o.fifoMonth ===
            (isEmpty(watchPeriodMonth) ? o.fifoMonth : watchPeriodMonth),
      )
      ?.map((item) => ({
        locationId: item.locationId,
        locationName: item.locationName,
      }));

    const uniqueLocationItems = uniqBy(locList, "locationId");
    return orderBy(uniqueLocationItems, "locationName", "asc");
  }, [filterDynamicDataResult, watchPeriodMonth, watchPeriodYear]);

  const feedstockList = useMemo(() => {
    const locationDetails = watchLocation?.split(seperationText);
    const feedstockListItems =
      locationDetails &&
      filterDynamicDataResult
        ?.filter(
          (o) =>
            o.locationId ===
              (isEmpty(locationDetails[0])
                ? o.locationId
                : locationDetails[0]) &&
            o.fifoYear ===
              (isEmpty(watchPeriodYear) ? o.fifoYear : watchPeriodYear) &&
            o.fifoMonth ===
              (isEmpty(watchPeriodMonth) ? o.fifoMonth : watchPeriodMonth),
        )
        ?.map((item) => ({
          materialId: item.materialId,
          materialShortName: item.materialShortName.trim(),
          sapMaterialCode: item.sapMaterialCode,
          sapNCCode: item.sapNCCode,
        }));

    const uniqueFeedstockItems = uniqBy(feedstockListItems, "materialId");
    return orderBy(uniqueFeedstockItems, "materialShortName", "asc");
  }, [
    filterDynamicDataResult,
    watchLocation,
    watchPeriodYear,
    watchPeriodMonth,
  ]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setValue("periodYear", defaultValues?.periodYear);
      setValue("periodMonth", defaultValues?.periodMonth);
      setValue("location", defaultValues?.location);
      setValue("feedstock", defaultValues?.feedstock);
    }
  }, [
    setValue,
    defaultValues?.location,
    defaultValues?.periodMonth,
    defaultValues?.periodYear,
    defaultValues?.feedstock,
    filterDynamicDataResult,
  ]);

  useEffect(() => {
    if (
      !isEmpty(watchPeriodMonth) &&
      !monthListItems.find((item) => item.fifoMonth === watchPeriodMonth)
    ) {
      if (monthListItems?.length > 0) {
        setValue("periodMonth", monthListItems[0]?.fifoMonth);
      } else {
        setValue("periodMonth", null);
      }
    }
  }, [setValue, monthListItems, watchPeriodMonth]);

  useEffect(() => {
    if (
      !isEmpty(watchPeriodYear) &&
      !yearListItems.find((item) => item.fifoYear === watchPeriodYear)
    ) {
      if (yearListItems?.length > 0) {
        setValue("periodYear", yearListItems[0]?.fifoYear);
      } else {
        setValue("periodYear", null);
      }
    }
  }, [setValue, yearListItems, watchPeriodYear]);

  useEffect(() => {
    const locationDetails = watchLocation?.split(seperationText);
    if (
      !isEmpty(watchLocation) &&
      locationList?.length > 0 &&
      !locationList?.find((item) => item.locationId === locationDetails[0])
    ) {
      if (locationList?.length > 0) {
        setValue("location", locationList[0]?.locationId);
      } else {
        setValue("location", null);
      }
    }
  }, [setValue, locationList, watchLocation]);

  useEffect(() => {
    const feedstockDetails = watchFeedstock?.split(seperationText);
    if (
      !isEmpty(watchFeedstock) &&
      !feedstockList.find((item) => item.materialId === feedstockDetails[0])
    ) {
      setValue("feedstock", "");
    }
  }, [setValue, feedstockList, watchFeedstock]);

  const getfilterName = (...inputStrings) => {
    const filterName = inputStrings
      .map((str) => (str ? str.replace(/[ .]/g, "") : "null"))
      .join(seperationText);
    return filterName;
  };

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <Label className="pb-2 filter-by ">Filter by:</Label>
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="periodMonth" className="fw-normal">
            Month
          </Label>
          <Input
            type="select"
            id="periodMonth"
            data-test="periodMonth"
            {...computeProps("periodMonth", {
              required: "Please select month",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-32 bg-white"
            invalid={!!errors.periodMonth}
          >
            {monthListItems.map((month) => (
              <option key={month.fifoMonth} value={month.fifoMonth}>
                {moment(month.fifoMonth, "MM").format("MMMM")}
              </option>
            ))}
          </Input>
          {errors.periodMonth && (
            <FormFeedback>{errors.periodMonth.message}</FormFeedback>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="periodYear" className="fw-normal">
            Year
          </Label>
          <Input
            type="select"
            id="periodYear"
            data-test="periodYear"
            {...computeProps("periodYear", {
              required: "Please select year",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-32 bg-white"
            invalid={!!errors.periodYear}
          >
            {yearListItems.map((year) => (
              <option key={year.fifoYear} value={year.fifoYear}>
                {year.fifoYear}
              </option>
            ))}
          </Input>
          {errors.periodYear && (
            <FormFeedback>{errors.periodYear.message}</FormFeedback>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="location" className="fw-normal">
            Location
          </Label>
          <Input
            type="select"
            id="location"
            data-test="location"
            {...computeProps("location", {
              required: "Please select Location",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
            invalid={!!errors.location}
          >
            {locationList.map((location) => (
              <option
                key={location.locationId}
                value={`${location.locationId}${seperationText}${location.locationName}`}
              >
                {location.locationName}
              </option>
            ))}
          </Input>
          {errors.location && (
            <FormFeedback>{errors.location.message}</FormFeedback>
          )}
        </FormGroup>

        <FormGroup>
          <Label for="feedstock" className="fw-normal">
            Feedstock
          </Label>
          <Input
            type="select"
            id="feedstock"
            data-test="feedstock"
            {...computeProps("feedstock", {
              required: "Please select feedstock",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            <option key="none" value="">
              -- All --
            </option>
            {feedstockList.map((feedstock) => (
              <option
                key={feedstock.materialId}
                value={getfilterName(
                  feedstock.materialId,
                  feedstock.sapMaterialCode,
                  feedstock.sapNCCode,
                  feedstock.materialShortName,
                )}
              >
                {`${feedstock.materialShortName} - ${
                  defaultValues?.materialView === "sap_code"
                    ? feedstock.sapMaterialCode
                    : feedstock.sapNCCode
                }`}
              </option>
            ))}
          </Input>
        </FormGroup>

        <div className="flex py-6 gap-x-4 mt-1">
          <Button
            className="padded h-10 rounded-0  apply-button !w-32"
            color="standard-outline"
            data-test="filter-submit-button"
            type="button"
            form="fifo-feedstock-filter-form"
            onClick={handleFilterSubmit}
          >
            Apply filters
          </Button>
          <Button
            className="btn-clear rounded-0"
            color="standard-outline"
            data-test="filter-clear-button"
            onClick={handleFilterClear}
          >
            <span className="btn-clear--text">Clear</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

FeedstockFilterContent.propTypes = {
  defaultValues: PropTypes.object,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
  handleFilterClear: PropTypes.func,
};
export default FeedstockFilterContent;
