import {gql} from "@apollo/client";

export const GET_FIELD_OPTIONS = gql`
  query GetFieldOptionsAurora {
    getFieldOptionsAurora {
      data {
        mainCustomsOffice {
          values {
            companyCode
            eDGroup
            plant
            mainCustomsOffice
          }
        }
        categories {
          values {
            AT0H
            ENG1
            NL01
            DE01
          }
        }
        category {
          values
        }
        commodityCode {
          values
        }
        companyCode {
          values
        }
        taxLiability {
          values
        }
        eDGroup {
          values {
            code
            name
          }
        }
        plant {
          values {
            code
            name
          }
        }
        uoM {
          values
        }
        legalEntity {
          values
        }
        registrationNumber {
          values
        }
        taxLaws {
          values {
            companyCode
            law
          }
        }
        exciseRegistryNumber {
          value
          plant
        }
        motProfiles {
          values
        }
      }
    }
  }
`;

export const GET_MANUAL_ADJUSTMENT = gql`
  query GetManualAdjustmentQuery {
    getManualAdjustmentAurora {
      data {
        id
        companyCode
        fisc
        declaration
        eDGroup
        eDGroupName
        commodityCode
        loadPlant
        loadPlantName
        calEDQty
        uoM
        tHGRelevant
        gnETSRelevant
        description
        category
        taxLaw
        mainCustomsOffice
        categories {
          AT0H
          DE01
          ENG1
          NL01
        }
        formatDeclaration
        formatFisc
      }
      statusCode
    }
  }
`;

export const CREATE_MANUAL_ADJUSTMENT = gql`
  mutation CreateManualAdjustment(
    $record: CreateManualAdjustmentAuroraRequest!
  ) {
    createManualAdjustmentAurora(event: $record) {
      data {
        mainCustomsOffice
        uoM
        taxLaw
        tHGRelevant
        loadPlant
        gnETSRelevant
        eDGroup
        fisc
        description
        declaration
        companyCode
        commodityCode
        category
        calEDQty
        mainCustomsOffice
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const UPDATE_MANUAL_ADJUSTMENT = gql`
  mutation UpdateManualAdjustment(
    $updateRecord: UpdateManualAdjustmentAuroraRequest!
  ) {
    updateManualAdjustmentAurora(event: $updateRecord) {
      data {
        uoM
        taxLaw
        tHGRelevant
        loadPlant
        id
        gnETSRelevant
        eDGroup
        fisc
        description
        declaration
        companyCode
        commodityCode
        category
        calEDQty
        mainCustomsOffice
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const DELETE_MANUAL_ADJUSTMENT = gql`
  mutation DeleteManualAdjustment(
    $deleteRecord: DeleteManualAdjustmentAuroraRequest!
  ) {
    deleteManualAdjustmentAurora(event: $deleteRecord) {
      data {
        id
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const MANUAL_ADJUSTMENT_EXPORT = gql`
  query ManualAdjustmentExport(
    $fileType: String!
    $selectedRows: [String]
    $year: String
  ) {
    manualAdjustmentExport(
      event: {fileType: $fileType, selectedRows: $selectedRows, year: $year}
    ) {
      message
      data {
        url
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export default {
  MANUAL_ADJUSTMENT_EXPORT,
  GET_MANUAL_ADJUSTMENT,
  CREATE_MANUAL_ADJUSTMENT,
  UPDATE_MANUAL_ADJUSTMENT,
  DELETE_MANUAL_ADJUSTMENT,
};
