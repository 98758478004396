import {createContext, useContext, useState, useMemo, useCallback} from "react";
import PropTypes from "prop-types";

const SplitBatchContext = createContext();

export const SplitBatchProvider = ({children}) => {
  const [splitBatch, setSplitBatch] = useState(false);

  const toggleHandler = useCallback((toggleStatus = []) => {
    setSplitBatch(toggleStatus);
  }, []);

  const splitBatchContext = useMemo(
    () => ({
      splitBatch,
      setSplitBatch,
      toggleHandler,
    }),
    [splitBatch, toggleHandler, setSplitBatch],
  );

  return (
    <SplitBatchContext.Provider value={splitBatchContext}>
      {children}
    </SplitBatchContext.Provider>
  );
};

SplitBatchProvider.propTypes = {
  children: PropTypes.node,
};

export const useSplitBatch = () => {
  const context = useContext(SplitBatchContext);

  if (context === undefined) {
    throw new Error("useSplitBatch must be used inside SplitBatchProvider");
  }

  return context;
};
