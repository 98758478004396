import {useDraggable} from "@dnd-kit/core";
import PropTypes from "prop-types";
import React from "react";

const DraggableButton = ({id, data, children, className, disabled}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id,
    data,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  if (disabled) {
    return (
      <button
        ref={setNodeRef}
        style={{cursor: "not-allowed"}}
        {...attributes}
        className={className}
        type="button"
        disabled={disabled}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      className={className}
      type="button"
      disabled={disabled}
    >
      {children}
    </button>
  );
};

DraggableButton.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DraggableButton;
