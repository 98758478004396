import PropTypes from "prop-types";
import {isEmpty} from "lodash";
import {formatNumberIfExists} from "utils/numberUtil";
import {useUserSettings} from "providers/userSettings";
import {useMemo} from "react";
import "./index.css";

const measuringUnits = "mt";

const MoreDetails = ({data, uom}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const fifoDetails = useMemo(() => {
    return data?.moreDetailsData[0] ?? null;
  }, [data]);

  const fifoData = useMemo(() => {
    return data ?? null;
  }, [data]);

  return (
    <div className="flex flex-col justify-start items-stretch w-full gap-4">
      <div className="grid grid-cols-4 gap-4">
        {fifoData?.type !== "Rebrand" &&
          fifoData?.type !== "Gain" &&
          fifoData?.type !== "Loss" && (
            <>
              <div className="flex flex-col">
                <span className="border-b">Supplier</span>
                <span className="py-1 fw-bold ">
                  {isEmpty(fifoDetails?.supplier) ? "-" : fifoDetails?.supplier}
                </span>
              </div>
              <div className="flex flex-col col-span-2">
                <span className="border-b">SAP contract number - item</span>
                <span className="py-1 fw-bold ">
                  {isEmpty(fifoDetails?.sapContractNumber)
                    ? "-"
                    : fifoDetails?.sapContractNumber}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="border-b">Document number</span>
                <span className="py-1 fw-bold ">
                  {fifoDetails?.documentNumber ?? "-"}
                </span>
              </div>
              <div className="flex flex-col">
                <div className="border-b">
                  <span>Load quantity </span>
                  <span>{"\u00A0"}</span>
                  {uom === "t" && <span>{measuringUnits}</span>}
                  {uom !== "t" && (
                    <span>
                      m<sup>3</sup>
                    </span>
                  )}
                </div>
                <span className="py-1 fw-bold ">
                  {formatNumberIfExists(
                    uom === "t"
                      ? fifoDetails?.loadQtyMt
                      : fifoDetails?.loadQtyM3,
                    decimalFormat,
                    3,
                  ) ?? "-"}
                </span>
              </div>
              <div className="flex flex-col col-span-2">
                <div className="border-b">
                  <span>Discharged quantity</span>
                  <span>{"\u00A0"}</span>
                  {uom === "t" && <span>{measuringUnits}</span>}
                  {uom !== "t" && (
                    <span>
                      m<sup>3</sup>
                    </span>
                  )}
                </div>
                <span className="py-1 fw-bold ">
                  {formatNumberIfExists(
                    uom === "t"
                      ? fifoDetails?.dischargeQtyMt
                      : fifoDetails?.dischargeQtyM3,
                    decimalFormat,
                    3,
                  ) ?? "-"}
                </span>
              </div>
              <div className="flex flex-col"></div>
              <div className="flex flex-col">
                <span className="border-b">Incoterm</span>
                <span className="py-1 fw-bold ">
                  {isEmpty(fifoDetails?.incoterm) ? "-" : fifoDetails?.incoterm}
                </span>
              </div>

              <div className="flex flex-col col-span-2">
                <table className="more-details-purchase-details">
                  <thead>
                    <tr className="border-b">
                      <th>Purchase cost</th>
                      <th>€</th>
                      <th>
                        <div>
                          {uom === "t" && <span>€/{measuringUnits}</span>}
                          {uom !== "t" && (
                            <span>
                              €/m<sup>3</sup>
                            </span>
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="fw-bold">Expected</span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseExpectedCostMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseExpectedCostM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseExpectedRateMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseExpectedRateM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Invoiced</span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseInvoicedCostMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseInvoicedCostM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseInvoicedRateMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseInvoicedRateM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-bold">Difference</span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseDifferenceCostMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseDifferenceCostM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                      <td>
                        <span className="fw-bold">
                          {(fifoDetails?.purchase &&
                            formatNumberIfExists(
                              uom === "t"
                                ? fifoDetails?.purchase[0]
                                    ?.purchaseDifferenceRateMt
                                : fifoDetails?.purchase[0]
                                    ?.purchaseDifferenceRateM3,
                              decimalFormat,
                              2,
                            )) ??
                            "-"}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>
          )}
        {(fifoData?.type === "Rebrand" ||
          fifoData?.type === "Loss" ||
          fifoData?.type === "Gain") && (
          <div className="flex flex-col">
            <span className="border-b">Document number</span>
            <span className="py-1 fw-bold ">
              {fifoDetails?.documentNumber ?? "-"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

MoreDetails.propTypes = {
  data: PropTypes.object,
  uom: PropTypes.string,
};

export default MoreDetails;
