import React, {useContext} from "react";

export const AppSettingDetailContext = React.createContext({
  appSetting: {
    featureMappingData: null,
    currentSite: "GLOBAL",
    currentCountry: "GLOBAL",
    currentModule: "",
    currentCountryMappingData: null,
    siteData: [],
    divisionData: [],
    currentCountryCode: "GO",
    subscriptionDataGenerateCertificate: {
      mbOutboundQtyEuId: null,
      country: null,
      division: null,
      sdNumber: null,
    },
  },
  setAppSetting: () => {
    /* do nothing */
  },
});

export const useAppSetting = () => useContext(AppSettingDetailContext);
