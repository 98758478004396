import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
  Alert24,
  CircleClose24,
  Down24,
  Info24,
  Kebab24,
  Up24,
  Flag24,
  CircleCheck24,
} from "@bphxd/ds-core-react/lib/icons";
import {Close} from "@bphxd/ds-core-react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {formatNumber, useUserSettings} from "providers/userSettings";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import {saf_urls} from "modules/SAF-dashboard/constants/common";
import moment from "moment";
import {INCOMING_DOC_STATUS} from "modules/SAF-dashboard/constants/documents";
import HighLightedText from "../OutgoingDocuments/HighLightedText";

const getFormattedDecimal = (cell) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (cell.getValue()) {
    return formatNumber(
      parseAndRoundToTwoDecimal(cell.getValue()),
      decimalFormat,
      2,
    );
  }
  return cell.getValue();
};

const RenderLink = (id, text, pageNo, cell) => {
  const {country} = useParams();
  const locationKey = country + "DocumentPage";
  const linkURL = saf_urls[locationKey] + "/" + id;
  return (
    <Link
      to={{
        pathname: linkURL,
        search: `?pageNo=${pageNo}`,
      }}
      className="link-dark"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  stateMgmt,
  previousVersions,
  previousVersionsCount,
  certificateInboundEuId,
  contractNumber,
}) => {
  const [versionModal, setVersionModal] = useState(false);
  const navigate = useNavigate();
  const {country} = useParams();
  const locationPurchaseKey = country + "LinkToPurchase";

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVersionModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <>
      <Modal size="sm" isOpen={versionModal} className="modal-dialog-centered">
        <ModalHeader
          className="p-4"
          style={{
            borderBottom: "solid 1px #80808061",
          }}
          close={
            <Close
              onClick={() => {
                setVersionModal(false);
              }}
            />
          }
        >
          Version history
        </ModalHeader>
        <ModalBody
          style={{
            padding: "0px",
          }}
        >
          {previousVersions?.map((prevItem) => (
            <div
              className="flex p-3.5 border-b border-gray-400 cursor-pointer"
              style={{color: "gray"}}
            >
              <div className="mr-1.5">
                <Flag24 color="grey" />
              </div>
              <div
                className="max-w-[calc(100%-38px)]"
                onClick={() =>
                  navigate({
                    pathname: `${
                      saf_urls[`${country}DocumentPage`]
                    }/${encodeURIComponent(prevItem.linkedCert)}`,
                  })
                }
                // This will be removed in future commits
                onKeyDown={() => {}}
              >
                <p className="text-sm mb-0.5 break-words">
                  {prevItem.sdNumber}
                </p>
                <p className="text-xs mb-0">
                  Rejected {prevItem.bvAuditChangedDatez}
                </p>
              </div>
            </div>
          ))}
        </ModalBody>
      </Modal>
      <UncontrolledDropdown direction="up">
        <DropdownToggle color="standard-quartenary" className="!px-0">
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() =>
              navigate({
                pathname: `${
                  saf_urls[`${country}DocumentPage`]
                }/${encodeURIComponent(certificateInboundEuId)}`,
              })
            }
          >
            View document
          </DropdownItem>
          <DropdownItem
            disabled={stateMgmt !== "ready_to_accept"}
            onClick={() =>
              navigate({
                pathname: `${
                  saf_urls[`${country}DocumentPage`]
                }/${encodeURIComponent(certificateInboundEuId)}`,
              })
            }
          >
            Accept the document
          </DropdownItem>
          <DropdownItem
            disabled={stateMgmt !== "ready_to_assign"}
            onClick={() =>
              navigate({
                pathname: saf_urls[locationPurchaseKey],
                search: `contract_no=${contractNumber}`,
              })
            }
          >
            Link to purchase
          </DropdownItem>
          <DropdownItem
            onClick={() => setVersionModal(true)}
            disabled={previousVersionsCount === 0}
          >
            Version history ({previousVersionsCount})
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  stateMgmt: PropTypes.string,
  previousVersions: PropTypes.array,
  previousVersionsCount: PropTypes.number,
  certificateInboundEuId: PropTypes.string,
  contractNumber: PropTypes.string,
};

const docStatus = {
  ready_to_assign: {
    message: "Ready to assign",
    icon: <Info24 color="#218dcc" />,
  },
  requires_attention: {
    message: "Requires your attention",
    icon: <Alert24 color="#e64949" />,
  },
  ready_to_accept: {
    message: "Ready to accept",
    icon: <Info24 color="#ffc000" />,
  },
  rejected: {
    message: "Rejected",
    icon: <CircleClose24 color="#111111" />,
  },
  failed_to_process: {
    message: "Rejected",
    icon: <CircleClose24 color="#111111" />,
  },
  assigned: {
    message: "Completed",
    icon: <CircleCheck24 color="#111111" />,
  },
  completed: {
    message: "Completed",
    icon: <CircleCheck24 color="#111111" />,
  },
};

const renderStatus = (text, cell) => {
  const {icon: defaultIcon, message: defaultMessage} =
    docStatus.failed_to_process;
  const {icon = defaultIcon, message = defaultMessage} = docStatus[text] || {};

  return (
    <div className="flex items-center">
      {icon}
      <span className="ml-2">
        <HighLightedText
          value={message}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </span>
    </div>
  );
};

const getColumns = (dateFormat, selectedCountry) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Down24 /> : <Up24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Status",
      key: "status",
      disabled: true,
      accessorKey: "status",
      visible: true,
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "Certificate ID",
      accessorKey: "certificateNumber",
      key: "certificateNumber",
      disabled: true,
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Product",
      accessorKey: "product",
      key: "product",
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw Material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m3",
      accessorKey: "quantinInm3",
      key: "Quantity m3",
      size: 100,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity mt",
      accessorKey: "quantity",
      key: "Quantity mt",
      size: 100,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Date of Issuance",
      accessorKey: "dateOfIssuance",
      key: "dateOfIssuance",
      size: 200,
      visible: true,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={moment(cell.getValue()).format(dateFormat)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract Number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass balance",
      accessorKey: "balance",
      key: "balance",
      size: 200,
      disabled: true,
      visible: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, status}) => {
        return status === INCOMING_DOC_STATUS.COMPLETED
          ? `${mbPeriodName} - ${mbLocationGroupName}`
          : "";
      },
      cell: ({
        row: {
          original: {
            mbBalanceTypeCode,
            mbBalanceGroupName,
            mbLocationGroupName,
            mbPeriodName,
            mbPeriodId,
            status,
          },
        },
        cell,
      }) => {
        return status === INCOMING_DOC_STATUS.COMPLETED ? (
          <Link
            to={`/mass-balance/${encodeURIComponent(
              selectedCountry,
            )}/SAF/${mbBalanceTypeCode}/${mbLocationGroupName}/${mbPeriodName}/${mbBalanceGroupName}`}
            className="link-dark !underline"
            state={{mbPeriodId}}
          >
            <HighLightedText
              value={cell.getValue()}
              globalFilter={cell.getContext().table.getState().globalFilter}
            />
          </Link>
        ) : (
          ""
        );
      },
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            status,
            previousVersions,
            previousVersionsCount,
            certificateInboundEuId,
            contractNumber,
          },
        },
      }) => (
        <RenderDropdown
          stateMgmt={status}
          previousVersions={previousVersions}
          previousVersionsCount={previousVersionsCount}
          certificateInboundEuId={certificateInboundEuId}
          contractNumber={contractNumber}
        />
      ),
    },
  ];
};

export default getColumns;
