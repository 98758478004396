import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, InputGroup, InputGroupText, Input} from "reactstrap";
import {Filter24, Remove24} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {useUserSettings} from "providers/userSettings";
import {useAccount, useMsal} from "@azure/msal-react";
import {toast} from "react-toastify";
import SAF_GET_OUTGOING_DOCUMENTS, {
  SAF_SEND_CERTIFICATE_EMAIL,
} from "graphql/saf/OutgoingDocuments";
import {useMutation, useQuery} from "@apollo/client";
import {siteRefId} from "modules/SAF-dashboard/constants/common";
import {useParams} from "react-router-dom";
import {DIVISION_ID} from "modules/SAF-dashboard/constants/documents";
import {parseDateString} from "modules/SAF-dashboard/utils";
import DocumentTable from "./DocumentTable";
import getColumns from "./columns";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import OutgoingFilters from "../OutgoingFilters";

const OutgoingDocuments = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [showFilter, setShowFilter] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [highlightedRows, setHighlightedRows] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [customerName, setCustomerName] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const {country} = useParams();

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {data, loading, refetch} = useQuery(SAF_GET_OUTGOING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {site_reference_id: siteRefId[country]},
  });

  const [SafSendCertificateEmailAPI] = useMutation(SAF_SEND_CERTIFICATE_EMAIL, {
    onCompleted: ({bioLcSafOutgoingCertEmail}) => {
      if (bioLcSafOutgoingCertEmail.statusCode === 201) {
        refetch();
      } else {
        toast.error(bioLcSafOutgoingCertEmail.error);
      }
    },
  });

  const handleSendCertificate = useCallback(
    async (certificate_outbound_eu_id, certificate_number) => {
      await SafSendCertificateEmailAPI({
        variables: {
          event: {
            changed_by: account?.username,
            doc_data: [{certificate_outbound_eu_id, certificate_number}],
            site_reference_id: siteRefId[country],
            division_id: DIVISION_ID,
          },
        },
      });
      setHighlightedRows([certificate_outbound_eu_id]);
      setRowSelection({});
      toast.success("Certificates sent");
    },
    [SafSendCertificateEmailAPI, account?.username, country],
  );

  const baseColumns = useMemo(
    () => getColumns(dateFormat, decimalFormat, handleSendCertificate),
    [dateFormat, decimalFormat, handleSendCertificate],
  );

  const [columns, setColumns] = useState(baseColumns);

  useEffect(() => {
    if (baseColumns) {
      setColumns(baseColumns);
    }
  }, [baseColumns, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const tableData = useMemo(() => {
    return (data?.bioLcSafGetOutgoingDocs?.documents ?? []).map((document) => ({
      status: document.status,
      sd_number: document.sd_number,
      date_of_issuance: parseDateString(document.date_of_issuance),
      product_type: document.product_type,
      raw_material_type: document.raw_material_type,
      quantity_mt: document.product_qty_uom === "MT" && document.product_qty,
      quantity_m3: document.product_qty_uom === "M3" && document.product_qty,
      recipient_name: document.recipient_name,
      recipient_receipt_address: document.recipient_receipt_address,
      contract_number: document.contract_number,
      ghg_total: document.ghg_total,
      grn: document.grn,
      po_number: document.po_number,
      mb_location: document.mb_location,
      certificate_outbound_eu_id: document.certificate_outbound_eu_id,
      certificate_number: document.certificate_number,
    }));
  }, [data?.bioLcSafGetOutgoingDocs?.documents]);

  const handleFilterSubmit = (filterData) => {
    if (filterData) {
      setCustomerName(filterData.recipient_name);
      setDateFrom(
        filterData.date_of_issuance
          ? filterData.date_of_issuance[0]
          : undefined,
      );
      setDateTo(
        filterData.date_of_issuance
          ? filterData.date_of_issuance[1]
          : undefined,
      );
      const convertedObjects = Object.entries(filterData)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setCustomerName(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
    }
  };

  const isRowSelected = Object.keys(rowSelection).length > 0;

  return (
    <div className="flex flex-col justify-stretch w-full items-start px-7 pt-16">
      <div className=" flex flex-row justify-between w-full p-4 bg-white">
        <div>
          <CustomizeTable
            columns={columns}
            onColumnChange={(cols) => setColumns(cols)}
            showApplyButton={false}
          />
        </div>
        <div className="flex flex-none flex-row items-center mx-5">
          <div className="mr-2">
            <Button
              data-test="copro-filter-btn"
              color="standard-tertiary rounded-0"
              onClick={() => setShowFilter(!showFilter)}
            >
              Filters
              <Filter24 className="btn-icon-suffix" />
            </Button>
          </div>
          <div>
            <InputGroup className="input-group-merge" size="">
              <Input
                className="form-control-appended"
                placeholder="Search"
                value={filtering}
                onChange={(e) => setFiltering(e.target.value)}
              />
              <div className="input-group-append">
                <InputGroupText>
                  {filtering ? (
                    <Remove24
                      className="cursor-pointer"
                      onClick={() => setFiltering("")}
                    />
                  ) : (
                    <SvgSearch24 />
                  )}
                </InputGroupText>
              </div>
            </InputGroup>
          </div>
        </div>
      </div>
      {showFilter && (
        <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
          <div className=" flex flex-row w-full my-6">
            <OutgoingFilters
              onSubmit={handleFilterSubmit}
              customerList={[]}
              setColumnFilter={setColumnFilter}
              customerName={customerName}
              dateFrom={dateFrom}
              dateTo={dateTo}
              setShowFilter={setShowFilter}
            />
          </div>
        </div>
      )}
      <div className="border-secondary border-top w-full">
        <DocumentTable
          columns={filteredColumns}
          data={tableData}
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          className="outgoing-docs-table"
          highlightedRows={highlightedRows}
          loading={loading}
          filtering={filtering}
          setFiltering={setFiltering}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          type="Outgoing"
        />
      </div>
      <div>
        <Button
          className="rounded-0 mb-40"
          disabled={!isRowSelected}
          onClick={async () => {
            const selectedRecords = Object.keys(rowSelection)
              .map((index) => Number(index))
              .map((index) => tableData[index]);

            const selectedIds = selectedRecords.map((item) => {
              return {
                certificate_number: item.certificate_number,
                certificate_outbound_eu_id: item.certificate_outbound_eu_id,
              };
            });

            const highlightIds = selectedRecords.map(
              ({certificate_outbound_eu_id}) => certificate_outbound_eu_id,
            );

            await SafSendCertificateEmailAPI({
              variables: {
                event: {
                  changed_by: account?.username,
                  doc_data: selectedIds,
                  site_reference_id: siteRefId[country],
                  division_id: DIVISION_ID,
                },
              },
            });

            setHighlightedRows(highlightIds);
            setRowSelection({});
            toast.success("Certificates sent");
          }}
        >
          Send email
        </Button>
      </div>
    </div>
  );
};

export default OutgoingDocuments;
