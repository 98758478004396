import {gql} from "@apollo/client";

const MARK_AS_COMPLETE = gql`
  mutation markAsCompleteMutation($event: bioLcCoproEuMarkAsCompleteRequest!) {
    bioLcCoproEuMarkAsComplete(event: $event) {
      statusCode
      message
    }
  }
`;

export default MARK_AS_COMPLETE;
