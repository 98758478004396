import {gql} from "@apollo/client";

export const ALLOCATION_INCOMING_FETCH_API = gql`
  query bioLcGetAvailableIncomingRecordsApi(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetAvailableIncomingRecordsApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      documents {
        physicalReceiptDate
        mbInboundQtyEuId
        incomingDocument
        countryOfOrigin
        ghgTotalMj
        sustainableQuantityM3
        rawMaterial
        certificateInboundEuId
      }
    }
  }
`;

export default ALLOCATION_INCOMING_FETCH_API;
