import "modules/common/Menu/index.scss";
import React from "react";
import PropTypes from "prop-types";
import objectMap from "utils/helpers/objectMap";
import {merge} from "lodash";
import CheckboxFilter from "../common/Filter/CheckBoxFilter";
import CheckboxFilterGroup from "../common/Filter/CheckboxFilterGroup";
import {getMasterFilter} from "./posPLFilterHelpers";
import {getUniqueList} from "./posUKFilterHelpers";

const PoSPolandDashboardFilter = ({
  activefilters,
  setActiveFilters,
  masterFilter,
  setFilterOptions,
  defaultFilterOptions,
  isNoActiveFilter,
  filterOptions,
  setPage,
  refetch,
  activeFiltersDefault,
  setMasterFilter,
  defaultFilterMap,
  filterLoading,
  filterMap,
}) => {
  const handleFilterOnChange = ({
    filterKey,
    singleActiveFilters,
    checked,
    filterOption,
    allChecked,
  }) => {
    const currentFilters = {
      ...activefilters,
      [filterKey]: singleActiveFilters,
    };

    const currentMasterFilter = masterFilter || getMasterFilter(currentFilters);

    if (checked) {
      if (allChecked && filterKey === currentMasterFilter) {
        setFilterOptions(defaultFilterOptions);
      } else {
        const previousFilterOptions = objectMap(filterOptions, (value) =>
          isNoActiveFilter ? [] : value,
        );

        Object.keys(filterOptions).forEach((filterOptionkey) => {
          if (
            filterKey === filterOptionkey &&
            currentMasterFilter === filterOptionkey
          ) {
            const restFilterKeys = Object.keys(filterOptions).filter(
              (key) => currentMasterFilter !== key,
            );

            const restFilterOptions = {};

            restFilterKeys.forEach((restFilterKey) => {
              restFilterOptions[restFilterKey] = getUniqueList([
                ...previousFilterOptions[restFilterKey],
                ...filterMap[`${filterKey}Map`][filterOption][restFilterKey],
              ]);
            });

            setFilterOptions({
              ...filterOptions,
              ...restFilterOptions,
            });
          }
        });
      }
      setActiveFilters(currentFilters);
      setPage(1);
      refetch({
        ...currentFilters,
      });
    } else if (allChecked) {
      setActiveFilters(activeFiltersDefault);
    } else {
      Object.keys(filterOptions).forEach((filterOptionKeySec) => {
        if (
          filterKey === filterOptionKeySec &&
          currentMasterFilter === filterOptionKeySec
        ) {
          const restFilterKeys = Object.keys(filterOptions).filter(
            (key) => currentMasterFilter !== key,
          );

          const restFilterOptions = {};
          const remainingFilters = {};

          restFilterKeys.forEach((restFilterKeySec) => {
            restFilterOptions[restFilterKeySec] = getUniqueList(
              filterOptions[restFilterKeySec].filter(
                (item) =>
                  !filterMap[`${filterKey}Map`][filterOption][
                    restFilterKeySec
                  ].includes(item),
              ),
            );

            remainingFilters[restFilterKeySec] = getUniqueList(
              currentFilters[filterKey].flatMap(
                (item) => filterMap[`${filterKey}Map`][item][restFilterKeySec],
              ),
            );
          });

          const newFilterOptions = merge(restFilterOptions, remainingFilters);

          const availableFilters = {};
          restFilterKeys.forEach((restFilterKeyThird) => {
            availableFilters[restFilterKeyThird] = currentFilters[
              restFilterKeyThird
            ].filter((item) =>
              newFilterOptions[restFilterKeyThird].includes(item),
            );
          });

          setFilterOptions({...filterOptions, ...newFilterOptions});
          setActiveFilters({...currentFilters, ...availableFilters});

          setPage(1);
          refetch({
            ...{...currentFilters, ...availableFilters},
          });
        }
      });
    }

    if (!currentFilters[currentMasterFilter].length) {
      setFilterOptions(defaultFilterOptions);
      setMasterFilter("");
    }

    if (!currentMasterFilter) {
      setActiveFilters(activeFiltersDefault);
      setMasterFilter("");
    }

    if (!masterFilter) {
      setMasterFilter(currentMasterFilter);
    }

    if (currentMasterFilter !== filterKey) {
      setActiveFilters(currentFilters);
      setPage(1);
      refetch({...currentFilters});
    }
  };

  return (
    <CheckboxFilterGroup defaultFilterMap={defaultFilterMap}>
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions.certificateType}
        activeFilters={activefilters.certificateType}
        dataKey="certificateType"
        label="Type of Certificate"
        loading={filterLoading}
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions.supplier}
        activeFilters={activefilters.supplier}
        dataKey="supplier"
        label="Supplier"
        loading={filterLoading}
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions.fuelType}
        activeFilters={activefilters.fuelType}
        dataKey="fuelType"
        label="Fuel Type"
        loading={filterLoading}
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions.feedstockType}
        activeFilters={activefilters.feedstockType}
        dataKey="feedstockType"
        label="Feedstock Type"
        loading={filterLoading}
      />
    </CheckboxFilterGroup>
  );
};

PoSPolandDashboardFilter.propTypes = {
  activefilters: PropTypes.any,
  setActiveFilters: PropTypes.any,
  masterFilter: PropTypes.any,
  setFilterOptions: PropTypes.any,
  defaultFilterOptions: PropTypes.any,
  isNoActiveFilter: PropTypes.any,
  filterOptions: PropTypes.any,
  refetch: PropTypes.any,
  setPage: PropTypes.any,
  activeFiltersDefault: PropTypes.any,
  setMasterFilter: PropTypes.any,
  defaultFilterMap: PropTypes.any,
  filterLoading: PropTypes.any,
  filterMap: PropTypes.any,
};

export default PoSPolandDashboardFilter;
