import React from "react";
import PropTypes from "prop-types";
import tw from "twin.macro";

const CardWrapper = tw.div`flex flex-col self-center text-center py-4 gap-2 sm:py-0`;
const DisplayData = tw.data`text-3xl h-8 text-[#161038]`;
const LabelText = tw.label`text-xs  text-[#89888c] whitespace-pre-wrap`;

const KPICard = ({data, label}) => (
  <CardWrapper data-test="kpi-card">
    <DisplayData>{data ?? ""}</DisplayData>
    <LabelText> {label}</LabelText>
  </CardWrapper>
);
KPICard.propTypes = {
  data: PropTypes.string,
  label: PropTypes.string,
};

const KPIWrapper = tw.div`
  max-w-2xl mt-1
  flex flex-col justify-center items-center
  rounded-md shadow-md border border-solid border-[#d2d2d4]
  md:w-6/12
`;

const KPISectionWrapper = tw.div`
  col-span-full px-10 max-w-2xl
  grid grid-cols-1 divide-y divide-[#d2d2d4]
  sm:py-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x sm:px-4
  md:w-full
`;

const KPILabel = tw.data`text-xl h-8 text-[#89888c] pt-2 px-2 text-center`;

const KPICardSection = ({label, value1, message1, value2, message2}) => {
  return (
    <KPIWrapper>
      <KPILabel>{label}</KPILabel>
      <KPISectionWrapper data-test="kpi-group">
        <KPICard label={message1} data={value1} />
        <KPICard label={message2} data={value2} />
      </KPISectionWrapper>
    </KPIWrapper>
  );
};

KPICardSection.propTypes = {
  label: PropTypes.string,
  value1: PropTypes.string,
  message1: PropTypes.string,
  value2: PropTypes.string,
  message2: PropTypes.string,
};

export default KPICardSection;
