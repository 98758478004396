import React, {useState} from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import {useMutation} from "@apollo/client";
import PropTypes from "prop-types";
import {useForm, FormProvider, Controller} from "react-hook-form";
import {toast} from "react-toastify";
import {useUserSettings} from "providers/userSettings";
import FormField from "modules/common/FormFieldNew.js";
import {SET_USER_SETTINGS} from "graphql/userSettings";
import "./index.scss";

const SettingsModal = ({title, visible, onCancel, showCrossArrow = true}) => {
  const {
    userSettings: {dateFormat, decimalFormat},
    setUserSettings,
  } = useUserSettings();

  const [mutateUserSettings] = useMutation(SET_USER_SETTINGS, {
    onCompleted: () => {
      toast.success("Successfully updated");
      onCancel();
    },
    onError: () => {
      onCancel();
    },
  });

  const [newDateFormat, setNewDateFormat] = useState(dateFormat);
  const [newDecimalFormat, setNewDecimalFormat] = useState(decimalFormat);

  const methods = useForm();

  const submitUserSettings = methods.handleSubmit(() => {
    mutateUserSettings({
      variables: {
        date_format: newDateFormat,
        decimal_format: newDecimalFormat,
      },
    });
    setUserSettings({
      dateFormat: newDateFormat,
      decimalFormat: newDecimalFormat,
    });
  });

  return (
    <Modal isOpen={visible} style={{width: "310px", marginTop: "120px"}}>
      <ModalHeader toggle={onCancel} className="text-xl">
        {title}
      </ModalHeader>
      <div className="border-b-2 border-grey-200 mb-6"></div>

      <FormProvider {...methods}>
        <ModalBody className="text-center pt-0 pb-11">
          <div className="grid grid-cols-1 gap-y-12 text-left">
            <FormField label="Date format" className="formfield-label">
              <Controller
                name="dateFormat"
                control={methods.control}
                render={({field}) => (
                  <Input
                    {...field}
                    type="select"
                    id="dateFormat"
                    data-test="dateFormat"
                    value={newDateFormat}
                    onChange={(e) => {
                      setNewDateFormat(e.target.value);
                      methods.setValue("dateFormat", e.target.value);
                    }}
                    style={{color: "grey"}}
                  >
                    {["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD"].map((item) => (
                      <option key={`dateFormat-${item}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormField>
            <FormField label="Decimal format" className="formfield-label">
              <Controller
                name="decimalFormat"
                control={methods.control}
                render={({field}) => (
                  <Input
                    {...field}
                    type="select"
                    id="decimalFormat"
                    data-test="decimalFormat"
                    value={newDecimalFormat}
                    onChange={(e) => {
                      setNewDecimalFormat(e.target.value);
                      methods.setValue("decimalFormat", e.target.value);
                    }}
                    style={{color: "grey"}}
                  >
                    {["Imperial Style", "European Style"].map((item) => (
                      <option
                        key={`decimalFormat-${item}`}
                        data-test="decimalFormat"
                        value={item}
                      >
                        {item}
                      </option>
                    ))}
                  </Input>
                )}
              />
            </FormField>
          </div>
        </ModalBody>
      </FormProvider>

      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={submitUserSettings}
            >
              Save changes
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

SettingsModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  showCrossArrow: PropTypes.bool,
};

export default SettingsModal;
