import {useState, useMemo, createContext, useContext} from "react";
import PropTypes from "prop-types";
import moment from "moment";

const now = moment();
const initialDate = {
  monthIndex: now.subtract(1, "months").month(), // 0 = Jan, 1 = Feb...& 11 = Dec
};

export const SelectedDateContext = createContext({});
export const DateActionsContext = createContext({});

export const ForecastingDateProvider = ({children}) => {
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const dateDispatch = useMemo(() => {
    const onNext = () => {
      setSelectedDate({
        ...selectedDate,
        monthIndex: selectedDate.monthIndex + 1,
      });
    };
    const onPrevious = () => {
      setSelectedDate({
        ...selectedDate,
        monthIndex: selectedDate.monthIndex - 1,
      });
    };

    return {
      onNext,
      onPrevious,
    };
  }, [selectedDate]);

  return (
    <DateActionsContext.Provider value={dateDispatch}>
      <SelectedDateContext.Provider value={selectedDate}>
        {children}
      </SelectedDateContext.Provider>
    </DateActionsContext.Provider>
  );
};

ForecastingDateProvider.propTypes = {
  children: PropTypes.node,
};

export const useSelectedDate = () => {
  const context = useContext(SelectedDateContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedDate must be used inside ForecastingDateProvider",
    );
  }

  return context;
};

export const useDateActions = () => {
  const context = useContext(DateActionsContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedDateDispatch must be used inside ForecastingDateProvider",
    );
  }

  return context;
};
