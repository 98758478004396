import outboundTypes from "constants/allocation";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const Checkbox = ({row, table, type}) => {
  const isSelected = row.getIsSelected();

  const isSomeRowsSelected = table.getIsSomeRowsSelected();

  const handleChange = row.getToggleSelectedHandler();

  const selectedOutBoundType =
    table.getSelectedRowModel().rows?.[0]?.original?.outboundType;

  const rowOutBoundType = row?.original?.outboundType;

  const selectedReceipient =
    table.getSelectedRowModel().rows?.[0]?.original?.receipient;

  const rowReceipient = row?.original?.receipient;

  const selectedShipToLocation =
    table.getSelectedRowModel().rows?.[0]?.original?.shipToLocation;

  const rowShipToLocation = row?.original?.shipToLocation;

  let isDisabled = false;

  if (isSomeRowsSelected && !isSelected) {
    switch (rowOutBoundType) {
      case outboundTypes.VOLUNTARY:
        isDisabled = selectedOutBoundType !== rowOutBoundType;
        break;
      case outboundTypes.EXPORT:
        isDisabled =
          selectedOutBoundType !== rowOutBoundType ||
          selectedReceipient !== rowReceipient ||
          selectedShipToLocation !== rowShipToLocation;
        break;
      case outboundTypes.LOCATION_TRANSFER:
        isDisabled =
          selectedOutBoundType !== rowOutBoundType ||
          selectedReceipient !== rowReceipient ||
          selectedShipToLocation !== rowShipToLocation;
        break;
      default:
        isDisabled = false;
    }
  }

  return (
    <Input
      type="checkbox"
      checked={isSelected}
      onChange={handleChange}
      disabled={type === "Outgoing" && isDisabled}
    />
  );
};

Checkbox.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  type: PropTypes.string,
};

export default Checkbox;
