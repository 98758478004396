import PropTypes from "prop-types";
import YearTotalBarChart from "modules/forecasting/components/GraphSection/YearTotalBarChart";
import YearTotalCreditsGraph from "modules/forecasting/components/GraphSection/YearTotalCreditsGraph";

const TotalCharts = ({forecastType, data}) => {
  if (!data?.length || !forecastType) return null;

  return {
    credits: <YearTotalCreditsGraph fullYearData={data} />,
    deficits: <YearTotalBarChart pageData={data} />,
  }[forecastType];
};

export default TotalCharts;

const categoryShape = {
  actualValue: PropTypes.number,
  systemValue: PropTypes.number,
  variance: PropTypes.number,
};

TotalCharts.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      d4_current: PropTypes.shape(categoryShape),
      d4_previous: PropTypes.shape(categoryShape),
      d4_total: PropTypes.shape(categoryShape),
      d5_current: PropTypes.shape(categoryShape),
      d5_previous: PropTypes.shape(categoryShape),
      d5_total: PropTypes.shape(categoryShape),
      d6_current: PropTypes.shape(categoryShape),
      d6_previous: PropTypes.shape(categoryShape),
      d6_total: PropTypes.shape(categoryShape),
      total_gas: PropTypes.shape(categoryShape),
      total_diesel: PropTypes.shape(categoryShape),
      total_obligation: PropTypes.shape(categoryShape),

      date: PropTypes.shape({
        month: PropTypes.number,
        year: PropTypes.number,
      }),
    }),
  ).isRequired,

  forecastType: PropTypes.oneOf(["credits", "deficits"]).isRequired,
};
