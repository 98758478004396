import {saf_urls} from "modules/SAF-dashboard/constants/common";
import PropTypes from "prop-types";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";

const ActualisationModal = ({
  showModal,
  setShowModal,
  isIncoming,
  setRowSelection,
  setPurchaseRowSelection,
  apiSuccess,
  errorCode,
}) => {
  const navigate = useNavigate();
  const {country} = useParams();

  const successMessage = errorCode
    ? "Successfully linked document quantity  with received quantity within the 0.5% tolerance range as governed by ISCC."
    : "Successfully linked document quantity with received quantity.";

  let Header;
  if (apiSuccess) {
    Header = "Successfully linked";
  } else if (isIncoming) {
    Header = "Quantities cannot be linked";
  } else {
    Header = "Quantities cannot be linked";
  }

  let Body;
  if (apiSuccess) {
    Body = successMessage;
  } else if (isIncoming) {
    Body = `Linking a purchase quantity lower than the PoS quantity is not possible.`;
  } else {
    Body = `Linking a purchase quantity higher than the PoS quantity is not possible.`;
  }

  let Body2;
  if (!apiSuccess) {
    Body2 =
      "Please check the quantities you are trying to link and try again, or reject the PoS if there is an error.";
  }

  return (
    <Modal size="sm" isOpen={showModal} className="modal-dialog-centered">
      <ModalBody className="text-center">
        <p className="mb-[2px]">{Header}</p>
        <p className="text-[13px] text-gray-800 mb-0">{Body} </p>
        {!apiSuccess && (
          <>
            <br />
            <p className="text-[13px] text-gray-800 mb-0">{Body2} </p>
          </>
        )}
      </ModalBody>
      {!apiSuccess && (
        <>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                setShowModal(false);
              }}
            >
              Try again
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                setRowSelection({});
                setPurchaseRowSelection({});
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
      {apiSuccess && (
        <>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                setRowSelection({});
                setPurchaseRowSelection({});
                setShowModal(false);
              }}
            >
              Link another
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                navigate({
                  pathname: saf_urls[`${country}DocumentPage`],
                });
              }}
            >
              Incoming document
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                navigate({
                  pathname: saf_urls[`${country}MassBalance`],
                });
              }}
            >
              Mass balance
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

ActualisationModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  isIncoming: PropTypes.bool,
  setRowSelection: PropTypes.func,
  setPurchaseRowSelection: PropTypes.func,
  apiSuccess: PropTypes.bool,
  errorCode: PropTypes.string,
};

export default ActualisationModal;
