import {useQuery} from "@apollo/client";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useCallback, useMemo, useState} from "react";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {dateSorterExt} from "modules/common/Table/sorters";
import SummaryTable from "modules/common/SummaryTable";
import {HEADER_MOT_VALIDATION_REPORT} from "modules/gy-enablement/constants/Headers";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {TAXED_OUTBOUND_DAY_LEVEL_SUMMARY} from "graphql/gy-enablement/MOT/summaryDashboard";
import {paginationSizeMOT, paginationPageSizeOptions} from "constants/config";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import BioBreadcrumb from "modules/common/Breadcrumb";
import {motTabDataDayLevelItems} from "../../../content/tabData";
import {multiLevelBreadcrumbItems} from "../../../content/breadCrumbData";
import FilterDashboardLayout from "../../common/FilterDashboardLayout";
import {getTaxedOutboundTableDayLevelCols} from "../../../content/motSummaryColumns";
import "../../common/index.scss";
import CeemasValidationFilter from "./components/CeemasValidationFilter";

const CeemasValidationDayLevel = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const taxedOutboundTableDayLevelCols =
    getTaxedOutboundTableDayLevelCols(decimalFormat);
  const [pageNumber, setPage] = useState(1);
  const [pageSizeNumber, setPageSize] = useState(paginationSizeMOT);
  const {filterDetails} = useParams();
  const filterParamDetails = JSON.parse(filterDetails);
  const onLoadPayload = {
    company: filterParamDetails?.company,
    complianceyear: filterParamDetails?.complianceyear,
    compliancemonth: filterParamDetails?.compliancemonth,
    ed: filterParamDetails?.ed,
    legalentity: filterParamDetails?.legalentity,
    material: filterParamDetails?.material,
    plant: filterParamDetails?.plant,
    balancegroup: filterParamDetails?.balancegroup,
    motProfile: filterParamDetails?.motProfile,
  };
  const [filterValues, setFilterValues] = useState(onLoadPayload);

  const [breadcrumbitems, setBreadcrumbitems] = useState(
    multiLevelBreadcrumbItems(onLoadPayload),
  );
  const motTabData = motTabDataDayLevelItems(filterParamDetails);

  const {data: optionsDataPayload, loading: optionsLoading} =
    useQuery(GET_FIELD_OPTIONS);

  const {
    data: taxedOutboundData,
    loading: taxedOutboundDataLoading,
    refetch: refetchTaxedOutboundSummary,
  } = useQuery(TAXED_OUTBOUND_DAY_LEVEL_SUMMARY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const taxedOutboundDataItems = taxedOutboundData?.getOutboundDateLevelRequest;

  const handleOnFilter = useCallback(
    (values) => {
      const payload = {
        company: values.company || "",
        ed: values.ed || "",
        legalentity: values.legalentity || "",
        plant: values.plant || "",
        compliancemonth: values.compliancemonth
          ? moment(values.compliancemonth).format("MM")
          : "",
        complianceyear: moment(values.complianceyear).format("YYYY"),
        material: values.material || "",
        balancegroup: values.balancegroup || "",
        motProfile: values.motProfile || "",
      };

      refetchTaxedOutboundSummary(payload);
      setFilterValues(payload);
      setBreadcrumbitems(multiLevelBreadcrumbItems(payload));
    },
    [refetchTaxedOutboundSummary],
  );
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const taxedOutboundDataItemsWithFilterDetails = taxedOutboundDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );

  const [dateCol, ...rest] = taxedOutboundTableDayLevelCols;
  const col = {
    ...dateCol,
    render: (text, record, index) =>
      dateCol.render(text, record, index, dateFormat),
    ...dateSorterExt("dynamicDate", dateFormat),
  };

  const formatedDataTaxedOutbound = applyDateFormat(
    taxedOutboundDataItemsWithFilterDetails,
    [],
    dateFormat,
    "",
    [],
    decimalFormat,
    0,
  );

  const headerbreadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Germany", link: "/"},
    {
      text: "CEEMAS",
      link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
    },
  ];

  return (
    <BasePageLayout
      pageTitle={HEADER_MOT_VALIDATION_REPORT}
      tabsData={motTabData}
      breadcrumbs={headerbreadcrumbItems}
    >
      <CeemasValidationFilter
        onFilter={handleOnFilter}
        data={optionsData}
        loading={optionsLoading}
        defaultValues={{
          company: filterParamDetails?.company,
          complianceyear: moment(filterParamDetails?.complianceyear),
          compliancemonth:
            filterParamDetails?.compliancemonth === ""
              ? ""
              : moment(
                  `${filterParamDetails?.complianceyear}-${filterParamDetails?.compliancemonth}`,
                ),
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }}
      />

      <BasePageLayoutContent>
        <BioBreadcrumb breadcrumbitems={breadcrumbitems} />

        <div className="grid grid-cols-1 mt-10 ">
          <SummaryTable
            tableHeading="Taxed Outbound Volumes"
            columns={[col, ...rest]}
            dataSource={formatedDataTaxedOutbound ?? []}
            loading={taxedOutboundDataLoading}
            data-test="taxed-outbound-day-level-table"
            pagination={{
              current: pageNumber,
              pageSize: pageSizeNumber,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              total: formatedDataTaxedOutbound?.length,
              showTotal: (total) => `Total ${total} items`,
              position: ["bottomLeft"],
              defaultPageSize: pageSizeNumber,
              pageSizeOptions: paginationPageSizeOptions,
            }}
          />
        </div>
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default CeemasValidationDayLevel;
