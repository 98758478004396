import React, {useState} from "react";
import {useQuery, useLazyQuery} from "@apollo/client";
import {COPRO_US_SHIPMENTS_API_GET_SHIPMENTS} from "graphql/coprocessing/shipments";
import {discardedHeaders} from "modules/co-processing/constants/shipments";
import buildRecycleColumns from "modules/co-processing/helpers/buildRecycleColumns";
import Table from "modules/co-processing/common/Table";
import transformShipments from "modules/co-processing/helpers/transformShipments";

const RecycleBinTable = () => {
  const [discardedShipments, setDiscardedShipments] = useState([]);

  const {loading} = useQuery(COPRO_US_SHIPMENTS_API_GET_SHIPMENTS, {
    variables: {offset: -1, discarded: true},
    onCompleted: (data) => {
      const formattedShipments = transformShipments(
        data?.bioLcCoproUsShipmentsApi?.body?.shipments,
      );
      setDiscardedShipments(formattedShipments);
    },
  });

  const [refreshDiscardShipments] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_GET_SHIPMENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (refreshedData) => {
        const formattedShipments = transformShipments(
          refreshedData?.bioLcCoproUsShipmentsApi?.body?.shipments,
        );
        setDiscardedShipments(formattedShipments);
      },
    },
  );

  const onRefreshData = () => {
    refreshDiscardShipments({variables: {offset: -1, discarded: true}});
  };

  const dataColumns = buildRecycleColumns(discardedHeaders, onRefreshData);

  return (
    <div data-test="recycle-bin-shipments-table" className="w-11/12">
      <Table
        responsive
        columns={dataColumns}
        loading={loading}
        data={discardedShipments}
        enableSorting
        rowSelection={false}
      />
    </div>
  );
};

export default RecycleBinTable;
