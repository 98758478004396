import PropTypes from "prop-types";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";
import Datepicker from "../Datepicker";

const SupplierAndRecipientInfo = ({status}) => {
  const {
    register,
    formState: {errors},
    control,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  const isDisabled = status === "completed" || status === "additional_info";
  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="feedstockIssueDate" className="fw-normal">
          Date of issuance
        </Label>
        <Datepicker
          id="feedstockIssueDate"
          name="feedstockIssueDate"
          control={control}
          error={errors.feedstockIssueDate}
          rules={{required: "Please enter date of issuance"}}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="supplierName" className="fw-normal">
          Name of supplier
        </Label>
        <Input
          type="text"
          id="supplierName"
          {...computeProps("supplierName", {
            required: "Please enter name of supplier",
          })}
          data-test="supplierName"
          invalid={!!errors.supplierName}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.supplierName && (
          <FormFeedback>{errors.supplierName.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="supplierAddress" className="fw-normal">
          Address of supplier
        </Label>
        <Input
          type="text"
          id="supplierAddress"
          {...computeProps("supplierAddress", {
            required: "Please enter address of supplier",
          })}
          data-test="supplierAddress"
          invalid={!!errors.supplierAddress}
          maxLength={200}
          disabled={isDisabled}
        />
        {errors.supplierAddress && (
          <FormFeedback>{errors.supplierAddress?.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="supplierDispatchAddress" className="fw-normal">
          Address of dispatch
        </Label>
        <Input
          type="text"
          id="supplierDispatchAddress"
          data-test="supplierDispatchAddress"
          {...computeProps("supplierDispatchAddress", {
            required: "Please enter address of dispatch",
          })}
          invalid={!!errors.supplierDispatchAddress}
          maxLength={200}
          disabled={isDisabled}
        />
        {errors.supplierDispatchAddress && (
          <FormFeedback>{errors.supplierDispatchAddress?.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Label check for="supplierDispatchAddressSameFlag">
          Same as address of supplier
        </Label>
        <Input
          id="supplierDispatchAddressSameFlag"
          type="checkbox"
          data-test="supplierDispatchAddressSameFlag"
          invalid={!!errors.supplierDispatchAddressSameFlag}
          disabled={isDisabled}
          {...computeProps("supplierDispatchAddressSameFlag")}
        />
      </FormGroup>
      <FormGroup>
        <Label for="certificationSystem" className="fw-normal">
          Certification system
        </Label>
        <Input
          type="select"
          id="certificationSystem"
          data-test="certificationSystem"
          {...computeProps("certificationSystem", {
            required: "Please select a certification system",
          })}
          invalid={!!errors.certificationSystem}
          disabled={isDisabled}
        >
          <option value="ISCC-EU">ISCC-EU</option>
        </Input>
        {errors.certificationSystem && (
          <FormFeedback>{errors.certificationSystem?.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="certificateNumber" className="fw-normal">
          Certificate number
        </Label>
        <Input
          type="text"
          id="certificateNumber"
          data-test="certificateNumber"
          {...computeProps("certificateNumber", {
            required: "Please enter certificate number",
          })}
          invalid={!!errors.certificateNumber}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.certificateNumber && (
          <FormFeedback>{errors.certificateNumber.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientName" className="fw-normal">
          Name of recipient
        </Label>
        <Input
          type="text"
          id="recipientName"
          data-test="recipientName"
          {...computeProps("recipientName", {
            required: "Please enter name of recipient",
          })}
          invalid={!!errors.recipientName}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.recipientName && (
          <FormFeedback>{errors.recipientName.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-normal">
          Address of recipient
        </Label>
        <Input
          type="text"
          id="recipientAddress"
          data-test="recipientAddress"
          {...computeProps("recipientAddress", {
            required: "Please enter address of recipient",
          })}
          invalid={!!errors.recipientAddress}
          maxLength={200}
          disabled={isDisabled}
        />
        {errors.recipientAddress && (
          <FormFeedback>{errors.recipientAddress.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-normal">
          Address of receipt
        </Label>
        <Input
          type="text"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          {...computeProps("recipientReceiptAddress")}
          invalid={!!errors.recipientReceiptAddress}
          maxLength={200}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Label check for="recipientReceiptAddressSameFlag">
          Same as address of recipient
        </Label>
        <Input
          id="recipientReceiptAddressSameFlag"
          type="checkbox"
          data-test="recipientReceiptAddressSameFlag"
          invalid={!!errors.recipientReceiptAddressSameFlag}
          disabled={isDisabled}
          {...computeProps("recipientReceiptAddressSameFlag")}
        />
      </FormGroup>
      <FormGroup>
        <Label for="materialDispatchDate" className="fw-normal">
          Date of dispatch
        </Label>
        <Datepicker
          id="materialDispatchDate"
          name="materialDispatchDate"
          control={control}
          error={errors.materialDispatchDate}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="contractNumber" className="fw-normal">
          Contract number
        </Label>
        <Input
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          {...computeProps("contractNumber")}
          invalid={!!errors.contractNumber}
          maxLength={50}
          disabled={isDisabled}
        />
      </FormGroup>
    </div>
  );
};
SupplierAndRecipientInfo.propTypes = {
  status: PropTypes.string.isRequired,
};
export default SupplierAndRecipientInfo;
