import React, {useLayoutEffect, useState} from "react";
import PropTypes from "prop-types";

const RECEIPTS_HEADER_TASK_HEIGHT = 300;

const StickyHeader = ({children}) => {
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    const currScroll = window.scrollY;

    setSticky(currScroll > RECEIPTS_HEADER_TASK_HEIGHT);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, {passive: true});

    return () => {
      window.removeEventListener("scroll", handleScroll, {passive: true});
    };
  }, []);

  return (
    <header className={`receipts__${isSticky ? "sticky-header" : "header"}`}>
      {children}
    </header>
  );
};

export default StickyHeader;

StickyHeader.propTypes = {
  children: PropTypes.node,
};
