import PropTypes from "prop-types";
import React from "react";
import {Input} from "reactstrap";

const FilterRangeField = ({column, columnFilterValue}) => {
  return (
    <div className="flex space-x-2">
      <Input
        className="bg-white"
        type="number"
        placeholder="Min"
        onChange={(e) => {
          column.setFilterValue((old) => {
            if (!old?.[1] && !e.target.value) {
              return undefined;
            }

            return [e.target.value, old?.[1]];
          });
        }}
        value={columnFilterValue?.[0] || ""}
      />
      <Input
        className="bg-white"
        type="number"
        placeholder="Max"
        onChange={(e) => {
          column.setFilterValue((old) => {
            if (!old?.[0] && !e.target.value) {
              return undefined;
            }

            return [old?.[0], e.target.value];
          });
        }}
        value={columnFilterValue?.[1] || ""}
      />
    </div>
  );
};

FilterRangeField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
};

export default FilterRangeField;
