import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  Alert24,
  Info24,
  CircleClose24,
  Checklist24,
} from "@bphxd/ds-core-react/lib/icons";
import {Spinner} from "reactstrap";

import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {useAppSetting} from "providers/appSetting";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import Layout from "../../es-co-processing/components/Layout";
import TaskComponent from "../components/TaskComponent/TaskComponent";
import {GET_NOTIFICATION} from "../../../graphql/saf/GetNotification";
import {saf_urls} from "../constants/common";
import generateBreadcrumbItems, {generateMessage} from "../utils";
import {
  FAILED_TO_PROCESS_MESSAGES,
  READY_TO_ACCEPT_MESSAGES,
  READY_TO_ASSIGN_MESSAGES,
  REQUIRE_ATTENTION_MESSAGES,
} from "../constants/documents";

const SAFLandingPage = () => {
  const {appSetting} = useAppSetting();
  const {country} = useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_SAF);

  const {data, loading} = useQuery(GET_NOTIFICATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const isLoading =
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined;

  const records = data?.bioLcIncomingTaskManagerApi?.data;
  const filteredRecords = (targetState) =>
    records?.filter((record) => record.state === targetState);

  const allTasksDone = !isLoading && records?.every((obj) => obj.total === 0);

  const targetStates = {
    REQUIRE_ATTENTION: "requires_attention",
    READY_TO_ASSIGN: "ready_to_assign",
    READY_TO_ACCEPT: "ready_to_accept",
    FAILED_TO_PROCESS: "failed_to_process",
  };

  const breadcrumbItems = generateBreadcrumbItems(country, saf_urls);

  const landingPage =
    country === "sweden"
      ? saf_urls.swedenLandingPage
      : saf_urls.norwayLandingPage;
  const requireAttentionTotal =
    data && records && filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total;
  const readyToAccept =
    data && records && filteredRecords(targetStates.READY_TO_ACCEPT)[0].total;
  const readyToAssign =
    data && records && filteredRecords(targetStates.READY_TO_ASSIGN)[0].total;
  const failedToProcess =
    data && records && filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total;

  const taskNotification = data &&
    records && [
      {
        id: 1,
        text: generateMessage(
          requireAttentionTotal,
          REQUIRE_ATTENTION_MESSAGES,
        ),
        link: landingPage,
        icon: <Alert24 color="#e64949" />,
        taskItems: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].documents,
        header: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].state,
        latestDocument: filteredRecords(targetStates.REQUIRE_ATTENTION)[0]
          .latestDocument,
        total: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total,
        state: targetStates.REQUIRE_ATTENTION,
      },
      {
        id: 2,
        text: generateMessage(readyToAccept, READY_TO_ACCEPT_MESSAGES),
        link: landingPage,
        icon: <Info24 color="#ffc000" />,
        taskItems: filteredRecords(targetStates.READY_TO_ACCEPT)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ACCEPT)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ACCEPT)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ACCEPT)[0].total,
        state: targetStates.READY_TO_ACCEPT,
      },
      {
        id: 3,
        text: generateMessage(readyToAssign, READY_TO_ASSIGN_MESSAGES),
        link: landingPage,
        icon: <Info24 color="#218dcc" />,
        taskItems: filteredRecords(targetStates.READY_TO_ASSIGN)[0].documents,
        header: filteredRecords(targetStates.READY_TO_ASSIGN)[0].state,
        latestDocument: filteredRecords(targetStates.READY_TO_ASSIGN)[0]
          .latestDocument,
        total: filteredRecords(targetStates.READY_TO_ASSIGN)[0].total,
        state: targetStates.READY_TO_ASSIGN,
      },
      {
        id: 4,
        text: generateMessage(failedToProcess, FAILED_TO_PROCESS_MESSAGES),
        link: landingPage,
        icon: <CircleClose24 color="#111111" />,
        taskItems: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].documents,
        header: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].state,
        latestDocument: filteredRecords(targetStates.FAILED_TO_PROCESS)[0]
          .latestDocument,
        total: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total,
        state: targetStates.FAILED_TO_PROCESS,
      },
    ];

  // Code to loop through the taskNotification array and display the text and link
  const taskDisplay =
    !isLoading && data && records
      ? taskNotification.map((item) => {
          return (
            <TaskComponent
              item={item}
              key={item.id}
              targetStates={targetStates}
            />
          );
        })
      : "";

  return (
    <Layout title="SAF Dashboard" breadcrumbs={breadcrumbItems}>
      <section className="flex flex-col justify-stretch items-start w-full pb-6 px-7">
        <h4 className="fs-4 pb-3">Tasks</h4>
        {isLoading && (
          <div className="flex flex-row items-center justify-center flex-1">
            <Spinner size="sm" />
          </div>
        )}
        {!isLoading && !allTasksDone ? (
          <div className="flex flex-row flex-wrap flex-1">{taskDisplay}</div>
        ) : (
          !isLoading && (
            <div className="flex flex-row">
              <div className="pt-0">
                <Checklist24 className="text-gray-900" />
              </div>
              <div className="drop-down-name">
                All done! You have no outstanding tasks
              </div>
            </div>
          )
        )}
      </section>
      <section className="flex flex-col pt-8 gap-4 px-7">
        <h3 className="fs-3">Your Documents</h3>

        {isLoading && <Spinner size="sm" />}
        <hr className="border-1 mb-2" />
      </section>
    </Layout>
  );
};

export default SAFLandingPage;
