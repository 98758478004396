import "../../common/index.scss";

import Fuse from "fuse.js";
import {
  GeneralActionsMenu,
  DownloadCSVItem,
  EditionItem,
} from "modules/common/Table/ActionsMenu";
import {useMemo, useState} from "react";
import editionFormSchema from "modules/gy-enablement/schemas/masterTaxPlantRegistry";
import {HEADER_MASTER_SETTINGS} from "modules/gy-enablement/constants/Headers";
import {useQuery} from "@apollo/client";
import {
  createTaxPlantRegistryApi,
  GET_TAX_PLANT_REGISTRY,
} from "graphql/gy-enablement/masterDetails/TaxPlantRegistry";
import useRowSelection from "modules/common/Table/useRowSelection";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import {masterTaxPlantRegistryColumns} from "../../../content/taxplantregistryColumns";
import {gyMasterTabData} from "../../../content/tabData";
import DashboardTable from "../../../../common/DashboardTable";
import fileNames from "../../../../../utils/helpers/uniqueFileName";
import MOTValidationReportFilter from "../../common/MOTValidationReportFilter";
import TaxPlantRegistryForm from "./TaxPlantRegistryForm";

const fuseOptions = {
  keys: [
    "companyCode",
    "legalEntity",
    "registrationNumber",
    "plantName",
    "taxLiability",
    "eDGroupName",
    "mainCustomsOffice",
  ],
  threshold: 0.0,
  includeScore: true,
  ignoreLocation: true,
};

const TaxPlantRegistryDashboard = () => {
  const {data, loading} = useQuery(GET_TAX_PLANT_REGISTRY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const taxPlantRegistryData = useMemo(
    () => data?.getTaxPlantRegistryAurora?.data ?? [],
    [data?.getTaxPlantRegistryAurora?.data],
  );

  const [selectedRows, rowSelection] = useRowSelection(taxPlantRegistryData);

  const headerbreadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: "Configuration centre",
      link: "/gy-enablement/master/tax-plant-registry",
    },
  ];

  // ------------------------------------------------------
  // search section
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    const dataFuse = new Fuse(taxPlantRegistryData, fuseOptions);
    return search
      ? dataFuse.search(search).map(({item}) => item)
      : taxPlantRegistryData;
  }, [search, taxPlantRegistryData]);
  // ------------------------------------------------------

  const batchActionsContent = useMemo(
    () => (
      <GeneralActionsMenu>
        <EditionItem
          mode="add"
          schema={editionFormSchema}
          modalTitle="Tax Plant Registry Details"
          formContent={<TaxPlantRegistryForm data={data} />}
          onSubmit={async (values) => {
            const {
              data: {createTaxPlantRegistryAurora: response},
            } = await createTaxPlantRegistryApi(values);
            return response;
          }}
        />

        <DownloadCSVItem
          columns={masterTaxPlantRegistryColumns}
          data={selectedRows}
          filename={fileNames.fileNameTypes.FILE_MASTER_TAX_PLANT_REGISTRY}
        />
      </GeneralActionsMenu>
    ),

    [data, selectedRows],
  );

  return (
    <BasePageLayout
      pageTitle={HEADER_MASTER_SETTINGS}
      tabsData={gyMasterTabData}
      data-test="master-details-tax-plant-dashboard"
      breadcrumbs={headerbreadcrumbItems}
    >
      <BasePageLayoutContent>
        {/* TODO: This is not MOT, the component name should change its name */}
        <MOTValidationReportFilter
          batchActionsContent={batchActionsContent}
          setSearch={setSearch}
          search={search}
        />
        <DashboardTable
          rowKey="id"
          columns={masterTaxPlantRegistryColumns}
          data={filteredData ?? []}
          rowSelection={rowSelection}
          isLoading={loading}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default TaxPlantRegistryDashboard;
