import {createContext, useContext, useReducer, useMemo, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import {useFeatureFlag} from "./ForecastingFeatureFlag";

const currentYear = moment().format("YYYY");

const LANDING_URL = "/forecasting";

const getInitialState = ({credits}) => {
  return credits
    ? [
        {applied: "us", selected: "us", id: "country", name: "Country"},
        {
          applied: "",
          selected: "",
          id: "region",
          name: "Region",
        },
        {
          applied: "rfs",
          selected: "rfs",
          id: "compliance",
          name: "Compliance Scheme",
        },
        {
          applied: "",
          selected: "",
          id: "forecastType",
          name: "Forecast Type",
        },
        {
          applied: currentYear,
          selected: currentYear,
          id: "year",
          name: "Year",
        },
      ]
    : [
        {applied: "us", selected: "us", id: "country", name: "Country"},
        {
          applied: "",
          selected: "",
          id: "region",
          name: "Region",
        },
        {
          applied: "rfs",
          selected: "rfs",
          id: "compliance",
          name: "Compliance Scheme",
        },
        {
          applied: currentYear,
          selected: currentYear,
          id: "year",
          name: "Year",
        },
      ];
};

const getRegion = (filters) => {
  return filters.find((f) => f.id === "region");
};

export const ForecastingFiltersProvider = ({children}) => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const credits = useFeatureFlag();
  const isOnLandingPage = pathname === LANDING_URL;

  const reducer = (filters, action) => {
    switch (action.type) {
      case "select_filter": {
        return filters.map((filter) => {
          if (filter.id !== action.payload.id) {
            return filter;
          }

          return {...filter, selected: action.payload.value};
        });
      }

      case "apply_filter": {
        return filters.map((f) => {
          if (f.applied === f.selected) {
            return f;
          }

          return {
            ...f,
            applied: f.selected,
          };
        });
      }

      case "reset": {
        // TODO: clarify business logic for displaying reset state
        return getInitialState({url: "/forecasting", credits});
      }

      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  };

  const [filters, dispatch] = useReducer(reducer, {credits}, getInitialState);

  /**
   * -------------------------------------------
   *  User actions:
   *  - selects filter option
   *  - applies the selected values
   *  - resets active filter
   * -------------------------------------------
   */
  const filterActions = useMemo(() => {
    function handleApply() {
      dispatch({type: "apply_filter"});
    }

    function updateFilter({id, value}) {
      dispatch({type: "select_filter", payload: {id, value}});
    }

    function resetFilter() {
      dispatch({type: "reset"});
    }

    return {
      handleApply,
      updateFilter,
      resetFilter,
    };
  }, []);

  const region = getRegion(filters);
  useEffect(() => {
    if (!isOnLandingPage && !region?.applied) {
      navigate(LANDING_URL);
    }
  }, [isOnLandingPage, region, navigate]);

  const {appliedFilters, selectedFilters} = useMemo(
    () =>
      filters.reduce(
        (memo, f) => ({
          ...memo,
          appliedFilters: {...memo?.appliedFilters, [f.id]: f?.applied},
          selectedFilters: {...memo?.selectedFilters, [f.id]: f?.selected},
        }),
        {},
      ),
    [filters],
  );

  const filterState = useMemo(
    () => ({
      appliedFilters,
      selectedFilters,
      forecastFilters: filters,
    }),
    [filters, appliedFilters, selectedFilters],
  );

  return (
    <FilterDispatchContext.Provider value={filterActions}>
      <ActiveFiltersContext.Provider value={appliedFilters}>
        <SelectedFiltersContext.Provider value={selectedFilters}>
          <ForecastingFilterContext.Provider value={filterState}>
            {children}
          </ForecastingFilterContext.Provider>
        </SelectedFiltersContext.Provider>
      </ActiveFiltersContext.Provider>
    </FilterDispatchContext.Provider>
  );
};
ForecastingFiltersProvider.propTypes = {
  children: PropTypes.node,
};

export const ActiveFiltersContext = createContext(null);
export const SelectedFiltersContext = createContext(null);
export const ForecastingFilterContext = createContext(null);
export const FilterDispatchContext = createContext(null);

export const useActiveFilters = () => useContext(ActiveFiltersContext);

export const useForecastFilter = () => {
  const filterState = useContext(ForecastingFilterContext);

  if (filterState === undefined || !filterState) {
    throw new Error(
      "useForecastFilter must be used inside ForecastingFiltersProvider",
    );
  }

  return filterState;
};

export const useForecastFilterDispatch = () => {
  const context = useContext(FilterDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useForecastFilterDispatch must be used inside ForecastingFiltersProvider",
    );
  }

  return context;
};
