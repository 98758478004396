export const getLocationMap = (filterData) => {
  if (!filterData) {
    return {};
  }
  const locationMap = {};
  filterData?.fuelType?.forEach(({title, feedstockType}) => {
    feedstockType?.forEach(({location, title: feedstockTypeTitle}) => {
      location.forEach((locationItem) => {
        if (locationItem in locationMap) {
          const fuelType = [...locationMap[locationItem].fuelType, title];
          const mapedFeedstockType = [
            ...locationMap[locationItem].feedstockType,
            feedstockTypeTitle,
          ];
          locationMap[locationItem].fuelType = [...new Set(fuelType)];
          locationMap[locationItem].feedstockType = [
            ...new Set(mapedFeedstockType),
          ];
        } else {
          locationMap[locationItem] = {
            fuelType: [title],
            feedstockType: [feedstockTypeTitle],
          };
        }
      });
    });
  });
  return locationMap;
};

export const getFeedstockMap = (filterData) => {
  if (!filterData) {
    return {};
  }
  const feedstockMap = {};
  filterData?.fuelType?.forEach(({feedstockType, title}) => {
    feedstockType?.forEach(({location, title: feedstockTypeTitle}) => {
      if (feedstockTypeTitle in feedstockMap) {
        const fuelType = [...feedstockMap[feedstockTypeTitle].fuelType, title];
        const mapedLocation = [
          ...feedstockMap[feedstockTypeTitle].location,
          ...location,
        ];

        feedstockMap[feedstockTypeTitle].fuelType = [...new Set(fuelType)];
        feedstockMap[feedstockTypeTitle].location = [...new Set(mapedLocation)];
      } else {
        feedstockMap[feedstockTypeTitle] = {
          location,
          fuelType: [title],
        };
      }
    });
  });
  return feedstockMap;
};

export const getFuelMap = (filterData) => {
  if (!filterData) {
    return {};
  }
  const fuelMap = {};
  filterData?.fuelType?.forEach(
    ({feedstockType, title: feedstockTypetitle}) => {
      fuelMap[feedstockTypetitle] = {};
      fuelMap[feedstockTypetitle].feedstockType = feedstockType.map(
        ({title}) => title,
      );
      fuelMap[feedstockTypetitle].location = [
        ...new Set(feedstockType.flatMap(({location}) => location)),
      ];
    },
  );
  return fuelMap;
};

export const getDefaultFilterOptions = (filterData) => {
  if (!filterData) {
    return {
      fuelTypeOptions: [],
      feedstockTypeOptions: [],
      locationOptions: [],
    };
  }
  const fuelType = filterData?.fuelType?.map(({title}) => title);
  const feedstockType = filterData?.fuelType?.flatMap(
    ({feedstockType: feedstockTypeItem}) =>
      feedstockTypeItem?.map(({title}) => title),
  );
  const location = filterData?.fuelType?.flatMap(
    ({feedstockType: feedstockTypeItem}) =>
      feedstockTypeItem?.flatMap(({location: locationItem}) => locationItem),
  );

  return {
    fuelTypeOptions: fuelType,
    feedstockTypeOptions: [...new Set(feedstockType)],
    locationOptions: [...new Set(location)],
  };
};

export const getMasterFilter = ({fuelType, feedstockType, location}) =>
  (fuelType.length && "fuelType") ||
  (feedstockType.length && "feedstockType") ||
  (location.length && "location") ||
  "";

export const getUniqueList = (array) => [...new Set(array)];

export default {
  getLocationMap,
  getFeedstockMap,
  getDefaultFilterOptions,
};
