/**
 * Given "tk34.CP21_7_A" return {batchCode:'CP113133_7_A', suffix: 'A' }.
 * Given "tk34.CP21_7_bypass" return {batchCode:'CP113133_7_A', suffix: 'bypass' }.
 */
function extractBatchIdDetails(batchId) {
  const batchIdPattern = /(?:tk\d+\.)?(.*?)_(?:([A-Z]|bypass))?$/;
  const match = batchIdPattern.exec(batchId);

  return {
    batchCode: match?.[1] ? `${match[1]}_${match[2]}` : batchId,
    suffix: match?.[2] ?? "",
  };
}

export default extractBatchIdDetails;
