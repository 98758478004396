import {gql} from "@apollo/client";

export const LOCATION_DETAILS = gql`
  query bioLcCoproConfigCenterLocationDetails {
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_detail_id
        location_id
        location_code
        location_name
        mass_balance_cycle
        country_id
        valid_from
        valid_to
        certificate_id_iscc_plus
        certificate_id_iscc_eu
        sap_location_code
      }
    }
  }
`;

export default {LOCATION_DETAILS};
