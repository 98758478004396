import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {uniqWith, isEqual, sortBy} from "lodash";
import {FormGroup, Label, Button} from "reactstrap";
import FormDropdown from "modules/common/FormDropdown/FormDropdown";
import {Controller, useFormContext} from "react-hook-form";
import "./index.css";

const CostCalculationFilterContent = ({
  defaultValues,
  filterDynamicData,
  handleFilterSubmit,
  setFeedstockList,
}) => {
  const {watch, control, setValue} = useFormContext();

  const watchPeriodMonth = watch("periodMonth");
  const watchPeriodYear = watch("periodYear");

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  const monthList = useMemo(() => {
    const dateListMonth = filterDynamicDataResult.map((item) => ({
      monthCostCalc: item.fifoMonth,
    }));

    const uniqueItems = uniqWith(dateListMonth, isEqual);
    return sortBy(uniqueItems, "monthCostCalc", "asc");
  }, [filterDynamicDataResult]);

  const yearList = useMemo(() => {
    const dateListYear = filterDynamicDataResult.map((item) => ({
      yearCostCalc: item.fifoYear,
    }));
    const uniqueItems = uniqWith(dateListYear, isEqual);

    const yearsInOrder = sortBy(uniqueItems, "yearCostCalc", "desc");
    return yearsInOrder?.slice(-5);
  }, [filterDynamicDataResult]);

  const feedstockList = useMemo(() => {
    const dateDetails = watchPeriodMonth && watchPeriodYear;
    const feedstockListItems =
      dateDetails &&
      filterDynamicDataResult
        ?.filter(
          (o) =>
            o.fifoYear ===
              (watchPeriodYear === "" ||
              watchPeriodYear === undefined ||
              watchPeriodYear === null
                ? o.fifoYear
                : watchPeriodYear) &&
            o.fifoMonth ===
              (watchPeriodMonth === "" ||
              watchPeriodMonth === undefined ||
              watchPeriodMonth === null
                ? o.fifoMonth
                : watchPeriodMonth),
        )
        ?.map((item) => ({
          materialShortName: item.materialShortName,
        }));

    const uniqueItems = uniqWith(feedstockListItems, isEqual);
    return sortBy(uniqueItems, "materialShortName", "asc");
  }, [filterDynamicDataResult, watchPeriodMonth, watchPeriodYear]);

  useEffect(() => {
    if (feedstockList) {
      setFeedstockList(feedstockList);
    }
  }, [feedstockList, setFeedstockList]);

  const currencyList = useMemo(() => {
    const currency = filterDynamicDataResult.map((item) => ({
      currencyUnit: item.currencyUnit,
    }));

    const uniqueItems = uniqWith(currency, isEqual);
    return sortBy(uniqueItems, "currencyUnit", "asc");
  }, [filterDynamicDataResult]);

  const unitRateList = useMemo(() => {
    const unitRate = filterDynamicDataResult.map((item) => ({
      unitRateBasedOnRatioCostCalc: item.unitRateBasedOnRatio,
      unitRateBasedOnTypeCostCalc: item.unitRateBasedOnType,
    }));

    const uniqueItems = uniqWith(unitRate, isEqual);
    return sortBy(uniqueItems, "unitRateBasedOnTypeCostCalc", "asc");
  }, [filterDynamicDataResult]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setValue("periodMonth", defaultValues?.periodMonth);
      setValue("periodYear", defaultValues?.periodYear);
      setValue("materialShortName", defaultValues?.materialShortName);
      setValue("currencyUnit", defaultValues?.currencyUnit);
      setValue("unitRate", defaultValues?.unitRate);
    }
  }, [
    setValue,
    defaultValues?.periodMonth,
    defaultValues?.periodYear,
    defaultValues?.materialShortName,
    defaultValues?.currencyUnit,
    defaultValues?.unitRate,
    defaultValues,
    filterDynamicDataResult?.length,
  ]);

  const feedstockOptions = feedstockList?.map((feedstock) => {
    return feedstock.materialShortName;
  });
  const feedstockValues = feedstockList?.map((feedstock) => {
    return feedstock.materialShortName;
  });

  const yearOptions = yearList?.map((year) => {
    return year.yearCostCalc;
  });
  const yearValues = yearList?.map((year) => {
    return year.yearCostCalc;
  });

  const monthOptions = monthList?.map((month) => {
    return Intl.DateTimeFormat("en", {month: "long"}).format(
      new Date(month.monthCostCalc),
    );
  });
  const monthValues = monthList?.map((month) => {
    return month.monthCostCalc;
  });

  const currencyOptions = currencyList?.map((cur) => {
    return cur.currencyUnit;
  });
  const currencyValues = currencyList?.map((cur) => {
    return cur.currencyUnit;
  });

  const unitRateOptions = unitRateList?.map((rate) => {
    return (
      `${rate.unitRateBasedOnTypeCostCalc}${rate.unitRateBasedOnRatioCostCalc}` +
      (rate.unitRateBasedOnRatioCostCalc ? "%" : "")
    );
  });
  const unitRateValues = unitRateList?.map((rate) => {
    return `${rate.unitRateBasedOnTypeCostCalc}${rate.unitRateBasedOnRatioCostCalc}`;
  });

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <Label className="pb-2 filter-by ">Filter by:</Label>
      <div className="flex flex-row gap-5">
        <FormGroup className="month-dropdown-filter">
          <Label for="periodMonth">Month</Label>
          <Controller
            control={control}
            name="periodMonth"
            render={({field}) => (
              <FormDropdown
                options={monthOptions}
                values={monthValues}
                data-test="periodMonth"
                hookToBody
                menuClassName="month-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormGroup>

        <FormGroup className="year-dropdown-filter">
          <Label for="periodYear">Year</Label>
          <Controller
            control={control}
            name="periodYear"
            render={({field}) => (
              <FormDropdown
                options={yearOptions}
                values={yearValues}
                data-test="periodYear"
                hookToBody
                menuClassName="year-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormGroup>

        <FormGroup className="feedstock-dropdown-filter">
          <Label for="materialShortName">Feedstock</Label>
          <Controller
            control={control}
            name="materialShortName"
            render={({field}) => (
              <FormDropdown
                options={feedstockOptions}
                values={feedstockValues}
                data-test="materialShortName"
                hookToBody
                menuClassName="feedstock-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormGroup>

        <FormGroup className="currency-dropdown-filter">
          <Label for="currencyUnit">Currency</Label>
          <Controller
            control={control}
            name="currencyUnit"
            render={({field}) => (
              <FormDropdown
                options={currencyOptions}
                values={currencyValues}
                data-test="currencyUnit"
                hookToBody
                menuClassName="currency-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormGroup>

        <FormGroup className="unit-rate-dropdown-filter">
          <Label for="unitRate">Unit rate based on:</Label>
          <Controller
            control={control}
            name="unitRate"
            render={({field}) => (
              <FormDropdown
                options={unitRateOptions}
                values={unitRateValues}
                data-test="unitRate"
                hookToBody
                menuClassName="unit-rate-dropdown-filter"
                {...field}
              />
            )}
          />
        </FormGroup>

        <div className="flex py-6 gap-x-4 mt-1">
          <Button
            className="p-[10px 19.5px] h-[40px] rounded-0  apply-button w-[74px]"
            color="standard-outline"
            data-test="filter-submit-button"
            type="button"
            form="cost-calculation-filter-form"
            onClick={handleFilterSubmit}
          >
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

CostCalculationFilterContent.propTypes = {
  defaultValues: PropTypes.object,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
  handleFilterClear: PropTypes.func,
  setFeedstockList: PropTypes.func.isRequired,
};
export default CostCalculationFilterContent;
