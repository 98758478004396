import {gql} from "@apollo/client";

export const UOM_CONVERSION = gql`
  query bioLcCoproConfigCenterUomConversion {
    bioLcCoproConfigCenterUomConversion {
      Records {
        conversion_type
        factor
        numerator
        denominator
        valid_from
        valid_to
      }
    }
  }
`;

export default {UOM_CONVERSION};
