import React from "react";
import {useWatch} from "react-hook-form";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
} from "modules/co-processing/constants/batches";
import {OperatingCondition, SplitBatch} from "../FormElements";

const StepTwo = () => {
  const operatingCondition = useWatch({
    name: ADD_BATCH_FORM.keys.opCondition,
  });

  return (
    <>
      <OperatingCondition />
      <SplitBatch isBypassed={operatingCondition === OP_CONDITIONS.BY_PASSED} />
    </>
  );
};

export default StepTwo;
