import {useState, useMemo, createContext, useContext, useCallback} from "react";
import PropTypes from "prop-types";

export const StatusContext = createContext({});

export const StatusProvider = ({children}) => {
  const [isDraft, setDraft] = useState(true);
  const [isRowSelected, setRowSelected] = useState(false);
  const [isSchedulableRecords, setSchedulableRecords] = useState(false);

  const draftChangeHandler = useCallback((draftState = []) => {
    setDraft(draftState);
  }, []);

  const rowChangeHandler = useCallback((rowSelected = []) => {
    setRowSelected(rowSelected);
  }, []);

  const schedulableRecordHandler = useCallback((schedulableFlag = []) => {
    setSchedulableRecords(schedulableFlag);
  }, []);

  const statusContext = useMemo(
    () => ({
      draftChangeHandler,
      isDraft,
      setDraft,
      isRowSelected,
      setRowSelected,
      rowChangeHandler,
      isSchedulableRecords,
      setSchedulableRecords,
      schedulableRecordHandler,
    }),
    [
      isDraft,
      draftChangeHandler,
      setDraft,
      isRowSelected,
      setRowSelected,
      rowChangeHandler,
      isSchedulableRecords,
      setSchedulableRecords,
      schedulableRecordHandler,
    ],
  );

  return (
    <StatusContext.Provider value={statusContext}>
      {children}
    </StatusContext.Provider>
  );
};

StatusProvider.propTypes = {
  children: PropTypes.node,
};

export const useStatus = () => {
  const context = useContext(StatusContext);

  if (context === undefined) {
    throw new Error("useStatus must be used inside StatusProvider");
  }

  return context;
};
