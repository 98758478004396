import React, {useState} from "react";
import PropTypes from "prop-types";

const CheckboxFilterGroup = ({children, defaultFilterMap}) => {
  const [showClickAwayBack, setShowClickAwayBack] = useState(false);
  const [filterMap, setFilterMap] = useState(defaultFilterMap);

  const hideDropDowns = !showClickAwayBack;
  const handleClickAwayBack = (filterName) => {
    setShowClickAwayBack(true);
    setFilterMap({...defaultFilterMap, [filterName]: !filterMap[filterName]});
  };

  return (
    <>
      <div
        className="click-away"
        onClick={() => {
          setShowClickAwayBack(false);
          setFilterMap(defaultFilterMap);
        }}
        onKeyDown={() => {
          /* do nothing */
        }}
        hidden={!showClickAwayBack}
      />
      {Array.isArray(children)
        ? children.map((child) =>
            React.cloneElement(child, {
              onClickAwayBack: handleClickAwayBack,
              hidden: hideDropDowns,
              filterMap,
            }),
          )
        : React.cloneElement(children, {
            onClickAwayBack: handleClickAwayBack,
            hidden: hideDropDowns,
            filterMap,
          })}
    </>
  );
};

export default CheckboxFilterGroup;

CheckboxFilterGroup.propTypes = {
  children: PropTypes.node,
  defaultFilterMap: PropTypes.object,
};
