import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const CERTIFICATE_GENERATION_MUTATION = gql`
  mutation bioLcCertGenPdf($event: GetCertGenPdfDataRequest!) {
    bioLcCertGenPdf(event: $event) {
      error
      statusCode
    }
  }
`;

export const CertificateGenerationMutationAPI = (data) =>
  client.mutate({
    mutation: CERTIFICATE_GENERATION_MUTATION,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export default {
  CERTIFICATE_GENERATION_MUTATION,
  CertificateGenerationMutationAPI,
};
