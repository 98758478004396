import React from "react";
import {ArrowRightOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import WatchIcon from "assets/images/svg/watch.svg";
import CalculatorIcon from "assets/images/svg/calculator.svg";
import FileOffice from "assets/images/svg/file-office.svg";

const Product = ({item}) => {
  const renderItemOne = (param) => {
    switch (param) {
      case "invoice":
        return "Number of invoices uploaded";
      case "bol":
        return "Number of BOL uploaded";
      case "shipmentRecord":
        return "Number of shipment records uploaded";
      case "total":
        return "Total Days saved";
      default:
        return "";
    }
  };

  const renderItemTwo = (param) => {
    const minutesSaved = "Minutes Saved";
    switch (param) {
      case "invoice":
      case "bol":
      case "shipmentRecord":
        return minutesSaved;
      case "total":
        return "Total Days saved";
      default:
        return "";
    }
  };

  const renderItemOneIcon = (param) => {
    switch (param) {
      case "invoice":
        return FileOffice;
      case "bol":
        return FileOffice;
      case "shipmentRecord":
        return FileOffice;
      case "total":
        return WatchIcon;
      default:
        return "";
    }
  };

  const renderItemTwoIcon = (param) => {
    switch (param) {
      case "invoice":
        return WatchIcon;
      case "bol":
        return WatchIcon;
      case "shipmentRecord":
        return WatchIcon;
      case "total":
        return WatchIcon;
      default:
        return "";
    }
  };

  const renderValueTwo = (val) => {
    switch (val.type) {
      case "invoice":
        return val.valueTwo.toLocaleString();
      case "bol":
        return val.valueTwo.toLocaleString();
      case "shipmentRecord":
        return val.valueTwo.toLocaleString();
      case "total":
        return val.value.toLocaleString();
      default:
        return "";
    }
  };

  return (
    <div
      data-test="product-card"
      className="flex-1 px-5 pt-5 m-1 mx-3 text-gray-700 bg-white rounded shadow-md md:mx-0 pb-7 text-start hover:bg-gray-50"
    >
      <div className="lg:flex lg:items-start">
        <div className="flex justify-between w-full">
          <div className="flex flex-col">
            <div
              data-test="product-title"
              className="p-0 m-0 font-bold lg:flex-shrink-0"
            >
              {item.title}
            </div>
          </div>
          <ArrowRightOutlined
            className="hidden"
            data-test="product-expand-more-arrow"
          />
        </div>
      </div>
      <div
        data-test="product-item-grid"
        className={
          item.showBoth ? "grid w-full gap-3 grid-cols-2" : "grid w-full  gap-3"
        }
      >
        <div
          data-test="product-sub-card-one"
          className="flex flex-col items-start justify-start w-full mt-4 rounded-lg shadow-md bg-gray-50"
        >
          <img
            data-test="img-sub-card-one"
            className="w-5 h-6 mt-3 ml-3"
            src={renderItemOneIcon(item.type)}
            alt=""
          />

          <h3
            data-test="sub-card-one-value"
            className="mx-3 text-2xl font-bold"
          >
            {item.value.toLocaleString()}
          </h3>

          <div
            data-test="sub-card-one-description"
            className="mx-3 mb-3 text-sm font-normal lg:flex-shrink-0"
          >
            {renderItemOne(item.type)}
          </div>
        </div>

        <div
          data-test="product-sub-card-two"
          hidden={!item.showBoth}
          className="flex flex-col items-start justify-start w-full mt-4 rounded-lg shadow-md bg-gray-50"
        >
          <img
            data-test="img-sub-card-two"
            className="w-5 h-6 mt-3 ml-3"
            src={renderItemTwoIcon(item.type)}
            alt=""
          />

          <h3
            data-test="sub-card-two-value"
            className="block mx-3 text-2xl font-bold"
          >
            {renderValueTwo(item)}
          </h3>

          <div
            data-test="sub-card-two-description"
            className="mx-3 mb-3 text-sm font-normal lg:flex-shrink-0"
          >
            {renderItemTwo(item.type)}
          </div>
        </div>
      </div>
    </div>
  );
};

Product.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Product;
