import React from "react";

import {useQuery} from "@apollo/client";
import {MB_BALANCE_TYPE_GET_QUERY} from "graphql/GlobalConfigCenter/MBBalanceType";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {MB_BALANCE_TYPE_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {MB_BALANCE_TYPE_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const MbBalanceTypeConfigPage = () => {
  const {data, loading} = useQuery(MB_BALANCE_TYPE_GET_QUERY);

  return (
    <Layout
      title={MB_BALANCE_TYPE_CONFIG_PAGE.title}
      isGlobal
      description={MB_BALANCE_TYPE_CONFIG_PAGE.description}
      tableColumns={MB_BALANCE_TYPE_COLUMNS}
      tableData={
        loading ? [] : data?.bioLcGetMbBalanceTypeApi.mbBalanceTypeData
      }
    />
  );
};

export default MbBalanceTypeConfigPage;
