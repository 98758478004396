import PropType from "prop-types";
import {useState} from "react";
import InvoicePreview from "./InvoicePreview";

const CherrypointPreviewPopup = ({title, record, isInvoice}) => {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div>
      <button
        type="button"
        onClick={() => setShowPopup(true)}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="dashboard-cherrypoint-data-button-link"
      >
        {title}
      </button>
      {showPopup ? (
        <InvoicePreview
          data-test="dashboard-cherrypoint-data-table-invoice-popup"
          invoiceDetails={record}
          preview={setShowPopup}
          isInvoice={isInvoice}
        />
      ) : null}
    </div>
  );
};

CherrypointPreviewPopup.propTypes = {
  isInvoice: PropType.bool.isRequired,
  record: PropType.any.isRequired,
  title: PropType.any,
};

export default CherrypointPreviewPopup;
