import React from "react";

import {useQuery} from "@apollo/client";
import {NATIONAL_REGISTRY_GET_QUERY} from "graphql/GlobalConfigCenter/NationalRegistry";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {NATIONAL_REGISTRY_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {NATIONAL_REGISTRY_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const NationalRegistryConfigPage = () => {
  const {data, loading} = useQuery(NATIONAL_REGISTRY_GET_QUERY);

  return (
    <Layout
      title={NATIONAL_REGISTRY_CONFIG_PAGE.title}
      description={NATIONAL_REGISTRY_CONFIG_PAGE.description}
      tableColumns={NATIONAL_REGISTRY_COLUMNS}
      tableData={
        loading ? [] : data?.bioLcGetNationalRegistry.nationalRegistryData
      }
    />
  );
};

export default NationalRegistryConfigPage;
