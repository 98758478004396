import {gql} from "@apollo/client";

export const COUNTRY_CODE_GET_QUERY = gql`
  query bioLcGetCountryCodeData {
    bioLcGetCountryCodeData(event: {}) {
      countryCodeData {
        countryName
        countryCode
        sapCountryCode
      }
    }
  }
`;

export default {COUNTRY_CODE_GET_QUERY};
