export const RuleStatus = {
  PUBLISHED: {
    message: "PUBLISHED",
    className: " disabled:text-white bg-[#e6fff2]",
  },
  DELETED: {
    message: "DELETED",
    className: "disabled:text-white bg-[#ffedf0]",
  },
  DRAFT: {
    message: "DRAFT",
    className: "disabled:text-white bg-[#ecfafe]",
  },
};

export default {RuleStatus};
