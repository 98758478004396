import {gql} from "@apollo/client";

export const MATERIAL_MASTER = gql`
  query bioLcCoproConfigCenterMaterialMaster {
    bioLcCoproConfigCenterMaterialMaster {
      Records {
        material_id
        material_code
        material_short_name
        material_long_name
        material_type
        material_status
        material_group
      }
    }
  }
`;

export default {MATERIAL_MASTER};
