import {currMonthPublishedActuals} from "modules/forecasting/helpers/computeEastCoastData";
import PropTypes from "prop-types";

// Deficits charts & table expect gas_total, diesel_total
export const getCategoryKey = (key) =>
  ["gas_total", "diesel_total"].includes(key)
    ? key?.split("_").reverse().join("_")
    : key;

export const transformApiData = (
  obligationsList = [],
  forecastType = "deficits",
) =>
  obligationsList?.map((apiItem) => {
    const publishedActuals = currMonthPublishedActuals(apiItem);
    const totalsPerMonth = Object.entries(publishedActuals).reduce(
      (memo, [key, actual]) => ({
        ...memo,
        [getCategoryKey(key)]: {actualValue: actual},
        total_obligation: {
          actualValue: memo.total_obligation.actualValue + actual,
        },
      }),
      {total_obligation: {actualValue: 0}},
    );

    return {
      ...totalsPerMonth,
      date: apiItem?.date,
    };
  });

const totalFieldType = {actualValue: PropTypes.number};

export const deficitsFieldType = {
  date: PropTypes.object.isRequired,
  gas_total: PropTypes.shape(totalFieldType),
  diesel_total: PropTypes.shape(totalFieldType),
  total_obligation: PropTypes.shape(totalFieldType),
};

export const creditsFieldType = {
  date: PropTypes.object.isRequired,
  d4_total: PropTypes.shape(totalFieldType),
  d5_total: PropTypes.shape(totalFieldType),
  d6_total: PropTypes.shape(totalFieldType),
  total_obligation: PropTypes.shape(totalFieldType),
};
