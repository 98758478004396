import PropTypes from "prop-types";
import moment from "moment";
import at from "lodash/fp/at";
import set from "lodash/fp/set";
import omit from "lodash/fp/omit";
import flow from "lodash/fp/flow";
import EditableTableCell from "modules/forecasting/components/EditableTableCell";
import ForecastArrowWidget from "modules/forecasting/components/ForecastArrowWidget";
import computeMonthTotalWithVariance from "modules/forecasting/helpers/computeMonthlyTotals";
import {
  gasKeyValuePairsToPublish,
  dieselKeyValuePairsToPublish,
} from "modules/forecasting/constants/inventory";
import * as yup from "yup";
import {calculateUnpublishedVariancePercentage} from "./calculateVariancePercentage";
import {
  isDieselField,
  isGasField,
  getObligatedTotalRow,
  isTableTitleRow,
  isMonthTitleRow,
  isTotalRow,
  sumMonthTotal,
} from "./tableUtils";

const formatMonthTitle = (month) =>
  moment(month, "MM").format("MMM").toLocaleUpperCase();

const formatDateYYYY_MM = ({year, month} = {}) => `${year}_${month}`;

const dateRegexYYYY_MM = /^(?:[0-9]{2})?[0-9]{2}_(1[0-2]|0?[1-9])$/;
const getDataIndexFromRow = (rowObj) =>
  Object.keys(rowObj).filter((key) => dateRegexYYYY_MM.test(key));

const isNotNull = (v) => v !== null && v !== undefined;
const formatDisplayValue = (value) =>
  isNotNull(value) ? value?.toLocaleString("en-US") : "";

const dropTypeNameProperty = omit("__typename");
const isGasObligations = (list = []) =>
  list.length &&
  list.some((monthData) => monthData.__typename === "BioLcGasObligations");

const mergeGasWithDieselProducts = ([gasList, dieselList]) =>
  gasList.map((gasItem, i) => ({
    ...gasItem,
    ...dieselList[i],
    ...{
      total_obligation: {
        actualValue:
          gasItem.total_gas.actualValue +
          dieselList[i].total_diesel.actualValue,
        systemValue:
          gasItem.total_gas.systemValue +
          dieselList[i].total_diesel.systemValue,
        variance:
          gasItem.total_gas.variance + dieselList[i].total_diesel.variance,
        ...(gasItem.total_gas.variance && {
          variancePercentage:
            gasItem.total_gas.variancePercentage +
            dieselList[i].total_diesel.variancePercentage,
        }),
      },
    },
  }));

const getGasDieselFromResponse = at([
  "bioLcForecastApi.body.obligations.gas",
  "bioLcForecastApi.body.obligations.diesel",
]);

// On Unpublished Page savedValues from the api response is treated as the actuals
const computePageValues = (keyValuePairs, {values, savedValues} = {}) =>
  Object.fromEntries(
    Object.entries(dropTypeNameProperty(savedValues)).map(([k, savedValue]) => {
      const systemValue = values?.[k] ?? 0;
      // arrow widget value = dataPageValue - savedValue;
      const variance = systemValue - savedValue;
      const variancePercentage = calculateUnpublishedVariancePercentage(
        variance,
        systemValue,
        savedValue,
      );

      return [
        [keyValuePairs[k]],
        {
          actualValue: savedValue,
          systemValue,
          variance,
          variancePercentage,
        },
      ];
    }),
  );

const buildGasFields = ({date, ...gasData} = {}) => {
  const gasProducts = computePageValues(gasKeyValuePairsToPublish, gasData);
  const total_gas = computeMonthTotalWithVariance(Object.values(gasProducts));

  return {
    date: dropTypeNameProperty(date),
    ...gasProducts,
    total_gas,
  };
};

const buildDieselFields = ({date, ...dieselList} = {}) => {
  const dieselProducts = computePageValues(
    dieselKeyValuePairsToPublish,
    dieselList,
  );
  const total_diesel = computeMonthTotalWithVariance(
    Object.values(dieselProducts),
  );

  return {
    date: dropTypeNameProperty(date),
    ...dieselProducts,
    total_diesel,
  };
};

const setProductKeys = (obligationsList) =>
  obligationsList.map((obligations) =>
    isGasObligations(obligations)
      ? obligations.map(buildGasFields)
      : obligations.map(buildDieselFields),
  );

export const computeUnpublishedData = ({responseData}) =>
  flow([getGasDieselFromResponse, setProductKeys, mergeGasWithDieselProducts])(
    responseData,
  );

export const mapPageDataToTableRows = (
  yearlyData,
  forecastUserData,
  measurementLabels,
) => {
  return Object.entries(measurementLabels).map(([rowKey, measurementLabel]) => {
    let priorYear;

    const forecastUserDataMatch = forecastUserData?.filter((item) =>
      rowKey.includes(item.key),
    );

    const rowData = yearlyData.reduce((memo, monthData) => {
      const {date, ...valuesForEachMonth} = monthData;
      if (!priorYear) priorYear = date.year - 1;
      const columnKey = formatDateYYYY_MM(date);
      const productDataPerMonth = valuesForEachMonth?.[rowKey] || {};

      // Table Type rows
      if (rowKey.includes("table")) {
        return {
          ...memo,
          [columnKey]: "",
        };
      }

      // Month Title rows
      if (rowKey.includes("title")) {
        return {
          ...memo,
          [columnKey]: formatMonthTitle(date?.month) || "",
        };
      }

      const priorValues = forecastUserDataMatch.length
        ? {
            [`${columnKey}-priorValue`]:
              forecastUserDataMatch[0][
                Object.keys(forecastUserDataMatch[0]).find((item) =>
                  item.startsWith(`${columnKey}-priorYear`),
                )
              ],
            [`${columnKey}-monthOverMonth`]:
              forecastUserDataMatch[0][
                Object.keys(forecastUserDataMatch[0]).find((item) =>
                  item.startsWith(`${columnKey}-monthOverMonth`),
                )
              ],
            [`${columnKey}-weightedAverage`]:
              forecastUserDataMatch[0][
                Object.keys(forecastUserDataMatch[0]).find((item) =>
                  item.startsWith(`${columnKey}-weightedAverage`),
                )
              ],
          }
        : {};

      // Product rows
      return {
        ...memo,
        [columnKey]: productDataPerMonth?.actualValue ?? null,
        [`${columnKey}-dataPageValue`]:
          productDataPerMonth?.systemValue ?? null,
        [`${columnKey}-variance`]: productDataPerMonth?.variance || 0,
        [`${columnKey}-variancePercentage`]:
          productDataPerMonth?.variancePercentage || 0,
        ...priorValues,
      };
    }, {});

    return {
      key: rowKey,
      measurement: measurementLabel,
      priorYear,
      ...rowData,
    };
  });
};

// -------------------- Columns ------------------------------ //

const measurementColumn = {
  align: "left",
  dataIndex: "measurement",
  key: "measurementLabelColumn",
  onCell: (row) => {
    const styles = {paddingLeft: 20, fontSize: 16};
    const titleRowStyles = {
      ...styles,
      backgroundColor: "rgb(249 249 249)",
      borderBottom: "2px solid rgb(210, 210, 212)",
    };

    return {style: isTableTitleRow(row) ? titleRowStyles : styles};
  },
  render: (value, row) => (
    <h3
      className="text-lg font-black whitespace-nowrap"
      data-test={`measurement-label-cell-${row?.key}`}
    >
      {value}
    </h3>
  ),
  width: 170,
};

const TotalAmountCell = ({cellId, value}) => {
  return (
    <div data-test={`${cellId}-cell-savedValue`} className="text-right">
      {formatDisplayValue(value)}
    </div>
  );
};
TotalAmountCell.propTypes = {
  cellId: PropTypes.string,
  value: PropTypes.number,
};

const CellWrapper = ({children, value, record, dateKey}) => {
  const cellId = `${record.key}_${dateKey}`;
  const valueFromDataPage = record?.[`${dateKey}-dataPageValue`] || 0;
  const savedValue =
    record?.updatedForecast?.[dateKey] ?? (value || record?.[dateKey]);
  const variance = valueFromDataPage - savedValue;
  const variancePercentage = calculateUnpublishedVariancePercentage(
    variance,
    valueFromDataPage,
    savedValue,
  );
  const isVarianceOverFour = Math.abs(variancePercentage) > 4;

  return isMonthTitleRow(record) || isTableTitleRow(record) ? (
    <h3
      data-test={`${value}-title-cell`}
      className="font-black whitespace-nowrap"
    >
      {value}
    </h3>
  ) : (
    <div
      data-test={`${cellId}-cell-container`}
      className="grid grid-cols-[130px_70px] gap-8 whitespace-nowrap justify-center"
    >
      {children({cellId, savedValue, variance, isVarianceOverFour})}
    </div>
  );
};
CellWrapper.propTypes = {
  children: PropTypes.func,
  dateKey: PropTypes.any,
  record: PropTypes.any,
  value: PropTypes.any,
};

export const buildColumnsWithEditableCells = (handleSave, list) => {
  if (list.length === 0) return [];

  const columnDateKeys = getDataIndexFromRow(list[0] || {});

  const editableColumns = columnDateKeys.map((dataIndex, index) => ({
    dataIndex,
    key: `editableColumn_${index + 1}`,
    onCell: (row) => {
      if (isTableTitleRow(row)) {
        return {
          style: {
            fontSize: 16,
          },
        };
      }
      if (isMonthTitleRow(row)) {
        return {
          style: {
            fontSize: 16,
          },
        };
      }
      if (isTotalRow(row)) {
        return {
          style: {
            fontSize: 16,
            fontWeight: 900,
            color: "#3f3e45",
          },
        };
      }

      return {
        style: {
          fontSize: 16,
          justifyContent: "center",
        },
      };
    },
    render: (value, row) => {
      if (isMonthTitleRow(row) || isTotalRow(row) || isTableTitleRow(row)) {
        return (
          <CellWrapper value={value} record={row} dateKey={dataIndex}>
            {({cellId, savedValue, variance, isVarianceOverFour}) => (
              <>
                <TotalAmountCell value={savedValue} cellId={cellId} />
                <ForecastArrowWidget
                  changedValue={variance || 0}
                  isVarianceOver4Percent={isVarianceOverFour}
                />
              </>
            )}
          </CellWrapper>
        );
      }

      return (
        <EditableTableCell
          dataIndex={dataIndex}
          handleSave={handleSave}
          key={dataIndex}
          record={row}
        />
      );
    },
  }));

  return [measurementColumn, ...editableColumns];
};

// utilities to update Obligated Total row
export const setTotalObligationsForMonth = (month, updatedTableData = []) => {
  const obligatedTotalRow = getObligatedTotalRow(updatedTableData);
  const newTotalForMonth = sumMonthTotal(month, updatedTableData);

  return set(`updatedForecast.${month}`, newTotalForMonth, obligatedTotalRow);
};

export const updateGasObligatedTotal = (dateKey, updatedTableData) => {
  if (!updatedTableData.length) return {};

  const gasFields = updatedTableData.filter(isGasField);
  const newValue = sumMonthTotal(dateKey, gasFields);
  const gasObligatedTotalRow = updatedTableData.find(
    (o) => o.key === "total_gas",
  );

  return set(`updatedForecast.${dateKey}`, newValue, gasObligatedTotalRow);
};

export const updateDieselObligatedTotal = (dateKey, updatedList) => {
  const dieselRows = updatedList.filter(isDieselField);
  const newValue = sumMonthTotal(dateKey, dieselRows);
  const dieselObligatedTotalRow = updatedList.find(
    (o) => o.key === "total_diesel",
  );

  return set(`updatedForecast.${dateKey}`, newValue, dieselObligatedTotalRow);
};

const schema = yup.object().shape({
  bioLcForecastUserApi: yup.object().shape({
    statusCode: yup.number().required(),
    body: yup.array().of(
      yup.object().shape({
        date: yup.object().shape({
          year: yup.number().required(),
          month: yup.number().required(),
        }),
        obligationItems: yup.array().of(
          yup.object().shape({
            type: yup.string().required(),
            obligationValues: yup.object().shape({
              alaska_exports_diesel: yup.number().nullable(),
              heating_oil_diesel: yup.number().nullable(),
              inter_area_movements_exports_diesel: yup.number().nullable(),
              marine_shipments_diesel: yup.number().nullable(),
              supply_term_military_sales_diesel: yup.number().nullable(),
              non_obligated_export_gas: yup.number().nullable(),
              renewable_diesel: yup.number().nullable(),
              obligated_blended_production: yup.number().nullable(),
              production_diesel: yup.number().nullable(),
              other_volumes_excluded_gas: yup.number().nullable(),
              other_volumes_excluded_diesel: yup.number().nullable(),
            }),
          }),
        ),
      }),
    ),
  }),
});

export const processForecastUserData = (userData) => {
  // validate object based on the schema
  const isValid = schema.isValidSync(userData);

  if (!isValid) {
    return [];
  }

  const {body} = userData.bioLcForecastUserApi;
  const keys = Object.keys(body[0].obligationItems[0].obligationValues).filter(
    (o) => o !== "__typename",
  );

  // return an array of objects with the following shape:
  // [{
  //   key: "alaska_exports_diesel",
  //   priorYear: 2020,
  //   "2021_1-priorYear": 0,
  //   "2021_2-priorYear": 0,
  // ...
  // },
  // ...]
  return keys.map((key) => {
    return body.reduce((acc, row) => {
      const {year, month} = row.date;

      const types = row.obligationItems.map((item) => item.type);

      const values = {};

      types.forEach((type) => {
        const objItem = row.obligationItems.find((item) => item.type === type);

        values[`${year}_${month}-${type}`] = objItem.obligationValues[key];
      });

      return {
        ...acc,
        key,
        priorYear: year - 1,
        ...values,
      };
    }, {});
  });
};
