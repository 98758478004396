import {Modal as AModal} from "antd";
import PropTypes from "prop-types";
import "./index.scss";
import Heading from "DLS/Typography/Heading";
import closeIcon from "../../../assets/images/svg/close.svg";

const Modal = ({
  children,
  wrapClassName = "",
  footer = [],
  title = null,
  full = false,
  ...props
}) => (
  <AModal
    role="dialog"
    maskClosable={false}
    wrapClassName={[
      "bp-modal",
      wrapClassName || "",
      full ? "fullscreen" : "",
    ].join(" ")}
    title={title && <Heading level={4}>{title}</Heading>}
    closable={false}
    closeIcon={<img src={closeIcon} alt="close" />}
    footer={footer} // Empty by default
    width={null} // force Antd to not add width style
    centered
    {...props}
  >
    {children}
  </AModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  wrapClassName: PropTypes.string,
  footer: PropTypes.node,
  full: PropTypes.bool,
  title: PropTypes.node,
};

export default Modal;
