import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {
  Input,
  Label,
  FormGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  SHIPMENT_TYPES_DROPDOWN,
  STATUS_DROPDOWN,
} from "modules/co-processing/constants/shipments";
import "./index.scss";

export const ShipmentStatusFilter = ({
  shipmentStatuses,
  onShipmentStatusChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState(shipmentStatuses);

  useEffect(() => {
    setSelectedStatuses(shipmentStatuses);
  }, [shipmentStatuses]);

  const handleStatusChange = ({target}) => {
    const value = target?.value || "";
    const newStatuses = selectedStatuses.includes(value)
      ? selectedStatuses.filter((status) => status !== value)
      : [...selectedStatuses, value];
    setSelectedStatuses(newStatuses);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);
  const applyFilters = () => {
    onShipmentStatusChange(selectedStatuses);
    toggleDropdown();
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
      <DropdownToggle color="standard" caret>
        Shipment status
        {shipmentStatuses?.length > 0 ? ` (${shipmentStatuses.length})` : ""}
        <Down24 />
      </DropdownToggle>
      <DropdownMenu>
        {STATUS_DROPDOWN.map(({value, label} = {}) => {
          return (
            <DropdownItem key={value} toggle={!isOpen}>
              <FormGroup className="mb-md-0 w-full form-check-alt form-check-reverse">
                <Input
                  checked={selectedStatuses?.includes(value)}
                  id={`status-${value}`}
                  value={value}
                  type="checkbox"
                  onChange={handleStatusChange}
                />
                <Label check for={`status-${value}`}>
                  {label}
                </Label>
              </FormGroup>
            </DropdownItem>
          );
        })}
        <DropdownItem divider />
        <DropdownItem className="justify-center" onClick={applyFilters}>
          Apply filter
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
ShipmentStatusFilter.propTypes = {
  shipmentStatuses: PropTypes.arrayOf(PropTypes.string),
  onShipmentStatusChange: PropTypes.func,
};

export const ShipmentTypeFilter = ({
  activeShipmentType,
  onShipmentTypeChange,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="standard" caret>
        {SHIPMENT_TYPES_DROPDOWN.find((t) => t?.value === activeShipmentType)
          ?.label || "Shipment type"}
        <Down24 />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={(e) => onShipmentTypeChange("")}>
          All shipment types
        </DropdownItem>
        {SHIPMENT_TYPES_DROPDOWN.map(({value, label}) => {
          return (
            <DropdownItem
              onClick={(e) => onShipmentTypeChange(value)}
              key={value}
            >
              {label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};
ShipmentStatusFilter.propTypes = {
  shipmentStatuses: PropTypes.arrayOf(PropTypes.string),
  onShipmentStatusChange: PropTypes.func,
};

ShipmentTypeFilter.propTypes = {
  activeShipmentType: PropTypes.string,
  onShipmentTypeChange: PropTypes.func,
};
