import {gql} from "@apollo/client";

export const LOCATION_MASTER = gql`
  query bioLcCoproConfigCenterLocationMasterDetails {
    bioLcCoproConfigCenterLocationMasterDetails {
      Records {
        location_id
        location_code
        location_name
        legal_entity
        location_desc
        location_type
        country_code
        location_status
      }
    }
  }
`;

export default {LOCATION_MASTER};
