const calculateRemainingVolume = (
  shipmentData,
  currentIndex,
  isTruckRack,
  actualVolumes,
) => {
  const {total_estimated_volume, parts} = shipmentData ?? {};

  // return 0 if estimated total and parts are missing
  if (!total_estimated_volume || !parts) return 0;

  // Calculate the sum of other parts' volumes, using actualVolumes for up-to-date data
  const sumOfOtherParts = parts.reduce((sum, part, index) => {
    if (isTruckRack) {
      return 0; // Ignore parts for truck rack shipments
    }

    // Use actualVolumes array if available, otherwise fall back to part.total_actual_volume
    const partVolume =
      actualVolumes && actualVolumes[index] !== undefined
        ? actualVolumes[index]
        : part.total_actual_volume || 0;
    return index !== currentIndex ? sum + partVolume : sum;
  }, 0);

  return Math.max(0, total_estimated_volume - sumOfOtherParts);
};

export default calculateRemainingVolume;
