import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const SPLIT_FEEDSTOCK = gql`
  mutation splitShipmentRefineryData(
    $balance: String
    $batchId: String
    $shipDateISO: String
    $deliverySlipBOL: String
  ) {
    splitShipmentRefineryData(
      event: {
        balance: $balance
        batchId: $batchId
        shipDateISO: $shipDateISO
        deliverySlipBOL: $deliverySlipBOL
      }
    ) {
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const SHIPMENTS_REFINARY = gql`
  query listShipmentRefinery(
    $fromDate: String
    $toDate: String
    $isDelete: Boolean
  ) {
    getDashBoardData(
      event: {fromdate: $fromDate, todate: $toDate, isSoftDelete: $isDelete}
    ) {
      body {
        batchId
        weightAnimalFatsMT
        weightUsedCookingOilMT
        weightSoybeanOilMT
        weightCanolaOilMT
        weightDistillersCornOilMT
        weightOtherOilMT
        bbl
        bblToMt
        begBalance
        comments
        dealNumberP
        dealNumberS
        deliverySlipBOL
        density
        invoiceNo
        wcrSalesContract
        isSoftDelete
        itemDescription
        owbBatchID
        shipDate
        shipDateISO
        shipTime
        tankId
        units
        action
        actionDate
      }

      statusCode
    }
  }
`;

export const DELETE_SHIPMENT_REFINERY = gql`
  mutation deleteShipmentRecord(
    $data: [UpdateShipmentRefineryDataSingleRequest!]
  ) {
    updateShipmentRefineryDataList(
      event: {updateShipmentRefineryDataList: $data}
    ) {
      data {
        actionDate
        action
        bbl
        bblToMt
        begBalance
        comments
        dealNumberP
        dealNumberS
        deliverySlipBOL
        density
        invoiceNo
        isSoftDelete
        itemDescription
        owbBatchID
        shipDateISO
        shipDate
        shipTime
        tankId
        units
        wcrSalesContract
      }
      errors {
        message
        code
      }
      message
      statusCode
    }
  }
`;

export const deleteCherrypointApi = async (data) => {
  return client.mutate({
    mutation: DELETE_SHIPMENT_REFINERY,
    variables: {
      data,
    },
    refetchQueries: ["listShipmentRefinery"],
  });
};

export const RESTORE_SHIPMENT_REFINERY = gql`
  mutation restoreShipmentRefinery(
    $data: [UpdateShipmentRefineryDataSingleRequest!]
  ) {
    updateShipmentRefineryDataList(
      event: {updateShipmentRefineryDataList: $data}
    ) {
      data {
        actionDate
        action
        bbl
        bblToMt
        begBalance
        comments
        dealNumberP
        dealNumberS
        deliverySlipBOL
        density
        invoiceNo
        isSoftDelete
        itemDescription
        owbBatchID
        shipDateISO
        shipDate
        shipTime
        tankId
        units
        wcrSalesContract
      }
      errors {
        message
        code
      }
      message
      statusCode
    }
  }
`;

export const EDIT_SHIPMENT_REFINERY = gql`
  mutation editShipmentRefinery(
    $data: [UpdateShipmentRefineryDataSingleRequest!]
  ) {
    updateShipmentRefineryDataList(
      event: {updateShipmentRefineryDataList: $data}
    ) {
      data {
        actionDate
        action
        bbl
        bblToMt
        begBalance
        comments
        dealNumberP
        dealNumberS
        deliverySlipBOL
        density
        invoiceNo
        isSoftDelete
        itemDescription
        owbBatchID
        shipDateISO
        shipDate
        shipTime
        tankId
        units
        wcrSalesContract
      }
      errors {
        message
        code
      }
      message
      statusCode
    }
  }
`;

export const updateCherrypointApi = async (data) => {
  return client.mutate({
    mutation: EDIT_SHIPMENT_REFINERY,
    variables: {
      data,
    },
    refetchQueries: ["listShipmentRefinery"],
  });
};

export const DOWNLOAD_DOCUMENT = gql`
  query downloadRefineryDocument(
    $billoflanding: String
    $invoice: String
    $type: String
  ) {
    supportingDocumentURL(
      event: {billoflanding: $billoflanding, invoice: $invoice, type: $type}
    ) {
      url
      url_download
      statusCode
    }
  }
`;

export default {
  SHIPMENTS_REFINARY,
  DELETE_SHIPMENT_REFINERY,
  RESTORE_SHIPMENT_REFINERY,
  EDIT_SHIPMENT_REFINERY,
};
