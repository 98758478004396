import React from "react";
import {useLazyQuery} from "@apollo/client";
import {USER_FORECAST_AUDIT_DATA} from "graphql/forecast-data";
import {useActiveFilters} from "modules/forecasting/context/ForecastingFilterContext";
import useCreditsAndDeficits from "modules/forecasting/helpers/useCreditsAndDeficits";
import {REGIONS} from "modules/forecasting/constants/inventory";
import KPICardSection from "modules/forecasting/components/KPICardSection";
import EmptyData from "modules/forecasting/components/EmptyData";
import SavedVersionsTable from "modules/forecasting/components/SavedVersionsTable";
import {logFetchDefaults} from "modules/forecasting/helpers/getQueryVariables";
import PublishedViewByForecastType from "modules/forecasting/components/PublishedViewByForecastType";
import InventoryWrapper from "modules/forecasting/components/InventoryWrapper";

const InventoryPublished = () => {
  const {forecastType, year} = useActiveFilters();
  const {data, loading} =
    useCreditsAndDeficits({region: REGIONS.WEST.id, year}) || {};

  const [fetchAuditLog, {data: dataAudit, loading: loadingAudit}] =
    useLazyQuery(USER_FORECAST_AUDIT_DATA, {
      variables: logFetchDefaults({
        group: "logPublish",
        region: REGIONS.WEST.id,
        forecast_type: forecastType,
      }),
      fetchPolicy: "cache-and-network",
    });

  if (!data && !loading) return <EmptyData />;

  return (
    <InventoryWrapper loading={loading}>
      <PublishedViewByForecastType
        data={data}
        region={REGIONS.WEST.id}
        renderKPI={(kpiProps) => <KPICardSection {...kpiProps} />}
        activeForecastType={forecastType}
      />

      <SavedVersionsTable
        data={dataAudit}
        loading={loadingAudit}
        fetchAuditLog={fetchAuditLog}
        type="Published"
      />
    </InventoryWrapper>
  );
};

export default InventoryPublished;
