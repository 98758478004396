import React from "react";
import CombinedObligatedVolumeTable from "modules/forecasting/components/CombinedObligatedVolumeTable";
import ForecastGraphSet from "modules/forecasting/components/ForecastGraphSet";
import {pageDataToGraph} from "modules/forecasting/helpers/computeGraphData";
import {useSelectedDate} from "modules/forecasting/context/ForecastingDateContext";
import {useActiveFilters} from "modules/forecasting/context/ForecastingFilterContext";
import {transformEastCoastData} from "modules/forecasting/helpers/computeEastCoastData";
import {isCreditType} from "modules/forecasting/helpers/forecastFilterUtils";
import InventoryWrapper from "modules/forecasting/components/InventoryWrapper";
import ViewByForecastType, {
  mapKPIToDataView,
} from "modules/forecasting/components/ViewByForecastType";
import KPICardSection from "modules/forecasting/components/KPICardSection";
import TotalCharts from "modules/forecasting/components/GraphSection/TotalCharts";
import GraphSection from "modules/forecasting/components/GraphSection/GraphSection";
import useCreditsAndDeficits from "modules/forecasting/helpers/useCreditsAndDeficits";
import "../../components/index.scss";

const ECData = () => {
  const {monthIndex} = useSelectedDate();
  const {year} = useActiveFilters();
  const {data, loading} = useCreditsAndDeficits({region: "ec", year}) || {};

  return (
    <InventoryWrapper loading={loading}>
      <ViewByForecastType
        data={data}
        region="ec"
        transformerFn={transformEastCoastData}
        renderKPI={({state, forecastTypes}) =>
          forecastTypes.map((forecastType) => (
            <KPICardSection
              key={forecastType}
              {...mapKPIToDataView({
                forecastType,
                variance: state?.[forecastType].variance,
              })}
            />
          ))
        }
      >
        {({forecastTypeName, pageData}) => {
          const {graphValues = []} = pageDataToGraph(pageData);

          return (
            <React.Fragment key={forecastTypeName}>
              <GraphSection
                view="dataView"
                isCredits={isCreditType(forecastTypeName)}
              >
                <TotalCharts forecastType={forecastTypeName} data={pageData} />

                <ForecastGraphSet
                  isCredits={isCreditType(forecastTypeName)}
                  fullYearData={graphValues}
                  graphData={
                    graphValues?.slice(monthIndex, monthIndex + 4) || []
                  }
                />
              </GraphSection>

              <CombinedObligatedVolumeTable
                forecastType={forecastTypeName}
                pageData={pageData?.slice(monthIndex, monthIndex + 4) || []}
                region="ec"
              />
            </React.Fragment>
          );
        }}
      </ViewByForecastType>
    </InventoryWrapper>
  );
};

export default ECData;
