import React from "react";
import PropTypes from "prop-types";
import {BarChart, Bar, XAxis, YAxis} from "recharts";
import tw, {styled} from "twin.macro";

const BarChartHeight = 200;
const BarColors = {total: "#395600", gas: "#89cf00", diesel: "#ffe600"};
const defaultStyle = {barSize: 30, margin: {top: 0, bottom: 0}};
const publishedViewStyle = {barSize: 30, margin: {top: 10, bottom: 20}};
const labelVariants = {total: tw`pr-1`, gas: tw`px-1.5`, diesel: tw`pl-1`};

const StyledBarGraphCard = tw.div`flex flex-col justify-center items-center`;

const StyledValueLabelsContainer = tw.div`
  flex flex-row divide-x divide-black
  self-stretch mx-auto pr-1.5 pt-4
  text-sm leading-3 tracking-tighter
`;

const BarChartName = tw.div`pb-5`;

const DisplayValueLabel = styled.div(() => [
  tw`basis-1/3`,
  ({type = "gas"}) => labelVariants[type],
]);

const getValues = (data = []) =>
  data?.map(({gas, diesel, total}) => [gas.value, diesel.value, total.value]);

const BarGraphSet = ({data, width, view}) => {
  const styles =
    {
      publishedView: publishedViewStyle,
    }[view] || defaultStyle;

  const maxYValue = Math.max(...getValues(data)[0]);

  const gasDieselAndTotalBar = data.map((item) =>
    Object.keys(item)
      .filter((key) => key !== "name")
      .map((key) => (
        <Bar
          barSize={styles.barSize}
          dataKey={`${key}.value`}
          fill={BarColors[key]}
          key={key}
        />
      )),
  );

  const graphData = data?.find((item) => item.name);

  return (
    <StyledBarGraphCard>
      <>
        <BarChartName data-test="barGraph-name" key={graphData.name}>
          {graphData.name || ""}
        </BarChartName>
        <BarChart
          barGap="4%"
          data={data}
          margin={styles.margin}
          width={width}
          height={BarChartHeight}
        >
          {gasDieselAndTotalBar}
          <YAxis domain={[0, maxYValue]} hide />
          <XAxis dataKey="name" tickLine={false} hide />
        </BarChart>

        <StyledValueLabelsContainer data-test="bar-display-values">
          {Object.entries(graphData)
            .filter(([key, _]) => key !== "name")
            .map(([key, {value}]) => (
              <DisplayValueLabel
                data-test={`display-value-${key}`}
                key={`${key}-${graphData.name}`}
              >
                {(value ?? 0).toLocaleString()}
              </DisplayValueLabel>
            ))}
        </StyledValueLabelsContainer>
      </>
    </StyledBarGraphCard>
  );
};

BarGraphSet.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  view: PropTypes.string,
};

export default BarGraphSet;
