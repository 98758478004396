import BulkAdjustmentShipmentPopover from "modules/co-processing/components/Shipments/BulkAdjustment/BulkAdjustmentShipmentPopover";
import BatchesStatusChip from "../components/Shared/BatchesStatusChip";
import MetricInputField from "../components/Shared/MetricInputField";
import {METRICS} from "../constants/bulkAdjustment";

const getDisplayValue = (props, inputChange, onError) => {
  const {column, getValue, row} = props;
  const value = getValue();

  switch (column.id) {
    case "shipment_status":
      return <BatchesStatusChip value={value} small />;

    case "shipment_volume":
      return (
        <MetricInputField
          value={value}
          shipment={row?.original}
          onError={onError}
          handleInputChange={inputChange}
          showMetric
          hasNetChange
        />
      );

    case "estimated_volume":
      return <div>{`(${value?.toLocaleString()} ${METRICS.BBL})`}</div>;

    default:
      return <div>{value}</div>;
  }
};

const buildAdjustmentColumns = (
  columnHelper,
  headers,
  inputChange,
  onError,
) => {
  return Object.keys(headers).map((key) => {
    switch (key) {
      case "shipment_status":
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: (props) => getDisplayValue(props, inputChange, onError),
        });

      case "shipment_id":
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: ({row}) => (
            <BulkAdjustmentShipmentPopover
              shipment={row?.original}
              target={`shipment-popover-${row?.index}`}
            />
          ),
        });

      case "shipment_type":
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: (props) => getDisplayValue(props, inputChange, onError),
        });

      case "estimated_volume":
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: (props) => getDisplayValue(props, inputChange, onError),
        });

      case "shipment_volume":
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: (props) => getDisplayValue(props, inputChange, onError),
        });

      default:
        return columnHelper.accessor(key, {
          header: headers[key],
          cell: (props) => <div>{props.getValue()}</div>,
        });
    }
  });
};

export default buildAdjustmentColumns;
