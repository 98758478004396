import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import {useQuery} from "@apollo/client";
import {LOCATION_YIELD} from "graphql/config-center/LocationYield.js";
import {UpdateDocAPI} from "graphql/es-co-processing/mass-balance/updateInboundDetails";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import {uniqWith, isEqual} from "lodash";

const OutboundDetail = ({
  data,
  rowId,
  subRowId,
  onSelectionChange = () => {},
}) => {
  const [currentIndex, setCurrentIndex] = useState(subRowId);
  const {outboundRecords} = data;
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const outboundRecord = useMemo(() => {
    const obRec = {...outboundRecords[currentIndex]};
    let calculationSteps = obRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      obRec.calculationSteps = {};
      return obRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    obRec.calculationSteps =
      calculationSteps[obRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return obRec;
  }, [currentIndex, outboundRecords]);

  const {data: yieldDataRes} = useQuery(LOCATION_YIELD);

  const yieldData =
    yieldDataRes?.bioLcCoproConfigCenterLocationYieldDetails?.Records?.filter(
      (item) =>
        item?.location_code ===
          data?.inboundRecord?.mbLocationCode.toString() &&
        item?.material_code === data?.inboundRecord?.materialCode,
    ).sort();

  const yieldDataRatioAndTypeOnly = yieldData?.map((data) => {
    const yiledRatioType = {
      yield_ratio: data.yield_ratio,
      yield_type: data.yield_type,
    };
    return yiledRatioType;
  });

  const yiledDataFilteredListForDropdown = uniqWith(
    yieldDataRatioAndTypeOnly,
    isEqual,
  );

  const processingUnitData = yieldData?.map((data) => {
    const processingUnits = data.processing_unit_code;
    return processingUnits;
  });

  const processingUnitsFilteredListForDropdown = uniqWith(
    processingUnitData,
    isEqual,
  );

  const inboundRecordData = useMemo(() => {
    const {inboundRecord} = data;
    const inRec = {...inboundRecord};
    let calculationSteps = inRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      inRec.calculationSteps = {};
      return inRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    inRec.calculationSteps =
      calculationSteps[inRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return inRec;
  }, [data]);

  const [appliedYieldRatio, setAppliedYieldRatio] = useState(
    inboundRecordData.appliedYieldRatio,
  );

  const [appliedProcessingUnit, setAppliedProcessingUnit] = useState(
    inboundRecordData.appliedProcessingUnit,
  );

  const handleYieldRatioChange = async (yieldRatio, yieldType) => {
    if (yieldRatio !== "") {
      setAppliedYieldRatio(yieldRatio);
      const yieldOptionSelected = yieldType?.split("-");
      const {data: response} = await UpdateDocAPI({
        event: {
          inboundEuQtyId: inboundRecordData.inboundEuQtyId,
          mbLocationCode: inboundRecordData.mbLocationCode,
          qtyProcessedIn: inboundRecordData.qtyProcessedIn,
          appliedYieldRatio: yieldRatio,
          appliedYieldType:
            yieldOptionSelected[0]?.replace("Yield", "")?.trim() ?? "",
          appliedProcessingUnit,
        },
      });
      if (response?.bioLcCoproEuMassBalanceCustomYield?.statusCode !== 200) {
        toast.error(response?.bioLcCoproEuMassBalanceCustomYield?.message);
      }
    }
  };

  const handleProcessingUnitChange = async (processingUnit) => {
    if (processingUnit !== "") {
      setAppliedProcessingUnit(processingUnit);
      const {data: response} = await UpdateDocAPI({
        event: {
          appliedProcessingUnit: processingUnit,
          inboundEuQtyId: inboundRecordData.inboundEuQtyId,
          mbLocationCode: inboundRecordData.mbLocationCode,
          qtyProcessedIn: inboundRecordData.qtyProcessedIn,
          appliedYieldRatio: inboundRecordData.appliedYieldRatio,
          appliedYieldType: inboundRecordData.appliedYieldType,
        },
      });
      if (response?.bioLcCoproEuMassBalanceCustomYield?.statusCode !== 200) {
        toast.error(response?.bioLcCoproEuMassBalanceCustomYield?.message);
      }
    }
  };

  function numberFormatter(value) {
    if (Number.isInteger(value) && value !== 0) {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    }
    if (value === 0 || value === "0.0") {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 0);
    }

    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  function yieldThreeDecimalConversion(value) {
    if (!value) return null;

    const number = value + "00";
    return number;
  }

  return (
    <div className="flex flex-col h-96 w-full gap-4 items-stretch">
      <div className="flex flex-row justify-between items-center">
        <div className="text-uppercase text-xs fw-bold">
          {currentIndex + 1} of {outboundRecords.length}
        </div>
        <div className="flex flex-row justify-end gap-2 items-center">
          <Button
            color="link"
            size="sm"
            className="text-default fw-bold !pr-0"
            onClick={() => {
              setCurrentIndex(currentIndex - 1);
              onSelectionChange(`${rowId}_${currentIndex - 1}`);
            }}
            disabled={currentIndex === 0}
          >
            PREV
          </Button>
          <Button
            color="link"
            size="sm"
            className="text-default fw-bold !pr-0"
            onClick={() => {
              setCurrentIndex(currentIndex + 1);
              onSelectionChange(`${rowId}_${currentIndex + 1}`);
            }}
            disabled={currentIndex === outboundRecords.length - 1}
          >
            NEXT
          </Button>
        </div>
      </div>
      <div className="flex flex-row justify-between gap-2">
        <FormGroup>
          <Label for="ghgEecOut" className="fw-bold">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEecOut"
            data-test="ghgEecOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEec)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgElOut" className="fw-bold">
            El
          </Label>
          <Input
            type="text"
            id="ghgElOut"
            data-test="ghgElOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEl)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEpOut" className="fw-bold">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEpOut"
            data-test="ghgEpOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEp)}
          />

          <UncontrolledTooltip placement="bottom" target="ghgEpOut">
            {outboundRecord.calculationSteps?.ghg_ep ?? ""}
          </UncontrolledTooltip>
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEtdOut" className="fw-bold">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtdOut"
            data-test="ghgEtdOut"
            readOnly
            value={
              outboundRecord.ghgEtd === "DDV" || outboundRecord.ghgEtd === "TDV"
                ? outboundRecord.ghgEtd
                : numberFormatter(outboundRecord.ghgEtd)
            }
          />
          <UncontrolledTooltip placement="bottom" target="ghgEtdOut">
            {outboundRecord.calculationSteps?.ghg_etd ?? ""}
          </UncontrolledTooltip>
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEuOut" className="fw-bold">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEuOut"
            data-test="ghgEuOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEu)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEscaOut" className="fw-bold">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEscaOut"
            data-test="ghgEscaOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEsca)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccsOut" className="fw-bold">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccsOut"
            data-test="ghgEccsOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEccs)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccrOut" className="fw-bold">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccrOut"
            data-test="ghgEccrOut"
            readOnly
            value={numberFormatter(outboundRecord.ghgEccr)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">=</div>
        <FormGroup>
          <Label for="ghgTotalOut" className="fw-bold">
            Total
          </Label>
          <Input
            type="text"
            id="ghgTotalOut"
            className="min-w-[100px]"
            data-test="ghgTotalOut"
            readOnly
            value={
              outboundRecord.ghgTotal === "DDV" ||
              outboundRecord.ghgTotal === "TDV"
                ? outboundRecord.ghgTotal
                : numberFormatter(outboundRecord.ghgTotal)
            }
          />
          <FormText>
            {outboundRecord.unitToBeDisplayed ?? "kg CO2eq/dry-ton"}
          </FormText>
        </FormGroup>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col">
          {inboundRecordData?.mbLocationCode === 3 ? (
            <>
              <span>Processing unit</span>
              <Input
                type="select"
                id="processingUnit"
                data-test="processingUnit"
                onChange={(e) => handleProcessingUnitChange(e.target.value)}
                value={appliedProcessingUnit}
                disabled={!inboundRecordData.yieldEnableFlag}
              >
                {processingUnitsFilteredListForDropdown?.map(
                  (processingUnit) => (
                    <option key={processingUnit} value={processingUnit}>
                      {processingUnit}
                    </option>
                  ),
                )}
              </Input>
            </>
          ) : (
            <>
              <span className="border-b">Processing unit</span>
              <span className="py-1 fw-bold ">
                {processingUnitsFilteredListForDropdown ?? "-"}
              </span>
            </>
          )}
        </div>

        <div className="flex flex-col">
          {inboundRecordData?.mbLocationCode === 3 ? (
            <>
              <span className="">Yield %</span>
              <Input
                type="select"
                id="yieldRatio"
                data-test="yieldRatio"
                onChange={(e) =>
                  handleYieldRatioChange(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text,
                  )
                }
                value={appliedYieldRatio}
                disabled={!inboundRecordData.yieldEnableFlag}
              >
                {yiledDataFilteredListForDropdown?.map((yieldDataItem) => (
                  <option
                    key={yieldDataItem.yield_ratio}
                    value={yieldDataItem.yield_ratio}
                  >
                    {`${
                      yieldDataItem.yield_type
                    } Yield - ${yieldThreeDecimalConversion(
                      yieldDataItem.yield_ratio,
                    )}`}
                  </option>
                ))}
              </Input>
            </>
          ) : (
            <>
              {" "}
              <span className="border-b">Yield %</span>
              <span className="py-1 fw-bold truncate ...">
                {yieldThreeDecimalConversion(
                  inboundRecordData.appliedYieldRatio,
                ) ?? "-"}
              </span>
            </>
          )}
        </div>
        <div className="flex flex-col">
          <span className="border-b">Date of issuance</span>
          <span className="py-1 fw-bold ">
            {outboundRecord.issueDate != null
              ? moment(new Date(outboundRecord.issueDate)).format(dateFormat)
              : "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Name of recipient</span>
          <span className="py-1 fw-bold ">
            {outboundRecord.recipientName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Address of recipient</span>
          <span className="py-1 fw-bold ">
            {outboundRecord.recipientAddress ?? "-"}
          </span>
        </div>

        <div className="flex flex-col">
          <span className="border-b">Transport system</span>
          <span className="py-1 fw-bold ">{outboundRecord.motName ?? "-"}</span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Vessel name</span>
          <span className="py-1 fw-bold ">{outboundRecord.motOut ?? "-"}</span>
        </div>
      </div>
    </div>
  );
};

OutboundDetail.propTypes = {
  data: PropTypes.object,
  rowId: PropTypes.string,
  subRowId: PropTypes.number,
  onSelectionChange: PropTypes.func,
};

export default OutboundDetail;
