import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {TRANSPORTATION_EMISSIONS} from "graphql/config-center/TransportationEmissions.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {TRANSPORTATION_EMISSIONS_COLUMNS} from "../components/Table/Columns.js";

const TransportationEmissions = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => TRANSPORTATION_EMISSIONS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(TRANSPORTATION_EMISSIONS);

  return (
    <RTable
      columns={columns}
      data={
        loading
          ? []
          : data?.bioLcCoproConfigCenterTransportationEmission.Records
      }
      tableName="Transportation emissions"
    />
  );
};
export default TransportationEmissions;
