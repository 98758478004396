import {Kebab32} from "@bphxd/ds-core-react/lib/icons";
import Spinner from "modules/common/Spinner";
import PropTypes from "prop-types";

const TabHeader = ({
  Heading,
  subHeading,
  loading = false,
  onMore,
  showMore,
}) => {
  return (
    <div className="flex flex-row justify-between w-full justify-content-center">
      <div className="flex flex-col py-2">
        <label
          className=" tab-header-sub-text"
          data-test={`tab-compliance-${Heading}`}
        >
          {Heading}
        </label>
        {loading ? (
          <Spinner />
        ) : (
          <label className="tab-header">{subHeading}</label>
        )}
      </div>
      {showMore === true && (
        <button
          data-test="header-more-button"
          className="flex flex-row  items-center justify-end w-10 border-none"
          type="button"
          onClick={() => onMore()}
        >
          <Kebab32 style={{color: "gray"}} />
        </button>
      )}
    </div>
  );
};

TabHeader.propTypes = {
  Heading: PropTypes.string,
  subHeading: PropTypes.string,
  loading: PropTypes.bool,
  onMore: PropTypes.func,
  showMore: PropTypes.bool,
};

export default TabHeader;
