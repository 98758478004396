import React from "react";

import {useQuery} from "@apollo/client";
import {CERTIFICATE_SCHEME_VARIATION_GET_QUERY} from "graphql/GlobalConfigCenter/CertificateSchemeVariation";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {CERTIFICATE_SCHEME_VARIATION_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {CERTIFICATE_SCHEME_VARIATION_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const CertificateSchemeVariationConfigPage = () => {
  const {data, loading} = useQuery(CERTIFICATE_SCHEME_VARIATION_GET_QUERY);

  return (
    <Layout
      title={CERTIFICATE_SCHEME_VARIATION_CONFIG_PAGE.title}
      description={CERTIFICATE_SCHEME_VARIATION_CONFIG_PAGE.description}
      tableColumns={CERTIFICATE_SCHEME_VARIATION_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetCertificationSchemeVariationData
              .certificationSchemeVariationData
      }
    />
  );
};

export default CertificateSchemeVariationConfigPage;
