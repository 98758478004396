import {gql} from "@apollo/client";

// Fetch query for Material master table on Config center(/configuration-center/material-master)
export const CERTIFICATE_SCHEME_GET_QUERY = gql`
  query bioLcGetCertificationSchemeData {
    bioLcGetCertificationSchemeData(event: {}) {
      certificationSchemeData {
        certificationSchemeId
        certificationSchemeCode
        certificationSchemeName
        euCommissionRecognizedFlag
        countryId
        countryName
        divisionName
        siteReferenceId
        divisionId
      }
    }
  }
`;

export default {CERTIFICATE_SCHEME_GET_QUERY};
