export const LABEL_MONTH = "Month";
export const LABEL_NO_OF_PLANTS = "No. of Plants";
export const LABEL_NO_OF_RECONCILED_PLANTS = "Reconciled Plants";
export const LABEL_RECONCILIATION_STATUS = "Reconciliation Status";
export const LABEL_CEEMAS_VOLUME = "Ceemas Volume";
export const LABEL_EXCISE_VOLUME = "Excise Volume";
export const LABEL_VOLUME_DIFFERENCE = "Volume Difference";
export const LABEL_FEM_VOLUME = "FEM Volume";
export const LABEL_INTRANSIT_VOLUME = "Intransit Volume";
export const LABEL_TRANSPORT_GAINLOSS = "Transport Gain/Loss";
export const LABEL_CHECK = "Check";
export const LABEL_STATUS = "Status";
export const LABEL_PLANT = "Plant";
export const LABEL_NO_OF_EDGROUPS = "No. of ED Groups";
export const LABEL_NO_OF_RECONCILED_EDGROUPS = "Reconciled ED Groups";
export const LABEL_EDGROUP = "ED Group";
export const LABEL_NO_OF_BALANCEGROUPS = "No. of BalanceGroups";
export const LABEL_NO_OF_RECONCILED_BALANCEGROUPS = "Reconciled BalanceGroups";
export const LABEL_BALANCE_GROUP = "Balance Group";
export const LABEL_NO_OF_MATERIALS = "No. of Materials";
export const LABEL_NO_OF_RECONCILED_MATERIALS = "Reconciled Materials";
export const LABEL_MATERIAL = "Material";
export const LABEL_NO_OF_DAYS = "No. of Days";
export const LABEL_NO_OF_RECONCILED_DAYS = "Reconciled Days";
export const LABEL_DATE = "Date";
export const LABEL_OUTBOUND_DELIVERY = "Outbound Delivery";
export const LABEL_LOAD_MAT_DOCUMENT = "Load Material Document";
export const LABEL_LOAD_MAT_DOC_DATE = "Load Doc Date";
export const LABEL_DISCHARGE_MAT_DOC = "Discharge Material Document";
export const LABEL_DISCHARGE_DOC_DATE = "Discharge Doc Date";

// MOT CEEMAS TABS
export const LABEL_TAB_CEEMAS_MOT = "CEEMAS MOT Validation";
export const LABEL_TAB_MANUAL_ADJUSTMENT = "MOT Manual Adjustment";
export const LABEL_TAB_TAX_PLANT_REGISTRY = "Tax Plant Registry";
export const LABEL_TAB_TAX_NETS_CO2_COSTS = "NETS CO2 Costs";
export const LABEL_TAB_COPRO_SPAIN = "Co-processing - Spain";

export default LABEL_NO_OF_DAYS;
