import PropTypes from "prop-types";
import {Datepicker as BPDatepicker} from "@bphxd/ds-core-react";
import {Controller, useFormContext} from "react-hook-form";
import {useUserSettings} from "providers/userSettings";
import {FormFeedback} from "reactstrap";

function convertDateFormat(dateFormat) {
  const dateFormatMap = {
    "MM/DD/YYYY": "m/d/Y",
    "DD/MM/YYYY": "d/m/Y",
    "YYYY/MM/DD": "Y/m/d",
  };
  return dateFormatMap[dateFormat] ?? dateFormat;
}
const Datepicker = ({
  control,
  name,
  error,
  rules,
  placeholder,
  disabled = false,
  readOnly = false,
  format = "",
}) => {
  const {
    formState: {errors},
    trigger,
  } = useFormContext();
  const {userSettings = {dateFormat: "m/d/Y"}} = useUserSettings();
  const dateFormat = format || convertDateFormat(userSettings.dateFormat);
  return (
    <Controller
      rules={rules}
      render={({field: {ref, onChange, value, name}}) => {
        return (
          <>
            {/** Temporary hack to scroll to datepicker */}
            <input type="text" ref={ref} style={{width: "0px"}} />
            <BPDatepicker
              placeholder={placeholder}
              options={{
                allowInput: false,
                mode: "single",
                defaultDate: value,
                dateFormat,
                altFormat: dateFormat,
                onChange: (selectedDates) => {
                  const newDate = selectedDates[0];
                  if (newDate != null) {
                    const today = new Date();
                    newDate.setHours(today.getHours());
                    newDate.setMinutes(today.getMinutes());
                    newDate.setSeconds(today.getSeconds());
                    newDate.setMilliseconds(today.getMilliseconds());
                  }
                  onChange(newDate);
                  trigger(name);
                },
              }}
              invalid={!!error && !disabled}
              data-test={name}
              disabled={disabled}
              readOnly={readOnly}
            />
            {error && !disabled && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

Datepicker.propTypes = {
  placeholder: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  format: PropTypes.string,
};
export default Datepicker;
