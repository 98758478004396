import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import getInputMap from "modules/common/getInputMap";
import {useUserSettings} from "providers/userSettings";
import React, {useMemo} from "react";

const CherrypointEditForm = ({data}) => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const {
    formState: {errors},
  } = useFormContext();
  const inputMap = useMemo(() => getInputMap(data, errors), [data, errors]);

  return (
    <div
      data-test="cherrypointEdit-form"
      className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4"
    >
      {inputMap.input("batchId", "Batch ID", {maxLength: 14})}
      {inputMap.input("itemDescription", "Item Description", {
        disabled: true,
        maxLength: 250,
      })}
      {inputMap.inputNumeric("deliverySlipBOL", "Delivery Slip/BOL#", {
        disabled: true,
      })}
      {inputMap.inputDate("shipDate", "Actual Ship Date", {
        format: dateFormat,
        disabled: true,
      })}
      {inputMap.input("weightAnimalFatsMT", "Weight - Animal Fats (MT)", {
        disabled: true,
        maxLength: 14,
      })}
      {inputMap.input(
        "weightUsedCookingOilMT",
        "Weight - Used Cooking Oil (MT)",
        {disabled: true, maxLength: 14},
      )}
      {inputMap.input("weightSoybeanOilMT", "Weight - Soybean Oil (MT)", {
        disabled: true,
        maxLength: 14,
      })}
      {inputMap.input("weightCanolaOilMT", "Weight - Canola Oil (MT)", {
        disabled: true,
        maxLength: 14,
      })}
      {inputMap.input(
        "weightDistillersCornOilMT",
        "Weight - Distillers Corn Oil (MT)",
        {disabled: true, maxLength: 14},
      )}
      {inputMap.input("weightOtherOilMT", "Weight - Other (MT)", {
        disabled: true,
        maxLength: 14,
      })}
      {inputMap.inputNumeric("begBalance", "Beginning Balance", {
        disabled: true,
      })}
      {inputMap.input("units", "Units", {disabled: true, maxLength: 10})}
      {inputMap.input("owbBatchID", "OWB Batch ID", {maxLength: 14})}
      {inputMap.input("invoiceNo", "Invoice #", {
        disabled: true,
        maxLength: 14,
      })}
      {inputMap.inputNumeric("shipTime", "Ship Time", {
        disabled: true,
      })}
      {inputMap.input("wcrSalesContract", "WCR Sales Contract", {
        maxLength: 14,
      })}
      {inputMap.inputNumeric("tankId", "Tank", {
        disabled: true,
      })}
      {inputMap.inputNumeric("density", "Density", {
        disabled: true,
      })}
      {inputMap.inputNumeric("bblToMt", "BBL to MT", {
        disabled: true,
      })}
      {inputMap.inputNumeric("bbl", "BBLS", {
        disabled: true,
      })}
      {inputMap.input("dealNumberP", "WCR-BPPNA (P)", {maxLength: 14})}
      {inputMap.input("dealNumberS", "BPPNA - BPWCP (S)", {maxLength: 14})}
      {inputMap.inputTextArea("comments", "Add comments", {maxLength: 250})}
    </div>
  );
};

CherrypointEditForm.propTypes = {
  data: PropTypes.object,
};

export default CherrypointEditForm;
