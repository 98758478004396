import React from "react";
import PropTypes from "prop-types";
import {
  useFormContext,
  Controller,
  FormProvider,
  useForm,
} from "react-hook-form";
import {
  FormText,
  FormGroup,
  FormFeedback,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {dtnShipmentKeys} from "modules/co-processing/constants/shipments";
import {TruckRackFields, BackToUnitFields} from "./formConfigs";
import {calculateMaxVolume, renderInputs} from "./formUtils";

const Field = ({fieldName}) => {
  const {
    control,
    formState: {errors},
    watch,
  } = useFormContext();
  const fieldConfig = TruckRackFields?.[fieldName] ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const error = errors[key];
  const startTime = watch(dtnShipmentKeys.SHIPMENT_START_DATE);

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
    >
      <Label
        className={`text-nowrap ${
          fieldConfig?.isOptional ? "label-optional" : ""
        }`}
        htmlFor={key}
      >
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          ...(fieldConfig.type !== "switch" && {
            required: !fieldConfig.isOptional,
          }),
        }}
        render={({field}) =>
          renderInput({field, error, startTime, ...fieldConfig})
        }
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || "Expected information missing"}
        </FormFeedback>
      )}

      {fieldConfig?.info && <FormText>{fieldConfig?.info}</FormText>}
    </FormGroup>
  );
};

const ShipmentIdField = ({validate, fieldError}) => {
  const {control, watch} = useFormContext();
  const shipmentId = watch(dtnShipmentKeys.SHIPMENT_ID);
  const fieldConfig = TruckRackFields?.SHIPMENT_ID ?? {};
  const {key} = fieldConfig;

  if (!fieldConfig || !key) return null;

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup
      className={`createShipment__field ${fieldConfig?.width || "col-md-6"}`}
      onBlur={() => {
        if (validate) {
          validate({variables: {shipmentId}});
        }
      }}
    >
      <Label className="text-nowrap" htmlFor={key}>
        {fieldConfig.label}
      </Label>
      <Controller
        name={key}
        control={control}
        rules={{
          required: true,
          validate: () => {
            return !fieldError.message;
          },
        }}
        render={({field}) =>
          renderInput({field, error: fieldError.message, ...fieldConfig})
        }
      />

      {fieldError && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {fieldError.type === "custom"
            ? fieldError.message
            : fieldError.type === "required"
            ? "Expected information missing"
            : ""}
        </FormFeedback>
      )}

      {fieldConfig?.info && <FormText>{fieldConfig?.info}</FormText>}
    </FormGroup>
  );
};

const BackUnitField = ({fieldName}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const fieldConfig = BackToUnitFields?.[fieldName] ?? {};

  if (!fieldConfig?.key) return null;

  const {key, label, width, type, isOptional} = fieldConfig;
  const error = errors?.[key]?.[type] || errors?.[key];

  const renderInput = renderInputs[fieldConfig?.type] || renderInputs.input;

  return (
    <FormGroup className={`createShipment__field ${width || "col-md-6"}`}>
      <Label
        className={`text-nowrap ${isOptional ? "label-optional" : ""}`}
        htmlFor={key}
      >
        {label}
      </Label>
      <Controller
        name={key === dtnShipmentKeys.SHIPMENT_DATE ? `${key}.${type}` : key}
        control={control}
        rules={fieldConfig?.validation}
        render={({field}) => renderInput({field, error, ...fieldConfig})}
      />

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || `${label} is missing`}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const EstVolumeDisplay = ({startTime, endTime}) => {
  const {register, setValue} = useFormContext();
  const estVolumeKey = dtnShipmentKeys.ESTIMATED_VOLUME;
  const volume = calculateMaxVolume(startTime, endTime) ?? 0;

  setValue(estVolumeKey, volume, {shouldDirty: true});

  return (
    <FormGroup className="createShipment__field col-md-6">
      <Label htmlFor={estVolumeKey}>Estimated shipment max volume</Label>
      <span
        className="label-info pl-1 text-[#002bbc]"
        href="#"
        id={estVolumeKey}
      >
        What is this?
      </span>
      <UncontrolledTooltip
        popperClassName="tooltip-light"
        placement="top"
        target={estVolumeKey}
      >
        This number is calculated by the amount of time X 1,500 bbl/hour
      </UncontrolledTooltip>

      <div className="input-group bg-transparent">
        <Input {...register(estVolumeKey)} type="hidden" />
        <span className="display-5 fs-5 form-text">{`${volume?.toLocaleString()} bbl`}</span>
      </div>
    </FormGroup>
  );
};

const ShipmentForm = ({onSubmit, children, defaultValues}) => {
  const {handleSubmit, ...methods} = useForm({
    defaultValues,
    mode: "onSubmit",
  });

  return (
    <FormProvider {...methods}>
      <form
        className="createShipment__form"
        id="create_shipment_form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

const fieldPropTypes = {fieldName: PropTypes.string};

Field.propTypes = fieldPropTypes;
BackUnitField.propTypes = fieldPropTypes.fieldName;
ShipmentIdField.propTypes = {
  validate: PropTypes.func,
  fieldError: PropTypes.object,
};
ShipmentForm.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
EstVolumeDisplay.propTypes = {
  startTime: PropTypes.string,
  endTime: PropTypes.string,
};

ShipmentForm.Field = Field;
ShipmentForm.BackUnitField = BackUnitField;
ShipmentForm.ShipmentIdField = ShipmentIdField;
ShipmentForm.EstVolumeDisplayField = EstVolumeDisplay;

export default ShipmentForm;
