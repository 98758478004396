import React from "react";
import PropTypes from "prop-types";
import {Input} from "antd";
import "./index.scss";

const {TextArea} = Input;

const Textarea = React.forwardRef(({className = "", ...props}, ref) => (
  <TextArea
    ref={ref}
    className={["bp-textarea", className].join(" ")}
    rows={4}
    {...props}
  />
));

Textarea.propTypes = {
  className: PropTypes.string,
};

export default Textarea;
