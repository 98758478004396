import {gql} from "@apollo/client";

export const MASS_BALANCE_TOP_FILTERS = gql`
  query bioLcGetMBFilterOptionsApi(
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcGetMBFilterOptionsApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      mbPeriodInstances {
        mbBalanceGroup {
          mbBalanceGroupId
          mbBalanceGroupName
        }
        mbBalanceType {
          mbBalanceTypeCode
          mbBalanceTypeId
        }
        mbLocationGroup {
          mbLocationGroupId
          mbLocationGroupName
        }
        mbPeriod {
          mbPeriodId
          mbPeriodName
          mbPeriodToDate
        }
      }
      statusCode
      error
    }
  }
`;

export const GET_CUSTOMERS_LIST = gql`
  query bioLcGetMassBalanceCustomerDetails(
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcGetMassBalanceCustomerDetails(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      statusCode
      error
      customerDetails {
        businessPartnerCode
        businessPartnerId
        businessPartnerLongName
        businessPartnerShortName
        divisionId
        countryCode
        businessPartnerType
        siteReferenceId
        validFrom
        validTo
      }
    }
  }
`;

export default MASS_BALANCE_TOP_FILTERS;
