import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

const TaskComponent = ({item}) => {
  return (
    <div className="flex mr-4" key={item.id}>
      {item.icon}
      <Link to={item.link} className="link-border text-default">
        <span className="fw-light">{item.text}</span>
      </Link>
    </div>
  );
};

TaskComponent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default TaskComponent;
