import {ArrowLeftOutlined} from "@ant-design/icons";
import FormField from "modules/common/FormField";
import Modal from "modules/common/Modal";
import {useState} from "react";
import PropTypes from "prop-types";
import Menu from "modules/common/Menu";
import Textarea from "modules/common/Textarea";
import {Controller, useFormContext} from "react-hook-form";
import Form from "modules/common/Form";
import {deleteFormSchema} from "modules/gy-enablement/schemas/MOTManualAdjustment";
import Notification from "modules/common/Notification";
import useNotification from "modules/common/Notification/useNotification";
import {Spin} from "antd";
import Button from "DLS/Button";
import Heading from "DLS/Typography/Heading";
import PreviewTable from "./PreviewTable";

const DeleteFormContent = () => {
  const {
    formState: {errors},
  } = useFormContext();

  return (
    <FormField
      className="mt-4"
      label="Add Comments"
      errorMessage={errors.reason?.message}
    >
      <Controller
        name="reason"
        render={({field}) => (
          <Textarea
            {...field}
            placeholder="Add Comments"
            data-test="manual-adjustment-delete-input-reason"
            maxLength={250}
            showCount
          />
        )}
      />
    </FormField>
  );
};

const DeleteItem = ({
  onSubmit,
  onModalClose = () => {
    /* do nothing */
  },
  columns,
  data,
}) => {
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [timerId, setTimerId] = useState();
  const [triggerNotification, clearNotification, notificationProps] =
    useNotification();

  const handleSubmit = async (values) => {
    setSubmitAllowed(false);
    setSubmitting(true);
    await onSubmit(values);
    setHasBeenSubmitted(true);
    setSubmitting(false);
    triggerNotification("success", "Deleted successfully.");
    // Close after 10secs
    const id = setTimeout(() => setDeleteModalVisible(false), 10000);
    setTimerId(id);
  };

  return (
    <>
      {/* MODAL */}
      <Modal
        onCancel={() => setDeleteModalVisible((prevValue) => !prevValue)}
        visible={isDeleteModalVisible}
        title="Delete - Following Items"
        afterClose={async () => {
          // Due modal is not unmounted when changing visibility: restart UI
          // We check for isSubmitAllowed to 'suppose' that mutation have happened
          // We will call onCloseAfterSubmit
          onModalClose(hasBeenSubmitted);
          clearNotification();
          clearTimeout(timerId);
          setTimerId(null);
          setSubmitAllowed(true);
          // }
        }}
        footer={
          <>
            <div className="flex flex-col w-full gap-2 pt-8 border-t sm:flex-row">
              <Button
                label="Back"
                icon={<ArrowLeftOutlined />}
                type="secondary"
                data-test="edition-modal-back-btn"
                onClick={() => setDeleteModalVisible((prevValue) => !prevValue)}
              />
              <Button
                label={isSubmitting ? <Spin size="small" /> : "Submit"}
                type="primary"
                htmlType="submit"
                data-test="delete-modal-confirm-btn"
                form="delete-form"
                disabled={!isSubmitAllowed}
              />
            </div>

            {/* SUBMIT NOTIFICATION */}
            <Notification
              {...notificationProps}
              className="mt-8"
              onClose={() => clearNotification()}
            />
          </>
        }
        destroyOnClose
        closable
      >
        <PreviewTable
          tableTitle="Delete Items"
          columns={columns}
          data={[data].flat()} // Force array casting
        />
        <div className="flex flex-col mb-10 mt-14">
          <Heading level={5}>Justification Reason</Heading>
          <p className="mt-1 text-indigo-500">
            Please provide the justification reason in the comment box
          </p>
        </div>
        <Form
          schema={deleteFormSchema}
          onSubmit={handleSubmit}
          id="delete-form"
        >
          <DeleteFormContent />
        </Form>
      </Modal>

      {/* ACTION */}
      <Menu.Item
        key="table-action-delete"
        data-test="table-action-delete"
        onClick={() => setDeleteModalVisible(true)}
      >
        Delete
      </Menu.Item>
    </>
  );
};

DeleteItem.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onModalClose: PropTypes.func,
};

export default DeleteItem;
