import PropTypes from "prop-types";
import {InputGroup, Input} from "reactstrap";
import {Search24} from "@bphxd/ds-core-react/lib/icons";
import useDebouncedCallback from "../helpers/useDebounceCallback";

const TableSearch = ({refetch, variables}) => {
  const [inputValue, handleInputChange] = useDebouncedCallback(
    variables.search_text,
    (newValue) => {
      refetch({
        ...variables,
        search_text: newValue.value,
      });
    },
    800,
  );

  return (
    <div className="d-inline-block">
      <InputGroup className="input-group-merge">
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search"
        />
        <div className="input-group-append">
          <div className="input-group-text">
            <Search24 />
          </div>
        </div>
      </InputGroup>
    </div>
  );
};

TableSearch.propTypes = {
  refetch: PropTypes.func.isRequired,
  variables: PropTypes.object.isRequired,
};

export default TableSearch;
