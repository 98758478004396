import {capitalize} from "lodash";
import {displayTime, displayDate} from "./dateHelper";

export const shipmentLabelFormatter = (label) => {
  const newLabel = label.replace(/_/g, " ");
  return capitalize(newLabel);
};

const valueFormatter = (data, dataType) => {
  if (data == null) {
    return "";
  }
  switch (dataType) {
    case "date":
      return displayDate(data, true);
    case "dateTime":
      return displayDate(data);
    case "time":
      return displayTime(data);
    case "number":
      return data.toLocaleString();
    case "boolean":
      return data ? "Yes" : "No";
    case "formattedText":
      return shipmentLabelFormatter(data);

    default:
      return data;
  }
};

export default valueFormatter;
