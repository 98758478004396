import {Modal, ModalHeader, ModalBody} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import "./index.scss";

export const FullScreenModal = ({
  children,
  isOpen,
  title = "",
  toggle = () => {},
  wrapClassName = "",
  ...props
}) => (
  <Modal
    backdrop={false}
    fullscreen
    isOpen={isOpen}
    wrapClassName={["fullscreen-modal-wrapper", wrapClassName || ""].join(" ")}
    {...props}
  >
    <ModalHeader close={<Close onClick={toggle} large />}>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
  </Modal>
);

FullScreenModal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  wrapClassName: PropTypes.string,
};

export default FullScreenModal;
