import PropTypes from "prop-types";
import ApiXLSXItem from "./ApiXLSXItem";
import LocalXLSXItem from "./LocalXLSXItem";

const DownloadXLSItem = ({
  data,
  columns,
  filename,
  clearSelection,
  onDownload,
}) => {
  const component = onDownload ? (
    <ApiXLSXItem {...{data, onDownload}} />
  ) : (
    <LocalXLSXItem {...{data, columns, filename, clearSelection}} />
  );

  return component;
};

DownloadXLSItem.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  filename: PropTypes.string,
  clearSelection: PropTypes.func,
  onDownload: PropTypes.func,
};

export default DownloadXLSItem;
