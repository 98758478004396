import PropTypes from "prop-types";
import {Breadcrumb} from "antd";
import "./index.scss";

const BioBreadcrumb = ({breadcrumbitems}) => {
  return (
    <div className="flex items-center justify-between px-1 mt-8 font-bold text-indigo-500 border-indigo-500 hover:opacity-50">
      <Breadcrumb separator="/" data-test="breadcrumb">
        {breadcrumbitems?.map((item, index) => (
          <Breadcrumb.Item
            key={"breadcrumbitem" + index}
            data-test={"breadcrumbitem" + index}
            href={item.link}
          >
            {item.linkname}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

BioBreadcrumb.propTypes = {
  breadcrumbitems: PropTypes.array.isRequired,
};

export default BioBreadcrumb;
