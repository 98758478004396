import PropTypes from "prop-types";
import "./index.scss";

const FormField = ({
  children,
  errorMessage,
  className = "",
  label,
  ...props
}) => (
  <div
    className={[
      "bp-formfield",
      errorMessage ? "--has-error" : "",
      className,
    ].join(" ")}
    {...props}
  >
    {label ? <label title={label}>{label}</label> : null}
    {children}
    {errorMessage ? (
      <div role="alert" className="py-1 text-xs text-red-500">
        {errorMessage}
      </div>
    ) : null}
  </div>
);

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
};
export default FormField;
