import {useQuery} from "@apollo/client";
import {Download24} from "@bphxd/ds-core-react/lib/icons";
import Papa from "papaparse";
import React, {useState} from "react";
import * as XLSX from "xlsx";

import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

import PropTypes from "prop-types";

import {RULE_VERSION_TEST} from "graphql/compliance-rules-engine/sandboxTestRuleVersion";

import {useParams} from "react-router-dom";
import {setCountryModuleDetails} from "utils/helpers/getAppSetting.js";
import CustomizeTable from "./CustomizeTable";
import ResultsTable from "./ResultsTable";

import "./styles.scss";

export const exportExcelData = (data, exportData) => {
  // Format data using the provided headerMapping
  const ws = XLSX.utils.json_to_sheet(exportData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Results");
  XLSX.writeFile(wb, "results_export.xlsx");
};

const SandboxExecutionResults = (props) => {
  const [rowSelection, setRowSelection] = useState({});
  const {country: countryName} = useParams();

  setCountryModuleDetails(countryName, "complianceRulesEngine");

  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [columns, setColumns] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(true);

  const {data, loading} = useQuery(RULE_VERSION_TEST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {
      ruleVersionId: props?.ruleVersionId,
    },
  });

  if (loading) {
    return (
      <div className="spinner-container">
        <div>
          <Spinner />
        </div>
        <div>Preparing your test results...</div>
      </div>
    );
  }
  if (
    !loading &&
    data &&
    data?.bioLcComplianceRulesEngineTestExecute?.statusCode !== 200
  ) {
    return (
      <div className="spinner-container">
        <div>Something went wrong, please check the version details...</div>
      </div>
    );
  }

  const downloadLink =
    data?.bioLcComplianceRulesEngineTestExecute?.executionResultDownloadLink;

  const schema = data?.bioLcComplianceRulesEngineTestExecute?.schema;

  if (sortedData?.length === 0)
    if (downloadLink) {
      Papa.parse(downloadLink, {
        download: true,
        complete: (results) => {
          const dataArray = [];
          const keys = results?.data[0];

          results?.data?.slice(1).forEach((line) => {
            if (line.length === 1 && line[0] === "") {
              return;
            }
            const d = {};
            line.forEach((value, i) => {
              d[keys[i]] = value;
            });
            dataArray.push(d);
          });

          setColumns(
            schema.map((i) => {
              const columnName = i.column;
              return {
                id: columnName,
                header: columnName,
                accessorKey: columnName,
                key: columnName,
              };
            }),
          );

          setSortedData(dataArray);
          setCsvLoading(false);
        },
      });
    }

  const handleExportSelected = (rowSelection) => {
    exportExcelData(rowSelection, sortedData);
  };

  if (csvLoading)
    return (
      <div className="spinner-container">
        <div>
          <Spinner />
        </div>
        <div>Preparing your test results...</div>
      </div>
    );

  return (
    <div className="flex flex-col justify-stretch w-full items-start">
      <div className="results-table-buttons flex flex-row justify-between w-full py-5">
        <div className="rule-engine-results-rule-name">{props.ruleName}</div>
        <div className="flex flex-none flex-row items-center">
          <div className="mr-2">
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div>
            <UncontrolledDropdown>
              <DropdownToggle color="standard-tertiary rounded-0" caret>
                Export
                <Download24 className="btn-icon-suffix" />
              </DropdownToggle>
              <DropdownMenu tag="ul">
                <li>
                  <DropdownItem
                    href="#"
                    onClick={async () => handleExportSelected(rowSelection)}
                    disabled={Object.keys(rowSelection)?.length === 0}
                  >{`Export selected (${
                    Object.keys(rowSelection)?.length
                  })`}</DropdownItem>
                </li>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </div>

      <div className="results-table-container border-secondary w-full pb-40 min-h-[500px]">
        <ResultsTable
          columns={columns}
          data={sortedData ?? []}
          className="results-table-compliance-rules-engine"
          highlightedRows={[]}
          loading={csvLoading}
          filtering={filtering?.trim()}
          setFiltering={setFiltering}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          type="Rules"
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          pageIndex={0}
        />
      </div>
    </div>
  );
};

SandboxExecutionResults.propTypes = {
  ruleName: PropTypes.string,
  ruleVersionId: PropTypes.string,
};

export default SandboxExecutionResults;
