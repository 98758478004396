import {
  CalculatorDetailed48,
  Edit48,
  Electric48,
  OilQuart48,
  SpreadsheetGeneric48,
  Swap48,
} from "@bphxd/ds-core-react/lib/icons";
import Labels from "./Labels";

export const blockList = [
  {
    id: "select_data",
    ruleBlockMasterId: "selectDataBlockRuleId",
    name: Labels.SELECT_DATA,
    blockType: "data",
    disabled: true,
    configuration: {table_name: undefined, predicate: undefined},
  },
  {
    id: "write_data",
    ruleBlockMasterId: "writeDataBlockRuleId",
    name: Labels.WRITE_DATA,
    blockType: "data",
    disabled: true,
    configuration: {table_name: undefined, result_column: undefined},
  },
  {
    id: "convert_units",
    ruleBlockMasterId: "convertUnitsBlockRuleId",
    name: Labels.CONVERT_UNITS,
    blockType: "conversion",
    disabled: false,
    configuration: {
      input_column: undefined,
      conversion_factor: undefined,
      conversion_factor_name: undefined,
      output_column: undefined,
    },
  },
  {
    id: "volume_to_energy",
    ruleBlockMasterId: "volumeToEnergyBlockRuleId",
    name: Labels.VOLUME_TO_ENERGY,
    blockType: "conversion",
    disabled: false,
    configuration: {
      input_column: undefined,
      prod_hrc_column: undefined,
      output_column: undefined,
    },
  },
  {
    id: "energy_to_actual_emission",
    ruleBlockMasterId: "energyToEmissionBlockRuleId",
    name: Labels.ENERGY_TO_EMISSION,
    blockType: "emissions",
    disabled: false,
    configuration: {
      input_column: undefined,
      prod_hrc_column: undefined,
      output_column: undefined,
    },
  },
  {
    id: "calculate",
    ruleBlockMasterId: "calculateBlockRuleId",
    name: Labels.CALCULATE,
    blockType: "calculation",
    disabled: false,
    configuration: {formula: undefined, output_column: undefined},
  },
];

export const blockIconList = {
  select_data: <SpreadsheetGeneric48 className="btn-icon-prefix" />,
  write_data: <Edit48 className="btn-icon-prefix" />,
  convert_units: <Swap48 className="btn-icon-prefix" />,
  volume_to_energy: <Electric48 className="btn-icon-prefix" />,
  energy_to_actual_emission: <OilQuart48 className="btn-icon-prefix" />,
  calculate: <CalculatorDetailed48 className="btn-icon-prefix" />,
};

export const blockTypeList = {
  calculation: Labels.CALCULATION,
  emissions: Labels.EMISSIONS,
  conversion: Labels.CONVERSION,
  data: Labels.DATA,
};

export default blockList;
