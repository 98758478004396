import DashboardLayout from "modules/common/DashboardLayout";
import tabsData from "./tabsData";

const MOTManualAdjustment = () => (
  <DashboardLayout pageTitle="Reporting" tabsData={tabsData}>
    <div className="flex items-center justify-center h-96">
      This feature will come soon. Stay tuned!
    </div>
  </DashboardLayout>
);

export default MOTManualAdjustment;
