import PropTypes from "prop-types";
import {useForm, Controller} from "react-hook-form";
import {Button, Label, Input, FormGroup} from "reactstrap";
import {
  BUTTONS,
  LABELS,
  TITLES,
  MESSAGES,
} from "modules/co-processing/constants/creditGen";
import EmailInputField from "./EmailInputField";

const ReviewEmailAndSubmit = ({backToPrepareSubmission, sendSubmission}) => {
  const {control, handleSubmit, reset, clearErrors, setError} = useForm({
    defaultValues: {
      recipientEmails: [],
      additionalMessage: "",
    },
  });

  const handleSubmission = async (submissionData) => {
    await sendSubmission(submissionData);
    reset(); // Reset form fields after submission
  };

  return (
    <div className="submission__reviewEmail">
      <h3>{TITLES.REVIEW_EMAIL_AND_SEND}</h3>

      <form
        className="submission__form"
        onSubmit={handleSubmit(handleSubmission)}
      >
        <div className="col-md-12">
          <EmailInputField
            control={control}
            label={LABELS.RECIPIENTS}
            name="recipientEmails"
            clearErrors={clearErrors}
            setError={setError}
          />
        </div>
        <div className="col-md-12">
          <FormGroup>
            <Label>{LABELS.SYSTEM_MESSAGE}</Label>
            <p className="helloAllAttached">
              Hello all, <br />
              Attached is the EMTS submission for [Current Month and Year].
              Total D5 RINs volume is [Sum of RINS selected].
            </p>
          </FormGroup>
        </div>
        <div className="col-md-12">
          <FormGroup>
            <Label for="additionalMessage">{LABELS.ADDITIONAL_MESSAGE}</Label>
            <Controller
              name="additionalMessage"
              control={control}
              render={({field}) => {
                return (
                  <Input
                    {...field}
                    bsSize="lg"
                    type="textarea"
                    innerRef={field.ref}
                    value={field?.value ?? ""}
                  />
                );
              }}
            />
          </FormGroup>
        </div>
        <div className="submission__btn-group">
          <Button color="standard-tertiary" onClick={backToPrepareSubmission}>
            {BUTTONS.BACK}
          </Button>
          <Button color="primary" type="submit">
            {BUTTONS.SUBMIT_AND_SEND}
          </Button>
        </div>
      </form>
    </div>
  );
};

ReviewEmailAndSubmit.propTypes = {
  backToPrepareSubmission: PropTypes.func,
  sendSubmission: PropTypes.func,
};

export default ReviewEmailAndSubmit;
