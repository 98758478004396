import moment from "moment";

export const currentYear = moment().format("YYYY");
export const currentMonth = moment().format("M");
export const currentYearMonth = moment().format("YYYY-M");

export const formatDateYearMonth = (year, month) =>
  moment(`${year}-${month}`, "YYYY-MM").format("YYYY-MM");

export const formatActualsDateLabel = (dateString) => {
  if (dateString === "N/A" || dateString == null) return "N/A";
  return moment(dateString).format("MM/DD/YYYY");
};

export const isCurrentYear = (dateString) =>
  moment(dateString).format("YYYY") === moment().format("YYYY");

export const isCurrentMonth = (date) => {
  const currMonth = moment().format("YYYY-MM");

  if (date && typeof date === "string") {
    return moment(date, "YYYY-MM").format("YYYY-MM") === currMonth;
  }

  if (date?.month && date?.year) {
    return formatDateYearMonth(date?.year, date?.month) === currMonth;
  }

  return false;
};

export const isFutureMonth = (date) => moment(date, "YYYY-MM") > moment();

export const isBeforeSpecifiedYear = (date, specifiedYear) => {
  const {year, month} = date || {};
  const dateToCompare = typeof date === "string" ? date : `${year}-${month}`;

  return moment(dateToCompare, "YYYY-MM").isBefore(moment(specifiedYear));
};

export const isFutureYear = (dateString) => {
  return moment(dateString, "YYYY").isAfter(moment(), "year");
};

// includes current month
export const isDateBeforeCurrentMonth = (dateString) => {
  const currentDate = moment();
  const columnDate = moment(dateString, "YYYY-MM");
  return currentDate.diff(columnDate, "months", true) > 0;
};

export const showPublishedVariance = (
  dateString,
  showCurrentVariance = false,
) => {
  const PUBLISHED_VARIANCE_START_YEAR = "2022";
  if (isCurrentMonth(dateString)) return showCurrentVariance;
  return (
    !isBeforeSpecifiedYear(dateString, PUBLISHED_VARIANCE_START_YEAR) &&
    isDateBeforeCurrentMonth(dateString)
  );
};

export const isAfterCurrMonth = ({year, month} = {}) => {
  const dateToCheck = moment(`${year}-${month}`, "YYYY-MM");

  return moment(dateToCheck, "YYYY-MM").isAfter(
    moment().startOf("month"),
    "month",
  );
};

export const isBeforeCurrentMonth = ({year, month} = {}) => {
  const dateToCheck = moment(`${year}-${month}`, "YYYY-MM");

  return moment(dateToCheck, "YYYY-MM").isBefore(moment().startOf("month"));
};

export const showDataVariance = (dateString) => {
  const DATA_FORECAST_START_YEAR = "2020";
  const date = moment(dateString, "YYYY-MM");

  return (
    date < moment().add(3, "months") &&
    !isBeforeSpecifiedYear(dateString, DATA_FORECAST_START_YEAR)
  );
};

export const formatMonthNameShort = (date) => {
  if (typeof date === "string") {
    return moment(date.split("-")[1], "MM").format("MMM");
  }

  return date?.month ? moment(date.month, "M").format("MMM") : "N/A";
};

export const findCurrentMonth = (list = []) =>
  list?.find(({month, year} = {}) =>
    moment(`${year}-${month}`, "YYYY-MM").isSame(moment(), "month"),
  );

export const formatMonthName = ({month} = {}) =>
  moment(month, "MM").format("MMMM");

export const generateYearsForDropdown = () => {
  const HISTORICAL_START_YEAR = 2016;
  const startingYear = moment(HISTORICAL_START_YEAR, "YYYY");
  const endingYear = moment().add(1, "year");
  const forecastYears = [];

  while (startingYear.isBefore(endingYear)) {
    forecastYears.push(startingYear.format("YYYY"));
    startingYear.add(1, "year");
  }

  return forecastYears.reverse();
};
