import {useQuery} from "@apollo/client";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useCallback, useState} from "react";
import SummaryTable from "modules/common/SummaryTable";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {HEADER_MOT_VALIDATION_REPORT} from "modules/gy-enablement/constants/Headers";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {
  RECONCILIATION_SUMMARY,
  TAXED_OUTBOUND_SUMMARY,
} from "graphql/gy-enablement/MOT/summaryDashboard";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import {setCountryDetails} from "utils/helpers/getAppSetting.js";
import BioBreadcrumb from "modules/common/Breadcrumb";
import {motTabData} from "../../../content/tabData";
import {multiLevelBreadcrumbItems} from "../../../content/breadCrumbData";
import {
  motSummaryReconciliationTableColumns,
  getTaxedOutboundTableCols,
} from "../../../content/motSummaryColumns";
import "../../common/index.scss";
import CeemasValidationFilter from "./components/CeemasValidationFilter";

const CeemasValidation = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  setCountryDetails("germany");
  const taxedOutboundTableCols = getTaxedOutboundTableCols(decimalFormat);

  const headerbreadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Germany", link: "/"},
    {
      text: "CEEMAS",
      link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
    },
  ];

  const {filterDetails} = useParams();
  const filterParamDetails =
    filterDetails === ":filterDetails"
      ? {
          company: "DE01",
          complianceyear: moment(new Date()).format("YYYY"),
          compliancemonth: "",
          ed: "",
          legalentity: "",
          material: "",
          plant: "",
          balancegroup: "",
          motProfile: "",
        }
      : JSON.parse(filterDetails);

  const onLoadPayload = {
    company: filterParamDetails?.company || "DE01",
    complianceyear:
      filterParamDetails?.complianceyear || moment(new Date()).format("YYYY"),
    compliancemonth: filterParamDetails?.compliancemonth,
    ed: filterParamDetails?.ed,
    legalentity: filterParamDetails?.legalentity,
    material: filterParamDetails?.material,
    plant: filterParamDetails?.plant,
    balancegroup: filterParamDetails?.balancegroup,
    motProfile: filterParamDetails?.motProfile,
  };
  const [filterValues, setFilterValues] = useState(onLoadPayload);
  const [breadcrumbitems, setBreadcrumbitems] = useState(
    multiLevelBreadcrumbItems(onLoadPayload),
  );
  const {data: optionsDataPayload, loading: optionsLoading} =
    useQuery(GET_FIELD_OPTIONS);

  const {
    data: taxedOutboundData,
    loading: taxedOutboundDataLoading,
    refetch: refetchTaxedOutboundSummary,
  } = useQuery(TAXED_OUTBOUND_SUMMARY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const {
    data: reconciliationData,
    loading: reconciliationDataLoading,
    refetch: refetchReconciliationSummary,
  } = useQuery(RECONCILIATION_SUMMARY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const reconciliationDataItems = reconciliationData?.getSummaryLevelRequest;
  const taxedOutboundDataItems = taxedOutboundData?.getSummaryMonthLevelRequest;

  const handleOnFilter = useCallback(
    (values) => {
      const payload = {
        company: values.company || "",
        ed: values.ed || "",
        legalentity: values.legalentity || "",
        plant: values.plant || "",
        compliancemonth: values.compliancemonth
          ? moment(values.compliancemonth).format("MM")
          : "",
        complianceyear: moment(values.complianceyear).format("YYYY"),
        material: values.material || "",
        balancegroup: values.balancegroup || "",
        motProfile: values.motProfile || "",
      };
      setBreadcrumbitems(multiLevelBreadcrumbItems(payload));
      refetchReconciliationSummary(payload);
      refetchTaxedOutboundSummary(payload);

      // Temporary solution to send filters to next pages
      setFilterValues(payload);
    },
    [refetchReconciliationSummary, refetchTaxedOutboundSummary],
  );

  const reconciliationDataItemsWithFilterDetails = reconciliationDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );
  const taxedOutboundDataItemsWithFilterDetails = taxedOutboundDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );

  const formatedDataTaxedOutbound = applyDateFormat(
    taxedOutboundDataItemsWithFilterDetails,
    [],
    dateFormat,
    "",
    [],
    decimalFormat,
    0,
  );

  return (
    <BasePageLayout
      pageTitle={HEADER_MOT_VALIDATION_REPORT}
      tabsData={motTabData}
      breadcrumbs={headerbreadcrumbItems}
    >
      <CeemasValidationFilter
        onFilter={handleOnFilter}
        data={optionsData}
        loading={optionsLoading}
        defaultValues={{
          company: filterValues?.company,
          complianceyear: moment(filterValues?.complianceyear),
          compliancemonth:
            filterValues?.compliancemonth === ""
              ? ""
              : moment(
                  `${filterValues?.complianceyear}-${filterValues?.compliancemonth}`,
                ),
          ed: filterValues?.ed === "" ? null : filterValues?.ed,
          legalentity:
            filterValues?.legalentity === "" ? null : filterValues?.legalentity,
          material:
            filterValues?.material === "" ? null : filterValues?.material,
          plant: filterValues?.plant === "" ? null : filterValues?.plant,
          balancegroup:
            filterValues?.balancegroup === ""
              ? null
              : filterValues?.balancegroup,
          motProfile:
            filterValues?.motProfile === "" ? null : filterValues?.motProfile,
        }}
      />

      <BasePageLayoutContent>
        <BioBreadcrumb breadcrumbitems={breadcrumbitems} />
        <div className="grid grid-cols-1 mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-5 gap-y-10">
          <SummaryTable
            loading={reconciliationDataLoading}
            columns={motSummaryReconciliationTableColumns}
            dataSource={reconciliationDataItemsWithFilterDetails ?? []}
            tableHeading="Overall Reconciliation Status"
            data-test="overall-reconciliation-table"
          />

          <SummaryTable
            tableHeading="Taxed Outbound Volumes"
            columns={taxedOutboundTableCols}
            dataSource={formatedDataTaxedOutbound ?? []}
            loading={taxedOutboundDataLoading}
            data-test="taxed-outbound-table"
          />
        </div>
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default CeemasValidation;
