import PropTypes from "prop-types";
import {Table} from "reactstrap";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {useState, Fragment} from "react";
import {
  Down24,
  Up24,
  TriangleDown16,
  TriangleUp16,
} from "@bphxd/ds-core-react/lib/icons";
import "./index.scss";

const ExpandableTable = ({data, columns}) => {
  const [expanded, setExpanded] = useState({});
  const table = useReactTable({
    data,
    columns,
    state: {
      expanded,
    },
    getSubRows: (row) => {
      return row.scenarioDetails;
    },
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Table className="bp-core expandable-table">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            <th label="padding" id="padding"></th>
            <th label="expansion" id="expansion"></th>
            {headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {header.isPlaceholder ? (
                  <th aria-label="placeholder" />
                ) : header.column.getCanSort() ? (
                  <th
                    onClick={header.column.getToggleSortingHandler()}
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      width: header.getSize(),
                    }}
                  >
                    <div
                      className={
                        header.column.columnDef.align === "right"
                          ? "d-flex gap-x-1 whitespace-nowrap justify-content-end"
                          : "d-flex gap-x-1 whitespace-nowrap"
                      }
                    >
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                      <div className="position-relative ms-2">
                        <div className="react-table-sort position-absolute top-50 translate-middle-y">
                          <TriangleDown16
                            className={`position-absolute bottom-0 ${
                              !header.column.getIsSorted() ||
                              (header.column.getIsSorted() &&
                                header.column.getIsSorted() !== "desc")
                                ? "opacity-20"
                                : ""
                            }`}
                          />
                          <TriangleUp16
                            className={`position-absolute top-0 ${
                              !header.column.getIsSorted() ||
                              (header.column.getIsSorted() &&
                                header.column.getIsSorted() !== "asc")
                                ? "opacity-20"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                  </th>
                ) : (
                  <th
                    // align-top for row selection and row options columns so positioning is same as <td>
                    className="align-top"
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                )}
              </Fragment>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={() => row.toggleExpanded()}
            className="cursor-pointer"
          >
            <td label="padding" id="padding"></td>
            {row.depth === 0 ? (
              <td key="button" id="expansion">
                <button type="button">
                  {row.getIsExpanded() ? <Up24 /> : <Down24 />}
                </button>
              </td>
            ) : (
              <td label="expansion" id="expansion" />
            )}
            {row.getVisibleCells().map((cell) => {
              if (cell.column.id !== "operation" || row.depth === 0) {
                let className = row.depth === 0 ? "fw-bold " : "";
                className += "text-" + cell.column.columnDef.align;
                return (
                  <td key={cell.id} className={className} id={cell.column.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              }
              return (
                <td key={cell.id} id={cell.column.id}>
                  {" "}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

ExpandableTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
};
export default ExpandableTable;
