import {useSubscription} from "@apollo/client";
import GENERATE_PDF_SUBSCRIPTION from "graphql/docManager/pdfGenSubscription";
import {useAppSetting} from "providers/appSetting";
import {useEffect} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";

const useGenerateCertificateSubscription = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {mbOutboundQtyEuId, country, division, sdNumber} =
    appSetting.subscriptionDataGenerateCertificate;

  const {data, loading, error} = useSubscription(GENERATE_PDF_SUBSCRIPTION, {
    variables: {mbOutboundQtyEuId},
    skip: !mbOutboundQtyEuId,
  });

  useEffect(() => {
    if (data && mbOutboundQtyEuId) {
      const redirectUrl = `/document-manager/outgoing/${country}/${division}/${mbOutboundQtyEuId}`;
      const message = (
        <span>
          Your document is now ready{" "}
          <Link
            className="text-[#757575] hover:text-[#757575] italic !underline"
            to={redirectUrl}
          >
            {sdNumber}
          </Link>
        </span>
      );
      toast.success(message);
      setAppSetting({
        ...appSetting,
        subscriptionDataGenerateCertificate: {
          mbOutboundQtyEuId: null,
          country: null,
          division: null,
          sdNumber: null,
        },
      });
    }
  }, [
    appSetting,
    country,
    data,
    division,
    mbOutboundQtyEuId,
    sdNumber,
    setAppSetting,
  ]);

  return {data, loading, error};
};

export default useGenerateCertificateSubscription;
