import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useMemo, useState} from "react";

import {useMsal, useAccount} from "@azure/msal-react";
import {UpdateDocAPI} from "graphql/es-co-processing/mass-balance/updateInbound";

import {toast} from "react-toastify";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";

const unitToBeDisplayed = "kg CO2eq/dry-ton";

const InboundDetail = ({data}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const [transportationLossValue, setTransportationLoss] = useState(0);
  const [transportationLossEditMode, setTransportationLossEditMode] =
    useState(false);

  const inboundRecordData = useMemo(() => {
    const {inboundRecord} = data;
    const inRec = {...inboundRecord};
    let calculationSteps = inRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      inRec.calculationSteps = {};
      return inRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    inRec.calculationSteps =
      calculationSteps[inRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return inRec;
  }, [data]);

  const handleTransportLossChange = async () => {
    if (transportationLossValue > 0) {
      if (
        transportationLossValue > inboundRecordData.qtyProcessedIn ||
        transportationLossValue > inboundRecordData.qtyProcessedOut
      ) {
        toast.error(
          "Tranportation loss cannot be greater than quantity processed",
        );
      } else {
        setTransportationLossEditMode(false);
        const {data: response} = await UpdateDocAPI({
          event: {
            inboundEuQtyId: inboundRecordData.inboundEuQtyId,
            transportationLoss: transportationLossValue,
            changedBy: account.username,
          },
        });
        if (response?.bioLcMassBalanceTransportationLoss?.statusCode !== 200) {
          toast.error(response?.bioLcMassBalanceTransportationLoss?.message);
        }
      }
    }
  };

  function numberFormatter(value) {
    if (Number.isInteger(value) && value !== 0) {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    }
    if (value === 0 || value === "0.0") {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 0);
    }

    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  return (
    <div className="flex flex-col h-96 justify-start items-stretch w-full gap-4">
      <div className="flex flex-row justify-between items-center h-[2.2rem]"></div>
      <div className="flex flex-row justify-between items-start gap-2">
        <FormGroup>
          <Label for="ghgEecIn" className="fw-bold">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEecIn"
            data-test="ghgEecIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEec)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgElIn" className="fw-bold">
            El
          </Label>
          <Input
            type="text"
            id="ghgElIn"
            data-test="ghgElIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEl)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEpIn" className="fw-bold">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEpIn"
            data-test="ghgEpIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEp)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEtdIn" className="fw-bold">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtdIn"
            data-test="ghgEtdIn"
            readOnly
            value={
              inboundRecordData.ghgEtd === "DDV"
                ? inboundRecordData.ghgEtd
                : numberFormatter(inboundRecordData.ghgEtd)
            }
          />
          <UncontrolledTooltip placement="bottom" target="ghgEtdIn">
            {inboundRecordData.calculationSteps?.ghg_etd ?? ""}
          </UncontrolledTooltip>
        </FormGroup>
        <div className="h-full flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEuIn" className="fw-bold">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEuIn"
            data-test="ghgEuIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEu)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEscaIn" className="fw-bold">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEscaIn"
            data-test="ghgEscaIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEsca)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccsIn" className="fw-bold">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccsIn"
            data-test="ghgEccsIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEccs)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccrIn" className="fw-bold">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccrIn"
            data-test="ghgEccrIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEccr)}
          />
        </FormGroup>
        <div className="h-full flex flex-row items-center">=</div>
        <FormGroup>
          <Label for="ghgTotalIn" className="fw-bold">
            Total
          </Label>
          <Input
            className="min-w-[100px]"
            type="text"
            id="ghgTotalIn"
            data-test="ghgTotalIn"
            readOnly
            value={
              inboundRecordData.ghgTotal === "DDV" ||
              inboundRecordData.ghgTotal === "TDV"
                ? inboundRecordData.ghgTotal
                : numberFormatter(inboundRecordData.ghgTotal)
            }
          />
          <FormText>{unitToBeDisplayed}</FormText>
        </FormGroup>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col">
          <span className="border-b">Quantity on certificate t</span>
          <span className="py-1 fw-bold truncate ...">
            {numberFormatter(inboundRecordData.feedstockQty)}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="">Transportation loss</span>
          <div className="flex flex-row ">
            <Input
              type="number"
              id="transportationLossFactor"
              data-test="transportationLossFactor"
              onChange={(e) => setTransportationLoss(e.target.value)}
              disabled={!transportationLossEditMode}
              defaultValue={numberFormatter(
                inboundRecordData.transportationLossFactor,
              )}
            />

            {!transportationLossEditMode && (
              <Button
                size="sm"
                color="standard-tertiary bg-white"
                className="rounded-0 btn-standard-secondary bg-white"
                onClick={() => setTransportationLossEditMode(true)}
                disabled={!inboundRecordData.transportationLossEnableFlag}
              >
                Edit
              </Button>
            )}
            {transportationLossEditMode && (
              <Button
                size="sm"
                color="standard-tertiary bg-white"
                className="rounded-0 btn-standard-secondary  bg-white"
                onClick={() => handleTransportLossChange()}
              >
                Apply
              </Button>
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <span className="border-b">Country of origin of raw material</span>
          <span className="py-1 fw-bold ">
            {inboundRecordData.originCountryName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Name of supplier</span>
          <span className="py-1 fw-bold ">
            {inboundRecordData.supplierName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Address of supplier</span>
          <span className="py-1 fw-bold ">
            {inboundRecordData.supplierAddress ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Transport system</span>
          <span className="py-1 fw-bold ">
            {inboundRecordData.motName ?? "-"}
          </span>
        </div>
        <div className="flex flex-col">
          <span className="border-b">Vessel name</span>
          <span className="py-1 fw-bold ">
            {inboundRecordData.vesselName ?? "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

InboundDetail.propTypes = {
  data: PropTypes.object,
};

export default InboundDetail;
