import {useMemo, useState} from "react";
import {Nav, NavItem, NavLink} from "reactstrap";
import {
  PI_Vision_URL,
  ORION_URL,
} from "modules/co-processing/constants/coProcessing";
import {DATA_TABS} from "modules/co-processing/constants/shipments";

const DataView = () => {
  const [dataTab, setDataTab] = useState(DATA_TABS.PI_DATA);
  const displayURL = useMemo(
    () => (dataTab === DATA_TABS.PI_DATA ? PI_Vision_URL : ORION_URL),
    [dataTab],
  );

  return (
    <div className="shipments-table-container">
      <div className="shipments-tab-row pl-7">
        <Nav tabs>
          <NavItem>
            <NavLink
              data-test="shipments-nav-link"
              active={dataTab === DATA_TABS.PI_DATA}
              onClick={() => setDataTab(DATA_TABS.PI_DATA)}
            >
              {DATA_TABS.PI_DATA}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-test="shipments-nav-link"
              active={dataTab === DATA_TABS.ORION_DATA}
              onClick={() => setDataTab(DATA_TABS.ORION_DATA)}
            >
              {DATA_TABS.ORION_DATA}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        className="h-[600px] bg-light pl-7"
        data-test="pi-iframe-container-shipments"
      >
        <iframe
          className="w-full"
          src={displayURL}
          title="PIVisionSRC"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </div>
  );
};

export default DataView;
