import {gql} from "@apollo/client";
import axios from "axios";
import {client} from "providers/Apollo";
import {POS_FILE_PARSE_TRIGGER} from "./pos";

export const UPLOAD_CSV_FILE = gql`
  mutation uploadCsvMutation($bodyJson: String, $overRideWarnings: Boolean) {
    updateShipmentDataFromFileUploadToDB(
      event: {bodyJson: $bodyJson, overRideWarnings: $overRideWarnings}
    ) {
      errors {
        columnName
        rowNumber
      }
      message
      statusCode
      warnings {
        columnName
        rowNumber
      }
    }
  }
`;

const UPLOAD_FILES = gql`
  query uploadFileQuery(
    $commentValue: String
    $fileNameValue: String
    $fileTypeValue: String
    $type: String
  ) {
    fileUploader(
      event: {
        comment: $commentValue
        file_name: $fileNameValue
        file_type: $fileTypeValue
        source: $type
      }
    ) {
      body
      data {
        errorMessage
      }
      headers {
        content_type
      }
      request {
        status
      }
      statusCode
    }
  }
`;

export const UPLOAD_C14 = gql`
  query c14Upload($fileName: String, $fileType: String, $batchId: String) {
    tankusage_c14_batches(
      event: {file_name: $fileName, file_type: $fileType, batchId: $batchId}
    ) {
      statusCode
      body
      data {
        errorMessage
      }
      request {
        status
      }
      headers {
        content_type
      }
    }
  }
`;

export const DOWNLOAD_CSV_TEMPLATE = gql`
  query donwloadTemplate {
    templateDownloader {
      body
      error
      status_code
    }
  }
`;

export const UPLOAD_POS_FILE = gql`
  query uploadPoSFile($com: String!, $file: String!, $countryCode: String!) {
    posFileUploader(
      event: {comment: $com, countryCode: $countryCode, fileName: $file}
    ) {
      statusCode
      message
      data {
        url
      }
      errors {
        code
        message
      }
    }
  }
`;

export const uploadPoSFile = async (com, file, countryCode) => {
  const isResponseSuccess = (fileUploadStatusCode) => {
    switch (fileUploadStatusCode) {
      case 200:
      case 204:
        return true;
      default:
        return false;
    }
  };

  const buildFormData = (fileData, fields) => {
    const fileFormData = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([k, v]) => {
        fileFormData.append(k, v);
      });
      fileFormData.append("file", fileData);
    }
    return fileFormData;
  };

  let statusCode;

  // Allocate s3 bucket
  const {
    data: {posFileUploader: responsePosFileUploader},
  } = await client.query({
    query: UPLOAD_POS_FILE,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      com,
      file: file.name,
      countryCode,
    },
  });

  statusCode = responsePosFileUploader.statusCode;
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  // Upload binary file
  const body = JSON.parse(responsePosFileUploader.data.url);
  const formData = buildFormData(file, body.fields);
  const uploadBinaryResponse = await axios.post(body.url, formData);
  statusCode = uploadBinaryResponse.status;
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  const {
    data: {posDataExtractor: responsePosDataExtractor},
  } = await client.query({
    query: POS_FILE_PARSE_TRIGGER,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      country: countryCode,
      fileName: file.name,
    },
  });
  statusCode = responsePosDataExtractor.statusCode;
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  // All good
  return 200;
};

export default UPLOAD_FILES;
