import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import SpinnerLoading from "modules/common/SpinnerLoading";
import PropTypes from "prop-types";
import {Fragment} from "react";
import {Table} from "reactstrap";
import Filter from "../Filter/Filter";

const getSortOpacity = (isSorted, sortDirection) => {
  if (!isSorted || (isSorted && isSorted !== sortDirection)) {
    return "opacity-20";
  }
  return "";
};

const DocumentTable = ({
  columns,
  data,
  loading,
  showFilter,
  columnFilter,
  setColumnFilter,
}) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters: columnFilter,
    },
    initialState: {
      pagination: {
        pageSize: 50,
      },
    },
    onColumnFiltersChange: setColumnFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  const totalData = table.getPrePaginationRowModel().rows.length;

  const gettableHeader = (headerItem) => {
    return headerItem.column.getCanSort() ? (
      <th
        style={{
          cursor: "pointer",
          userSelect: "none",
          width: headerItem.getSize(),
          borderTop: "1px solid #ededed",
          borderBottom: "1px solid #ccc",
          backgroundColor: "#fafafa",
        }}
      >
        <div
          className={`d-flex align-items-center ${
            headerItem.getContext().column.columnDef.dataType === "number" &&
            "justify-content-end"
          }`}
          onClick={headerItem.column.getToggleSortingHandler()}
          onKeyDown={() => {}}
        >
          <div>
            {flexRender(
              headerItem.column.columnDef.header,
              headerItem.getContext(),
            )}
          </div>
          <div className="position-relative ms-2">
            <div className="react-table-sort position-absolute top-50 translate-middle-y">
              <TriangleUp16
                className={`mb-[-5px] ${getSortOpacity(
                  headerItem.column.getIsSorted(),
                  "asc",
                )}`}
              />
              <TriangleDown16
                className={`mt-[-5px] ${getSortOpacity(
                  headerItem.column.getIsSorted(),
                  "desc",
                )}`}
              />
            </div>
          </div>
        </div>
        {headerItem.column.getCanFilter() && showFilter ? (
          <div className="mt-[10px]">
            <Filter
              column={headerItem.column}
              data={data}
              isParentKey={false}
            />
          </div>
        ) : null}
      </th>
    ) : (
      <th
        style={{
          width: headerItem.getSize(),
          borderTop: "1px solid #ededed",
          borderBottom: "1px solid #ccc",
          backgroundColor: "#fafafa",
        }}
      >
        {flexRender(
          headerItem.column.columnDef.header,
          headerItem.getContext(),
        )}
      </th>
    );
  };

  return (
    <>
      <div className="w-full overflow-x-auto">
        <Table hover responsive>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Fragment key={header.id}>{gettableHeader(header)}</Fragment>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="100%">
                  <div className="flex items-center justify-center ">
                    <SpinnerLoading />
                  </div>
                </td>
              </tr>
            )}
            {!loading &&
              totalData > 0 &&
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`
                        ${
                          cell.getContext().column.columnDef.dataType ===
                            "number" && "text-right"
                        }
                        ${
                          cell.row.getIsSelected()
                            ? "table-row-selected-bg"
                            : ""
                        }
                        
                        `}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            {!loading && totalData === 0 && !columnFilter.length === 0 && (
              <tr>
                <td colSpan="100%">
                  <div className="flex items-center justify-center error-message-table ">
                    No Data
                  </div>
                </td>
              </tr>
            )}
            {!loading && totalData === 0 && columnFilter.length > 0 && (
              <tr>
                <td colSpan="100%">
                  <div className="pt-7 pl-5 error-message-table ">
                    No results found for the search criteria.
                    <span
                      className="underline cursor-pointer"
                      onClick={() => columnFilter([])}
                      onKeyDown={() => columnFilter([])}
                    >
                      Clear search
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};

DocumentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  showFilter: PropTypes.bool,
  columnFilter: PropTypes.string,
  setColumnFilter: PropTypes.func,
};

export default DocumentTable;
