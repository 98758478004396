/* eslint-disable no-restricted-syntax */
import {useState, useCallback, useEffect, useMemo, useRef} from "react";
import {Table} from "antd";
import "../../components/index.scss";
import {useQuery, useLazyQuery} from "@apollo/client";
import {
  FORECAST_EAST_DATA,
  USER_FORECAST_DATA,
  USER_FORECAST_AUDIT_DATA,
  FORECAST_USER_API_DATA,
  FORECAST_CREDITS_DATA,
} from "graphql/forecast-data";
import {useSelectedDate} from "modules/forecasting/context/ForecastingDateContext";
import {useForecastFilter} from "modules/forecasting/context/ForecastingFilterContext";
import {
  getGraphDeficitsValues,
  getGraphCreditsValues,
  getFullYearData,
} from "modules/forecasting/helpers/generateUnpublishedData";
import {
  buildColumnsWithEditableCells,
  updateGasObligatedTotal,
  updateDieselObligatedTotal,
  setTotalObligationsForMonth,
  mapPageDataToTableRows,
  processForecastUserData,
} from "modules/forecasting/helpers/computeUnpublishedPageTableData";
import {
  setTotalCreditsForMonth,
  updateCreditsObligation,
  getCreditsUPPageData,
} from "modules/forecasting/helpers/computeCreditsData";
import YearTotalBarChart from "modules/forecasting/components/GraphSection/YearTotalBarChart";
import ForecastGraphSet from "modules/forecasting/components/ForecastGraphSet";
import GraphSection from "modules/forecasting/components/GraphSection/GraphSection";
import YearTotalCreditsGraph from "modules/forecasting/components/GraphSection/YearTotalCreditsGraph";
import AdjustmentSummary from "modules/forecasting/components/AdjustmentSummary";
import CommentSection from "modules/forecasting/components/CommentSection";
import {
  measurementLabelsKVPairsForEastDeficits,
  measurementLabelsKVPairsForEastCredits,
  SAVE_CHANGES,
  CANCEL,
  ERRORS,
  SUCCESS_SAVE,
} from "modules/forecasting/constants/inventory";
import UnpublishedViewByForecastType from "modules/forecasting/components/UnpublishedViewByForecast";
import {getEastCoastUPPageData} from "modules/forecasting/helpers/computeEastCoastData";
import {NavLink} from "react-router-dom";
import {toast} from "react-toastify";
import {useMsal, useAccount} from "@azure/msal-react";
import SavedVersionsTable from "modules/forecasting/components/SavedVersionsTable";
import ToPublishModal from "modules/forecasting/components/ToPublishModal";
import {logFetchDefaults} from "modules/forecasting/helpers/getQueryVariables";
import {isEqual, debounce} from "lodash";
import multiReasonValidation from "modules/forecasting/helpers/multiReasonValidation";
import Collapse, {
  collapsibleData,
} from "modules/forecasting/components/CollapsiblePanel";
import kpiDataAndLabels from "modules/forecasting/helpers/getKPIData";
import mapUpdatedValues, {
  setRowUpdatedForecast,
} from "modules/forecasting/helpers/mapUpdatedValues";
import InventoryWrapper from "../../components/InventoryWrapper";

const ECUnpublished = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {monthIndex} = useSelectedDate();

  const {appliedFilters} = useForecastFilter();

  const {
    region: selectedRegion,
    year: selectedYear,
    forecastType,
  } = appliedFilters;

  const [tableDeficitsData, setTableDeficitsData] = useState([]);
  const [initialTableDeficitsData, setInitialTableDeficitsData] = useState([]);
  const [fullYearTableDeficitsData, setFullYearTableDeficitsData] = useState(
    [],
  );
  const [tableCreditsData, setTableCreditsData] = useState([]);
  const [initialTableCreditsData, setInitialTableCreditsData] = useState([]);
  const [fullYearTableCreditsData, setFullYearTableCreditsData] = useState([]);
  const [comments, setComments] = useState("");
  const [userEditedValues, setUserEditedValues] = useState([]);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [search, setSearch] = useState("");
  const [forecastUserData, setForecastUserData] = useState([]);
  const [resetLogs, setResetLogs] = useState(false);
  const [resetTableData, setResetTableData] = useState(false);

  const {
    data: deficitsData,
    loading,
    error: deficitsError,
    refetch: refetchDeficits,
  } = useQuery(FORECAST_EAST_DATA, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      region: "ec",
      year: parseInt(selectedYear, 10),
    },
  });

  const {
    data: creditsData,
    error: creditsError,
    refetch: refetchCredits,
  } = useQuery(FORECAST_CREDITS_DATA, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      region: "ec",
      year: parseInt(selectedYear, 10),
      forecastType: "rin",
    },
  });

  const resetForm = () => {
    setComments("");
    setTableDeficitsData([]);
    setFullYearTableDeficitsData([]);
    setTableCreditsData([]);
    setFullYearTableCreditsData([]);
    setUserEditedValues([]);
  };

  // reset table state on year change
  const yearRef = useRef(selectedYear);

  const [fetchAuditLog, {data: dataAudit, loading: loadingAudit}] =
    useLazyQuery(USER_FORECAST_AUDIT_DATA, {
      variables: logFetchDefaults({
        group: "logEntry",
        region: "ec",
        forecast_type: forecastType,
      }),
      fetchPolicy: "network-only",
      onCompleted: () => {
        if (dataAudit && !resetLogs) {
          setResetLogs(false);
        }
      },
    });

  const debounceInput = useMemo(
    () =>
      debounce((value) => {
        fetchAuditLog({
          variables: logFetchDefaults({
            group: "logEntry",
            region: "ec",
            search_text: value,
            forecast_type: forecastType,
          }),
        });
      }, 800),
    [fetchAuditLog, forecastType],
  );

  const handleDebouncedInput = (value) => {
    setSearch(value);
    debounceInput(value);
  };

  const [submitQuery, {data: dataRes, errors: errorsRes}] = useLazyQuery(
    USER_FORECAST_DATA,
    {
      // Only cache-and-network calls onCompleted on subsequent calls with same variables
      // https://github.com/apollographql/apollo-client/issues/9338
      fetchPolicy: "cache-and-network",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length < 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        } else if (dataRes != null) {
          refetchDeficits();
          refetchCredits();
          fetchAuditLog();
          resetForm();
          toast.success(SUCCESS_SAVE);
          setSubmitDisabled(true);
          setSearch("");
          setResetLogs(true);
        }
      },
    },
  );

  const [fetchForecastUserApi, {data: userData}] = useLazyQuery(
    FORECAST_USER_API_DATA,
    {
      variables: {
        year: selectedYear,
        region: "ec",
        forecast_type: forecastType,
      },
      fetchPolicy: "cache-and-network",

      onCompleted: () => {
        const processedData = processForecastUserData(userData);
        setForecastUserData(processedData);
      },
    },
  );

  useEffect(() => {
    fetchForecastUserApi();
    setSearch("");
    setComments("");
    setUserEditedValues([]);
    setResetTableData(true);
    setSubmitDisabled(true);
    fetchAuditLog({
      variables: logFetchDefaults({
        group: "logEntry",
        region: selectedRegion,
        forecast_type: forecastType,
      }),
    });
  }, [
    fetchForecastUserApi,
    selectedYear,
    selectedRegion,
    forecastType,
    fetchAuditLog,
  ]);

  useEffect(() => {
    if (yearRef.current !== selectedYear) {
      resetForm();
      yearRef.current = selectedYear;
    }
  }, [selectedYear]);

  const deficitsPageData = useMemo(() => {
    if (loading || deficitsError) return null;
    return getEastCoastUPPageData(deficitsData, selectedYear);
  }, [deficitsData, selectedYear, loading, deficitsError]);

  const creditsPageData = useMemo(() => {
    if (loading || creditsError) return null;
    return getCreditsUPPageData(creditsData, selectedYear, "east");
  }, [creditsData, selectedYear, loading, creditsError]);

  useEffect(() => {
    if (deficitsPageData && deficitsPageData.length !== 0) {
      const fullYearDeficits = mapPageDataToTableRows(
        deficitsPageData,
        forecastUserData,
        measurementLabelsKVPairsForEastDeficits,
        true,
      );

      setFullYearTableDeficitsData(fullYearDeficits);

      const currentDeficits = mapPageDataToTableRows(
        deficitsPageData.slice(monthIndex, monthIndex + 4),
        forecastUserData,
        measurementLabelsKVPairsForEastDeficits,
        true,
      );

      setInitialTableDeficitsData(currentDeficits);

      setTableDeficitsData((prevTableData) => {
        const updatedArray = mapPageDataToTableRows(
          deficitsPageData.slice(monthIndex, monthIndex + 4),
          forecastUserData,
          measurementLabelsKVPairsForEastDeficits,
          true,
        );
        return mapUpdatedValues(updatedArray, prevTableData);
      });
    }
  }, [forecastUserData, deficitsPageData, monthIndex]);

  useEffect(() => {
    if (creditsPageData && creditsPageData.length !== 0) {
      const fullYearCredits = mapPageDataToTableRows(
        creditsPageData,
        forecastUserData,
        measurementLabelsKVPairsForEastCredits,
      );

      setFullYearTableCreditsData(fullYearCredits);

      const currentCredits = mapPageDataToTableRows(
        creditsPageData.slice(monthIndex, monthIndex + 4),
        forecastUserData,
        measurementLabelsKVPairsForEastCredits,
        true,
      );

      setInitialTableCreditsData(currentCredits);

      setTableCreditsData((prevTableData) => {
        const updatedArray = mapPageDataToTableRows(
          creditsPageData.slice(monthIndex, monthIndex + 4),
          forecastUserData,
          measurementLabelsKVPairsForEastCredits,
          true,
        );
        return mapUpdatedValues(updatedArray, prevTableData);
      });
    }
  }, [forecastUserData, creditsPageData, monthIndex]);

  useEffect(() => {
    if (resetTableData) {
      if (!isEqual(tableDeficitsData, initialTableDeficitsData)) {
        setTableDeficitsData(initialTableDeficitsData);
      }
      if (!isEqual(tableCreditsData, initialTableCreditsData)) {
        setTableCreditsData(initialTableCreditsData);
      }

      setResetTableData(false);
    }
  }, [
    resetTableData,
    initialTableDeficitsData,
    initialTableCreditsData,
    tableCreditsData,
    tableDeficitsData,
  ]);

  /**
   * Formats the user edited values to fit the API format. Updates User Edited Values state by filtering
   * current record from the state and adding the new formatted records.
   */
  const valueToSave = useCallback(
    (currentUserEdit) => {
      const editedDateKeys = Object.keys(currentUserEdit.updatedForecast || []);
      const {key} = currentUserEdit; // Current user edited values without the current record key

      const currentUserEditedValues = userEditedValues.filter(
        (row) => row.key !== key,
      ); // If the updated forecast object is empty, it updates the state with filtered values

      if (!editedDateKeys.length) {
        return currentUserEditedValues;
      }

      // Generates needed format for saving
      const result = editedDateKeys.map((date) => {
        const obj = currentUserEdit.updatedForecast[date];
        const [year, month] = date.split("_");

        return {
          year,
          month,
          key,
          value: obj.value,
          reason: obj?.reasonCodes || [],
          itemComments: obj.itemComments || "",
        };
      });

      // Sorts based on month and year
      return [...currentUserEditedValues, ...result].sort((a, b) => {
        return a.year === b.year ? a.month - b.month : a.year - b.year;
      });
    },
    [userEditedValues],
  );

  const submitForecastData = () => {
    if (!multiReasonValidation(userEditedValues)) {
      toast.error(ERRORS.REASON_CODE_REQUIRED);
      return;
    }
    submitQuery({
      fetchPolicy: "network-only",
      variables: {
        userInfo: account.username,
        comments,
        values: userEditedValues,
        region: selectedRegion,
      },
    });
  };

  // Patch the user edits to submit
  const patchUserEdits = useCallback(
    (updatedRecord, dataIndex) => {
      // Set userData to be patched
      const userEditedValueToSave = valueToSave(updatedRecord);
      setUserEditedValues(userEditedValueToSave);

      const hadReasonCodes = !userEditedValueToSave.find(
        (values) => !values.reason.length,
      );
      if (userEditedValueToSave.length && hadReasonCodes) {
        setSubmitDisabled(false);
      }

      if (!userEditedValueToSave.length || !hadReasonCodes) {
        setSubmitDisabled(true);
      }

      // Update row data
      const rowsWithUpdatedForecast = setRowUpdatedForecast(
        tableDeficitsData,
        updatedRecord,
      );

      setTableDeficitsData(
        rowsWithUpdatedForecast.map((row) => {
          if (updatedRecord?.key?.includes("gas") && row.key === "total_gas") {
            return updateGasObligatedTotal(dataIndex, rowsWithUpdatedForecast);
          }
          if (
            updatedRecord?.key?.includes("diesel") &&
            row.key === "total_diesel"
          ) {
            return updateDieselObligatedTotal(
              dataIndex,
              rowsWithUpdatedForecast,
            );
          }
          // updates total for the whole month
          if (row.key === "total_obligation") {
            return setTotalObligationsForMonth(
              dataIndex,
              rowsWithUpdatedForecast,
            );
          }

          return row;
        }),
      );
    },

    [tableDeficitsData, valueToSave],
  );

  // Patch the user edits to submit
  const patchUserCreditsEdits = useCallback(
    (updatedRecord, dataIndex) => {
      // Set userData to be patched
      const userEditedValueToSave = valueToSave(updatedRecord);
      setUserEditedValues(userEditedValueToSave);

      const hadReasonCodes = !userEditedValueToSave.find(
        (values) => !values.reason.length,
      );
      if (userEditedValueToSave.length && hadReasonCodes) {
        setSubmitDisabled(false);
      }

      if (!userEditedValueToSave.length || !hadReasonCodes) {
        setSubmitDisabled(true);
      }

      // Update row data
      const rowsWithUpdatedForecast = setRowUpdatedForecast(
        tableCreditsData,
        updatedRecord,
      );

      setTableCreditsData(
        rowsWithUpdatedForecast.map((row) => {
          if (updatedRecord?.key?.includes("d4") && row.key === "d4_total") {
            return updateCreditsObligation(
              dataIndex,
              "d4",
              rowsWithUpdatedForecast,
            );
          }
          if (updatedRecord?.key?.includes("d6") && row.key === "d6_total") {
            return updateCreditsObligation(
              dataIndex,
              "d6",
              rowsWithUpdatedForecast,
            );
          }
          // updates total for the whole month
          if (row.key === "total_obligation") {
            return setTotalCreditsForMonth(dataIndex, rowsWithUpdatedForecast);
          }
          return row;
        }),
      );
    },

    [tableCreditsData, valueToSave],
  );

  return (
    <InventoryWrapper loading={loading}>
      <UnpublishedViewByForecastType
        region="ec"
        activeForecastType={forecastType}
        data={{deficits: deficitsData, credits: creditsData}}
      />

      <Collapse
        initialOpenIds={forecastType !== "" ? [forecastType] : ["rin", "rvo"]}
      >
        {collapsibleData(forecastType).map(({label, value}) => {
          return label === "deficits" ? (
            <Collapse.Item key={value} id={value}>
              <Collapse.ItemHeader>
                <h2>{label}</h2>
              </Collapse.ItemHeader>
              <Collapse.ItemContent>
                <GraphSection view="unpublishedView" isUnpublished>
                  <YearTotalBarChart
                    tableData={tableDeficitsData}
                    pageData={deficitsPageData}
                  />
                  <ForecastGraphSet
                    unpublishedGraphData={getGraphDeficitsValues(
                      tableDeficitsData,
                    )}
                    fullYearData={getFullYearData(
                      value,
                      fullYearTableDeficitsData,
                    )}
                  />
                </GraphSection>
                <div
                  data-test="unpublished-editable-table"
                  className="col-span-full combined-table"
                >
                  <Table
                    columns={buildColumnsWithEditableCells(
                      patchUserEdits,
                      tableDeficitsData,
                    )}
                    data-test="obligated-volume-table"
                    dataSource={tableDeficitsData ?? false}
                    pagination={false}
                    scroll={{x: "max-content"}}
                    showHeader={false}
                  />
                </div>
              </Collapse.ItemContent>
            </Collapse.Item>
          ) : (
            <Collapse.Item key={value} id={value}>
              <Collapse.ItemHeader>
                <h2>{label}</h2>
              </Collapse.ItemHeader>

              <Collapse.ItemContent>
                <GraphSection view="unpublishedView" isUnpublished isCredits>
                  <YearTotalCreditsGraph
                    fullYearData={creditsPageData}
                    region="east"
                  />
                  <ForecastGraphSet
                    unpublishedGraphData={getGraphCreditsValues(
                      tableCreditsData,
                    )}
                    fullYearData={getFullYearData(
                      value,
                      fullYearTableCreditsData,
                    )}
                    isCredits
                  />
                </GraphSection>
                <div
                  data-test="unpublished-editable-table"
                  className="col-span-full combined-table"
                >
                  <Table
                    columns={buildColumnsWithEditableCells(
                      patchUserCreditsEdits,
                      tableCreditsData,
                    )}
                    data-test="obligated-volume-table"
                    dataSource={tableCreditsData ?? false}
                    pagination={false}
                    scroll={{x: "max-content"}}
                    showHeader={false}
                  />
                </div>
              </Collapse.ItemContent>
            </Collapse.Item>
          );
        })}
      </Collapse>
      <SavedVersionsTable
        data={dataAudit}
        loading={loadingAudit}
        fetchAuditLog={fetchAuditLog}
        type="Saved"
        search={search}
        setSearch={handleDebouncedInput}
        resetLogs={resetLogs}
        setResetLogs={setResetLogs}
      />
      <div className="col-span-full">
        <AdjustmentSummary userEditedValues={userEditedValues} />
        <CommentSection comments={comments} updateComments={setComments} />
        <div className="w-full lg:w-2/3 flex h-12 items-center mt-2 sm:mt-0">
          <button
            disabled={isSubmitDisabled}
            key="edit-forecast-button-save"
            type="submit"
            data-test="edit-forecast-save-button"
            className="px-3.5 py-3 my-1 text-sm font-bold text-center text-white uppercase rounded hover:opacity-80 bg-bp-green-800 disabled:bg-gray-300"
            onClick={submitForecastData}
          >
            {SAVE_CHANGES}
          </button>
          <button
            key="edit-forecast-button-cancel"
            type="button"
            data-test="edit-forecast-cancel-button"
            className="px-5 py-2 my-1 text-lg text-center text-blue-500 underline hover:text-blue-500"
          >
            <NavLink
              className="hover:text-blue-500 text-decoration-none"
              data-test="forecast-reports-link"
              to="/forecasting/ec-forecast-data"
            >
              {CANCEL}
            </NavLink>
          </button>
          {!loading && !forecastType ? (
            <ToPublishModal
              account={account}
              creditsData={creditsData}
              deficitsData={deficitsData}
            />
          ) : null}
        </div>
      </div>
    </InventoryWrapper>
  );
};

export default ECUnpublished;
