import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {BREADCRUMBS} from "modules/forecasting/constants/header";
import COUNTRY_CODES from "constants/appSettings";

const BREADCRUMBS_LIST = [
  {title: BREADCRUMBS.BIOVERSE, to: "/", isLast: false},
  {title: BREADCRUMBS.UNITED_STATES, to: "/", isLast: false},
  {title: BREADCRUMBS.FORECASTING, to: "/forecasting", isLast: true},
];

const ForecastingBreadcrumbs = () => {
  const {appSetting, setAppSetting} = useAppSetting();

  const handleBreadcrumbClick = (title) => {
    const countryCode =
      title === BREADCRUMBS.BIOVERSE
        ? COUNTRY_CODES.GLOBAL
        : COUNTRY_CODES.UNITED_STATES;
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName === countryCode,
    );

    console.log("countryCode", countryCode);

    setAppSetting({
      ...appSetting,
      currentCountry: countryCode,
      currentCountryMappingData: countryFeatureData,
    });
  };

  return (
    <div className="px-6 pt-5">
      <Breadcrumb>
        {BREADCRUMBS_LIST.map(({title, to, isLast}, index) => (
          <BreadcrumbItem
            key={title}
            data-test="forecasting-breadcrumb"
            active={index !== 0}
          >
            {isLast ? (
              title
            ) : (
              <Link to={to} onClick={() => handleBreadcrumbClick(title)}>
                {title}
              </Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default ForecastingBreadcrumbs;
