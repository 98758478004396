/* eslint-disable array-callback-return */
import {Form, Button} from "reactstrap";
import process from "process";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {useForm, FormProvider} from "react-hook-form";
import {useState, useEffect} from "react";
import {useQuery} from "@apollo/client";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {
  COPRO_EU_COST_CALCULATION_FILTER,
  COPRO_EU_COST_CALCULATION_DETAILS,
} from "graphql/es-co-processing/cost-calculation/costCalculation";
import Layout from "../components/Layout";
import CostCalculationFilterContent from "../components/CostCalculationFilterContent";
import SplitTable from "../components/Table/index.js";
import {
  DEFAULT_ADD_COST_PARAMETERS_HEADER,
  DEFAULT_EDIT_COST_PARAMETERS_HEADER,
  DEFAULT_REVIEW_COST_PARAMETERS_HEADER,
} from "./DefaultHeaders";

const CostCalculationLanding = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);

  const currentYear = new Date().getFullYear();
  const currentMonthNumber = new Date().getMonth() + 1;
  const DEFAULT_CURRENCY_EURO = "Euro";

  const extractRatio = (inputString) => {
    if (!inputString) return "";
    const parts = inputString?.split(" - ");
    if (parts.length === 1) return "";
    return parts[parts.length - 1].trim();
  };

  const [feedstockList, setFeedstockList] = useState(null);
  const [paramsEditable, setParamsEditable] = useState(true);

  const navigate = useNavigate();

  const [currentFilterValues, setCurrentFilterValues] = useState({});

  const methods = useForm({
    mode: "onBlur",
  });

  const {getValues} = methods;
  const maxAllowedEditParamsMonth =
    process.env.REACT_APP_COST_CALC_MAX_ALLOWED_PARAMS_EDIT_MONTH || 2;

  const {data: filterData} = useQuery(COPRO_EU_COST_CALCULATION_FILTER, {
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });

  const defaultVal =
    filterData?.bioLcCoproCostCalculationDynamicFilter?.data?.filter((d) => {
      return (
        d.fifoYear === currentYear.toString() &&
        d.fifoMonth === (currentMonthNumber - 1).toString()
      );
    });

  const [consumedQuantityData, setConsumedQuantityData] = useState([]);
  const [consumedQuantityDataLabel, setConsumedQuantityDataLabel] =
    useState("");
  const [calculationBreakdownData, setCalculationBreakdownData] = useState([]);
  const [totalCoprocessingCostData, setTotalCoprocessingCostData] = useState(
    [],
  );
  const [totalCoprocessingCostDataLabel, setTotalCoprocessingCostDataLabel] =
    useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [paramsButtonHeader, setParamsButtonHeader] = useState(
    DEFAULT_ADD_COST_PARAMETERS_HEADER,
  );

  // Inside your component
  const location = useLocation();
  const {
    periodMonthReceived,
    periodYearReceived,
    materialShortNameReceived,
    currencyUnitReceived,
    unitRateReceived,
  } = location.state || {};

  let fifoMonth = "";
  let fifoYear = "";
  let materialId = "";
  let currency = "";
  let unitRateBasedOnRatio = "";

  if (
    periodMonthReceived !== undefined &&
    periodYearReceived !== undefined &&
    materialShortNameReceived !== undefined &&
    currencyUnitReceived !== undefined &&
    unitRateReceived !== undefined
  ) {
    fifoMonth = periodMonthReceived || defaultVal?.[0]?.fifoMonth || "";
    fifoYear = periodYearReceived || defaultVal?.[0]?.fifoYear || "";
    const matchingMaterial =
      filterData?.bioLcCoproCostCalculationDynamicFilter?.data?.find(
        (item) =>
          item.fifoMonth === periodMonthReceived &&
          item.fifoYear === periodYearReceived &&
          item?.materialShortName === materialShortNameReceived,
      );
    materialId = matchingMaterial ? matchingMaterial.materialId : "";
    currency =
      currencyUnitReceived ||
      (defaultVal?.[0]?.currencyUnit ?? DEFAULT_CURRENCY_EURO);
    unitRateBasedOnRatio = unitRateReceived;
  } else {
    fifoMonth = defaultVal?.[0]?.fifoMonth || "";
    fifoYear = defaultVal?.[0]?.fifoYear || "";
    materialId = defaultVal?.[0]?.materialId || "";
    currency = defaultVal?.[0]?.currencyUnit ?? DEFAULT_CURRENCY_EURO;
    unitRateBasedOnRatio = unitRateReceived;
  }

  const [tableLoading, setTableLoading] = useState(true);

  const {
    data: tableData,
    loading,
    error,
    refetch,
  } = useQuery(COPRO_EU_COST_CALCULATION_DETAILS, {
    variables: {
      fifoMonth,
      fifoYear,
      materialId,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      currency,
      unitRateBasedOnRatio: extractRatio(unitRateBasedOnRatio),
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      filterData === undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    setTableLoading(!(filterData && tableData));
  }, [filterData, tableData]);

  const getMaterialIdList = (month, year) => {
    return Array.from(
      new Set(
        filterData?.bioLcCoproCostCalculationDynamicFilter?.data
          ?.filter((item) => {
            return item.fifoMonth === month && item.fifoYear === year;
          })
          .map((item) => item.materialId),
      ),
    );
  };

  useEffect(() => {
    // Reset state variables
    setConsumedQuantityData([]);
    setConsumedQuantityDataLabel("");
    setCalculationBreakdownData([]);
    setTotalCoprocessingCostData([]);
    setTotalCoprocessingCostDataLabel("");
    setCurrencySymbol("");

    let fifoMonthVal = "";
    let fifoYearVal = "";
    let materialIdVal = "";
    let materialShortNameVal = "";
    let currencyVal = "";
    let unitRateVal = "";

    if (
      periodMonthReceived !== undefined &&
      periodYearReceived !== undefined &&
      materialShortNameReceived !== undefined &&
      currencyUnitReceived !== undefined &&
      unitRateReceived !== undefined
    ) {
      fifoMonthVal = periodMonthReceived;
      fifoYearVal = periodYearReceived;
      const matchingMaterial =
        filterData?.bioLcCoproCostCalculationDynamicFilter?.data?.find(
          (item) =>
            item.fifoMonth === fifoMonthVal &&
            item.fifoYear === fifoYearVal &&
            item?.materialShortName === materialShortNameReceived,
        );
      materialIdVal = matchingMaterial?.materialId;
      materialShortNameVal = matchingMaterial?.materialShortName;
      currencyVal = currencyUnitReceived;
      unitRateVal = unitRateReceived;
    } else {
      fifoMonthVal = defaultVal?.[0]?.fifoMonth || "";
      fifoYearVal = defaultVal?.[0]?.fifoYear || "";
      materialIdVal = defaultVal?.[0]?.materialId || "";
      materialShortNameVal = defaultVal?.[0]?.materialShortName || "";
      currencyVal = defaultVal?.[0]?.currencyUnit || DEFAULT_CURRENCY_EURO;
      unitRateVal = "Consumption";
    }

    setCurrentFilterValues((state) => ({
      ...state,
      periodMonth: fifoMonthVal,
      periodYear: fifoYearVal,
      materialShortName: materialShortNameVal,
      materialId: materialIdVal,
      currencyUnit: currencyVal,
      unitRate: unitRateVal,
    }));

    if (!loading && !error && tableData) {
      setConsumedQuantityData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.consumedQuantity
          ?.values,
      );
      setConsumedQuantityDataLabel(
        tableData?.bioLcCoproCostCalculationDetail?.data?.consumedQuantity
          ?.label,
      );
      setCalculationBreakdownData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.calculationBreakdown,
      );
      setTotalCoprocessingCostData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.totalCoprocessingCost
          .values,
      );
      setTotalCoprocessingCostDataLabel(
        tableData?.bioLcCoproCostCalculationDetail?.data?.totalCoprocessingCost
          .label,
      );
      setCurrencySymbol(
        tableData?.bioLcCoproCostCalculationDetail?.data?.currencySymbol,
      );

      const fifoMonthNumber = parseFloat(currentFilterValues.periodMonth);
      const fifoYearNumber = parseFloat(currentFilterValues.periodYear);

      if (
        currentYear > fifoYearNumber ||
        currentMonthNumber - fifoMonthNumber >= maxAllowedEditParamsMonth
      ) {
        setParamsButtonHeader(DEFAULT_REVIEW_COST_PARAMETERS_HEADER);
        setParamsEditable(false);
      } else if (
        tableData?.bioLcCoproCostCalculationDetail?.data?.coproCostParamsExists
      ) {
        setParamsEditable(true);
        setParamsButtonHeader(DEFAULT_EDIT_COST_PARAMETERS_HEADER);
      } else {
        setParamsButtonHeader(DEFAULT_ADD_COST_PARAMETERS_HEADER);
        setParamsEditable(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, tableData]);

  const handleFilterSubmit = async () => {
    const {periodMonth, periodYear, materialShortName, currencyUnit, unitRate} =
      getValues();

    let materialIdValCreated = "";
    const selectedMaterial =
      filterData?.bioLcCoproCostCalculationDynamicFilter?.data?.find(
        (item) => item.materialShortName === materialShortName,
      );

    if (selectedMaterial) {
      materialIdValCreated = selectedMaterial.materialId;
    }

    await refetch({
      fifoMonth: periodMonth,
      fifoYear: periodYear,
      materialId: materialIdValCreated,
      currency: currencyUnit,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      unitRateBasedOnRatio: extractRatio(unitRate),
      awaitRefetchQueries: true,
    });

    setCurrentFilterValues((state) => ({
      ...state,
      periodMonth,
      periodYear,
      materialShortName,
      materialId: materialIdValCreated,
      currencyUnit,
      unitRate,
    }));
  };

  useEffect(() => {
    if (!loading && !error && tableData) {
      setConsumedQuantityData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.consumedQuantity
          ?.values,
      );
      setConsumedQuantityDataLabel(
        tableData?.bioLcCoproCostCalculationDetail?.data?.consumedQuantity
          ?.label,
      );
      setCalculationBreakdownData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.calculationBreakdown,
      );
      setTotalCoprocessingCostData(
        tableData?.bioLcCoproCostCalculationDetail?.data?.totalCoprocessingCost
          .values,
      );
      setTotalCoprocessingCostDataLabel(
        tableData?.bioLcCoproCostCalculationDetail?.data?.totalCoprocessingCost
          .label,
      );
      setCurrencySymbol(
        tableData?.bioLcCoproCostCalculationDetail?.data?.currencySymbol,
      );

      const fifoMonthNumber = parseFloat(currentFilterValues.periodMonth);
      const fifoYearNumber = parseFloat(currentFilterValues.periodYear);

      if (
        currentYear > fifoYearNumber ||
        currentMonthNumber - fifoMonthNumber >= maxAllowedEditParamsMonth
      ) {
        setParamsButtonHeader(DEFAULT_REVIEW_COST_PARAMETERS_HEADER);
        setParamsEditable(false);
      } else if (
        tableData?.bioLcCoproCostCalculationDetail?.data?.coproCostParamsExists
      ) {
        setParamsEditable(true);
        setParamsButtonHeader(DEFAULT_EDIT_COST_PARAMETERS_HEADER);
      } else {
        setParamsButtonHeader(DEFAULT_ADD_COST_PARAMETERS_HEADER);
        setParamsEditable(true);
      }
    }
  }, [
    tableData,
    loading,
    error,
    currentFilterValues,
    currentMonthNumber,
    currentYear,
    maxAllowedEditParamsMonth,
  ]);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Spain", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Cost calculation", link: "/copro-spain/spain/cost-calculation"},
  ];

  return (
    <Layout
      title="Cost calculation"
      className="cost-calc-layout"
      breadcrumbs={breadcrumbItems}
    >
      <div className="flex flex-col justify-stretch w-full items-start px-10 cost-calculation-details">
        <div className="flex flex-row justify-between w-full">
          <FormProvider {...methods}>
            <Form id="cost-calculation-filter-form">
              <CostCalculationFilterContent
                defaultValues={currentFilterValues}
                filterDynamicData={
                  filterData?.bioLcCoproCostCalculationDynamicFilter?.data ?? []
                }
                handleFilterSubmit={handleFilterSubmit}
                setFeedstockList={setFeedstockList}
              ></CostCalculationFilterContent>
            </Form>
          </FormProvider>
          <div className="flex align-middle items-center ">
            <Button
              data-test="copro-fifo-download-to-xlsx-button"
              id="add-cost-parameters"
              name="add-cost-parameters"
              color="primary"
              type="secondary"
              className={`float-right rounded-0 text-gray-400 h-10 mt-8 inline-block items-center ${
                tableLoading ||
                !tableData?.bioLcCoproCostCalculationDetail?.data
                  ?.coproConsumedQuantityExists
                  ? "no-consumption-data-header"
                  : ""
              }`}
              size="md"
              onClick={() => {
                navigate(
                  "/copro-spain/spain/cost-calculation/add-cost-parameters",
                  {
                    state: {
                      periodMonth: currentFilterValues?.periodMonth,
                      periodYear: currentFilterValues?.periodYear,
                      materialShortName: currentFilterValues?.materialShortName,
                      feedstockList,
                      materialIdList: getMaterialIdList(
                        currentFilterValues?.periodMonth,
                        currentFilterValues?.periodYear,
                      ),
                      paramsEditable,
                      currencyUnit: currentFilterValues?.currencyUnit,
                      unitRate: currentFilterValues?.unitRate,
                    },
                  },
                );
              }}
            >
              {paramsButtonHeader}
            </Button>
          </div>
        </div>
        <SplitTable
          defaultValues={currentFilterValues}
          consumedQuantityData={consumedQuantityData || []}
          consumedQuantityDataLabel={consumedQuantityDataLabel || ""}
          calculationBreakdownData={calculationBreakdownData || []}
          totalCoprocessingCostData={totalCoprocessingCostData || []}
          totalCoprocessingCostDataLabel={totalCoprocessingCostDataLabel || ""}
          currencySymbol={currencySymbol || ""}
          loading={tableLoading}
        />
      </div>
    </Layout>
  );
};

export default CostCalculationLanding;
