import {OPERATION} from "modules/forecasting/constants/inventory";
import {isCurrentYear} from "./dateUtils";

export default function getQueryVariables(year) {
  return isCurrentYear(year)
    ? {
        op: OPERATION.CURRENT,
      }
    : {
        op: OPERATION.YEAR,
        year,
      };
}

export const logFetchDefaults = (logParams) => {
  const {
    group,
    region,
    search_text = "",
    fetch_after = null,
    offset = 0,
    forecast_type = null,
  } = logParams;

  return {
    group,
    region,
    search_text,
    fetch_after,
    offset,
    forecast_type,
  };
};
