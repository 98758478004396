import PropTypes from "prop-types";
import * as msal from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {Spin} from "antd";
import {useEffect} from "react";

const MSAL_CONFIG = {
  auth: {
    clientId: process.env.REACT_APP_AD_CLIENTID,
    authority: process.env.REACT_APP_AD_AUTHORITY,

    redirectUri: process.env.REACT_APP_AD_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_AD_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const LOGIN_REQUEST = {
  scopes: [process.env.REACT_APP_AD_CLIENTID_SCOPE],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/",
};

export const msalInstance = new msal.PublicClientApplication(MSAL_CONFIG);

export const Msal = ({children}) => {
  // This component is responsible to handle the authentication flow

  useEffect(() => {
    const asyncFn = async () => {
      await msalInstance.handleRedirectPromise();

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        await msalInstance.loginRedirect();
      }
    };

    asyncFn().catch((e) => console.error(e));
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="m-auto">
          <Spin />
        </div>
      </UnauthenticatedTemplate>
    </MsalProvider>
  );
};

Msal.propTypes = {
  children: PropTypes.node.isRequired,
};
