import {gql} from "@apollo/client";

// Fetch query for product default values table on Config center(/configuration-center/product-default-values)
export const PRODUCT_DEFAULT_VALUES_DATA = gql`
  query bioLcGetProductDefaultValuesData {
    bioLcGetProductDefaultValuesData(event: {}) {
      productDefaultValuesData {
        productDvId
        materialId
        materialCode
        materialShortName
        defaultValue
        defaultValueType
        uomCode
        validFrom
        validTo
        valid_status
        divisionId
        siteReferenceId
        countryCode
        countryId
        divisionCode
      }
    }
  }
`;

export default {PRODUCT_DEFAULT_VALUES_DATA};
