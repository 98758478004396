import React, {useState} from "react";
import Modal from "modules/common/Modal";
import {useMutation} from "@apollo/client";
import PropTypes from "prop-types";
import Button from "modules/common/Button";
import {toast} from "react-toastify";
import Select from "modules/common/Select";
import {useUserSettings} from "providers/userSettings";
import FormField from "modules/common/FormField";
import {SET_USER_SETTINGS} from "graphql/userSettings";

const SettingsModal = ({title, visible, onCancel, showCrossArrow = true}) => {
  const {
    userSettings: {dateFormat, decimalFormat},
    setUserSettings,
  } = useUserSettings();

  const [mutateUserSettings] = useMutation(SET_USER_SETTINGS, {
    onCompleted: () => {
      toast.success("Successfully updated");
      onCancel();
    },
    onError: () => {
      onCancel();
    },
  });

  const [newDateFormat, setNewDateFormat] = useState(dateFormat);
  const [newDecimalFormat, setNewDecimalFormat] = useState(decimalFormat);

  const submitUserSettings = () => {
    mutateUserSettings({
      variables: {
        date_format: newDateFormat,
        decimal_format: newDecimalFormat,
      },
    });
    setUserSettings({
      dateFormat: newDateFormat,
      decimalFormat: newDecimalFormat,
    });
  };

  return (
    <Modal
      data-test="success-modal"
      visible={visible}
      title={title}
      footer={null}
      onCancel={onCancel}
      closable={showCrossArrow}
      destroyOnClose
      width="500px"
    >
      <div className="grid grid-cols-1 gap-x-4 gap-y-4">
        <FormField label="Date Format">
          <Select
            size="large"
            name="dateFormat"
            data-test="dateFormat"
            className="w-full"
            value={newDateFormat}
            onChange={(value) => {
              setNewDateFormat(value);
            }}
          >
            {["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD"].map((item) => {
              return (
                <Select.Option
                  key={`dateFormat-${item}`}
                  data-test="dateFormat"
                  value={item}
                >
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </FormField>
        <FormField label="Decimal Format">
          <Select
            size="large"
            name="decimalFormat"
            data-test="decimalFormat"
            className="w-full"
            value={newDecimalFormat}
            onChange={(value) => {
              setNewDecimalFormat(value);
            }}
          >
            {["Imperial Style", "European Style"].map((item) => {
              return (
                <Select.Option
                  key={`decimalFormat-${item}`}
                  data-test="decimalFormat"
                  value={item}
                >
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </FormField>
      </div>
      <div className="flex flex-row items-start w-full gap-5 pt-8 mt-10 sm:flex-col md:flex-row lg:flex-row xl:flex-row  border-t border-gray-100">
        <Button
          data-test="settings-save-changes"
          onClick={submitUserSettings}
          className=""
        >
          Save changes
        </Button>
        <Button type="secondary" data-test="settings-cancel" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

SettingsModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  showCrossArrow: PropTypes.bool,
};

export default SettingsModal;
