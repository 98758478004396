import PropTypes from "prop-types";
import {useCallback} from "react";
import {isEmpty} from "lodash";
import {
  creditsTable,
  deficitsTable,
  isSpecificForecastBeyondNextMonth,
} from "./toPublishData";

const Header = ({
  label,
  credits,
  deficits,
  region,
  comments,
  isTrueUp,
  updateType,
}) => {
  const regionName = region === "ec" ? "East Coast" : "West Coast";

  const creditsAvailable = isTrueUp
    ? !isEmpty(credits)
    : !isSpecificForecastBeyondNextMonth({forecast: credits, updateType});
  const deficitsAvailable = isTrueUp
    ? !isEmpty(deficits)
    : !isSpecificForecastBeyondNextMonth({forecast: deficits, updateType});

  return (
    <div className="inline-block">
      <div
        className="my-4"
        style={{maxWidth: "fit-content"}}
        data-test="to-publish-email-header-region"
      >
        <p>{`Included below are the ${label} orders for:`}</p>
        {creditsAvailable ? (
          <p className="ml-4">• {regionName}-RFS-Credits</p>
        ) : null}
        {deficitsAvailable ? (
          <p className="ml-4">• {regionName}-RFS-Deficits</p>
        ) : null}
      </div>
      <div className="my-4" style={{maxWidth: "fit-content"}}>
        <p>
          Please respond with the deal numbers confirming the order has been
          submitted.
        </p>
        <p className="mt-4">Note:</p>
        <p className="ml-4">• For Negative values, C&P Buys from T&S.</p>
        <p className="ml-4">• For Positive values, C&P Sells to T&S.</p>
      </div>

      <div className="my-4" style={{maxWidth: "fit-content"}}>
        <p className="mt-4 font-bold">Change Summary:</p>
        <p data-test="to-publish-email-comments">{comments}</p>
      </div>
    </div>
  );
};

Header.propTypes = {
  label: PropTypes.string,
  credits: PropTypes.array,
  deficits: PropTypes.array,
  region: PropTypes.string,
  comments: PropTypes.string,
  isTrueUp: PropTypes.bool,
  updateType: PropTypes.string,
};

const ToPublishEmailTables = ({
  dataByType,
  obligations,
  monthsByType,
  pageCount,
  currentPage,
  region,
  updateType,
  comments,
}) => {
  const generateTable = useCallback(
    (data, months, isTrueUp, tableType) => {
      if (!data || data.length === 0) {
        return null;
      }

      return tableType === "credits"
        ? creditsTable({
            data,
            obligations,
            months,
            isTrueUp,
            region,
            updateType,
          })
        : deficitsTable({
            data,
            obligations,
            months,
            isTrueUp,
            region,
            updateType,
          });
    },
    [region, updateType, obligations],
  );

  const {creditsTrueUp, creditsForecast, deficitsTrueUp, deficitsForecast} =
    dataByType;
  const {trueUpMonths, forecastMonths} = monthsByType;

  const trueUps = () => {
    if (!creditsTrueUp?.length && !deficitsTrueUp?.length) {
      return null;
    }
    return (
      <>
        <Header
          label="True-Up"
          credits={creditsTrueUp}
          deficits={deficitsTrueUp}
          region={region}
          comments={comments}
          isTrueUp
          updateType={updateType}
        />
        {generateTable(creditsTrueUp, trueUpMonths, true, "credits")}
        {generateTable(deficitsTrueUp, trueUpMonths, true, "deficits")}
      </>
    );
  };

  const forecasts = () => {
    if (!creditsForecast?.length && !deficitsForecast?.length) {
      return null;
    }
    return (
      <>
        <Header
          label="Forecast"
          credits={creditsForecast}
          deficits={deficitsForecast}
          region={region}
          comments={comments}
          isTrueUp={false}
          updateType={updateType}
        />
        {generateTable(creditsForecast, forecastMonths, false, "credits")}
        {generateTable(deficitsForecast, forecastMonths, false, "deficits")}
      </>
    );
  };

  if (pageCount === 1) {
    return trueUps() || forecasts();
  }

  if (currentPage === 1) {
    return trueUps();
  }
  return forecasts();
};

ToPublishEmailTables.propTypes = {
  dataByType: PropTypes.object,
  obligations: PropTypes.array,
  monthsByType: PropTypes.object,
  pageCount: PropTypes.number,
  currentPage: PropTypes.number,
  region: PropTypes.string,
  updateType: PropTypes.string,
  comments: PropTypes.string,
};

export default ToPublishEmailTables;
