import tw, {styled} from "twin.macro";

export const ShipmentLabel = styled.div(({className}) => [
  className && tw`${className}`,
]);

export const ShipmentField = styled.div(({className}) => [
  tw`flex items-baseline text-[13px] font-[400]`,
  className && tw`${className}`,
]);

export const ShipmentValue = tw.div`text-[#111111] pl-3 text-opacity-[0.64]`;
