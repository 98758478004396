import {Alert24, Alert32, Info32} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import "./index.scss";

const Subtitle = ({status, invalidTabs, onClick, className}) => {
  return (
    <div
      className={`flex flex-col justify-between items-start gap-2 small pb-6 ${className}`}
    >
      {status === "attention" && (
        <>
          <span className="mb-2">
            Based on this declaration we have been unable to gather some
            expected information.
          </span>
          <span className="mb-2">
            If you find the information is available from the PDF please confirm
            and add a note to explain your decision. This will help us learn and
            improve this feature in the future.
          </span>
        </>
      )}
      {status === "ready_for_mb" && (
        <>
          <span className="mb-2">
            We have successfully captured all information, and this declaration
            meets criteria for acceptance.
          </span>
          <span className="mb-2">
            You now have the opportunity to review, add additional information
            if required
          </span>
        </>
      )}
      {status === "attention-split" && (
        <>
          <span className="mb-2">
            Please be aware that declaration has multiple GHG entries. Please
            review and confirm.
          </span>
          <span className="mb-2">
            Any required splits can be managed from the incoming document
            manager page once this document has been accepted.
          </span>
        </>
      )}
      <span className="mb-2">
        This document has been accepted and assigned to a related mass balance.
      </span>
      <span className="mb-2">
        You can add additional information, such as invoice number or vessel
        name, below.
      </span>
      <span className="fw-bold mt-6">Key points to review:</span>
      <div className="flex flex-col gap-0">
        {invalidTabs.supplier && (
          <div className="flex flex-row">
            <Alert32 className="btn-icon-prefix icon-requires--attention" />
            <Button
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("supplier")}
            >
              <span>Supplier and recipient information</span>
            </Button>
          </div>
        )}
        {invalidTabs.general && (
          <div className="flex flex-row">
            <Alert32 className="btn-icon-prefix icon-requires--attention" />
            <Button
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("general")}
            >
              <span>General information</span>
            </Button>
          </div>
        )}
        {invalidTabs.emission && (
          <div className="flex flex-row">
            <Alert32 className="btn-icon-prefix icon-requires--attention" />
            <Button
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("emission")}
            >
              <span>Greenhouse gas (GHG) emission information</span>
            </Button>
          </div>
        )}
        {invalidTabs.certification && (
          <div className="flex flex-row">
            <Alert32 className="btn-icon-prefix icon-requires--attention" />
            <Button
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("certification")}
            >
              <span>Scope of certification of raw material</span>
            </Button>
          </div>
        )}
        {invalidTabs.additionalInfo && (
          <div className="flex flex-row">
            <Alert32 className="btn-icon-prefix icon-requires--attention" />
            <Button
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("additionalInfo")}
            >
              <span>Additional information</span>
            </Button>
          </div>
        )}
        {!invalidTabs.additionalInfo && (
          <div className="flex flex-row">
            <Info32 className="btn-icon-prefix alert-icon--info" />
            <Button
              id="test"
              color="standard-quartenary !pl-0.5"
              size="sm"
              onClick={() => onClick("additionalInfo")}
            >
              <span>Additional information</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
Subtitle.propTypes = {
  status: PropTypes.string,
  invalidTabs: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default Subtitle;
