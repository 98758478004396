import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {DELETE_BATCH_BY_ID} from "graphql/coprocessing/batches";
import {DELETE_POPOVER, OPERATIONS, ERRORS} from "../../constants/batches";
import {useRefetch} from "../../context/RefetchContext";
import DeletePopover from "../Shared/DeletePopover";

const BatchesDeletePopover = ({showPopover, setShowPopover, batchID}) => {
  const {refetchBatches} = useRefetch();

  const [deleteBatch] = useLazyQuery(DELETE_BATCH_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        setTimeout(() => {
          refetchBatches();
        }, 500);
      } else {
        toast.error(
          result?.bioLcCoproUsBatchesApi?.error || ERRORS.FAILED_DELETE,
        );
      }
    },
  });

  const handleDelete = () => {
    deleteBatch({variables: {op: OPERATIONS.DELETE, batch_id: batchID}});
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="batches-kebab-menu"
      header={DELETE_POPOVER.HEADER}
      message={DELETE_POPOVER.MESSAGE}
      cancel={DELETE_POPOVER.CANCEL}
      confirm={DELETE_POPOVER.YES}
      idToDelete={batchID}
    />
  );
};

BatchesDeletePopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  batchID: PropTypes.string,
};

export default BatchesDeletePopover;
