import "modules/common/Menu/index.scss";
import React from "react";
import PropTypes from "prop-types";
import {checkDataReturnEmpty} from "utils/helpers/checkData";
import CheckboxFilter from "../common/Filter/CheckBoxFilter";
import CheckboxFilterGroup from "../common/Filter/CheckboxFilterGroup";
import {getMasterFilter, getUniqueList} from "./posUKFilterHelpers";

const PosDashboardFilter = ({
  activefilters,
  setActiveFilters,
  masterFilter,
  setFilterOptions,
  defaultFilterOptions,
  isNoActiveFilter,
  filterOptions,
  fuelMap,
  feedstockMap,
  locationMap,
  setPage,
  refetch,
  activeFiltersDefault,
  setMasterFilter,
  defaultFilterMap,
  filterLoading,
}) => {
  const handleFilterKeyFuelType = (
    filterKey,
    currentMasterFilter,
    filterOptionsItems,
    previousLocationOptions,
    fuelMapItems,
    filterOption,
    previousFeedstockTypeOptions,
  ) => {
    if (filterKey === "fuelType" && currentMasterFilter === "fuelType") {
      setFilterOptions({
        ...filterOptionsItems,
        locationOptions: getUniqueList([
          ...previousLocationOptions,
          ...fuelMapItems[filterOption].location,
        ]),
        feedstockTypeOptions: getUniqueList([
          ...previousFeedstockTypeOptions,
          ...fuelMapItems[filterOption].feedstockType,
        ]),
      });
    }
  };

  const handleFilterKeyFeedstockType = (
    filterKey,
    currentMasterFilter,
    filterOptionsItems,
    previousLocationOptions,
    feedstockMapItems,
    filterOption,
    previousFuelTypeOptions,
  ) => {
    if (
      filterKey === "feedstockType" &&
      currentMasterFilter === "feedstockType"
    ) {
      setFilterOptions({
        ...filterOptionsItems,
        locationOptions: getUniqueList([
          ...previousLocationOptions,
          ...feedstockMapItems[filterOption].location,
        ]),
        fuelTypeOptions: getUniqueList([
          ...previousFuelTypeOptions,
          ...feedstockMapItems[filterOption].fuelType,
        ]),
      });
    }
  };

  const handleFilterKeyLocation = (
    filterKey,
    currentMasterFilter,
    filterOptionsItems,
    previousFuelTypeOptions,
    locationMapItems,
    filterOption,
    previousFeedstockTypeOptions,
  ) => {
    if (filterKey === "location" && currentMasterFilter === "location") {
      setFilterOptions({
        ...filterOptionsItems,
        fuelTypeOptions: getUniqueList([
          ...previousFuelTypeOptions,
          ...locationMapItems[filterOption].fuelType,
        ]),
        feedstockTypeOptions: getUniqueList([
          ...previousFeedstockTypeOptions,
          ...locationMapItems[filterOption].feedstockType,
        ]),
      });
    }
  };

  const handleFilterOnChange = ({
    filterKey,
    singleActiveFilters,
    checked,
    filterOption,
    allChecked,
  }) => {
    const currentFilters = {
      ...activefilters,
      [filterKey]: singleActiveFilters,
    };

    const currentMasterFilter = masterFilter || getMasterFilter(currentFilters);

    if (checked) {
      if (allChecked && filterKey === currentMasterFilter) {
        setFilterOptions(defaultFilterOptions);
      } else {
        const previousFuelTypeOptions = checkDataReturnEmpty(
          isNoActiveFilter,
          filterOptions?.fuelTypeOptions,
        );
        const previousFeedstockTypeOptions = checkDataReturnEmpty(
          isNoActiveFilter,
          filterOptions?.feedstockTypeOptions,
        );
        const previousLocationOptions = checkDataReturnEmpty(
          isNoActiveFilter,
          filterOptions?.locationOptions,
        );

        handleFilterKeyFuelType(
          filterKey,
          currentMasterFilter,
          filterOptions,
          previousLocationOptions,
          fuelMap,
          filterOption,
          previousFeedstockTypeOptions,
        );

        handleFilterKeyFeedstockType(
          filterKey,
          currentMasterFilter,
          filterOptions,
          previousLocationOptions,
          feedstockMap,
          filterOption,
          previousFuelTypeOptions,
        );

        handleFilterKeyLocation(
          filterKey,
          currentMasterFilter,
          filterOptions,
          previousFuelTypeOptions,
          locationMap,
          filterOption,
          previousFeedstockTypeOptions,
        );
      }

      setActiveFilters(currentFilters);
      setPage(1);
      refetch({
        ...currentFilters,
      });
    } else if (allChecked) {
      setActiveFilters(activeFiltersDefault);
    } else {
      if (filterKey === "location" && currentMasterFilter === "location") {
        const filteredFuelType = filterOptions?.fuelTypeOptions.filter(
          (fuelType) => !locationMap[filterOption].fuelType.includes(fuelType),
        );
        const filteredFeedstockType =
          filterOptions?.feedstockTypeOptions.filter(
            (feedstockType) =>
              !locationMap[filterOption].feedstockType.includes(feedstockType),
          );

        const remaningFuelType = currentFilters.location.flatMap(
          (location) => locationMap[location].fuelType,
        );
        const remaningFeedStockType = currentFilters.location.flatMap(
          (location) => locationMap[location].feedstockType,
        );

        const fuelTypeOptions = getUniqueList([
          ...filteredFuelType,
          ...remaningFuelType,
        ]);
        const feedstockTypeOptions = getUniqueList([
          ...filteredFeedstockType,
          ...remaningFeedStockType,
        ]);

        const availableFuelType = currentFilters.fuelType.filter((fuelType) =>
          fuelTypeOptions.includes(fuelType),
        );
        const availableFeedstockType = currentFilters.feedstockType.filter(
          (feedstockType) => feedstockTypeOptions.includes(feedstockType),
        );

        setFilterOptions({
          ...filterOptions,
          fuelTypeOptions,
          feedstockTypeOptions,
        });

        const newFilters = {
          ...currentFilters,
          fuelType: availableFuelType,
          feedstockType: availableFeedstockType,
        };

        setActiveFilters(newFilters);

        setPage(1);
        refetch({
          ...newFilters,
        });
      }

      if (filterKey === "fuelType" && currentMasterFilter === "fuelType") {
        const filteredFeedstockType =
          filterOptions?.feedstockTypeOptions.filter(
            (feedstockType) =>
              !fuelMap[filterOption].feedstockType.includes(feedstockType),
          );
        const filteredLocation = filterOptions?.locationOptions.filter(
          (location) => !fuelMap[filterOption].location.includes(location),
        );

        const remaningFeedStockType = currentFilters.fuelType.flatMap(
          (fuelType) => fuelMap[fuelType].feedstockType,
        );
        const remaningLocation = currentFilters.fuelType.flatMap(
          (fuelType) => fuelMap[fuelType].location,
        );

        const feedstockTypeOptions = getUniqueList([
          ...filteredFeedstockType,
          ...remaningFeedStockType,
        ]);
        const locationOptions = getUniqueList([
          ...filteredLocation,
          ...remaningLocation,
        ]);

        const availableFeedstockType = currentFilters.feedstockType.filter(
          (feedstockType) => feedstockTypeOptions.includes(feedstockType),
        );
        const availableLocation = currentFilters.location.filter((location) =>
          locationOptions.includes(location),
        );

        setFilterOptions({
          ...filterOptions,
          feedstockTypeOptions,
          locationOptions,
        });

        const newFilters = {
          ...currentFilters,
          feedstockType: availableFeedstockType,
          location: availableLocation,
        };

        setActiveFilters(newFilters);

        setPage(1);
        refetch({
          ...newFilters,
        });
      }

      if (
        filterKey === "feedstockType" &&
        currentMasterFilter === "feedstockType"
      ) {
        const filteredFuelType = filterOptions?.fuelTypeOptions.filter(
          (fuelType) => !feedstockMap[filterOption].fuelType.includes(fuelType),
        );
        const filteredLocation = filterOptions?.locationOptions.filter(
          (location) => !feedstockMap[filterOption].location.includes(location),
        );

        const remainingFuelType = currentFilters.feedstockType.flatMap(
          (feedstockType) => feedstockMap[feedstockType].fuelType,
        );
        const remainingLocation = currentFilters.feedstockType.flatMap(
          (feedstockType) => feedstockMap[feedstockType].location,
        );

        const fuelTypeOptions = getUniqueList([
          ...filteredFuelType,
          ...remainingFuelType,
        ]);
        const locationOptions = getUniqueList([
          ...filteredLocation,
          ...remainingLocation,
        ]);

        const availableFuelType = currentFilters.fuelType.filter((fuelType) =>
          fuelTypeOptions.includes(fuelType),
        );
        const availableLocation = currentFilters.fuelType.filter((fuelType) =>
          locationOptions.includes(fuelType),
        );

        setFilterOptions({
          ...filterOptions,
          fuelTypeOptions,
          locationOptions,
        });

        const newFilters = {
          ...currentFilters,
          fuelType: availableFuelType,
          location: availableLocation,
        };

        setActiveFilters(newFilters);

        setPage(1);
        refetch({
          ...newFilters,
        });
      }
    }

    if (!currentFilters[currentMasterFilter].length) {
      setFilterOptions(defaultFilterOptions);
      setMasterFilter("");
    }

    if (!currentMasterFilter) {
      setActiveFilters(activeFiltersDefault);
      setMasterFilter("");
    }

    if (!masterFilter) {
      setMasterFilter(currentMasterFilter);
    }

    if (currentMasterFilter !== filterKey) {
      setActiveFilters(currentFilters);
      setPage(1);
      refetch({
        ...currentFilters,
      });
    }
  };
  return (
    <CheckboxFilterGroup defaultFilterMap={defaultFilterMap}>
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={["ISCC"]}
        activeFilters={["ISCC"]}
        dataKey="certificateType"
        label="Type of Certificate"
        loading={filterLoading}
        isNotActive
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={["BPOI"]}
        activeFilters={["BPOI"]}
        dataKey="supplier"
        label="Supplier"
        loading={filterLoading}
        isNotActive
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions?.fuelTypeOptions}
        activeFilters={activefilters.fuelType}
        dataKey="fuelType"
        label="Fuel Type"
        loading={filterLoading}
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions?.feedstockTypeOptions}
        activeFilters={activefilters.feedstockType}
        dataKey="feedstockType"
        label="Feedstock Type"
        loading={filterLoading}
      />
      <CheckboxFilter
        onChange={handleFilterOnChange}
        data={filterOptions?.locationOptions}
        activeFilters={activefilters.location}
        dataKey="location"
        label="Location"
        loading={filterLoading}
      />
    </CheckboxFilterGroup>
  );
};

PosDashboardFilter.propTypes = {
  activefilters: PropTypes.any,
  setActiveFilters: PropTypes.any,
  masterFilter: PropTypes.any,
  setFilterOptions: PropTypes.any,
  defaultFilterOptions: PropTypes.any,
  isNoActiveFilter: PropTypes.any,
  filterOptions: PropTypes.any,
  fuelMap: PropTypes.any,
  feedstockMap: PropTypes.any,
  locationMap: PropTypes.any,
  refetch: PropTypes.any,
  setPage: PropTypes.any,
  activeFiltersDefault: PropTypes.any,
  setMasterFilter: PropTypes.any,
  defaultFilterMap: PropTypes.any,
  filterLoading: PropTypes.any,
};

export default PosDashboardFilter;
