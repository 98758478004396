import {sortBy, uniq} from "lodash";
import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects, {
  useRuleValidateOutputColumns,
} from "./validationError";

const EnergyToActualEmission = ({
  selectedItem,
  selectDatasetList,
  selectedDataset,
  selectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const inputColumnOptions =
    selectDatasetList?.find((x) => x.dataset === selectedDataset)?.schema || [];

  const numericColumnOptions = inputColumnOptions
    ?.filter((obj) => obj.type === "numeric")
    ?.map((obj) => obj.column);

  // get configuration data from previous blocks
  const configurationData = selectedTemplateItems
    .filter((x) => x.templateCount < selectedItem?.templateCount)
    ?.map((item) => item.configuration);

  // get output columns from previous blocks
  const outputColumns = configurationData
    ?.map((config) => config?.output_column)
    ?.filter(
      (output) => output !== undefined && output !== null && output !== "",
    );

  const numericAndOutputColumns = sortBy(
    uniq([...numericColumnOptions, ...outputColumns]),
  );

  const stringColumnOptions = inputColumnOptions?.filter(
    (obj) => obj.type === "string",
  );

  const watchEAEInputColumn = watch("input_column");
  const watchEAEProductHierarchyLevelColumn = watch("prod_hrc_column");
  const watchEAEOutputColumnName = watch("output_column");

  useRuleValidateOutputColumns({
    field: "output_column",
    value: watchEAEOutputColumnName,
    selectedTemplateItems,
    clearErrors,
    setError,
    setFocus,
  });

  useEffect(() => {
    updateConfigurationInBlocks(selectedItem, {
      input_column: watchEAEInputColumn,
      prod_hrc_column: watchEAEProductHierarchyLevelColumn,
      output_column: watchEAEOutputColumnName,
    });
  }, [
    selectedItem,
    watchEAEInputColumn,
    watchEAEProductHierarchyLevelColumn,
    watchEAEOutputColumnName,
    updateConfigurationInBlocks,
  ]);

  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      setValue("input_column", selectedItem?.configuration?.input_column);
      setValue("prod_hrc_column", selectedItem?.configuration?.prod_hrc_column);
      setValue("output_column", selectedItem?.configuration?.output_column);
    } else {
      setValue("input_column", "");
      setValue("prod_hrc_column", "prod_hier_lvl_3");
      setValue("output_column", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="input_column" className="fw-normal mb-4">
          Input Column
        </Label>
        <Input
          type="select"
          id="input_column"
          data-test="input_column"
          {...computeProps("input_column", {
            required: "Please select a input column",
          })}
          invalid={!!errors.input_column}
          disabled={isDisabled}
        >
          <option value="">Select input column</option>
          {numericAndOutputColumns?.map((inputColumnOption) => (
            <option key={inputColumnOption} value={inputColumnOption}>
              {inputColumnOption}
            </option>
          ))}
        </Input>
        {errors.input_column && (
          <FormFeedback className="mt-2">
            {errors.input_column?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="prod_hrc_column" className="fw-normal mb-4">
          Product hierarchy level column
        </Label>
        <Input
          type="select"
          id="prod_hrc_column"
          data-test="prod_hrc_column"
          {...computeProps("prod_hrc_column", {
            required: "Please select a product hierarchy level column",
          })}
          invalid={!!errors.prod_hrc_column}
          disabled={isDisabled}
        >
          <option value="">Select product hierarchy level column</option>
          {stringColumnOptions?.map((inputColumnOption) => (
            <option
              key={inputColumnOption?.column}
              value={inputColumnOption?.column}
            >
              {inputColumnOption?.column}
            </option>
          ))}
        </Input>
        {errors.prod_hrc_column && (
          <FormFeedback className="mt-2">
            {errors.prod_hrc_column?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="output_column" className="fw-normal">
          Output column name
        </Label>
        <Input
          type="text"
          id="output_column"
          {...computeProps("output_column", {
            required: "Please enter name",
            pattern: {
              value: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
              message:
                "Column name must start with a letter or underscore, and can only contain letters, digits, and underscores",
            },
          })}
          invalid={!!errors.output_column}
          maxLength={31}
          placeholder="Enter name"
          disabled={isDisabled}
        />
        {errors.output_column && (
          <FormFeedback>{errors.output_column.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

EnergyToActualEmission.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  selectedDataset: PropTypes.string,
  selectedTemplateItems: PropTypes.array,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default EnergyToActualEmission;
