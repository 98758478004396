import {gql} from "@apollo/client";

// Fetch query for Certificate scheme variation table on Config center(/configuration-center/certificate-scheme-variation)
export const CERTIFICATE_SCHEME_VARIATION_GET_QUERY = gql`
  query bioLcGetCertificationSchemeVariationData {
    bioLcGetCertificationSchemeVariationData(event: {}) {
      certificationSchemeVariationData {
        certificationSchemeVariationId
        certificationSchemeId
        certificationSchemeName
        schemeVariationType
        schemeVariationValue
        validFrom
        validTo
        divisionId
        siteReferenceId
      }
    }
  }
`;

export default {CERTIFICATE_SCHEME_VARIATION_GET_QUERY};
