import * as yup from "yup";

export const posEditSchema = yup.object().shape({}).optional();

export const posNLISCCSchema = yup.object().shape({
  uniquePoSNumber: yup.number().label("Unique PoS number"),
  dateOfIssuance: yup.string().label("Date of Issuance of PoS"),
  dateOfDispatchMaterial: yup.string().label("Date of dispatch of material"),
  contractNumber: yup.string().label("Contract number"),
  recipient: yup.string().label("Recipient"),
  recipientAddress: yup.string().label("Recipient address"),
  typeOfProduct: yup.string().label("Type of Product"),
  typeOfRawMaterial: yup.string().label("Type of Raw material"),
  countryOfOrigin: yup.string().label("Country of Origin"),
  quantitym3C: yup.string().label("Quantity m3/15* C"),
  energyContentMJ: yup.string().label("Energy content (MJ)"),
  euRedCompliant: yup.string().label("EU Red Compliant"),
  art29RedII: yup.string().label("Art 29 (2) - (7) RED II"),
  meetsDefinitionWasteResidue: yup
    .string()
    .label("Meets definition of waste/Residue"),
  cultivatedIntermediateCrop: yup
    .string()
    .label("Cultivated as intermediate crop"),
  ilucRiskFeedstock: yup.string().label("ILUC Risk feedstock"),
  ghgValue: yup.string().label("GHG Value %"),
  ghgEec: yup.string().label("GHG - EeC"),
  ghgEl: yup.string().label("GHG - El"),
  ghgEp: yup.string().label("GHG - Ep"),
  ghgEtd: yup.string().label("GHG - Etd"),
  ghgEu: yup.string().label("GHG - Eu"),
  ghgEcsa: yup.string().label("GHG - Ecsa"),
  ghgEccs: yup.string().label("GHG - Eccs"),
  ghgEccr: yup.string().label("GHG - Eccr"),
  dateOfOperation: yup.string().label("Date of operation"),
});

export default {posEditSchema, posNLISCCSchema};
