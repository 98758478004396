import React from "react";

import {useQuery} from "@apollo/client";
import {COUNTRY_CODE_GET_QUERY} from "graphql/GlobalConfigCenter/CountryCode";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {COUNTRY_CODE_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {COUNTRY_CODE_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const CountryCodeConfigPage = () => {
  const {data, loading} = useQuery(COUNTRY_CODE_GET_QUERY);

  return (
    <Layout
      title={COUNTRY_CODE_CONFIG_PAGE.title}
      isGlobal
      description={COUNTRY_CODE_CONFIG_PAGE.description}
      tableColumns={COUNTRY_CODE_COLUMNS}
      tableData={loading ? [] : data?.bioLcGetCountryCodeData.countryCodeData}
    />
  );
};

export default CountryCodeConfigPage;
