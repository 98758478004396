import PropTypes from "prop-types";
import tw, {styled, css} from "twin.macro";
import {formatDisplayValue} from "../CombinedObligatedVolumeTable";

const StyledTd = styled.td(
  ({centered = true}) => css`
    ${tw`border border-gray-300 px-4`}
    ${centered && tw`text-center`}
  `,
);

const StyledTh = tw.th`border border-gray-300 px-4`;

const EmailTableCredits = ({
  availableMonths,
  columns,
  totals,
  pricingLabel,
  region,
  isTrueUp,
}) => {
  return (
    <>
      <div>
        <p className="font-bold" data-test="to-publish-email-table-label">
          Credits:
        </p>
      </div>
      <table className="table-auto border-collapse mt-4">
        <thead>
          <tr>
            <StyledTh className="w-[160px]">Pricing Window</StyledTh>
            <StyledTh
              colSpan={2}
              className="w-[300px]"
              data-test="to-publish-email-pricing-label"
            >
              {pricingLabel}
            </StyledTh>
            {availableMonths.map((col, idx) => (
              <StyledTh
                className={
                  isTrueUp && idx === availableMonths.length - 1
                    ? "font-bold w-[130px]"
                    : ""
                }
                key={idx}
              >
                {col}
              </StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {columns[0].map((row, idx) => (
            <tr key={idx}>
              {idx === 0 && (
                <StyledTd rowSpan={region === "wc" ? 6 : 4}>
                  Credit Asset Sale
                </StyledTd>
              )}
              {idx === 0 && <StyledTd rowSpan={2}>D4 (RINS)</StyledTd>}
              {idx === 2 && region === "wc" && (
                <StyledTd rowSpan={2}>D5 (RINS)</StyledTd>
              )}
              {((region === "wc" && idx === 4) ||
                (region === "ec" && idx === 2)) && (
                <StyledTd rowSpan={2}>D6 (RINS)</StyledTd>
              )}
              {columns.map((col, cidx) => (
                <StyledTd
                  className={
                    isTrueUp && cidx === columns.length - 1 ? "font-bold" : ""
                  }
                  centered={cidx !== 0}
                  key={cidx}
                >
                  {formatDisplayValue(col[idx])}
                </StyledTd>
              ))}
            </tr>
          ))}
          <tr className="">
            <td colSpan={2} aria-label="Empty cell" />
            <StyledTd centered={false} className="font-bold">
              Total (RINS)
            </StyledTd>
            {totals.map((col, cidx) => (
              <StyledTd
                className="font-bold"
                key={cidx}
                data-test={`to-publish-email-totals-${cidx}`}
              >
                {formatDisplayValue(col)}
              </StyledTd>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

EmailTableCredits.propTypes = {
  availableMonths: PropTypes.array,
  columns: PropTypes.array,
  totals: PropTypes.array,
  region: PropTypes.string,
  pricingLabel: PropTypes.string,
  isTrueUp: PropTypes.bool,
};

export default EmailTableCredits;
