import Button from "DLS/Button";
import PropTypes from "prop-types";
import {cloneElement, useCallback, useMemo, useState} from "react";
import {useFormContext} from "react-hook-form";
import {MdExpandMore, MdExpandLess, MdSync} from "react-icons/md";
import Form from "../Form";
import "./index.scss";

const Filter = ({
  className,
  children,
  onClear,
  onFilter,
  defaultValues,
  showExpand,
  schema,
}) => {
  const handleClear = useCallback(
    (values) => {
      // call onFilter and onClear with default values
      onFilter?.(values);
      onClear?.(values);
    },
    [onClear, onFilter],
  );

  return (
    <Form
      className={["bp-filter", className].join(" ")}
      defaultValues={defaultValues}
      onSubmit={onFilter}
      schema={schema}
    >
      <FilterChildrenWrapper onClear={handleClear} showExpand={showExpand}>
        {children}
      </FilterChildrenWrapper>
    </Form>
  );
};

const FilterChildrenWrapper = ({children, onClear, showExpand = false}) => {
  // This is created as independent component to be able to reach
  // react-hook-form context from inside the form.
  const {reset, getValues} = useFormContext();
  const [expanded, setExpanded] = useState(false);

  const handleClear = useCallback(() => {
    reset(undefined, {keepDefaultValues: true});
    onClear(getValues());
  }, [getValues, onClear, reset]);

  const handleExpand = useCallback(() => setExpanded((v) => !v), []);

  const FilterButtons = useCallback(
    () => (
      <div className={`bp-filter-buttons ${expanded ? "--expanded" : ""}`}>
        <Button
          size="medium"
          label="Apply"
          htmlType="submit"
          data-test="filter-submit-button"
        />
        <Button
          size="medium"
          data-test="filter-clear-button"
          type="secondary"
          onClick={handleClear}
          icon={<MdSync />}
        />
        <Button
          size="medium"
          hidden={!showExpand}
          type="secondary"
          data-test="filter-expand-button"
          onClick={handleExpand}
          icon={expanded ? <MdExpandLess /> : <MdExpandMore />}
        />
      </div>
    ),
    [expanded, handleClear, handleExpand, showExpand],
  );

  // Two columns layout, used when ShowExpand is there
  const TwoColsLayout = useMemo(
    () => (
      <>
        <div className="bp-filter-layout-two-cols">
          {cloneElement(children, {filterExpanded: expanded})}
        </div>
        <FilterButtons />
      </>
    ),
    [children, expanded],
  );

  // Single row layout, used when no showExpand is used,
  // it avoid changing the previous design.
  // eventually this could be removed when we get some
  // insight that expand will be in all filters or not
  const SingleRowLayout = useMemo(
    () => (
      <div className="bp-filter-layout-single-row">
        {cloneElement(children, {filterExpanded: expanded})}
        <FilterButtons />
      </div>
    ),
    [children, expanded],
  );

  return (
    <div className="bp-filter-content">
      {showExpand ? TwoColsLayout : SingleRowLayout}
    </div>
  );
};

FilterChildrenWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onClear: PropTypes.func,
  showExpand: PropTypes.bool,
};

Filter.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onClear: PropTypes.func,
  defaultValues: PropTypes.object,
  showExpand: PropTypes.bool,
  schema: PropTypes.object,
};

export default Filter;
