import PropTypes from "prop-types";
import {KEYS_FOR_REVIEW} from "../../constants/coProcessing";

const listKeyPoints = (error, map) => {
  return (
    <div
      key={`${error}-key-point`}
      data-test="copro-missing-key"
      className="underline underline-offset-1 text-slate-300 py-3 text-xs"
    >
      {map.get(error)}
    </div>
  );
};

const FormKeyPoints = ({errors, state, map}) => {
  return (
    <div data-test="form-key-points" className="copro-key-points">
      {!!errors && state && (
        <div className="pb-8 border-b">
          <div>{KEYS_FOR_REVIEW}:</div>
          <div>{errors?.map((error) => listKeyPoints(error, map))}</div>
        </div>
      )}
    </div>
  );
};

FormKeyPoints.propTypes = {
  errors: PropTypes.array,
  state: PropTypes.bool,
  map: PropTypes.object,
};
export default FormKeyPoints;
