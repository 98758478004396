import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import {Up16, Down16} from "@bphxd/ds-core-react/lib/icons";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import {useAppSetting} from "providers/appSetting";
import {countryNames, status} from "../constants";

const unitToBeDisplayed = "gCO2eq/MJ";

const OutboundDetail = ({data, rowId, subRowId, onSelectionChange}) => {
  const [currentIndex, setCurrentIndex] = useState(subRowId);
  const {outboundRecords} = data;
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();

  const outboundRecord = useMemo(() => {
    const obRec = {...outboundRecords[currentIndex]};
    let calculationSteps = obRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      obRec.calculationSteps = {};
      return obRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    obRec.calculationSteps =
      calculationSteps[obRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return obRec;
  }, [currentIndex, outboundRecords]);

  function numberFormatter(value) {
    if (Number.isInteger(value) && value !== 0) {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    }
    if (value === 0 || value === "0.0") {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 0);
    }

    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  const isDisabled =
    countryNames.UNITED_KINGDOM === appSetting.currentCountry ||
    outboundRecord.status === status.AVAILABLE;

  return (
    <div className="flex flex-col h-[260px] w-full gap-4 items-stretch justify-between">
      <div className="flex flex-row justify-between gap-2 mt-[52px]">
        {!isDisabled && (
          <>
            <FormGroup>
              <Label for="ghgEecOut" className="text-[12px] mb-0">
                Eec
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEecOut"
                data-test="ghgEecOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEec)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgElOut" className="text-[12px] mb-0">
                El
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgElOut"
                data-test="ghgElOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEl)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEpOut" className="text-[12px] mb-0">
                Ep
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEpOut"
                data-test="ghgEpOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEp)}
              />

              <UncontrolledTooltip placement="bottom" target="ghgEpOut">
                {outboundRecord.calculationSteps?.ghg_ep ?? ""}
              </UncontrolledTooltip>
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEtdOut" className="text-[12px] mb-0">
                Etd
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEtdOut"
                data-test="ghgEtdOut"
                readOnly
                value={
                  outboundRecord.ghgEtd === "DDV" ||
                  outboundRecord.ghgEtd === "TDV"
                    ? outboundRecord.ghgEtd
                    : numberFormatter(outboundRecord.ghgEtd)
                }
              />
              <UncontrolledTooltip placement="bottom" target="ghgEtdOut">
                {outboundRecord.calculationSteps?.ghg_etd ?? ""}
              </UncontrolledTooltip>
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              +
            </div>
            <FormGroup>
              <Label for="ghgEuOut" className="text-[12px] mb-0">
                Eu
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEuOut"
                data-test="ghgEuOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEu)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEscaOut" className="text-[12px] mb-0">
                Esca
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEscaOut"
                data-test="ghgEscaOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEsca)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEccsOut" className="text-[12px] mb-0">
                Eccs
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEccsOut"
                data-test="ghgEccsOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEccs)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              -
            </div>
            <FormGroup>
              <Label for="ghgEccrOut" className="text-[12px] mb-0">
                Eccr
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgEccrOut"
                data-test="ghgEccrOut"
                readOnly
                value={numberFormatter(outboundRecord.ghgEccr)}
              />
            </FormGroup>
            <div className="h-[calc(100%-9px)] flex flex-row items-center">
              =
            </div>
            <FormGroup>
              <Label for="ghgTotalOut" className="text-[12px] mb-0">
                Total
              </Label>
              <Input
                className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
                type="text"
                id="ghgTotalOut"
                data-test="ghgTotalOut"
                readOnly
                value={
                  outboundRecord.ghgTotal === "DDV" ||
                  outboundRecord.ghgTotal === "TDV"
                    ? outboundRecord.ghgTotal
                    : numberFormatter(outboundRecord.ghgTotal)
                }
              />
              <div className="text-right">
                <FormText>{unitToBeDisplayed}</FormText>
              </div>
            </FormGroup>
          </>
        )}
      </div>

      {outboundRecords.length > 1 && (
        <div className="flex flex-row justify-end items-center">
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button
              color="link"
              size="sm"
              className="!text-[#111111a3] !pr-[30px] !text-[11px]"
              onClick={() => {
                setCurrentIndex(currentIndex - 1);
                onSelectionChange(`${rowId}_${currentIndex - 1}`);
              }}
              disabled={currentIndex === 0}
            >
              <Up16 className="mr-[15px]" />
              PREV
            </Button>
          </div>
          <div className="text-uppercase text-[14px] font-[500] text-[#111111] items-center">
            {currentIndex + 1} of {outboundRecords.length}
          </div>
          <div className="flex flex-row justify-end gap-2 items-center">
            <Button
              color="link"
              size="sm"
              className="!text-[#111111a3] !pr-0 !pl-[30px] !text-[11px]"
              onClick={() => {
                setCurrentIndex(currentIndex + 1);
                onSelectionChange(`${rowId}_${currentIndex + 1}`);
              }}
              disabled={currentIndex === outboundRecords.length - 1}
            >
              NEXT
              <Down16 className="ml-[15px]" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

OutboundDetail.propTypes = {
  data: PropTypes.object,
  rowId: PropTypes.string,
  subRowId: PropTypes.number,
  onSelectionChange: PropTypes.func,
};

export default OutboundDetail;
