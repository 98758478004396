import PropTypes from "prop-types";
import React from "react";
import {Button, Modal, ModalBody, ModalFooter, Spinner} from "reactstrap";

const MassBalanceCloseConfirmationModal = ({
  showResponse,
  setShowResponse,
  period,
  onSubmit,
  loading,
  periodStatus,
}) => {
  const headerText =
    periodStatus === "CLOSED" ? "Confirm re-open" : "Confirm close";
  const bodyText =
    periodStatus === "CLOSED"
      ? `Are you sure you want to re-open ${period} mass balance?`
      : `Are you sure you want to close ${period} mass balance?`;
  const buttonText = periodStatus === "CLOSED" ? "Open" : "Close period";
  return (
    <Modal size="sm" isOpen={showResponse} className="modal-dialog-centered">
      <ModalBody className="text-center">
        <p className="mb-[2px]">{headerText}</p>
        <p className="text-[13px] text-gray-800 mb-0">{bodyText}</p>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowResponse("")}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={onSubmit}
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              {buttonText}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

MassBalanceCloseConfirmationModal.propTypes = {
  showResponse: PropTypes.bool,
  setShowResponse: PropTypes.func,
  period: PropTypes.string,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  periodStatus: PropTypes.string,
};

export default MassBalanceCloseConfirmationModal;
