import {gql} from "@apollo/client";

export const LOCATION_YIELD = gql`
  query bioLcCoproConfigCenterLocationYieldDetails {
    bioLcCoproConfigCenterLocationYieldDetails {
      Records {
        location_yield_id
        location_id
        location_code
        location_name
        processing_unit_code
        material_id
        material_code
        material_short_name
        yield_ratio
        country_id
        valid_from
        valid_to
        yield_type
      }
    }
  }
`;

export default {LOCATION_YIELD};
