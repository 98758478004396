import PropTypes from "prop-types";

const FieldRow = ({label, value}) => (
  <div className="grid grid-cols-2 gap-4 mb-1 text-left">
    <span className="font-medium">{label}</span>
    <span>{value}</span>
  </div>
);

FieldRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default FieldRow;
