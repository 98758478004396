import {Link} from "react-router-dom";
import moment from "moment";
import ProgressBar from "modules/common/progressBar";
import * as LABELS from "constants/ceemasValidationLabels";
import {
  dateSorterExt,
  numberSorterExt,
  stringSorterExt,
} from "modules/common/Table/sorters";
import StatusIcons from "content/StatusIcons";
import {formatNumber} from "providers/userSettings";

export const statusIcon = (value) => (
  <div className="flex justify-center ">
    {value === 0 ? (
      <StatusIcons status="TRUE" />
    ) : (
      <StatusIcons status="FALSE" />
    )}
  </div>
);

export const motSummaryReconciliationTableColumns = [
  {
    title: LABELS.LABEL_MONTH,
    dataIndex: "complianceMonth",
    key: "complianceMonth",
    ...numberSorterExt("complianceMonth"),
    render: (text, record) => (
      <Link
        to={`/gy-enablement/ceemas-mot-validation-plant-level/${encodeURIComponent(
          JSON.stringify({
            company: record?.filterDetails?.company,
            complianceyear: record?.filterDetails?.complianceyear,
            compliancemonth: record?.complianceMonth,
            ed: record?.filterDetails?.ed,
            legalentity: record?.filterDetails?.legalentity,
            material: record?.filterDetails?.material,
            plant: record?.filterDetails?.plant,
            balancegroup: record?.filterDetails?.balancegroup,
            motProfile: record?.filterDetails?.motProfile,
          }),
        )}`}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="mot-summary-reconciliation-month-link"
      >
        {moment(text).format("MMM")}
      </Link>
    ),
  },
  {
    title: LABELS.LABEL_NO_OF_PLANTS,
    dataIndex: "noOfLocations",
    key: "noOfLocations",
    ...numberSorterExt("noOfLocations"),
  },
  {
    title: LABELS.LABEL_NO_OF_RECONCILED_PLANTS,
    dataIndex: "reconciledLocations",
    key: "reconciledLocations",
    ...numberSorterExt("reconciledLocations"),
  },
  {
    title: LABELS.LABEL_RECONCILIATION_STATUS,
    dataIndex: "reconciliationPercentage",
    key: "reconciliationPercentage",
    fixed: "right",
    width: 100,
    ...numberSorterExt("reconciliationPercentage"),
    render: (reconciliationPercentage) => (
      <ProgressBar percentage={reconciliationPercentage} />
    ),
  },
];

export const getTaxedOutboundTableCols = (decimalFormat) => {
  const taxedOutboundTableCols = [
    {
      title: LABELS.LABEL_MONTH,
      dataIndex: "complianceMonth",
      key: "complianceMonth",
      ...numberSorterExt("complianceMonth"),
      render: (text, record) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-plant-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.complianceMonth,
              ed: record?.filterDetails?.ed,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.filterDetails?.material,
              plant: record?.filterDetails?.plant,
              balancegroup: record?.filterDetails?.balancegroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-reconciliation-month-link"
        >
          {moment(text).format("MMM")}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTableCols;
};

export const motSummaryPlantLevelReconciliationTableColumns = [
  {
    title: LABELS.LABEL_PLANT,
    dataIndex: "plantName",
    key: "plantName",
    align: "left",

    ...stringSorterExt("plantName"),
    render: (text, record) => (
      <Link
        to={`/gy-enablement/ceemas-mot-validation-edgroup-level/${encodeURIComponent(
          JSON.stringify({
            company: record?.filterDetails?.company,
            complianceyear: record?.filterDetails?.complianceyear,
            compliancemonth: record?.filterDetails?.compliancemonth,
            ed: record?.filterDetails?.ed,
            legalentity: record?.filterDetails?.legalentity,
            material: record?.filterDetails?.material,
            plant: record?.plant,
            balancegroup: record?.filterDetails?.balancegroup,
            motProfile: record?.filterDetails?.motProfile,
          }),
        )}`}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="mot-summary-reconciliation-plant-link"
      >
        {text}
      </Link>
    ),
  },
  {
    title: LABELS.LABEL_NO_OF_EDGROUPS,
    dataIndex: "noOfEDGroups",
    key: "noOfEDGroups",
    ...numberSorterExt("noOfEDGroups"),
    width: 150,
  },
  {
    title: LABELS.LABEL_NO_OF_RECONCILED_EDGROUPS,
    dataIndex: "reconciledEDGroups",
    key: "reconciledEDGroups",
    ...numberSorterExt("reconciledEDGroups"),
    width: 100,
  },
  {
    title: LABELS.LABEL_RECONCILIATION_STATUS,
    dataIndex: "reconciliationPercentage",
    key: "reconciliationPercentage",
    fixed: "right",
    width: 100,
    ...numberSorterExt("reconciliationPercentage"),
    render: (reconciliationPercentage) => (
      <ProgressBar percentage={reconciliationPercentage} />
    ),
  },
];

export const getTaxedOutboundTablePlantLevelCols = (decimalFormat) => {
  const taxedOutboundTablePlantLevelCols = [
    {
      title: LABELS.LABEL_PLANT,
      dataIndex: "plantName",
      key: "plantName",
      align: "left",

      ...stringSorterExt("plantName"),
      render: (text, record) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-edgroup-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.filterDetails?.compliancemonth,
              ed: record?.filterDetails?.ed,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.filterDetails?.material,
              plant: record?.plant,
              balancegroup: record?.filterDetails?.balancegroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-reconciliation-plant-link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTablePlantLevelCols;
};

export const motSummaryEDGroupLevelReconciliationTableColumns = [
  {
    title: LABELS.LABEL_EDGROUP,
    dataIndex: "eDGroupName",
    key: "eDGroupName",
    align: "left",
    ...stringSorterExt("eDGroupName"),
    render: (text, record) => (
      <Link
        to={`/gy-enablement/ceemas-mot-validation-balancegroup-level/${encodeURIComponent(
          JSON.stringify({
            company: record?.filterDetails?.company,
            complianceyear: record?.filterDetails?.complianceyear,
            compliancemonth: record?.filterDetails?.compliancemonth,
            ed: record?.eDGroup,
            legalentity: record?.filterDetails?.legalentity,
            material: record?.filterDetails?.material,
            plant: record?.filterDetails?.plant,
            balancegroup: record?.filterDetails?.balancegroup,
            motProfile: record?.filterDetails?.motProfile,
          }),
        )}`}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="mot-summary-reconciliation-edgroup-link"
      >
        {text}
      </Link>
    ),
  },
  {
    title: LABELS.LABEL_NO_OF_BALANCEGROUPS,
    width: 100,
    dataIndex: "noOfBalanceGroups",
    key: "noOfBalanceGroups",
    ...numberSorterExt("noOfBalanceGroups"),
  },
  {
    title: LABELS.LABEL_NO_OF_RECONCILED_BALANCEGROUPS,
    width: 100,
    dataIndex: "reconciledBalanceGroups",
    key: "reconciledBalanceGroups",
    ...numberSorterExt("reconciledBalanceGroups"),
  },
  {
    title: LABELS.LABEL_RECONCILIATION_STATUS,
    dataIndex: "reconciliationPercentage",
    key: "reconciliationPercentage",
    fixed: "right",
    width: 100,
    ...numberSorterExt("reconciliationPercentage"),
    render: (reconciliationPercentage) => (
      <ProgressBar percentage={reconciliationPercentage} />
    ),
  },
];

export const getTaxedOutboundTableEDGroupLevelCols = (decimalFormat) => {
  const taxedOutboundTableEDGroupLevelCols = [
    {
      title: LABELS.LABEL_EDGROUP,
      dataIndex: "eDGroupName",
      key: "eDGroupName",
      align: "left",
      ...stringSorterExt("eDGroupName"),
      render: (text, record) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-balancegroup-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.filterDetails?.compliancemonth,
              ed: record?.eDGroup,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.filterDetails?.material,
              plant: record?.filterDetails?.plant,
              balancegroup: record?.filterDetails?.balancegroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-reconciliation-edgroup-link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTableEDGroupLevelCols;
};

export const motSummaryBalanceGroupLevelReconciliationTableColumns = [
  {
    title: LABELS.LABEL_BALANCE_GROUP,
    dataIndex: "balanceGroup",
    key: "balanceGroup",
    align: "left",
    ...stringSorterExt("balanceGroup"),
    render: (text, record) => (
      <Link
        to={`/gy-enablement/ceemas-mot-validation-material-level/${encodeURIComponent(
          JSON.stringify({
            company: record?.filterDetails?.company,
            complianceyear: record?.filterDetails?.complianceyear,
            compliancemonth: record?.filterDetails?.compliancemonth,
            ed: record?.filterDetails?.ed,
            legalentity: record?.filterDetails?.legalentity,
            material: record?.filterDetails?.material,
            plant: record?.filterDetails?.plant,
            balancegroup: record?.balanceGroupNo,
            motProfile: record?.filterDetails?.motProfile,
          }),
        )}`}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="mot-summary-reconciliation-balance-group-link"
      >
        {text}
      </Link>
    ),
  },
  {
    title: LABELS.LABEL_NO_OF_MATERIALS,
    width: 100,
    dataIndex: "noOfMaterials",
    key: "noOfMaterials",
    ...numberSorterExt("noOfMaterials"),
  },
  {
    title: LABELS.LABEL_NO_OF_RECONCILED_MATERIALS,
    width: 100,
    dataIndex: "reconciledMaterials",
    key: "reconciledMaterials",
    ...numberSorterExt("reconciledMaterials"),
  },
  {
    title: LABELS.LABEL_RECONCILIATION_STATUS,
    dataIndex: "reconciliationPercentage",
    key: "reconciliationPercentage",
    fixed: "right",
    width: 100,
    ...numberSorterExt("reconciliationPercentage"),
    render: (reconciliationPercentage) => (
      <ProgressBar percentage={reconciliationPercentage} />
    ),
  },
];

export const getTaxedOutboundTableBalanceGroupLevelCols = (decimalFormat) => {
  const taxedOutboundTableBalanceGroupLevelCols = [
    {
      title: LABELS.LABEL_BALANCE_GROUP,
      dataIndex: "balanceGroupName",
      key: "balanceGroupName",
      align: "left",
      ...stringSorterExt("balanceGroupName"),
      render: (text, record) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-material-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.filterDetails?.compliancemonth,
              ed: record?.filterDetails?.ed,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.filterDetails?.material,
              plant: record?.filterDetails?.plant,
              balancegroup: record?.balanceGroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-taxed-outbound-balance-group-link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTableBalanceGroupLevelCols;
};

export const motSummaryMaterialLevelReconciliationTableColumns = [
  {
    title: LABELS.LABEL_MATERIAL,
    dataIndex: "material",
    key: "material",
    align: "left",
    ...stringSorterExt("material"),
    render: (text, record) => (
      <Link
        to={`/gy-enablement/ceemas-mot-validation-day-level/${encodeURIComponent(
          JSON.stringify({
            company: record?.filterDetails?.company,
            complianceyear: record?.filterDetails?.complianceyear,
            compliancemonth: record?.filterDetails.compliancemonth,
            ed: record?.filterDetails?.ed,
            legalentity: record?.filterDetails?.legalentity,
            material: record?.materialCode,
            plant: record?.filterDetails?.plant,
            balancegroup: record?.filterDetails?.balancegroup,
            motProfile: record?.filterDetails?.motProfile,
          }),
        )}`}
        className="text-blue-500 underline hover:text-blue-500"
        data-test="mot-summary-reconciliation-material-link"
      >
        {text}
      </Link>
    ),
  },
  {
    title: LABELS.LABEL_NO_OF_DAYS,
    width: 100,
    dataIndex: "noOfDays",
    key: "noOfDays",
    ...numberSorterExt("noOfDays"),
  },
  {
    title: LABELS.LABEL_NO_OF_RECONCILED_DAYS,
    width: 100,
    dataIndex: "reconciledDays",
    key: "reconciledDays",
    ...numberSorterExt("reconciledDays"),
  },
  {
    title: LABELS.LABEL_RECONCILIATION_STATUS,
    dataIndex: "reconciliationPercentage",
    key: "reconciliationPercentage",
    fixed: "right",
    width: 100,
    ...numberSorterExt("reconciliationPercentage"),
    render: (reconciliationPercentage) => (
      <ProgressBar percentage={reconciliationPercentage} />
    ),
  },
];

export const getTaxedOutboundTableMaterialLevelCols = (decimalFormat) => {
  const taxedOutboundTableMaterialLevelCols = [
    {
      title: LABELS.LABEL_MATERIAL,
      dataIndex: "materialName",
      key: "materialName",
      align: "left",
      ...stringSorterExt("materialName"),
      render: (text, record) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-day-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.filterDetails.compliancemonth,
              ed: record?.filterDetails?.ed,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.material,
              plant: record?.filterDetails?.plant,
              balancegroup: record?.filterDetails?.balancegroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-taxoutbound-material-link"
        >
          {text}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTableMaterialLevelCols;
};

export const getTaxedOutboundTableDayLevelCols = (decimalFormat) => {
  const taxedOutboundTableDayLevelCols = [
    {
      title: LABELS.LABEL_DATE,
      dataIndex: "dynamicDate",
      key: "dynamicDate",
      align: "left",
      render: (text, record, index, dateFormat) => (
        <Link
          to={`/gy-enablement/ceemas-mot-validation-transaction-level/${encodeURIComponent(
            JSON.stringify({
              company: record?.filterDetails?.company,
              complianceyear: record?.filterDetails?.complianceyear,
              compliancemonth: record?.filterDetails.compliancemonth,
              ed: record?.filterDetails?.ed,
              legalentity: record?.filterDetails?.legalentity,
              material: record?.filterDetails?.material,
              plant: record?.filterDetails?.plant,
              date: record?.dynamicDate,
              balancegroup: record?.filterDetails?.balancegroup,
              motProfile: record?.filterDetails?.motProfile,
            }),
          )}`}
          className="text-blue-500 underline hover:text-blue-500"
          data-test="mot-summary-taxoutbound-day-link"
        >
          {moment(text, "DD-MM-YYYY").format(dateFormat)}
        </Link>
      ),
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      width: 100,
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      fixed: "right",
      render: (value) => statusIcon(value),
    },
  ];
  return taxedOutboundTableDayLevelCols;
};

export const getTransactionalLevelTableCols = (decimalFormat) => {
  const transactionalLevelTableCols = [
    {
      title: LABELS.LABEL_OUTBOUND_DELIVERY,
      dataIndex: "outboundDelivery",
      key: "outboundDelivery",
      width: 150,
      ...stringSorterExt("outboundDelivery"),
    },
    {
      title: LABELS.LABEL_LOAD_MAT_DOCUMENT,
      dataIndex: "loadMatDoc",
      width: 150,
      key: "loadMatDoc",
      ...stringSorterExt("loadMatDoc"),
    },
    {
      title: LABELS.LABEL_LOAD_MAT_DOC_DATE,
      dataIndex: "loadDate",
      key: "loadDate",
      inputType: "date",
    },
    {
      title: LABELS.LABEL_CEEMAS_VOLUME,
      dataIndex: "ceemasVolume",
      key: "ceemasVolume",
      align: "right",
      ...numberSorterExt("ceemasVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_EXCISE_VOLUME,
      dataIndex: "motVolume",
      key: "motVolume",
      align: "right",
      ...numberSorterExt("motVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_VOLUME_DIFFERENCE,
      dataIndex: "variance",
      key: "variance",
      align: "right",
      ...numberSorterExt("variance"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "variance",
      key: "variance",
      render: (value) => statusIcon(value),
    },
    {
      title: LABELS.LABEL_DISCHARGE_MAT_DOC,
      dataIndex: "disMatDoc",
      key: "disMatDoc",
      width: 150,
      ...stringSorterExt("disMatDoc"),
    },
    {
      title: LABELS.LABEL_DISCHARGE_DOC_DATE,
      dataIndex: "disDate",
      key: "disDate",
      ...dateSorterExt("disDate", "YYYY/MM/DD"),
    },
    {
      title: LABELS.LABEL_FEM_VOLUME,
      dataIndex: "femVolume",
      key: "femVolume",
      align: "right",
      ...numberSorterExt("femVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_INTRANSIT_VOLUME,
      dataIndex: "intransitVolume",
      key: "intransitVolume",
      align: "right",
      ...numberSorterExt("intransitVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_TRANSPORT_GAINLOSS,
      dataIndex: "transportVolume",
      key: "transportVolume",
      align: "right",
      ...numberSorterExt("transportVolume"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_CHECK,
      dataIndex: "check",
      key: "check",
      align: "right",
      ...numberSorterExt("check"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: LABELS.LABEL_STATUS,
      dataIndex: "check",
      key: "check",
      render: (value) => statusIcon(value),
    },
  ];
  return transactionalLevelTableCols;
};
