import PropTypes from "prop-types";

export const FilterBar = ({children}) => (
  <div className="relative">
    <div className="absolute left-0 right-0 z-0 h-40 px-6 bp-green-gradient lg:px-10"></div>
    <div className="relative px-6 pb-6 lg:px-10 top-6">{children}</div>
  </div>
);

export const FilterBarContent = ({children}) => (
  <div
    className="flex flex-grow items-end gap-4 flex-wrap bg-white py-10 px-7"
    style={{boxShadow: "0 20px 30px 0 rgba(35, 35, 35, 0.2)"}}
  >
    {children}
  </div>
);

FilterBar.propTypes = {
  children: PropTypes.node.isRequired,
};

FilterBarContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FilterBar;
