import set from "lodash/fp/set";
import omit from "lodash/fp/omit";
import flow from "lodash/fp/flow";
import {
  filterByKey,
  getProductKey,
  getUPTotalValues,
  sumMonthTotal,
  getObligatedTotalRow,
} from "./tableUtils";
import {isAfterCurrMonth, isBeforeCurrentMonth} from "./dateUtils";

// -------------------- UNPUBLISHED ------------------------------ //

const getMonthUPData = (
  beforeCurrMonth,
  date,
  actuals = {},
  forecasted = {},
  savedActuals = {},
  savedForecast = {},
  region = "west",
) => {
  const monthData = {};

  const isAfter = isAfterCurrMonth(date);

  Object.entries(omit("__typename", actuals?.obligationValues)).forEach(
    ([key]) => {
      const actualValue = isAfter
        ? savedForecast?.obligationValues[key]
        : savedActuals?.obligationValues[key];
      const systemValue = Math.round(
        beforeCurrMonth
          ? actuals?.obligationValues[key]
          : forecasted?.obligationValues[key],
      );

      const productKey = getProductKey(key);

      if (productKey.includes("d5") && region === "east") return;

      monthData[productKey] = {
        actualValue,
        systemValue,
        variance: systemValue - actualValue,
      };

      monthData.total_obligation = getUPTotalValues(
        actualValue,
        systemValue,
        "total_obligation",
        monthData,
      );

      if (productKey.includes("d4")) {
        monthData.d4_total = getUPTotalValues(
          actualValue,
          systemValue,
          "d4_total",
          monthData,
        );
      }

      if (productKey.includes("d5")) {
        monthData.d5_total = getUPTotalValues(
          actualValue,
          systemValue,
          "d5_total",
          monthData,
        );
      }

      if (productKey.includes("d6")) {
        monthData.d6_total = getUPTotalValues(
          actualValue,
          systemValue,
          "d6_total",
          monthData,
        );
      }
    },
  );

  return monthData;
};

/**
 * Transforms a list of obligation data for credits and returns a new array
 * with unpublished page data for each month.
 *
 * @param {Array} obligationsList - The list of obligation data to transform.
 * @returns {Array} A new array of objects with unpublished page data for each month.
 */
const transformCreditsUPData = (obligationsList = [], region = null) => {
  return obligationsList?.map(({date = {}, obligationItems = []}) => {
    const actuals = filterByKey("actual", obligationItems)[0];
    const forecasted = filterByKey("forecasted", obligationItems)[0];
    const savedActuals = filterByKey("savedActuals", obligationItems)[0];
    const savedForecast = filterByKey("savedForecast", obligationItems)[0];

    const monthData = getMonthUPData(
      isBeforeCurrentMonth(date),
      date,
      actuals,
      forecasted,
      savedActuals,
      savedForecast,
      region,
    );

    return {
      date,
      ...monthData,
    };
  });
};

export const computeUnpublishedCreditData = ({responseData, region}) =>
  flow([transformCreditsUPData])(responseData, region);

/**
 * Calculates and returns an array of unpublished page data for credits data.
 *
 * @param {Object} data - The list of object containing monthly measurements.
 * @param {number} monthIndex - The index of the first column to display.
 * @param {number} selectedYear - The selected year on the dropdown.
 * @returns {Array} An array of objects with unpublished page data for each month.
 */
export const getCreditsUPPageData = (data, selectedYear, region) => {
  const hasValidData = data?.bioLcForecastDataApi?.body?.length > 0 ?? false;

  if (!hasValidData || !selectedYear) {
    return [];
  }

  const pageData = computeUnpublishedCreditData({
    responseData: data.bioLcForecastDataApi.body,
    region,
  });

  return pageData;
};

export const setTotalCreditsForMonth = (month, updatedTableData = []) => {
  const creditsTotalRow = getObligatedTotalRow(updatedTableData);
  const newTotalForMonth = sumMonthTotal(month, updatedTableData);

  return set(`updatedForecast.${month}`, newTotalForMonth, creditsTotalRow);
};

export const updateCreditsObligation = (dateKey, rinKey, updatedList) => {
  const isSpecifiedRINKey = (record) =>
    record && record?.key && record.key?.includes(rinKey);
  const rinRows = updatedList.filter(isSpecifiedRINKey);
  const newValue = sumMonthTotal(dateKey, rinRows);
  const rinTotalRow = updatedList.find((o) => o.key === `${rinKey}_total`);

  return set(`updatedForecast.${dateKey}`, newValue, rinTotalRow);
};
