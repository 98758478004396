import {Remove24} from "@bphxd/ds-core-react/lib/icons";
import process from "process";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import CheckboxFlag from "../CheckboxNullable";
import Datepicker from "../Datepicker";
import {DDV, TDV, parseGhgValues} from "./utils";

const GHGSplit = ({
  field,
  index,
  countries,
  onRemoveSplit = () => {},
  status,
  isDisabled,
}) => {
  const [isSplitCheckedYes, setIsSplitCheckedYes] = useState(null);
  const {
    register,
    formState: {errors},
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
  } = useFormContext();

  const hideLtpButton =
    process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(`splitDetails.${index}.${name}`, options);
    return {innerRef: ref, ...props};
  };
  const changes = useWatch({
    name: [
      `splitDetails.${index}.ghgEec`,
      `splitDetails.${index}.ghgEl`,
      `splitDetails.${index}.ghgEp`,
      `splitDetails.${index}.ghgEtd`,
      `splitDetails.${index}.ghgEu`,
      `splitDetails.${index}.ghgEsca`,
      `splitDetails.${index}.ghgEccs`,
      `splitDetails.${index}.ghgEccr`,
      `splitDetails.${index}.defaultValueAppliedFlag`,
    ],
  });

  useEffect(() => {
    const ghgTotal = getValues(`splitDetails.${index}.ghgTotal`);
    const flagValue = getValues(
      `splitDetails.${index}.defaultValueAppliedFlag`,
    );
    const parsedChanges = parseGhgValues(changes);
    const total =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);
    if (ghgTotal !== TDV && ghgTotal !== DDV) {
      setValue(`splitDetails.${index}.ghgTotal`, total.toFixed(2));
      setIsSplitCheckedYes(false);
    }
    if (flagValue) {
      setValue(`splitDetails.${index}.ghgTotal`, TDV);
      setIsSplitCheckedYes(true);
      clearErrors(`splitDetails.${index}.ghgEec`);
    }
    if (!flagValue) {
      if (status === "completed" || status === "ready_to_assign") {
        setValue(`splitDetails.${index}.ghgTotal`, ghgTotal);
      } else {
        setValue(`splitDetails.${index}.ghgTotal`, total.toFixed(2));
      }
      setIsSplitCheckedYes(false);
      if (
        parseFloat(changes[0] ?? "0") === 0 &&
        parseFloat(changes[1] ?? "0") === 0 &&
        parseFloat(changes[2] ?? "0") === 0 &&
        parseFloat(changes[3] ?? "0") === 0 &&
        parseFloat(changes[4] ?? "0") === 0 &&
        parseFloat(changes[5] ?? "0") === 0 &&
        parseFloat(changes[6] ?? "0") === 0 &&
        parseFloat(changes[7] ?? "0") === 0
      ) {
        setError(`splitDetails.${index}.ghgEec`, {
          type: "value",
          message: "At least one GHG emission must be greater than 0",
        });
      }
    }
  }, [
    changes,
    setValue,
    index,
    getValues,
    isSplitCheckedYes,
    status,
    clearErrors,
    setError,
  ]);

  return (
    <>
      <div className="flex flex-row justify-between items-center">
        <span className="fs-6 fw-semibold flex-1">
          SPLIT {String.fromCharCode(65 + index)}
        </span>
        {!isDisabled && (
          <Button
            color="standard-quartenary"
            type="button"
            className="!pr-0"
            onClick={() => onRemoveSplit(index)}
          >
            <Remove24 />
          </Button>
        )}
      </div>
      <FormGroup className="py-3">
        <Label
          for={`$splitDetails.${index}.defaultValueAppliedFlag`}
          className="fw-normal"
        >
          Total default value according to RED II applied
        </Label>
        <CheckboxFlag
          id={`splitDetails.${index}.defaultValueAppliedFlag`}
          name={`splitDetails.${index}.defaultValueAppliedFlag`}
          control={control}
          error={errors.defaultValueAppliedFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field.id}originCountryName`} className="fw-normal">
          Country of origin (of the origin raw material)
        </Label>
        <Input
          type="select"
          id={`${field.id}originCountryName`}
          data-test={`${field.id}originCountryName`}
          {...computeProps("originCountryName", {
            required: "Please enter country of origin",
          })}
          invalid={
            !!errors.splitDetails?.[index]?.originCountryName && !isDisabled
          }
          disabled={isDisabled}
        >
          <option key="none" value="">
            None
          </option>
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </Input>
        {errors.splitDetails?.[index]?.originCountryName && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.originCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for={`${field.id}feedstockQty`} className="fw-normal">
          Quantity (t)
        </Label>
        <Input
          type="number"
          id={`${field.id}feedstockQty`}
          data-test={`${field.id}feedstockQty`}
          {...computeProps("feedstockQty", {
            required: "Please enter a quantity",
          })}
          invalid={!!errors.splitDetails?.[index]?.feedstockQty && !isDisabled}
          disabled={isDisabled}
        />
        {errors.splitDetails?.[index]?.feedstockQty && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.feedstockQty.message}
          </FormFeedback>
        )}
      </FormGroup>
      {!isSplitCheckedYes && (
        <div className="flex flex-row justify-between items-start gap-3">
          <FormGroup>
            <Label for={`${field.id}ghgEec`} className="fw-normal">
              Eec
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEec`}
              data-test={`${field.id}ghgEec`}
              {...computeProps("ghgEec")}
              invalid={!!errors.splitDetails?.[index]?.ghgEec && !isDisabled}
              disabled={isDisabled}
            />
            {errors.splitDetails?.[index]?.ghgEec && (
              <FormFeedback>
                {errors.splitDetails?.[index]?.ghgEec?.message}
              </FormFeedback>
            )}
          </FormGroup>

          <FormGroup>
            <Label for={`${field.id}ghgEl`} className="fw-normal">
              El
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEl`}
              data-test={`${field.id}ghgEl`}
              {...computeProps("ghgEl")}
              invalid={!!errors.splitDetails?.[index]?.ghgEl && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEp`} className="fw-normal">
              Ep
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEp`}
              data-test={`${field.id}ghgEp`}
              {...computeProps("ghgEp")}
              invalid={!!errors.splitDetails?.[index]?.ghgEp && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEtd`} className="fw-normal">
              Etd
            </Label>
            <Input
              type="text"
              id={`${field.id}ghgEtd`}
              data-test={`${field.id}ghgEtd`}
              {...computeProps("ghgEtd", {
                pattern: {
                  value: /^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                  message:
                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or DDV",
                },
              })}
              invalid={!!errors.splitDetails?.[index]?.ghgEtd && !isDisabled}
              disabled={isDisabled}
            />
            {errors.splitDetails?.[index]?.ghgEtd && (
              <FormFeedback>
                {errors.splitDetails?.[index]?.ghgEtd?.message}
              </FormFeedback>
            )}
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEu`} className="fw-normal">
              Eu
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEu`}
              data-test={`${field.id}ghgEu`}
              {...computeProps("ghgEu")}
              invalid={!!errors.splitDetails?.[index]?.ghgEu && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEsca`} className="fw-normal">
              Esca
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEsca`}
              data-test={`${field.id}ghgEsca`}
              {...computeProps("ghgEsca")}
              invalid={!!errors.splitDetails?.[index]?.ghgEsca && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEccs`} className="fw-normal">
              Eccs
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEccs`}
              data-test={`${field.id}ghgEccs`}
              {...computeProps("ghgEccs")}
              invalid={!!errors.splitDetails?.[index]?.ghgEccs && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
          <FormGroup>
            <Label for={`${field.id}ghgEccr`} className="fw-normal">
              Eccr
            </Label>
            <Input
              type="number"
              id={`${field.id}ghgEccr`}
              data-test={`${field.id}ghgEccr`}
              {...computeProps("ghgEccr")}
              invalid={!!errors.splitDetails?.[index]?.ghgEccr && !isDisabled}
              disabled={isDisabled}
            />
          </FormGroup>
        </div>
      )}
      <FormGroup>
        <Label for={`${field.id}ghgTotal`} className="fw-normal">
          GHG (total) kgCO2/dry-t
        </Label>
        <Input
          type="text"
          id={`${field.id}ghgTotal`}
          data-test={`${field.id}ghgTotal`}
          {...computeProps("ghgTotal", {
            required: "Please enter total GHG emissions",
            pattern: {
              value: /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
              message:
                "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
            },
          })}
          invalid={!!errors.splitDetails?.[index]?.ghgTotal && !isDisabled}
          disabled={isDisabled || isSplitCheckedYes}
        />
        {errors.splitDetails?.[index]?.ghgTotal && (
          <FormFeedback>
            {errors.splitDetails?.[index]?.ghgTotal?.message}
          </FormFeedback>
        )}
      </FormGroup>
      {hideLtpButton !== "false" && (
        <FormGroup>
          <Label for={`${field.id}physicalReceiptDate`} className="fw-normal">
            Date of physical receipt
          </Label>
          <Datepicker
            id={`${field.id}physicalReceiptDate`}
            name={`splitDetails.${index}.physicalReceiptDate`}
            control={control}
            error={errors.splitDetails?.[index]?.physicalReceiptDate}
            rules={{
              required: "Please add physical receipt date",
            }}
            placeholder="Please add date if available"
            disabled={isDisabled}
          />
          {errors.splitDetails?.[index]?.physicalReceiptDate && (
            <FormFeedback>
              {errors.splitDetails?.[index]?.physicalReceiptDate?.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
    </>
  );
};
GHGSplit.propTypes = {
  field: PropTypes.object,
  index: PropTypes.number,
  isCheckedYes: PropTypes.bool,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  onRemoveSplit: PropTypes.func,
  status: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default GHGSplit;
