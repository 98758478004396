import {useFormContext} from "react-hook-form";
import {useEffect} from "react";
import PropTypes from "prop-types";
import {FormGroup, Label, Input} from "reactstrap";
import CheckboxFlag from "modules/common/BinaryFlagNullable";

const ScopeOfCertification = ({data}) => {
  const {
    formState: {errors},
    control,
    register,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {}, [setValue, data]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="materialSustainabilityCriteriaFlag" className="fw-light">
          The raw material complies with the relevant sustainability criteria
          according to Art. 29 (2)-(7) RED II
        </Label>
        <CheckboxFlag
          id="materialSustainabilityCriteriaFlag"
          name="materialSustainabilityCriteriaFlag"
          control={control}
          error={errors.materialSustainabilityCriteriaFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label
          for="agriculturalBiomassIntermediateCropFlag"
          className="fw-light"
        >
          The agricultural biomass was cultivated as intermediate crop (if
          applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassIntermediateCropFlag"
          name="agriculturalBiomassIntermediateCropFlag"
          control={control}
          error={errors.agriculturalBiomassIntermediateCropFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="agriculturalBiomassLowRiskFlag" className="fw-light">
          The agricultural biomass additionally fulfills the measures for low
          ILUC risk feedstocks (if applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassLowRiskFlag"
          name="agriculturalBiomassLowRiskFlag"
          control={control}
          error={errors.agriculturalBiomassLowRiskFlag}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialWasteOrResidueFlag" className="fw-light">
          The raw material meets the definition of waste or residue according to
          the RED II
        </Label>
        <CheckboxFlag
          id="rawMaterialWasteOrResidueFlag"
          name="rawMaterialWasteOrResidueFlag"
          control={control}
          error={errors.rawMaterialWasteOrResidueFlag}
        />
      </FormGroup>
      {data?.templateType === "ISCC PoS 2.6" &&
        data?.certificationSystem === "ISCC EU" && (
          <FormGroup>
            <Label for="additionalInfoNUTS2region" className="fw-light">
              If applicable, please specify the NUTS2 Region
            </Label>

            <Input
              {...computeProps("additionalInfoNUTS2region")}
              type="textarea"
              rows="3"
              name="additionalInfoNUTS2region"
              id="additionalInfoNUTS2region"
              data-test="additionalInfoNUTS2region"
              invalid={!!errors.additionalInfoNUTS2region}
              maxLength={250}
            />
          </FormGroup>
        )}
    </div>
  );
};

ScopeOfCertification.propTypes = {
  data: PropTypes.object,
};

export default ScopeOfCertification;
