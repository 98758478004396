import {useEffect, useMemo} from "react";
import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import {HEADER_COPRO} from "modules/co-processing/constants/coProcessing";
import "./index.scss";
import {useAppSetting} from "providers/appSetting";
import COUNTRY_CODES, {COUNTRY_NAMES} from "constants/appSettings";
import CoproBreadcrumbs from "./CoproBreadcrumbs";

export default () => {
  const {appSetting, setAppSetting} = useAppSetting();

  const countryFeatureData = useMemo(
    () =>
      appSetting?.featureMappingData?.find(
        (item) => item.countryName === COUNTRY_NAMES.UNITED_STATES,
      ),
    [appSetting.featureMappingData],
  );

  useEffect(() => {
    if (appSetting.currentCountry !== COUNTRY_CODES.UNITED_STATES) {
      setAppSetting((prevAppSetting) => ({
        ...prevAppSetting,
        currentCountry: COUNTRY_CODES.UNITED_STATES,
        currentCountryMappingData: countryFeatureData,
      }));
    }
  }, [countryFeatureData, appSetting.currentCountry, setAppSetting]);

  return (
    <div data-test="copro-root-layout" id="copro-container">
      <Helmet>
        <title>{HEADER_COPRO}</title>
      </Helmet>
      <div className="copro-header-section">
        <div className="flex mx-[40px] pt-5 text-xs">
          <CoproBreadcrumbs />
        </div>
      </div>
      <Outlet />
    </div>
  );
};
