import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input} from "reactstrap";
import CheckboxFlag from "../../BinaryFlag";

const ChainofCustody = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const chainOfCustody = ["Mass Balance", "Physical Segregation"];

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="chainOfCustody" className="fw-light">
          Chain of custody
        </Label>
        <Input
          {...computeProps("chainOfCustody")}
          type="select"
          id="chainOfCustody"
          data-test="chainOfCustody"
          invalid={!!errors.chainOfCustody}
        >
          {chainOfCustody.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="multiSiteCreditTransferFlag" className="fw-light">
          Multi-site credit transfer
        </Label>
        <CheckboxFlag
          id="multiSiteCreditTransferFlag"
          name="multiSiteCreditTransferFlag"
          control={control}
          error={errors.multiSiteCreditTransferFlag}
        />
      </FormGroup>
    </div>
  );
};
ChainofCustody.propTypes = {
  data: PropTypes.object,
};
export default ChainofCustody;
