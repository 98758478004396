import {Branch32} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {BATCH_STATUS, MULTI_PART_ICON_FLAG} from "../../constants/batches";

const BatchesStatusChip = ({value, small = false, ...props}) => {
  const upperBatchStatus =
    BATCH_STATUS[value?.toUpperCase().replace(/\s+/g, "_")];
  const fontSize = small ? "text-[10px]" : "";

  const statusToChipClassMap = {
    [BATCH_STATUS.ACTIVE]: "copro-blue-chip",
    [BATCH_STATUS.IN_PROGRESS]: "copro-blue-chip",
    [BATCH_STATUS.TRACKED_OUT]: "copro-blue-chip",
    [BATCH_STATUS.DRAFT]: "copro-blue-chip",
    [BATCH_STATUS.READY_FOR_ALLOCATION]: "copro-blue-chip",
    [BATCH_STATUS.READY_FOR_SUBMISSION]: "copro-blue-chip",
    [BATCH_STATUS.SUBMITTED_FOR_CREDITS]: "copro-green-chip",
    [BATCH_STATUS.NOT_QUALIFIED]: "copro-green-chip",
    [BATCH_STATUS.COMMITTED]: "copro-blue-chip",
    [BATCH_STATUS.READY_TO_COMMIT]: "copro-blue-chip",
  };

  const chipClass = statusToChipClassMap[upperBatchStatus];

  return (
    <div data-test="copro-batch-chip" className="flex items-center" {...props}>
      {value !== MULTI_PART_ICON_FLAG ? (
        chipClass && (
          <div className={`${chipClass} copro-batches-chip ${fontSize}`}>
            {upperBatchStatus}
          </div>
        )
      ) : (
        <Branch32 className="ml-1" />
      )}
    </div>
  );
};

BatchesStatusChip.propTypes = {
  value: PropTypes.string,
  small: PropTypes.bool,
};

export default BatchesStatusChip;
