import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import "./index.scss";
import getInputMap from "modules/common/getInputMap";
import React, {useMemo} from "react";
import {useUserSettings} from "providers/userSettings";

const PoSPLEditForm = ({data}) => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {
    formState: {errors},
  } = useFormContext();
  const inputMap = useMemo(() => getInputMap(data, errors), [data, errors]);

  return (
    <div
      data-test="pos-pl-edit-form"
      className="grid pos-uk-edit-form sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4"
    >
      {inputMap.input("uniqueNumberOfSustainabilityDeclaration", "PoS ID", {
        disabled: true,
      })}
      {inputMap.inputDate("receiptDate", "Date of Receipt", {
        disabled: true,
        format: dateFormat,
      })}
      {inputMap.inputDate("dateOfIssuance", "Issuance Date", {
        disabled: true,
        format: dateFormat,
      })}
      {inputMap.input("placeOfIssuance", "Place of Issuance", {
        disabled: true,
        format: dateFormat,
      })}
      {inputMap.input("supplier", "Supplier", {
        disabled: true,
      })}
      {inputMap.input("typeOfProduct", "Type of Product", {
        disabled: true,
      })}
      {inputMap.input("typeOfRawMaterial", "Type of Raw Material", {
        disabled: true,
      })}
      {inputMap.input("countryOfOrigin", "Feedstock origin", {
        disabled: true,
      })}
      {inputMap.input("certificateType", "Certificate Type", {
        disabled: true,
      })}
      {inputMap.inputNumericFormatted("quantity", "Quantity [m3]", {
        disabled: true,
        decimals: 3,
        decimalFormat,
      })}
      {inputMap.inputNumericFormatted("ghgEmissionSaving", "GHG emissions", {
        disabled: true,
        decimalFormat,
      })}
      {inputMap.input("monthNumber", "M-th", {
        disabled: true,
      })}
      {inputMap.input("type", "Type", {
        disabled: true,
      })}
      {inputMap.inputNumericFormatted(
        "emissionGCO2eqMJ",
        "Emission g CO2eq/MJ",
        {
          disabled: true,
          decimalFormat,
        },
      )}
      {inputMap.inputNumericFormatted("weight", "Weight [TO]", {
        disabled: true,
        decimals: 3,
        decimalFormat,
      })}
      {inputMap.input("countryCode", "Country Code", {
        disabled: true,
      })}

      {inputMap.input("notes", "Notes", {maxLength: "200"})}
    </div>
  );
};

PoSPLEditForm.propTypes = {
  data: PropTypes.object,
};

export default PoSPLEditForm;
