import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const UPDATE_DOCUMENT = gql`
  mutation bioLcCoproEuMassBalanceCustomYield(
    $event: MassBalanceCustomYieldRequest!
  ) {
    bioLcCoproEuMassBalanceCustomYield(event: $event) {
      statusCode
      status
      message
    }
  }
`;

export const UpdateDocAPI = (data) => {
  return client.mutate({
    mutation: UPDATE_DOCUMENT,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuMassBalanceAllRecords"],
  });
};

export default {UPDATE_DOCUMENT, UpdateDocAPI};
