import {Close} from "@bphxd/ds-core-react";
import {
  BUTTONS,
  RENEWABLE_OPTIONS,
  RINS_QUALIFIED_OPTIONS,
  dtnShipmentKeys,
} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useCallback, useEffect, useState} from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import LabeledSelect from "./LabeledSelect";

const EditModalMulti = ({details, modal, setModal, saveDetail}) => {
  const [renewable, setRenewable] = useState("");
  const [rinsQualified, setRinsQualified] = useState("");
  const [renewableError, setRenewableError] = useState("");
  const [rinsQualifiedError, setRinsQualifiedError] = useState("");

  const resetFormState = useCallback(() => {
    if (details) {
      const initialRenewable = details.renewable_shipment_flag
        ? "true"
        : "false";
      const initialRinsQualified = details.credits_qualified || "";
      setRenewable(initialRenewable);
      setRinsQualified(initialRinsQualified);
    }
  }, [details]);

  useEffect(() => {
    if (modal) {
      resetFormState();
    }
  }, [modal, resetFormState]);

  const handleRenewableChange = useCallback((e) => {
    const {value} = e.target;

    setRenewable(value);
    setRenewableError("");

    if (value === "false") {
      setRinsQualified("False");
      setRinsQualifiedError("");
    } else if (value === "true") {
      setRinsQualified("");
    }
  }, []);

  const handleRinsQualifiedChange = useCallback((e) => {
    const {value} = e.target;
    setRinsQualified(value);
    setRinsQualifiedError("");
  }, []);

  const handleSave = useCallback(() => {
    let valid = true;

    if (renewable === "") {
      setRenewableError("Please select Renewable option");
      valid = false;
    }
    if (rinsQualified === "") {
      setRinsQualifiedError("Please select RINS Qualified option");
      valid = false;
    }

    if (!valid) return;

    const updatedDetails = {
      allocated_shipment_id: details?.allocated_shipment_id ?? null,
      update_values: [
        {
          field: dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG,
          value: renewable,
        },
        {
          field: dtnShipmentKeys.CREDITS_QUALIFIED,
          value: rinsQualified,
        },
      ],
    };

    saveDetail(updatedDetails);
    setModal(false);
  }, [details, renewable, rinsQualified, saveDetail, setModal]);

  const handleClose = () => {
    setRenewableError("");
    setRinsQualifiedError("");
    setModal(false);
  };

  const isButtonDisabled = () => {
    let initial_renewable = details.renewable_shipment_flag;

    if (typeof initial_renewable === "boolean") {
      initial_renewable = initial_renewable ? "true" : "false";
    }

    return (
      renewable === initial_renewable &&
      rinsQualified === details.credits_qualified
    );
  };

  return (
    <Modal size="sm" unmountOnClose centered isOpen={modal}>
      <ModalHeader
        className="border border-b"
        close={<Close className="custom-close" onClick={() => handleClose()} />}
      >
        Renewable / RINS Qualified
      </ModalHeader>
      <ModalBody className="p-0">
        <div className="px-4 pt-6">
          <LabeledSelect
            id="renewable"
            label="Renewable"
            value={renewable}
            onChange={handleRenewableChange}
            options={RENEWABLE_OPTIONS}
            error={renewableError}
          />
          <LabeledSelect
            id="rinsQualified"
            label="RINS Qualified"
            value={rinsQualified}
            onChange={handleRinsQualifiedChange}
            options={RINS_QUALIFIED_OPTIONS}
            error={rinsQualifiedError}
            disabled={!renewable}
          />
        </div>
        <div className="flex flex-row w-full border border-t mt-10">
          <Button
            className="w-1/2 !text-gray-400"
            color=""
            onClick={() => handleClose()}
          >
            {BUTTONS.CANCEL}
          </Button>
          <span>
            <div
              style={{
                width: "1px",
                height: "100%",
                backgroundColor: "#ededed",
              }}
            />
          </span>
          <Button
            className="w-1/2 disabled:border-0"
            color=""
            disabled={isButtonDisabled()}
            onClick={handleSave}
          >
            {BUTTONS.SAVE}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

EditModalMulti.propTypes = {
  details: PropTypes.object,
  saveDetail: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
};

export default EditModalMulti;
