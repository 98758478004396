import React from "react";

import {useQuery} from "@apollo/client";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {BUSINESS_PARTNER_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {BUSINESS_PARTNER_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";
import {BUSINESS_PARTNER_GET_QUERY} from "../../../../graphql/GlobalConfigCenter/BusinessPartner";

const BusinessPartnerConfigPage = () => {
  const {data, loading} = useQuery(BUSINESS_PARTNER_GET_QUERY);

  return (
    <Layout
      title={BUSINESS_PARTNER_CONFIG_PAGE.title}
      isGlobal
      description={BUSINESS_PARTNER_CONFIG_PAGE.description}
      tableColumns={BUSINESS_PARTNER_COLUMNS}
      tableData={
        loading ? [] : data?.bioLcGetBusinessPartnerApi.businessPartnerData
      }
    />
  );
};

export default BusinessPartnerConfigPage;
