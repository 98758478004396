import PropTypes from "prop-types";
import {useState} from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import BatchDetailsCard from "modules/co-processing/components/Batches/BatchDetailsCard";

const ITEMS_PER_PAGE = 10;

const BatchDetailsCardView = ({batches}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const currentItems = batches.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE,
  );

  const pageCount = Math.ceil(batches.length / ITEMS_PER_PAGE);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-6">
        {currentItems.map((batch, index) => (
          <div key={index}>
            <BatchDetailsCard batchDetails={batch} />
          </div>
        ))}
      </div>
      <Pagination aria-label="Pagination">
        {Array.from({length: pageCount}, (_, i) => (
          <PaginationItem key={i} active={i === currentPage}>
            <PaginationLink onClick={() => handlePageClick(i)}>
              {i + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
      </Pagination>
    </>
  );
};

BatchDetailsCardView.propTypes = {
  batches: PropTypes.array,
};

export default BatchDetailsCardView;
