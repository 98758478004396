import {orderBy, uniqBy} from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";

const MassBalanceFilterContent = ({
  defaultValues,
  filterDynamicData = [],
  handleFilterSubmit,
  allFiltersDisabled,
  isAllAvailable = false,
}) => {
  const [balanceType, setBalanceType] = useState();
  const [locationGroup, setLocationGroup] = useState();
  const [periodValue, setPeriodValue] = useState();
  const [balanceGroup, setBalanceGroup] = useState();

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setBalanceType(defaultValues?.mbBalanceTypeId || null);
      setLocationGroup(defaultValues?.mbLocationGroupId || null);
      setPeriodValue(defaultValues?.mbPeriodId || null);
      setBalanceGroup(defaultValues?.mbBalanceGroupId || null);
    }
  }, [
    filterDynamicDataResult,
    defaultValues?.mbBalanceTypeId,
    defaultValues?.mbLocationGroupId,
    defaultValues?.mbPeriodId,
    defaultValues?.mbBalanceGroupId,
  ]);

  const typeList = useMemo(() => {
    if (filterDynamicDataResult?.length > 0) {
      const typeItems = filterDynamicDataResult?.map((item) => ({
        mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
        mbBalanceTypeId: item?.mbBalanceType?.mbBalanceTypeId,
      }));
      let orderedList = orderBy(
        uniqBy(typeItems, "mbBalanceTypeId"),
        "mbBalanceTypeCode",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbBalanceTypeCode: "All", mbBalanceTypeId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [filterDynamicDataResult, isAllAvailable]);

  const locationList = useMemo(() => {
    if (
      filterDynamicDataResult?.length > 0 &&
      (balanceType || isAllAvailable)
    ) {
      const locationItems = filterDynamicDataResult
        ?.filter((item) => item?.mbBalanceType?.mbBalanceTypeId === balanceType)
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbLocationGroupId: item?.mbLocationGroup?.mbLocationGroupId,
        }));
      let orderedList = orderBy(
        uniqBy(locationItems, "mbLocationGroupId"),
        "mbLocationGroupName",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbLocationGroupName: "All", mbLocationGroupId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [balanceType, filterDynamicDataResult, isAllAvailable]);

  const periodList = useMemo(() => {
    if (
      filterDynamicDataResult?.length > 0 &&
      ((balanceType && locationGroup) || isAllAvailable)
    ) {
      const periodItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.mbBalanceType?.mbBalanceTypeId === balanceType &&
            item?.mbLocationGroup?.mbLocationGroupId === locationGroup &&
            item?.mbBalanceGroup?.mbBalanceGroupId === balanceGroup,
        )
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodName: item?.mbPeriod?.mbPeriodName,
          mbPeriodId: item?.mbPeriod?.mbPeriodId,
          mbPeriodToDate: item?.mbPeriod?.mbPeriodToDate,
        }));
      let orderedList = orderBy(
        uniqBy(periodItems, "mbPeriodId"),
        "mbPeriodToDate",
        "asc",
      );
      if (isAllAvailable) {
        orderedList = [{mbPeriodName: "All", mbPeriodId: null}, ...orderedList];
      }
      return orderedList;
    }
    return [];
  }, [
    filterDynamicDataResult,
    balanceType,
    locationGroup,
    isAllAvailable,
    balanceGroup,
  ]);

  const balanceList = useMemo(() => {
    if (
      filterDynamicDataResult?.length > 0 &&
      ((balanceType && locationGroup) || isAllAvailable)
    ) {
      const balanceItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.mbBalanceType?.mbBalanceTypeId === balanceType &&
            item?.mbLocationGroup?.mbLocationGroupId === locationGroup,
        )
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodName: item?.mbPeriod?.mbPeriodName,
          mbBalanceGroupName: item?.mbBalanceGroup?.mbBalanceGroupName,
          mbBalanceGroupId: item?.mbBalanceGroup?.mbBalanceGroupId,
        }));
      let orderedList = orderBy(
        uniqBy(balanceItems, "mbBalanceGroupId"),
        "mbBalanceGroupName",
        "desc",
      );
      if (isAllAvailable) {
        orderedList = [
          {mbBalanceGroupName: "All", mbBalanceGroupId: null},
          ...orderedList,
        ];
      }
      return orderedList;
    }
    return [];
  }, [filterDynamicDataResult, balanceType, locationGroup, isAllAvailable]);

  useEffect(() => {
    if (
      balanceType &&
      !typeList.find((item) => item?.mbBalanceTypeId === balanceType)
    ) {
      if (typeList?.length > 0) {
        setBalanceType(typeList[0]?.mbBalanceTypeId);
      } else {
        setBalanceType(undefined);
      }
    }
  }, [balanceType, typeList]);

  useEffect(() => {
    if (
      locationGroup &&
      !locationList.find((item) => item?.mbLocationGroupId === locationGroup)
    ) {
      if (locationList?.length > 0) {
        setLocationGroup(locationList[0]?.mbLocationGroupId);
      } else {
        setLocationGroup(undefined);
      }
    }
  }, [locationList, locationGroup]);

  useEffect(() => {
    if (
      periodValue &&
      !periodList.find((item) => item?.mbPeriodId === periodValue)
    ) {
      if (periodList?.length > 0) {
        setPeriodValue(periodList[0]?.mbPeriodId);
      } else {
        setPeriodValue(undefined);
      }
    }
    if (periodList?.length > 0 && !periodValue) {
      setPeriodValue(periodList[0]?.mbPeriodId);
    }
  }, [periodList, periodValue]);

  useEffect(() => {
    if (
      balanceGroup &&
      !balanceList.find((item) => item?.mbBalanceGroupId === balanceGroup)
    ) {
      if (balanceList?.length > 0) {
        setBalanceGroup(balanceList[0]?.mbBalanceGroupId);
      } else {
        setBalanceGroup(undefined);
      }
    }
    if (balanceList?.length > 0 && !balanceGroup) {
      setBalanceGroup(balanceList[0]?.mbBalanceGroupId);
    }
  }, [balanceList, balanceGroup]);

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="mbBalanceTypeId">Type</Label>
          <Input
            type="select"
            id="type"
            data-test="type"
            value={balanceType}
            onChange={(e) => {
              setBalanceType(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-60 ${
              (!isAllAvailable && typeList?.length === 1) || allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={
              (!isAllAvailable && typeList?.length === 1) || allFiltersDisabled
            }
          >
            {typeList?.length > 0 &&
              typeList?.map((type) => (
                <option
                  key={type?.mbBalanceTypeId}
                  value={type?.mbBalanceTypeId}
                >
                  {type?.mbBalanceTypeCode}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbLocationGroupId">Location</Label>
          <Input
            type="select"
            id="mbLocationGroupId"
            data-test="mbLocationGroupId"
            value={locationGroup}
            onChange={(e) => {
              setLocationGroup(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-60 ${
              (!isAllAvailable && locationList?.length === 1) ||
              allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={
              (!isAllAvailable && locationList?.length === 1) ||
              allFiltersDisabled
            }
          >
            {locationList?.length > 0 &&
              locationList?.map((location) => (
                <option
                  key={location?.mbLocationGroupId}
                  value={location?.mbLocationGroupId}
                >
                  {location?.mbLocationGroupName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbBalanceGroupId">Balance</Label>
          <Input
            type="select"
            id="balance"
            data-test="balance"
            value={balanceGroup}
            onChange={(e) => {
              setBalanceGroup(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-32 ${
              (!isAllAvailable && balanceList?.length === 1) ||
              allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={
              (!isAllAvailable && balanceList?.length === 1) ||
              allFiltersDisabled
            }
          >
            {balanceList?.length > 0 &&
              balanceList?.map((balance) => (
                <option
                  key={balance?.mbBalanceGroupId}
                  value={balance?.mbBalanceGroupId}
                >
                  {balance?.mbBalanceGroupName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbPeriodId">Period</Label>
          <Input
            type="select"
            id="mbPeriodId"
            data-test="mbPeriodId"
            value={periodValue}
            onChange={(e) => {
              setPeriodValue(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-60 ${
              (!isAllAvailable && periodList?.length === 1) ||
              allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={
              (!isAllAvailable && periodList?.length === 1) ||
              allFiltersDisabled
            }
          >
            {periodList?.length > 0 &&
              periodList?.map((period) => (
                <option key={period?.mbPeriodId} value={period?.mbPeriodId}>
                  {period?.mbPeriodName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup className="flex flex-col-reverse">
          <Button
            data-test="filter-submit-button"
            color="standard-tertiary rounded-0"
            type="button"
            form="mb-filter-form"
            onClick={() =>
              handleFilterSubmit(
                balanceType,
                locationGroup,
                periodValue,
                balanceGroup,
              )
            }
            disabled={allFiltersDisabled}
          >
            Apply
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

MassBalanceFilterContent.propTypes = {
  defaultValues: PropTypes.object,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
  allFiltersDisabled: PropTypes.bool,
  isAllAvailable: PropTypes.bool,
};

export default MassBalanceFilterContent;
