import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@apollo/client";
import {Input} from "antd";
import Fuse from "fuse.js";
import {POS_LIST, POS_FILTER_LIST, downloadExportFile} from "graphql/pos";
import "modules/common/Menu/index.scss";
import GeneralActionsMenu from "modules/common/Table/ActionsMenu/GeneralActionsMenu";
import React, {useState, useMemo, useEffect} from "react";
import useRowSelection from "modules/common/Table/useRowSelection";
import TableWidget from "modules/common/Table/TableWidget";
import {ErrorModalBasic} from "modules/common/StatusModals/ErrorModal";
import usePoSExtractor from "modules/common/usePoSExtractor";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import {singleDateSort} from "modules/common/Table";
import DownloadAnnualItem from "modules/common/Table/ActionsMenu/DownloadAnnualItem";
import {DownloadCSVItem} from "modules/common/Table/ActionsMenu";
import useUploadDocument from "modules/common/useUploadDocument/useUploadDocument";
import {uploadPoSFile} from "graphql/file-upload";
import {getFuseOptions} from "utils/helpers/fuseConfig";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {getPosColumns} from "./content/posColumns";
import {
  getLocationMap,
  getFeedstockMap,
  getDefaultFilterOptions,
  getFuelMap,
} from "./posUKFilterHelpers";
import CountryDropdown from "./CountryDropdown";
import PosDashboardFilter from "./PosDashboardFilter";

const activeFiltersDefault = {
  fuelType: [],
  feedstockType: [],
  location: [],
};

const defaultFilterMap = {
  fuelType: false,
  feedstockType: false,
  location: false,
};

const DEFAULT_PAGE_SIZE = 20;

const PosDashboard = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const posColumns = getPosColumns(decimalFormat);
  const [search, setSearch] = useState("");
  const [activefilters, setActiveFilters] = useState(activeFiltersDefault);
  const [masterFilter, setMasterFilter] = useState("");
  const [pageNumber, setPage] = useState(1);
  const [exportError, setExportError] = useState(false);

  const {data, error, refetch} = useQuery(POS_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const handleDocumentSubmit = (file) => {
    return uploadPoSFile("###", file, "gbr");
  };

  const [uploadDocumentButton] = useUploadDocument(
    handleDocumentSubmit,
    refetch,
  );

  const isNoActiveFilter =
    !activefilters.fuelType.length &&
    !activefilters.feedstockType.length &&
    !activefilters.location.length;

  const {data: initialFilterData, loading: filterLoading} = useQuery(
    POS_FILTER_LIST,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const filterData = initialFilterData?.getPOSFilterList?.data?.GBR_ISCC;
  const defaultFilterOptions = getDefaultFilterOptions(filterData);

  const [filterOptions, setFilterOptions] = useState(
    getDefaultFilterOptions(filterData),
  );

  useEffect(() => {
    setFilterOptions(getDefaultFilterOptions(filterData));
  }, [filterData]);

  const locationMap = getLocationMap(filterData);
  const feedstockMap = getFeedstockMap(filterData);
  const fuelMap = getFuelMap(filterData);

  const loading =
    !data && !error
      ? true
      : data &&
        data.getPOSRecordList &&
        data?.getPOSRecordList?.lastEvaluatedKey?.SK;

  const posData = useMemo(
    () => data?.getPOSRecordList?.data?.GBR_ISCC ?? [],
    [data?.getPOSRecordList?.data?.GBR_ISCC],
  );

  const [selectedRows, rowSelection] = useRowSelection(posData, "posId");

  const {RefreshButton} = usePoSExtractor({country: "GBR"}, () => {
    refetch();
  });

  const formatedData = applyDateFormat(
    posData,
    ["vesselReceiptDate", "issuanceDate"],
    dateFormat,
    "DD/MM/YYYY",
    [],
    decimalFormat,
    3,
  );

  const keys = [
    "issuanceDate",
    "feedstockType",
    "deliveringVessel",
    "euVoluntarySchemePartialProofNumber",
  ];

  const fuseOptions = getFuseOptions(keys);

  const dataFuse = new Fuse(formatedData, fuseOptions);
  const items = search
    ? dataFuse.search(search).map(({item}) => item)
    : formatedData;

  return (
    <BasePageLayout
      pageTitle="Proof of Sustainability (PoS)"
      rightContent={<CountryDropdown />}
      tabsData={null}
    >
      <BasePageLayoutContent>
        <div className="flex flex-col justify-between px-5 py-4 mt-10 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
          <div className="flex flex-col items-start sm:items-center sm:flex-row gap-y-2 sm:gap-x-3">
            <GeneralActionsMenu>
              <DownloadCSVItem
                data={selectedRows}
                onDownload={async (rows) => {
                  const {
                    data: {posDataExport: response},
                  } = await downloadExportFile(null, rows, "GBR", dateFormat);
                  return response;
                }}
              />
              <DownloadAnnualItem
                pageTitle="Please select the year for which you need to download the PoS data"
                onDownload={async (year) => {
                  const {
                    data: {posDataExport: response},
                  } = await downloadExportFile(year, null, "GBR", dateFormat);
                  return response;
                }}
              />
            </GeneralActionsMenu>
            <PosDashboardFilter
              activefilters={activefilters}
              setActiveFilters={setActiveFilters}
              masterFilter={masterFilter}
              setFilterOptions={setFilterOptions}
              defaultFilterOptions={defaultFilterOptions}
              isNoActiveFilter={isNoActiveFilter}
              filterOptions={filterOptions}
              fuelMap={fuelMap}
              locationMap={locationMap}
              feedstockMap={feedstockMap}
              setPage={setPage}
              refetch={refetch}
              activeFiltersDefault={activeFiltersDefault}
              setMasterFilter={setMasterFilter}
              defaultFilterMap={defaultFilterMap}
              filterLoading={filterLoading}
            />
          </div>
          <div
            data-test="container-search-item"
            className="flex items-center gap-x-3"
          >
            {uploadDocumentButton}

            {RefreshButton}
            <Input
              data-test="table-input-search"
              className="w-56 rounded"
              size="large"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <TableWidget
          columns={posColumns.map((column) =>
            singleDateSort(column, dateFormat),
          )}
          data={items}
          rowKey={(obj) => obj.posId}
          loading={loading}
          pagination={{
            current: pageNumber,
            onChange: (page) => {
              setPage(page);
            },
          }}
          rowSelection={rowSelection}
          data-test="pos-ukdashboard"
        />
        <ErrorModalBasic
          visible={exportError}
          title="No data is available to export"
          onCancel={() => setExportError(false)}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default PosDashboard;
