import {gql} from "@apollo/client";

export const GET_NOTIFICATION = gql`
  query MyQuery($siteReferenceId: String, $divisionId: String) {
    bioLcIncomingTaskManagerApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      data {
        documents {
          docName
          id
          modifiedLast
          certificateInboundEuId
        }
        latestDocument
        state
        total
      }
      error
      statusCode
    }
  }
`;

export default GET_NOTIFICATION;
