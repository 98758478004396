import React from "react";
import {useDroppable} from "@dnd-kit/core";
import PropTypes from "prop-types";

const Droppable = (props) => {
  const {isOver, setNodeRef} = useDroppable({
    id: props.id,
  });
  const style = {
    border: isOver ? "1px dashed green" : undefined,
  };

  return (
    <div ref={setNodeRef} style={style}>
      {props.children}
    </div>
  );
};

Droppable.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};
export default Droppable;
