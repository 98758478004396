import PropTypes from "prop-types";
import tw from "twin.macro";
import {TIMESTAMP_TEXT} from "modules/forecasting/constants/inventory";
import GasAndDieselLegend from "modules/forecasting/components/GasAndDieselLegends";
import RinLegend from "modules/forecasting/components//RinLegend";
import IndicatorCaption from "../IndicatorCaption";

const IVPUpdatedTimeLabel = tw.div`
  text-sm text-right text-[#89888c]
  font-normal leading-relaxed
  ml-auto
`;
const StyledFlexRow = tw.div`flex flex-row pl-3`;

const GraphSection = ({
  isDataView,
  IVPDate,
  loading = false,
  isCredits = false,
  isWestCoast = false,
  isUnpublished = false,
  view,
  children,
}) => (
  <div className="col-span-full pt-3" data-test="graph-section">
    <StyledFlexRow>
      {isDataView && (
        <IVPUpdatedTimeLabel data-test="updated-time-info">
          {IVPDate && `${TIMESTAMP_TEXT.FORECAST}: ${IVPDate}`}
        </IVPUpdatedTimeLabel>
      )}
    </StyledFlexRow>

    <StyledFlexRow className="pl-3 justify-between" data-test="graph-legends">
      {isCredits ? (
        <RinLegend isWestCoast={isWestCoast} />
      ) : (
        <GasAndDieselLegend />
      )}
      <IndicatorCaption
        view={view}
        isWestCoast={isWestCoast}
        isCredits={isCredits}
      />
    </StyledFlexRow>

    {!loading && (
      <div className="flex flex-col gap-2 md:justify-between md:flex-row">
        {children}
      </div>
    )}
  </div>
);

GraphSection.propTypes = {
  isDataView: PropTypes.bool,
  isCredits: PropTypes.bool,
  isWestCoast: PropTypes.bool,
  isUnpublished: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.node,
  view: PropTypes.string,
  IVPDate: PropTypes.string,
};

export default GraphSection;
