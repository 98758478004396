import {gql} from "@apollo/client";

export const COPRO_EU_LANDING_PAGE_CONFIG_CENTER = gql`
  query bioLcCoproEuLandingPage {
    bioLcCoproConfigCenterLocationDetails {
      Records {
        location_id
        location_code
        location_name
        mass_balance_cycle
      }
    }
    bioLcCoproConfigCenterMaterialMaster {
      Records {
        material_id
        material_code
        material_short_name
        material_long_name
        material_type
      }
    }
  }
`;

export const COPRO_EU_GET_AVAILABLE_QTY = gql`
  query bioLcCoproEuGetAvailableQuantity(
    $event: CoproEuGetAvailableQuantityRequest!
  ) {
    bioLcCoproEuMassBalanceAvailableQuantity(event: $event) {
      mbLocation
      totalQuantity
      data {
        materialCode
        quantity
        materialStatus
        closingDate
      }
    }
  }
`;

export default {
  COPRO_EU_LANDING_PAGE_CONFIG_CENTER,
  COPRO_EU_GET_AVAILABLE_QTY,
};
