export const UPDATED_TEXT = "Updated";
export const JUST_NOW = "just now";
export const SECONDS = "seconds";
export const MINUTES = "minutes";
export const HOURS = "hours";
export const ADD_COMMENTS = "ADD COMMENTS";
export const ADJUSTMENT_SUMMARY = "ADJUSTMENT SUMMARY";
export const SAVE_CHANGES = "SAVE CHANGES";
export const SAVE_DEAL_NUMBER = "SAVE DEAL NUMBER";
export const SAVING = "SAVING...";
export const CONFIRM = "CONFIRM";
export const SUBMIT = "SUBMIT";
export const PREV = "PREVIOUS";
export const NEXT = "NEXT";
export const PUBLISH_VIEW = "PUBLISH VIEW";
export const SUBMIT_ORDER = "SUBMIT ORDER TO T&S";
export const SUBMIT_SUCCESSFUL =
  "Successful. Your order has been submitted to T&S";
export const CANCEL = "Cancel";
export const SUCCESS_SAVE =
  "Successful. Your changes have been tracked in system.";
export const EMAIL_PREVIEW_HEADER = "Email Preview";
export const PUBLISH_VIEW_HEADER = "Publish View";

export const GRAPH_LABEL = {
  PREVIOUS_FORECAST: "Previous Forecast",
  CURRENT_FORECAST: "Current Forecast",
  PREVIOUS: "Previous",
  CURRENT: "Current",
  FORECAST: "Forecast",
  TOTAL_FORECAST: "Total Forecast",
};

export const TOOLTIP_LABEL = {
  PREVIOUS_FORECAST: "Previous Forecast",
  CURRENT_FORECAST: "Current Forecast",
  FORECAST: "Forecast",
  ACTUALS: "Actuals",
  VARIANCE: "Variance",
};

export const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const DROPDOWN_TREND_LABELS = {
  FIVE_YEAR_AVG: "5YR AVG",
  MOM_TREND: "MOM TREND",
};

export const ROUTES = {
  WEST_COAST: "WC",
  EAST_COAST: "EC",
};

export const FORECAST_DISPLAY_LABELS = {
  PREVIOUS: "PREVIOUS",
  CURRENT: "CURRENT",
  FORECAST: "FORECAST",
};

export const EVENT_TYPES = {
  CHANGE: "change",
  BLUR: "blur",
  CLICK: "click",
  MOUSE_DOWN: "mousedown",
};

export const LEGEND = {
  GAS: "GASOLINE",
  DIESEL: "DIESEL",
  D4: "D4",
  D5: "D5",
  D6: "D6",
  TOTAL: "TOTAL",
};

export const OBLIGATIONS = {
  PRODUCTION: "Production",
  EXPORTS: "Exports",
  MILITARY_SHIPMENTS: "Military",
  OBLIGATED_TOTAL: "Obligated Total",
  ALASKA_EXPORTS: "Alaska Diesel Exports",
  HEATING_OIL: "Heating Oil",
  MARINE_SHIPMENTS: "DMA/DMB ECA Marine Fuel",
  RENEWABLE_DIESEL: "Renewable Diesel",
  OTHER_VOLUMES_EXCLUDED: "Other Volumes Excluded",
};

export const UPDATE_REASONS = {
  SUPPLY_ISSUE: "Supply Issue",
  MARKET_ADJUST: "Market Adjust",
  REFINERY_SHORT: "Refinery Short",
  REFINERY_EXC: "Refinery Exc",
  TRANSIT_EARLY: "Transit Early",
  TRANSIT_LATE: "Transit Late",
  SYSTEM_ADJUST: "System Adjust",
};

export const ERRORS = {
  FAILED_UPDATED: "Error: Failed Updating Values",
  FAILED_FETCH: "Error: Failed Fetching Forecast Data",
  REASON_CODE_REQUIRED: "Reason code is required.",
  ADD_REASON_CODE: "Add reason code",
  FORECAST_DATE: "Forecast date not available",
};

export const MEASUREMENTS = {
  KILOBARRELS: "(KB)",
  BARRELS: "(BBLS)",
  RINS: "(RINS)",
};

export const PLACEHOLDER_TEXT = {
  ADD_COMMENTS: "Add Comments",
};

// Userfeed back: The ask was to have production first in the list
export const GAS_MEASUREMENTS = [
  OBLIGATIONS.PRODUCTION,
  OBLIGATIONS.EXPORTS,
  OBLIGATIONS.OTHER_VOLUMES_EXCLUDED,
  OBLIGATIONS.OBLIGATED_TOTAL,
];

export const DIESEL_MEASUREMENTS = [
  OBLIGATIONS.PRODUCTION,
  OBLIGATIONS.MARINE_SHIPMENTS,
  OBLIGATIONS.ALASKA_EXPORTS,
  OBLIGATIONS.MILITARY_SHIPMENTS,
  OBLIGATIONS.HEATING_OIL,
  OBLIGATIONS.EXPORTS,
  OBLIGATIONS.RENEWABLE_DIESEL,
  OBLIGATIONS.OTHER_VOLUMES_EXCLUDED,
  OBLIGATIONS.OBLIGATED_TOTAL,
];

export const TABLE_TITLE = {
  GAS_OBLIGATION: "Gas Obligation (BBLS)",
  DIESEL_OBLIGATION: "Diesel Obligation (BBLS)",
  TOTAl_OBLIGATION: "Total Obligation (BBLS)",
  SAVED_VERSIONS: "Saved Versions",
  OBLIGATED_VOLUME: "Obligated Volume (BBLS)",
  PUBLISHED_VIEWS: "Published Views",
  RINS: "RINS",
};
TABLE_TITLE.getTitle = (forecastType) =>
  ({
    credits: TABLE_TITLE.RINS,
    deficits: TABLE_TITLE.OBLIGATED_VOLUME,
    // eslint-disable-next-line prettier/prettier
  }[forecastType]);

export const INDICATOR_CAPTION = {
  DATA_SCREEN: "* Previous Months' Indicators show SAP PR4 minus IVP.",
  EAST_COAST_DATA_SCREEN:
    "* Previous Months indicators show historical weighted average.",
  DATA_SCREEN_SECOND_LINE:
    "* Current and Forecast Indicators show latest IVP minus  previous IVP.",
  UNPUBLISHED_SCREEN: "* Indicators show Data minus User Inputs.",
  PUBLISHED_SCREEN:
    "* Indicators show Actuals minus Forecast submitted to T&S.",
};

export const KPI = {
  DEFICIT: {
    DATA_VARIANCE_PERCENT:
      "PREV MONTH SYSTEM FORECAST UNDERSTATED / OVERSTATED (%)",
    DATA_VARIANCE_VALUE:
      "PREV MONTH SYSTEM FORECAST UNDERSTATED / OVERSTATED (BBLS)",
    UNPUBLISHED_VARIANCE_PERCENT:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (%)",
    UNPUBLISHED_VARIANCE_VALUE:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (BBLS)",
    PUBLISHED_VARIANCE_PERCENT:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (%)",
    PUBLISHED_VARIANCE_VALUE:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (BBLS)",
    DATA_PAGE_LABEL: "DEFICITS SYSTEM FORECAST VS. ACTUAL",
    UNPUBLISHED_PAGE_LABEL: "DEFICITS USER VS. SYSTEM FORECAST",
    PUBLISHED_PAGE_LABEL: "DEFICITS FORECAST VS. ACTUALS",
  },
  CREDITS: {
    DATA_VARIANCE_PERCENT:
      "PREV MONTH SYSTEM FORECAST UNDERSTATED / OVERSTATED (%)",
    DATA_VARIANCE_VALUE:
      "PREV MONTH SYSTEM FORECAST UNDERSTATED / OVERSTATED (RINS)",
    UNPUBLISHED_VARIANCE_PERCENT:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (%)",
    UNPUBLISHED_VARIANCE_VALUE:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (RINS)",
    PUBLISHED_VARIANCE_PERCENT:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (%)",
    PUBLISHED_VARIANCE_VALUE:
      "PREV MONTH USER FORECAST UNDERSTATED / OVERSTATED (RINS)",
    DATA_PAGE_LABEL: "CREDITS SYSTEM FORECAST VS. ACTUAL",
    UNPUBLISHED_PAGE_LABEL: "CREDITS USER VS. SYSTEM FORECAST",
    PUBLISHED_PAGE_LABEL: "CREDITS FORECAST VS. ACTUALS",
  },
};

export const OPERATION = {
  CURRENT: "CURRENT",
  YEAR: "YEAR",
};

export const REGION = {
  WEST_COAST: "West Coast",
  EAST_COAST: "East Coast",
};

export const REGIONS = {
  EAST: {id: "ec", label: "EAST COAST"},
  WEST: {id: "wc", label: "WEST COAST"},
};

export const FORECAST_TYPE = {
  DEFICITS: "Deficits",
  CREDITS: "Credits",
  RVO: "rvo",
  RIN: "rin",
};

export const forecastTypeCodes = {
  rin: "credits",
  rvo: "deficits",
};

/**
 * @param {string|null} code Forecast type key: rvo or rin or empty string. Empty string value is treated as All filters.
 * @return {array} Returns a list of forecast types by names.
 */
export const codeToForecastTypes = (code) =>
  forecastTypeCodes[code]
    ? [forecastTypeCodes[code]]
    : Object.values(forecastTypeCodes);

export const LABEL_FORECASTING = "Forecasting";

/**
 * Enum for Forecast data types with updated date info.
 * values actuals | forecast
 * @enum {string}
 */
export const SOURCE_DATA_TYPES = Object.freeze({
  ACTUALS: "actuals",
  FORECAST: "forecast",
});

export const TIMESTAMP_TEXT = {
  ACTUALS: "Actuals source data updated as of",
  FORECAST: "Forecast source data updated as of",
};

export const CREDITS_CATEGORIES = ["d4", "d6", "d5"];
export const DEFICITS_CATEGORIES = ["gas", "diesel"];

// KeyValuePairs for each obligation. Potentially can be moved to a constants file
export const gasKeyValuePairs = {
  production: "Production",
  exports: "Exports",
  otherVolumesExcludedGas: "Other Volumes Excluded",
};

export const gasKeyValPairs_WC = {
  obligated_blended_production: "Production",
  non_obligated_export_gas: "Exports",
  other_volumes_excluded_gas: "Other Volumes Excluded",
};

export const dieselKeyValuePairs = {
  production: "Production",
  marineShipments: "DMA/DMB ECA Marine Fuel",
  alaskaExports: "Alaska Diesel Exports",
  militaryShipments: "Military",
  heatingOil: "Heating Oil",
  exports: "Exports",
  renewableDiesel: "Renewable Diesel",
  otherVolumesExcludedDiesel: "Other Volumes Excluded",
};

export const dieselKeyValPairs_WC = {
  production_diesel: "Production",
  marine_shipments_diesel: "DMA/DMB ECA Marine Fuel",
  alaska_exports_diesel: "Alaska Diesel Exports",
  supply_term_military_sales_diesel: "Military",
  heating_oil_diesel: "Heating Oil",
  inter_area_movements_exports_diesel: "Exports",
  renewable_diesel: "Renewable Diesel",
  other_volumes_excluded_diesel: "Other Volumes Excluded",
};

// Used in Adjustment Summary to find the correct key value pair for gas measurements
export const gasKeyValuePairsGeneral = {
  production: "obligated_blended_production",
  exports: "non_obligated_export_gas",
  otherVolumesExcludedGas: "other_volumes_excluded_gas",
};

export const gasKeyValuePairsToPublish = {
  production: "user_obligated_blended_production_gas",
  exports: "user_non_obligated_export_gas",
  otherVolumesExcludedGas: "other_volumes_excluded_gas",
};

export const dieselKeyValuePairsToPublish = {
  production: "production_diesel",
  marineShipments: "marine_shipments_diesel",
  alaskaExports: "alaska_exports_diesel",
  militaryShipments: "supply_term_military_sales_diesel",
  heatingOil: "heating_oil_diesel",
  exports: "inter_area_movements_exports_diesel",
  renewableDiesel: "renewable_diesel",
  otherVolumesExcludedDiesel: "other_volumes_excluded_diesel",
};

export const measurementLabelsKVPairsForCredits = {
  title_d4: "D4 (RINS)",
  d4_current: "Current year vintage",
  d4_previous: "Previous year vintage",
  d4_total: "Total RINS",
  title_d5: "D5 (RINS)",
  d5_current: "Current year vintage",
  d5_previous: "Previous year vintage",
  d5_total: "Total RINS",
  title_d6: "D6 (RINS)",
  d6_current: "Current year vintage",
  d6_previous: "Previous year vintage",
  d6_total: "Total RINS",
  title_totalCredits: "Total (RINS)",
  total_obligation: "Total RINS",
};

export const measurementLabelsKVPairsForEastCredits = {
  title_d4: "D4 (RINS)",
  d4_current: "Current year vintage",
  d4_previous: "Previous year vintage",
  d4_total: "Total RINS",
  title_d6: "D6 (RINS)",
  d6_current: "Current year vintage",
  d6_previous: "Previous year vintage",
  d6_total: "Total RINS",
  title_totalCredits: "Total (RINS)",
  total_obligation: "Total RINS",
};

export const creditsAdjustmentSummaryLabels = {
  d4_current: "D4 Current Year Vintage",
  true_up_d4_current: "D4 Current Year Vintage",
  d4_previous: "D4 Previous Year Vintage",
  true_up_d4_previous: "D4 Previous Year Vintage",
  d5_current: "D5 Current Year Vintage",
  true_up_d5_current: "D5 Current Year Vintage",
  d5_previous: "D5 Previous Year Vintage",
  true_up_d5_previous: "D5 Previous Year Vintage",
  d6_current: "D6 Current Year Vintage",
  true_up_d6_current: "D6 Current Year Vintage",
  d6_previous: "D6 Previous Year Vintage",
  true_up_d6_previous: "D6 Previous Year Vintage",
};

export const measurementLabelsKVPairsForDeficits = {
  title_gas: "Gasoline Obligation (BBLS)",
  user_obligated_blended_production_gas: "Production",
  user_non_obligated_export_gas: "Exports",
  other_volumes_excluded_gas: "Other Volumes Excluded",
  total_gas: "Obligated Total",
  title_diesel: "Diesel Obligation (BBLS)",
  production_diesel: "Production",
  inter_area_movements_exports_diesel: "Exports",
  supply_term_military_sales_diesel: "Military",
  heating_oil_diesel: "Heating Oil",
  renewable_diesel: "Renewable Diesel",
  alaska_exports_diesel: "Alaska Diesel Exports",
  marine_shipments_diesel: "DMA/DMB ECA Marine Fuel",
  other_volumes_excluded_diesel: "Other Volumes Excluded",
  total_diesel: "Obligated Total",
  title_totalObligation: "Total Obligation (BBLS)",
  total_obligation: "Obligated Total",
};

export const measurementLabelsKVPairsForEastDeficits = {
  title_gas: "Gasoline Obligation (BBLS)",
  user_obligated_blended_production_gas: "Production",
  other_volumes_excluded_gas: "Other Volumes Excluded",
  total_gas: "Obligated Total",
  title_diesel: "Diesel Obligation (BBLS)",
  production_diesel: "Production",
  other_volumes_excluded_diesel: "Other Volumes Excluded",
  total_diesel: "Obligated Total",
  title_totalObligation: "Total Obligation (BBLS)",
  total_obligation: "Obligated Total",
};

export const gasKeyValPairs_EC = {
  obligated_blended_production: "Production",
  other_volumes_excluded_gas: "Other Volumes Excluded",
};

export const dieselKeyValPairs_EC = {
  production_diesel: "Production",
  other_volumes_excluded_diesel: "Other Volumes Excluded",
};

export const keyValuePairsDiff = {
  ec: {gas: gasKeyValPairs_EC, diesel: dieselKeyValPairs_EC},
  wc: {gas: gasKeyValPairs_WC, diesel: dieselKeyValPairs_WC},
};

const generateKeyValuePairsDiffCredits = (keys, vintages) => {
  const keyValuePairsDiffCredit = {};

  keys.forEach((key) => {
    keyValuePairsDiffCredit[key] = {};

    vintages.forEach((vintage) => {
      keyValuePairsDiffCredit[key][vintage] = {
        [`${vintage}_current`]: "Current Year Vintage",
        [`${vintage}_previous`]: "Previous Year Vintage",
      };
    });
  });

  return keyValuePairsDiffCredit;
};

export const keyValuePairsDiffCredits = generateKeyValuePairsDiffCredits(
  ["ec", "wc"],
  [...CREDITS_CATEGORIES].sort(),
);

export const toPublishModalLabels = {
  credits_label: "Credits",
  d4: "D4 - Biomass-Based Diesel",
  d5: "D5 - Advanced Biofuel",
  d6: "D6 - Conventional Renewable Fuel",
  total_credits: "Total (RINS)",
  deficits_label: "Deficits",
  gas: "Gasoline Obligation (BBLS)",
  diesel: "Diesel Obligation (BBLS)",
  total_deficits: "Total Obligation (BBLS)",
};

export const FIELD_LABELS_BY_REGION = {
  ec: {
    credits: measurementLabelsKVPairsForEastCredits,
    deficits: measurementLabelsKVPairsForEastDeficits,
  },
  wc: {
    credits: measurementLabelsKVPairsForCredits,
    deficits: measurementLabelsKVPairsForDeficits,
  },
};

export const keyToBarCategory = {
  d4_total: "d4",
  d5_total: "d5",
  d6_total: "d6",
  total_gas: "gas",
  gas_total: "gas", // needed this for the total api query, have to find a better solution
  diesel_total: "diesel",
  total_diesel: "diesel",
  total_obligation: "monthTotal",
};

export const LandingTableLabels = {
  CREDITS: {
    d4_total: "D4 - Biomass-Based Diesel",
    d5_total: "D5 - Advanced Biofuel",
    d6_total: "D6 - Conventional Renewable Fuel",
    total_obligation: "Total RINS",
  },
  DEFICITS: {
    total_gas: "Gasoline",
    total_diesel: "Diesel",
    total_obligation: "Total Obligation",
  },
};
