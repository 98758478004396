import PropTypes from "prop-types";
import {sortBy, uniqBy, startCase} from "lodash";
import {useAppSetting} from "providers/appSetting";
import SpinnerLoading from "modules/common/SpinnerLoading";
import Section from "./Section";

const SectionType = ({items, isDashboard}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const uniqItems = uniqBy(items, "title");
  const sortedList = sortBy(uniqItems, "title", "asc");

  return (
    <div data-test="section-type">
      <h1 className="text-4x text-black fw-light mb-0 pb-[48px] px-4">
        {`Welcome to BioVerse ${
          appSetting?.currentCountry?.toLowerCase() !== "global"
            ? startCase(appSetting?.currentCountry?.toLowerCase())
            : ""
        }`}
      </h1>
      <div className="px-4">
        <hr className="border-1 mt-0 mb-6" />
      </div>
      {!isDashboard && (
        <>
          <div className="flex items-center gap-x-4">
            <h4
              data-test="document-type-title"
              className="text-xl font-bold text-indigo-500"
            >
              File uploader
            </h4>
            <div className="w-3/5 border border-gray-100" />
          </div>
          <p data-test="instructions" className="mt-3 text-indigo-500">
            Please select one of the document type
          </p>
        </>
      )}
      <div className="grid grid-cols-1 gap-3 px-4 mt-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
        {sortedList?.map((item, index) => (
          <Section
            key={index}
            link={item.link}
            img={item.img}
            title={item.title}
            description={item.description}
            module={item.module}
            redirectToExternalLink={item.redirectToExternalLink}
            redirectToExternalLinkURL={item.redirectToExternalLinkURL}
          />
        ))}
        {sortedList &&
          sortedList?.length === 0 &&
          appSetting?.featureMappingData !== null && (
            <p className="text-xl pt-7 pb-12">No items to display here...</p>
          )}
        {appSetting?.featureMappingData === null && <SpinnerLoading />}
      </div>
    </div>
  );
};

SectionType.propTypes = {
  items: PropTypes.array.isRequired,
  isDashboard: PropTypes.bool,
};

export default SectionType;
