import moment from "moment";
import {DATE_RANGE_SIZE} from "./components/constants/ConstantsCompliance";

export function createDateFromDDMMYYYY(dateString) {
  const parts = dateString.split("/");
  // Ensure parts array has 3 elements
  if (parts.length !== 3) {
    throw new Error(
      "Invalid date format. Please provide date in DD/MM/YYYY format.",
    );
  }

  // Parse day, month, and year from parts array
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  return new Date(year, month, day);
}

export const customDateFilterFn = (row, columnId, value, dateFormat) => {
  if (value && value.length === DATE_RANGE_SIZE && row.getValue(columnId)) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    const date = createDateFromDDMMYYYY(
      moment(row.getValue(columnId)).format(dateFormat),
    );
    return date >= startDate && date <= endDate;
  }
  return true;
};

export const getFilterValue = (cell, filterId) => {
  const context = cell.getContext();
  const tableState = context ? context.table.getState() : null;
  const columnFilters = tableState ? tableState.columnFilters : [];

  const filterItem = columnFilters.find((item) => item.id === filterId);

  return filterItem ? filterItem.value : null;
};

export default getFilterValue;
