import {useState, useEffect} from "react";
import tw, {styled} from "twin.macro";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import powerBICommonMethods from "utils/helpers/powerBICommonMethods";
import Layout from "../components/Layout";

const PowerBiActualization = () => {
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF", link: "/saf/dashboard"},
    {text: "Report", link: ""},
  ];
  const [accessTokenPowerBI, setAccessTokenPowerBI] = useState();
  useEffect(() => {
    async function fetchPowerBIAccessToken() {
      const accessToken = await powerBICommonMethods.getPowerBIAPIToken();
      setAccessTokenPowerBI(accessToken);
    }
    fetchPowerBIAccessToken();
  }, []);
  return (
    <Layout title="SAF Report" breadcrumbs={breadcrumbItems}>
      <PageLayout data-test="power-bi-base-layout">
        <ReportContainer>
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              id: process.env.REACT_APP_SAF_POWER_BI_ACTUALISATION,
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${process.env.REACT_APP_SAF_POWER_BI_ACTUALISATION}`,
              accessToken: accessTokenPowerBI,
              tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
              settings: {
                panes: {
                  pageNavigation: {
                    position: models.PageNavigationPosition.Left,
                  },
                  filters: {
                    expanded: false,
                  },
                },
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
                background: models.BackgroundType.Transparent,
              },
              width: "100%",
              height: "100vh",
            }}
            cssClassName="h-screen"
          />
        </ReportContainer>
      </PageLayout>
    </Layout>
  );
};
export default PowerBiActualization;
const ReportContainer = styled.div`
  height: 100vh;
  maxheight: 100vmin;
  position: relative;
`;
const PageLayout = tw.div`
  flex flex-col gap-2 pb-10 
`;
