import {displayDate, displayTime} from "./dateHelper";
import DiscardDropdown from "../components/RecycleBin/DiscardDropdown";
import {DAYS} from "../constants/shipments";

export const getDisplayValue = (props, refreshData) => {
  const {row, column, getValue} = props;
  const value = getValue();

  switch (column.id) {
    case "days_to_delete": {
      return (
        <div>
          <div className="copro-discard-chip copro-red-chip w-[90px]">{`${value} ${DAYS}`}</div>
        </div>
      );
    }
    case "shipment_date": {
      const date = value ? displayDate(value, true) : "N/A";
      return <div>{date}</div>;
    }

    case "load_start_time":
    case "load_end_time": {
      const date = value ? displayTime(value) : "N/A";
      return <div>{date}</div>;
    }

    case "total_estimated_volume":
      return <div>{value?.toLocaleString()}</div>;
    case "dropdown_icon":
      return (
        <DiscardDropdown
          refetchDiscardedShipments={refreshData}
          shipmentDetails={row?.original}
        />
      );

    default:
      return <div>{value}</div>;
  }
};

const buildRecycleColumns = (headers, refreshData) => {
  const columns = [];
  Object.keys(headers).forEach((key) => {
    return columns.push({
      id: key,
      accessorKey: key,
      key,
      header: headers[key],
      enableSorting: key !== "dropdown_icon",
      cell: (props) => getDisplayValue(props, refreshData),
    });
  });
  return columns;
};

export default buildRecycleColumns;
