import {EditionItem, RowActionsMenu} from "modules/common/Table/ActionsMenu";
import {updateNetCO2CostApi} from "graphql/gy-enablement/masterDetails/NETSCO2Costs";
import {convertToNumber, formatNumber} from "providers/userSettings";
import {stringSorter, numberSorter} from "../../common/Table";
import {masterNETSCO2CostsSchemaAddEdit} from "../schemas/masterNETSCO2CostsSchema";
import MasterNETSCO2CostsForm from "../components/masterDetails/NETSCO2Costs/NETSCO2CostsForm";

export const getmasterNetsCO2CostsColumns = (decimalFormat) => {
  const masterNetsCO2CostsColumns = [
    {
      title: "Company Code",
      dataIndex: "companyCode",
      key: "companyCode",
      ...stringSorter("companyCode"),
    },
    {
      title: "Compliance Year",
      dataIndex: "complianceYear",
      key: "complianceYear",
      ...numberSorter("complianceYear"),
    },

    {
      title: "Certificate Costs",
      dataIndex: "certificateCosts",
      key: "certificateCosts",
      ...numberSorter("certificateCosts"),
      render: (text) => formatNumber(text, decimalFormat),
    },

    {
      title: "Actions",
      key: "operation",
      fixed: "right",
      align: "center",
      width: 100,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      render: (data) => {
        return (
          <RowActionsMenu>
            <EditionItem
              mode="edit"
              schema={masterNETSCO2CostsSchemaAddEdit}
              modalTitle="NETS CO2 Costs"
              formContent={<MasterNETSCO2CostsForm data={data} />}
              onSubmit={async (values) => {
                const {
                  data: {updateBehgCo2Costs: response},
                } = await updateNetCO2CostApi({
                  PK: data.PK,
                  complianceYear: Number(data.complianceYear),
                  certificateCosts: convertToNumber(
                    values.certificateCosts,
                    decimalFormat,
                  ),
                  companyCode: data.companyCode,
                });
                return response;
              }}
            />
          </RowActionsMenu>
        );
      },
    },
  ];

  return masterNetsCO2CostsColumns;
};

export default {getmasterNetsCO2CostsColumns};
