import {gql} from "@apollo/client";

export const MB_CONFIG_GET_QUERY = gql`
  query bioLcGetMbConfigInstance {
    bioLcGetMbConfigInstance(event: {}) {
      mbConfigInstanceData {
        mbConfigInstanceId
        countryCode
        mbBalanceType
        mbLocationGroup
        balanceCycle
        mbBalanceGroupName
        businessPartnerCode
        businessPartnerName
        divisionCode
        validFrom
        validTo
      }
    }
  }
`;

export default {MB_CONFIG_GET_QUERY};
