import PropTypes from "prop-types";
import {BATCH_BAR_LABELS} from "modules/co-processing/constants/shipments";

const ENDING_INVENTORY_DEFAULT = 10000;
const BatchesVolumeBar = ({
  totalAmount,
  remainingAmount,
  endingAmount,
  volumes = [],
}) => {
  const totalVolume = volumes.reduce((acc, curr) => acc + curr.volume, 0);
  const endingVolume = !endingAmount ? ENDING_INVENTORY_DEFAULT : endingAmount;
  const totalUsed = totalVolume + endingVolume + remainingAmount;
  const scale = totalAmount / totalUsed;

  const remainingPercentage = ((remainingAmount * scale) / totalAmount) * 100;
  const endingPercentage = ((endingVolume * scale) / totalAmount) * 100;

  return (
    <div className="batches-volume-bar">
      <div className="volume-bar" data-test="active-batch-volume-bar">
        <div className="volume-bar__total">
          {volumes.map((volume, index) => {
            const volumePercentage =
              ((volume.shipment_volume * scale) / totalAmount) * 100;
            return (
              <div
                key={index}
                className="volume-bar__volume solid-fill"
                data-test="active-batch-shipment-volume-bar"
                style={{width: `${volumePercentage}%`}}
              >
                <div className="legend">
                  <div className="flex flex-col justify-center">
                    <span className="self-center">|</span>
                    <span className="self-center">{index + 1}</span>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            className="volume-bar__remaining solid-fill"
            data-test="active-batch-remaining-volume-bar"
            style={{width: `${remainingPercentage}%`}}
          >
            <div className="legend">
              <div className="flex flex-col justify-center">
                <span className="self-center">|</span>
                <span className="self-center">
                  {BATCH_BAR_LABELS.REMAINING_VOLUME}
                </span>
              </div>
            </div>
          </div>
          <div
            className={`volume-bar__ended flex flex-col ${
              !endingAmount ? "striped-fill" : "solid-fill"
            }`}
            data-test="active-batch-ending-volume-bar"
            style={{width: `${endingPercentage}%`}}
          >
            <div className="legend">
              <div className="flex flex-col justify-center">
                <span className="self-center">|</span>
                <span className="self-center">
                  {BATCH_BAR_LABELS.ENDING_INVENTORY}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BatchesVolumeBar.propTypes = {
  totalAmount: PropTypes.number,
  remainingAmount: PropTypes.number,
  endingAmount: PropTypes.number,
  volumes: PropTypes.array,
};

export default BatchesVolumeBar;
