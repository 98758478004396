import InfoIcon from "assets/images/info.png";
import {Info24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {formatActualsDateLabel} from "modules/forecasting/helpers/dateUtils";
import "./index.scss";

const BannerInfoIcon = () => (
  <img
    className="h-6 px-2"
    src={InfoIcon}
    alt="Info"
    data-test="timestamp-label-icon"
  />
);

export const TimeStamp = ({label, date}) => (
  <span className="text-sm leading-4 pl-2">{`${label} ${formatActualsDateLabel(
    date,
  )}.`}</span>
);
TimeStamp.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
};

export const BannerWrapper = ({children}) => {
  return (
    <div
      className="bg-[#ededed] h-12 flex mt-3 items-center rounded pl-2"
      data-test="info-banner-wrapper"
    >
      <Info24 />

      {children}
    </div>
  );
};
BannerWrapper.propTypes = {
  children: PropTypes.any,
};

const TimeStampLabel = ({label, date}) => {
  return (
    <div
      className="bg-[#ededed]	mt-3 h-12 flex items-center rounded"
      data-test="timestamp-label"
    >
      <BannerInfoIcon />
      <TimeStamp date={date} label={label} />
    </div>
  );
};

TimeStampLabel.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
};

export default TimeStampLabel;
