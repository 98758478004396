import {gql} from "@apollo/client";

// Fetch query for Processing unit table on Config center(/configuration-center/processing-units)
export const PROCESSING_UNIT_GET_QUERY = gql`
  query bioLcGetProcessingUnitApi {
    bioLcGetProcessingUnitApi {
      processingUnitData {
        locationCode
        locationShortName
        processingUnitCode
      }
    }
  }
`;

export default {PROCESSING_UNIT_GET_QUERY};
