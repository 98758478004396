const combineShipments = (shipments) => {
  const combinedMap = {};

  shipments.forEach((shipment) => {
    const {shipment_id} = shipment;

    // If the shipment_id already exists, merge the objects
    if (combinedMap[shipment_id]) {
      combinedMap[shipment_id] = {...combinedMap[shipment_id], ...shipment};
    } else {
      combinedMap[shipment_id] = shipment;
    }
  });

  return Object.values(combinedMap);
};

export default combineShipments;
