/* eslint-disable prettier/prettier */
import {gql} from "@apollo/client";

export const COPRO_US_TASKS_API = gql`
  query GetCoproUsTasksApiQuery(
    $op: String
    $ids: [String]
    $task_type: String
  ) {
    bioLcTasksApi(event: {op: $op, ids: $ids, task_type: $task_type}) {
      statusCode
      body {
        data {
          inbound_doc_status_us_id
          country_code
          doc_type
          doc_name
          textract_job_id
          textract_status
          file_processed_status
          confidence_score_percentage
          notification_ack
          bv_audit_created_datez
          bv_audit_created_by
          bv_audit_changed_datez
          bv_audit_changed_by
          resource_id {
            cofa_id
            bol_ids
            c14_id
          }
          view_url
          download_url
        }
      }
    }
  }
`;

export default COPRO_US_TASKS_API;
