import {useState} from "react";
import {toast} from "react-toastify";
import {useLazyQuery} from "@apollo/client";
import {Close, Step, Steps} from "@bphxd/ds-core-react";
import {Button, ModalBody, ModalFooter, Modal, ModalHeader} from "reactstrap";
import {COPRO_US_SHIPMENTS_API_SAVE_DTN as SAVE_DTN} from "graphql/coprocessing/shipments";
import {
  CREATE_SHIPMENT,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import {ShipmentType, EnterDetails, Review} from "./Steps";
import {InitialShipmentState, FIRST_STEP} from "./formConfigs";
import ShipmentForm from "./Form";
import {formatBackToUnit, formatTruckRack} from "./formUtils";
import "./index.scss";

const StepConfig = [
  {id: "SHIPMENT_TYPE", label: "Shipment type", Component: ShipmentType},
  {id: "ENTER_DETAILS", label: "Shipment details", Component: EnterDetails},
  {id: "REVIEW", label: "Review", Component: Review},
];

const transformByShipmentType = {
  [SHIPMENT_TYPE.BACK_TO_UNIT]: formatBackToUnit,
  [SHIPMENT_TYPE.TRUCK_RACK]: formatTruckRack,
};

const CreateShipment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(FIRST_STEP);
  const [shipmentDetail, setShipmentDetail] = useState(InitialShipmentState);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const resetForm = () => {
    setShipmentDetail(InitialShipmentState);
    setCurrentStep(FIRST_STEP);
    toggleModal();
  };

  const [saveShipment, {loading: saving}] = useLazyQuery(SAVE_DTN, {
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
        resetForm();
        toast.success("Shipment created successfully!");
      } else {
        toast.error("Something went wrong, failed to save shipment.");
      }

      toggleModal();
    },
  });

  const handleNext = () =>
    setCurrentStep((prev) => Math.min(prev + 1, StepConfig.length - 1));

  const isFinalStep = currentStep === StepConfig.length - 1;

  const handleShipmentFormSubmit = async (formData) => {
    setShipmentDetail((currentState) => ({...currentState, ...formData}));

    if (isFinalStep) {
      try {
        const transformFn = transformByShipmentType[formData.shipment_type];
        const shipmentDetail = transformFn ? transformFn(formData) : formData;

        await saveShipment({
          variables: {dtn_shipment_details: shipmentDetail},
          onError: (error) => {
            toggleModal();
            toast.error(`Failed to save shipment: ${error.message}`);
          },
        });
      } catch (error) {
        console.error(error);
        toast.error(`Failed to save shipment: ${error.message}`);
      }
    } else {
      handleNext();
    }
  };

  const CurrentFormStep = StepConfig?.[currentStep]?.Component;

  return (
    <>
      <Button
        color="primary"
        className="hover-shadow-elevated rounded-0 ml-auto h-10 text-nowrap"
        onClick={toggleModal}
      >
        {CREATE_SHIPMENT}
      </Button>

      {isModalOpen && (
        <Modal
          contentClassName="rounded-0 shadow-xl overflow-hidden"
          className="lg:px-36"
          isOpen={isModalOpen}
          fullscreen={StepConfig[currentStep]?.id === "ENTER_DETAILS"}
          size="lg"
        >
          <ModalHeader
            className="border border-b rounded-0"
            close={<Close onClick={toggleModal} />}
          >
            <div className="pl-3 fs-5">{CREATE_SHIPMENT}</div>
          </ModalHeader>

          <ModalBody className="p-0 position-relative">
            <ShipmentForm
              defaultValues={shipmentDetail}
              onSubmit={handleShipmentFormSubmit}
            >
              <Steps activeStep={currentStep} size="sm">
                {StepConfig.map((step) => (
                  <Step data-test={step.id} key={step.id} label={step?.label} />
                ))}
              </Steps>

              <CurrentFormStep shipmentType={shipmentDetail.shipment_type} />
            </ShipmentForm>
          </ModalBody>

          <ModalFooter>
            <div className="createShipment__btn-group">
              {currentStep > FIRST_STEP && (
                <Button
                  color="light"
                  onClick={() =>
                    setCurrentStep((step) => Math.max(step - 1, FIRST_STEP))
                  }
                >
                  Back
                </Button>
              )}

              <Button
                color="primary"
                className="createShipment__btn"
                disabled={saving}
                form="create_shipment_form"
                type={isFinalStep ? "submit" : ""}
              >
                {isFinalStep ? CREATE_SHIPMENT : "Continue"}
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default CreateShipment;
