import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const ReasonCodeItem = ({
  checked,
  onChange,
  reasonName,
  disabled = false,
  label,
}) => {
  return (
    <>
      {label}
      <Input
        data-test={`forecast-${reasonName}-checkbox`}
        className="forecast-checkbox float-right"
        name={reasonName}
        type="checkbox"
        onChange={(e) => onChange(e)}
        disabled={disabled}
        checked={checked}
      />
    </>
  );
};

ReasonCodeItem.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  reasonName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default ReasonCodeItem;
