import {
  clearNotificationApi,
  getNotificationApi,
  posSharepointExtractorApi,
} from "graphql/pos";
import {MdOutlineSync} from "react-icons/md";

import {useMsal, useAccount} from "@azure/msal-react";
import {useCallback, useMemo} from "react";
import useGlobalNotification from "providers/GlobalNotification/useGlobalNotification";
import Button from "DLS/Button";

const usePoSExtractor = (data, onSuccess) => {
  const {
    triggerNotification,
    clearNotification,
    notificationProps,
    isRefreshButtonDisabled,
    setRefreshButtonDisabled,
  } = useGlobalNotification();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {country} = data;

  const setServerNotificationSeen = useCallback(
    async (id) => {
      const {data: dataItem} = await clearNotificationApi(id, account.username);
      if (dataItem) {
        clearNotification();
      }
    },
    [clearNotification, account.username],
  );

  const getServerNotification = useCallback(
    async (onSuccessNotification, timer) => {
      const {data: dataNotification} = await getNotificationApi(
        account.username,
      );
      if (dataNotification) {
        if (
          !dataNotification?.getNotification?.expectResult &&
          dataNotification?.getNotification?.data?.items?.length === 0
        ) {
          // no pending notifications
          if (timer) {
            clearInterval(timer);
          }
          setRefreshButtonDisabled(false);
        } else if (dataNotification?.getNotification?.errors?.length > 0) {
          // error in notification
          if (timer) {
            clearInterval(timer);
          }
          setRefreshButtonDisabled(false);
          triggerNotification(
            "error",
            "PoS data refresh failed",
            clearNotification,
          );
        } else if (dataNotification?.getNotification?.data?.items?.length > 0) {
          const currentNotification =
            dataNotification?.getNotification?.data?.items?.[0];
          if (currentNotification?.status === "0") {
            // success notification condition
            if (timer) {
              clearInterval(timer);
            }
            setRefreshButtonDisabled(false);
            triggerNotification("success", "PoS data refresh successful", () =>
              setServerNotificationSeen(currentNotification),
            );
            if (onSuccessNotification) {
              await onSuccessNotification();
            }
          }
        }
      }
    },
    [
      account.username,
      clearNotification,
      setServerNotificationSeen,
      triggerNotification,
      setRefreshButtonDisabled,
    ],
  );

  const triggerMassUpload = useCallback(async () => {
    setRefreshButtonDisabled(true);
    triggerNotification(
      "progress",
      "PoS data refresh in progress",
      clearNotification,
    );

    const {data: dataExtractor} = await posSharepointExtractorApi(
      country,
      account.username,
    );

    if (dataExtractor) {
      if (dataExtractor?.posSharePointExtractor?.atLeastOnePdf) {
        getServerNotification(onSuccess, null);
        const timer = setInterval(async () => {
          getServerNotification(onSuccess, timer);
        }, 25000);
      } else {
        setRefreshButtonDisabled(false);
        triggerNotification(
          "error",
          "No PoS data to refresh",
          clearNotification,
        );
      }
    }
  }, [
    account.username,
    clearNotification,
    country,
    getServerNotification,
    onSuccess,
    triggerNotification,
    setRefreshButtonDisabled,
  ]);

  const RefreshButton = useMemo(
    () => (
      <Button
        icon={<MdOutlineSync />}
        type="tertiary"
        size="medium"
        onClick={triggerMassUpload}
        disabled={isRefreshButtonDisabled}
        data-test="button-refresh"
      />
    ),
    [isRefreshButtonDisabled, triggerMassUpload],
  );
  return {notificationProps, RefreshButton};
};

export default usePoSExtractor;
