import {useAccount, useMsal} from "@azure/msal-react";
import {Close} from "@bphxd/ds-core-react";
import {WriteOffAPI} from "graphql/MassBalance/Actions/WriteOff";
import {getQtyandUom} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting/context.js";
import {useEffect, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting.js";
import "../index.css";

const WriteOff = ({isVisibleModal, rowData, divisionCode}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [isProcessing, setProcessing] = useState(false);
  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
    watch,
  } = methods;

  const {quantity, quantity_uom} = getQtyandUom(rowData);
  const watchQuantity = parseFloat(watch("quantity"));

  const onSubmit = async () => {
    const valid = await trigger();
    const {...docData} = getValues();
    if (valid) {
      setProcessing(true);
      const {data: response} = await WriteOffAPI({
        reason: docData.writeOffReason,
        mbInboundQtyEuId: rowData.original.inboundRecord.mbInboundQtyEuId,
        quantity: docData.quantity,
        createdBy: account.username,
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      });

      if (response?.bioLcGlobalMassBalanceDiscardQuantity?.statusCode === 200) {
        setProcessing(false);
        setModalVisible(false);
        toast.success(
          response?.bioLcGlobalMassBalanceDiscardQuantity?.message ??
            "Write off was successfully added",
        );
      } else {
        setProcessing(false);
        toast.error(
          response?.bioLcGlobalMassBalanceDiscardQuantity?.error ??
            "Something went wrong, failed to write off",
        );
      }
    }
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div>
      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered mb-action-modal-344"
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Write off
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <FormProvider {...methods}>
            <Form className="flex flex-col gap-5 text-left">
              <FormGroup>
                <Label for="quantity" className="fw-light">
                  Quantity m³
                </Label>
                <Input
                  type="number"
                  id="quantity"
                  {...computeProps("quantity", {
                    required: "Please enter quantity",
                  })}
                  invalid={!!errors.quantity}
                  maxLength={100}
                />
                {errors.quantity && (
                  <FormFeedback>{errors.quantity.message}</FormFeedback>
                )}
                {!errors.quantity && watchQuantity > quantity && (
                  <FormFeedback className="!block">
                    It cannot exceed the maximum quantity
                  </FormFeedback>
                )}
                <span className="text-xs text-gray-700">{`You have ${quantity} ${quantity_uom} available`}</span>
              </FormGroup>
              <FormGroup>
                <Label for="writeOffReason" className="fw-light">
                  Reason for write off
                </Label>

                <Input
                  type="textarea"
                  rows="3"
                  id="writeOffReason"
                  data-test="writeOffReason"
                  {...computeProps("writeOffReason", {
                    required: "Please enter notes",
                  })}
                  invalid={!!errors.writeOffReason}
                  maxLength={200}
                />
                {errors.writeOffReason && (
                  <FormFeedback>{errors.writeOffReason.message}</FormFeedback>
                )}
              </FormGroup>
            </Form>
          </FormProvider>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !p-0 bg-transparent text-default whitespace-nowrap"
                type="button"
                disabled={
                  isProcessing ||
                  errors.quantity ||
                  !watchQuantity ||
                  watchQuantity > quantity
                }
                onClick={onSubmit}
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Confirm write off
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

WriteOff.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
};

export default WriteOff;
