import {client} from "providers/Apollo";

const accessToken = localStorage.getItem(
  `${process.env.REACT_APP_AD_CLIENTID}-${process.env.REACT_APP_AD_AUTHORITY}-UserAccessToken`,
);

export const handleMutation = (
  apiMutation,
  body,
  refreshQuery,
  onApiSuccess,
  onApiFailure,
  shouldReferesh = false,
) => {
  client
    .mutate({
      mutation: apiMutation,
      variables: body,
      fetchPolicy: "network-only",
    })
    .then(async (response) => {
      onApiSuccess(response);
      if (shouldReferesh) {
        await client.refetchQueries({
          include: [refreshQuery],
        });
      }
    })
    .catch((err) => {
      onApiFailure(err);
    });
};

export const handleQuery = (
  apiQuery,
  body,
  refreshQuery,
  onApiSuccess,
  onApiFailure,
  shouldReferesh = false,
) => {
  client
    .query({
      query: apiQuery,
      variables: body,
      fetchPolicy: "network-only",
    })
    .then(async (response) => {
      onApiSuccess(response);
      if (shouldReferesh) {
        await client.refetchQueries({
          include: [refreshQuery],
        });
      }
    })
    .catch((err) => {
      onApiFailure(err);
    });
};

export default {handleMutation, handleQuery};
