import {DatePicker as ADatePicker} from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

export const {RangePicker: ArangePicker} = ADatePicker;

const DatePicker = React.forwardRef(
  ({className = "", noHeader = false, ...props}, ref) => {
    return (
      <ADatePicker
        ref={ref}
        className={["bp-datepicker", className].join(" ")}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        dropdownClassName={[
          "bp-datepicker-dropdown",
          noHeader ? "--no-header" : "",
        ].join(" ")}
        inputReadOnly
        {...props}
      />
    );
  },
);

DatePicker.propTypes = {
  className: PropTypes.string,
  noHeader: PropTypes.bool,
};

export default DatePicker;

export const RangePicker = React.forwardRef(
  ({className = "", noHeader = false, ...props}, ref) => {
    return (
      <ArangePicker
        ref={ref}
        className={["bp-rangepicker", className].join(" ")}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        dropdownClassName={[
          "bp-rangepicker-dropdown",
          noHeader ? "--no-header" : "",
        ].join(" ")}
        inputReadOnly
        {...props}
      />
    );
  },
);

RangePicker.propTypes = {
  className: PropTypes.string,
  noHeader: PropTypes.bool,
};
