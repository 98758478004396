import DatePicker from "modules/common/DatePicker";
import FormField from "modules/common/FormField";
import Select from "modules/common/Select";
import InputNumeric from "modules/common/lnputNumeric";
import Input from "modules/common/Input";
import Textarea from "modules/common/Textarea";
import {Controller} from "react-hook-form";
import moment from "moment";
import {formatNumber} from "providers/userSettings";

const handleNumInputChange = (e, i, handleQuantityChange) => {
  handleQuantityChange(e, i);
};

const handleDateInputChange = (e, i, handleDateChange) => {
  handleDateChange(e, i);
};

const getInputMap = (data, errors) => {
  return {
    inputSelect: (key, label, options) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={data[key] || undefined}
          render={({field}) => (
            <Select
              {...field}
              placeholder={label}
              data-test={key}
              defaultValue={data[key]}
              {...options}
            >
              {[
                {label: "Yes", value: true},
                {label: "No", value: false},
              ].map((object) => (
                <Select.Option value={object.value}>
                  {object.label}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>
    ),
    inputSelectCustom: (key, label, options) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          data-test={key}
          defaultValue={data[key] || undefined}
          {...options}
        />
      </FormField>
    ),
    inputNumeric: (key, label, options) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={data[key]}
          render={({field}) => (
            <InputNumeric
              {...field}
              value={field.value === null ? "" : field.value}
              placeholder={field.value ? label : "N/A"}
              decimals={2}
              title={field.value}
              max={100000000}
              min={0}
              allowNegative
              data-test={key}
              {...options}
            />
          )}
        />
      </FormField>
    ),
    inputNumericFormatted: (key, label, options) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={formatNumber(data[key], options.decimalFormat)}
          render={({field}) => (
            <InputNumeric
              {...field}
              value={field.value === null ? "" : field.value}
              placeholder={field.value ? label : "N/A"}
              decimals={2}
              title={field.value}
              max={100000000}
              min={0}
              allowNegative
              data-test={`formatted-value-${key}`}
              {...options}
            />
          )}
        />
      </FormField>
    ),
    dynamicInputNumericFormatted: (key, val, label, options) => (
      <FormField label="" errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={val || label}
          render={({field}) => (
            <InputNumeric
              {...field}
              value={val === null ? "" : val}
              placeholder={val || label}
              decimals={2}
              title={field.value}
              max={100000000}
              min=""
              allowNegative
              data-test={key}
              {...options}
              onChange={(evnt) =>
                handleNumInputChange(
                  evnt,
                  options.index,
                  options.handleQuantityChange,
                )
              }
            />
          )}
        />
      </FormField>
    ),
    input: (key, label, options) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={data[key]}
          render={({field}) => (
            <Input
              {...field}
              title={field.value}
              placeholder={field.value ? label : "N/A"}
              data-test={key}
              {...options}
            />
          )}
        />
      </FormField>
    ),
    inputHidden: (key, val, label) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue="val"
          render={() => <input type="hidden" name={key} value={val} />}
        />
      </FormField>
    ),
    inputDate: (key, label, options = {format: undefined}) => (
      <FormField label={label} errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          render={({field}) => {
            return (
              <DatePicker
                {...field}
                value={field.value !== "N/A" ? field.value : ""}
                placeholder={label}
                data-test={key}
                {...options}
              />
            );
          }}
        />
      </FormField>
    ),
    dynamicInputDate: (key, val, label, options = {format: undefined}) => (
      <FormField errorMessage={errors[key]?.message}>
        <Controller
          name={key}
          defaultValue={val !== "" ? moment(val, "MM/DD/YYYY") : ""}
          render={({field}) => {
            return (
              <DatePicker
                {...field}
                value={field.value !== "" ? field.value : "Date"}
                placeholder={label}
                data-test={key}
                disabledDate={(current) =>
                  current && current > moment().endOf("day")
                }
                {...options}
                onChange={(evnt) =>
                  handleDateInputChange(
                    evnt,
                    options.index,
                    options.handleDateChange,
                  )
                }
              />
            );
          }}
        />
      </FormField>
    ),
    inputCustomDate: (key, label, options) => {
      return (
        <FormField label={label} errorMessage={errors[key]?.message}>
          <Controller
            name={key}
            defaultValue={
              data?.[key] !== null &&
              data?.[key] !== "" &&
              data?.[key] !== undefined &&
              data?.[key] !== "N/A"
                ? moment(data?.[key])
                : ""
            }
            render={({field}) => {
              return (
                <DatePicker
                  {...field}
                  placeholder={label}
                  data-test={key}
                  {...options}
                />
              );
            }}
          />
        </FormField>
      );
    },
    inputTextArea: (key, label, options) => (
      <FormField
        label={label}
        className="col-span-1 md:col-span-3"
        errorMessage={errors[key]?.message}
      >
        <Controller
          name={key}
          defaultValue={data[key]}
          render={({field}) => (
            <Textarea
              {...field}
              placeholder={label}
              data-test={key}
              maxLength={500}
              {...options}
            />
          )}
        />
      </FormField>
    ),
    inputError: (label, options, key = "sumLess") => {
      if (errors.sumLess) {
        return (
          <span
            className={`text-red-500 text-xs col-span-1 md:col-span-3 ${
              errors.sumLess?.message && errors.sumLess?.message !== ""
                ? "block"
                : "hidden"
            }`}
          >
            Sum of Portion of Contract - Animal Fats, Portion of Contract - Used
            Cooking Oil,Portion of Contract - Soybean Oil, Portion of Contract -
            Canola Oil, Portion of Contract - Distillers Corn Oil, Portion of
            Contract - Other should be equal to 100
          </span>
        );
      }

      if (errors.sumGreater) {
        return (
          <span
            className={`text-red-500 text-xs col-span-1 md:col-span-3 ${
              errors.sumGreater?.message && errors.sumGreater?.message !== ""
                ? "block"
                : "hidden"
            }`}
          >
            Sum of Portion of Contract - Animal Fats, Portion of Contract - Used
            Cooking Oil,Portion of Contract - Soybean Oil, Portion of Contract -
            Canola Oil, Portion of Contract - Distillers Corn Oil, Portion of
            Contract - Other cannot exceed 100
          </span>
        );
      }

      return null;
    },
  };
};

export default getInputMap;
