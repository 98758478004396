import PropTypes from "prop-types";
import * as StyledHeadings from "./styles";

const styledVariants = {
  1: StyledHeadings.StyledHeading1,
  2: StyledHeadings.StyledHeading2,
  3: StyledHeadings.StyledHeading3,
  4: StyledHeadings.StyledHeading4,
  5: StyledHeadings.StyledHeading5,
  6: StyledHeadings.StyledHeading6,
};

const Heading = ({children, level, ...props}) => {
  const StyledHeading = styledVariants[level];
  return <StyledHeading {...props}>{children}</StyledHeading>;
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
};

export default Heading;
