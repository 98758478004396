import React from "react";

import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS_GET_QUERY} from "graphql/GlobalConfigCenter/locationDetails";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {LOCATION_DETAILS_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {LOCATION_DETAILS_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const LocationDetailsConfigPage = () => {
  const {data, loading} = useQuery(LOCATION_DETAILS_GET_QUERY);

  return (
    <Layout
      title={LOCATION_DETAILS_CONFIG_PAGE.title}
      description={LOCATION_DETAILS_CONFIG_PAGE.description}
      tableColumns={LOCATION_DETAILS_COLUMNS}
      tableData={loading ? [] : data?.bioLcGetLocationDetails.locationDetail}
    />
  );
};

export default LocationDetailsConfigPage;
