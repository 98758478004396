import {gql} from "@apollo/client";

// Fetch query for product baseline emission table on Config center(/configuration-center/product-baseline-emission)
export const PRODUCT_BASELINE_EMISSION_DETAIL = gql`
  query bioLcGetProductBaselineEmissionDetailsNew {
    bioLcGetProductBaselineEmissionDetailsNew(event: {}) {
      productBaselineEmissionDetailsResponse {
        baselineEmission
        countryCode
        countryId
        divisionCode
        divisionId
        divisionName
        materialCode
        materialId
        materialShortName
        productBaselineEmissionId
        siteName
        siteReferenceId
        uomCode
        validFrom
        validStatus
        validTo
      }
    }
  }
`;

export default {PRODUCT_BASELINE_EMISSION_DETAIL};
