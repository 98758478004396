import React from "react";

import {useQuery} from "@apollo/client";
import {GET_PRODUCTION_EMISSION_NEW_QUERY} from "graphql/GlobalConfigCenter/ProductionEmissionNew";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {PRODUCTION_EMISSION_NEW_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {PRODUCTION_EMISSION_NEW_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const ProductionEmissionNewConfigPage = () => {
  const {data, loading} = useQuery(GET_PRODUCTION_EMISSION_NEW_QUERY);

  return (
    <Layout
      title={PRODUCTION_EMISSION_NEW_CONFIG_PAGE.title}
      description={PRODUCTION_EMISSION_NEW_CONFIG_PAGE.description}
      tableColumns={PRODUCTION_EMISSION_NEW_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetProductionEmissionNew.productionEmissionNewResponse
      }
    />
  );
};

export default ProductionEmissionNewConfigPage;
