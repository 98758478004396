import TankUsageTypes from "utils/helpers/tankUsageTypes";
import CherrypointPreviewPopup from "modules/reports/components/Cherrypoint/CherrypointPreviewPopup";
import DetailedModal from "modules/common/Table/ActionsMenu/DetailedModal";
import PoSNLNabisyEditForm from "modules/pos-management/PoSNLNabisyEditForm";
import PoSNLISCCEditForm from "modules/pos-management/PoSNLISCCEditForm";
import moment from "moment";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {updatePosUKISCCRecord, updatePosPlRecord} from "graphql/pos";
import PoSPLEditForm from "modules/pos-management/PoSPLEditForm";
import {numberSorterExt, stringSorterExt} from "modules/common/Table/sorters";
import PoSEditForm from "modules/pos-management/PoSEditForm";
import {posNLISCCSchema, posEditSchema} from "modules/pos-management/schema";
import * as LABELS from "constants/pos";
import * as DATEFORMATS from "constants/dateformats";

export const getPosColumns = (decimalFormat) => {
  const posColumns = [
    {
      title: "PoS ID",
      dataIndex: "euVoluntarySchemePartialProofNumber",
      key: "euVoluntarySchemePartialProofNumber",
      width: 180,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      render: (text, record) => (
        <CherrypointPreviewPopup
          title={text}
          record={{
            ...record,
            type: TankUsageTypes.TYPE_POS_UK,
            countryCode: "GBR",
          }}
          isInvoice={false}
        />
      ),
      ...stringSorterExt("posId"),
    },
    {
      title: "Date of Receipt",
      dataIndex: "vesselReceiptDate",
      key: "vesselReceiptDate",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "date",
    },
    {
      title: "Issuance Date",
      dataIndex: "issuanceDate",
      key: "issuanceDate",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "date",
    },
    {
      title: "Place of Issuance",
      dataIndex: "placeOfIssuance",
      key: "placeOfIssuance",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("placeOfIssuance"),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("supplier"),
    },
    {
      title: LABELS.LABEL_TYPE_OF_PRODUCT,
      dataIndex: "fuelType",
      key: "fuelType",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("fuelType"),
    },
    {
      title: "Type of Raw Material",
      dataIndex: "feedstockType",
      key: "feedstockType",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("feedstockType"),
    },
    {
      title: "Feedstock Origin",
      dataIndex: "feedstockOrigin",
      key: "feedstockOrigin",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("feedstockOrigin"),
    },
    {
      title: "Certificate Type",
      dataIndex: "certificateType",
      key: "certificateType",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "input",
      ...stringSorterExt("certificateType"),
    },
    {
      title: "Quantity [m3]",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "number",
      ...numberSorterExt("quantity"),
      render: (text) => formatNumber(text, decimalFormat, 3),
    },
    {
      title: "GHG Emissions",
      dataIndex: "ghgEmissions",
      key: "ghgEmissions",
      width: 150,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      align: "center",
      inputType: "number",
      ...numberSorterExt("ghgEmissions"),
      render: (text) => formatNumber(text, decimalFormat, 3),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 40,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      inputType: "input",
      render: (_, record, index, currentDataSource, dateFormat) => {
        return (
          <DetailedModal
            tableState={{data: currentDataSource}}
            mode="edit-row"
            record={record}
            schema={posEditSchema}
            formContent={<PoSEditForm data={record} />}
            onSubmit={async (values) => {
              const {
                data: {updatePOSRecord: response},
              } = await updatePosUKISCCRecord(values, decimalFormat);
              return response;
            }}
            dataIndex={index}
            getModalTitle={(data) =>
              `PoS number #${data?.euVoluntarySchemePartialProofNumber}`
            }
            getDataWithDates={(data) => ({
              ...data,
              vesselReceiptDate: data?.vesselReceiptDate
                ? moment(data.vesselReceiptDate, dateFormat)
                : "",
              issuanceDate: data?.issuanceDate
                ? moment(data.issuanceDate, dateFormat)
                : "",

              volumeDrawn: formatNumber(data?.volumeDrawn, decimalFormat),
              ghgEee: formatNumber(data?.ghgEee, decimalFormat),
              runningBalance: formatNumber(data?.runningBalance, decimalFormat),
              ghgValuePct: formatNumber(data?.ghgValuePct, decimalFormat),
              remainingFeedstockVolumeSl: formatNumber(
                data?.remainingFeedstockVolumeSl,
                decimalFormat,
              ),
              outturnFeedstockVolumeSl: formatNumber(
                data?.outturnFeedstockVolumeSl,
                decimalFormat,
              ),
              feedstockAllocationOfOutturnPct: formatNumber(
                data?.feedstockAllocationOfOutturnPct,
                decimalFormat,
              ),
              volumeForEthanol: formatNumber(
                data?.volumeForEthanol,
                decimalFormat,
              ),
              outturnVolumeTotalByPosSl: formatNumber(
                data?.outturnVolumeTotalByPosSl,
                decimalFormat,
              ),
              ghgEmissions: formatNumber(data?.ghgEmissions, decimalFormat),
              quantity: formatNumber(data?.quantity, decimalFormat),
            })}
          />
        );
      },
    },
  ];

  return posColumns;
};

export const getPosPLColumns = (decimalFormat) => {
  const posPLColumns = [
    {
      title: "PoS ID",
      dataIndex: "uniqueNumberOfSustainabilityDeclaration",
      key: "uniqueNumberOfSustainabilityDeclaration",
      width: 180,
      align: "center",
      inputType: "input",
      render: (text, record) => (
        <CherrypointPreviewPopup
          title={text}
          record={{
            ...record,
            type: TankUsageTypes.TYPE_POS_PL,
            countryCode: "POL",
          }}
          isInvoice={false}
        />
      ),
      ...stringSorterExt("uniqueNumberOfSustainabilityDeclaration"),
    },
    {
      title: "Date of Receipt",
      dataIndex: "receiptDate",
      key: "receiptDate",
      width: 150,
      inputType: "date",
      align: "center",
    },
    {
      title: "Issuance Date",
      dataIndex: "dateOfIssuance",
      key: "dateOfIssuance",
      width: 150,
      inputType: "date",
      align: "center",
    },
    {
      title: "Place of Issuance",
      dataIndex: "placeOfIssuance",
      key: "placeOfIssuance",
      width: 150,
      inputType: "input",
      align: "center",
      ...stringSorterExt("placeOfIssuance"),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 150,
      inputType: "input",
      align: "center",
      ...stringSorterExt("supplier"),
    },
    {
      title: LABELS.LABEL_TYPE_OF_PRODUCT,
      dataIndex: "typeOfProduct",
      key: "typeOfProduct",
      width: 150,
      align: "center",
      inputType: "input",
      ...stringSorterExt("typeOfProduct"),
    },
    {
      title: "Type of Raw Material",
      dataIndex: "typeOfRawMaterial",
      key: "typeOfRawMaterial",
      width: 150,
      align: "center",
      inputType: "input",
      ...stringSorterExt("typeOfRawMaterial"),
    },
    {
      title: "Feedstock Origin",
      dataIndex: "countryOfOrigin",
      key: "countryOfOrigin",
      width: 150,
      align: "center",
      inputType: "input",
      ...stringSorterExt("countryOfOrigin"),
    },
    {
      title: "Certificate Type",
      dataIndex: "certificateType",
      key: "certificateType",
      width: 150,
      align: "center",
      inputType: "input",
      ...stringSorterExt("certificateType"),
    },
    {
      title: "Quantity [m3]",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      align: "center",
      inputType: "number",
      ...numberSorterExt("quantity"),
      render: (text) => formatNumber(text, decimalFormat, 3),
    },
    {
      title: "GHG Emissions",
      dataIndex: "ghgEmissionSaving",
      key: "ghgEmissionSaving",
      width: 150,
      align: "center",
      inputType: "number",
      ...numberSorterExt("ghgEmissionSaving"),
      render: (text) => formatNumber(text, decimalFormat, 3),
    },
    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 80,
      render: (_, record, index, currentDataSource, dateFormat) => {
        return (
          <DetailedModal
            tableState={{data: currentDataSource}}
            mode="edit-row"
            formContent={<PoSPLEditForm data={record} />}
            record={record}
            onSubmit={async (values) => {
              const {
                data: {updatePOSRecord: response},
              } = await updatePosPlRecord(values);
              return response;
            }}
            dataIndex={index}
            getModalTitle={(data) =>
              `PoS number #${data?.uniqueNumberOfSustainabilityDeclaration}`
            }
            getDataWithDates={(data) => ({
              ...data,
              dateOfIssuance: data?.dateOfIssuance
                ? moment(data.dateOfIssuance, dateFormat)
                : "",
              receiptDate: data?.receiptDate
                ? moment(data.receiptDate, dateFormat)
                : "",

              quantity: formatNumber(data?.quantity, decimalFormat),
              ghgEmissionSaving: formatNumber(
                data?.ghgEmissionSaving,
                decimalFormat,
              ),
              emissionGCO2eqMJ: formatNumber(
                data?.emissionGCO2eqMJ,
                decimalFormat,
              ),
              weight: formatNumber(data?.weight, decimalFormat),
            })}
          />
        );
      },
    },
  ];
  return posPLColumns;
};

export const posNLColumns = [
  {
    title: "Unique PoS number",
    dataIndex: "uniquePosNumber",
    key: "uniquePosNumber",
    width: 180,
    align: "center",
    inputType: "input",
  },
  {
    title: "Date of Issuance of PoS",
    dataIndex: "dateOfIssuance",
    key: "dateOfIssuance",
    width: 200,
    align: "center",
    inputType: "date",
  },
  {
    title: "Date of dispatch of material",
    dataIndex: "dateOfDispatchMaterial",
    key: "dateOfDispatchMaterial",
    width: 200,
    align: "center",
    inputType: "date",
  },
  {
    title: "Recipient",
    dataIndex: "recipient",
    key: "recipient",
    width: 150,
    align: "center",
    inputType: "input",
  },
  {
    title: LABELS.LABEL_TYPE_OF_PRODUCT,
    dataIndex: "typeOfProduct",
    key: "typeOfProduct",
    width: 180,
    align: "center",
    inputType: "input",
  },
  {
    title: "Type of Raw material",
    dataIndex: "typeOfRawMaterial",
    key: "typeOfRawMaterial",
    width: 150,
    align: "center",
    inputType: "input",
  },
  {
    title: "Country of Origin",
    dataIndex: "countryOfOrigin",
    key: "countryOfOrigin",
    width: 150,
    align: "center",
    inputType: "input",
  },
  {
    title: "GHG Value %",
    dataIndex: "ghgValue",
    key: "ghgValue",
    width: 130,
    align: "center",
    inputType: "number",
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 100,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    inputType: "input",
    render: (_, record, index, currentDataSource, dateFormat) => {
      return (
        <DetailedModal
          mode="edit-row"
          tableState={{data: currentDataSource}}
          schema={posNLISCCSchema}
          record={record}
          formContent={<PoSNLISCCEditForm data={record} />}
          onSubmit={() => {
            /* do nothing */
          }}
          dataIndex={index}
          getModalTitle={(data) => `PoS number #${data?.uniquePosNumber}`}
          getDataWithDates={(data) => ({
            ...data,
            dateOfIssuance: data?.dateOfIssuance
              ? moment(data?.dateOfIssuance, dateFormat)
              : "",
            dateOfDispatchMaterial: data?.dateOfDispatchMaterial
              ? moment(data?.dateOfDispatchMaterial, dateFormat)
              : "",
            dateOfOperation: data?.dateOfOperation
              ? moment(
                  data?.dateOfOperation,
                  DATEFORMATS.DATE_FORMAT_YYYYMMDD_HYPHEN,
                )
              : "",
          })}
        />
      );
    },
  },
];

export const posNLNabisyColumns = [
  {
    title: "Partial Proof",
    dataIndex: "partialProof",
    key: "partialProof",
    width: 130,
    align: "center",
    inputType: "input",
  },
  {
    title: "Date of Issuance",
    dataIndex: "dateOfIssuance",
    key: "dateOfIssuance",
    width: 200,
    align: "center",
    dateFormat: DATEFORMATS.DATE_FORMAT_YYYYMMDD_HYPHEN,
    inputType: "date",
  },
  {
    title: "Date of receipt",
    dataIndex: "dateOfReceipt",
    key: "dateOfReceipt",
    width: 130,
    align: "center",
    dateFormat: DATEFORMATS.DATE_FORMAT_YYYYMMDD_HYPHEN,
    inputType: "date",
  },
  {
    title: "Place of receipt",
    dataIndex: "placeOfReceipt",
    key: "placeOfReceipt",
    width: 130,
    align: "center",
    inputType: "input",
  },
  {
    title: LABELS.LABEL_TYPE_OF_PRODUCT,
    dataIndex: "typeOfProduct",
    key: "typeOfProduct",
    width: 130,
    align: "center",
    inputType: "input",
  },
  {
    title: "Type of Biomass",
    dataIndex: "typeOfBiomass",
    key: "typeOfBiomass",
    width: 130,
    align: "center",
    inputType: "input",
  },
  {
    title: "Country of origin",
    dataIndex: "countryOfOrigin",
    key: "countryOfOrigin",
    width: 130,
    align: "center",
    inputType: "input",
  },
  {
    title: "GHG Value %",
    dataIndex: "ghgValue",
    key: "ghgValue",
    width: 130,
    align: "center",
    inputType: "number",
  },
  {
    title: "",
    key: "operation",
    fixed: "right",
    width: 80,
    selected: true,
    allowEdit: false,
    showInPreview: false,
    inputType: "input",
    render: (_, record, index, currentDataSource, dateFormat) => {
      return (
        <DetailedModal
          tableState={{data: currentDataSource}}
          mode="edit-row"
          formContent={<PoSNLNabisyEditForm data={record} />}
          onSubmit={() => {
            /* do nothing */
          }}
          record={record}
          dataIndex={index}
          getModalTitle={(data) => `PoS number #${data?.partialProof}`}
          getDataWithDates={(data) => ({
            ...data,
            dateOfIssuance: data?.dateOfIssuance
              ? moment(data?.dateOfIssuance, dateFormat)
              : "",
            dateOfReceipt: data?.dateOfReceipt
              ? moment(data?.dateOfReceipt, dateFormat)
              : "",
            dateOfOperation: data?.dateOfOperation
              ? moment(
                  data?.dateOfOperation,
                  DATEFORMATS.DATE_FORMAT_YYYYMMDD_HYPHEN,
                )
              : "",
          })}
        />
      );
    },
  },
];

export default {
  posNLColumns,
  posNLNabisyColumns,
};
