import Menu from "modules/common/Menu";
import Modal from "modules/common/Modal";
import PropTypes from "prop-types";
import {Select, Spin} from "antd";
import React, {useState} from "react";
import moment from "moment";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Button from "DLS/Button";
import {toast} from "react-toastify";
import {ErrorModalBasic} from "modules/common/StatusModals/ErrorModal";
import FormField from "modules/common/FormField";

const menuItemTitle = {
  none: "Export/Download Annual data",
  xlsx: "Export/Download Annual Excel",
  csv: "Export/Download Annual CSV",
  template: "Export/Download Annual Template",
};

const dataTest = {
  none: "button-annual-export",
  xlsx: "button-annual-export-xlsx",
  csv: "button-annual-export-csv",
  template: "button-annual-export-template",
};

const DownloadAnnualItem = ({
  pageTitle,
  onDownload,
  disabled = false,
  format = "none",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [exportError, setExportError] = useState(false);
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const currentYear = moment().year();
  const yearsList = Array.from({length: 6}, (_, i) => currentYear - i);
  const [year, setYear] = useState(currentYear);

  const downloadFile = (response) => {
    const fileUrl = response?.data?.url;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleDownload = async () => {
    setExportError(false);
    setSubmitAllowed(false);
    setSubmitting(true);
    try {
      const response = await onDownload(year);
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          setSubmitting(false);
          setSubmitAllowed(true);
          toast.error(response?.errors?.[0]?.message);
          break;
        default:
          // Theorically succesfully
          setSubmitting(false);
          setSubmitAllowed(true);
          if (response?.data) {
            downloadFile(response);
            setShowModal(false);
          } else {
            setExportError(true);
          }
      }
    } catch (err) {
      console.error(err);
      // Unexpected graphql error
      setSubmitting(false);
      setSubmitAllowed(true);
      toast.error("Something went wrong, please try later.");
    }
  };

  return (
    <>
      <Modal
        data-test="annual-export-modal"
        visible={showModal}
        title="Download annual data"
        onCancel={() => setShowModal((prevValue) => !prevValue)}
        closable
        destroyOnClose
        width="25%"
        footer={
          <div className="flex flex-col w-full gap-2 pt-8 border-t border-gray-100 sm:flex-row">
            <Button
              label="Back"
              icon={<ArrowLeftOutlined />}
              type="secondary"
              data-test="button-back"
              onClick={() => setShowModal((prevValue) => !prevValue)}
            />
            <Button
              label={isSubmitting ? <Spin size="small" /> : "DOWNLOAD"}
              data-test="button-download"
              htmlType="submit"
              onClick={handleDownload}
              disabled={!isSubmitAllowed}
            />
          </div>
        }
      >
        <p className="pt-2 pb-10">{pageTitle}</p>
        <FormField label="SELECT YEAR">
          <Select
            size="large"
            name="evaluation"
            defaultValue={year}
            onChange={(value) => setYear(value)}
            data-test="export-year"
            className="w-full"
          >
            {yearsList.map((item) => {
              return (
                <Select.Option key={item} data-test={item} value={item}>
                  {item}
                </Select.Option>
              );
            })}
          </Select>
        </FormField>
      </Modal>

      <ErrorModalBasic
        visible={exportError}
        title="No data is available to export"
        onCancel={() => setExportError(false)}
      />

      <Menu.Item
        key="button-annual-export"
        data-test={dataTest[format]}
        onClick={() => {
          setShowModal(true);
        }}
        disabled={disabled}
      >
        {menuItemTitle[format]}
      </Menu.Item>
    </>
  );
};

DownloadAnnualItem.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  format: PropTypes.oneOf(["none", "xlsx", "csv", "template"]),
};

export default DownloadAnnualItem;
