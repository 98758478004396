import tw, {styled} from "twin.macro";

const sizeVariants = {
  large: tw`
    h-[3.1rem] // 50px
    px-[0.7rem]
    min-w-[6.3rem]
  `,
  medium: tw`
    h-[2.5rem] // 40px
    px-[0.7rem]
    min-w-[5rem]
  `,
};

const OnlyIconSizeVariants = {
  large: tw`
    h-[3.1rem] // 50px
    w-[3.1rem] // 50px
    p-0
    justify-center

  `,
  medium: tw`
    h-[2.5rem] // 40px
    w-[2.5rem] // 40px
    p-0
    justify-center
  `,
};

const typeVariants = {
  primary: tw`
    bg-bp-green-800
    text-white
    border-bp-green-800

    hover:bg-bp-green-900
    hover:border-bp-green-900

    active:bg-bp-green-900
    active:border-bp-green-900

    disabled:bg-gray-200
    disabled:text-gray-600
    disabled:border-gray-200
  `,
  secondary: tw`
    bg-white
    text-indigo-500 
    border-indigo-500

    hover:bg-indigo-500
    hover:text-white
    
    active:bg-indigo-900
    active:text-white

    disabled:text-gray-600 
    disabled:border-gray-200
    disabled:bg-white
  `,
  tertiary: tw`
    bg-gray-800
    text-white
    border-gray-800

    hover:bg-gray-300
    hover:text-indigo-500
    hover:border-gray-300

    active:bg-gray-900
    active:text-white
    active:border-gray-900

    disabled:bg-gray-200
    disabled:text-gray-600 
    disabled:border-gray-200
  `,
};

export const StyledLabel = styled.div(() => [tw`text-base`]);

export const StyledButton = styled.button(({label, icon, reverse}) => [
  tw`
    self-start
    inline-block
    flex
    items-center
    border-2

    text-icon
    font-bold
    uppercase
    rounded-sm
    
    disabled:cursor-not-allowed

    transition
    duration-200
    ease-in-out
  `,
  // Size
  ({size}) => sizeVariants[size],
  // Type
  ({btnType}) => typeVariants[btnType],
  // Reverse
  reverse && tw`flex-row-reverse`,
  // Label only
  label && !icon && tw`justify-center`,
  // // Label + Icon
  label && icon && tw`justify-between gap-3`,
  // Icon only
  ({size}) => !label && icon && [tw`min-w-fit`, OnlyIconSizeVariants[size]],
]);
