import {Table} from "antd";
import PropTypes from "prop-types";
import {CREDITS_CATEGORIES, DEFICITS_CATEGORIES} from "../constants/inventory";

const columnObjectsGeneratorTotal = (monthData) => {
  const columnObjects = [
    {
      title: "Total",
      className: "to-publish-measurement-title",
      dataIndex: "total",
      align: "left",
      width: 160,
    },
  ];

  monthData.forEach((item) => {
    columnObjects.push({
      title: () => {
        return (
          <span className="mr-9">{`${item.monthName} - ${
            item.year % 100
          }`}</span>
        );
      },

      className: `to-publish-month-cell-${item.dataIndex}`,
      dataIndex: item.dataIndex,
      align: "right",
      width: 140,
      render: (value) => {
        if (value) {
          return (
            <span className="font-bold mr-9 text-[#3f3e45]">
              {value.toLocaleString("en-US")}
            </span>
          );
        }
        return null;
      },
    });
  });

  return columnObjects;
};

const getTotalObligationsByType = (totalObligations, forecastType) => {
  const categories =
    forecastType === "credits" ? CREDITS_CATEGORIES : DEFICITS_CATEGORIES;

  const filteredEntries = Object.entries(totalObligations).filter(([key]) =>
    categories.includes(key),
  );

  return Object.fromEntries(filteredEntries);
};

const columnsDataGeneratorTotal = (
  monthData,
  totalObligations,
  forecastType,
) => {
  const totalObligationsByType = getTotalObligationsByType(
    totalObligations,
    forecastType,
  );
  const columnObjects = monthData.reduce((columnObject, item) => {
    const sum = Object.values(totalObligationsByType).reduce(
      (acc, obligation) => {
        const found = obligation.find(
          (row) => row.dataIndex === item.dataIndex,
        );
        return acc + (found?.total || 0);
      },
      0,
    );

    return {...columnObject, [item.dataIndex]: sum};
  }, {});

  return [{total: "Total", key: 0, ...columnObjects}];
};

const ToPublishTotalObligations = ({
  monthData,
  totalObligations,
  label,
  forecastType,
  totalKey,
}) => {
  const columns = columnObjectsGeneratorTotal(monthData);
  const dataSource = columnsDataGeneratorTotal(
    monthData,
    totalObligations,
    forecastType,
  );

  return (
    <div className="border-1 rounded-lg col-span-2">
      <div className="flex flex-col justify-between px-5 py-4 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
        <p className="table-title font-bold">{label}</p>
      </div>
      <Table
        data-test={`to-publish-table-${totalKey}`}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{x: "max-content"}}
      />
    </div>
  );
};

ToPublishTotalObligations.propTypes = {
  monthData: PropTypes.any,
  totalObligations: PropTypes.any,
  label: PropTypes.string,
  forecastType: PropTypes.string,
  totalKey: PropTypes.string,
};

export default ToPublishTotalObligations;
