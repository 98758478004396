import {useFormContext, Controller} from "react-hook-form";
import PropTypes from "prop-types";

import {useUserSettings, formatNumber} from "providers/userSettings";
import DatePicker from "modules/common/DatePicker";
import FormField from "modules/common/FormField";
import Select from "modules/common/Select";
import Textarea from "modules/common/Textarea";
import moment from "moment";
import {useEffect, useState} from "react";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {useQuery} from "@apollo/client";
import InputNumeric from "modules/common/lnputNumeric";
import {labelBlueColor} from "utils/helpers/labelColor";
import Input from "modules/common/Input";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";

const customSpinIcon = (
  <LoadingOutlined style={{fontSize: 14, color: "gray"}} spin />
);

const disabledDate = (current) => {
  // Only two+current years allowed
  const now = moment();
  const yearsDiff = now.diff(current, "year");

  return yearsDiff >= 2 || current.isAfter(now);
};

const ManualAdjustmentForm = ({data}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const {
    formState: {errors},
    watch,
    setValue,
  } = useFormContext();
  const [cachedUom, setCachedUom] = useState();
  const [cachedcompanyCode, setCachedcompanyCode] = useState();
  const watchedUom = watch("uoM");
  const watchedCompanyCode = watch("companyCode");
  const watchedLoadPlant = watch("loadPlant");
  const watchedEdGroup = watch("eDGroup");
  const watchedMainCustomsOffice = watch("mainCustomsOffice");

  const {data: optionsDataPayload, loading: fieldOptionsLoading} = useQuery(
    GET_FIELD_OPTIONS,
    {fetchPolicy: "network-only"},
  );

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const taxLawOptions = optionsData?.taxLaws?.values || [];
  const companyCodeOptions = optionsData?.companyCode?.values || [];
  const uoMOptions = optionsData?.uoM?.values || [];
  const categoryOptions = optionsData?.categories?.values || [];
  const eDGroupOptions = optionsData?.eDGroup?.values || [];
  const commodityCodeOptions = optionsData?.commodityCode?.values || [];
  const plantOptions = optionsData?.plant?.values || [];
  const mainCustomsOfficeOptions = optionsData?.mainCustomsOffice?.values;

  useEffect(() => {
    // When data mainCustomsOffice arrive, set the form
    setValue("mainCustomsOffice", data?.mainCustomsOffice);
  }, [data?.mainCustomsOffice, setValue]);

  useEffect(() => {
    if (
      watchedLoadPlant &&
      watchedCompanyCode &&
      watchedEdGroup &&
      mainCustomsOfficeOptions
    ) {
      setValue(
        "mainCustomsOffice",
        mainCustomsOfficeOptions.find(
          (o) =>
            o.plant === watchedLoadPlant &&
            o.companyCode === watchedCompanyCode &&
            o.eDGroup === watchedEdGroup,
        )?.mainCustomsOffice,
      );
    }
  }, [
    setValue,
    watchedLoadPlant,
    watchedCompanyCode,
    watchedEdGroup,
    mainCustomsOfficeOptions,
  ]);

  useEffect(() => {
    if (watchedUom) {
      setCachedUom(watchedUom);
    }
  }, [watchedUom]);

  useEffect(() => {
    if (cachedUom && watchedUom !== cachedUom) {
      setValue("calEDQty", "");
    }
  }, [cachedUom, setValue, watchedUom]);

  useEffect(() => {
    if (watchedCompanyCode) {
      setCachedcompanyCode(watchedCompanyCode);
    }
  }, [watchedCompanyCode]);

  useEffect(() => {
    if (cachedcompanyCode && watchedCompanyCode !== cachedcompanyCode) {
      setValue("taxLaw", null);
    }
  }, [cachedcompanyCode, setValue, watchedCompanyCode]);

  return (
    <div
      className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4"
      data-test={optionsDataPayload ? "TEST_READY" : undefined}
    >
      <FormField
        label="Company Code"
        errorMessage={errors.companyCode?.message}
      >
        <Controller
          name="companyCode"
          defaultValue={data?.companyCode}
          render={({field}) => (
            <Select
              {...field}
              disabled={fieldOptionsLoading}
              placeholder="Company Code"
              data-test="manual-adjustment-edition-input-company-code"
              loading={fieldOptionsLoading}
            >
              {companyCodeOptions.map((value) => (
                <Select.Option
                  value={value}
                  key={`companyCodeOptions-${value}`}
                >
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField label="Fiscal Month Year" errorMessage={errors.fisc?.message}>
        <Controller
          name="fisc"
          defaultValue={data?.fisc ? moment(data.fisc) : ""}
          render={({field}) => (
            <DatePicker
              {...field}
              disabled={fieldOptionsLoading}
              placeholder="Fiscal Month Year"
              picker="month"
              format="MMM YYYY"
              disabledDate={disabledDate}
              data-test="manual-adjustment-edition-input-fisc"
            />
          )}
        />
      </FormField>
      <FormField
        label="Declaration In Month"
        errorMessage={errors.declaration?.message}
      >
        <Controller
          name="declaration"
          defaultValue={data?.declaration ? moment(data?.declaration) : ""}
          render={({field}) => (
            <DatePicker
              {...field}
              disabled={fieldOptionsLoading}
              placeholder="Declaration In Month"
              picker="month"
              format="MMM YYYY"
              disabledDate={disabledDate}
              data-test="manual-adjustment-edition-input-declaration"
            />
          )}
        />
      </FormField>
      <FormField label="ED Group" errorMessage={errors.eDGroup?.message}>
        <Controller
          name="eDGroup"
          defaultValue={data?.eDGroup}
          render={({field}) => (
            <Select
              {...field}
              showSearch
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              optionFilterProp="label"
              placeholder="Type to search..."
              defaultActiveFirstOption={false}
              data-test="manual-adjustment-edition-input-ed-group"
              options={eDGroupOptions.map((d) => ({
                label: d.name,
                value: d.code,
              }))}
            />
          )}
        />
      </FormField>
      <FormField
        label="Commodity Code"
        errorMessage={errors.commodityCode?.message}
      >
        <Controller
          name="commodityCode"
          defaultValue={data?.commodityCode.toString()}
          render={({field}) => (
            <Select
              {...field}
              showSearch
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              optionFilterProp="label"
              placeholder="Type to search..."
              defaultActiveFirstOption={false}
              data-test="manual-adjustment-edition-input-commodity-code"
              options={commodityCodeOptions.map((value) => ({
                label: value,
                value,
              }))}
            />
          )}
        />
      </FormField>
      <FormField label="Load Plant" errorMessage={errors.loadPlant?.message}>
        <Controller
          name="loadPlant"
          defaultValue={data?.loadPlant}
          render={({field}) => (
            <Select
              {...field}
              showSearch
              disabled={fieldOptionsLoading}
              optionFilterProp="label"
              loading={fieldOptionsLoading}
              placeholder="Type to search..."
              defaultActiveFirstOption={false}
              data-test="manual-adjustment-edition-input-load-plant"
              options={plantOptions.map((d) => ({
                label: d.name,
                value: d.code,
              }))}
            />
          )}
        />
      </FormField>
      <FormField label="UOM" errorMessage={errors.uoM?.message}>
        <Controller
          name="uoM"
          defaultValue={data?.uoM}
          render={({field}) => (
            <Select
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              {...field}
              data-test="manual-adjustment-edition-input-uom"
              placeholder="UOM"
            >
              {uoMOptions.map((value) => (
                <Select.Option value={value} key={`${value}`}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>
      <FormField label="Calc ED Qty" errorMessage={errors.calEDQty?.message}>
        <Controller
          name="calEDQty"
          defaultValue={formatNumber(data?.calEDQty, decimalFormat)}
          render={({field}) => (
            <InputNumeric
              {...field}
              placeholder="Calc ED Qty"
              disabled={!watchedUom}
              max={100000000}
              min={-100000000}
              allowNegative
              decimals={watchedUom === "MWh" ? 3 : 2}
              data-test="manual-adjustment-edition-input-calc-ed-qty"
              decimalFormat={decimalFormat}
            />
          )}
        />
      </FormField>
      <FormField
        label="THG Relevant"
        errorMessage={errors.tHGRelevant?.message}
      >
        <Controller
          name="tHGRelevant"
          defaultValue={data?.tHGRelevant}
          render={({field}) => (
            <Select
              {...field}
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              placeholder="THG Relevant"
              data-test="manual-adjustment-edition-input-thg-relevant"
            >
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          )}
        />
      </FormField>
      <FormField
        label="GnET Relevant"
        errorMessage={errors.gnETSRelevant?.message}
      >
        <Controller
          name="gnETSRelevant"
          defaultValue={data?.gnETSRelevant}
          label="GnETS Relevant"
          render={({field}) => (
            <Select
              {...field}
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              placeholder="GnET Relevant"
              data-test="manual-adjustment-edition-input-gnets-relevant"
            >
              <Select.Option value>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          )}
        />
      </FormField>
      <FormField label="Category" errorMessage={errors.category?.message}>
        <Controller
          name="category"
          defaultValue={data?.category}
          render={({field}) => (
            <Select
              {...field}
              disabled={fieldOptionsLoading}
              loading={fieldOptionsLoading}
              placeholder="Category"
              data-test="manual-adjustment-edition-input-category"
            >
              {categoryOptions.map((value) => (
                <Select.Option
                  value={value.ENG1}
                  key={`categoryOptions-${value.DE01}`}
                >
                  {labelBlueColor(value.ENG1, value.DE01)}
                </Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>
      <FormField label="Tax Law" errorMessage={errors.taxLaw?.message}>
        <Controller
          name="taxLaw"
          defaultValue={data?.taxLaw}
          render={({field}) => (
            <Select
              {...field}
              loading={fieldOptionsLoading}
              placeholder="Tax Law"
              data-test="manual-adjustment-edition-input-taxlaw"
              disabled={!watchedCompanyCode}
            >
              {taxLawOptions
                .filter((option) => option.companyCode === watchedCompanyCode)
                .map((option) => (
                  <Select.Option
                    value={option.law}
                    key={`taxLawOptions-${option.law}`}
                  >
                    {option.law}
                  </Select.Option>
                ))}
            </Select>
          )}
        />
      </FormField>

      <FormField
        label="Main Customs Office"
        errorMessage={errors.mainCustomsOffice?.message}
      >
        <span data-test="manual-adjustment-edition-main-customs-office">
          {fieldOptionsLoading ? (
            <Spin indicator={customSpinIcon} />
          ) : (
            watchedMainCustomsOffice || "N/A"
          )}
        </span>

        <Controller
          name="mainCustomsOffice"
          defaultValue={data?.mainCustomsOffice}
          render={({field}) => <Input {...field} className="hidden" disabled />}
        />
      </FormField>

      <FormField
        label="Description"
        className="col-span-1 md:col-span-3 lg:col-span-3 xl:col-span-3"
        errorMessage={errors.description?.message}
      >
        <Controller
          name="description"
          defaultValue={data?.description}
          render={({field}) => (
            <Textarea
              {...field}
              disabled={fieldOptionsLoading}
              placeholder="Description"
              data-test="manual-adjustment-edition-input-description"
              maxLength={500}
              showCount
            />
          )}
        />
      </FormField>
    </div>
  );
};

ManualAdjustmentForm.propTypes = {
  data: PropTypes.object,
};

export default ManualAdjustmentForm;
