import {useCallback, useEffect, useState} from "react";

const useRowSelection = (tableData, idProp = "id", extraProp = "") => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowsTable, setSelectedRows] = useState([]);

  const clearSelection = useCallback(() => {
    setSelectedRowKeys([]);
    setSelectedRows([]);
  }, []);

  useEffect(() => {
    // Save row values everytime keys or data change
    const rows = tableData.filter((row) =>
      selectedRowKeys.includes(
        extraProp === "" ? row[idProp] : `${row[idProp]}-${row[extraProp]}`,
      ),
    );
    setSelectedRows(rows);
  }, [tableData, selectedRowKeys, idProp, extraProp]);

  return [
    selectedRowsTable,
    {
      selectedRowKeys,
      onSelectAll: (selected) => {
        setSelectedRowKeys(
          selected
            ? tableData.map((record) =>
                extraProp === ""
                  ? record[idProp]
                  : `${record[idProp]}-${record[extraProp]}`,
              )
            : [],
        );
        setSelectedRows(selected ? tableData : []);
      },
      onSelect: (record, selected, selectedRows) => {
        setSelectedRowKeys(
          selected
            ? [
                ...selectedRowKeys,
                extraProp === ""
                  ? record[idProp]
                  : `${record[idProp]}-${record[extraProp]}`,
              ]
            : selectedRowKeys.filter(
                (rowId) =>
                  rowId !==
                  (extraProp === ""
                    ? record[idProp]
                    : `${record[idProp]}-${record[extraProp]}`),
              ),
        );
        setSelectedRows(selectedRows);
      },
    },
    clearSelection,
  ];
};

export default useRowSelection;
