import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const ADD_NETS_CO2_COSTS = gql`
  mutation MyMutation(
    $PK: String!
    $certificateCosts: Float!
    $companyCode: String!
    $complianceYear: Int!
  ) {
    createBehgCo2Costs(
      event: {
        PK: $PK
        certificateCosts: $certificateCosts
        companyCode: $companyCode
        complianceYear: $complianceYear
      }
    ) {
      data {
        PK
        certificateCosts
        companyCode
        complianceYear
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const NETS_CO2_COSTS_LIST = gql`
  query MyQuery {
    getBehgCo2Costs {
      data {
        PK
        certificateCosts
        companyCode
        complianceYear
      }
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const NETS_CO2_COSTS_UPDATE = gql`
  mutation MyMutation(
    $PK: String!
    $certificateCosts: Float!
    $companyCode: String!
    $complianceYear: Int!
  ) {
    updateBehgCo2Costs(
      event: {
        PK: $PK
        certificateCosts: $certificateCosts
        companyCode: $companyCode
        complianceYear: $complianceYear
      }
    ) {
      data {
        PK
        certificateCosts
        companyCode
        complianceYear
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const createNetCO2CostApi = (data) =>
  client.mutate({
    mutation: ADD_NETS_CO2_COSTS,
    variables: {
      ...data,
    },
    refetchQueries: ["MyQuery"],
  });

export const updateNetCO2CostApi = (data) =>
  client.mutate({
    mutation: NETS_CO2_COSTS_UPDATE,
    variables: {
      ...data,
    },
    refetchQueries: ["MyQuery"],
  });

export default {
  ADD_NETS_CO2_COSTS,
  NETS_CO2_COSTS_LIST,
};
