import {gql} from "@apollo/client";

export const GET_CERT_GEN_PREVIEW_DATA = gql`
  query bioLcGetCertGenPreviewData(
    $divisionId: String
    $siteReferenceId: String
    $mbOutboundQtyEuId: String
    $contractNumber: String
    $dateOfIssuance: String
    $modeOfTransport: String
    $recipientAddress: String
    $recipientName: String
    $recipientReceiptAddress: String
  ) {
    bioLcGetCertGenPreviewData(
      event: {
        contractNumber: $contractNumber
        dateOfIssuance: $dateOfIssuance
        modeOfTransport: $modeOfTransport
        recipientAddress: $recipientAddress
        recipientName: $recipientName
        mbOutboundQtyEuId: $mbOutboundQtyEuId
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        recipientReceiptAddress: $recipientReceiptAddress
      }
    ) {
      error
      statusCode
      downloadUrl
      templateData {
        additionalInfo
        agriculturalBiomassIntermediateCropFlag
        agriculturalBiomassLowRiskFlag
        allocatedElectricity
        allocatedHeat
        bioProductUser
        bioProductProducer
        certificateNumber
        certificationSystem
        chainOfCustodyOption
        contractNumber
        issueDatez
        defaultValueAppliedFlag
        euRedCompliantFlag
        ghgEccr
        ghgEccs
        ghgEec
        ghgEl
        ghgEmissionPercentageIn
        ghgEp
        ghgEsca
        ghgEtd
        ghgEu
        ghgTotal
        isccCompliantFlag
        isccIssuerLicense
        materialDispatchDatez
        materialSustainabilityCriteriaFlag
        nuts2Region
        producerInstallationDate
        productEnergyContent
        productType
        quantity
        quantityUom
        rawMaterialType
        rawMaterialOriginCountryName
        rawMaterialWasteOrResidueFlag
        recipientAddress
        recipientName
        recipientReceiptAddress
        status
        supplierAddress
        supplierDispatchAddress
        supplierDispatchAddressSameFlag
        supplierName
        userInstallationDate
        sdNumber
        documentMasterId
        certificateVersionNo
      }
    }
  }
`;

export default {GET_CERT_GEN_PREVIEW_DATA};
