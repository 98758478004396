import React from "react";
import PropTypes from "prop-types";
import tw, {css, styled} from "twin.macro";
import {BarChart, Bar, YAxis, ResponsiveContainer} from "recharts";
import {isItCurrentMonth} from "modules/forecasting/helpers/generateGraphValues";
import BarChartDataSet from "./BarChartDataSet";

const barCategoryStyles = {
  d4: {id: "d4_total", color: "#89cf00"},
  d5: {id: "d5_total", color: "#767676"},
  d6: {id: "d6_total", color: "#ffe600"},
  gas: {id: "total_gas", color: "#89cf00"},
  diesel: {id: "total_diesel", color: "#ffe600"},
  monthTotal: {id: "total_obligation", color: "#395600"},
};

const getBarColorById = (id) => barCategoryStyles?.[id]?.color ?? "";
const getValueByID = (id) => (data) => data?.[id]?.actualValue ?? data?.[id];

const BarByCategories = ({
  graphDataObj,
  renderDateLabel,
  barKeys = [],
  maxYValue = "auto",
}) => (
  <>
    <CurrentMonthBorderedDiv
      className={isItCurrentMonth(graphDataObj?.date) ? "currentMonth" : ""}
      data-test="bars-by-categories"
    >
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={[graphDataObj]} margin={{top: 0, bottom: 0}}>
          {barKeys?.map((id) => (
            <Bar
              dataKey={getValueByID(id)}
              fill={getBarColorById(id)}
              key={id}
              barSize={28}
            />
          ))}
          <YAxis domain={[0, maxYValue]} hide />
        </BarChart>
      </ResponsiveContainer>

      <BarChartDataSet categories={barKeys} data={graphDataObj} />
    </CurrentMonthBorderedDiv>

    {renderDateLabel && renderDateLabel(graphDataObj.date)}
  </>
);

BarByCategories.propTypes = {
  barKeys: PropTypes.array,
  graphDataObj: PropTypes.object,
  renderDateLabel: PropTypes.func,
  maxYValue: PropTypes.number,
};

export default BarByCategories;

const CurrentMonthBorderedDiv = styled.div(() => [
  tw`rounded flex flex-col items-center w-full h-full`,

  css`
    &.currentMonth {
      ${tw`outline-dashed outline-1 outline-gray-400 px-2`},
    }
  `,
]);
