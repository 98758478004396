import {useLazyQuery} from "@apollo/client";
import {
  COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID,
  COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID,
} from "graphql/coprocessing/shipments";
import {
  ACTIVE_BATCH_KEYS,
  ENDING_INVENTORY_LIMIT,
  ERRORS,
  INPUT_METRIC,
  SHIPMENT_FIELD_KEYS,
} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Input, InputGroup, Spinner} from "reactstrap";

const MetricInput = ({
  value,
  details,
  showMetric,
  onChange,
  maxValue,
  reportErrors,
  batchId = "",
  shipmentId = "",
  remainingVolume,
  updateActualVolume,
  updateTotalVolume,
  setChecking,
  isCommitted,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const [error, setError] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const [checkShipmentId, {loading: isLoadingShipmentCheck}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    },
  );

  const [checkBatchId, {loading: isLoadingBatchCheck}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_BATCH_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    setChecking(isTyping || isLoadingBatchCheck || isLoadingShipmentCheck);
  }, [setChecking, isTyping, isLoadingBatchCheck, isLoadingShipmentCheck]);

  const getIdValidation = async (validationFunc, args) => {
    try {
      const response = await validationFunc(args);
      const data = response?.data?.bioLcCoproUsShipmentsApi;
      if (data?.error) {
        setError(true);
        return reportErrors(details?.errorKey, data.error);
      }
      setError(false);
      reportErrors(details?.errorKey, "");

      if (data?.body?.shipment_volume) {
        const shipmentVolume = data.body.shipment_volume || 0;
        if (shipmentVolume <= remainingVolume) {
          updateTotalVolume(shipmentVolume);
        } else {
          setError(true);
          reportErrors(ACTIVE_BATCH_KEYS.SHIPMENT_ID, ERRORS.EXCEEDS_VOLUME);
          return false;
        }
      }
      return true;
    } catch (error) {
      console.error("Validation error:", error);
      setError(true);
      reportErrors(details?.errorKey, ERRORS.UNEXPECTED);
      return false;
    }
  };

  const validateShipmentId = async (inputValue) => {
    await getIdValidation(checkShipmentId, {
      variables: {shipmentId: inputValue, batchId},
    });
  };

  const validateBatchId = async (inputValue) => {
    await getIdValidation(checkBatchId, {
      variables: {batchId: inputValue},
    });
  };

  const validateTotalVolume = (inputValue) => {
    const parsedValue = parseFloat(inputValue);

    if (Number.isNaN(parsedValue)) {
      setError(true);
      return;
    }

    if (parsedValue === value) {
      setError(null);
      reportErrors(details?.errorKey, "");
      return;
    }

    const isError = parsedValue > maxValue;
    if (!isError) {
      setError(false);
      reportErrors(details?.errorKey, "");
    } else {
      setError(true);
    }

    updateActualVolume(parsedValue);
  };

  const validateEndingInventory = (inputValue) => {
    const numericInputValue = parseFloat(inputValue, 10);
    const lowerBound = 0;
    const upperBound = maxValue;
    // checks ending inventory is within range
    if (numericInputValue < lowerBound || numericInputValue > upperBound) {
      reportErrors(details?.errorKey, ERRORS.ENDING_INVENTORY_VALUE);
      return setError(true);
    }
    reportErrors(details?.errorKey, "");
    return setError(false);
  };

  async function validateInput(inputValue) {
    setIsTyping(false);
    if (!inputValue || inputValue.trim().length === 0) {
      reportErrors(details?.errorKey, "");
      return null;
    }

    switch (details?.key) {
      case "batch_id":
        await validateBatchId(inputValue);
        break;
      case "shipment_id":
        await validateShipmentId(inputValue);
        break;
      case "ending_inventory":
        validateEndingInventory(inputValue);
        break;
      case SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL:
        validateTotalVolume(inputValue);
        break;
      default:
        return null;
    }
    return null;
  }

  const handleActualVolume = (inputValue) => {
    setError(null);
    const value = parseFloat(inputValue);

    if (Number.isNaN(value)) {
      setError(true);
      return;
    }

    // Set input field error state based on value exceeding max volume
    if (value > maxValue) {
      setError(true);
    }

    updateActualVolume(value);
  };

  const isTotalActualVolumeKey =
    details?.key === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL;

  const exceedsMaxValue = (inputValue, actualMaxValue) => {
    return (
      maxValue &&
      inputValue > actualMaxValue &&
      // Bypasses maxValue restriction for 'total_actual_volume'
      details?.key !== SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL
    );
  };
  const handleInputChange = (event) => {
    setIsTyping(true);
    let targetValue = event?.target?.value;

    if (showMetric) {
      targetValue = targetValue.replace(/[^0-9.]/g, "");
      const numericValue = Number(targetValue);

      const actualMaxValue =
        maxValue +
        (details?.key === ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV
          ? ENDING_INVENTORY_LIMIT.UPPER
          : 0);
      if (
        Number.isNaN(numericValue) ||
        exceedsMaxValue(numericValue, actualMaxValue)
      ) {
        // If the value is not a number or exceeds the maxValue, don't update
        return;
      }
    }

    if (details?.key === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL) {
      handleActualVolume(targetValue);
    }

    setInputValue(targetValue);
    onChange(
      {target: {name: details?.key, value: targetValue}},
      batchId !== "" ? batchId : shipmentId,
    );
  };

  return (
    <div data-test="copro-metric-input" className="max-w-[205px]">
      <InputGroup
        data-test="copro-metric-input-group"
        className={`${showMetric ? "min-w-[169px]" : "min-w-[205px]"} ${
          !error ? "" : "border-2 border-red-500"
        } input-group-merge`}
      >
        <Input
          data-test="copro-metric-actual-input"
          className={`${
            showMetric ? "min-w-[169px]" : "min-w-[205px]"
          } text-xs`}
          name={details?.key}
          id={details?.key}
          value={inputValue ?? ""}
          placeholder={details?.placeholder}
          onChange={(event) => handleInputChange(event)}
          onBlur={(event) => validateInput(event?.target?.value)}
          valid={!error && error != null}
          disabled={isCommitted}
        />
        {!!showMetric && (
          <div data-test="copro-metric-appended" className="input-group-append">
            <div className="input-group-text">{INPUT_METRIC}</div>
          </div>
        )}
        {(!!isLoadingShipmentCheck || !!isLoadingBatchCheck) && (
          <div
            data-test="copro-loading-appended"
            className="input-group-append"
          >
            <div className="input-group-text">
              <Spinner size="sm" />
            </div>
          </div>
        )}
      </InputGroup>
    </div>
  );
};

MetricInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  details: PropTypes.shape({
    errorKey: PropTypes.string,
    index: PropTypes.number,
    key: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  showMetric: PropTypes.bool,
  maxValue: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  reportErrors: PropTypes.func,
  batchId: PropTypes.string,
  shipmentId: PropTypes.string,
  remainingVolume: PropTypes.number,
  updateActualVolume: PropTypes.func,
  updateTotalVolume: PropTypes.func,
  setChecking: PropTypes.func,
  isCommitted: PropTypes.bool,
};

export default MetricInput;
