import {gql} from "@apollo/client";
import axios from "axios";
import {client} from "providers/Apollo";

export const UPLOAD_FILE = gql`
  query uploadIncomingDocApi(
    $docName: String!
    $siteReferenceId: String
    $divisionId: String!
  ) {
    bioLcUploadIncomingDocApi(
      event: {
        divisionId: $divisionId
        docName: $docName
        siteReferenceId: $siteReferenceId
      }
    ) {
      error
      statusCode
      uploadUrl
    }
  }
`;

export const DOC_STATUS_TRACKER = gql`
  query uploadIncomingDocStatusTrackerApi(
    $divisionId: String!
    $siteReferenceId: String
    $docName: String!
  ) {
    bioLcUploadIncomingDocStatusTrackerApi(
      event: {
        divisionId: $divisionId
        docName: $docName
        siteReferenceId: $siteReferenceId
      }
    ) {
      error
      status
      statusCode
    }
  }
`;

export const uploadFile = async (file, siteReferenceId, divisionId) => {
  const isResponseSuccess = (code) => [200, 204].includes(code);

  const buildFormData = (fileData, fields) => {
    const formData = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
    }
    formData.append("file", fileData);
    return formData;
  };

  let statusCode;

  try {
    // Allocate s3 bucket
    const {
      data: {bioLcUploadIncomingDocApi: responseFileUpload},
    } = await client.query({
      query: UPLOAD_FILE,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        docName: file.name,
        siteReferenceId,
        divisionId,
      },
    });

    statusCode = responseFileUpload?.statusCode;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // Upload binary file
    const body = JSON.parse(responseFileUpload?.uploadUrl);
    const formData = buildFormData(file, body.fields);
    const uploadBinaryResponse = await axios.post(body.url, formData);
    statusCode = uploadBinaryResponse.status;
    if (!isResponseSuccess(statusCode)) {
      return statusCode;
    }

    // All good
    return 200;
  } catch (error) {
    // Handle errors here
    console.error("Error uploading file:", error);
    return error.response?.status || 500;
  }
};
