/* eslint-disable no-param-reassign */
const sumByProperty = (list, ...props) =>
  list.reduce((yearTotal, monthItem) => {
    props.forEach((key) => {
      const total = yearTotal[key] ?? 0;
      const value = monthItem[key]?.actualValue ?? 0;

      yearTotal[key] = total + value;
    });

    return yearTotal;
  }, {});

export default sumByProperty;
