import React from "react";
import "./index.scss";
import Modal from "modules/common/Modal";
import PropTypes from "prop-types";
import Error from "assets/images/svg/error.svg";
import Info from "assets/images/svg/info-yellow.svg";
import {ArrowLeftOutlined} from "@ant-design/icons";
import Button from "DLS/Button";

const ErrorModal = ({title, visible, onCancel, errors = []}) => {
  return (
    <Modal
      data-test="error-modal"
      visible={visible}
      title={
        <>
          <img
            data-test="status-icon"
            className="w-12 h-12 mb-5"
            src={Error}
            alt=""
          />
          {title}
        </>
      }
      footer={null}
      onCancel={onCancel}
      closable
      destroyOnClose
      width="30%"
    >
      <p className="text-indigo-500">
        Following errors found in the uploaded file -
      </p>
      <ul className="mt-6 mb-6 ml-5 text-indigo-500 bullet-list">
        {errors.map(({columnName}) => (
          <li key={columnName}>{columnName}</li>
        ))}
      </ul>
      <p className="pb-10 text-indigo-500">
        Please rectify the fields and upload
      </p>
      <Button
        type="secondary"
        label="Close"
        data-test="close"
        onClick={onCancel}
      />
    </Modal>
  );
};

export const PoSErrorModal = ({title, visible, onCancel}) => {
  return (
    <Modal
      data-test="pos-error-modal"
      className="error-pos-modal"
      visible={visible}
      title={
        <>
          <img
            data-test="status-icon"
            className="w-12 h-12 mb-5"
            src={Error}
            alt=""
          />
          {title}
        </>
      }
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      width="50%"
    >
      <p className="mb-8 text-indigo-500">
        There was an error in uploading the PoS Certificate. Please try again or
        if you face issues please get in touch with the support team using the{" "}
        <a
          href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5MzUzNjg3MDQwMCJ9/all"
          target="_blank"
          className="inline underline cursor-pointer text-blue-500"
          rel="noreferrer"
        >
          link
        </a>{" "}
        in the page.
      </p>

      <Button
        type="secondary"
        label="Close"
        data-test="close"
        onClick={onCancel}
      />
    </Modal>
  );
};

export const ErrorModalBasic = ({title, visible, onCancel}) => {
  return (
    <Modal
      data-test="error-modal-basic"
      visible={visible}
      closable
      destroyOnClose
      width="30%"
      footer={null}
      onCancel={onCancel}
    >
      <img
        data-test="status-icon"
        className="w-12 h-12 mb-5"
        src={Error}
        alt=""
      />
      <h3 className="mb-5 text-3xl font-bold text-indigo-500">{title}</h3>
      <Button
        type="secondary"
        label="back"
        icon={<ArrowLeftOutlined />}
        data-test="error-modal-back-btn"
        onClick={onCancel}
      />
    </Modal>
  );
};

export const ExecutionErrorModal = ({title, visible, onCancel}) => {
  return (
    <Modal
      data-test="error-modal-execution"
      visible={visible}
      closable
      destroyOnClose
      width="30%"
      footer={null}
      onCancel={onCancel}
    >
      <img data-test="status-icon" className="w-8 h-8 mb-5" src={Info} alt="" />
      <h3 className="mb-5 text-3xl font-bold text-indigo-500">{title}</h3>
      <Button
        label="close"
        icon={<ArrowLeftOutlined />}
        type="secondary"
        data-test="error-modal-close-btn"
        onClick={onCancel}
      />
    </Modal>
  );
};

ErrorModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  errors: PropTypes.array,
};

PoSErrorModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

ErrorModalBasic.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

ExecutionErrorModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default ErrorModal;
