import {useState, useCallback, useMemo} from "react";
import {Button} from "reactstrap";
import {useQuery, useLazyQuery} from "@apollo/client";
import {useNavigate} from "react-router-dom";
import tw from "twin.macro";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import buildAllocationDetails from "modules/co-processing/helpers/buildAllocationDetails";
import combineShipments from "modules/co-processing/helpers/combineShipments";
import {TABLE_LABELS} from "modules/co-processing/constants/bulkAdjustment";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";
import {
  COPRO_US_GET_SELECTED_WATERFALL_BATCHES,
  COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
} from "graphql/coprocessing/shipments";
import {
  ERRORS,
  SHIPMENT_SUCCESS,
} from "modules/co-processing/constants/shipments";
import Banner from "modules/co-processing/components/Shipments/Banner";
import BatchesTable from "./BulkAdjustmentBatchesTable";
import ShipmentsTable from "./BulkAdjustmentShipmentsTable";
import "./index.scss";

const TableHeader = tw.div`flex items-center bg-white text-sm h-14 pl-3.5 self-center border-b`;

const BulkAdjustmentDashboard = ({selectedBatches, volume}) => {
  const navigate = useNavigate();

  const [batches, setBatches] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [shipmentErrors, setShipmentErrors] = useState({});
  const [remainingVolume, setRemainingVolume] = useState(volume);
  const [formData, setFormData] = useState([]);

  const [allocateBatchtoShipment, {data: res}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (!res) return {};
        const {error, statusCode} = res.bioLcCoproUsShipmentsApi;
        if (error) {
          const errorMessage =
            statusCode === 400
              ? `${ERRORS.FAILED_ALLOCATION}. ${error}`
              : ERRORS.FAILED_ALLOCATION;
          return toast.error(errorMessage);
        }
        toast.success(SHIPMENT_SUCCESS);
        return navigate("/co-processing/shipments");
      },
    },
  );

  const batchList = useMemo(() => {
    const list =
      Object.keys(selectedBatches).filter((key) => selectedBatches[key]) ?? [];
    return list;
  }, [selectedBatches]);

  useQuery(COPRO_US_GET_SELECTED_WATERFALL_BATCHES, {
    fetchPolicy: "network-only",
    variables: {batch_ids: batchList},
    skip: batchList?.length === 0,
    onCompleted: (data) => {
      const res = data?.bioLcCoproUsShipmentsApi?.body?.batches ?? [];
      setBatches(res);

      const shipmentsData = res
        .flatMap((batch) => batch.shipments)
        .filter((shipment) => shipment && Object.keys(shipment).length > 0);

      setShipments(shipmentsData);
    },
  });

  const updateRemainingVolume = useCallback((previousValue, currentValue) => {
    if (previousValue === currentValue) return;
    const difference = previousValue - currentValue;
    setRemainingVolume(
      (prevRemainingVolume) => prevRemainingVolume + difference,
    );
  }, []);

  const updateValues = useCallback((name, value, idKey, idValue) => {
    setFormData((prevData) => {
      return buildAllocationDetails(prevData, name, value, idKey, idValue);
    });
  }, []);

  const updateShipmentData = useCallback(
    (shipmentId, newValue) => {
      const sanitizedValue = parseFloat(newValue);
      if (Number.isNaN(sanitizedValue)) return; // Skip non-numeric values

      const shipmentsData = shipments.map((shipment) => ({...shipment}));
      const index = shipmentsData.findIndex(
        (item) => item.shipment_id === shipmentId,
      );

      if (index === -1) return;

      const previousValue =
        shipmentsData[index].updatedVolume ??
        shipmentsData[index].shipment_volume;

      if (previousValue === sanitizedValue) return;

      shipmentsData[index] = {
        ...shipmentsData[index],
        updatedVolume: sanitizedValue,
      };

      setShipments(shipmentsData);
      updateValues(
        "actual_shipment_volume",
        sanitizedValue,
        "shipment_id",
        shipmentId,
      );
      updateRemainingVolume(previousValue, sanitizedValue);
    },
    [shipments, updateValues, updateRemainingVolume],
  );

  const handleSave = () => {
    allocateBatchtoShipment({
      variables: {
        batch_shipment_info: combineShipments(formData),
      },
    });
  };

  const handleShipmentError = useCallback((shipmentId, errorMessage) => {
    setShipmentErrors((errs) => ({
      ...errs,
      [shipmentId]: errorMessage,
    }));
  }, []);

  const hasErrors = useMemo(
    () => Object.values(shipmentErrors).some((error) => error != null),
    [shipmentErrors],
  );

  return (
    <>
      <div className="mt-7 bg-white px-5 py-4">
        <div className="flex justify-between my-3">
          <div
            className="flex space-x-5 items-center"
            data-test="volume-to-adjust"
          >
            <span className="text-secondary">Remaining volume to adjust</span>
            <span>{`${remainingVolume} bbl`}</span>
          </div>

          <div className="ml-auto space-x-4">
            <Button
              className="btn-clear rounded-0"
              color="standard-outline"
              data-test="cancel-adjustment-btn"
              onClick={() => navigate("/co-processing/shipments")}
            >
              {BUTTONS.CANCEL}
            </Button>

            <Button
              color="primary"
              className="rounded-0 text-nowrap"
              data-test="submit-adjustment-btn"
              disabled={hasErrors || !formData.length}
              onClick={handleSave}
            >
              {BUTTONS.SUBMIT_ADJUSTMENT}
            </Button>
          </div>
        </div>

        {Object.values(shipmentErrors).map((error, index) => (
          <Banner
            key={index}
            errorState={!!error}
            text={error}
            type="warning"
          />
        ))}
      </div>

      <div className="py-4">
        <div className="flex">
          <div className="bulk-adjustment-table w-1/4 overflow-x-auto">
            <TableHeader>{TABLE_LABELS.BATCHES}</TableHeader>
            <BatchesTable batches={batches} shipments={shipments} />
          </div>
          <div className="bulk-adjustment-table w-3/4">
            <TableHeader>{TABLE_LABELS.SHIPMENTS}</TableHeader>
            <ShipmentsTable
              shipments={shipments}
              onVolumeChange={updateShipmentData}
              onError={handleShipmentError}
            />
          </div>
        </div>
      </div>
    </>
  );
};

BulkAdjustmentDashboard.propTypes = {
  selectedBatches: PropTypes.object,
  volume: PropTypes.number,
};

export default BulkAdjustmentDashboard;
