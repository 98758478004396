import styled from "@emotion/styled";
import PropTypes from "prop-types";
import {useUserSettings, formatNumber} from "providers/userSettings";

const StyledDataText = styled.div`
  color: #ffffffa3;
`;

const TooltipContent = ({active, selectedPayload}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (!active || !selectedPayload) {
    return null;
  }
  const {
    netCommittedVolume,
    netActualizedVolume,
    netForecastVolume,
    creditActualizedVolume,
    creditCommittedVolume,
    creditForecastVolume,
    deficitActualizedVolume,
    deficitCommittedVolume,
    deficitForecastVolume,
    timePeriod,
  } = selectedPayload;

  return (
    <>
      <p className="mb-2">{timePeriod}</p>

      <StyledDataText>
        Actualized Net = {formatNumber(netActualizedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Actualized Credit ={" "}
        {formatNumber(creditActualizedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Actualized Deficit ={" "}
        {formatNumber(deficitActualizedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Committed Net = {formatNumber(netCommittedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Committed Credit ={" "}
        {formatNumber(creditCommittedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Committed Deficit ={" "}
        {formatNumber(deficitCommittedVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Forecast Net = {formatNumber(netForecastVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Forecast Credit = {formatNumber(creditForecastVolume, decimalFormat, 0)}
      </StyledDataText>
      <StyledDataText>
        Forecast Deficit ={" "}
        {formatNumber(deficitForecastVolume, decimalFormat, 0)}
      </StyledDataText>
    </>
  );
};

TooltipContent.propTypes = {
  active: PropTypes.bool,
  selectedPayload: PropTypes.arrayOf(PropTypes.object),
};

export default TooltipContent;
