import "./index.scss";
import React from "react";
import PropTypes from "prop-types";

const Side = ({children, isOpen, onClose}) => {
  return (
    <div
      role="complementary"
      className={`
    top-0 z-10 flex-shrink-0 bg-white min-h-0 shadow-md transition-all 
     ${isOpen ? "w-80 sticky" : "w-0 -translate-x-full"}
   `}
    >
      <div className="relative flex flex-col h-full pb-10 space-y-6 overflow-auto overflow-x-hidden no-scrollbar ">
        {children}
      </div>
    </div>
  );
};

Side.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Side;
