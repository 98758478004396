export const POLLING_FIRST_TRIGGER_IN_SECONDS = 20000;
export const POLLING_END_IN_SECONDS = 50000;
export const POLLING_INTERVAL_IN_SECONDS = 10000;

export const INCOMING_DOC_STATUS = {
  READY_TO_ACCEPT: "ready_to_accept",
  REQUIRES_ATTENTION: "requires_attention",
  READY_TO_ASSIGN: "ready_to_assign",
  REJECTED: "rejected",
  ASSIGNED: "assigned",
  ADDITIONAL_INFO: "additional_info",
  READY_FOR_MB: "ready_for_mb",
  ATTENTION: "attention",
  FAILED_TO_PROCESS: "failed_to_process",
  COMPLETED: "completed",
  ATTENTION_SPLIT: "attention-split",
};

export const DOC_STATUS = {
  ready_to_accept: "Ready to accept",
  requires_attention: "Requires attention",
  ready_to_assign: "Ready to assign",
  rejected: "Rejected",
  assigned: "Assigned",
  additional_info: "Additional info",
  ready_for_mb: "Ready for mass balance",
  attention: "Attention",
  failed_to_process: "Failed to process",
  completed: "Completed",
};

export const DIVISION_ID_GF = "c07b0b4d7660314f711a68fc47c4ab38";
