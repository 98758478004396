import {useMemo} from "react";
import PropTypes from "prop-types";
import tw from "twin.macro";
import sumByProperty from "modules/forecasting/helpers/sumByProperty";
import BarGraphSet from "./BarGraphSet";

const BarChartContainer = tw.div`
  border border-solid rounded bg-gray-50 py-8
  flex flex-col shrink-0 
  content-between items-center
  overflow-x-scroll lg:overflow-x-hidden
`;

const GraphHeaderLabel = tw.div`place-self-center text-base pt-10`;
const StyledFlexRow = tw.div`flex flex-row w-full justify-evenly`;

const removeCommas = (str) => str.replace(/,/g, "");
const formatToNumber = (value) =>
  !!value && typeof value === "string"
    ? parseInt(removeCommas(value), 10)
    : value;

const convertToBarrels = (v) => Math.round(formatToNumber(v) / 1000, 10);

const getGasAndDieselGraphTotals = (yearData) => {
  if (!yearData.length) return {};

  const {total_gas, total_diesel} = sumByProperty(
    yearData,
    "total_gas",
    "total_diesel",
  );

  return {
    gas: {value: convertToBarrels(total_gas)},
    diesel: {value: convertToBarrels(total_diesel)},
  };
};

const getObligationDate = (list) => {
  const obligation =
    list?.find((obligationItem) => !!obligationItem?.date) ?? {};

  if (!obligation.date) return {};

  return obligation.date;
};

const getYearTotalGraphData = ({gas, diesel} = {}) => {
  if (!gas || !diesel) return {};

  return {
    name: "TOTAL",
    total: {
      value: diesel.value + gas.value,
    },
    gas: {
      value: gas.value,
    },
    diesel: {
      value: diesel.value,
    },
  };
};

const dailyAvgReducer = ({year}, {value}) => {
  const numberOfDays =
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 366 : 365;

  return {
    value: (value / numberOfDays).toFixed(1),
  };
};

const getBarrelsPerDayGraphData = (year, yearTotalGraphObj) =>
  Object.entries(yearTotalGraphObj).reduce((memo, [key, value]) => {
    if (key === "name") {
      return {...memo, [key]: "KBD"};
    }

    return {
      ...memo,
      [key]: [value].reduce(dailyAvgReducer, {year}),
    };
  }, {});

const sumTotalFromTableData = ({pageData, tableData}) => {
  const [gasTableRow, dieselTableRow] = tableData.filter(
    ({key = ""}) => key === "total_gas" || key === "total_diesel",
  );

  const updatedTotal = pageData.reduce(
    ({gas, diesel}, {date, total_gas, total_diesel} = {}) => {
      const key = `${date.year}_${date.month}`;
      const gasValue =
        gasTableRow?.updatedForecast?.[key] ?? total_gas.actualValue;
      const dieselValue =
        dieselTableRow?.updatedForecast?.[key] ?? total_diesel.actualValue;

      return {
        gas: gas + gasValue,
        diesel: diesel + dieselValue,
      };
    },
    {gas: 0, diesel: 0},
  );

  return {
    gas: {value: convertToBarrels(updatedTotal.gas)},
    diesel: {value: convertToBarrels(updatedTotal.diesel)},
  };
};

const YearTotalBarChart = ({view, tableData, pageData = []}) => {
  if (!pageData?.length) {
    return (
      <BarChartContainer data-test="no-data-total-barrels">
        <div className="p-8">N/A</div>
      </BarChartContainer>
    );
  }

  const yearTotalValues = useMemo(
    () =>
      tableData?.length
        ? sumTotalFromTableData({pageData, tableData})
        : getGasAndDieselGraphTotals(pageData),
    [pageData, tableData],
  );
  const yearTotalGraphData = getYearTotalGraphData(yearTotalValues);

  const {year = "N/A"} = getObligationDate(pageData) || {};

  return (
    <BarChartContainer data-test="year-total-barrels-per-day-bar-chart">
      <StyledFlexRow>
        <BarGraphSet data={[yearTotalGraphData]} view={view} width={170} />
        <BarGraphSet
          data={[getBarrelsPerDayGraphData(year, yearTotalGraphData)]}
          view={view}
          width={145}
        />
      </StyledFlexRow>
      <GraphHeaderLabel data-test="year-header">{`${year}`}</GraphHeaderLabel>
    </BarChartContainer>
  );
};

YearTotalBarChart.propTypes = {
  pageData: PropTypes.array,
  view: PropTypes.string,
  tableData: PropTypes.array,
};

export default YearTotalBarChart;
