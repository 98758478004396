import Tab from "modules/common/Svg/Tab";
import {NavLink, useLocation} from "react-router-dom";

const activeStyles = " bg-indigo-500 text-white";
const inActiveStyles =
  "border-indigo-500 bg-white text-indigo-500 hover:no-underline";
const navStyles =
  "capitalize flex items-center gap-x-2 rounded border h-8 px-3 py-1 font-medium hover:text-gray-500 hover:opacity-50 hover:no-underline";
const tabSelected = "#fff";
const tabUnselected = "#161038";

const LANDING_URL = "/forecasting";
const landingPageTab = ["published"];
const tabs = ["data", "unpublished", "published"];

const extractRegion = (pathname = "") =>
  pathname.substring(pathname.lastIndexOf("/") + 1, pathname.indexOf("-"));

const createForecastTabLinks = (urlPath) => {
  const region = extractRegion(urlPath);

  if (urlPath === "/forecasting") {
    return landingPageTab.map((view) => ({
      name: view,
      to: LANDING_URL,
    }));
  }

  return tabs.map((view) => ({
    name: view,
    to: `${LANDING_URL}/${region}-forecast-${view}`,
  }));
};

export default () => {
  const {pathname} = useLocation();
  const tabLinks = createForecastTabLinks(pathname);

  return (
    <div
      data-test="tabs"
      className="flex flex-col bg-gray-50 w-full py-4 px-6 lg:px-10 sm:flex-row gap-y-2 sm:gap-x-3"
    >
      {tabLinks.map(({to, name}) => (
        <NavLink
          data-test={`inventory-${name.toLowerCase()}`}
          to={to}
          key={name}
          className={({isActive}) =>
            navStyles + (isActive ? activeStyles : inActiveStyles)
          }
        >
          {({isActive}) => (
            <>
              <Tab fill={isActive ? tabSelected : tabUnselected} />
              {name}
            </>
          )}
        </NavLink>
      ))}
    </div>
  );
};
