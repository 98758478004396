import {Datepicker as BPDatepicker} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const DatePicker = React.forwardRef(
  ({className = "", noHeader = false, ...props}, ref) => {
    return (
      <BPDatepicker
        ref={ref}
        className={["bp-datepicker-bp-core", className].join(" ")}
        {...props}
      />
    );
  },
);

DatePicker.propTypes = {
  className: PropTypes.string,
  noHeader: PropTypes.bool,
};

export default DatePicker;
