import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";

const ResponseModal = ({
  showResponse,
  setShowResponse,
  isUploading,
  isErrorModalVisible,
  setErrorModalVisible,
  setUploadModal,
  bodyClassNames = "",
  isUploadSuccessful,
  setIsUploadSuccessful,
  isDuplicate,
  setIsDuplicate,
  isStillUploading,
  setIsStillUploading,
}) => {
  return (
    <Modal size="sm" isOpen={showResponse} className="modal-dialog-centered">
      {(isErrorModalVisible || isUploadSuccessful || isStillUploading) && (
        <ModalHeader
          className="border-b-2 border-gray-200 mb-2 text-xl"
          close={
            <Close
              onClick={() => {
                setShowResponse(false);
                setErrorModalVisible(false);
                setIsDuplicate(false);
                setIsUploadSuccessful(false);
                setIsStillUploading(false);
              }}
            />
          }
        ></ModalHeader>
      )}
      <ModalBody
        className={`flex flex-col items-center w-72 ${bodyClassNames}`}
      >
        {isUploading && (
          <>
            <p className="pt-5 mb-8">Document Uploading</p>
            <Spinner size={26} />
          </>
        )}
        {isErrorModalVisible && (
          <>
            <p className="mb-4">Upload failed</p>
            <p className="text-xs text-center">
              {isDuplicate
                ? "A document with the same unique ID number already exists. The existing version needs to be rejected before uploading a new version with the same unique ID number."
                : "Please try uploading again."}
            </p>
          </>
        )}
        {isUploadSuccessful && (
          <>
            <p className="pt-5 mb-4">Upload successful</p>
            <p className="text-xs">
              You have successfully uploaded a document.
            </p>
          </>
        )}
        {isStillUploading && (
          <>
            <p>Document is still uploading</p>
            <p className="text-xs text-center">
              This process is taking longer than anticipated. Please check back
              later to see if the upload has been completed.
            </p>
          </>
        )}
      </ModalBody>
      {isErrorModalVisible && (
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setShowResponse(false);
                  setErrorModalVisible(false);
                  setIsDuplicate(false);
                }}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                onClick={() => {
                  setShowResponse(false);
                  setUploadModal(true);
                  setErrorModalVisible(false);
                  setIsDuplicate(false);
                }}
              >
                Try again
              </Button>
            </div>
          </div>
        </ModalFooter>
      )}

      {(isUploadSuccessful || isStillUploading) && (
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-12 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                onClick={() => {
                  setShowResponse(false);
                  setErrorModalVisible(false);
                  setIsDuplicate(false);
                  setIsUploadSuccessful(false);
                  setIsStillUploading(false);
                }}
              >
                {isUploadSuccessful ? "Done" : "Close"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      )}
    </Modal>
  );
};

ResponseModal.propTypes = {
  showResponse: PropTypes.bool.isRequired,
  setShowResponse: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  isErrorModalVisible: PropTypes.bool.isRequired,
  setErrorModalVisible: PropTypes.func.isRequired,
  setUploadModal: PropTypes.func.isRequired,
  bodyClassNames: PropTypes.string,
  isUploadSuccessful: PropTypes.bool.isRequired,
  setIsUploadSuccessful: PropTypes.func,
  isDuplicate: PropTypes.bool,
  setIsDuplicate: PropTypes.func,
  isStillUploading: PropTypes.bool,
  setIsStillUploading: PropTypes.bool,
};

export default ResponseModal;
