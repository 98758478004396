import PropTypes from "prop-types";
import "./index.scss";

const Card = ({children, className = "", ...props}) => (
  <div className={["bp-card", className].join(" ")} {...props}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Card;
