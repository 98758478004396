import PropTypes from "prop-types";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getExpandedRowModel,
} from "@tanstack/react-table";
import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {Table, Input} from "reactstrap";
import {Fragment, useEffect, useMemo, useState} from "react";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import "./document-table.css";
import {useSearchParams} from "react-router-dom";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {customDateFilterFn} from "modules/SAF-dashboard/utils";

const Checkbox = (row) => (
  <Input
    type="checkbox"
    checked={row.row.getIsSelected()}
    onChange={row.row.getToggleSelectedHandler()}
  />
);

const TableCheckbox = (table) => (
  <div className="d-flex align-items-center">
    <Input
      type="checkbox"
      checked={table.table.getIsAllRowsSelected()}
      onChange={table.table.getToggleAllRowsSelectedHandler()}
    />
  </div>
);

const getColumns = (columns, enableRowSelection) => {
  const newColumns = enableRowSelection
    ? [
        {
          header: ({table}) => <TableCheckbox table={table} />,
          accessorKey: "checkbox",
          enableSorting: false,
          size: 25,
          cell: ({row}) => <Checkbox row={row} />,
        },
        ...columns,
      ]
    : columns;

  return newColumns;
};

const DocumentTable = ({
  columns,
  data,
  enableRowSelection,
  rowSelection,
  setRowSelection,
  className,
  loading,
  highlightedRows,
  filtering,
  setFiltering,
  columnFilter,
  setColumnFilter,
  type,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHighlight, setIsHighlight] = useState(true);
  const docId = searchParams.get("docId");
  const pageIndex = searchParams.get("pageNo") ?? 0;
  const newColumns = useMemo(
    () => getColumns(columns, enableRowSelection),
    [columns, enableRowSelection],
  );
  const table = useReactTable({
    data,
    columns: newColumns,
    enableRowSelection,
    filterFns: {
      dateRangeFilterFn: customDateFilterFn,
    },
    state: {
      rowSelection,
      globalFilter: filtering,
      columnFilters: columnFilter,
    },
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    onColumnFiltersChange: setColumnFilter,
    onGlobalFilterChange: setFiltering,
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => row.splitDetails ?? [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  useEffect(() => {
    if (data && data.length === 0) return () => {};
    if (highlightedRows && highlightedRows.length) {
      setIsHighlight(true);
    }
    const setPageIndexTimeout = setTimeout(
      () => table.setPageIndex(parseInt(pageIndex, 10)),
      100,
    );
    // Remove highlight after 5.1 secs
    const removeHighlightTimeout = setTimeout(
      () => setIsHighlight(false),
      5100,
    );
    return () => {
      clearTimeout(setPageIndexTimeout);
      clearTimeout(removeHighlightTimeout);
    };
  }, [table, pageIndex, data, setIsHighlight, highlightedRows]);

  const isCellHighlighted = (cell, documentId) =>
    cell.row.original.certificateInboundEuId === documentId ||
    cell.row.original.certificate_outbound_eu_id === documentId ||
    (highlightedRows &&
      highlightedRows.includes(cell.row.original.certificate_outbound_eu_id));

  const totalData = table.getPrePaginationRowModel().rows.length;

  return (
    <>
      <Table className={className} hover>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {header.isPlaceholder ? (
                    <th aria-label="placeholder" />
                  ) : header.column.getCanSort() ? (
                    <th
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        cursor: "pointer",
                        userSelect: "none",
                        width: header.getSize(),
                      }}
                    >
                      <div
                        className={`d-flex align-items-center ${
                          header.getContext().column.columnDef.dataType ===
                            "number" && "justify-content-end"
                        }`}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                        <div className="position-relative ms-2">
                          <div className="react-table-sort position-absolute top-50 translate-middle-y">
                            <TriangleDown16
                              className={`position-absolute bottom-0 ${
                                !header.column.getIsSorted() ||
                                (header.column.getIsSorted() &&
                                  header.column.getIsSorted() !== "desc")
                                  ? "opacity-20"
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  type === "Incoming" &&
                                  header.id === "status"
                                ) {
                                  searchParams.delete("sortBy");
                                  setSearchParams(searchParams);
                                }
                              }}
                            />
                            <TriangleUp16
                              className={`position-absolute top-0 ${
                                !header.column.getIsSorted() ||
                                (header.column.getIsSorted() &&
                                  header.column.getIsSorted() !== "asc")
                                  ? "opacity-20"
                                  : ""
                              }`}
                              onClick={() => {
                                if (
                                  type === "Incoming" &&
                                  header.id === "status"
                                ) {
                                  searchParams.delete("sortBy");
                                  setSearchParams(searchParams);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  ) : (
                    <th
                      // align-top for row selection and row options columns so positioning is same as <td>
                      className="align-top"
                      style={{
                        width: header.getSize(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  )}
                </Fragment>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <SpinnerLoading />
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            totalData > 0 &&
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`${
                      isCellHighlighted(cell, docId) && isHighlight
                        ? "bg-success-subtle"
                        : ""
                    }
                        ${
                          cell.getContext().column.columnDef.dataType ===
                            "number" && "text-right"
                        }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          {!loading && totalData === 0 && !filtering && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">No Data</div>
              </td>
            </tr>
          )}
          {!loading && totalData === 0 && filtering && (
            <tr>
              <td colSpan="100%">
                <div className="pt-7 pl-5">
                  {`Sorry, no ${
                    type === "Incoming" ? "incoming" : "outgoing"
                  } document matched your search. `}
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setFiltering("")}
                    onKeyDown={() => console.log("setFiltering")}
                  >
                    Clear search
                  </span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};

DocumentTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  highlightedRows: PropTypes.array,
  filtering: PropTypes.string,
  setFiltering: PropTypes.func,
  columnFilter: PropTypes.string,
  setColumnFilter: PropTypes.func,
  type: PropTypes.string,
};

export default DocumentTable;
