import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import PropTypes from "prop-types";
import {useState} from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DropdownCustomizeItem from "../CustomizeTable/DropdownCustomizeItem";
import Droppable from "../CustomizeTable/Droppable";

const CustomizeTable = ({
  columns,
  onColumnChange,
  showApplyButton = true,
  purchaseColumns,
  onPurchaseChange,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const handleColumnChange = (column, type) => {
    const _columns = type === "Incoming" ? columns : purchaseColumns;
    const header = _columns?.[0]?.header;
    const newColumns = _columns?.[0]?.columns.map((c) =>
      c.key === column.key ? column : c,
    );
    if (type === "Incoming") {
      onColumnChange([{header, columns: newColumns}]);
    } else {
      onPurchaseChange([{header, columns: newColumns}]);
    }
  };

  const handleDragEnd = (event, type) => {
    const {active, over} = event;
    if (!active || !over) {
      return;
    }
    if (active.id === over.id) {
      return;
    }
    const _columns = type === "Incoming" ? columns : purchaseColumns;
    const header = _columns?.[0]?.header;
    const newColumns = _columns?.[0]?.columns;
    const reorderedColumns = [...newColumns];
    const activeIndex = reorderedColumns.findIndex(
      (c) => c.header === active.id,
    );
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.header === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);

    if (type === "Incoming") {
      onColumnChange([{header, columns: reorderedColumns}]);
    } else {
      onPurchaseChange([{header, columns: reorderedColumns}]);
    }
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const incomingLength = columns?.[0]?.columns.length;
  const purchaseLength = purchaseColumns?.[0]?.columns.length;

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle color="tertiary" className="rounded-0 ms-0 border" caret>
        Customize columns
        <Down24 className="btn-icon-suffix" />
      </DropdownToggle>
      <DropdownMenu
        className={`max-h-[500px] overflow-auto w-[560px] ${
          dropdownOpen ? "!flex" : ""
        } `}
      >
        <div>
          <p className="mt-[31px] ml-5 text-black">{columns?.[0]?.header}</p>
          <DndContext
            onDragEnd={(event) => {
              handleDragEnd(event, "Incoming");
            }}
            modifiers={[restrictToVerticalAxis]}
          >
            {columns?.[0]?.columns.map(
              (column) =>
                column.header !== "" && (
                  <div
                    key={column.header}
                    className={`${
                      incomingLength > purchaseLength ? "border-r-2" : ""
                    }`}
                  >
                    <Droppable key={column.header} id={column.header}>
                      <DropdownCustomizeItem
                        column={column}
                        onChange={(incomingColumn) => {
                          handleColumnChange(incomingColumn, "Incoming");
                        }}
                      />
                    </Droppable>
                  </div>
                ),
            )}
          </DndContext>
        </div>
        <div>
          <p className="mt-[31px] ml-5 text-black">
            {purchaseColumns?.[0]?.header}
          </p>
          <DndContext
            onDragEnd={(event) => {
              handleDragEnd(event, "Purchase");
            }}
            modifiers={[restrictToVerticalAxis]}
          >
            {purchaseColumns?.[0]?.columns.map(
              (column) =>
                column.header !== "" && (
                  <div
                    key={column.header}
                    className={`${
                      incomingLength > purchaseLength ? "" : "border-l-2"
                    }`}
                  >
                    <Droppable key={column.header} id={column.header}>
                      <DropdownCustomizeItem
                        column={column}
                        onChange={(purchaseColumn) => {
                          handleColumnChange(purchaseColumn, "Purchase");
                        }}
                      />
                    </Droppable>
                  </div>
                ),
            )}
          </DndContext>
        </div>
        {showApplyButton && (
          <>
            <DropdownItem divider />
            <div className="text-center">
              <Button
                onClick={toggle}
                size="lg"
                color="standard-primary"
                className="rounded-0 ms-3 primary-btn"
              >
                Apply changes
              </Button>
            </div>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomizeTable.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
  showApplyButton: PropTypes.bool,
  purchaseColumns: PropTypes.array,
  onPurchaseChange: PropTypes.func,
};

export default CustomizeTable;
