import React from "react";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {BUTTONS} from "modules/co-processing/constants/shipments";

const BulkAllocationResetButtons = ({
  onCancel,
  onSave,
  isSaveDisabled = true,
}) => (
  <div className="ml-auto">
    <Button
      className="rounded-0 mr-2"
      color="standard-outline"
      data-test="cancel-allocation-reset"
      onClick={onCancel}
    >
      <span>{BUTTONS.CANCEL}</span>
    </Button>
    <Button
      className="rounded-0"
      color="primary"
      disabled={isSaveDisabled}
      data-test="save-allocation-reset"
      onClick={onSave}
    >
      <span>{BUTTONS.SAVE_AND_CLOSE}</span>
    </Button>
  </div>
);

BulkAllocationResetButtons.propTypes = {
  isSaveDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default BulkAllocationResetButtons;
