import {useLazyQuery} from "@apollo/client";
import {Close, ReactSelectOverrides} from "@bphxd/ds-core-react";
import {UPDATE_BATCH} from "graphql/coprocessing/batches";
import {BATCH_UPDATE_MODAL} from "modules/co-processing/constants/batches";
import {BUTTONS} from "modules/co-processing/constants/shipments";
import PropTypes from "prop-types";
import {useCallback, useEffect, useState} from "react";
import Select from "react-select";
import {toast} from "react-toastify";
import {Button, Input, Label, Modal, ModalBody, ModalHeader} from "reactstrap";
import "./index.scss";

const parseSourceSampleCode = (sourceSampleCode, prefix) => {
  // Remove  curly braces, square brackets & quotes
  const cleanedString = sourceSampleCode.replace(/[{}[\]"]/g, "");
  const sampleIds = cleanedString.split(",").map((code) => code.trim());

  return (
    sampleIds?.map((sampleId) => ({
      value: sampleId,
      label: `${prefix}${sampleId}`,
    })) || []
  );
};

const EditBatchModal = ({
  batchDetails,
  field,
  refetch,
  onClose,
  modalHeader,
  label,
}) => {
  const [value, setValue] = useState(batchDetails?.[field.key] || "");
  const isEditingBatchId = field.key === "batch_id";

  useEffect(() => {
    setValue(batchDetails?.[field.key] || "");
  }, [batchDetails, field.key]);

  const [updateBatch] = useLazyQuery(UPDATE_BATCH, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        toast.success(BATCH_UPDATE_MODAL.UPDATE_SUCCESS);

        if (isEditingBatchId) {
          refetch({batch_id: value?.label});
        } else {
          refetch();
        }
        onClose();
      } else {
        toast.error(
          `${BATCH_UPDATE_MODAL.UPDATE_FAILURE} - ${result?.bioLcCoproUsBatchesApi?.error}`,
        );
      }
    },
  });

  const handleModalClose = useCallback(() => {
    setValue("");
    onClose();
  }, [onClose]);

  const handleUpdateBatchDetail = useCallback(() => {
    if (isEditingBatchId) {
      updateBatch({
        variables: {
          batch_resource_id: field?.batchResourceId,
          updates: [
            {column: "batch_id", value: value.label},
            {column: "source_sample_code", value: `{${value.value}}`},
          ],
        },
      });
    } else {
      updateBatch({
        variables: {
          batch_resource_id: field?.batchResourceId,
          updates: [{column: field.key, value}],
        },
      });
    }
  }, [isEditingBatchId, field, value, updateBatch]);

  const handleChange = useCallback((e) => {
    setValue(e.target?.value);
  }, []);

  const handleSelectChange = useCallback((newValue) => {
    setValue(newValue); // Set the value state with the newValue object
  }, []);

  const getDefaultInput = () => (
    <Input
      style={{resize: "none"}}
      data-test="copro-batch-modal-input"
      name={field?.key}
      id={field?.key}
      type={field?.type}
      value={value}
      onChange={handleChange}
      maxLength={200}
      required
    />
  );

  const renderInput = (field) => {
    if (isEditingBatchId) {
      const tankNumber = batchDetails?.tank_number?.toLowerCase() || "";
      const prefix = tankNumber ? `${tankNumber}.CP` : "";
      const sampleIdOptions = parseSourceSampleCode(
        batchDetails?.source_sample_code,
        prefix,
      );

      return (
        <Select
          className="basic-single-select"
          classNamePrefix="react-select"
          components={ReactSelectOverrides.default}
          defaultValue={sampleIdOptions.find(
            (option) => option.value === value,
          )}
          name={field.key}
          options={sampleIdOptions}
          onChange={handleSelectChange}
          hideSelectedOptions={false}
        />
      );
    }
    return getDefaultInput();
  };

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={field.modalOpen}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={handleModalClose} />}
        >
          {modalHeader}
        </ModalHeader>
        <ModalBody className="p-0">
          <div className="px-4 pt-6">
            <Label className="text-gray-700 text-sm mb-2" for={label}>
              {label}
            </Label>
            {renderInput(field)}
          </div>
          <div className="flex flex-row w-full border border-t mt-32">
            <Button className="w-1/2" color="" onClick={handleModalClose}>
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 disabled:border-0"
              color=""
              disabled={!value}
              onClick={handleUpdateBatchDetail}
            >
              {BUTTONS.SAVE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditBatchModal.propTypes = {
  field: PropTypes.shape({
    key: PropTypes.string,
    batchResourceId: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    modalOpen: PropTypes.bool,
  }).isRequired,
  batchDetails: PropTypes.shape({
    batch_id: PropTypes.string,
    tank_number: PropTypes.string,
    source_sample_code: PropTypes.string,
  }).isRequired,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
  modalHeader: PropTypes.string,
  label: PropTypes.string,
};

export default EditBatchModal;
