import {Nav, NavItem, NavLink} from "reactstrap";
import {useMemo} from "react";
import PropTypes from "prop-types";
import {Left24} from "@bphxd/ds-core-react/lib/icons";
import {NavItemAccordion} from "@bphxd/ds-core-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {sortBy, startCase, has} from "lodash";

import "./index.scss";

const NavigationMenu = ({menuConfig}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const navigate = useNavigate();
  const bioverseMenuItems = useMemo(() => {
    // filter features according to the user's country
    // Extracting features available for the user's current country
    const userFeatures =
      appSetting?.currentCountryMappingData &&
      Object.entries(appSetting?.currentCountryMappingData)
        ?.filter(([key, value]) => {
          // Filtering out features that are enabled (value === true)
          return value === true;
        })
        ?.map((item) => item && item[0]);

    // Filtering menuConfig based on visibility, user's features, and current module
    const availableFeatures = menuConfig?.filter(
      (item) =>
        item.isVisible &&
        userFeatures?.includes(item?.module) &&
        item.module ===
          (appSetting?.currentModule?.toLowerCase() === "global" ||
          appSetting?.currentModule === ""
            ? item.module
            : appSetting?.currentModule),
    );

    // Checking for subItems in the first available feature and assigning them to subItems
    // If no subItems are found, defaults to availableFeatures
    const subItems = has(availableFeatures[0], "subItems")
      ? availableFeatures[0]?.subItems
      : availableFeatures;

    // If the current module is global or empty, all features are displayed
    // If the current module is not global or empty, only the features of the current module are displayed
    const getSubItems =
      appSetting?.currentModule?.toLowerCase() === "global" ||
      appSetting?.currentModule === ""
        ? availableFeatures
        : subItems;

    return getSubItems;
  }, [
    appSetting?.currentCountryMappingData,
    menuConfig,
    appSetting?.currentModule,
  ]);

  const handleSectionNavigation = (link, module, moduleTitle) => {
    setAppSetting({
      ...appSetting,
      currentModule: module,
      currentModuleTitle: moduleTitle,
    });
    navigate(link);
  };

  const sortedMenuItems = sortBy(bioverseMenuItems, "title", "asc");

  const handleNavTitleClick = (currentModule) => {
    if (currentModule === "") {
      const countryFeatureData = appSetting?.featureMappingData?.find(
        (item) => item.countryName === "GLOBAL",
      );

      setAppSetting({
        ...appSetting,
        currentCountry: "GLOBAL",
        currentCountryMappingData: countryFeatureData,
        currentModule: "",
        currentModuleTitle: "",
      });
      navigate("/");
    } else {
      setAppSetting({
        ...appSetting,

        currentModule: "",
        currentModuleTitle: "",
      });
      navigate("/");
    }
  };

  return (
    <div className="d-flex h-100">
      <div className="nav-sidebar d-flex flex-column flex-shrink-0">
        <ul className="list-unstyled mb-auto">
          <li>
            <h5 className="nav-sidebar-header uppercase no-underline cursor-pointer">
              <span
                onClick={() =>
                  handleNavTitleClick(appSetting?.currentModuleTitle)
                }
                onKeyDown={() =>
                  handleNavTitleClick(appSetting?.currentModuleTitle)
                }
              >
                {appSetting?.currentModule === "" ||
                appSetting?.currentCountry === "GLOBAL"
                  ? "bioverse"
                  : appSetting?.currentModuleTitle}
              </span>
            </h5>
            <Nav vertical>
              {sortedMenuItems?.length > 0 &&
                sortedMenuItems?.map((item) => {
                  const location = useLocation();
                  const {pathname} = location;
                  if (!item.isVisible) {
                    return null;
                  }
                  if (item?.subItems) {
                    let level2AccordionOpen = false;
                    const level1AccordionOpen = item?.subItems?.find(
                      (element) => element.link === pathname,
                    );
                    return (
                      <NavItemAccordion
                        key={item.id}
                        title={item.title}
                        data-test={item.id}
                        active={level1AccordionOpen || level2AccordionOpen}
                        open={level1AccordionOpen || level2AccordionOpen}
                      >
                        <Nav vertical>
                          {item?.subItems?.map((child) => {
                            if (!child.isVisible) {
                              return null;
                            }
                            level2AccordionOpen = child?.subItems?.find(
                              (element) => element.link === pathname,
                            );
                            if (child?.subItems) {
                              return (
                                <NavItemAccordion
                                  key={child.id}
                                  title={child.title}
                                  data-test={child.id}
                                  active={level2AccordionOpen}
                                  open={level2AccordionOpen}
                                >
                                  <Nav vertical>
                                    {child?.subItems?.map((subChild, index) => {
                                      if (!subChild.isVisible) {
                                        return null;
                                      }

                                      return (
                                        <NavItem key={subChild.id}>
                                          <NavLink
                                            key={index}
                                            href={subChild.link}
                                            active={subChild.link === pathname}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleSectionNavigation(
                                                subChild.link,
                                                subChild?.module,
                                                subChild?.moduleTitle,
                                              );
                                            }}
                                          >
                                            {subChild.title}
                                          </NavLink>
                                        </NavItem>
                                      );
                                    })}
                                  </Nav>
                                </NavItemAccordion>
                              );
                            }
                            return (
                              <NavItem key={child.id}>
                                <NavLink
                                  href={child.link}
                                  active={child.link === pathname}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleSectionNavigation(
                                      child.link,
                                      child?.module,
                                      child?.moduleTitle,
                                    );
                                  }}
                                >
                                  {child.title}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                        </Nav>
                      </NavItemAccordion>
                    );
                  }

                  return (
                    <NavItem key={item.id}>
                      <NavLink
                        href={item.link}
                        active={item.link === pathname}
                        onClick={(e) => {
                          e.preventDefault();
                          handleSectionNavigation(
                            item.link,
                            item?.module,
                            item?.moduleTitle,
                          );
                        }}
                      >
                        {item.title}
                      </NavLink>
                    </NavItem>
                  );
                })}
            </Nav>
            {appSetting?.currentModule !== "" &&
              appSetting?.currentCountry?.toLowerCase() !== "global" && (
                <>
                  <hr className="border-1 mt-6  mb-5 border-[#ccc]" />
                  <h5 className="side-menu-underline no-underline  cursor-pointer w-full">
                    <span
                      className="flex flex-row items-center w-full justify-start "
                      onClick={() =>
                        handleNavTitleClick(appSetting?.currentModuleTitle)
                      }
                      onKeyDown={() =>
                        handleNavTitleClick(appSetting?.currentModuleTitle)
                      }
                    >
                      <Left24 />
                      <span className="pl-3.5">{`BioVerse ${startCase(
                        appSetting?.currentCountry?.toLowerCase(),
                      )}`}</span>
                    </span>
                  </h5>
                </>
              )}
          </li>
        </ul>
      </div>
    </div>
  );
};

NavigationMenu.propTypes = {
  menuConfig: PropTypes.any,
};

export default NavigationMenu;
