export const calculateVariancePercentage = (
  variance,
  displayValue,
  dataPageValue,
) => {
  if (variance && displayValue) {
    return Math.round((dataPageValue / displayValue - 1) * 100);
  }
  if (!displayValue && variance < 0) {
    return -100;
  }
  return !displayValue && variance > 0 ? 100 : 0;
};

export const calculateUnpublishedVariancePercentage = (
  variance,
  mainValue,
  secondaryValue,
) => {
  if (variance && secondaryValue) {
    return Math.round((mainValue / secondaryValue - 1) * 100);
  }
  return variance && !secondaryValue ? 100 : 0;
};

export default calculateVariancePercentage;
