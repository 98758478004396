import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";

import {
  getFilterValue,
  getStatusColor,
  getStatusValue,
  isNumberAvailable,
} from "modules/GlobalMassBalance/utils";

import moment from "moment";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";

import ActionDropdown from "modules/GlobalMassBalance/components/ActionDropdown";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import "./index.scss";

const mandateCertificateColumnsSAF = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  navigateToAllocation,
  currentFilterValues,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecords?.physicalReceiptDate;
        },
        cell: ({row}) => {
          return (
            row?.original.inboundRecords?.physicalReceiptDate &&
            moment(row?.original.inboundRecords?.physicalReceiptDate).format(
              dateFormat,
            )
          );
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument || "";
        },
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          return (
            <>
              <Link
                to={{
                  pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                    row?.original?.inboundRecords?.certificateInboundEuId,
                  )}`,
                  search: `?siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {row?.original.inboundRecords?.incomingDocument && (
                    <FilePdf24 />
                  )}
                  <ColumnHighlight
                    value={row?.original.inboundRecords?.incomingDocument}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {row?.original.inboundRecords?.incomingDocument ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecords?.rawMaterial;
        },
        cell: ({row}) => {
          console.log("row?.original rawMaterial", row?.original);
          return row?.original.inboundRecords?.rawMaterial;
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecords?.countryOfOrigin;
        },
        cell: ({row}) => {
          return row?.original.inboundRecords?.countryOfOrigin;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return isNumberAvailable(row?.inboundRecords?.ghgTotalMj)
            ? parseAndRoundToThreeDecimal(
                row?.inboundRecords?.ghgTotalMj,
              ).toString()
            : "";
        },
        cell: ({row}) => {
          const value = row?.original.inboundRecords?.ghgTotalMj;
          return value && parseAndRoundToThreeDecimal(value).toString();
        },
      },
      {
        header: "Retired quantity (in) m³",
        accessorKey: "retiredQuantityM3",
        key: "retiredQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return isNumberAvailable(row?.inboundRecords?.retiredQuantityM3)
            ? parseAndRoundToThreeDecimal(
                row?.inboundRecords?.retiredQuantityM3,
              ).toString()
            : "";
        },
        cell: ({row}) => {
          const value = row?.original.inboundRecords?.retiredQuantityM3;
          return value && parseAndRoundToThreeDecimal(value).toString();
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity(out) m³",
        accessorKey: "quantityOutM3",
        key: "quantityOutM3",
        visible: true,
        disabled: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return isNumberAvailable(row?.outboundRecord?.quantityOutM3)
            ? parseAndRoundToThreeDecimal(
                row?.outboundRecord?.quantityOutM3,
              ).toString()
            : "";
        },
        cell: ({row}) => {
          const value = row?.original.outboundRecord?.quantityOutM3;
          return value && parseAndRoundToThreeDecimal(value).toString();
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecord?.outboundType;
        },
        cell: ({row}) => {
          return row?.original.outboundRecord?.outboundType;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecord?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecord?.recipient;
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecord?.document || "";
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to={`/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                row?.original?.inboundRecord?.certificateInboundEuId,
              )}`}
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecord?.document && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecord?.document}
                  columnFilter={getFilterValue(cell, "document")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecord?.status;
        },
        cell: ({row}) => {
          const currentStatus = row?.original?.outboundRecord?.status;

          console.log("row?.original", row?.original);

          const bgColor = getStatusColor(currentStatus);
          return (
            <span
              className={`whitespace-nowrap uppercase status-badge-mass-balance disabled:text-white ${bgColor}`}
            >
              {getStatusValue(currentStatus)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return (
            <ActionDropdown
              row={row}
              table={table}
              navigateToAllocation={navigateToAllocation}
              currentFilterValues={currentFilterValues}
            />
          );
        },
      },
    ],
  },
];

export default mandateCertificateColumnsSAF;
