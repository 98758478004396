export const COUNTRY_CODES = {
  UNITED_STATES: "UNITED STATES",
  GLOBAL: "GLOBAL",
};

export const COUNTRY_NAMES = {
  UNITED_STATES: "United States",
  GLOBAL: "Global",
};

export default COUNTRY_CODES;
