import moment from "moment";

export const getFilteredLegalEntity = (data, watchParams) => {
  /// legal entity options Start
  const {
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchPlant,
    watchEDGroup,
    watchBalGroup,
    watchMaterial,
  } = watchParams;

  const seenLegalEntity = new Set();
  const filteredLegalEntity = data
    ?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === moment(watchYear).format("YYYY") &&
        o.month ===
          (watchMonth === "" || watchMonth === undefined || watchMonth === null
            ? o.month
            : moment(watchMonth).format("M")) &&
        o.plant ===
          (watchPlant === "" || watchPlant === undefined || watchPlant === null
            ? o.plant
            : watchPlant) &&
        o.eDGroup ===
          (watchEDGroup === "" ||
          watchEDGroup === undefined ||
          watchEDGroup === null
            ? o.eDGroup
            : watchEDGroup) &&
        o.balanceGroupNumber ===
          (watchBalGroup === "" ||
          watchBalGroup === undefined ||
          watchBalGroup === null
            ? o.balanceGroupNumber
            : watchBalGroup) &&
        o.material ===
          (watchMaterial === "" ||
          watchMaterial === undefined ||
          watchMaterial === null
            ? o.material
            : watchMaterial),
    )
    .filter((entry) => {
      const key = entry.legalEntity;
      const keep = !seenLegalEntity.has(key);
      if (keep) {
        seenLegalEntity.add(key);
      }
      return keep;
    });
  return filteredLegalEntity;
};

export const getFilteredPlant = (data, watchParams) => {
  /// plant options Start
  const {
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchEDGroup,
    watchBalGroup,
    watchMaterial,
    watchLegalentity,
  } = watchParams;
  const seenPlant = new Set();
  const filteredPlant = data
    ?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === moment(watchYear).format("YYYY") &&
        o.month ===
          (watchMonth === "" || watchMonth === undefined || watchMonth === null
            ? o.month
            : moment(watchMonth).format("M")) &&
        o.eDGroup ===
          (watchEDGroup === "" ||
          watchEDGroup === undefined ||
          watchEDGroup === null
            ? o.eDGroup
            : watchEDGroup) &&
        o.balanceGroupNumber ===
          (watchBalGroup === "" ||
          watchBalGroup === undefined ||
          watchBalGroup === null
            ? o.balanceGroupNumber
            : watchBalGroup) &&
        o.material ===
          (watchMaterial === "" ||
          watchMaterial === undefined ||
          watchMaterial === null
            ? o.material
            : watchMaterial) &&
        o.legalEntity ===
          (watchLegalentity === "" ||
          watchLegalentity === undefined ||
          watchLegalentity === null
            ? o.legalEntity
            : watchLegalentity),
    )
    .filter((entry) => {
      const key = entry.plant;
      const keep = !seenPlant.has(key);
      if (keep) {
        seenPlant.add(key);
      }
      return keep;
    });
  return filteredPlant;
};

export const getFilteredEDGroup = (data, watchParams) => {
  /// ED Group options Start
  const {
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchPlant,
    watchBalGroup,
    watchMaterial,
    watchLegalentity,
  } = watchParams;

  const seenEDGroup = new Set();
  const filteredEDGroup = data
    ?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === moment(watchYear).format("YYYY") &&
        o.month ===
          (watchMonth === "" || watchMonth === undefined || watchMonth === null
            ? o.month
            : moment(watchMonth).format("M")) &&
        o.plant ===
          (watchPlant === "" || watchPlant === undefined || watchPlant === null
            ? o.plant
            : watchPlant) &&
        o.balanceGroupNumber ===
          (watchBalGroup === "" ||
          watchBalGroup === undefined ||
          watchBalGroup === null
            ? o.balanceGroupNumber
            : watchBalGroup) &&
        o.material ===
          (watchMaterial === "" ||
          watchMaterial === undefined ||
          watchMaterial === null
            ? o.material
            : watchMaterial) &&
        o.legalEntity ===
          (watchLegalentity === "" ||
          watchLegalentity === undefined ||
          watchLegalentity === null
            ? o.legalEntity
            : watchLegalentity),
    )
    .filter((entry) => {
      const key = entry.eDGroup;
      const keep = !seenEDGroup.has(key);
      if (keep) {
        seenEDGroup.add(key);
      }
      return keep;
    });
  return filteredEDGroup;
};

export const getFilteredBalanceGroup = (data, watchParams) => {
  /// Bal Group options Start
  const {
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchPlant,
    watchEDGroup,
    watchMaterial,
    watchLegalentity,
  } = watchParams;
  const seenBalGroup = new Set();
  const filteredBalGroup = data
    ?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === moment(watchYear).format("YYYY") &&
        o.month ===
          (watchMonth === "" || watchMonth === undefined || watchMonth === null
            ? o.month
            : moment(watchMonth).format("M")) &&
        o.plant ===
          (watchPlant === "" || watchPlant === undefined || watchPlant === null
            ? o.plant
            : watchPlant) &&
        o.eDGroup ===
          (watchEDGroup === "" ||
          watchEDGroup === undefined ||
          watchEDGroup === null
            ? o.eDGroup
            : watchEDGroup) &&
        o.material ===
          (watchMaterial === "" ||
          watchMaterial === undefined ||
          watchMaterial === null
            ? o.material
            : watchMaterial) &&
        o.legalEntity ===
          (watchLegalentity === "" ||
          watchLegalentity === undefined ||
          watchLegalentity === null
            ? o.legalEntity
            : watchLegalentity),
    )
    .filter((entry) => {
      const key = entry.balanceGroupNumber;
      const keep = !seenBalGroup.has(key);
      if (keep) {
        seenBalGroup.add(key);
      }
      return keep;
    });
  return filteredBalGroup;
};

export const getFilteredMaterial = (data, watchParams) => {
  /// Material options Start
  const {
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchPlant,
    watchEDGroup,
    watchBalGroup,
    watchLegalentity,
  } = watchParams;

  const seenMaterials = new Set();
  const filteredMaterials = data
    ?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === moment(watchYear).format("YYYY") &&
        o.month ===
          (watchMonth === "" || watchMonth === undefined || watchMonth === null
            ? o.month
            : moment(watchMonth).format("M")) &&
        o.plant ===
          (watchPlant === "" || watchPlant === undefined || watchPlant === null
            ? o.plant
            : watchPlant) &&
        o.eDGroup ===
          (watchEDGroup === "" ||
          watchEDGroup === undefined ||
          watchEDGroup === null
            ? o.eDGroup
            : watchEDGroup) &&
        o.balanceGroupNumber ===
          (watchBalGroup === "" ||
          watchBalGroup === undefined ||
          watchBalGroup === null
            ? o.balanceGroupNumber
            : watchBalGroup) &&
        o.legalEntity ===
          (watchLegalentity === "" ||
          watchLegalentity === undefined ||
          watchLegalentity === null
            ? o.legalEntity
            : watchLegalentity),
    )
    .filter((entry) => {
      const key = entry.material;
      const keep = !seenMaterials.has(key);
      if (keep) {
        seenMaterials.add(key);
      }
      return keep;
    });

  return filteredMaterials;
};

export default getFilteredLegalEntity;
