import moment from "moment";
import {ADD_BATCH_FORM} from "modules/co-processing/constants/batches";

const batchFieldKeys = ADD_BATCH_FORM.keys;

const toggleParser = (bool) => (bool ? "Yes" : "No");
const dateParser = (date) => (date ? moment(date).format("L h:mm A") : "");

const parsers = {
  [batchFieldKeys.clearHeel]: toggleParser,
  [batchFieldKeys.splitBatch]: toggleParser,
  [batchFieldKeys.batchEndTime]: dateParser,
  [batchFieldKeys.batchStartTime]: dateParser,
  [batchFieldKeys.tankCertifiedDate]: dateParser,
};

const parseFieldValue = (name, value) => {
  const parser = parsers[name];
  return parser ? parser(value) : value;
};

export default parseFieldValue;
