import moment from "moment";
import {isEmpty} from "lodash";
import {
  measurementLabelsKVPairsForCredits,
  measurementLabelsKVPairsForEastCredits,
  measurementLabelsKVPairsForDeficits,
} from "modules/forecasting/constants/inventory";
import {currentMonth, currentYear} from "modules/forecasting/helpers/dateUtils";
import EmailTableCredits from "./EmailTableCredits";
import EmailTableDeficits from "./EmailTableDeficits";

const checkIfTrueUp = (item, updateType) => {
  const {month, year} = item;

  return (
    year < Number(currentYear) ||
    (year === Number(currentYear) && month < Number(currentMonth)) ||
    (year === Number(currentYear) &&
      month === Number(currentMonth) &&
      updateType === "true-up")
  );
};

const pushIfUnique = (obj, arr) => {
  if (!arr.find((item) => item.month === obj.month && item.year === obj.year)) {
    arr.push(obj);
  }
  return arr;
};

const sortArr = (arr) => {
  return arr.sort((a, b) => a.month - b.month);
};

export const separateByType = (data, region) => {
  const output = {
    creditsTrueUp: [],
    creditsForecast: [],
    deficitsTrueUp: [],
    deficitsForecast: [],
  };

  const trueUpMonths = [];
  const forecastMonths = [];

  const updateType = data.update_type || undefined;

  const creditsKeyPairs =
    region === "ec"
      ? measurementLabelsKVPairsForEastCredits
      : measurementLabelsKVPairsForCredits;

  const creditsKeys = Object.keys(creditsKeyPairs);
  const deficitsKeys = Object.keys(measurementLabelsKVPairsForDeficits);

  data.values.forEach((item) => {
    const isTrueUpItem = checkIfTrueUp(item, updateType);

    if (creditsKeys.includes(item.key)) {
      if (isTrueUpItem) {
        output.creditsTrueUp.push(item);
        pushIfUnique({month: item.month, year: item.year}, trueUpMonths);
      } else {
        output.creditsForecast.push(item);
        pushIfUnique({month: item.month, year: item.year}, forecastMonths);
      }
    }

    if (deficitsKeys.includes(item.key)) {
      if (isTrueUpItem) {
        output.deficitsTrueUp.push(item);
        pushIfUnique({month: item.month, year: item.year}, trueUpMonths);
      } else {
        output.deficitsForecast.push(item);
        pushIfUnique({month: item.month, year: item.year}, forecastMonths);
      }
    }
  });

  return {
    dataSeparated: output,
    trueUpMonths: sortArr(trueUpMonths),
    forecastMonths: sortArr(forecastMonths),
  };
};

const getActiveMonthYear = (month) => {
  if (Number(month) > 12) {
    return {
      month: Number(month) - 12,
      year: Number(currentYear) + 1,
    };
  }
  return {
    month: Number(month),
    year: Number(currentYear),
  };
};

const newYearTransitionChecker = (monthData) => {
  const {month, year} = monthData;

  return (
    currentMonth === "12" && month === 1 && year === Number(currentYear) + 1
  );
};

/**
 * Retrieves the list of available months based on the provided options.
 *
 * @param {Object} options - The properties object.
 * @param {Object[]} options.data - The array of data objects.
 * @param {boolean} options.isTrueUp - The flag to return data specific to True Up.
 * @param {boolean} options.isCredits - The flag indicating whether it is for credits or not.
 * @param {string} options.updateType - The update type (forecast or true-up).
 * @returns {string[]} The list of available months.
 */
export const getAvailableMonths = ({
  data,
  months,
  isTrueUp,
  isCredits,
  updateType,
}) => {
  if (isTrueUp) {
    const monthsList = [];

    months.forEach(({month, year}) => {
      const monthLabel = moment({month: month - 1, year}).format("MMMM 'YY");
      monthsList.push(monthLabel);
    });

    const totalLabel = isCredits ? "Total (RINS)" : "Total (BBLS)";
    monthsList.push(totalLabel);

    return monthsList;
  }

  const transition = newYearTransitionChecker(months[0]);
  const monthToAdd = updateType !== "forecast" || transition ? 1 : 0;

  const itemFound = months.find((item) => {
    const availableDate = getActiveMonthYear(Number(currentMonth) + monthToAdd);

    return (
      item.month === availableDate.month &&
      data.some(
        (obj) =>
          obj.month === availableDate.month && obj.year === availableDate.year,
      )
    );
  });

  return itemFound
    ? [
        moment({month: itemFound.month - 1, year: itemFound.year}).format(
          "MMMM 'YY",
        ),
      ]
    : [];
};

const getForecastValues = ({
  month,
  year,
  obligations,
  isCredits = false,
  keyMapping = {},
}) => {
  const monthItem = obligations.find(
    (item) => item.date.month === month && item.date.year === year,
  );

  const obj = {
    ...monthItem.obligationItems.find(
      (item) => item.type === "publishedForecast",
    ).obligationValues,
  };

  if (isCredits) {
    return Object.keys(keyMapping).map((key) => {
      return obj[key];
    });
  }

  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key]) => !/^d[4-6]_(current|previous)$/i.test(key),
    ),
  );
};

/**
 * Generates the columns and totals data based on the provided credits data and options.
 *
 * @param {Object} options - The properties object.
 * @param {Object[]} options.data - The array of data objects.
 * @param {string} options.region - The region (wc or ec).
 * @param {boolean} options.isTrueUp - The flag indicating whether to use 'diff' (true) or 'value' (false).
 * @param {string} options.updateType - The update type (forecast or true-up).
 * @returns {Object} An object containing the generated columns and totals data.
 */
export const generateCreditsData = ({
  data,
  obligations,
  months,
  region,
  isTrueUp,
  updateType,
}) => {
  const wcKeyMapping = {
    d4_current: 0,
    d4_previous: 1,
    d5_current: 2,
    d5_previous: 3,
    d6_current: 4,
    d6_previous: 5,
  };

  const ecKeyMapping = {
    d4_current: 0,
    d4_previous: 1,
    d6_current: 2,
    d6_previous: 3,
  };

  const keyMapping = region === "wc" ? wcKeyMapping : ecKeyMapping;
  const columnCount = Object.keys(keyMapping).length;

  const groupedData = data.reduce((acc, {key, diff, value, month, year}) => {
    const position = keyMapping[key];

    const amount = isTrueUp ? diff : value;

    if (!acc[month]) {
      acc[month] = isTrueUp
        ? new Array(columnCount).fill("")
        : getForecastValues({
            month,
            year,
            obligations,
            isCredits: true,
            keyMapping,
          });
    }

    if (position !== undefined) {
      acc[month][position] = amount;
    }

    return acc;
  }, {});

  // Add empty columns for months with no data
  months.forEach(({month}) => {
    const filtered = data.filter(({month: m}) => m === month);
    if (!filtered.length) {
      groupedData[month] = new Array(columnCount).fill("");
    }
  });

  const transition = newYearTransitionChecker(months[0]);
  const monthToAdd = updateType !== "forecast" || transition ? 1 : 0;

  const monthSelection = transition
    ? monthToAdd
    : Number(currentMonth) + monthToAdd;

  const columns = isTrueUp
    ? Object.values(groupedData)
    : [groupedData[monthSelection]];

  // Generate vintages column data
  const vintages = new Array(columnCount)
    .fill("Current Year Vintage")
    .map((v, i) => (i % 2 === 0 ? v : "Previous Year Vintage"));

  // calculate sum column data for True Up
  if (isTrueUp) {
    const sumColumn = columns.reduce((acc, curr) => {
      curr.forEach((item, index) => {
        if (typeof item === "number") {
          acc[index] = (acc[index] || 0) + item;
        } else if (acc[index] === undefined) {
          acc[index] = "";
        }
      });
      return acc;
    }, []);

    columns.push(sumColumn); // add sum column data to the end of the array
  }

  columns.unshift(vintages); // add vintages column data to the beginning of the array

  // calculate totals for each row excluding vintages and sum column data for True Up
  const totals = [];
  const sliced = columns.slice(1);
  sliced.forEach((row) => {
    if (row.every((item) => item === "")) {
      totals.push("");
    } else {
      totals.push(row.filter(Number).reduce((acc, item) => acc + item, 0));
    }
  });

  return {columns, totals};
};

/**
 * Generates the list of summed values based on the provided deficits data,
 * using either the 'diff' or 'value' depending on the 'isTrueUp' flag.
 *
 * @param {Object} options - The properties object.
 * @param {Object[]} options.data - The array of data objects.
 * @param {boolean} options.isTrueUp - The flag indicating whether to use 'diff' (true) or 'value' (false).
 * @returns {number[]} The list of summed values, followed by the total sum.
 */
export const generateDeficitsData = ({data, obligations, months, isTrueUp}) => {
  if (isTrueUp) {
    const {monthlySum, totalSum} = data.reduce(
      (acc, {diff, month}) => {
        acc.monthlySum[month] = (acc.monthlySum[month] || 0) + diff;
        acc.totalSum += diff;
        return acc;
      },
      {monthlySum: {}, totalSum: 0},
    );

    // Add empty columns for months with no data
    months.forEach(({month}) => {
      const filtered = data.filter(({month: m}) => m === month);
      if (!filtered.length) {
        monthlySum[month] = "";
      }
    });

    return Object.values(monthlySum).concat(totalSum);
  }

  const {month, year} = months[0];
  const obj = getForecastValues({month, year, obligations});

  data.forEach(({key, value, month: dataMonth}) => {
    if (dataMonth === month) {
      switch (key) {
        case "user_non_obligated_export_gas":
          obj.non_obligated_export_gas = value;
          break;
        case "user_obligated_blended_production_gas":
          obj.obligated_blended_production = value;
          break;
        default:
          if (key in obj) {
            obj[key] = value;
          }
          break;
      }
    }
  });

  const sum = Object.values(obj)
    .filter((value) => typeof value === "number")
    .reduce((acc, value) => acc + value, 0);

  return [sum];
};

/**

Retrieves the pricing window for the current day.
@returns {string} The pricing window in the format "MM/DD/YYYY-MM/DD/YYYY".
*/
export const getPricingWindow = () => {
  const tomorrow = moment().add(1, "day");
  const startDateString = tomorrow.format("MM/DD/YYYY");
  const endDateString = tomorrow.endOf("month").format("MM/DD/YYYY");

  return `${startDateString}-${endDateString}`;
};

export const getPageCount = ({data, updateType}) => {
  const {creditsForecast, creditsTrueUp, deficitsForecast, deficitsTrueUp} =
    data;
  const monthToAdd = updateType !== "forecast" ? 1 : 0;
  const foundCreditsMonth = creditsForecast?.some(
    (item) => item.month === Number(currentMonth) + monthToAdd,
  );

  const foundDeficitsMonth = deficitsForecast?.some(
    (item) => item.month === Number(currentMonth) + monthToAdd,
  );

  if (
    (creditsTrueUp.length || deficitsTrueUp.length) &&
    (foundCreditsMonth || foundDeficitsMonth)
  ) {
    return 2;
  }

  return 1;
};

export const getPricingLabel = ({availableMonths, isTrueUp, updateType}) => {
  const pricingWindow = getPricingWindow();

  const isCurrentMonthForecast =
    !isTrueUp &&
    updateType === "forecast" &&
    availableMonths[0] ===
      moment({month: Number(currentMonth) - 1, year: currentYear}).format(
        "MMMM 'YY",
      );

  if (isTrueUp || isCurrentMonthForecast) {
    return pricingWindow;
  }
  return `${availableMonths[0].split(" ")[0]} Month Average`;
};

export const creditsTable = ({
  data,
  obligations,
  months,
  isTrueUp,
  region,
  updateType,
}) => {
  const availableMonths = getAvailableMonths({
    data,
    months,
    isTrueUp,
    isCredits: true,
    updateType,
  });

  if (!availableMonths.length) {
    return null;
  }

  const {columns, totals} = generateCreditsData({
    data,
    obligations,
    months,
    region,
    isTrueUp,
    updateType,
  });

  const pricingLabel = getPricingLabel({
    availableMonths,
    isTrueUp,
    updateType,
  });

  return (
    <EmailTableCredits
      availableMonths={availableMonths}
      columns={columns}
      totals={totals}
      region={region}
      pricingLabel={pricingLabel}
      isTrueUp={isTrueUp}
    />
  );
};

export const deficitsTable = ({
  data,
  obligations,
  months,
  isTrueUp,
  region,
  updateType,
}) => {
  const availableMonths = getAvailableMonths({
    data,
    months,
    isTrueUp,
    isCredit: false,
    updateType,
  });

  if (!availableMonths.length) {
    return null;
  }

  const columns = generateDeficitsData({
    data,
    obligations,
    months,
    isTrueUp,
  });

  const pricingLabel = getPricingLabel({
    availableMonths,
    isTrueUp,
    updateType,
  });

  return (
    <EmailTableDeficits
      availableMonths={availableMonths}
      columns={columns}
      region={region}
      pricingLabel={pricingLabel}
      isTrueUp={isTrueUp}
    />
  );
};

export const isSpecificForecastBeyondNextMonth = ({forecast, updateType}) => {
  const monthOffset = updateType !== "forecast" ? 1 : 0;
  const targetDate = moment().add(monthOffset + 1, "months");
  const month = targetDate.month();
  const year = targetDate.year();

  return forecast.every((obj) => {
    return obj.year > year || (obj.year === year && obj.month > month);
  });
};

/**
 * Checks if all forecasts are beyond the next month, considering the update type.
 *
 * @param {Object} params.dataByType - The data object by type (credits and deficits)
 * @param {Array} params.dataByType.creditsTrueUp - Array of credit true-ups.
 * @param {Array} params.dataByType.creditsForecast - Array of credit forecasts.
 * @param {Array} params.dataByType.deficitsTrueUp - Array of deficit true-ups.
 * @param {Array} params.dataByType.deficitsForecast - Array of deficit forecasts.
 * @param {string} params.updateType - The type of the update ("forecast" or "true-up").
 *
 * @returns {boolean} True if all forecasts are beyond the next month, false otherwise.
 *
 * @example
 * const dataByType = {
 *   creditsTrueUp: [],
 *   creditsForecast: [{year: 2023, month: 8, ...}],
 *   deficitsTrueUp: [],
 *   deficitsForecast: [{year: 2023, month: 9, ...}]
 * };
 * isAllForecastBeyondNextMonth({dataByType, updateType: "forecast"}); // returns true if current month is earlier than August 2023.
 */
export const isAllForecastBeyondNextMonth = ({dataByType, updateType}) => {
  const {creditsTrueUp, creditsForecast, deficitsTrueUp, deficitsForecast} =
    dataByType;

  if (
    isEmpty(creditsTrueUp) &&
    isEmpty(deficitsTrueUp) &&
    (!isEmpty(creditsForecast) || !isEmpty(deficitsForecast))
  ) {
    return isSpecificForecastBeyondNextMonth({
      forecast: [...deficitsForecast, ...creditsForecast],
      updateType,
    });
  }

  return false;
};
