import {isEmpty} from "lodash";
import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {Button, FormGroup, Input, Label} from "reactstrap";

const MandateFilter = ({
  filterDynamicData,
  currentFilterValues,
  handleFilterSubmit,
  allFiltersDisabled,
}) => {
  const [locationGroup, setLocationGroup] = useState();
  const [periodValue, setPeriodValue] = useState();
  const [outboundType, setOutboundType] = useState();

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0) {
      setLocationGroup(currentFilterValues?.mbLocationGroupId);
      setPeriodValue(currentFilterValues?.mbPeriodId);
      setOutboundType(currentFilterValues?.outboundType);
    }
  }, [
    filterDynamicDataResult,
    currentFilterValues?.mbLocationGroupId,
    currentFilterValues?.mbPeriodId,
    currentFilterValues?.outboundType,
  ]);

  const locationGroupList = useMemo(() => {
    if (!isEmpty(filterDynamicDataResult)) {
      return filterDynamicDataResult?.mbLocationGroups;
    }
    return [];
  }, [filterDynamicDataResult]);

  const periodList = useMemo(() => {
    if (!isEmpty(filterDynamicDataResult)) {
      return filterDynamicDataResult?.mbPeriods;
    }
    return [];
  }, [filterDynamicDataResult]);

  const outboundTypeList = useMemo(() => {
    if (!isEmpty(filterDynamicDataResult)) {
      return filterDynamicDataResult?.outboundTypes;
    }
    return [];
  }, [filterDynamicDataResult]);

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="mbLocationGroupId">Location</Label>
          <Input
            type="select"
            id="mbLocationGroupId"
            data-test="mbLocationGroupId"
            value={currentFilterValues?.mbLocationGroupId}
            onChange={(e) => {
              setLocationGroup(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-60 ${
              locationGroupList?.length === 1 || allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={locationGroupList?.length === 1 || allFiltersDisabled}
          >
            {locationGroupList?.length > 0 &&
              locationGroupList?.map((location) => (
                <option
                  key={location?.mbLocationGroupId}
                  value={location?.mbLocationGroupId}
                >
                  {location?.mbLocationGroupName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="mbPeriodId">Period</Label>
          <Input
            type="select"
            id="mbPeriodId"
            data-test="mbPeriodId"
            value={currentFilterValues?.mbPeriodId}
            onChange={(e) => {
              setPeriodValue(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-60 ${
              periodList?.length === 1 || allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={periodList?.length === 1 || allFiltersDisabled}
          >
            {periodList?.length > 0 &&
              periodList?.map((period) => (
                <option key={period?.mbPeriodId} value={period?.mbPeriodId}>
                  {period?.mbPeriodName}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="outboundType">Outbound type</Label>
          <Input
            type="select"
            id="outboundType"
            data-test="outboundType"
            value={currentFilterValues?.outboundType}
            onChange={(e) => {
              setOutboundType(e.target.value);
            }}
            maxLength={100}
            placeholder="Please choose from list"
            className={`!w-32 ${
              outboundTypeList?.length === 1 || allFiltersDisabled
                ? "bg-[#fafafa] !text-[#11111180]"
                : "bg-white"
            }`}
            disabled={outboundTypeList?.length === 1 || allFiltersDisabled}
          >
            {outboundTypeList?.length > 0 &&
              outboundTypeList?.map((outboundType) => (
                <option key={outboundType} value={outboundType}>
                  {outboundType}
                </option>
              ))}
          </Input>
        </FormGroup>
        <FormGroup className="flex flex-col-reverse">
          <Button
            data-test="filter-submit-button"
            color="standard-tertiary rounded-0"
            type="button"
            form="mb-filter-form"
            onClick={() =>
              handleFilterSubmit(locationGroup, periodValue, outboundType)
            }
            disabled={allFiltersDisabled}
          >
            Apply
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

MandateFilter.propTypes = {
  filterDynamicData: PropTypes.object.isRequired,
  currentFilterValues: PropTypes.object,
  handleFilterSubmit: PropTypes.func,
  allFiltersDisabled: PropTypes.bool,
};

export default MandateFilter;
