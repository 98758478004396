import {useNavigate, useLocation, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useQuery} from "@apollo/client";
import {Button, Spinner} from "reactstrap";
import moment from "moment";
import axios from "axios";
import {useUserSettings} from "providers/userSettings/context.js";
import {useMemo, useState, useEffect} from "react";
import {AssignToSaleAPI} from "graphql/es-co-processing/mass-balance/assignToSale";
import dateFunctions from "utils/helpers/dateFunctions";
import {useAccount, useMsal} from "@azure/msal-react";
import {LOCATION_MASTER} from "graphql/config-center/LocationMaster.js";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {
  getCertificateDetailsAPI,
  GET_EU_COUNTRIES,
} from "graphql/es-co-processing/mass-balance/generateCertificate";
import Form from "modules/common/FormNew";
import Layout from "../Layout";
import {
  SchemaGenerateCertificatePOS,
  SchemaGenerateCertificateBPMarketing,
} from "./schemaGenerateCertificate";

import "./generateCertificate.css";
import GenerateCerificatePagePOS from "./ISCCEU/GenerateCerificatePagePOS";
import GenerateCertificatePageBPMarketing from "./ISCCPlus/GenerateCertificatePageBPMarketing";
import ConfirmationPopup from "./ConfirmationPopup";
import GenerateCertificatePageSD from "./ISCCEU/GenerateCertificatePageSD";
import GenerateCertificatePageISCCplusV351 from "./ISCCPlus/GenerateCertificatePageISCCplusV351";

const GenerateCertificateDetails = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);

  const navigate = useNavigate();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const [data, setData] = useState(null);

  const location = useLocation();

  const docDetails = location.state;

  const [isSuccessModalVisible, setSuccessModalVisible] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const {data: locationDetails} = useQuery(LOCATION_MASTER);

  const recipientNameParam =
    docDetails?.recipientReceiptName === "Same as recipient address"
      ? docDetails?.recipientName
      : docDetails?.recipientReceiptName;

  const recipientRecord =
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.find(
      (objloc) => objloc.location_code === recipientNameParam,
    );

  const recipientLegalEntityRecord =
    locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.find(
      (objloc) => objloc.location_code === docDetails?.recipientName,
    );

  useEffect(() => {
    async function fetchCertAPI() {
      const {data: dataCert} = await getCertificateDetailsAPI({
        locationCode: docDetails?.rowData?.inboundRecord?.mbLocationCode,
        certificationSystem: docDetails?.certificationSystem,
        templateType: docDetails?.templateType,
        vesselName: docDetails?.vesselName,
        dispatchDate: dateFunctions.formatDatePickerDate(
          docDetails?.dispatchDate,
        ),
        inboundEuQtyId: docDetails?.rowData?.inboundRecord?.inboundEuQtyId,
        motRecipient: docDetails?.motRecipient,
        sdNumber: docDetails?.rowData?.inboundRecord?.sdNumber,
        issueDate: dateFunctions.formatDatePickerDate(docDetails?.issueDate),
      });
      if (
        dataCert?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.statusCode ===
        500
      ) {
        toast.error(
          dataCert?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.message,
        );
      }
      setData(dataCert);
    }

    fetchCertAPI();
  }, [docDetails]);

  const {data: countrylist} = useQuery(GET_EU_COUNTRIES);

  const breadcrumbItems = useMemo(
    () => [
      {text: "BioVerse", link: "/"},
      {text: "Co-processing", link: "/copro-spain/spain"},
      {
        text: docDetails?.rowData?.inboundRecord?.mbLocation,
        link: `/copro-spain/spain/mass-balance/${encodeURIComponent(
          docDetails?.rowData?.inboundRecord?.mbLocationCode,
        )}/${encodeURIComponent(
          docDetails?.rowData?.inboundRecord?.mbLocation,
        )}/${encodeURIComponent(
          docDetails?.rowData?.inboundRecord?.materialCode,
        )}`,
      },
      {
        text: data?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.data
          ?.generatedCertificateId,
      },
    ],
    [
      data?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.data
        ?.generatedCertificateId,
      docDetails?.rowData?.inboundRecord?.mbLocation,
      docDetails?.rowData?.inboundRecord?.mbLocationCode,
      docDetails?.rowData?.inboundRecord?.materialCode,
    ],
  );

  const callPDFFetchAPI = async (apiLink) => {
    // fetch api in each 10 s until we get a success and terminate it when it reaches
    let fetchCount = 0;

    const timer = setInterval(async () => {
      fetchCount += 1;
      try {
        const uploadBinaryResponse = await axios.get(apiLink);
        if (uploadBinaryResponse?.status === 200) {
          setProcessing(false);
          setSuccessModalVisible(true);
          clearInterval(timer);
        }
      } catch (err) {
        // nothing
      }
      if (fetchCount > 7) {
        setErrorModalVisible(true);
        setProcessing(false);
        clearInterval(timer);
      }
    }, 10000);
  };

  return (
    <div className="flex flex-col  bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 min-h-screen ">
          <Layout
            title="Please review and complete"
            breadcrumbs={breadcrumbItems}
            subtitle={
              <div className="flex flex-col justify-between items-start gap-2 small pb-6 px-7">
                <span className="mb-2">
                  To finalise this sales assignment please review the following
                  details answering any outstanding questions. An example
                  declaration is shown on the right for your reference.
                </span>

                {docDetails?.templateType === "BP Marketing" &&
                  docDetails?.certificationSystem === "ISCC Plus" && (
                    <span className="mb-2">
                      Please review the declaration statement with care. The
                      example content in [brackets] should be replaced by you
                      with the information presented on this page.
                    </span>
                  )}

                <span className="mb-2">
                  Once you have completed the review, you can generate the
                  certificate. This will take approximately 20 seconds
                </span>

                <span>Once complete you can return to the mass balance.</span>
              </div>
            }
          >
            <div className="flex flex-col p-2 min-h-[800px] px-7 pt-6">
              <Form
                className="flex flex-col gap-5 generate-certificate-form-details"
                id="generate-certificate-form"
                schema={
                  docDetails?.templateType === "BP Marketing" &&
                  docDetails?.certificationSystem === "ISCC Plus"
                    ? SchemaGenerateCertificateBPMarketing
                    : SchemaGenerateCertificatePOS
                }
                onSubmit={async (values) => {
                  setProcessing(true);
                  const params = {
                    siteReferenceId: siteReferenceData?.siteReferenceId,
                    divisionId: divisionData?.divisionId,
                    additionalInfo: values?.additionalInfo,
                    agriculturalBiomassIntermediateCropFlag:
                      values?.agriculturalBiomassIntermediateCropFlag,
                    agriculturalBiomassLowRiskFlag:
                      values?.agriculturalBiomassLowRiskFlag,
                    certificationSystem: values?.certificationSystem,
                    chainOfCustody: values?.chainOfCustody,
                    contractNumber: values?.contractNumber,
                    createdBy: account.username,
                    dispatchDate: dateFunctions.formatDatePickerDate(
                      values?.dispatchDate,
                    ),
                    euRedCompliantFlag: values?.euRedCompliantFlag,
                    inboundEuQtyId:
                      docDetails?.rowData?.inboundRecord?.inboundEuQtyId,
                    isccCompliantFlag: values?.isccCompliantFlag,
                    issueDate: dateFunctions.formatDatePickerDate(
                      values?.issueDate,
                    ),
                    locationCode:
                      docDetails?.rowData?.inboundRecord?.mbLocationCode,
                    motRecipient: docDetails?.motRecipient,
                    productType: values?.productType,
                    quantity: values?.feedstockQty,
                    rawMaterialCompliantFlag:
                      values?.materialSustainabilityCriteriaFlag,
                    rawMaterialWasteOrResidueFlag:
                      values?.rawMaterialWasteOrResidueFlag,
                    recipientAddress: values?.recipientAddress,
                    recipientName: recipientRecord?.location_name,
                    recipientReceiptAddress:
                      values?.receiptReceivingPointAddress,
                    sdNumber: docDetails?.rowData?.inboundRecord?.sdNumber,
                    templateType: docDetails?.templateType,
                    uom: docDetails?.uom,
                    vesselName: docDetails?.vesselName,
                    dateOfInstallation: values?.dateOfInstallation
                      ? moment(values?.dateOfInstallation).format(dateFormat)
                      : "",
                    dateOfInstallation2: values?.dateOfInstallation2
                      ? moment(values?.dateOfInstallation2).format(dateFormat)
                      : "",
                    declarationStatement: values?.declarationStatement,
                    bioProductSpec: values?.bioProductSpec,
                    rawMaterialCatSpec: values?.rawMaterialCatSpec,
                    general: values?.general,
                    issueDateFormatted: moment(values?.issueDate).format(
                      dateFormat,
                    ),
                    dispatchDateFormatted: moment(values?.dispatchDate).format(
                      dateFormat,
                    ),
                    additionalInfoNUTS2region:
                      values?.additionalInfoNUTS2region,
                    allocatedHeat: values?.ghgAllocatedHeat,
                    allocatedElectricity: values?.ghgAllocatedElectricity,
                    conventionalDefaultValue: docDetails?.fossilFuelComparator,
                    userOfBiofuel: values?.IsUserofBioLiquidFuel,
                    producerOfBiofuel: values?.IsProducerOfBiofuelBiomassFuel,
                    recipientLegalEntity:
                      recipientLegalEntityRecord?.legal_entity,
                    totalDefaultValue: values?.totalDefaultValueRedApplied,
                  };

                  const paramsBPMarketing = {
                    ...params,
                    rawMaterialCategory: values?.rawMaterialType,
                    bioFeedstock: values?.bioFeedstock,
                  };

                  const paramsPOS = {
                    ...params,
                    rawMaterialType: values?.rawMaterialType,
                  };

                  const paramsPOSSD = {
                    ...params,
                    rawMaterialType: values?.rawMaterialType,
                    transportationDistanceShip: values?.shipKm,
                    transportationDistanceTrain: values?.trainKm,
                    transportationDistanceRoad: values?.roadKm,
                    disaggregatedDefaultValueOilExtraction:
                      values?.IsDisaggregatedDefaultValueForOilApplied,
                    disaggregatedDefaultValueSoilEmissions:
                      values?.IsDisaggregatedDefaultValueForSoilApplied,
                    escaBonus: values?.animalManureUsedFlag,
                    elBonus:
                      values?.biomassObtainedFromRestoredDegradedLandFlag,
                    biogasIncentives:
                      values?.incentivesReceivedforBiogasProductionFlag,
                    biogasIncentivesDetails: values?.biogasIncentivesDetails,
                  };

                  const paramsISCCPlus351 = {
                    ...params,
                    quantity: values?.certifiedMaterialQty,
                    rawMaterialCompliantFlag:
                      values?.rawMaterialSustainabilityCriteriaFlag,
                    rawMaterialWasteOrResidueFlag:
                      values?.rawMaterialMeetsDefCriteriaFlag,
                    rawMaterialType: values?.rawMaterialType,
                    isccCompliantFlag: values?.isccCompliantFlag,

                    circularRawMaterial: values?.feedstockType === "Circular",
                    circularRawMaterialQuantity:
                      values?.feedstockType === "Circular"
                        ? values?.feedstockQtyInput
                        : "",
                    bioCircularRawMaterial:
                      values?.feedstockType === "Bio-circular",
                    bioCircularRawMaterialQuantity:
                      values?.feedstockType === "Bio-circular"
                        ? values?.feedstockQtyInput
                        : "",
                    bioRawMaterial: values?.feedstockType === "Bio",
                    bioRawMaterialQuantity:
                      values?.feedstockType === "Bio"
                        ? values?.feedstockQtyInput
                        : "",
                    renewableRawMaterial:
                      values?.feedstockType === "Renewable-energy-derived",
                    renewableRawMaterialQuantity:
                      values?.feedstockType === "Renewable-energy-derived"
                        ? values?.feedstockQtyInput
                        : "",

                    deliveryQuantity: values?.deliveryQty,
                    recyclingOperationsType: values?.operationType,
                    wasteStatus: values?.wasteStatus,

                    multiSiteCreditTransfer:
                      values?.multiSiteCreditTransferFlag,
                    isccPlusEmissionsRequirementsFlag:
                      values?.isscGHGEmissionsRequirementFlag,

                    transportationDistanceShip: values?.shipKm,
                    transportationDistanceTrain: values?.trainKm,
                    transportationDistanceRoad: values?.roadKm,

                    isccPlusConsumablesFlag: values?.isccPlusConsumablesFlag,
                    isccPlusNonGMOFeedFlag: values?.isccPlusNonGMOFoodFlag,
                    isccPlusNonGMOTechnicalMarketsFlag:
                      values?.isccPlusNonGMOTechnicalFlag,
                    isccPlusElectricityAndHeatFlag:
                      values?.isccPlusElectricityHeatFlag,
                    isccPlusFSSAddOnFlag: values?.IsccPlusAddonFoodSecurityFlag,

                    cornUseJapaneseDefaultValues:
                      values?.isJapaneseDefaultValuesforBrazilianEthanolUS,
                    cornEmissionsLandUseChargeZero:
                      values?.isEmissionsfromLandUseZeroUS,
                    sugarCaneUseJapaneseDefaultValues:
                      values?.isJapaneseDefaultValuesforBrazilianEthanolBrazil,
                    sugarCaneEmissionsLandUseChargeZero:
                      values?.isEmissionsfromLandUseZeroBrazil,
                  };

                  const getParamValues = (templateType) => {
                    switch (templateType) {
                      case "ISCC PoS 2.6":
                        return paramsPOS;
                      case "ISCC SD 2.6":
                        return paramsPOSSD;
                      case "BP Marketing":
                        return paramsBPMarketing;
                      case "ISCC Plus 3.5.1":
                        return paramsISCCPlus351;
                      default:
                        return params;
                    }
                  };

                  const {data: response} = await AssignToSaleAPI({
                    event: getParamValues(docDetails?.templateType),
                  });
                  if (
                    response?.bioLcCoproEuMassBalanceAssign?.statusCode === 200
                  ) {
                    if (
                      response?.bioLcCoproEuMassBalanceAssign
                        ?.certificatePdfLink
                    ) {
                      await callPDFFetchAPI(
                        response?.bioLcCoproEuMassBalanceAssign
                          ?.certificatePdfLink,
                      );
                    } else {
                      setErrorModalVisible(true);
                      setProcessing(false);
                    }
                  } else {
                    setErrorModalVisible(true);
                    setProcessing(false);
                  }
                  return response;
                }}
              >
                {docDetails?.templateType === "ISCC PoS 2.6" &&
                  docDetails?.certificationSystem === "ISCC EU" && (
                    <GenerateCerificatePagePOS
                      data={{
                        ...docDetails,
                        ...data?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                          ?.data,
                        locationDetails:
                          locationDetails
                            ?.bioLcCoproConfigCenterLocationMasterDetails
                            ?.Records,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}

                {docDetails?.templateType === "ISCC SD 2.6" &&
                  docDetails?.certificationSystem === "ISCC EU" && (
                    <GenerateCertificatePageSD
                      data={{
                        ...docDetails,
                        ...data?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                          ?.data,
                        locationDetails:
                          locationDetails
                            ?.bioLcCoproConfigCenterLocationMasterDetails
                            ?.Records,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}

                {docDetails?.templateType === "BP Marketing" &&
                  docDetails?.certificationSystem === "ISCC Plus" && (
                    <GenerateCertificatePageBPMarketing
                      data={{
                        ...docDetails,
                        ...data?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                          ?.data,
                        locationDetails:
                          locationDetails
                            ?.bioLcCoproConfigCenterLocationMasterDetails
                            ?.Records,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}
                {docDetails?.templateType === "ISCC Plus 3.5.1" &&
                  docDetails?.certificationSystem === "ISCC Plus" && (
                    <GenerateCertificatePageISCCplusV351
                      data={{
                        ...docDetails,
                        ...data?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                          ?.data,
                        locationDetails:
                          locationDetails
                            ?.bioLcCoproConfigCenterLocationMasterDetails
                            ?.Records,
                        countries: countrylist?.bioLcCoproEuGetCountries,
                      }}
                    />
                  )}
              </Form>
              <div className="flex flex-row gap-4 my-6 justify-end">
                <Button
                  className="show rounded-0"
                  color="tertiary"
                  outline
                  onClick={() =>
                    navigate(
                      `/copro-spain/spain/mass-balance/${encodeURIComponent(
                        docDetails?.rowData?.inboundRecord?.mbLocationCode,
                      )}/${encodeURIComponent(
                        docDetails?.rowData?.inboundRecord?.mbLocation,
                      )}/${encodeURIComponent(
                        docDetails?.rowData?.inboundRecord?.materialCode,
                      )}`,
                    )
                  }
                >
                  Cancel
                </Button>
                <Button
                  className="primary-btn rounded-0"
                  color="standard-primary"
                  form="generate-certificate-form"
                  type="submit"
                  disabled={isProcessing}
                >
                  {isProcessing && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  Generate Certificate
                </Button>
              </div>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {data?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.data
            ?.templatePdfUrl != null &&
          data?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.data?.templatePdfUrl.includes(
            "file-not-found",
          ) ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={
                data?.bioLcCoproEuMassBalanceGetAutoPopulatedData?.data
                  ?.templatePdfUrl
              }
            ></iframe>
          )}
        </div>
        <ConfirmationPopup
          isOpen={isSuccessModalVisible}
          title="Congratulations"
          confirmLabel="Return to mass balance"
          message={
            <span>
              <p>Your certificate has been created.</p>
            </span>
          }
          onConfirm={async () => {
            navigate(
              `/copro-spain/spain/mass-balance/${encodeURIComponent(
                docDetails?.rowData?.inboundRecord?.mbLocationCode,
              )}/${encodeURIComponent(
                docDetails?.rowData?.inboundRecord?.mbLocation,
              )}/${encodeURIComponent(
                docDetails?.rowData?.inboundRecord?.materialCode,
              )}`,
            );
          }}
        />
        <ConfirmationPopup
          isOpen={isErrorModalVisible}
          title="Error"
          confirmLabel="Return to certificate"
          message={
            <span>
              <p>
                Sorry, we were unable to generate the certificate. You can try
                again by closing this window or cancelling for the time being
              </p>
            </span>
          }
          onConfirm={async () => {
            setErrorModalVisible(false);
          }}
        />
      </div>
    </div>
  );
};

export default GenerateCertificateDetails;
