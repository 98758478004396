import {
  getKeyNames,
  getUniqueLists,
  getUniqueListWithoutParent,
} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {Input} from "reactstrap";

const FilterSelectField = ({
  column,
  columnFilterValue,
  data,
  isParentKey,
  setRowSelection,
}) => {
  const [dropDownData, setDropDownData] = useState([]);
  useEffect(() => {
    if (column) {
      const {parentKeyName, keyName} = getKeyNames(column);
      const dropData = isParentKey
        ? getUniqueLists(data, `${parentKeyName}.${keyName}`)
        : getUniqueListWithoutParent(data, keyName);
      setDropDownData(isParentKey ? dropData?.[keyName] : dropData);
    }
  }, [column, data, isParentKey]);

  return (
    <Input
      className="bg-white"
      type="select"
      onChange={(e) => {
        column.setFilterValue(e.target.value);
        if (setRowSelection) {
          setRowSelection({});
        }
      }}
      value={columnFilterValue || ""}
    >
      <option value="">All</option>
      {dropDownData?.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </Input>
  );
};

FilterSelectField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
  data: PropTypes.array,
  isParentKey: PropTypes.bool,
  setRowSelection: PropTypes.func,
};

export default FilterSelectField;
