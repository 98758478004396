import {Spinner as RSpinner} from "reactstrap";

const Spinner = () => {
  return (
    <div>
      <RSpinner
        style={{width: ".25rem", height: ".25rem", marginLeft: ".25rem"}}
        type="grow"
      />{" "}
      <RSpinner style={{width: ".25rem", height: ".25rem"}} type="grow" />{" "}
      <RSpinner style={{width: ".25rem", height: ".25rem"}} type="grow" />
    </div>
  );
};

export default Spinner;
