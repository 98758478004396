import PropTypes from "prop-types";
import {useMemo} from "react";

const NetChangeChip = ({prevValue, currentValue}) => {
  if (
    prevValue == null ||
    currentValue == null ||
    Number.isNaN(prevValue) ||
    Number.isNaN(currentValue)
  ) {
    return null;
  }

  const changeValue = useMemo(
    () => parseFloat(currentValue) - parseFloat(prevValue),
    [currentValue, prevValue],
  );

  const formatValue = (value) => {
    return Number.isInteger(value) ? value : value.toFixed(2);
  };

  return (
    <div>
      {changeValue !== 0 && (
        <div data-test="copro-net-change-chip" className="flex items-center">
          <div className="copro-net-change-chip text-[10px]">
            {changeValue > 0
              ? `+${formatValue(changeValue)}`
              : formatValue(changeValue)}
          </div>
        </div>
      )}
    </div>
  );
};

NetChangeChip.propTypes = {
  prevValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default NetChangeChip;
