import {useState, useMemo, createContext, useContext, useCallback} from "react";
import PropTypes from "prop-types";
import {TABS} from "modules/co-processing/constants/batches";

export const BatchTypeContext = createContext({});

export const BatchTypeProvider = ({children}) => {
  const [batchType, setBatchType] = useState(TABS.ACTIVE.toLowerCase());

  const typeHandler = useCallback((batchType = []) => {
    setBatchType(batchType);
  }, []);

  const batchTypeContext = useMemo(
    () => ({
      typeHandler,
      batchType,
      setBatchType,
    }),
    [batchType, typeHandler, setBatchType],
  );

  return (
    <BatchTypeContext.Provider value={batchTypeContext}>
      {children}
    </BatchTypeContext.Provider>
  );
};

BatchTypeProvider.propTypes = {
  children: PropTypes.node,
};

export const useBatchType = () => {
  const context = useContext(BatchTypeContext);

  if (context === undefined) {
    throw new Error("useBatchType must be used inside BatchTypeProvider");
  }

  return context;
};
