import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {Progress} from "reactstrap";
import {roundDecimal} from "utils/helpers/uihelper";
import ChecklistPopover from "./ChecklistPopover";
import {batchStatusPropTypes} from "./checkListStatusTypes";
import "./index.scss";

function generateUniqueId(prefix = "id") {
  const uniqueString = Math.random().toString(36).substring(2, 9); // String starts with alphanumeric characters and avoids special characters
  const uniqueId = `${prefix}-${uniqueString}`;
  const validId = uniqueId.replace(/[^a-zA-Z0-9-_.]/g, "");

  return validId;
}

const BatchChecklistProgress = ({statusDetails}) => {
  const {completion_percentage, ...statuses} = statusDetails;

  const completionPercent = useMemo(
    () => roundDecimal(completion_percentage),
    [completion_percentage],
  );

  return (
    <ChecklistPopover
      popoverId={generateUniqueId("batch-popover")}
      statuses={statuses}
    >
      <div className="progress-indicator" data-test="batch-checklist-progress">
        <span className="text-[10px]">{completionPercent}% COMPLETE</span>
        <Progress
          className="rounded-pill"
          color="success"
          style={{height: "5px"}}
          value={completionPercent}
        />
      </div>
    </ChecklistPopover>
  );
};

BatchChecklistProgress.propTypes = {
  statusDetails: PropTypes.shape(batchStatusPropTypes).isRequired,
};

export default BatchChecklistProgress;
