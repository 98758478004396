import React from "react";

import {useQuery} from "@apollo/client";
import {PRODUCT_BASELINE_EMISSION_DETAIL} from "graphql/GlobalConfigCenter/ProductBaselineEmission";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {PRODUCT_BASELINE_EMISSION_DETAIL_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {PRODUCT_BASELINE_EMISSION_DETAIL_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const ProductBaselineEmissionConfigPage = () => {
  const {data, loading} = useQuery(PRODUCT_BASELINE_EMISSION_DETAIL);

  return (
    <Layout
      title={PRODUCT_BASELINE_EMISSION_DETAIL_CONFIG_PAGE.title}
      description={PRODUCT_BASELINE_EMISSION_DETAIL_CONFIG_PAGE.description}
      tableColumns={PRODUCT_BASELINE_EMISSION_DETAIL_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetProductBaselineEmissionDetailsNew
              .productBaselineEmissionDetailsResponse
      }
    />
  );
};

export default ProductBaselineEmissionConfigPage;
