import {useState, useMemo, createContext, useContext, useCallback} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {DATE_FORMAT_YYYYMMDD_HYPHEN} from "constants/dateformats";

export const DateRangeContext = createContext({});

const PRIOR_DAYS = 45;
const TODAY = moment().format(DATE_FORMAT_YYYYMMDD_HYPHEN);
export const INITIAL_RANGE = {
  from: moment()
    .subtract(PRIOR_DAYS, "days")
    .format(DATE_FORMAT_YYYYMMDD_HYPHEN),
  to: TODAY,
};

const formatDate = (date) => moment(date).format(DATE_FORMAT_YYYYMMDD_HYPHEN);
const strIsAValidDate = (date = "") =>
  moment(date, DATE_FORMAT_YYYYMMDD_HYPHEN).isValid();

let didUserSetDate = false;

export const ContractDateRangeProvider = ({children}) => {
  const [dateRange, setRange] = useState(INITIAL_RANGE);
  const [isScheduled, setIsScheduled] = useState(false);

  const dateChangeHandler = useCallback((selectedDates = []) => {
    didUserSetDate = true;

    const [from, to] = selectedDates.map(formatDate);

    setIsScheduled(false);

    return strIsAValidDate(from) && strIsAValidDate(to)
      ? setRange({from, to})
      : setRange(INITIAL_RANGE);
  }, []);

  const dateRangeContext = useMemo(
    () => ({
      dateRange,
      dateChangeHandler,
      didUserSetDate,
      isScheduled,
      setIsScheduled,
    }),
    [dateRange, dateChangeHandler, isScheduled, setIsScheduled],
  );

  return (
    <DateRangeContext.Provider value={dateRangeContext}>
      {children}
    </DateRangeContext.Provider>
  );
};

ContractDateRangeProvider.propTypes = {
  children: PropTypes.node,
};

export const useDateRange = () => {
  const context = useContext(DateRangeContext);

  if (context === undefined) {
    throw new Error(
      "useDateRange must be used inside ContractDateRangeProvider",
    );
  }

  return context;
};
