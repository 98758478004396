import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Add24, Filter24} from "@bphxd/ds-core-react/lib/icons";
import {
  ADD_RULE_VERSION,
  DELETE_RULE_VERSION,
  EDIT_RULE_VERSION,
  VERSION_LIST_DISPLAY,
} from "graphql/compliance-rules-engine/VersionsPageAPI";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import {useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Spinner} from "reactstrap";
import tw from "twin.macro";
import columnsRules from "../components/columns/ColumnVersions";
import Layout from "../components/index";
import ComplianceDocumentTable from "../components/tables/ComplianceDocumentTable";
import DeleteVersionModal from "../modals/DeleteVersionModal";
import EditVersionModalDisplay from "../modals/EditVersionModal";
import VersionModalDisplay from "../modals/VersionModal";

// function starts

const RulesVersionPage = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const navigate = useNavigate();
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const methods = useForm();

  // Set up all states here

  const [showFilter, setShowFilter] = useState(false);
  const [columnFilter, setColumnFilter] = useState([]);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [versionToEdit, setVersionToEdit] = useState(null);

  const {appSetting} = useAppSetting();
  const {ruleMasterId, ruleVersionId} = useParams();
  const [searchParams] = useSearchParams();
  const [modalContent, setModalContent] = useState({
    title: "",
    inputIndex: "",
    dropdownIndex: "",
  });

  // fetch version list

  const {
    data: versionRecordsData,
    loading,
    error,
    refetch,
  } = useQuery(VERSION_LIST_DISPLAY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {ruleMasterId},
  });

  // mutation call for performing addition of rule version

  const [addVersion] = useMutation(ADD_RULE_VERSION, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateVersion}) => {
      if (bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 201) {
        toast.success("Version created successfully", {
          autoClose: 750,
        });
        setUploadModal(false);

        navigate(
          `/compliance-rules-engine/rule-editor/${bioLcComplianceRulesEngineCreateUpdateVersion?.ruleVersionId}`,
        );
      } else {
        toast.error(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Failed to create version, please try again",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  // mutation call for performing deletion of rule version

  const [deleteVersion] = useMutation(DELETE_RULE_VERSION, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateVersion}) => {
      if (bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 204) {
        toast.success("Version deleted successfully", {
          autoClose: 750,
        });
        refetch();
      } else {
        toast.error(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Failed to delete version, please try again",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  // mutation call for performing editing of rule version

  const [editVersion] = useMutation(EDIT_RULE_VERSION, {
    onCompleted: ({bioLcComplianceRulesEngineCreateUpdateVersion}) => {
      if (bioLcComplianceRulesEngineCreateUpdateVersion?.statusCode === 204) {
        toast.success("Version updated successfully", {
          autoClose: 750,
        });
        setShowEditModal(false);
        refetch();
      } else {
        toast.error(
          bioLcComplianceRulesEngineCreateUpdateVersion?.error ??
            "Failed to update version, please try again",
          {
            autoClose: 750,
          },
        );
      }
    },
  });

  const handleAddVersionClick = () => {
    setModalContent({
      title: "New Version",
      inputIndex: "Comment",
      dropdownIndex: "Valid from",
    });
    setUploadModal(true);
  };

  const handleAddVersionSubmit = ({inputValue, startDate}) => {
    addVersion({
      variables: {
        event: {
          isPublishActionConfirmed: false,
          operation: "create",
          remarks: inputValue,
          ruleMasterId,
          ruleVersionId,
          userId: account.username,
          validFrom: startDate,
        },
      },
    });
  };

  const handleOpenDeleteModal = (ruleVersionId) => {
    setVersionToDelete(ruleVersionId);
    setDeleteModal(true);
  };

  const handleDeleteVersion = () => {
    deleteVersion({
      variables: {
        isPublishActionConfirmed: true,
        remarks: "Deleting version",
        ruleMasterId,
        ruleVersionId: versionToDelete,
        userId: account.username,
        validFrom: new Date().toISOString(),
      },
    });
    setDeleteModal(false);
  };

  const handleEditClick = (version) => {
    setVersionToEdit(version);
    setShowEditModal(true);
  };

  const handleEditVersionSubmit = ({inputValue, startDate}) => {
    editVersion({
      variables: {
        isPublishActionConfirmed: false,
        remarks: inputValue,
        ruleMasterId,
        ruleVersionId: versionToEdit.ruleVersionId,
        userId: account.username,
        validFrom: startDate,
      },
    });
  };

  if (error) {
    console.error("Error fetching version records:", error);
  }

  // check response data

  const versionResponseData =
    (versionRecordsData &&
      versionRecordsData?.bioLcComplianceRulesEngineGetRuleVersions
        ?.versions) ||
    [];

  // check rule data

  const ruleName =
    versionRecordsData?.bioLcComplianceRulesEngineGetRuleVersions?.ruleName ||
    "";

  // check country data

  const country =
    versionRecordsData?.bioLcComplianceRulesEngineGetRuleVersions
      ?.countryName || "";

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: "Compliance rules engine",
      link: `/compliance-rules-engine`,
    },
    {
      text: "Rule details",
      link: `/compliance-rules-engine/versions/${ruleMasterId}`,
    },
  ];

  return (
    <Layout
      title={ruleName}
      countryText={country?.toUpperCase()}
      breadcrumbs={breadcrumbItems}
    >
      {loading ? (
        <div className="flex items-center justify-center mt-13">
          <div className="text-center">
            <Spinner />
          </div>
        </div>
      ) : versionResponseData.length > 0 ? (
        <div>
          <HeaderSection>
            <div className="flex flex-row justify-between w-full items-center my-5">
              <PageTitle className="flex">Versions</PageTitle>
              <div className="flex flex-row justify-end w-full my-3 gap-x-3 items-center">
                <div className="mr-2">
                  <Button
                    data-test="doc-manager-filter-btn"
                    color="standard-tertiary rounded-0"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    Filters
                    <Filter24 className="btn-icon-suffix" />
                  </Button>
                </div>
                <div className="">
                  <Button
                    color="primary"
                    className="float-right rounded-0 text-gray-400"
                    id="addRuleButton"
                    size="md"
                    onClick={handleAddVersionClick}
                  >
                    New Version
                    <Add24 className="btn-icon-suffix" />
                  </Button>
                </div>
              </div>
            </div>
          </HeaderSection>

          <div className="border-secondary w-full px-7 min-h-[500px]">
            <ComplianceDocumentTable
              columns={columnsRules(
                handleOpenDeleteModal,
                handleEditClick,
                dateFormat,
              )}
              data={versionResponseData ?? []}
              showFilter={showFilter}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
              loading={loading}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-13">
          <div className="text-center">
            <p>There are currently no versions for this rule,</p>
            <p>please create a version</p>
            <Button
              color="primary"
              className="rounded-0 text-gray-400"
              id="addRuleButton"
              size="md"
              onClick={handleAddVersionClick}
            >
              Create version
              <Add24 className="btn-icon-suffix" />
            </Button>
          </div>
        </div>
      )}
      <FormProvider {...methods}>
        {showUploadModal && (
          <VersionModalDisplay
            showUploadModal={showUploadModal}
            setUploadModal={setUploadModal}
            initialComment=""
            initialValidFrom=""
            onSubmit={handleAddVersionSubmit}
          />
        )}
      </FormProvider>

      {showDeleteModal && (
        <DeleteVersionModal
          showDeleteModal={showDeleteModal}
          setDeleteModal={setDeleteModal}
          onDelete={handleDeleteVersion}
        />
      )}
      <FormProvider {...methods}>
        {showEditModal && (
          <EditVersionModalDisplay
            showUploadModal={showEditModal}
            setUploadModal={setShowEditModal}
            initialComment={versionToEdit?.remarks}
            initialValidFrom={versionToEdit?.validFrom}
            onSubmit={handleEditVersionSubmit}
          />
        )}
      </FormProvider>
    </Layout>
  );
};

export default RulesVersionPage;

const HeaderSection = tw.div`relative flex flex-col  mx-[40px]`;
const PageTitle = tw.h1`text-[1.8125rem] m-0`;
