import React from "react";

import {useQuery} from "@apollo/client";
import {MB_LG_LOCATION_GET_QUERY} from "graphql/GlobalConfigCenter/MbLgLocation";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {MB_LG_LOCATION_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {MB_LG_LOCATION_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const MbLgLocationPage = () => {
  const {data, loading} = useQuery(MB_LG_LOCATION_GET_QUERY);

  return (
    <Layout
      title={MB_LG_LOCATION_CONFIG_PAGE.title}
      isGlobal
      description={MB_LG_LOCATION_CONFIG_PAGE.description}
      tableColumns={MB_LG_LOCATION_COLUMNS}
      tableData={loading ? [] : data?.bioLcGetMbLgLocation.mbLgLocationInfo}
    />
  );
};

export default MbLgLocationPage;
