import {
  useEffect,
  useMemo,
  useState,
  createContext,
  Children,
  cloneElement,
  useContext,
} from "react";
import {MinusOutlined, PlusCircleOutlined} from "@ant-design/icons";
import tw, {css, styled} from "twin.macro";
import PropTypes from "prop-types";

const CollapseItemContext = createContext();

const CollapseItem = ({children, id, toggleItem, isOpen}) => {
  const collapseEvents = useMemo(
    () => ({id, amIOpen: isOpen(id), toggleMe: () => toggleItem(id)}),
    [id, isOpen, toggleItem],
  );

  return (
    <CollapseItemContext.Provider value={collapseEvents}>
      <StyledCollapseItem data-test={`item-${id}`}>
        {children}
      </StyledCollapseItem>
    </CollapseItemContext.Provider>
  );
};
CollapseItem.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
  isOpen: PropTypes.func,
  toggleItem: PropTypes.func,
};

const Collapse = ({children, initialOpenIds = [], ...otherProps}) => {
  const [openIds, setOpenIds] = useState([initialOpenIds].flat());

  const toggleItem = (id) =>
    setOpenIds((state) =>
      state.includes(id) ? state.filter((e) => e !== id) : state.concat(id),
    );

  useEffect(
    () => initialOpenIds.length && setOpenIds((ids) => [...initialOpenIds]),
    [initialOpenIds],
  );

  return (
    <CollapseContainer {...otherProps}>
      {Children.map(children, (child, index) => {
        if (child.type !== CollapseItem) {
          throw new Error(
            `Only CollapseItem can be a direct child of the Collapse component. This child is ${child.type.name}`,
          );
        }

        return cloneElement(child, {
          index,
          isOpen: (id) => openIds.includes(id),
          toggleItem,
        });
      })}
    </CollapseContainer>
  );
};
Collapse.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  initialOpenIds: PropTypes.array,
};

const Header = ({children}) => {
  const {toggleMe, amIOpen, id} = useContext(CollapseItemContext);

  return (
    <StyledButton forecastType={id} onMouseDown={toggleMe}>
      {children}

      {amIOpen ? <MinusOutlined /> : <PlusCircleOutlined />}
    </StyledButton>
  );
};
Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const Content = ({children, ...otherProps}) => {
  const {amIOpen, id} = useContext(CollapseItemContext);

  return amIOpen ? (
    <div
      className="border-x rounded-b-lg"
      data-test={`${id}-content`}
      {...otherProps}
    >
      {children}
    </div>
  ) : null;
};
Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Collapse.Item = CollapseItem;
Collapse.ItemHeader = Header;
Collapse.ItemContent = Content;

export default Collapse;

const indicatorColors = {
  credits: tw`border-t-4 border-t-bp-green-500 bg-gray-100`,
  deficits: tw` border-t-4 border-t-red-500  bg-gray-100`,
};

const getButtonStyles = (forecastType) =>
  ({
    [["rin", "credits"].includes(forecastType)]: indicatorColors.credits,
    [["rvo", "deficits"].includes(forecastType)]: indicatorColors.deficits,
    // eslint-disable-next-line prettier/prettier
  }.true);

const CollapseContainer = tw.div`col-span-full my-2`;
const StyledCollapseItem = tw.div`rounded mt-5`;
const StyledButton = styled.button(({forecastType}) => [
  tw`capitalize py-7 px-8 w-full bg-gray-100`,

  getButtonStyles(forecastType),

  css`
    font-size: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    line-height: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    -webkit-appearance: none;

    & > h1,
    h2,
    h3,
    div {
      font-family: "Roboto Black", sans-serif;
      float: left;
      padding-left: 20px;
    }

    & > h2 {
      height: 56px;
      display: flex;
      align-items: center;
    }

    & > div {
      width: 90%;

      & span {
        font-family: "Roboto Regular", sans-serif;
      }
    }

    & > span {
      float: right;
      padding-right: 20px;
    }
  `,
]);

export const collapsibleData = (forecastType) => {
  switch (forecastType) {
    case "rin":
      return [{label: "credits", value: "rin"}];
    case "rvo":
      return [{label: "deficits", value: "rvo"}];
    case "":
      return [
        {label: "credits", value: "rin"},
        {label: "deficits", value: "rvo"},
      ];
    default:
      return [
        {label: "credits", value: "rin"},
        {label: "deficits", value: "rvo"},
      ];
  }
};
