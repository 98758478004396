import {useLocation, useNavigate} from "react-router-dom";
import {MODAL_QUERY_PARAM} from "modules/co-processing/constants/batches";

const queryStringToObject = (searchUrl) => {
  return Array.from(new URLSearchParams(searchUrl)).reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {},
  );
};

const objectToQueryString = (queryParameters) => {
  const searchParams = new URLSearchParams();
  Object.keys(queryParameters).forEach((key) => {
    searchParams.set(key, queryParameters[key]);
  });
  return searchParams.toString();
};

const useBatchModal = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParam2Obj = queryStringToObject(location.search);
  const obj2QueryParam = objectToQueryString(queryParam2Obj);

  const openModal = () => {
    const queryParams = queryStringToObject(location.search);
    queryParams[MODAL_QUERY_PARAM] = "true";
    navigate({
      pathname: "/co-processing/batches",
      search: objectToQueryString(queryParams),
    });
  };

  const closeModal = () => {
    const queryParams = queryStringToObject(location.search);
    delete queryParams[MODAL_QUERY_PARAM];
    navigate({
      pathname: location.pathname,
      search: objectToQueryString(queryParams),
    });
  };

  const isModalOpen = () => {
    const queryParams = queryStringToObject(location.search);
    return queryParams[MODAL_QUERY_PARAM] === "true";
  };

  return {isModalOpen, openModal, closeModal};
};

export default useBatchModal;
