import * as yup from "yup";

export default yup
  .object()
  .shape({
    companyCode: yup.string().label("Company Code").required(),
    legalEntity: yup.string().label("Legal Entity").required(),
    registrationNumber: yup
      .string()
      .label("Excise Registration Number")
      .max(250)
      .default(""),
    plant: yup.string().label("Plant").required(),
    taxLiability: yup.string().label("Tax Liability AS").required(),
    eDGroup: yup.string().label("ED Group").required(),
    mot: yup.string().label("MOT Scope").required(),
    mainCustomsOffice: yup
      .string()
      .trim()
      .label("Main Customs Office")
      .required(),
  })
  .required();
