import {useEffect} from "react";

function useRuleFormValidationEffects({
  selectedItem,
  saveErrorData,
  clearErrors,
  setError,
  setFocus,
}) {
  useEffect(() => {
    clearErrors();
    if (saveErrorData && saveErrorData?.index !== selectedItem?.templateCount) {
      clearErrors();
    } else if (saveErrorData) {
      setError(saveErrorData?.field, {
        type: "manual",
        message: saveErrorData?.error,
      });
      setFocus(saveErrorData?.field);
    }
  }, [
    saveErrorData,
    selectedItem?.templateCount,
    clearErrors,
    setError,
    setFocus,
  ]);
}

export function useRuleValidateOutputColumns({
  field,
  value,
  selectedTemplateItems,
  clearErrors,
  setError,
  setFocus,
  x,
}) {
  useEffect(() => {
    const configurationData = selectedTemplateItems.map(
      (item) => item.configuration,
    );

    const outputColumns = configurationData
      ?.map((config) => config?.output_column)
      ?.filter(
        (output) => output !== undefined && output !== null && output !== "",
      );

    if (outputColumns && outputColumns.length > 0) {
      // Check if the value is unique within outputColumns
      const isNotUnique =
        outputColumns.filter((item) => item === value).length > 1;

      if (isNotUnique) {
        // Set  error for outputColumns validation
        setError(field, {
          type: "manual",
          message:
            "This output column name is already used. Please choose a different name.",
        });
        setFocus(field);
      }
    }
  }, [field, value, selectedTemplateItems, clearErrors, setError, setFocus]);
}

export default useRuleFormValidationEffects;
