import {useState, useMemo} from "react";
import PropTypes from "prop-types";
import {Popover, PopoverBody} from "reactstrap";
import {formatNumber} from "providers/userSettings";

const DHDSDataGridItem = ({dataBbl, dataLb, label, aimTag, metric = null}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const sanitizeString = (id) =>
    id?.replace(/\s+/g, "-")?.replace(/[^a-zA-Z0-9-_]/g, "");

  const sanitizedTagId = useMemo(
    () => `aim-data-point-${sanitizeString(label)}-${sanitizeString(aimTag)}`,
    [label, aimTag],
  );

  const getDisplayedValue = (data, metric) => {
    if (!data) {
      return "";
    }
    return `${formatNumber(data, "Imperial Style")} ${metric}`;
  };

  return (
    <div
      data-test="data-grid-item"
      className="grid grid-cols-3 place-content-between py-3 text-xs border-b border-gray-100"
    >
      <div
        id={sanitizedTagId}
        className="text-secondary"
        onMouseEnter={() => setTooltipOpen(true)}
        onMouseLeave={() => setTooltipOpen(false)}
      >
        {label}
      </div>
      <div className="data-grid-item-value text-right">
        {getDisplayedValue(dataBbl, metric ?? "bbl")}
      </div>
      <div className="data-grid-item-value text-right">
        {getDisplayedValue(dataLb, "lb")}
      </div>

      {aimTag && (
        <Popover
          popperClassName="copied-popover"
          innerClassName="copied-popover-inner"
          data-test="aims-tag-popover"
          placement="top"
          isOpen={tooltipOpen}
          target={sanitizedTagId}
        >
          <PopoverBody>{`AIM #: ${aimTag}`}</PopoverBody>
        </Popover>
      )}
    </div>
  );
};

DHDSDataGridItem.propTypes = {
  dataBbl: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataLb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  aimTag: PropTypes.string,
  metric: PropTypes.string,
};

export default DHDSDataGridItem;
