import moment from "moment";
import cond from "lodash/fp/cond";
import maxBy from "lodash/fp/maxBy";
import {isNotNull} from "./nullCheck";
import {
  FORECAST_DISPLAY_LABELS,
  CREDITS_CATEGORIES,
  DEFICITS_CATEGORIES,
} from "../constants/inventory";

export const isCurrentDate = ({date} = {}) =>
  !!(
    Number(date.year) === new Date().getFullYear() &&
    Number(date.month) === new Date().getMonth() + 1
  );

const getMonthLabel = (isGreaterThanCurrentMonth, isCurrentMonth) => {
  if (isGreaterThanCurrentMonth) {
    return FORECAST_DISPLAY_LABELS.FORECAST;
  }
  return isCurrentMonth
    ? FORECAST_DISPLAY_LABELS.CURRENT
    : FORECAST_DISPLAY_LABELS.PREVIOUS;
};

export const getDisplayLabel = ({year, month}) => {
  const currentDate = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  };

  const isGreaterThanCurrentMonth =
    (parseInt(year, 10) === currentDate.year &&
      parseInt(month, 10) > currentDate.month) ||
    parseInt(year, 10) > currentDate.year;
  const isCurrentMonth =
    currentDate.year === parseInt(year, 10) &&
    currentDate.month === parseInt(month, 10);

  const label = getMonthLabel(isGreaterThanCurrentMonth, isCurrentMonth);

  return FORECAST_DISPLAY_LABELS[label];
};

const isPreviousMonth = (columnForecastDate) => {
  const previousMonthDate = moment().subtract(1, "months").format("YYYY-MM");

  return moment(previousMonthDate, "YYYY-MM").isSame(
    columnForecastDate,
    "month",
  );
};

const isNextMonth = (columnDate) => {
  const nextMonthDate = moment().add(1, "months").format("YYYY-MM");

  return moment(nextMonthDate, "YYYY-MM").isSame(columnDate, "month");
};

const isFutureMonth = (columnDate) => {
  const currentMonthDate = moment().format("YYYY-MM");

  return moment(currentMonthDate, "YYYY-MM").isBefore(columnDate, "month");
};

const offsetFromCurrentMonth = (columnDate) => {
  return moment().diff(columnDate, "months");
};

const getFirstIndexVisibility = (forecast, forecastDateInColumn) => {
  if (
    isCurrentDate(forecast) ||
    isPreviousMonth(forecastDateInColumn) ||
    isNextMonth(forecastDateInColumn) ||
    isFutureMonth(forecastDateInColumn)
  ) {
    return "visible";
  }
  return "invisible";
};

const getSecondIndexVisibility = (forecast, forecastDateInColumn) => {
  if (
    isCurrentDate(forecast) ||
    isPreviousMonth(forecastDateInColumn) ||
    isNextMonth(forecastDateInColumn)
  ) {
    return "visible";
  }
  return "invisible";
};

const getLastIndexVisibility = (forecast, forecastDateInColumn) => {
  if (
    isCurrentDate(forecast) ||
    offsetFromCurrentMonth(forecastDateInColumn) >= 1 ||
    isNextMonth(forecastDateInColumn)
  ) {
    return "visible";
  }
  return "invisible";
};

// columnIndex is the idex of the four columns rendered on the UI ( | previousMonth | currenForecasttMonth | nextForecastMonth | fourthForecastMonth )
// startingIndex is the index of the forecast item being rendered in the firstColumn
export const showPreviousCurrentOrForecastLabel = (columnIndex, forecast) => {
  const forecastDateInColumn = moment(
    `${forecast.date.year}-${forecast.date.month}`,
    "YYYY-MM",
  );

  switch (columnIndex) {
    case 0:
      return getFirstIndexVisibility(forecast, forecastDateInColumn);
    case 1:
    case 2:
      return getSecondIndexVisibility(forecast, forecastDateInColumn);
    case 3:
      return getLastIndexVisibility(forecast, forecastDateInColumn);
    default:
      return "invisible";
  }
};

export const getForecastTypeCategory = (index, isCredits = false) =>
  isCredits ? CREDITS_CATEGORIES[index] : DEFICITS_CATEGORIES[index];

// shows the previous, current, and forecast labels for the expanded view of the graph set
export const showExpandedForecastLabels = (forecast) => {
  const forecastDateInColumn = moment(
    `${forecast.date?.year}-${forecast.date?.month}`,
    "YYYY-MM",
  );

  if (isCurrentDate(forecast)) {
    return "visible";
  }
  if (
    isPreviousMonth(forecastDateInColumn) &&
    offsetFromCurrentMonth(forecastDateInColumn) <= 1
  ) {
    return "visible";
  }
  if (
    isNextMonth(forecastDateInColumn) &&
    offsetFromCurrentMonth(forecastDateInColumn) <= 1
  ) {
    return "visible";
  }
  return "invisible";
};

export const getForecastDifference = (forecast) => {
  if (isNotNull(forecast.trueUpData)) {
    return forecast.trueUpData;
  }

  if (isNotNull(forecast.differenceFromRecentVersion)) {
    return forecast.differenceFromRecentVersion;
  }

  if (isNotNull(forecast.differenceFromActuals)) {
    return forecast.differenceFromActuals;
  }

  return 0;
};

const getForecastValue = (forecast, category) => {
  if (!forecast || !category) return null;

  const forecastCategory = forecast[category];

  if ("actualValue" in forecastCategory) {
    return forecastCategory.actualValue;
  }
  return forecastCategory.value;
};

export const getMaxValue = (data, isPublished = false, isCredits = false) => {
  const cat1 = getForecastTypeCategory(0, isCredits);
  const cat2 = getForecastTypeCategory(1, isCredits);
  let maxValue = 0;

  data?.forEach((forecast, i) => {
    const forecast1Value = getForecastValue(forecast, cat1);
    const forecast2Value = getForecastValue(forecast, cat2);

    const maxForecastValue = isPublished
      ? Math.max(forecast1Value, forecast2Value, i + 1)
      : Math.max(forecast1Value + forecast2Value, i + 1);

    maxValue = maxForecastValue > maxValue ? maxForecastValue : maxValue;
  });

  return maxValue;
};

export const getHighestBarValue = (graphList = []) =>
  graphList.reduce((max, {date, ...categories}) => {
    const {actualValue} = maxBy("actualValue", Object.values(categories));

    return actualValue > max ? actualValue : max;
  }, 0);

const dateObjToStr = (date) => {
  if (typeof date === "object" && date?.month && date?.year) {
    return `${date.year}-${date.month}`;
  }

  return date && typeof date === "string" ? date : "";
};

const isItPreviousMonth = (date) =>
  moment(dateObjToStr(date), "YYYY-MM").isSame(
    moment().subtract(1, "months"),
    "month",
  );
export const isItCurrentMonth = (date) =>
  moment(dateObjToStr(date), "YYYY-MM").isSame(new Date(), "month");
const isItNextMonth = (date) =>
  moment(dateObjToStr(date), "YYYY-MM").isSame(
    moment().add(1, "months"),
    "month",
  );

const displayPrevLabel = () => FORECAST_DISPLAY_LABELS.PREVIOUS;
const displayCurrLabel = () => FORECAST_DISPLAY_LABELS.CURRENT;
const displayForecastLabel = () => FORECAST_DISPLAY_LABELS.FORECAST;

export const getPreviousCurrentOrForecastHeader = cond([
  [isItPreviousMonth, displayPrevLabel],
  [isItCurrentMonth, displayCurrLabel],
  [isItNextMonth, displayForecastLabel],
]);
