import PropTypes from "prop-types";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import ConfigurationTable from "../ConfigurationTable/ConfigurationTable";
import RequestRevisionModal from "../RequestRevisionModal/RequestRevisionModal";
import "./Layout.css";

const Layout = ({title, isGlobal, description, tableColumns, tableData}) => {
  const [modal, setModal] = useState(false);
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Configuration centre", link: "/configuration-center"},
    {text: `${title}`, link: ""},
  ];
  const handleRequestRevision = () => {
    setModal(true);
  };

  return (
    <div
      className="bp-core bg-light-gray-100 px-10 pt-5 pb-40"
      data-test="dashboard-layout"
    >
      <RequestRevisionModal
        modal={modal}
        setModal={setModal}
        title={title}
        tableData={tableData}
        tableColumns={tableColumns}
      />
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <BreadcrumbItem
            key={item.text}
            active={index !== breadcrumbItems.length - 1}
          >
            <Link to={item.link}>{item.text}</Link>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <div className="d-flex align-items-center">
        <h1 className="fs-1 fw-light mr-3 mb-0">{title}</h1>

        {/* Global badge */}
        {isGlobal && (
          <div>
            <span className="whitespace-nowrap global-badge-config-center align-top">
              GLOBAL
            </span>
          </div>
        )}
      </div>

      {/* Horizontal rule after title row */}
      <div className="bp-core-hr bg-light-gray-600 mb-0" />

      {/* White square with description and request revision button */}
      <div className="d-flex align-items-center justify-between py-4">
        <div className="description fw-light">{description}</div>
        <Button color="secondary" onClick={handleRequestRevision}>
          Request revision
        </Button>
      </div>

      <ConfigurationTable columns={tableColumns} data={tableData} />
    </div>
  );
};

Layout.propTypes = {
  isGlobal: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tableColumns: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
};

Layout.defaultProps = {
  isGlobal: false,
};

export default Layout;
