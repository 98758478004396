import React, {useState} from "react";
import Button from "modules/common/Button";
import moment from "moment";
import PropTypes from "prop-types";
import {Datepicker} from "@bphxd/ds-core-react";
import {Add32} from "@bphxd/ds-core-react/lib/icons";

const ColumnItemFilterDate = ({column, onChange}) => {
  const [filterValues, setFilterValues] = useState(
    new Set(column.filterValues),
  );
  const handleFilter = (dateRange) => {
    if (dateRange?.filter((item) => item != null)?.length > 1) {
      const dateRangeItem = dateRange.map((date) =>
        moment(date).format("YYYY-MM-DD"),
      );
      filterValues.add(dateRangeItem?.join(","));
      setFilterValues(new Set([...filterValues]));
    }
  };
  const handleDateFilterAdd = () => {
    onChange({...column, filterValues: [...filterValues]});
  };

  return (
    <>
      <div className="flex flex-col py-2 px-4 gap-2 items-center w-full">
        {column?.filterValues?.map((dateItems) => (
          <label>
            {dateItems
              .split(",")
              .map(
                (date, index) =>
                  `${moment(date, "YYYY-MM-DD").format("DD-MM-YYYY")} ${
                    index === 0 ? "- " : ""
                  }`,
              )}
          </label>
        ))}
      </div>
      <div className="flex flex-row py-2 px-4 gap-2">
        <div className="w-100">
          <Datepicker
            options={{
              allowInput: false,
              altInput: true,
              mode: "range",
              showMonths: 1,
              allowInvalidPreload: false,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              enableTime: false,
              onChange: (selectedDates) => {
                handleFilter(selectedDates);
              },
            }}
          />
        </div>
        <Button
          size="large"
          data-test="filter-clear-button"
          type="secondary"
          onClick={() => handleDateFilterAdd()}
          icon={<Add32 />}
        />
      </div>
    </>
  );
};

ColumnItemFilterDate.propTypes = {
  column: PropTypes.object,
  onChange: PropTypes.func,
};
export default ColumnItemFilterDate;
