/* eslint-disable no-param-reassign */
import {Input} from "reactstrap";
import {isEqual} from "lodash";
import omit from "lodash/fp/omit";
import {
  MONTHS,
  dieselKeyValuePairsToPublish,
  gasKeyValuePairsGeneral,
  CREDITS_CATEGORIES,
} from "modules/forecasting/constants/inventory";
import {currentMonth, isAfterCurrMonth, isCurrentMonth} from "./dateUtils";

const isEmpty = (obj = {}) => !Object.values(obj).some((x) => x !== null);

const isGas = (key = "") => {
  return key.includes("gas") || key.includes("obligated_blended_production");
};

const replaceKeysWithValue = (obj1, obj2) => {
  return Object.fromEntries(
    Object.entries(obj2).map(([key, value]) => [obj1[key] ?? key, value]),
  );
};

const getProductKey = (key = "") => {
  if (key === "non_obligated_export_gas") {
    return `user_${key}`;
  }
  if (key === "obligated_blended_production") {
    return `user_${key}_gas`;
  }
  return key;
};

const isSameDate =
  (dataIndex) =>
  ({date}) => {
    return `${date.year}-${date.month}` === dataIndex;
  };

const getItemByDate = (dataIndex, obligationsData = {}) =>
  obligationsData?.find(isSameDate(dataIndex));

export const wcDataConverter = (data) => {
  const gas = data.bioLcForecastApi?.body?.obligations?.gas || [];
  const diesel = data.bioLcForecastApi?.body?.obligations?.diesel || [];

  const result = gas.map((item, index) => {
    return {
      date: item.date,
      obligationItems: [
        {
          type: "savedActuals",
          obligationValues: {
            ...replaceKeysWithValue(gasKeyValuePairsGeneral, item.savedValues),
            ...replaceKeysWithValue(
              dieselKeyValuePairsToPublish,
              diesel[index].savedValues,
            ),
          },
        },
        {
          type: "savedForecast",
          obligationValues: {
            ...replaceKeysWithValue(gasKeyValuePairsGeneral, item.savedValues),
            ...replaceKeysWithValue(
              dieselKeyValuePairsToPublish,
              diesel[index].savedValues,
            ),
          },
        },
        {
          type: "publishedActuals",
          obligationValues: {
            ...replaceKeysWithValue(
              gasKeyValuePairsGeneral,
              item.publishedValues,
            ),
            ...replaceKeysWithValue(
              dieselKeyValuePairsToPublish,
              diesel[index].publishedValues,
            ),
          },
        },
        {
          type: "publishedForecast",
          obligationValues: {
            ...replaceKeysWithValue(
              gasKeyValuePairsGeneral,
              item.publishedForecast,
            ),
            ...replaceKeysWithValue(
              dieselKeyValuePairsToPublish,
              diesel[index].publishedForecast,
            ),
          },
        },
      ],
    };
  });

  return result;
};

const columnObjectsGenerator = (
  arr,
  checkboxState,
  obligation,
  handleChange,
  handleChangeMonth,
) => {
  const columnObjects = [
    {
      title: "Measurement",
      className: "to-publish-measurement-title",
      dataIndex: "measurementTitle",
      align: "left",
      width: 160,
    },
  ];

  arr.forEach((item) => {
    const checkedObligations = checkboxState[item.dataIndex];

    const isGasObligation = obligation === "gas";
    const filterObject = (obj) =>
      Object.entries(obj)
        .filter(([key]) => key in item)
        .filter(([key]) => (isGasObligation ? isGas(key) : !isGas(key)))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});

    const filteredObj = filterObject(checkedObligations);
    const checked = Object.values(filteredObj).every((value) => value === true);

    columnObjects.push({
      title: () => {
        return (
          <>
            <span>{`${item.monthName} - ${item.year % 100}`}</span>
            <Input
              className="ml-2 mr-3"
              type="checkbox"
              defaultChecked
              checked={checked}
              onChange={(e) =>
                handleChangeMonth(e.target.checked, item, obligation)
              }
            />
          </>
        );
      },

      className: `to-publish-month-cell-${item.dataIndex}`,
      dataIndex: item.dataIndex,
      align: "right",
      width: 140,
      render: (value, record) => {
        if (value) {
          return (
            <>
              <span className="font-bold text-[#3f3e45]">
                {value.toLocaleString("en-US")}
              </span>
              <Input
                className="ml-2 mr-3"
                type="checkbox"
                checked={
                  Object.keys(checkboxState || {}).length
                    ? checkboxState[item.dataIndex][record.measurement]
                    : true
                }
                onChange={() => handleChange(item, record, obligation)}
              />
            </>
          );
        }
        return null;
      },
    });
  });

  return columnObjects;
};

const columnsDataGenerator = (arr, keyValuePairs) => {
  const generatedArray = Object.keys(keyValuePairs).reduce(
    (accumulator, key, index) => {
      const object = arr.reduce((acc, item) => {
        return {
          ...acc,
          [item.dataIndex]: item[key],
        };
      }, {});

      // Only populates the array with objects that have at least one value
      if (!Object.values(object).every((item) => item === undefined)) {
        return [
          ...accumulator,
          {
            key: index,
            measurement: key,
            measurementTitle: keyValuePairs[key],
            ...object,
          },
        ];
      }

      return accumulator;
    },
    [],
  );
  return generatedArray;
};

/**
 * Function generates columns and data for obligations table.
 * @returns object with columns and data
 */
export const generateToPublishTableData = (
  monthData,
  keyValuePairs,
  checkboxState,
  obligation,
  handleChange,
  handleChangeMonth,
) => {
  const columns = columnObjectsGenerator(
    monthData,
    checkboxState,
    obligation,
    handleChange,
    handleChangeMonth,
  );
  const dataSource = columnsDataGenerator(monthData, keyValuePairs);

  return {columns, dataSource};
};

const getActualOrForecast = (
  type = "",
  monthData = {},
  dropdownValue = undefined,
) => {
  const {date = {}, obligationItems = []} = monthData;
  let typeKey = `${type}Actuals`;

  if (
    (isCurrentMonth(`${date.year}-${date.month}`) &&
      type === "published" &&
      dropdownValue === "forecast") ||
    isAfterCurrMonth({year: date.year, month: date.month})
  ) {
    typeKey = `${type}Forecast`;
  }

  return omit(
    "__typename",
    obligationItems.find((item) => item?.type === typeKey)?.obligationValues,
  );
};

const getTypeOfMeasurement = (measurement) => {
  if (isGas(measurement)) {
    return "gas";
  }

  if (/^d[4-6]_(current|previous)$/i.test(measurement)) {
    return CREDITS_CATEGORIES.find((category) =>
      measurement.includes(category),
    );
  }

  return "diesel";
};

export const diffReducer = (data, dropdownValue) => {
  const diffs = data.reduce((totalDiffs, monthItem) => {
    const dateKey = `${monthItem.date.year}-${monthItem.date.month}`;
    const saved = getActualOrForecast("saved", monthItem);
    const published = getActualOrForecast(
      "published",
      monthItem,
      dropdownValue,
    );

    const monthDiffs = Object.entries(saved).reduce(
      (savedDiffs, [measurement, savedValue]) => {
        const type = getTypeOfMeasurement(measurement);
        const diff = savedValue - (published[measurement] ?? 0);

        return diff
          ? {
              ...savedDiffs,
              [type]: {...savedDiffs[type], [measurement]: diff},
            }
          : savedDiffs;
      },
      {},
    );

    return !isEmpty(monthDiffs)
      ? {...totalDiffs, [dateKey]: monthDiffs}
      : totalDiffs;
  }, {});

  return diffs;
};

export const diffExistForCurrentMonth = (data, year) => {
  const item = data?.find(
    (dataElement) =>
      dataElement.date.year === Number(year) &&
      dataElement.date.month === Number(currentMonth),
  );

  const savedActuals = item?.obligationItems.find(
    (dataElement) => dataElement.type === "savedActuals",
  );
  const publishedActuals = item?.obligationItems.find(
    (dataElement) => dataElement.type === "publishedActuals",
  );
  const publishedForecast = item?.obligationItems.find(
    (dataElement) => dataElement.type === "publishedForecast",
  );

  return (
    !isEqual(
      savedActuals?.obligationValues,
      publishedActuals?.obligationValues,
    ) ||
    !isEqual(
      savedActuals?.obligationValues,
      publishedForecast?.obligationValues,
    )
  );
};

export const diffsByMeasurements = (diffs, region, keys) => {
  const combined = Object.assign({}, ...Object.values(keys));
  const measurements = Object.keys(combined);

  const result = {};
  Object.keys(diffs).forEach((yearMonth) => {
    result[yearMonth] = {};
    measurements.forEach((measurement) => {
      const hasKey = Object.values(diffs[yearMonth]).some((value) =>
        Object.prototype.hasOwnProperty.call(value, measurement),
      );
      result[yearMonth][measurement] = hasKey;
    });
  });
  return result;
};

/**
 * @param {string} obligationType gas type or diesel type
 * @param {Object} diffs all diffs available => saved - published
 */
export const diffsByObligationType = (obligationType, diffs = {}) =>
  Object.entries(diffs).map(([date, monthDiffs = {}]) => {
    const [year, month] = date.split("-");

    return {
      dataIndex: date,
      year: Number(year),
      month: Number(month),
      monthName: MONTHS[month - 1] || "",
      ...monthDiffs[obligationType],
    };
  });

const buildDataToPublish = (measurement, monthItems, dropdownValue) => {
  const saved = getActualOrForecast("saved", monthItems);
  const published = getActualOrForecast("published", monthItems, dropdownValue);

  return {
    diff: saved[measurement] - published[measurement] || 0,
    key: getProductKey(measurement),
    value: saved[measurement] ?? 0,
    month: monthItems.date.month,
    year: monthItems.date.year,
  };
};

export const getValuesToPublish = (
  obligationsData,
  dropdownValue,
  checkboxes,
) => {
  const valuesToPublish = [];

  Object.keys(checkboxes).forEach((dateItem) => {
    const monthItems = getItemByDate(dateItem, obligationsData);

    Object.keys(checkboxes[dateItem]).forEach((measurement) => {
      if (checkboxes[dateItem][measurement]) {
        valuesToPublish.push({
          ...buildDataToPublish(measurement, monthItems, dropdownValue),
        });
      }
    });
  });

  return valuesToPublish;
};

/**
 * Function generates an array of objects with total values for each month based on the checkbox state provided.
 * @returns array of objects with total values for each month, or empty array if checkboxes are unchecked.
 */
export const calculateToPublishTotal = (
  values,
  checks,
  keyValuePerObligation,
) => {
  const keys = Object.keys(keyValuePerObligation);
  const result = values.map((item) => {
    const checksPerMonth = Object.fromEntries(
      Object.entries(checks[item.dataIndex]).filter(([key]) =>
        keys.includes(key),
      ),
    );

    if (Object.keys(checksPerMonth).every((k) => !checksPerMonth[k])) {
      return {};
    }

    return {
      monthName: item.monthName,
      dataIndex: item.dataIndex,
      total: Object.keys(checksPerMonth).reduce((acc, key) => {
        if (
          checksPerMonth[key] &&
          Object.prototype.hasOwnProperty.call(item, key)
        ) {
          return acc + item[key];
        }
        return acc;
      }, 0),
    };
  });

  return result;
};

/**
 * Combines deficits and credits data into one array of objects
 *
 * @param {*} arr1
 * @param {*} arr2
 * @returns combined array of objects
 */
export const combineData = (arr1, arr2) => {
  const combinedArray = [];

  const mergeData = (items1, items2) => {
    const mergedItems = [];

    items1.forEach((item1) => {
      const item2 = items2.find((item) => item.type === item1.type);

      if (item2) {
        const mergedItem = {
          ...item1,
          obligationValues: {
            ...item1.obligationValues,
            ...item2.obligationValues,
          },
        };

        mergedItems.push(mergedItem);
      }
    });

    return mergedItems;
  };

  arr1.forEach(({date: date1, obligationItems: items1}) => {
    const {obligationItems: items2} = arr2.find(
      ({date: date2}) =>
        date2.year === date1.year && date2.month === date1.month,
    ) || {obligationItems: []};

    const mergedItems = mergeData(items1, items2);

    combinedArray.push({
      date: date1,
      obligationItems: mergedItems,
    });
  });

  return combinedArray;
};
