import {gql} from "@apollo/client";

// Fetch query for MB Lg Location table on Config center(/configuration-center/mb-lg-location)
export const MB_LG_LOCATION_GET_QUERY = gql`
  query bioLcGetMbLgLocation {
    bioLcGetMbLgLocation {
      mbLgLocationInfo {
        locationMasterId
        locationMasterShortName
        mbLocationGroupDescription
        mbLocationGroupId
        mbLocationGroupName
        validFrom
        validTo
        locationMasterLongName
        mbLgLocationId
      }
    }
  }
`;

export default {MB_LG_LOCATION_GET_QUERY};
