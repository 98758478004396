import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";

import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";

import {customDateFilterFn} from "modules/compliance-rules-engine/utils";
import {useUserSettings} from "providers/userSettings";
import {useSearchParams} from "react-router-dom";
import {Spinner, Table} from "reactstrap";
import Filter from "../filters/Filters";

const ComplianceDocumentTable = ({
  data,
  columns,
  loading,
  filtering,
  setFiltering,
  showFilter,
  columnFilter,
  setColumnFilter,
}) => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isHighlight, setIsHighlight] = useState(true);

  const table = useReactTable({
    data,
    columns,
    showFilter,

    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    filterFns: {
      dateRangeFilterFn: (row, columnId, value) =>
        customDateFilterFn(row, columnId, value, dateFormat),
    },

    state: {
      columnFilters: columnFilter,
    },
    onColumnFiltersChange: setColumnFilter,
    onGlobalFilterChange: setFiltering,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  const totalData = table.getPrePaginationRowModel().rows.length;

  const gettableHeader = (headerItem) => {
    return headerItem.column.getCanSort() ? (
      <th
        style={{
          cursor: "pointer",
          userSelect: "none",
          width: headerItem.getSize(),
        }}
      >
        <div
          className={`d-flex align-items-center ${
            headerItem.getContext().column.columnDef.dataType === "number" &&
            "justify-content-end"
          }`}
          onClick={headerItem.column.getToggleSortingHandler()}
          onKeyDown={() => {}}
        >
          <div>
            {flexRender(
              headerItem.column.columnDef.header,
              headerItem.getContext(),
            )}
          </div>
          <div className="position-relative ms-2">
            <div className="react-table-sort position-absolute top-50 translate-middle-y">
              <TriangleDown16
                className={`position-absolute bottom-0 ${
                  !headerItem.column.getIsSorted() ||
                  (headerItem.column.getIsSorted() &&
                    headerItem.column.getIsSorted() !== "desc")
                    ? "opacity-20"
                    : ""
                }`}
                onClick={() => {
                  if (headerItem.id === "status") {
                    searchParams.delete("sortBy");
                    setSearchParams(searchParams);
                  }
                }}
              />
              <TriangleUp16
                className={`position-absolute top-0 ${
                  !headerItem.column.getIsSorted() ||
                  (headerItem.column.getIsSorted() &&
                    headerItem.column.getIsSorted() !== "asc")
                    ? "opacity-20"
                    : ""
                }`}
                onClick={() => {
                  if (headerItem.id === "status") {
                    searchParams.delete("sortBy");
                    setSearchParams(searchParams);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {headerItem.column.getCanFilter() && showFilter ? (
          <div className="mt-[10px]">
            <Filter
              column={headerItem.column}
              data={data}
              isParentKey={false}
            />
          </div>
        ) : null}
      </th>
    ) : (
      <th
        className="align-top"
        style={{
          width: headerItem.getSize(),
        }}
      >
        {flexRender(
          headerItem.column.columnDef.header,
          headerItem.getContext(),
        )}
      </th>
    );
  };

  return (
    <>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {header.isPlaceholder ? (
                    <th aria-label="placeholder" />
                  ) : (
                    gettableHeader(header)
                  )}
                </Fragment>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <Spinner />
                </div>
              </td>
            </tr>
          )}
          {totalData > 0 &&
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    // className={`${
                    //   isCellHighlighted(cell, docId) && isHighlight
                    //     ? "bg-success-subtle"
                    //     : ""
                    // }
                    //   ${
                    //     cell.getContext().column.columnDef.dataType ===
                    //       "number" && "text-right"
                    //   }
                    //   ${
                    //     cell.row.getIsSelected()
                    //       ? "table-row-selected-bg"
                    //       : ""
                    //   }

                    //   `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          {!loading && totalData === 0 && !filtering && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center error-message-table ">
                  No Data
                </div>
              </td>
            </tr>
          )}
          {!loading && totalData === 0 && filtering && (
            <tr>
              <td colSpan="100%">
                <div className="pt-7 pl-5 error-message-table ">
                  No results found for the search criteria.
                  <span
                    className="underline cursor-pointer"
                    onClick={() => setFiltering("")}
                    onKeyDown={() => setFiltering("")}
                  >
                    Clear search
                  </span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};

ComplianceDocumentTable.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  pageIndex: PropTypes.number,
  showFilter: PropTypes.bool,
  filtering: PropTypes.string,
  setFiltering: PropTypes.func,
  columnFilter: PropTypes.array,
  setColumnFilter: PropTypes.func,
};

export default ComplianceDocumentTable;
