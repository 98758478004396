import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";

import {Button, Input} from "reactstrap";
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import {GET_COMPLIANCE_DETAILS_FILTER} from "graphql/gy-enablement/compliance/massBalance";
import {useQuery} from "@apollo/client";

import {debounce} from "lodash";

import ColumnItem from "./ColumnItem";
import Droppable from "./Droppable";

const CustomiseTable = ({
  onColumnChange,
  onClose,
  columns,
  scenarioNumber,
  filters,
  options,
  columnMap,
}) => {
  const [filteredColumns, setFilteredColumns] = useState(columns);
  const [bioScenarioNumberFields, setbioScenarioNumberFields] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (active.id === over.id) {
      return;
    }
    const reorderedColumns = [...columns];
    const activeIndex = reorderedColumns.findIndex((c) => c.key === active.id);
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.key === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);
    onColumnChange(reorderedColumns);
  };
  useEffect(() => {
    if (searchTerm.trim().length === 0) {
      setFilteredColumns(columns);
      return;
    }
    const cols = columns.filter((c) =>
      c.header.toLowerCase().includes(searchTerm),
    );
    setFilteredColumns(cols);
  }, [columns, searchTerm]);

  const handleColumnChange = (column) => {
    onColumnChange(columns.map((c) => (c.key === column.key ? column : c)));
  };

  const {
    data: bioScenarioNumberResult,
    loading,
    refetch,
  } = useQuery(GET_COMPLIANCE_DETAILS_FILTER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      ...filters,
      filterColumn: "bioScenarioNumber",
      scenarioNumber: "",
      searchTerm: "",
    },
    onCompleted: (data) => {
      setbioScenarioNumberFields(
        bioScenarioNumberResult?.queryComplianceDetailedFilter?.columnFields ??
          [],
      );
    },
  });

  const searchColumns = (e) => setSearchTerm(e.target.value.toLowerCase());
  return (
    <>
      <div className="flex justify-content-center mt-4">
        <Input
          onChange={debounce(searchColumns, 300)}
          placeholder="Search..."
          className="w-[90%]"
        ></Input>
      </div>
      <div className="max-h-[50vh] my-4 overflow-y-scroll">
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          {filteredColumns.map(
            (v) =>
              !v.notShowCustomize && (
                <Droppable key={v.key} id={v.key}>
                  <ColumnItem
                    column={v}
                    scenarioNumber={scenarioNumber}
                    onChange={handleColumnChange}
                    filters={filters}
                    columns={columns}
                    options={options}
                    columnMap={columnMap}
                  />
                </Droppable>
              ),
          )}
        </DndContext>
      </div>
      <div className="flex justify-content-center border-top bp-core">
        <Button
          className="apply-changes-button rounded-0 my-4 border-0"
          data-test="customise-apply-button"
          onClick={() => onClose(columns)}
        >
          Apply Changes
        </Button>
      </div>
    </>
  );
};

CustomiseTable.propTypes = {
  columns: PropTypes.array,
  scenarioNumber: PropTypes.string,
  onColumnChange: PropTypes.func,
  onClose: PropTypes.func,
  filters: PropTypes.object.isRequired,
  options: PropTypes.object,
  columnMap: PropTypes.object,
};

export default CustomiseTable;
