/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import {useForm, FormProvider} from "react-hook-form";
import {
  ArrowLeftOutlined,
  RightOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import Button from "modules/common/Button";
import Modal from "modules/common/Modal";
import Notification from "modules/common/Notification";
import useNotification from "modules/common/Notification/useNotification";
import {Spin} from "antd";
import {toast} from "react-toastify";
import {yupResolver} from "@hookform/resolvers/yup";
import Menu from "modules/common/Menu";

const modeData = {
  add: {
    succesfulNotificationText: "Created successfully.",
    submitButtonLabel: "Submit",
    TriggerButton: ({onClick, disabled}) => (
      <Menu.Item
        menuItemKey="table-action-add"
        data-test="table-action-add"
        disabled={disabled}
        onClick={onClick}
      >
        Add a new record
      </Menu.Item>
    ),
  },
  edit: {
    succesfulNotificationText: "Updated successfully.",
    submitButtonLabel: "Save changes",
    TriggerButton: ({onClick, disabled}) => (
      <Menu.Item
        menuItemKey="table-action-edit"
        data-test="table-action-edit"
        disabled={disabled}
        onClick={onClick}
      >
        Edit
      </Menu.Item>
    ),
  },
  "edit-row": {
    menuItemKey: "table-action-edit",
    succesfulNotificationText: "Updated successfully.",
    submitButtonLabel: "Save changes",
    TriggerButton: ({onClick}) => (
      <RightOutlined
        style={{fontSize: 20, fontWeight: "bold"}}
        key="table-action-edit"
        data-test="table-action-edit"
        onClick={onClick}
      />
    ),
  },
  clone: {
    succesfulNotificationText: "Cloned successfully.",
    submitButtonLabel: "Submit",
    TriggerButton: ({onClick, disabled}) => (
      <Menu.Item
        menuItemKey="table-action-clone"
        data-test="table-action-clone"
        disabled={disabled}
        onClick={onClick}
      >
        Clone
      </Menu.Item>
    ),
  },
};

const DetailedModal = ({
  tableState,
  formContent,
  mode,
  schema,
  getModalTitle,
  onSubmit,
  getDataWithDates,
  record,
  enableNextPrev = true,
  disabled,
}) => {
  const {data} = tableState;
  const [index, setDataIndex] = useState(0);
  const [isSubmitAllowed, setSubmitAllowed] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [timerId, setTimerId] = useState();
  const [isEditionModalVisible, setEditionModalVisible] = useState(false);
  const [triggerNotification, clearNotification, notificationProps] =
    useNotification();

  useEffect(() => {
    if (!isEditionModalVisible) {
      setDataIndex(data ? data.indexOf(record) : 0);
    }
  }, [data, record, isEditionModalVisible]);

  const {handleSubmit, reset, ...formMethods} = useForm({
    reValidateMode: "onSubmit",
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues: enableNextPrev
      ? getDataWithDates(data?.[index])
      : getDataWithDates(record),
  });

  useEffect(() => {
    if (enableNextPrev) {
      reset(getDataWithDates(data?.[index]));
    } else {
      reset(getDataWithDates(record));
    }
  }, [reset, getDataWithDates, data, record, index, enableNextPrev]);

  const {TriggerButton, submitButtonLabel, succesfulNotificationText} =
    modeData[mode];

  const disabledNext = data.indexOf(data[index]) === data.length - 1;
  const disabledPrevious = data.indexOf(data[index]) === 0;

  const handleOnSubmit = async (values) => {
    setSubmitAllowed(false);
    setSubmitting(true);
    try {
      const response = await onSubmit(values);
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          setSubmitting(false);
          setSubmitAllowed(true);
          triggerNotification("error", response.errors[0].message);
          break;
        default:
          // Theorically succesfully
          setSubmitting(false);
          toast.success(succesfulNotificationText);
          setEditionModalVisible(false);
      }
    } catch (err) {
      console.error(err);
      // Unexpected graphql error
      setSubmitting(false);
      setSubmitAllowed(true);
      triggerNotification("error", "Something went wrong, please try later.");
    }
  };

  return (
    <>
      {/* EDITION MODAL */}
      <Modal
        data-test="edition-modal"
        visible={isEditionModalVisible}
        title={getModalTitle(data?.[index])}
        onCancel={() => {
          if (enableNextPrev) {
            reset(getDataWithDates(data?.[data.indexOf(record)]));
            setDataIndex(data?.indexOf(record));
          }
          setEditionModalVisible((prevValue) => !prevValue);
        }}
        afterClose={() => {
          // Due modal is not unmounted when changing visibility: restart UI
          clearNotification();
          clearTimeout(timerId);
          setTimerId(null);
          setSubmitAllowed(true);
          reset();
        }}
        footer={
          <>
            <div className="flex w-full">
              <div className="flex flex-col items-start w-full gap-2 pt-8 border-t border-gray-100 sm:flex-row">
                <Button
                  icon={<ArrowLeftOutlined />}
                  type="secondary"
                  data-test="edition-modal-back-btn"
                  onClick={() => {
                    if (enableNextPrev) {
                      reset(getDataWithDates(data?.[data.indexOf(record)]));
                      setDataIndex(data.indexOf(record));
                    }
                    setEditionModalVisible((prevValue) => !prevValue);
                  }}
                >
                  Back
                </Button>
                <Button
                  data-test="edition-modal-submit-btn"
                  form="edition-form"
                  htmlType="submit"
                  disabled={!isSubmitAllowed}
                >
                  {isSubmitting ? <Spin size="small" /> : submitButtonLabel}
                </Button>
              </div>
              {enableNextPrev && (
                <div className="flex flex-col justify-end w-full gap-2 pt-8 border-t border-gray-100 sm:flex-row">
                  <Button
                    icon={<ArrowLeftOutlined />}
                    type="secondary"
                    data-test="edition-modal-prev-btn"
                    disabled={disabledPrevious}
                    className={
                      disabledPrevious &&
                      "disabled:opacity-50 cursor-not-allowed bg-gray-800"
                    }
                    onClick={() => {
                      reset(getDataWithDates(data?.[index - 1]));
                      setDataIndex((prevValue) => prevValue - 1);
                    }}
                  >
                    Previous
                  </Button>
                  <Button
                    icon={<ArrowRightOutlined />}
                    type="secondary"
                    data-test="edition-modal-next-btn"
                    className={
                      disabledNext &&
                      "disabled:opacity-50 cursor-not-allowed bg-gray-800"
                    }
                    disabled={disabledNext}
                    onClick={() => {
                      reset(getDataWithDates(data?.[index + 1]));
                      setDataIndex((prevValue) => prevValue + 1);
                    }}
                  >
                    Next
                  </Button>
                </div>
              )}
            </div>
            {disabledNext && enableNextPrev && (
              <div className="flex justify-end w-full mt-4">
                <p>
                  This is the last item on the page. Please navigate to the next
                  page for more items.
                </p>
              </div>
            )}

            {/* SUBMIT NOTIFICATION */}
            <Notification
              {...notificationProps}
              className="mt-8"
              onClose={() => clearNotification()}
            />
          </>
        }
        destroyOnClose
        closable
      >
        <FormProvider {...formMethods}>
          <form
            id="edition-form"
            noValidate
            onSubmit={handleSubmit(handleOnSubmit)}
          >
            {formContent}
          </form>
        </FormProvider>
      </Modal>

      {/* TRIGGER BUTTON */}
      <TriggerButton
        onClick={() => setEditionModalVisible(true)}
        disabled={disabled}
      />
    </>
  );
};

DetailedModal.propTypes = {
  formContent: PropTypes.node.isRequired,
  getModalTitle: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(["edit", "edit-row", "add", "clone"]).isRequired,
  schema: PropTypes.object,
  tableState: PropTypes.any,
  onSubmit: PropTypes.func.isRequired,
  dataIndex: PropTypes.number,
  getDataWithDates: PropTypes.func,
  enableNextPrev: PropTypes.bool,
  record: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DetailedModal;
