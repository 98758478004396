import React from "react";
import PropTypes from "prop-types";
import {HEADER_IVP} from "../constants/header";
import {FORECAST_TYPE, REGION} from "../constants/inventory";

const Header = ({region, forecastType}) => {
  const setRegion = () =>
    region === "ec" ? REGION.EAST_COAST : REGION.WEST_COAST;

  const setForecastType = () =>
    forecastType === "rin" ? FORECAST_TYPE.CREDITS : FORECAST_TYPE.DEFICITS;

  return (
    <div
      data-test="inventory-section-header"
      id="forecast-inventory-subheader"
      className="col-span-3 flex pt-6 text-xl text-gray-700 columns-2xs"
    >
      <div className="inventory-header">{`${HEADER_IVP} - ${setRegion()} - ${setForecastType()}`}</div>
    </div>
  );
};

Header.propTypes = {
  region: PropTypes.string,
  forecastType: PropTypes.string,
};

export default Header;
