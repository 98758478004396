import PropTypes from "prop-types";

import {client} from "providers/Apollo";

import {COPRO_EU_FIFO_ALL_RECORDS} from "./feedstock";

export const downloadExportApi = async (variables) => {
  return client.query({
    query: COPRO_EU_FIFO_ALL_RECORDS,
    fetchPolicy: "network-only",
    variables,
  });
};

downloadExportApi.propTypes = {
  variables: PropTypes.object.isRequired,
};
export default {downloadExportApi};
