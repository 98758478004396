import {gql} from "@apollo/client";

export const GET_RULE_BLOCK_SELECT_DATASETS = gql`
  query GetSelectDatasets {
    bioLcComplianceRuleEngineGetSelectDataSets {
      error
      selectDataSets {
        dataset
        schema {
          column
          type
        }
      }
      statusCode
    }
  }
`;

export default GET_RULE_BLOCK_SELECT_DATASETS;
