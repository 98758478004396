/* eslint-disable no-param-reassign */
import PropTypes from "prop-types";
import {useState, useEffect, useRef, useCallback, useMemo} from "react";
import {Menu, Dropdown} from "antd";
import {EllipsisOutlined, UndoOutlined} from "@ant-design/icons";
import NumericFormat from "react-number-format";
import {
  PLACEHOLDER_TEXT,
  UPDATE_REASONS,
  ERRORS,
  DROPDOWN_TREND_LABELS,
} from "../constants/inventory";
import calculateVariancePercentage from "../helpers/calculateVariancePercentage";
import ForecastArrowWidget from "./ForecastArrowWidget";
import DebouncedTextArea from "./DebouncedTextArea";
import ReasonCodeItem from "./ReasonCodeItem";
import {
  formatDisplayValue,
  formatDisplayValueWithSign,
} from "./CombinedObligatedVolumeTable";

const inputRightRadius = "5px";
const getSelectedReasonCodes = (o) => Object.keys(o).filter((key) => o[key]);

const validateUserInput = (enteredValue) => {
  const re = /^-?\d+$/;
  return !!(
    Number.isInteger(parseInt(enteredValue, 10)) && re.test(enteredValue)
  );
};

const EditableTableCell = ({dataIndex, handleSave, record}) => {
  const containerRef = useRef();
  const isClickInsideCell = useRef(false);
  const inputRef = useRef();

  // UI cell state
  const [showDropDown, setShowDropdown] = useState(false);

  // Selected reason code state
  const [reasonCodes, setReasons] = useState({});

  const [itemComments, setItemComments] = useState("");
  // values from ForecastApi

  const dataPageValue = record?.[`${dataIndex}-dataPageValue`];

  // savedValues from ForecastApi response
  const savedValue = record?.[dataIndex] ?? 0;

  // Input field visible to the user, once edited we send this value as saved value
  const [displayValue, setDisplayValue] = useState(savedValue);

  // Prior year
  const priorYear = record?.priorYear;

  // Prior year value
  const priorYearValue = record?.[`${dataIndex}-priorValue`];

  // Month over month value
  const monthOverMonthValue = record?.[`${dataIndex}-monthOverMonth`];

  // 5 year weighted average
  const weightedAverage = record?.[`${dataIndex}-weightedAverage`];

  // UI input field Error state
  const errorStatus =
    record?.updatedForecast?.[dataIndex]?.errorStatus ?? false;

  // arrow widget value
  const variance = dataPageValue - displayValue;
  const variancePercentage = useMemo(() => {
    return calculateVariancePercentage(variance, displayValue, dataPageValue);
  }, [dataPageValue, displayValue, variance]);

  // click event listener in the cell wrapper
  useEffect(() => {
    const handleClick = () => {
      if (!isClickInsideCell.current) setShowDropdown(false);

      isClickInsideCell.current = false;
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    if (
      record?.updatedForecast &&
      record?.updatedForecast[dataIndex] &&
      record?.updatedForecast[dataIndex]?.value !== undefined
    ) {
      if (record?.updatedForecast[dataIndex]?.reasonCodes?.length) {
        const reasonsFromRecord = record?.updatedForecast[
          dataIndex
        ]?.reasonCodes.reduce((acc, reason) => {
          acc[reason] = true;
          return acc;
        }, {});

        setReasons(reasonsFromRecord);
      }
      setItemComments(record?.updatedForecast[dataIndex]?.itemComments || "");
      setDisplayValue(record?.updatedForecast[dataIndex]?.value);
    }

    if (record?.updatedForecast && !record?.updatedForecast[dataIndex]) {
      setDisplayValue(record[dataIndex]);
    }
  }, [record, dataIndex]);

  const handleUpdate = (newValue, dataIdx) => {
    const parsedValue = newValue == null ? 0 : parseInt(newValue, 10);
    const selectedReasonsArray = getSelectedReasonCodes(reasonCodes);

    if (Number.isNaN(parsedValue)) {
      return;
    }

    if (savedValue === parsedValue && selectedReasonsArray.length === 0) {
      delete record?.updatedForecast?.[dataIndex];
      handleSave(record, dataIndex);
      return;
    }

    record.updatedForecast = record.updatedForecast ?? {};
    record.updatedForecast[dataIdx] = record.updatedForecast[dataIdx] ?? {};
    record.updatedForecast[dataIdx].value = parsedValue;
    record.updatedForecast[dataIdx].reasonCodes = selectedReasonsArray;
    record.updatedForecast[dataIdx].itemComments = itemComments;
    record.updatedForecast[dataIdx].errorStatus =
      (!selectedReasonsArray.length &&
        (displayValue !== "0" || displayValue !== null)) ||
      ((parseInt(displayValue, 10) === savedValue ||
        parsedValue === savedValue) &&
        itemComments !== "" &&
        !selectedReasonsArray.length);

    handleSave(record, dataIndex);
  };

  const handleInputBlur = ({target: {value: inputVal}}) => {
    if (inputVal === "" || inputVal === "-") {
      // reset cell state previous state when user edit is not valid
      setDisplayValue(savedValue);

      if (record?.updatedForecast?.[dataIndex]) {
        if (
          record?.updatedForecast?.[dataIndex]?.itemComments?.length ||
          record?.updatedForecast?.[dataIndex]?.reasonCodes?.length
        ) {
          record.updatedForecast[dataIndex] = {
            ...record.updatedForecast[dataIndex],
            value: savedValue,
          };
        } else {
          delete record.updatedForecast[dataIndex];
        }
      }
      handleSave(record, dataIndex);
    }

    if (
      validateUserInput(displayValue) &&
      parseInt(displayValue, 10) !== savedValue
    ) {
      const parsedValue = displayValue == null ? 0 : parseInt(displayValue, 10);

      setDisplayValue(parsedValue);
      handleUpdate(parsedValue, dataIndex);
    }

    return null;
  };

  const handleReasonChange = ({target}) => {
    const commentsValue =
      target.name === "comments" ? target.value : itemComments;

    setItemComments(commentsValue);

    const updatedReasons = {
      ...reasonCodes,
      ...(target.name !== "comments"
        ? {[`${target.name}`]: target.checked}
        : {}),
    };
    const reasonCodesArray = getSelectedReasonCodes(updatedReasons);

    setReasons(updatedReasons);

    if (
      savedValue === parseInt(displayValue, 10) &&
      reasonCodesArray.length === 0 &&
      commentsValue === ""
    ) {
      delete record.updatedForecast[dataIndex];
    } else {
      if (!record.updatedForecast || !record.updatedForecast?.[dataIndex]) {
        record.updatedForecast = record.updatedForecast ?? {};
        record.updatedForecast[dataIndex] =
          record.updatedForecast[dataIndex] ?? {};

        if (displayValue !== null) {
          record.updatedForecast[dataIndex].value = parseInt(displayValue, 10);
        }
      }

      record.updatedForecast[dataIndex].reasonCodes = reasonCodesArray;
      record.updatedForecast[dataIndex].itemComments = commentsValue;
      record.updatedForecast[dataIndex].errorStatus =
        !reasonCodesArray.length &&
        (parseInt(displayValue, 10) !== savedValue ||
          (parseInt(displayValue, 10) === savedValue &&
            commentsValue !== "")) &&
        displayValue !== "0" &&
        savedValue != null;
    }
    handleSave(record, dataIndex);
  };

  const handleSystemAdjust = (value) => {
    const systemAdjustTarget = {
      target: {name: "system_adjust"},
    };
    const parsedValue = parseInt(value, 10);
    if (validateUserInput(parsedValue) && dataPageValue - parsedValue !== 0) {
      if (reasonCodes.system_adjust) {
        systemAdjustTarget.target.checked = false;
        handleReasonChange(systemAdjustTarget);
      }
    } else if (
      validateUserInput(parsedValue) &&
      dataPageValue - parsedValue === 0 &&
      savedValue !== parsedValue
    ) {
      if (!reasonCodes.system_adjust) {
        systemAdjustTarget.target.checked = true;
        handleReasonChange(systemAdjustTarget);
      }
    }
  };

  const handleInputChange = (values, sourceInfo) => {
    const {value} = values;
    const {type} = sourceInfo?.event?.target || "";

    const parsedValue = value == null ? 0 : parseInt(value, 10);

    setDisplayValue(value);

    // When user manually changes the value and set appropriate system adjust selection
    handleSystemAdjust(value);

    // When user manually changes the value and value is equal to previous saved value then clear error state.
    if (
      validateUserInput(value) &&
      parsedValue === savedValue &&
      record?.updatedForecast?.[dataIndex]?.errorStatus
    ) {
      record.updatedForecast[dataIndex].errorStatus = false;

      // send value to the Unpublished page to update total Obligation data and graph data
      handleUpdate(parsedValue, dataIndex);
    } else if (
      validateUserInput(value) &&
      type === "system_adjust" &&
      record?.updatedForecast?.[dataIndex]?.value != null
    ) {
      record.updatedForecast[dataIndex].value = parsedValue;

      // send value to the Unpublished page to update total Obligation data and graph data
      handleSave(record, dataIndex);
    }
    return null;
  };

  const handleMenuBlur = (e) => {
    e.preventDefault();

    if (displayValue !== savedValue) {
      handleUpdate(displayValue, dataIndex);
    }
  };

  const resetCommentsText = useRef(false);
  const isUnsavedEditValid =
    record?.updatedForecast?.[dataIndex] && !errorStatus;
  const handleUndo = (e) => {
    e.preventDefault();

    if (record && record.updatedForecast && record.updatedForecast[dataIndex]) {
      delete record.updatedForecast[dataIndex];
      handleSave(record, dataIndex);
      setReasons({});
      setItemComments("");
      setDisplayValue(savedValue);

      // this was needed to reset the comments section, thought this might reset it --> setItemComments(""); but it doesn't
      resetCommentsText.current = true;
    }
  };

  const MAX_VAL = 999999999;
  const MIN_VAL = -999999999;
  const withValueLimit = ({formattedValue, floatValue}) => {
    if (floatValue == null) {
      return formattedValue === "" || formattedValue === "-";
    }
    return floatValue <= MAX_VAL && floatValue >= MIN_VAL;
  };

  useEffect(() => {
    if (displayValue && isClickInsideCell.current && variance === 0) {
      // sets cursor position when value adjusted by system/arrow widget
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        displayValue.length,
        displayValue.length,
      );
    }
  }, [displayValue, variance]);

  useEffect(() => {
    if (resetCommentsText.current === true && !isUnsavedEditValid) {
      resetCommentsText.current = false;
    }
  }, [isUnsavedEditValid, resetCommentsText]);

  const updateVariance = (event, changedValue) => {
    // check if display value is null before calculation
    const updatedValue =
      displayValue == null
        ? 0 + changedValue
        : parseInt(displayValue, 10) + changedValue;
    setDisplayValue(updatedValue);

    const sourceInfo = {
      event: {target: {type: "system_adjust"}},
    };
    const values = {value: updatedValue.toString()};
    handleInputChange(values, sourceInfo);
  };

  // passed as a prop to Dropdown component
  const reasonCodesMenu = (
    <Menu
      multiple
      data-test="forecast-dropdown"
      onBlur={(e) => handleMenuBlur(e)}
    >
      <Menu.Item
        key="user-values"
        className="forecast-dropdown-metrics p-0 text-[#89888c] !w-full font-normal !bg-white cursor-default"
      >
        <div className="flex flex-wrap justify-between text-sm col-span-2 w-1/2 pl-3">
          <span>{`${priorYear} VAL:`}</span>
          <span className="pr-5">{`${formatDisplayValue(
            priorYearValue,
          )}`}</span>
        </div>
        <div className="flex flex-wrap justify-between text-sm col-span-1 pl-3">
          <span>{DROPDOWN_TREND_LABELS.FIVE_YEAR_AVG}:</span>
          <span className="pr-4">{`${formatDisplayValue(
            weightedAverage,
          )}`}</span>
        </div>
        <div className="flex flex-wrap justify-between text-sm col-span-1">
          <span>{DROPDOWN_TREND_LABELS.MOM_TREND}:</span>
          <span className="pr-4">{`${formatDisplayValueWithSign(
            monthOverMonthValue,
            "%",
          )}`}</span>
        </div>
      </Menu.Item>
      <div className="grid grid-cols-2 gap-x-3">
        {Object.keys(UPDATE_REASONS).map((key) => {
          const reason = key.toLowerCase();
          return (
            <Menu.Item
              key={reason}
              selectable="false"
              className={`forecast-action-reason-${reason} flex justify-between py-0 odd:pl-3 even:pl-0 col-span-1 !bg-white`}
            >
              <ReasonCodeItem
                checked={reasonCodes[reason]}
                onChange={handleReasonChange}
                reasonName={reason}
                label={UPDATE_REASONS[key]}
                disabled={reason === "system_adjust"}
              />
            </Menu.Item>
          );
        })}
      </div>

      <DebouncedTextArea
        className="w-full"
        keyValue="comments"
        value={itemComments}
        placeholder={PLACEHOLDER_TEXT.ADD_COMMENTS}
        onChange={handleReasonChange}
        reset={resetCommentsText.current}
      />
    </Menu>
  );

  const handleKeyDown = useCallback((e) => {
    // when user presses the tab key, we hide the dropdown menu
    if (e.keyCode === 9) {
      setShowDropdown(false);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className="editable-cells flex flex-nowrap flex-row justify-center ml-6"
      onClickCapture={() => {
        isClickInsideCell.current = true;
      }}
      data-test="unpublished-table-cell"
    >
      <div className="editable-input-wrapper relative">
        <NumericFormat
          thousandSeparator
          decimalScale={0}
          isNumericString
          isAllowed={withValueLimit}
          getInputRef={inputRef}
          className={`editable-cell-input h-6 w-32 ${errorStatus && "error"}`}
          type="text"
          data-test="editable-table-cell"
          style={{
            borderTopLeftRadius: "5px",
            borderBottomLeftRadius: "5px",
            borderTopRightRadius: showDropDown ? "0px" : inputRightRadius,
            borderBottomRightRadius: showDropDown ? "0px" : inputRightRadius,
            border: isUnsavedEditValid
              ? "1px solid #077e00"
              : "1px solid #b2b2b5",
            color: "#89888c",
            paddingLeft: "0.25rem",
            outline: "none",
          }}
          value={displayValue ?? 0}
          onClick={() => setShowDropdown(true)}
          onFocus={() => setShowDropdown(true)}
          onValueChange={(values, sourceInfo) =>
            handleInputChange(values, sourceInfo)
          }
          onBlur={(e) => handleInputBlur(e)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        {errorStatus && (
          <span
            role="alert"
            className="py-1 text-xs text-[#fc532e] absolute top-5 left-0"
          >
            {errorStatus === true ? ERRORS.ADD_REASON_CODE : null}
          </span>
        )}
        {isUnsavedEditValid && (
          <UndoOutlined
            className={`absolute top-1 right-1 rotate-180 hover:rotate-45 ${
              isUnsavedEditValid ? "visible" : "hidden"
            }`}
            data-test="undo-edit-button"
            style={{
              fontSize: 16,
            }}
            onClick={handleUndo}
          />
        )}
      </div>
      <Dropdown
        overlayClassName="forecast-dropdown-menu w-80"
        visible={showDropDown}
        overlay={reasonCodesMenu}
        placement="bottomRight"
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <button
          type="button"
          data-test="row-actions-options"
          className="forecast-dropdown w-6 bg-gray-800 justify-self-start"
          style={{
            visibility: showDropDown ? "visible" : "hidden",
          }}
          onClick={() => setShowDropdown(!showDropDown)}
        >
          <EllipsisOutlined
            style={{color: "white", fontSize: 20}}
            rotate="90"
          />
        </button>
      </Dropdown>

      <div
        style={{
          maxWidth: "4rem",
          minWidth: "75px",
        }}
        className={`grow -ml-2 lg:-ml-3 ${
          variance ? "cursor-pointer" : "cursor-default"
        } `}
      >
        <ForecastArrowWidget
          changedValue={variance}
          updateVariance={updateVariance}
          isVarianceOver4Percent={
            variancePercentage > 4 || variancePercentage < -4
          }
        />
      </div>
    </div>
  );
};

EditableTableCell.propTypes = {
  dataIndex: PropTypes.string,
  handleSave: PropTypes.func,
  record: PropTypes.object,
};

export default EditableTableCell;
