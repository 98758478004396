import PropTypes from "prop-types";
import moment from "moment";
import {useFormContext} from "react-hook-form";
import {useState, useEffect} from "react";
import {useQuery} from "@apollo/client";

import {SUMMARY_FILTER_OPTIONS} from "graphql/gy-enablement/MOT/summaryDashboard";
import SelectFilter from "modules/common/Filter/SelectFilter";
import DateFilter from "modules/common/Filter/DateFilter";
import * as FilterFunctions from "./filterFunctions";

const CeemasValidationFilterContent = ({
  dataItems,
  dataItemsloading,
  defaultValues,
  filterExpanded,
}) => {
  const {watch, setValue} = useFormContext();

  const watchCompanyCode = watch("company");
  const watchYear = watch("complianceyear");
  const watchMonth = watch("compliancemonth");
  const watchPlant = watch("plant");
  const watchEDGroup = watch("ed");
  const watchBalGroup = watch("balancegroup");
  const watchMaterial = watch("material");
  const watchLegalentity = watch("legalentity");
  const watchMotProfile = watch("motProfile");

  const {
    data: summaryFilterOptions,
    loading: summaryFilterOptionsLoading,
    refetch: refetchsummaryFilterOptions,
  } = useQuery(SUMMARY_FILTER_OPTIONS, {
    variables: {
      companyCode: watchCompanyCode,
      year: moment(watchYear).format("YYYY"),
      balanceGroupNumber: "",
      month: "",
      material: "",
      eDGroup: "",
      plant: "",
      legalEntity: "",
      motProfile: watchMotProfile ?? "",
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    refetchsummaryFilterOptions();
  }, [watchCompanyCode, watchYear, refetchsummaryFilterOptions]);

  const data = summaryFilterOptions?.getFilteredOptionsRequest;

  const [filterOptionDataPlant, setfilterOptionDataPlant] = useState(
    data?.filter(
      (o) => o.companyCode === watchCompanyCode && o.year === watchYear,
    ),
  );

  const [filterOptionDataLegalEntity, setfilterOptionDataLegalEntity] =
    useState(
      data?.filter(
        (o) => o.companyCode === watchCompanyCode && o.year === watchYear,
      ),
    );

  const [filterOptionDataEDGroup, setfilterOptionDataEDGroup] = useState(
    data?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === watchYear &&
        o.plant === (watchPlant ?? o.plant),
    ),
  );
  const [filterOptionDataBalGroup, setfilterOptionDataBalGroup] = useState(
    data?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === watchYear &&
        o.plant === watchPlant &&
        o.eDGroup === watchEDGroup,
    ),
  );
  const [filterOptionDataMaterial, setfilterOptionDataMaterial] = useState(
    data?.filter(
      (o) =>
        o.companyCode === watchCompanyCode &&
        o.year === watchYear &&
        o.plant === watchPlant &&
        o.eDGroup === watchEDGroup &&
        o.balanceGroupNumber === watchBalGroup,
    ),
  );

  useEffect(() => {
    const filteredLegalEntity = FilterFunctions.getFilteredLegalEntity(data, {
      watchCompanyCode,
      watchYear,
      watchMonth,
      watchPlant,
      watchEDGroup,
      watchBalGroup,
      watchMaterial,
    });
    setfilterOptionDataLegalEntity(filteredLegalEntity);

    /// legal entity options End

    const filteredPlant = FilterFunctions.getFilteredPlant(data, {
      watchCompanyCode,
      watchYear,
      watchMonth,
      watchEDGroup,
      watchBalGroup,
      watchMaterial,
      watchLegalentity,
    });
    setfilterOptionDataPlant(filteredPlant);
    /// plant options End

    const filteredEDGroup = FilterFunctions.getFilteredEDGroup(data, {
      watchCompanyCode,
      watchYear,
      watchMonth,
      watchPlant,
      watchBalGroup,
      watchMaterial,
      watchLegalentity,
    });
    setfilterOptionDataEDGroup(filteredEDGroup);
    /// ED Group options End

    const filteredBalGroup = FilterFunctions.getFilteredBalanceGroup(data, {
      watchCompanyCode,
      watchYear,
      watchMonth,
      watchPlant,
      watchEDGroup,
      watchMaterial,
      watchLegalentity,
    });
    setfilterOptionDataBalGroup(filteredBalGroup);
    /// Bal Group options End

    const filteredMaterials = FilterFunctions.getFilteredMaterial(data, {
      watchCompanyCode,
      watchYear,
      watchMonth,
      watchPlant,
      watchEDGroup,
      watchBalGroup,
      watchLegalentity,
    });
    setfilterOptionDataMaterial(filteredMaterials);
    /// Material options End
  }, [
    data,
    watchCompanyCode,
    watchYear,
    watchMonth,
    watchPlant,
    watchEDGroup,
    watchBalGroup,
    watchMaterial,
    watchLegalentity,
  ]);

  useEffect(() => {
    if (
      filterOptionDataLegalEntity?.length === 1 &&
      watchPlant !== "" &&
      watchPlant !== undefined &&
      watchPlant !== null
    ) {
      setValue("legalentity", filterOptionDataLegalEntity[0]?.legalEntity);
    }
  }, [filterOptionDataLegalEntity, watchPlant, setValue]);

  return (
    <div className="grid w-full gap-4 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
      <SelectFilter
        label="Company Code"
        placeholder="Select"
        name="company"
        disabled={dataItemsloading}
        loading={dataItemsloading}
        options={dataItems?.companyCode?.values.map((str) => ({
          value: str,
          label: str,
        }))}
        data-test="company-code-filter"
      />

      <DateFilter
        allowClear={false}
        label="Compliance Year"
        name="complianceyear"
        picker="year"
        placeholder="Pick year..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        data-test="compliance-year-filter"
      />
      <DateFilter
        noHeader
        label="Month"
        name="compliancemonth"
        picker="month"
        format="MMM"
        placeholder="Pick month..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        data-test="compliance-month-filter"
      />
      <SelectFilter
        label="Legal Entity"
        placeholder="Select"
        name="legalentity"
        loading={summaryFilterOptionsLoading}
        disabled={watchPlant || summaryFilterOptionsLoading}
        defaultValue={watchLegalentity}
        options={[
          ...new Set(
            filterOptionDataLegalEntity
              ?.filter((o) => o.legalEntity !== null)
              ?.map((q) => {
                return {
                  legalEntity: q.legalEntity,
                  legalEntityName: q.legalEntity,
                };
              }),
          ),
        ]
          ?.sort((a, b) => a.legalEntityName - b.legalEntityName)
          ?.map((option) => ({
            value: option.legalEntity,
            label: option.legalEntityName,
          }))}
        data-test="legal-entity-filter"
      />
      <SelectFilter
        showSearch
        optionFilterProp="label"
        label="Profile"
        placeholder="No Filter"
        name="motProfile"
        loading={dataItemsloading}
        options={dataItems?.motProfiles?.values.map((str) => ({
          value: str,
          label: str,
        }))}
        data-test="motprofile-filter"
      />
      <SelectFilter
        formFieldProps={{
          hidden: !filterExpanded,
        }}
        showSearch
        optionFilterProp="label"
        label="Plant"
        name="plant"
        placeholder="Type to search..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        options={[
          ...new Set(
            filterOptionDataPlant?.map((q) => {
              return {plant: q.plant, plantName: `${q.plant} - ${q.plantName}`};
            }),
          ),
        ]
          ?.sort((a, b) => a.plant - b.plant)
          ?.map((option) => ({
            value: option.plant,
            label: option.plantName,
          }))}
        data-test="plant-filter"
      />

      <SelectFilter
        formFieldProps={{
          hidden: !filterExpanded,
        }}
        showSearch
        optionFilterProp="label"
        label="ED Group"
        name="ed"
        placeholder="Type to search..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        options={[
          ...new Set(
            filterOptionDataEDGroup?.map((q) => {
              return {
                eDGroup: q.eDGroup,
                eDGroupName: `${q.eDGroup} - ${q.edGroupName}`,
              };
            }),
          ),
        ]
          ?.sort((a, b) => a.eDGroup - b.eDGroup)
          ?.map((option) => ({
            value: option.eDGroup,
            label: option.eDGroupName,
          }))}
        data-test="ed-group-filter"
      />
      <SelectFilter
        formFieldProps={{
          hidden: !filterExpanded,
        }}
        showSearch
        optionFilterProp="label"
        label="Balance Group"
        name="balancegroup"
        placeholder="Type to search..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        options={[
          ...new Set(
            filterOptionDataBalGroup?.map((q) => {
              return {
                balanceGroupNumber: q.balanceGroupNumber,
                balanceGroupName: `${q.balanceGroupNumber} - ${q.balanceGroupName}`,
              };
            }),
          ),
        ]
          ?.sort((a, b) => a.balanceGroupNumber - b.balanceGroupNumber)
          ?.map((option) => ({
            value: option.balanceGroupNumber,
            label: option.balanceGroupName,
          }))}
        data-test="bal-group-filter"
      />
      <SelectFilter
        formFieldProps={{
          hidden: !filterExpanded,
        }}
        showSearch
        optionFilterProp="label"
        label="Material"
        name="material"
        placeholder="Type to search..."
        disabled={summaryFilterOptionsLoading}
        loading={summaryFilterOptionsLoading}
        options={[
          ...new Set(
            filterOptionDataMaterial?.map((q) => {
              return {
                material: q.material,
                materialDesc: `${q.material} - ${q.materialDesc}`,
              };
            }),
          ),
        ]
          ?.sort((a, b) => a.material - b.material)
          ?.map((option) => ({
            value: option.material,
            label: option.materialDesc,
          }))}
        data-test="material-filter"
      />
    </div>
  );
};

CeemasValidationFilterContent.propTypes = {
  dataItems: PropTypes.object,
  dataItemsloading: PropTypes.bool,
  defaultValues: PropTypes.object,
  filterExpanded: PropTypes.bool,
};

export default CeemasValidationFilterContent;
