import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {PRODUCT_DETAILS} from "graphql/config-center/ProductDetails.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {PRODUCT_DETAILS_COLUMNS} from "../components/Table/Columns.js";

const ProductDetails = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => PRODUCT_DETAILS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(PRODUCT_DETAILS);

  return (
    <RTable
      columns={columns}
      data={loading ? [] : data?.bioLcCoproConfigCenterProductDetails.Records}
      tableName="Product details"
    />
  );
};
export default ProductDetails;
