import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {actionConfig, linkAction} from "../utils";
import ModalToShow from "./ModalToShow";

import {actionItems, status} from "../constants";

const ActionDropdown = ({
  row,
  table,
  navigateToAllocation,
  currentFilterValues,
}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");
  const {division} = useParams();
  const divisionCode = division.toUpperCase();

  const rowStatus = row?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId ?? null;

  const finalRowStatus =
    rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null
      ? status.AVAILABLE_FROM_TRANSFER
      : rowStatus;

  const navigate = useNavigate();

  const ACTIONS = useMemo(
    () =>
      Object.values(actionItems).map((action) => ({
        name: action,
        isVisible:
          actionConfig[finalRowStatus + divisionCode]?.visible.includes(action),
        isDisabled:
          !actionConfig[finalRowStatus + divisionCode]?.enabled.includes(
            action,
          ),
        action: () =>
          linkAction(
            divisionCode,
            setActionModalVisible,
            action,
            row,
            table,
            navigate,
            navigateToAllocation,
          ),
      })),
    [finalRowStatus, divisionCode, row, table, navigate, navigateToAllocation],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <ModalToShow
          modal={actionModalVisible}
          rowData={row}
          divisionCode={divisionCode}
          currentFilterValues={currentFilterValues}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  navigateToAllocation: PropTypes.func,
  currentFilterValues: PropTypes.object,
};

export default ActionDropdown;
