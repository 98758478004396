import React, {useState} from "react";
import {useParams} from "react-router-dom";
import {Button} from "reactstrap";
import SandboxExecutionResults from "../components/SandboxExecution/SandboxExecutionResults";

const breadcrumbItems = [
  {text: "BioVerse", link: "/"},
  {text: "Compliance Rules Engine", link: "/compliance-rules-engine/:country"},
  {text: "Rule details", link: "/rules-page"},
];

const RuleVersionTestPage = () => {
  const {version} = useParams();
  const [testClicked, setTestClicked] = useState(false);
  console.log(`ruleVersionId: ${version}`);
  if (!testClicked) {
    return (
      <div className="overflow-x-auto">
        <Button
          color="primary"
          type="secondary"
          className="float-right rounded-0 text-gray-400"
          id="addRuleButton"
          size="md"
          onClick={() => setTestClicked(true)}
        >
          Test
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-stretch w-full items-start px-7">
      <SandboxExecutionResults ruleVersionId={version} />
    </div>
  );
};

export default RuleVersionTestPage;
