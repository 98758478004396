import Menu from "modules/common/Menu";
import {ErrorModalBasic} from "modules/common/StatusModals/ErrorModal";
import PropTypes from "prop-types";
import {useState} from "react";
import {toast} from "react-toastify";

const ApiCSVItem = ({data, onDownload}) => {
  const [exportError, setExportError] = useState(false);

  const downloadFile = (response) => {
    const fileUrl = response?.data?.url;
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleCSVDownload = async () => {
    if (data.length > 0) {
      setExportError(false);
      const response = await onDownload(data);
      console.log(response);
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          toast.error(response?.errors?.[0]?.message);
          break;
        default:
          // Theorically succesfully
          downloadFile(response);
      }
    } else {
      setExportError(true);
    }
  };

  return (
    <>
      <ErrorModalBasic
        visible={exportError}
        title="No data is available to export"
        onCancel={() => setExportError(false)}
      />
      <Menu.Item
        key="table-action-download-csv"
        data-test="table-action-download-csv"
        onClick={handleCSVDownload}
      >
        Export/Download to CSV
      </Menu.Item>
    </>
  );
};

ApiCSVItem.propTypes = {
  data: PropTypes.array.isRequired,
  onDownload: PropTypes.func.isRequired,
};

export default ApiCSVItem;
