export const BUTTONS = {
  SAVE: "Save",
  SAVE_NOTES: "Save notes",
};

export const BulkAdjustmentShipmentHeaders = {
  shipment_status: "Status",
  shipment_id: "Shipment ID",
  shipment_type: "Type",
  estimated_volume: "Total estimated shipment volume",
  shipment_volume: "Actual shipment volume (bbl)",
};

export const TABLE_LABELS = {
  BATCHES: "Batches",
  SHIPMENTS: "Shipments",
  REMAINING_VOL: "Remaining volume",
};

export const TOOLTIP_LABELS = {
  TANK_NUMBER: "Tank no",
  TOTAL_VOL: "Total volume",
  REMAINING_VOL: "Remaining volume",
  ENDING_INVENTORY: "Ending inventory",
  COMPLETE: "% COMPLETE",
};

export const POPOVER_LABELS = {
  SHIPMENT_DATE: "Shipment date",
  VOLUME_ALLOCATED: "Volume allocated",
  NOTES: "Notes",
};

export const METRICS = {
  BBL: "bbl",
};

export const SHIPMENTS_TABLE_COLUMNS = [
  "Status",
  "Shipment ID",
  "Type",
  "Total Estimated Volume",
  "Actual Volume (bbl)",
];
