const divideByThousand = (value) => {
  return Math.round(value / 1000, 10);
};

const displayDividedValue = (value) => {
  return divideByThousand(value);
};

const sumBarrelValues = (values, conversion = false) => {
  let sum = 0;
  values.forEach((value) => {
    sum += value;
  });
  sum = conversion ? divideByThousand(sum) : sum;
  return sum ?? 0;
};

export default {
  displayDividedValue,
  sumBarrelValues,
};
