import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {Table, Grid} from "antd";
import moment from "moment";
import {FIELD_LABELS_BY_REGION} from "modules/forecasting/constants/inventory";
import ArrowIndicatorWithTooltip from "modules/forecasting/components/ArrowIndicatorWithTooltip";
import {
  showPublishedVariance,
  showDataVariance,
  currentYear,
  currentMonth,
} from "../helpers/dateUtils";
import {isMonthTitleRow, isTotalRow} from "../helpers/tableUtils";

//  ============================ helper methods ============================ //

const isNotNull = (v) => v !== null && v !== undefined;
export const formatDisplayValue = (value) =>
  isNotNull(value) ? value?.toLocaleString("en-US") : "";
export const formatDisplayValueWithSign = (value, sign) =>
  isNotNull(value) ? `${value?.toLocaleString("en-US")}${sign}` : "";
const dateRegexYYYYMM = /^(?:[0-9]{2})?[0-9]{2}-(1[0-2]|0?[1-9])$/;
const getDateKeys = (o) =>
  Object.keys(o || {}).filter((key) => dateRegexYYYYMM.test(key));
const formatDateYYYYMM = ({year, month} = {}) => `${year}-${month}`;
const formatMonthTitle = (month) =>
  moment(month, "MM").format("MMM").toLocaleUpperCase();

//  ================================== Columns ==================================== //
const onCellWithMonthTitle = (row) => {
  if (row.key?.includes("title")) {
    return {
      style: {
        backgroundColor: "rgb(249 249 249)",
        borderBottom: "1px solid rgb(210, 210, 212)",
      },
    };
  }

  return {};
};

const emptyResponsiveCols = [
  {
    key: "empty-cell-responsive",
    responsive: ["xs"],
    width: 20,
    onCell: onCellWithMonthTitle,
  },
  {
    key: "empty-cell-responsive",
    responsive: ["sm"],
    width: 40,
    onCell: onCellWithMonthTitle,
  },
  {
    key: "empty-cell-responsive",
    responsive: ["xl"],
    width: 80,
    onCell: onCellWithMonthTitle,
  },
];

// generates measurements label column,
const getMeasurementsColumn = (displayTwelveColumns) => ({
  align: "left",
  dataIndex: "measurement",
  fixed: "left",
  width: 150,
  onCell: (row) => {
    const style = {
      paddingLeft: 20,
      paddingRight: 8,
      fontSize: 16,
      zIndex: 20,
      whiteSpace: "nowrap",
    };

    if (isMonthTitleRow(row)) {
      return {
        style: {...style, backgroundColor: "#fafafa"},
      };
    }

    if (isTotalRow(row)) {
      return {
        style: {...style, fontWeight: 900, color: "#3f3e45"},
      };
    }

    if (displayTwelveColumns) {
      return {
        style: {
          ...style,
          fontSize: 15,
        },
      };
    }

    return {style, className: `measurement-label-${row.key}`};
  },

  render: (value, row) => {
    if (isMonthTitleRow(row) || isTotalRow(row)) {
      return (
        <h3
          className="text-lg whitespace-nowrap month-title-row"
          data-test={`measurement-label-${row.key}`}
        >
          {value}
        </h3>
      );
    }

    return value;
  },
});

const getFourMonthViewColumns = (dataIndex, index, screenSize = {}) => ({
  align: "center",
  className: `monthly-forecast-column-${index + 1}`,
  dataIndex,
  onCell: (row) => {
    if (isMonthTitleRow(row)) {
      return {
        style: {
          fontSize: 16,
          ...(!screenSize.xs &&
            screenSize.lg && {
              paddingRight: 10,
            }),
        },
      };
    }

    if (isTotalRow(row)) {
      return {
        style: {
          fontSize: 16,
          fontWeight: 900,
          color: "#3f3e45",
          ...(!screenSize.xs &&
            screenSize.lg && {
              paddingRight: 10,
            }),
        },
      };
    }

    return {
      style: {
        fontSize: 16,
        justifyContent: "center",
        ...(!screenSize.xs &&
          screenSize.lg && {
            paddingRight: 10,
          }),
      },
    };
  },
  render: (value, row) => {
    //  Column Headers
    if (isMonthTitleRow(row)) {
      return (
        <div
          data-test={`column-${index + 1}-month-${row.key}`}
          className="whitespace-nowrap justify-center grid grid-cols-[120px_50px]"
        >
          <h3 className="text-right font-black">{value}</h3>
        </div>
      );
    }
    // Product Rows & Total Rows
    const variance = row?.[`${dataIndex}_variance`] || 0;
    return (
      <div
        data-test={`column-${index + 1}-${row.key}`}
        className={`whitespace-nowrap justify-center grid grid-cols-[120px_50px]
      }`}
      >
        <div
          data-test={`column-${index + 1}-${row.key}-cell-value`}
          className={`text-right ${dataIndex}-month-column`}
        >
          {formatDisplayValue(value).toLocaleString()}
        </div>
        {showDataVariance(dataIndex) && (
          // we don't display variance greater than three months on data page
          <ArrowIndicatorWithTooltip
            obligationDate={dataIndex}
            row={row}
            variance={variance}
          />
        )}
      </div>
    );
  },

  width: !screenSize.xs && screenSize.lg ? 160 : 120,
});

const getTwelveMonthViewColumns = (
  dataIndex,
  index,
  showCurrentMonthVariance = false,
) => ({
  align: "right",
  className: `monthly-forecast-column-${index + 1}`,
  dataIndex,
  onCell: (row) => {
    if (isMonthTitleRow(row)) {
      return {
        style: {
          fontSize: 14,
        },
      };
    }

    if (isTotalRow(row)) {
      return {
        style: {
          fontSize: 14,
          fontWeight: 900,
          color: "#3f3e45",
        },
      };
    }

    return {
      style: {
        fontSize: 14,
        justifyContent: "center",
      },
    };
  },

  render: (value, row) => {
    const cellStyleWithoutIndicator = "grid-cols-[100px_0px]";
    const cellStyleWithIndicator = "grid-cols-[100px_70px]";

    //  Table Headers
    if (isMonthTitleRow(row)) {
      return (
        <div
          style={{
            minWidth: "80px",
          }}
          data-test={`column-${index + 1}-month-${row.key}`}
          className={`font-black whitespace-nowrap justify-center grid ${
            showPublishedVariance(dataIndex, showCurrentMonthVariance)
              ? cellStyleWithIndicator
              : cellStyleWithoutIndicator
          }`}
        >
          <h3 className="text-right font-black">{value}</h3>
        </div>
      );
    }
    const variance = row[`${dataIndex}_variance`] || 0;

    return (
      <div
        data-test={`column-${index + 1}-${row.key}`}
        className={`whitespace-nowrap justify-center grid ${
          showPublishedVariance(dataIndex, showCurrentMonthVariance)
            ? cellStyleWithIndicator
            : cellStyleWithoutIndicator
        }`}
      >
        <div
          data-test={`column-${index + 1}-${row.key}-published-value`}
          className={`text-right ${dataIndex}-month-column`}
        >
          {formatDisplayValue(value)}
        </div>

        {showPublishedVariance(dataIndex, showCurrentMonthVariance) && (
          // we don't display the variance on Published page for future months
          <ArrowIndicatorWithTooltip
            obligationDate={dataIndex}
            row={row}
            variance={variance}
            isPublishedView
          />
        )}
      </div>
    );
  },
});

const getFourMonthlyColumns = (dateKeyList, screenSize) =>
  dateKeyList.map((dateKey, index) =>
    getFourMonthViewColumns(dateKey, index, screenSize),
  );

const getPublishedViewColumns = (dateKeyList, showCurrentMonthVariance) =>
  dateKeyList.map((dateKey, index) =>
    getTwelveMonthViewColumns(dateKey, index, showCurrentMonthVariance),
  );

const buildColumns = (
  {0: firstRow, length},
  screenSize,
  isPublishedPage,
  showCurrentMonthVariance,
) => {
  const dateKeys = getDateKeys(firstRow);

  if (length === 0) return [];

  if (isPublishedPage) {
    return [
      getMeasurementsColumn(isPublishedPage),
      ...getPublishedViewColumns(dateKeys, showCurrentMonthVariance),
      {
        key: "empty-cell-responsive",
        responsive: ["sm"],
        width: 30,
        onCell: onCellWithMonthTitle,
      },
    ];
  }

  return [
    getMeasurementsColumn(),
    ...getFourMonthlyColumns(dateKeys, screenSize),
    ...emptyResponsiveCols,
  ];
};

const computeRowData = (rowKey, yearlyData) => {
  return yearlyData.reduce((memo, monthData) => {
    const {date, ...valuesForEachMonth} = monthData;
    const columnKey = formatDateYYYYMM(date);
    const productDataPerMonth = valuesForEachMonth?.[rowKey] || {};

    // Month Title rows
    if (rowKey.includes("title")) {
      return {
        ...memo,
        [columnKey]: formatMonthTitle(date?.month) || "",
      };
    }

    // forecast variance
    const variance = productDataPerMonth?.variance ?? null;
    const variancePercent = productDataPerMonth?.variancePercentage ?? 0;
    // Product rows
    return {
      ...memo,
      [columnKey]: productDataPerMonth?.actualValue ?? null,
      [`${columnKey}_systemValue`]: productDataPerMonth?.systemValue ?? null,
      [`${columnKey}_variance`]: variance,
      ...(variance && {
        [`${columnKey}_variance_percentage`]:
          variancePercent ||
          Math.round((variance / (productDataPerMonth.actualValue || 0)) * 100),
      }),
    };
  }, {});
};

/**
 * Parses through data to get the current month's data
 * to determine if current month has variances
 *
 * @param {Array} yearlyData - The list of year's data.
 * @returns {Array} An array of obligations from the month with data
 */
const getCurrentMonthVariances = (yearlyData) => {
  const currentMonthData = yearlyData.find(
    (monthData) =>
      monthData?.date.year === parseInt(currentYear, 10) &&
      monthData?.date.month === parseInt(currentMonth, 10),
  );
  if (!currentMonthData) return [];
  return Object.keys(currentMonthData).filter((key) => {
    if (key !== "date") {
      const {variance} = currentMonthData[key];
      return variance !== 0 && variance != null;
    }
    return false;
  });
};

const setTableLabels = (region, isPublishedView, forecastType) => {
  const labelsList = FIELD_LABELS_BY_REGION?.[region]?.[forecastType] ?? {};

  if (!isPublishedView) {
    return Object.keys(labelsList)
      .filter((key) => !key?.includes("other_volumes_excluded"))
      .reduce((memo, key) => {
        return {
          ...memo,
          [key]: labelsList?.[key],
        };
      }, {});
  }

  return labelsList;
};

const mapPageDataToTableRows = (tableFieldsKeyValue, pageData) =>
  Object.entries(tableFieldsKeyValue).map(([key, measurementLabel]) => ({
    key,
    measurement: measurementLabel,
    ...computeRowData(key, pageData),
  }));

const {useBreakpoint} = Grid;
const CombinedObligatedVolumeTable = ({
  isPublishedView = false,
  forecastType,
  isLoading,
  region,
  pageData = [],
  ...otherProps
}) => {
  const screens = useBreakpoint();

  const measurementLabelKeyValue = setTableLabels(
    region,
    isPublishedView,
    forecastType,
  );
  const showCurrentMonthVariance =
    getCurrentMonthVariances(pageData)?.length > 0;

  const tableData = useMemo(
    () => mapPageDataToTableRows(measurementLabelKeyValue, pageData),
    [measurementLabelKeyValue, pageData],
  );
  const columns = useMemo(
    () =>
      buildColumns(
        tableData,
        screens,
        isPublishedView,
        showCurrentMonthVariance,
      ),
    [tableData, screens, isPublishedView, showCurrentMonthVariance],
  );

  return (
    <div className="col-span-full combined-table" {...otherProps}>
      <Table
        data-test="obligated-volume-table"
        columns={columns}
        dataSource={pageData.length ? tableData : false}
        loading={isLoading}
        pagination={false}
        scroll={{x: "max-content"}}
        showHeader={false}
      />
    </div>
  );
};

CombinedObligatedVolumeTable.propTypes = {
  isPublishedView: PropTypes.bool,
  forecastType: PropTypes.string,
  isLoading: PropTypes.bool,
  pageData: PropTypes.array,
  region: PropTypes.string,
};

export default CombinedObligatedVolumeTable;
