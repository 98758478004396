import {gql} from "@apollo/client";

export const PRODUCT_DDV_DETAILS = gql`
  query bioLcCoproConfigCenterProductDefaultValueDetails {
    bioLcCoproConfigCenterProductDefaultValueDetails {
      Records {
        defaultValue
        defaultValueType
        materialId
        materialShortName
        productDvId
        uomCode
        validFrom
        validTo
      }
    }
  }
`;

export default {PRODUCT_DDV_DETAILS};
