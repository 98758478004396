import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const RETIRE_FOR_MANDATE = gql`
  mutation RetireForMandate(
    $mbInboundQtyEuId: String!
    $reason: String!
    $createdBy: String!
    $quantity: Float!
    $siteReferenceId: String!
    $divisionId: String!
  ) {
    bioLcGlobalMassBalanceDiscardQuantity(
      event: {
        mbInboundQtyEuId: $mbInboundQtyEuId
        reason: $reason
        createdBy: $createdBy
        quantity: $quantity
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        action: "retire-to-mandate"
      }
    ) {
      error
      statusCode
    }
  }
`;

export const RetireForMandateAPI = (data) =>
  client.mutate({
    mutation: RETIRE_FOR_MANDATE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export default {
  RETIRE_FOR_MANDATE,
  RetireForMandateAPI,
};
