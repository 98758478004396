export const isTotalRow = ({key}) => !!(key && key.includes("total"));

export const isMonthTitleRow = ({key}) => !!(key && key.includes("title"));

export const isTableTitleRow = ({key}) => !!(key && key.includes("table"));

export const isGasField = (record) =>
  record && record?.key && record.key?.includes("gas");

export const isDieselField = (record) =>
  record && record?.key && record.key?.includes("diesel");

export const getObligatedTotalRow = (arr) =>
  arr.find(({key = ""}) => key && key === "total_obligation");

export const filterByKey = (key, list = []) => {
  return list?.filter((item) => item?.type === key);
};

export const getProductKey = (key = "") => {
  if (key === "obligated_blended_production") return `user_${key}_gas`;

  if (key === "non_obligated_export_gas") return `user_${key}`;

  return key;
};

export const getUPTotalValues = (actual, forecasted, totalType, monthData) => {
  return {
    actualValue: (monthData?.[totalType]?.actualValue || 0) + actual,
    systemValue: (monthData?.[totalType]?.systemValue || 0) + forecasted,
    variance: (monthData?.[totalType]?.variance || 0) + (actual - forecasted),
  };
};

const filterProductRows = (rows) =>
  rows.filter(
    (row) => !row.key.includes("title") && !row.key.includes("total"),
  );

export const sumMonthTotal = (dateKey, list) =>
  filterProductRows(list).reduce(function (monthTotal, currentProduct) {
    const value =
      currentProduct?.updatedForecast?.[dateKey]?.value ??
      (currentProduct?.[dateKey] || 0);

    return monthTotal + value;
  }, 0);
