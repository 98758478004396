import {memo} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import tw, {styled} from "twin.macro";
import {MdSync} from "react-icons/md";
import {
  useForecastFilter,
  useForecastFilterDispatch,
} from "modules/forecasting/context/ForecastingFilterContext";
import {currentYear} from "modules/forecasting/helpers/dateUtils";
import FilterList from "./FilterList";
import FilterWrapper from "./FilterWrapper";

const LANDING_URL = "/forecasting";

const extractRegion = (urlPath = "") =>
  urlPath.substring(urlPath.lastIndexOf("/") + 1, urlPath.indexOf("-"));

const replaceRegionParam = (urlPath, appliedRegion) => {
  let baseRoute = "/forecasting/";

  if (urlPath === LANDING_URL) {
    return `/forecasting/${appliedRegion}-forecast-published`;
  }

  baseRoute += urlPath
    .split("/")
    .pop()
    .replace(extractRegion(urlPath), appliedRegion);

  return baseRoute;
};

export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "string" && value.trim().length === 0);

export const hasDefaultFilters = ({forecastType, region, year}) =>
  isEmpty(forecastType) && isEmpty(region) && year === currentYear;

const ForecastingFilterBar = () => {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const isOnLandingPage = useLocation().pathname === LANDING_URL;

  const {appliedFilters, selectedFilters} = useForecastFilter();
  const {handleApply, resetFilter} = useForecastFilterDispatch();

  const onApply = () => {
    const {region: selectedRegion = ""} = selectedFilters;

    //  When user selects forecast type All or the value is empty,
    //  then navigate user back to the landing.
    if (isEmpty(selectedRegion)) {
      navigate("/forecasting");
    }

    if (selectedRegion && selectedRegion !== appliedFilters?.region) {
      // if selected region is not the same as applied, then navigate to page by region
      navigate(replaceRegionParam(pathname, selectedRegion));
    }

    return handleApply();
  };

  const onReset = () => {
    if (pathname !== LANDING_URL) {
      navigate(LANDING_URL);
    }

    resetFilter();
  };

  return (
    <FilterWrapper>
      <FilterList />
      <ButtonGroup data-test="filter-button-group">
        <ApplyCTA onClick={onApply} />
        <ResetCTA
          onClick={onReset}
          disabled={isOnLandingPage && hasDefaultFilters(selectedFilters)}
        />
      </ButtonGroup>
    </FilterWrapper>
  );
};

export default memo(ForecastingFilterBar);

const ApplyCTA = ({...props}) => (
  <StyledApply data-test="apply" type="button" {...props}>
    Apply
  </StyledApply>
);

const ResetCTA = ({...props}) => (
  <StyledResetBtn data-test="reset" {...props}>
    <MdSync style={{width: 24, height: 24, margin: "auto"}} />
  </StyledResetBtn>
);

const ButtonGroup = styled.div(() => [
  tw`flex gap-3 basis-2/6 lg:basis-1/12 lg:justify-end pt-4 lg:pt-1 mt-auto`,

  tw`[> button]:(uppercase rounded-sm h-10 tracking-wider)`,
]);

const StyledApply = styled.button(() => [
  tw`text-white font-bold items-center min-w-[6rem] transition ease-in-out duration-200`,
  tw`bg-bp-green-800 border-bp-green-800`,

  tw`[&:hover]:(bg-bp-green-900 border-bp-green-900)`,
]);

const StyledResetBtn = tw.button`
  min-w-[2.5rem] text-white 
  border-bp-green-700 bg-bp-green-700

  disabled:cursor-not-allowed
  disabled:(border-gray-300 bg-gray-300)
`;
