import PropTypes from "prop-types";
import {useUserSettings} from "providers/userSettings";
import {useEffect} from "react";
import {useFormContext} from "react-hook-form";

import {useAccount, useMsal} from "@azure/msal-react";

import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import useRuleFormValidationEffects from "./validationError";

const SelectData = ({
  selectedItem,
  selectDatasetList,
  setSelectDataSet,
  selectedTemplateItems,
  setSelectedTemplateItems,
  updateConfigurationInBlocks,
  saveErrorData,
  isDisabled,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {
    register,
    formState: {errors},
    control,
    setError,
    setFocus,
    setValue,
    clearErrors,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchSelectDataset = watch("table_name");
  const watchSelectDataFilters = watch("predicate");

  useEffect(() => {
    setSelectDataSet(watchSelectDataset);
    updateConfigurationInBlocks(selectedItem, {
      table_name: watchSelectDataset,
      predicate: watchSelectDataFilters,
    });
  }, [
    selectedItem,
    watchSelectDataset,
    watchSelectDataFilters,
    updateConfigurationInBlocks,
    setSelectDataSet,
  ]);

  useEffect(() => {
    // configuration values are set here
    if (selectedItem && selectedItem?.configuration) {
      setValue("table_name", selectedItem?.configuration?.table_name);
      setValue("predicate", selectedItem?.configuration?.predicate);
      setSelectDataSet(selectedItem?.configuration?.table_name);
    } else {
      setValue("table_name", "");
      setValue("predicate", "");
    }
  }, [selectedItem?.configuration, selectedItem, setValue, setSelectDataSet]);

  useRuleFormValidationEffects({
    selectedItem,
    saveErrorData,
    clearErrors,
    setError,
    setFocus,
  });

  return (
    <div className="flex flex-col gap-5 text-left">
      <FormGroup>
        <Label for="table_name" className="fw-normal mb-4">
          Dataset
        </Label>
        <Input
          type="select"
          id="table_name"
          data-test="table_name"
          {...computeProps("table_name", {
            required: "Please select a dataset",
          })}
          invalid={!!errors.table_name}
          disabled={isDisabled}
        >
          <option value="">Select dataset</option>
          {selectDatasetList?.map((datasetItem) => (
            <option key={datasetItem?.dataset} value={datasetItem?.dataset}>
              {datasetItem?.dataset}
            </option>
          ))}
        </Input>
        {errors.table_name && (
          <FormFeedback className="mt-2">
            {errors.table_name?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="predicate" className="fw-normal">
          Filters
        </Label>

        <Input
          type="textarea"
          rows="3"
          id="predicate"
          data-test="predicate"
          {...computeProps("predicate", {
            required: "Please enter filters",
          })}
          invalid={!!errors.predicate}
          maxLength={200}
          placeholder="Input filters"
          disabled={isDisabled}
        />
        {errors.predicate && (
          <FormFeedback>{errors.predicate.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

SelectData.propTypes = {
  selectedItem: PropTypes.object,
  selectDatasetList: PropTypes.array,
  setSelectDataSet: PropTypes.func,
  selectedTemplateItems: PropTypes.array,
  setSelectedTemplateItems: PropTypes.func,
  updateConfigurationInBlocks: PropTypes.func,
  saveErrorData: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default SelectData;
