import * as yup from "yup";

export const SchemaAssigntoSale = yup
  .object()
  .shape({
    certificationSystem: yup.string().label("Certification System").required(),
    templateType: yup.string().label("Template").required(),
    issueDate: yup.string().label("Date of Issuance").required(),

    productType: yup.string().label("Type of Product").required(),
    uom: yup.string().label("Unit of measure").required(),
    quantity: yup.string().label("Quantity").required(),
    recipientName: yup.string().label("Name of recipient").required(),
    recipientReceiptName: yup.string().label("Name of recipient").required(),
    fossilFuelComparator: yup
      .string()
      .label("Fossil fuel comparator")
      .required(),
    // recipientAddress: yup.string().label("Address of recipient").required(),
    // recipientReceiptAddress: yup
    //   .string()
    //   .label("Address of Receipt / receiving point")
    //   .required(),
    motRecipient: yup.string().label("Mode of Transportation").required(),

    dispatchDate: yup.string().label("Date of dispatch").required(),
  })
  .required();

export default SchemaAssigntoSale;
