import {formatNumber} from "providers/userSettings";
import {roundDecimal} from "utils/helpers/uihelper";

const columnMapRTFO = {
  // bioScenarioNumber: "Bio Scenario Number Combined",
  material: "Material",
  businessPartner: "Business Partner",
  contractItem: "Contract Item",
  contractNo: "Contract No",
  documentItem: "Document Item",
  documentNo: "Document No",
  goodMovementDocItemNumber: "Good Movement Document Item No",
  goodMovementDocNumber: "Good Movement Document No",
  plant: "Plant",
  bioType: "Bio Type",
  subType: "Sub Type",
  complianceStatus: "Compliance Status",
  bioScenarioNumber: "Bio Scenario Number",
  quantity: "Quantity",
  tickets: "Tickets",
  dealDoneDate: "Deal Done Date",
  postingDate: "Posting Date",
  transferDate: "Transfer Date",
  legalEntity: "Legal entity",
};

const columnMapRFS = {
  volume: "Volume",
  bioScenarioNumber: "Bio Scenario Number",
  rinRvoQuantity: "RINS/RVO",
  dataSourceReport: "Data Source Report",
  dataSource: "Data Source",
  createdDate: "Created Date",
  complianceStatus: "Compliance Status",
  transactionType: "Transaction Type",
  tradingPartnerName: "Trading Partner Name",
  tradingPartnerCode: "Trading Partner Code",
  sourceSystemId: "Source System ID",
  sapVendorNumber: "SAP Vendor Number",
  sapVendorName: "SAP Vendor Name",
  sapCustomerNumber: "SAP Customer Number",
  sapCustomerName: "SAP Customer Name",
  rinRvoYear: "RIN RVO Year",
  nextGenDealNumber: "NextGen Deal Number",
  modifiedBy: "Modified By",
  materialDescription: "Material Description",
  materialCode: "Material Code",
  lastChangedDate: "Last Changed Date",
  facilityName: "Facility Name",
  comments: "Comments",
  legalEntity: "Business Unit",
  fuelCodeSubType: "Fuel Code Sub Type",
  bioType: "Fuel Code",
  businessUnit: "Business Unit",
  chainPrefix: "Chain Prefix",
};

const existingColumnsRTFO = [
  "bioScenarioNumber",
  "quantity",
  "tickets",
  "material",
  "businessPartner",
  "bioType",
  "subType",
  "complianceStatus",
  "plant",
  "legalEntity",
];

const existingColumnsRFS = [
  "bioScenarioNumber",
  "volume",
  "rinRvoQuantity",
  "complianceStatus",
  "fuelCode",
  "bioType",
  "subType",
  "legalEntity",
  "businessUnit",
];

const complianceStatus = ["Committed", "Actualized", "Forecast"];

const defaultColumnsOnLoadRTFO = (
  quantityUom,
  ticketsUom,
  scenarioNumber,
  filters,
  decimalFormat,
) => [
  {
    visible: true,
    filterable: true,
    disabled: true,
    align: "left",
    size: 200,
    header: columnMapRTFO.bioScenarioNumber,
    dataIndex: "bioScenarioNumber",
    key: "bioScenarioNumber",
    accessorKey: "bioScenarioNumber",
    searchType: "text",
    filterValues: scenarioNumber?.length > 0 ? [scenarioNumber] : [],
    cell: ({row}) => {
      return `${row?.original?.scenarioNumber} - ${row?.original?.scenarioDescription}`;
    },
    sorter: () => {},
  },
  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: columnMapRTFO.quantity + " - " + quantityUom,
    dataIndex: "quantityL15",
    accessorKey: "quantityL15",
    key: "quantityL15",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: columnMapRTFO.tickets + " - " + ticketsUom,
    dataIndex: "tickets",
    accessorKey: "tickets",
    key: "tickets",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.businessPartner,
    dataIndex: "businessPartner",
    key: "businessPartner",
    accessorKey: "businessPartner",
    searchType: "text",
    filterValues: [],
    size: 500,
    cell: ({row}) => {
      const vendorDetails = [
        row?.original?.vendorCode,
        row?.original?.vendorName,
      ];
      const customerDetails = [
        row?.original?.customerCode,
        row?.original?.customerName,
      ];

      const vendorLabel =
        vendorDetails?.length > 0
          ? vendorDetails?.filter((e) => e !== null && e !== "")?.join(" - ")
          : "";
      const customerLabel =
        customerDetails?.length > 0
          ? customerDetails?.filter((e) => e !== null && e !== "")?.join(" - ")
          : "";

      const businessPartner = [vendorLabel, customerLabel];
      const businessPartnerLabel =
        businessPartner?.length > 0
          ? businessPartner?.filter((e) => e !== null && e !== "")?.join(" - ")
          : "";
      if (row?.original?.vendorCode || row?.original?.customerCode) {
        return businessPartnerLabel;
      }
      return "";
    },
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.material,
    dataIndex: "material",
    accessorKey: "material",
    key: "material",
    size: 500,
    searchType: "text",
    filterValues: [],
    cell: ({row}) => {
      if (row?.original?.materialNumber) {
        return `${row?.original?.materialNumber} - ${row?.original?.materialDescription}`;
      }
      return "";
    },
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    header: columnMapRTFO.plant,
    dataIndex: "plant",
    align: "left",
    key: "plant",
    size: 500,
    accessorKey: "plant",
    searchType: "text",
    filterValues: [],
    cell: ({row}) => {
      if (row?.original?.plantCode) {
        const plantName =
          row?.original?.plantName?.length > 0
            ? " - " + (row?.original?.plantName || "")
            : "";
        return `${row?.original?.plantCode} ${plantName}`;
      }
      return "";
    },
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.complianceStatus,
    dataIndex: "complianceStatus",
    accessorKey: "complianceStatus",
    key: "complianceStatus",
    searchType: "list",
    filterValues: [],
    size: 150,
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.bioType,
    dataIndex: "bioType",
    accessorKey: "bioType",
    key: "bioType",
    searchType: "list",
    size: 150,
    filterValues: filters?.bioType?.length > 0 ? [filters?.bioType] : [],

    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.subType,
    dataIndex: "subType",
    accessorKey: "subType",
    key: "subType",
    size: 150,
    searchType: "list",
    filterValues: filters?.subType?.length > 0 ? [filters?.subType] : [],

    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRTFO.legalEntity,
    dataIndex: "legalEntity",
    key: "legalEntity",
    accessorKey: "legalEntity",
    size: 200,
    searchType: "list",
    filterValues:
      filters?.legalEntity?.length > 0 ? [filters?.legalEntity] : [],

    sorter: () => {},
  },
];

const defaultColumnsOnLoadRFS = (
  quantityUom,
  ticketsUom,
  scenarioNumber,
  filters,
  decimalFormat,
) => [
  {
    visible: true,
    filterable: true,
    disabled: true,
    align: "left",
    size: 200,
    header: columnMapRFS.bioScenarioNumber,
    dataIndex: "bioScenarioNumber",
    key: "bioScenarioNumber",
    accessorKey: "bioScenarioNumber",
    searchType: "text",
    filterValues: scenarioNumber?.length > 0 ? [scenarioNumber] : [],
    cell: ({row}) => {
      return `${row?.original?.scenarioNumber} - ${row?.original?.scenarioDescription}`;
    },
    sorter: () => {},
  },

  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: columnMapRFS.volume + " - " + quantityUom,
    dataIndex: "volume",
    accessorKey: "volume",
    key: "volume",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: ticketsUom,
    dataIndex: "rinRvoQuantity",
    accessorKey: "rinRvoQuantity",
    key: "rinRvoQuantity",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRFS.complianceStatus,
    dataIndex: "complianceStatus",
    accessorKey: "complianceStatus",
    key: "complianceStatus",
    searchType: "text",
    filterValues: [],
    size: 150,
    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRFS.bioType,
    dataIndex: "bioType",
    accessorKey: "bioType",
    key: "bioType",
    searchType: "text",
    size: 150,
    filterValues:
      filters?.bioType?.length > 0
        ? filters?.bioType === "Diesel"
          ? [filters?.bioType, "RD"]
          : [filters?.bioType]
        : [],

    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: true,
    align: "left",
    header: columnMapRFS.rinRvoYear,
    dataIndex: "rinRvoYear",
    accessorKey: "rinRvoYear",
    key: "subType",
    customizeName: "Sub Type",
    queryKey: "rinRvoYear",
    searchType: "text",
    size: 150,
    filterValues:
      filters?.subType?.length > 0
        ? [
            filters.subType === "Current Year Vintage"
              ? filters.complianceYear
              : (parseInt(filters.complianceYear, 10) - 1).toString(),
          ]
        : [],

    sorter: () => {},
  },
  {
    visible: false,
    filterable: true,
    disabled: false,
    align: "left",
    header: columnMapRFS.businessUnit,
    dataIndex: "legalEntity",
    key: "legalEntity",
    accessorKey: "legalEntity",
    size: 200,
    searchType: "text",
    filterValues:
      filters?.legalEntity?.length > 0 ? [filters?.legalEntity] : [],

    sorter: () => {},
  },
];

const manualAdjustmentColumnsRFS = (
  decimalFormat,
  quantityUom,
  scenarioNumber,
) => [
  {
    visible: true,
    filterable: true,
    disabled: true,
    align: "left",
    size: 200,
    header: columnMapRFS.bioScenarioNumber,
    dataIndex: "bioScenarioNumber",
    key: "bioScenarioNumber",
    accessorKey: "bioScenarioNumber",
    searchType: "text",
    filterValues: scenarioNumber?.length > 0 ? [scenarioNumber] : [],
    cell: ({row}) => {
      return `${row?.original?.scenarioNumber} - ${row?.original?.scenarioDescription}`;
    },
    sorter: () => {},
  },
  {
    visible: true,
    filterable: true,
    header: "Facility Name",
    dataIndex: "facilityName",
    key: "facilityName",
    accessorKey: "facilityName",
    align: "left",
    size: 150,
    filterValues: [],
    sorter: () => {},
    searchType: "text",
  },
  {
    visible: true,
    filterable: true,
    header: "Created Date",
    dataIndex: "createdDate",
    key: "createdDate",
    accessorKey: "createdDate",
    align: "left",
    size: 150,
    filterValues: [],
    sorter: () => {},
    searchType: "date",
  },
  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: "RINS/RVO",
    dataIndex: "rinRvoQuantity",
    accessorKey: "rinRvoQuantity",
    key: "rinRvoQuantity",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: true,
    filterable: false,
    disabled: true,
    align: "right",
    header: columnMapRFS.volume + " - " + quantityUom,
    dataIndex: "volume",
    accessorKey: "volume",
    key: "volume",
    searchType: "text",
    cell: (cell) => {
      return formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0);
    },
    size: 150,
    sorter: () => {},
  },
  {
    visible: true,
    filterable: true,
    header: "Reason for manual adjustment",
    dataIndex: "comments",
    key: "comments",
    accessorKey: "comments",
    customizeName: "Reason for manual adjustment",
    align: "left",
    size: 600,
    filterValues: [],
    sorter: () => {},
    searchType: "text",
  },
  {
    visible: false,
    filterable: true,
    notShowCustomize: true,
    header: "",
    dataIndex: "dataSource",
    key: "dataSource",
    accessorKey: "dataSource",
    align: "left",
    size: 150,
    filterValues: ["Manual Adjustment"],
    sorter: () => {},
    searchType: "text",
  },
];

export default {
  columnMapRFS,
  columnMapRTFO,
  defaultColumnsOnLoadRFS,
  defaultColumnsOnLoadRTFO,
  existingColumnsRFS,
  existingColumnsRTFO,
  manualAdjustmentColumnsRFS,
  complianceStatus,
};
