import moment from "moment";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS} from "graphql/coprocessing/shipments";
import {
  dtnShipmentKeys as dtnKeys,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import Form from "../Form";
import PIData from "../../Shared/PIData";

const truckRackFieldOrder = [
  "SHIPMENT_ID",
  "SHIPMENT_DATE",
  "PI_VISION", // field is for display only
  "SHIPMENT_START_DATE",
  "SHIPMENT_END_DATE",
  "ESTIMATED_VOLUME",
  "SOURCE_TANK",
  "NOTES",
];

const TruckRackDetails = () => {
  const {watch, setError, clearErrors} = useFormContext();
  const startTime = watch(dtnKeys.SHIPMENT_START_DATE);
  const endTime = watch(dtnKeys.SHIPMENT_END_DATE);

  const [shipmentIdError, setShipmentIdError] = useState({
    type: "custom",
    message: "",
  });

  const [checkShipmentIdExists] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const error = data?.bioLcCoproUsShipmentsApi?.error;
        setShipmentIdError({
          type: "custom",
          message: error,
        });
      },
    },
  );

  useEffect(() => {
    // Validate only if both startTime and endTime have been provided by the user
    if (startTime && endTime) {
      const format = "YYYY-MM-DD hh:mm A";
      const startMoment = moment(startTime, format);
      const endMoment = moment(endTime, format);
      if (startMoment.isSameOrAfter(endMoment)) {
        setError(dtnKeys.SHIPMENT_START_DATE, {
          type: "custom",
          message: "Start time must be earlier than end time.",
        });
        setError(dtnKeys.SHIPMENT_END_DATE, {
          type: "custom",
          message: "End time must be after start time.",
        });
      } else {
        clearErrors([dtnKeys.SHIPMENT_START_DATE, dtnKeys.SHIPMENT_END_DATE]);
      }
    }
  }, [startTime, endTime, setError, clearErrors]);

  return (
    <>
      <h5 className="pb-4">
        Give this truck rack shipment a unique ID and enter the shipping
        details.
      </h5>

      <div className="divide-y-2">
        <div className="row g-5 mb-8">
          {truckRackFieldOrder.map((identifier) => {
            if (identifier === "ESTIMATED_VOLUME") {
              return (
                <Form.EstVolumeDisplayField
                  key={identifier}
                  startTime={startTime}
                  endTime={endTime}
                />
              );
            }
            if (identifier === "PI_VISION") {
              return <PIData key={identifier} />;
            }

            if (identifier === "SHIPMENT_ID") {
              return (
                <Form.ShipmentIdField
                  key={identifier}
                  validate={checkShipmentIdExists}
                  fieldError={shipmentIdError}
                />
              );
            }

            return <Form.Field key={identifier} fieldName={identifier} />;
          })}
        </div>
      </div>
    </>
  );
};

const backToUnitFieldOrder = [
  "SHIPMENT_DATE",
  "TIME",
  "PI_VISION",
  "ESTIMATED_VOLUME", // user entered volume will be used as actual volume during the allocation process
  "SOURCE_TANK",
  "SHIPMENT_ID",
  "NOTES",
];

const BackToUnitDetails = () => {
  const [shipmentIdError, setShipmentIdError] = useState({
    type: "custom",
    message: "",
  });

  const [checkShipmentIdExists] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_CHECK_SHIPMENT_ID_EXISTS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        const error = data?.bioLcCoproUsShipmentsApi?.error;
        setShipmentIdError({
          type: "custom",
          message: error,
        });
      },
    },
  );

  return (
    <>
      <div className="col-12">
        <h5 className="display-6">Enter the back to unit details.</h5>
      </div>

      <div className="row g-5 mb-8">
        {backToUnitFieldOrder.map((identifier) => {
          if (identifier === "PI_VISION") {
            return <PIData key={identifier} />;
          }

          if (identifier === "SHIPMENT_ID") {
            return (
              <Form.ShipmentIdField
                key={identifier}
                validate={checkShipmentIdExists}
                fieldError={shipmentIdError}
              />
            );
          }

          return <Form.BackUnitField key={identifier} fieldName={identifier} />;
        })}
      </div>
    </>
  );
};

const dtnDetailsConfig = {
  [SHIPMENT_TYPE.BACK_TO_UNIT]: BackToUnitDetails,
  [SHIPMENT_TYPE.TRUCK_RACK]: TruckRackDetails,
};

const EnterDetails = ({shipmentType}) => {
  const ShipmentDetailsComponent = dtnDetailsConfig?.[shipmentType];

  return (
    <div className="row pt-4" data-test="enter-shipment-deets">
      {ShipmentDetailsComponent ? (
        <ShipmentDetailsComponent />
      ) : (
        <p>Please select a shipment type.</p>
      )}
    </div>
  );
};

EnterDetails.propTypes = {
  shipmentType: PropTypes.oneOf(Object.keys(SHIPMENT_TYPE)).isRequired,
};
export default EnterDetails;
