import React, {useEffect, useState, useCallback} from "react";
import {Menu, Checkbox, Dropdown, Button, Space} from "antd";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const CheckboxFilter = ({
  data,
  dataKey,
  activeFilters,
  onChange,
  label,
  disabled,
  loading,
  onClickAwayBack,
  hidden,
  filterMap,
  isNotActive,
}) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (hidden) {
      setToggleDropdown(false);
    }
  }, [hidden]);

  useEffect(() => {
    setCheckAll(activeFilters.length === data?.length);
  }, [activeFilters, data]);

  const handleOnChange = useCallback(
    ({target: {checked, name}}) => {
      if (isNotActive) {
        return;
      }
      if (checked) {
        onChange({
          filterKey: dataKey,
          singleActiveFilters: [...activeFilters, name],
          checked,
          filterOption: name,
        });
        setCheckAll(activeFilters.length + 1 === data?.length);
      } else {
        setCheckAll(activeFilters.length - 1 === data?.length);
        onChange({
          filterKey: dataKey,
          singleActiveFilters: activeFilters?.filter(
            (activeItem) => activeItem !== name,
          ),
          checked,
          filterOption: name,
        });
      }
    },
    [activeFilters, data?.length, dataKey, onChange, isNotActive],
  );

  const handleOnCheckAllChange = ({target: {checked}}) => {
    if (isNotActive) {
      return;
    }
    onChange({
      filterKey: dataKey,
      singleActiveFilters: checked ? data : [],
      checked,
      allChecked: true,
    });
    setCheckAll(checked);
  };

  const renderDropdown = () => {
    if (data?.length && !disabled) {
      return (
        <Menu className="p-0 bg-transparent checkbox-filter-menu">
          <Menu.Item
            key="all"
            className="filter-item rounded first:bg-gray-200"
          >
            <Checkbox
              name="all"
              onChange={handleOnCheckAllChange}
              checked={checkAll}
            >
              All
            </Checkbox>
          </Menu.Item>
          {data.map((item) => (
            <Menu.Item key={item} className="filter-item">
              <Checkbox
                data-test={`checkbox-${item}`}
                onChange={handleOnChange}
                name={item}
                checked={activeFilters.includes(item)}
              >
                {item}
              </Checkbox>
            </Menu.Item>
          ))}
        </Menu>
      );
    }
    return <div />;
  };

  return (
    <Dropdown
      overlay={renderDropdown}
      trigger={["click"]}
      visible={!hidden && filterMap[dataKey]}
      disabled={disabled || !data?.length}
      loading={loading}
      className="w-48"
    >
      <Button
        onClick={() => {
          setToggleDropdown((currentValue) => !currentValue);
          onClickAwayBack(dataKey);
        }}
        className="toggle-dropdown"
        data-test={dataKey}
      >
        <Space align="center">
          {label}
          {toggleDropdown ? <UpOutlined /> : <DownOutlined />}
        </Space>
      </Button>
    </Dropdown>
  );
};

CheckboxFilter.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  activeFilters: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClickAwayBack: PropTypes.func,
  hidden: PropTypes.bool,
  filterMap: PropTypes.object,
  isNotActive: PropTypes.object,
};

export default CheckboxFilter;
