import {EllipsisOutlined, UndoOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";
import {Dropdown} from "antd";
import Menu from "../../Menu";

const RowActionsMenu = ({
  children,
  isSoftDelete = false,
  onRestoreRecord,
  icon = null,
}) => {
  const buttonClassName = icon
    ? ""
    : "px-1 pb-1 bg-gray-800 rounded-sm hover:bg-opacity-50";
  return !isSoftDelete ? (
    <Dropdown
      overlay={
        <Menu key="row-menu" className="bg-white shadow-none">
          {children}
        </Menu>
      }
      placement="bottomRight"
    >
      <button
        type="button"
        data-test="row-actions-options"
        className={buttonClassName}
      >
        {/* TECHDEBT: FIX INLINE STYLE WITH TAILWIND */}
        {icon || (
          <EllipsisOutlined
            style={{color: "white", fontSize: 20}}
            rotate="90"
          />
        )}
      </button>
    </Dropdown>
  ) : (
    <button
      onClick={onRestoreRecord}
      type="button"
      className="pb-1 rounded-sm bg-gray-800 h-7 w-7"
      data-test="button-restore-item"
    >
      {/* TECHDEBT: FIX INLINE STYLE WITH TAILWIND */}
      <UndoOutlined style={{color: "white", fontSize: 18}} rotate="90" />
    </button>
  );
};

RowActionsMenu.propTypes = {
  children: PropTypes.node,
  isSoftDelete: PropTypes.bool,
  onRestoreRecord: PropTypes.func,
  icon: PropTypes.element,
};

export default RowActionsMenu;
