import PropTypes from "prop-types";

const GetInTouchBox = ({img, title, description}) => {
  return (
    <div data-test={`get-in-touch-box-${title}`} className="text-center w-72">
      <img data-test="img" src={img} alt={title} className="mx-auto" />
      <h3 data-test="title" className="mt-3 text-lg font-medium">
        {title}
      </h3>
      {description}
    </div>
  );
};

GetInTouchBox.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
};

export default GetInTouchBox;
