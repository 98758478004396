import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const WRITE_OFF = gql`
  mutation WriteOff(
    $inboundEuQtyId: String!
    $sdNumber: String!
    $reason: String!
    $createdBy: String!
    $quantity: Float!
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcCoproEuMassBalanceWriteOff(
      event: {
        inboundEuQtyId: $inboundEuQtyId
        sdNumber: $sdNumber
        reason: $reason
        createdBy: $createdBy
        quantity: $quantity
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      message
      status
      statusCode
    }
  }
`;

export const WriteOffAPI = (data) =>
  client.mutate({
    mutation: WRITE_OFF,
    variables: {
      ...data,
    },
    refetchQueries: ["bioLcCoproEuMassBalanceAllRecords"],
  });

export default {
  WRITE_OFF,
  WriteOffAPI,
};
