import {gql} from "@apollo/client";

export const DOWNLOAD = gql`
  query bioLcCoproConfigCenterDownload {
    bioLcCoproConfigCenterDownload {
      errorMessage
      statusCode
      url
    }
  }
`;

export default {DOWNLOAD};
