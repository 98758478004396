import {useQuery} from "@apollo/client";
import moment from "moment";
import {useParams} from "react-router-dom";
import {useCallback, useMemo, useState} from "react";

import SummaryTable from "modules/common/SummaryTable";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {HEADER_MOT_VALIDATION_REPORT} from "modules/gy-enablement/constants/Headers";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {
  RECONCILIATION_SUMMARY_MATERIAL_LEVEL,
  TAXED_OUTBOUND_MATERIAL_LEVEL_SUMMARY,
} from "graphql/gy-enablement/MOT/summaryDashboard";
import {paginationSizeMOT, paginationPageSizeOptions} from "constants/config";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import BioBreadcrumb from "modules/common/Breadcrumb";
import {motTabDataMaterialLevelItems} from "../../../content/tabData";
import {multiLevelBreadcrumbItems} from "../../../content/breadCrumbData";
import FilterDashboardLayout from "../../common/FilterDashboardLayout";
import {
  motSummaryMaterialLevelReconciliationTableColumns,
  getTaxedOutboundTableMaterialLevelCols,
} from "../../../content/motSummaryColumns";
import "../../common/index.scss";
import CeemasValidationFilter from "./components/CeemasValidationFilter";

const CeemasValidationMaterialLevel = () => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const taxedOutboundTableMaterialLevelCols =
    getTaxedOutboundTableMaterialLevelCols(decimalFormat);
  const [pageNumber, setPage] = useState(1);
  const [pageSizeNumber, setPageSize] = useState(paginationSizeMOT);
  const {filterDetails} = useParams();
  const filterParamDetails = JSON.parse(filterDetails);
  const onLoadPayload = {
    company: filterParamDetails?.company,
    complianceyear: filterParamDetails?.complianceyear,
    compliancemonth: filterParamDetails?.compliancemonth,
    ed: filterParamDetails?.ed,
    legalentity: filterParamDetails?.legalentity,
    material: filterParamDetails?.material,
    plant: filterParamDetails?.plant,
    balancegroup: filterParamDetails?.balancegroup,
    motProfile: filterParamDetails?.motProfile,
  };
  const [filterValues, setFilterValues] = useState(onLoadPayload);

  const motTabData = motTabDataMaterialLevelItems(filterParamDetails);
  const [breadcrumbitems, setBreadcrumbitems] = useState(
    multiLevelBreadcrumbItems(onLoadPayload),
  );

  const {data: optionsDataPayload, loading: optionsLoading} =
    useQuery(GET_FIELD_OPTIONS);
  const {
    data: reconciliationData,
    loading: reconciliationDataLoading,
    refetch: refetchReconciliationSummary,
  } = useQuery(RECONCILIATION_SUMMARY_MATERIAL_LEVEL, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const {
    data: taxedOutboundData,
    loading: taxedOutboundDataLoading,
    refetch: refetchTaxedOutboundSummary,
  } = useQuery(TAXED_OUTBOUND_MATERIAL_LEVEL_SUMMARY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const reconciliationDataItems =
    reconciliationData?.getSummaryMaterialLevelRequest;
  const taxedOutboundDataItems =
    taxedOutboundData?.getOutboundMaterialLevelRequest;

  const handleOnFilter = useCallback(
    (values) => {
      const payload = {
        company: values.company || "",
        ed: values.ed || "",
        legalentity: values.legalentity || "",
        plant: values.plant || "",
        compliancemonth: values.compliancemonth
          ? moment(values.compliancemonth).format("MM")
          : "",
        complianceyear: moment(values.complianceyear).format("YYYY"),
        material: values.material || "",
        balancegroup: values.balancegroup || "",
        motProfile: values.motProfile || "",
      };
      refetchReconciliationSummary(payload);
      refetchTaxedOutboundSummary(payload);
      setFilterValues(payload);
      setBreadcrumbitems(multiLevelBreadcrumbItems(payload));
    },
    [refetchReconciliationSummary, refetchTaxedOutboundSummary],
  );
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };

  const reconciliationDataItemsWithFilterDetails = reconciliationDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );

  const taxedOutboundDataItemsWithFilterDetails = taxedOutboundDataItems?.map(
    (element) => ({
      ...element,
      filterDetails: filterValues,
    }),
  );

  const formatedDataTaxedOutbound = applyDateFormat(
    taxedOutboundDataItemsWithFilterDetails,
    [],
    dateFormat,
    "",
    [],
    decimalFormat,
    0,
  );

  const headerbreadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Germany", link: "/"},
    {
      text: "CEEMAS",
      link: "/gy-enablement/ceemas-mot-validation/:filterDetails",
    },
  ];

  return (
    <BasePageLayout
      pageTitle={HEADER_MOT_VALIDATION_REPORT}
      tabsData={motTabData}
      breadcrumbs={headerbreadcrumbItems}
    >
      <CeemasValidationFilter
        onFilter={handleOnFilter}
        data={optionsData}
        loading={optionsLoading}
        defaultValues={{
          company: filterParamDetails?.company,
          complianceyear: moment(filterParamDetails?.complianceyear),
          compliancemonth:
            filterParamDetails?.compliancemonth === ""
              ? ""
              : moment(
                  `${filterParamDetails?.complianceyear}-${filterParamDetails?.compliancemonth}`,
                ),
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }}
      />

      <BasePageLayoutContent>
        <BioBreadcrumb breadcrumbitems={breadcrumbitems} />
        <div className="grid grid-cols-1 mt-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-5 gap-y-10">
          <SummaryTable
            loading={reconciliationDataLoading}
            columns={motSummaryMaterialLevelReconciliationTableColumns}
            dataSource={reconciliationDataItemsWithFilterDetails ?? []}
            tableHeading="Overall Reconciliation Status"
            data-test="overall-reconciliation-table-material-level"
            pagination={{
              current: pageNumber,
              pageSize: pageSizeNumber,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              total: reconciliationDataItems?.length,
              showTotal: (total) => `Total ${total} items`,
              position: ["bottomLeft"],
              defaultPageSize: pageSizeNumber,
              pageSizeOptions: paginationPageSizeOptions,
            }}
          />

          <SummaryTable
            tableHeading="Taxed Outbound Volumes"
            columns={taxedOutboundTableMaterialLevelCols}
            dataSource={formatedDataTaxedOutbound ?? []}
            loading={taxedOutboundDataLoading}
            data-test="taxed-outbound-material-level-table"
            pagination={{
              current: pageNumber,
              pageSize: pageSizeNumber,
              onChange: (page, pageSize) => handlePageChange(page, pageSize),
              showSizeChanger: true,
              total: formatedDataTaxedOutbound?.length,
              showTotal: (total) => `Total ${total} items`,
              position: ["bottomLeft"],
              defaultPageSize: pageSizeNumber,
              pageSizeOptions: paginationPageSizeOptions,
            }}
          />
        </div>
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default CeemasValidationMaterialLevel;
