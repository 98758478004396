import PropTypes from "prop-types";
import {ReactComponent as ErrorIcon} from "assets/images/svg/error.svg";
import {ReactComponent as WarningIcon} from "assets/images/svg/warning.svg";
import {ReactComponent as SuccessIcon} from "assets/images/svg/success.svg";
import {ReactComponent as CloseIcon} from "assets/images/svg/close.svg";
import {ReactComponent as InfoIcon} from "assets/images/svg/info.svg";
import {ReactComponent as Loading} from "assets/images/svg/loading-circle.svg";
import "./index.scss";

const typeClasses = {
  success: "--success",
  error: "--error",
  warning: "--warning",
  info: "--info",
  progress: "--progress",
};

const typeIcon = {
  error: <ErrorIcon title="Error" />,
  warning: <WarningIcon title="Warning" />,
  success: <SuccessIcon title="Success" />,
  info: <InfoIcon title="Info" />,
  progress: <Loading title="Progress" />,
};

// ---------------------------------------------------------------------------------
// Notification component can be used in two different ways:
//
// 1) On its own using: <Notification type="success" text="example of notification" />
//
// 2) Using useNotification hook:
//       const [triggerNotification, clearNotification, notificationProps] = useNotification()
//       triggerNotification('success', 'This is an example text');
//       setTimer(() => clearNotification(), 20000);
//       return <Notification {...notificationProps} />
// ---------------------------------------------------------------------------------

const Notification = ({
  className = "",
  visible = true,
  type = "success",
  text,
  onClose,
}) => (
  <div
    data-test={`notification-${type}`}
    className={[
      "bp-notification",
      typeClasses[type],
      visible ? null : "--hidden",
      className,
    ].join(" ")}
  >
    <div className="bp-notification-text">
      {typeIcon[type]} {text}
    </div>
    {onClose ? (
      <CloseIcon
        className="bp-notification-close"
        onClick={() => onClose(type)}
      />
    ) : null}
  </div>
);

Notification.propTypes = {
  className: PropTypes.string,
  visible: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "error", "warning", "info", "progress"]),
  onClose: PropTypes.func,
};

export default Notification;
