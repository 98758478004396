import moment from "moment";
import {useEffect} from "react";
import PropTypes from "prop-types";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
  ADD_BATCH_DATE_FIELDS,
} from "modules/co-processing/constants/batches";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {useSplitBatch} from "../Context/SplitBatchContext";
import Form from "./Form";

const batchFormLabels = ADD_BATCH_FORM.labels;
const batchFieldKeys = ADD_BATCH_FORM.keys;

export const OperatingCondition = () => {
  return (
    <Form.Select
      label={batchFormLabels.opCondition}
      name={batchFieldKeys.opCondition}
      options={Object.values(OP_CONDITIONS)}
    />
  );
};

export const DHDSDateTime = ({fieldLabels, prefix = ""}) => {
  const {
    control,
    formState: {errors},
    trigger,
  } = useFormContext();

  const [startTime, endTime, previousBatchEndDate] = useWatch({
    name: [
      batchFieldKeys.batchStartTime,
      batchFieldKeys.batchEndTime,
      batchFieldKeys.latestEndDate,
    ],
    control,
  });

  const displayPreviousBatchEndDateMessage = (
    currentStartDate,
    isStartDateField,
  ) => {
    if (
      isStartDateField &&
      moment(currentStartDate).isBefore(moment(previousBatchEndDate))
    ) {
      return (
        <FormFeedback className="d-block w-1/2" invalid="true">
          {ADD_BATCH_FORM.messages.END_DATE_OVERLAP_INFO}
        </FormFeedback>
      );
    }
    return null;
  };

  // Validation logic for start and end date order
  const validateStartEndTimeOrder = (selectedDate, isStartTimeField) => {
    const selectedDateTime = moment(selectedDate);
    const comparisonDate = isStartTimeField
      ? moment(endTime)
      : moment(startTime);

    if (isStartTimeField && selectedDateTime.isAfter(comparisonDate)) {
      return ADD_BATCH_FORM.messages.START_TIME_MUST_BE_EARLIER_THAN_END;
    }
    if (!isStartTimeField && selectedDateTime.isBefore(comparisonDate)) {
      return ADD_BATCH_FORM.messages.END_TIME_AFTER_START_TIME;
    }
    return true;
  };

  return (
    <>
      {ADD_BATCH_DATE_FIELDS.map(({name, label}) => (
        <Controller
          key={name}
          name={name}
          control={control}
          rules={{
            required: true,
            validate: (value) =>
              validateStartEndTimeOrder(value, name === "batch_start_time"),
          }}
          render={({field}) => (
            <FormGroup className="col-md-6">
              <Form.DateTimePicker
                label={prefix + (fieldLabels?.[name] || label)}
                name={name}
                error={errors?.[name]}
                onChange={(value) => {
                  field.onChange(value);
                  trigger(name);
                  if (startTime && endTime) trigger();
                }}
                value={field.value}
              />
              {displayPreviousBatchEndDateMessage(
                field.value,
                name === "batch_start_time",
              )}
            </FormGroup>
          )}
        />
      ))}
    </>
  );
};
DHDSDateTime.propTypes = {
  prefix: PropTypes.string,
  fieldLabels: PropTypes.shape({
    batch_start_time: PropTypes.string,
    batch_end_time: PropTypes.string,
  }),
};

export const SplitBatch = ({isBypassed = false}) => {
  const {watch} = useFormContext();
  const {toggleHandler} = useSplitBatch();
  const isSplitBatch = watch(batchFieldKeys.splitBatch);

  const {setValue} = useFormContext();

  useEffect(() => {
    if (isBypassed) {
      setValue(batchFieldKeys.splitBatch, false);
    }
  }, [isBypassed, setValue]);

  useEffect(() => {
    toggleHandler(isSplitBatch);
  }, [toggleHandler, isSplitBatch]);

  return isBypassed ? null : (
    <Form.Toggle
      label={batchFormLabels.splitBatch}
      name={batchFieldKeys.splitBatch}
    />
  );
};
SplitBatch.propTypes = {isBypassed: PropTypes.bool};

export const BatchIDTextField = ({batchOptions = []}) => {
  const {control} = useFormContext();

  return (
    <Form.Field label={batchFormLabels.batchId} name={batchFieldKeys.batchId}>
      <Controller
        name={batchFieldKeys.batchId}
        control={control}
        render={({field}) => {
          return (
            <Input
              name={batchFieldKeys.batchId}
              value={field?.value ?? ""}
              {...field}
            />
          );
        }}
      />
    </Form.Field>
  );
};

export const Notes = () => {
  const {control} = useFormContext();

  return (
    <FormGroup className="col-sm-12 mb-3 mb-lg-5">
      <Label for={batchFieldKeys.batch_notes}>
        {batchFormLabels.batch_notes}
      </Label>

      <Controller
        name={batchFieldKeys.batch_notes}
        control={control}
        render={({field}) => {
          return (
            <Input
              {...field}
              className="bg-white"
              type="textarea"
              maxLength={300}
              placeholder="Add notes"
              rows="2"
              value={field?.value ?? ""}
            />
          );
        }}
      />
      <span className="label-optional" />
    </FormGroup>
  );
};

BatchIDTextField.propTypes = {batchOptions: PropTypes.array};
