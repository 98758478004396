import _ from "lodash";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Label, Input, FormGroup, FormFeedback, FormText} from "reactstrap";
import {Controller, useFormContext, useWatch} from "react-hook-form";
import {Datepicker} from "@bphxd/ds-core-react";
import FormDropdown from "../FormDropdown/FormDropdown";

const ManualAdjustmentForm = ({options, handleOptionsChange}) => {
  const errorMessage = "Expected information missing";
  const {
    formState: {errors},
    control,
    register,
    trigger,
    getValues,
    setValue,
  } = useFormContext();
  const gradeValue = useWatch({name: "grade"});
  const gradeSubTypeValue = useWatch({name: "gradeSubType"});
  const locationValue = useWatch({name: "location"});
  const businessUnitValue = useWatch({name: "businessUnit"});

  useEffect(
    () => handleOptionsChange("businessUnit", businessUnitValue),
    [handleOptionsChange, businessUnitValue],
  );

  useEffect(
    () => handleOptionsChange("location", locationValue),
    [handleOptionsChange, locationValue],
  );

  useEffect(
    () => handleOptionsChange("gradeSubType", gradeSubTypeValue),
    [handleOptionsChange, gradeSubTypeValue],
  );

  useEffect(
    () => handleOptionsChange("grade", gradeValue),
    [handleOptionsChange, gradeValue],
  );

  useEffect(() => {
    _.forOwn(getValues(), (value, key) => {
      // Clear the values from the form if new options don't contain the old value.
      if (value && key in options && !options[key].includes(value)) {
        setValue(key, "");
      }
    });
  }, [getValues, options, setValue]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="manual-adjustment-form">
      <div className="flex w-100 flex-row gap-4 flex-wrap mb-10 mt-7">
        <FormGroup className="w-[32%]">
          <Label for="transactionDate" className="mb-2">
            Transaction date
          </Label>
          <Controller
            name="transactionDate"
            control={control}
            render={({field}) => (
              <Datepicker
                data-test="transactionDate"
                placeholder="Select date"
                id="transactionDate"
                name="transactionDate"
                invalid={!!errors.transactionDate}
                options={{
                  allowInput: false,
                  altInput: true,
                  showMonths: 1,
                  allowInvalidPreload: false,
                  altFormat: "M j,Y",
                  dateFormat: "d/m/Y",
                  enableTime: false,
                  maxDate: "today",
                  value: field.value,
                  onChange: (selectedDates, dateStr) => {
                    field.onChange(dateStr);
                    trigger("transactionDate");
                  },
                }}
              />
            )}
            rules={{required: errorMessage}}
          />
          {errors.transactionDate && (
            <FormFeedback className="d-block">
              {errors.transactionDate.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup className="w-[32%]">
          <Controller
            name="grade"
            control={control}
            render={({field}) => {
              return (
                <FormDropdown
                  label="Grade"
                  options={options?.grade}
                  values={options?.grade}
                  invalid={!!errors.grade}
                  errors={errors.grade}
                  placeholder="Choose grade"
                  menuClassName="w-100 manual-adjustment-dropdown"
                  {...field}
                />
              );
            }}
            rules={{
              required: errorMessage,
            }}
          />
        </FormGroup>
        <FormGroup className="w-[32%]">
          <Controller
            name="gradeSubType"
            control={control}
            render={({field}) => {
              return (
                <FormDropdown
                  label="Grade sub-type"
                  options={options?.gradeSubType}
                  values={options?.gradeSubType}
                  invalid={!!errors.gradeSubType}
                  errors={errors.gradeSubType}
                  placeholder="Choose a sub-type"
                  menuClassName="w-100 manual-adjustment-dropdown"
                  {...field}
                />
              );
            }}
            rules={{
              required: errorMessage,
            }}
          />
        </FormGroup>
      </div>
      <div className="flex w-100 flex-row gap-4 flex-wrap my-10">
        <FormGroup className="w-[32%]">
          <Controller
            name="businessUnit"
            control={control}
            render={({field}) => {
              return (
                <FormDropdown
                  label="Business unit"
                  options={options?.businessUnit}
                  values={options?.businessUnit}
                  placeholder="Choose a business unit"
                  invalid={!!errors.businessUnit}
                  errors={errors.businessUnit}
                  menuClassName="w-100 manual-adjustment-dropdown"
                  {...field}
                />
              );
            }}
            rules={{required: errorMessage}}
          />
        </FormGroup>
        <FormGroup className="w-[32%]">
          <Controller
            name="location"
            control={control}
            render={({field}) => {
              return (
                <FormDropdown
                  label="Location"
                  options={options?.location}
                  values={options?.location}
                  invalid={!!errors.location}
                  errors={errors.location}
                  menuClassName="w-100 manual-adjustment-dropdown"
                  {...field}
                  placeholder="Choose a facility"
                />
              );
            }}
            rules={{
              required: errorMessage,
            }}
          />
        </FormGroup>
      </div>
      <div className="flex w-100 flex-row gap-4 flex-wrap my-10">
        <FormGroup className="w-[65.5%]">
          <Label for="volume" className="mb-2">
            Volume gal
          </Label>
          <Input
            id="volume"
            name="volume"
            type="number"
            {...computeProps("volume", {
              required: errorMessage,
              pattern: {
                value: /^-?\d{1,17}(?:[.]\d{1,3})?$/,
                message:
                  "Wrong number - can be at most 17 digits long with 3 numbers after a dot.",
              },
            })}
            invalid={!!errors.volume}
            placeholder="Type a number"
            required
          />
          <FormText>
            For obligated volumes (e.g. gasoline/diesel) enter a positive value
            to increase the total obligated volume. For non-obligated volumes
            (e.g. renewable diesel) enter a negative value to reduce the total
            obligated volume.
          </FormText>
          {errors.volume && (
            <FormFeedback>{errors.volume.message}</FormFeedback>
          )}
        </FormGroup>
      </div>
      <div>
        <FormGroup>
          <Controller
            name="comment"
            control={control}
            render={({field}) => {
              return (
                <>
                  <Label for="comment" className="mb-2">
                    Reason for manual adjustment
                  </Label>
                  <Input
                    type="textarea"
                    maxLength={100}
                    {...field}
                    invalid={!!errors.comment}
                  />
                </>
              );
            }}
            rules={{required: errorMessage}}
          />
          {errors.comment && (
            <FormFeedback>{errors.comment.message}</FormFeedback>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

ManualAdjustmentForm.propTypes = {
  options: PropTypes.object,
  handleOptionsChange: PropTypes.func,
};

export default ManualAdjustmentForm;
