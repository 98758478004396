import PropTypes from "prop-types";
import {
  useReactTable,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {Table, Input} from "reactstrap";
import {Fragment, useMemo} from "react";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";

import SpinnerLoading from "modules/common/SpinnerLoading";

const Checkbox = (row) => (
  <Input
    type="checkbox"
    checked={row.row.getIsSelected()}
    onChange={row.row.getToggleSelectedHandler()}
  />
);

const TableCheckbox = (table) => (
  <div className="d-flex align-items-center">
    <Input
      type="checkbox"
      checked={table.table.getIsAllRowsSelected()}
      onChange={table.table.getToggleAllRowsSelectedHandler()}
    />
  </div>
);

const getColumns = (columns, enableRowSelection) => {
  let newColumns = columns;
  if (enableRowSelection) {
    const newColumn = {
      header: ({table}) => <TableCheckbox table={table} />,
      accessorKey: "checkbox",
      enableSorting: false,
      size: 25,
      cell: ({row}) => <Checkbox row={row} />,
    };
    newColumns = [newColumn].concat(columns);
  }
  return newColumns;
};

const DetailTable = ({
  columns,
  data,
  enableRowSelection,
  rowSelection,
  setRowSelection,
  className,
  loading,
  totalCount,
  pagination,
  onPaginationChange,
  onSortingChange,
  orderByColumns,
}) => {
  const newColumns = useMemo(
    () => getColumns(columns, enableRowSelection),
    [columns, enableRowSelection],
  );
  const table = useReactTable({
    data,
    columns: newColumns,
    enableRowSelection,
    state: {
      pagination,
      rowSelection,
      sorting: orderByColumns,
    },
    manualPagination: true,
    manualSorting: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, pagination);
      onPaginationChange(newValue);
    },
    onSortingChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, orderByColumns);
      onSortingChange(newValue);
    },
  });

  const {pageSize} = table.getState().pagination;
  const pageCount = Math.ceil(totalCount / pageSize);

  const paginationProps = {
    rowCount: totalCount,
    pageLength: table.getRowModel().rows.length,
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: pagination.pageIndex < pageCount - 1,
    pageCount, // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize, // user setting from Select input
    showFirstAndLast: true,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  return (
    <>
      <Table className={className} responsive>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Fragment key={header.id}>
                  {header.isPlaceholder ? (
                    <th aria-label="placeholder" />
                  ) : header.column.getCanSort() ? (
                    <th
                      onClick={header.column.getToggleSortingHandler()}
                      style={{
                        cursor: "pointer",
                        userSelect: "none",
                        width: header.getSize(),
                      }}
                    >
                      <div className="d-flex w-100">
                        <div
                          className={
                            header.column.columnDef.align === "right"
                              ? "mr-auto"
                              : ""
                          }
                        ></div>
                        <div className="fw-bold">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                        <div className="position-relative ms-2">
                          <div className="react-table-sort position-absolute top-50 translate-middle-y">
                            <TriangleDown16
                              className={`position-absolute bottom-0 ${
                                !header.column.getIsSorted() ||
                                (header.column.getIsSorted() &&
                                  header.column.getIsSorted() !== "desc")
                                  ? "opacity-20"
                                  : ""
                              }`}
                            />
                            <TriangleUp16
                              className={`position-absolute top-0 ${
                                !header.column.getIsSorted() ||
                                (header.column.getIsSorted() &&
                                  header.column.getIsSorted() !== "asc")
                                  ? "opacity-20"
                                  : ""
                              }`}
                            />
                          </div>
                        </div>
                      </div>
                    </th>
                  ) : (
                    <th
                      // align-top for row selection and row options columns so positioning is same as <td>
                      className="align-top"
                      style={{
                        width: header.getSize(),
                        textAlign: header.column.columnDef.align,
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </th>
                  )}
                </Fragment>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <SpinnerLoading />
                </div>
              </td>
            </tr>
          )}
          {!loading &&
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    style={{textAlign: cell.column.columnDef.align}}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </Table>
      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};

DetailTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  enableRowSelection: PropTypes.bool,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  className: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  totalCount: PropTypes.number,
  onPaginationChange: PropTypes.func,
  onSortingChange: PropTypes.func,
  pagination: PropTypes.object,
  orderByColumns: PropTypes.arrayOf(PropTypes.object),
};

export default DetailTable;
