import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {Add24} from "@bphxd/ds-core-react/lib/icons";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {INBOUND_RECORDS} from "graphql/es-co-processing/InboundRecords";
import MARK_AS_COMPLETE from "graphql/es-co-processing/MarkAsComplete";
import moment from "moment";
import {useAppSetting} from "providers/appSetting/context.js";
import {useUserSettings} from "providers/userSettings";
import React, {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {Button, Modal, ModalBody, Spinner} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import CustomizeTable from "../components/CustomizeTable/CustomizeTable";
import DocumentTable from "../components/DocumentTable/DocumentTable";
import Layout from "../components/Layout";
import UploadDocuments from "../components/UploadDocuments";
import getColumns from "../components/incoming-documents/Columns";

const IncomingDocumentPage = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [isMarkAsCompleteOpen, setIsMarkAsCompleteOpen] = useState(false);
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const [showUploadModal, setUploadModal] = useState(false);
  const navigate = useNavigate();
  const {
    data: inboundDocs,
    loading: recordsLoading,
    refetch,
  } = useQuery(INBOUND_RECORDS, {
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });
  const [markAsComplete] = useMutation(MARK_AS_COMPLETE, {
    onCompleted: ({bioLcCoproEuMarkAsComplete}) => {
      setIsMarkAsCompleteOpen(false);
      if (bioLcCoproEuMarkAsComplete?.statusCode === 200) {
        toast.success(
          bioLcCoproEuMarkAsComplete?.message ??
            "Document successfully marked as completed",
        );
        refetch();
      } else {
        toast.error(
          bioLcCoproEuMarkAsComplete?.message ?? "Failed to mark as completed",
        );
      }
    },
  });

  const handleMarkAsComplete = useCallback(
    (sdNumber) => {
      setIsMarkAsCompleteOpen(true);
      markAsComplete({
        variables: {
          event: {
            changedBy: account?.username,
            sdNumber,
            userAction: "mark_complete",
            siteReferenceId: siteReferenceData?.siteReferenceId,
            divisionId: divisionData?.divisionId,
          },
        },
      });
    },
    [
      account?.username,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
      markAsComplete,
    ],
  );

  const tableData = useMemo(() => {
    const locations =
      inboundDocs?.bioLcCoproConfigCenterLocationDetails?.Records?.reduce(
        (acc, curr) => {
          acc[curr.location_code] = curr.location_name;
          return acc;
        },
        {},
      );
    return (inboundDocs?.bioLcCoproEuGetInboundRecords ?? []).map(
      ({stateMgmt, splitDetails, ...rest}) => ({
        stateMgmt,
        splitDetails: splitDetails.map((split) => ({
          ...split,
          status: stateMgmt,
          inboundDocStatusEuId: rest.inboundDocStatusEuId,
          certificateNumber: rest.certificateNumber,
          rawMaterialType: rest.rawMaterialType,
          supplierName: rest.supplierName,
          recipientReceiptAddress: rest.recipientReceiptAddress,
          mbLocation: locations[rest.mbLocationCode] ?? rest.mbLocation,
          mbLocationCode: rest.mbLocationCode,
          materialCode: rest.materialCode,
          physicalReceiptDate:
            split.physicalReceiptDate != null
              ? moment(new Date(split.physicalReceiptDate)).format(dateFormat)
              : "",
        })),
        ...rest,
        mbLocation: locations[rest.mbLocationCode] ?? rest.mbLocation,
        physicalReceiptDate:
          rest.physicalReceiptDate != null
            ? moment(new Date(rest.physicalReceiptDate)).format(dateFormat)
            : "",
      }),
    );
  }, [dateFormat, inboundDocs]);
  const tableCols = useMemo(
    () => getColumns(dateFormat, decimalFormat, handleMarkAsComplete),
    [dateFormat, decimalFormat, handleMarkAsComplete],
  );

  const [columns, setColumns] = useState(tableCols);
  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Spain", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Document manager", link: "/copro-spain/spain"},
    {
      text: "Incoming",
      link: "/copro-spain/spain/document-manager/incoming-documents",
    },
  ];
  return (
    <>
      <Modal
        size="sm"
        isOpen={isMarkAsCompleteOpen}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="flex flex-row pl-4 justify-start items-center gap-4">
            <Spinner />
            <span>Processing...</span>
          </div>
        </ModalBody>
      </Modal>
      <Layout title="Incoming documents" breadcrumbs={breadcrumbItems}>
        <div className="flex flex-col justify-stretch w-full items-start px-7">
          <div className=" flex flex-row justify-between w-full p-3 bg-white">
            <div className="bg-white">
              <CustomizeTable
                columns={columns}
                onColumnChange={(cols) => setColumns(cols)}
              />
            </div>
            <div>
              <Button
                color="primary"
                type="secondary"
                className="float-right rounded-0 text-gray-400"
                id="addDocumentButton"
                size="md"
                onClick={() => setUploadModal(!showUploadModal)}
              >
                Add document
                <Add24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>

          <div className="border-secondary border-top w-full">
            <DocumentTable
              columns={filteredColumns}
              data={tableData}
              className="incoming-docs-table"
              loading={recordsLoading}
            />
            {showUploadModal && <UploadDocuments />}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IncomingDocumentPage;
