/* eslint-disable no-restricted-globals */
import PropTypes from "prop-types";
import {BarChart, Bar, YAxis} from "recharts";
import {UncontrolledTooltip} from "reactstrap";
import VarianceInfoToolTip from "./VarianceInfoToolTip";
import ForecastArrowWidget from "./ForecastArrowWidget";
import displayDateLabel from "../../../utils/helpers/displayDateLabel";
import {
  getGraphBorder,
  getBarWidthEstimate,
  getBarCharts,
} from "../helpers/generateGraphElements";
import {isCurrentMonth, isFutureMonth} from "../helpers/dateUtils";

const labelStyles = {
  wrapper: ({showArrowIndicator}) =>
    [
      "px-6",
      "inventory-value-label",
      "items-baseline justify-items-end",
      "transition-all duration-100 ease-in-out",
      "text-[#3f3e45]",
      showArrowIndicator && "tracking-tighter",
    ].join(" "),
};

const ForecastBarGraphWidget = ({
  current,
  graphMaxValue,
  date,
  firstValue,
  secondValue,
  additionalValue,
  totalValue,
  firstDifference,
  secondDifference,
  additionalDifference,
  totalDifference,
  firstVariancePercent,
  secondVariancePercent,
  additionalVariancePercent,
  totalVariancePercent,
  isUnpublished = false,
  isPublishedView = false,
  isWestCoast = false,
  isCredits = false,
}) => {
  const currentGraph = getGraphBorder(current);
  const showArrowIndicator = !!firstDifference || !!secondDifference;
  const tooltipId = `tooltip-${Math.random().toString(36).substring(2, 9)}`;

  const getCellData = (value, variance, variancePercent, obligation) => {
    const cellData = {
      actualValue: value,
      displayDate: displayDateLabel(date),
      fieldName: "",
      variance,
      variancePercent: variance ? variancePercent : 0,
      systemValue: value - variance,
      type: obligation,
      dateType:
        !isPublishedView &&
        (isCurrentMonth(`${date.year}-${date.month}`) ||
          isFutureMonth(`${date.year}-${date.month}`)),
    };
    return cellData;
  };

  return (
    <div
      data-test="inventory-bar-graph-widget"
      className="flex flex-col justify-center items-center"
    >
      <div data-test="inventory-bar-graph-border" className={currentGraph}>
        <BarChart
          width={getBarWidthEstimate(
            firstValue,
            secondValue,
            totalValue,
            additionalValue,
          )}
          height={185}
          data={getBarCharts(
            firstValue,
            secondValue,
            additionalValue,
            totalValue,
            isWestCoast,
          )}
        >
          <YAxis hide="true" domain={[0, graphMaxValue]} />
          <Bar dataKey="total.value" fill="#395600" barSize={28} />
          <Bar dataKey="bar1.value" fill="#89cf00" barSize={28} />
          {isWestCoast && (
            <Bar dataKey="bar3.value" fill="#767676" barSize={28} />
          )}
          <Bar dataKey="bar2.value" fill="#ffe600" barSize={28} />
        </BarChart>
        <div
          data-test="inventory-bar-graph-value-label"
          className={`${labelStyles.wrapper({
            showArrowIndicator,
          })} max-w-[15rem] flex-wrap item-center align-center`}
        >
          <span className="align-baseline inline-flex items-center flex-col pr-1">
            <span>{totalValue?.toLocaleString("en-US")}</span>
            <span id={tooltipId}>
              <ForecastArrowWidget
                isVarianceOver4Percent={
                  totalVariancePercent > 4 || totalVariancePercent < -4
                }
                changedValue={totalDifference}
              />
            </span>
            {totalDifference && !isUnpublished && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                <VarianceInfoToolTip
                  cellData={getCellData(
                    totalValue,
                    totalDifference,
                    totalVariancePercent,
                    "total",
                  )}
                />
              </UncontrolledTooltip>
            )}
          </span>
          <span className="shrink pr-1">|</span>
          <span className="align-baseline inline-flex items-center flex-col pr-1">
            <span>{firstValue?.toLocaleString("en-US")}</span>

            <span id={tooltipId}>
              <ForecastArrowWidget
                isVarianceOver4Percent={
                  firstVariancePercent > 4 || firstVariancePercent < -4
                }
                changedValue={firstDifference}
              />
            </span>
            {firstDifference && !isUnpublished && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                <VarianceInfoToolTip
                  cellData={getCellData(
                    firstValue,
                    firstDifference,
                    firstVariancePercent,
                    isCredits ? "d4" : "gas",
                  )}
                />
              </UncontrolledTooltip>
            )}
          </span>
          {isWestCoast && <span className="shrink pr-1">|</span>}
          {isWestCoast && (
            <span className="align-baseline inline-flex items-center flex-col pr-1">
              <span>{additionalValue?.toLocaleString("en-US")}</span>

              <span id={tooltipId}>
                <ForecastArrowWidget
                  isVarianceOver4Percent={
                    additionalVariancePercent > 4 ||
                    additionalVariancePercent < -4
                  }
                  changedValue={additionalDifference}
                />
              </span>
              {additionalVariancePercent && !isUnpublished && (
                <UncontrolledTooltip placement="top" target={tooltipId}>
                  <VarianceInfoToolTip
                    cellData={getCellData(
                      additionalValue,
                      additionalDifference,
                      additionalVariancePercent,
                      "d5",
                    )}
                  />
                </UncontrolledTooltip>
              )}
            </span>
          )}
          <span className="shrink pr-1">|</span>
          <span className="align-baseline inline-flex items-center flex-col">
            <span>{secondValue?.toLocaleString("en-US")}</span>

            <span id={tooltipId}>
              <ForecastArrowWidget
                changedValue={secondDifference}
                isVarianceOver4Percent={
                  secondVariancePercent > 4 || secondVariancePercent < -4
                }
              />
            </span>
            {secondDifference && !isUnpublished && (
              <UncontrolledTooltip placement="top" target={tooltipId}>
                <VarianceInfoToolTip
                  cellData={getCellData(
                    secondValue,
                    secondDifference,
                    secondVariancePercent,
                    isCredits ? "d6" : "diesel",
                  )}
                />
              </UncontrolledTooltip>
            )}
          </span>
        </div>
      </div>
      <div
        data-test="inventory-bar-graph-month-label"
        className="inventory-month-label ml-0 align-center"
      >
        {date && date.month ? displayDateLabel(date) : "N/A"}
      </div>
    </div>
  );
};

ForecastBarGraphWidget.propTypes = {
  current: PropTypes.bool,
  graphMaxValue: PropTypes.number,
  date: PropTypes.object,
  firstValue: PropTypes.number,
  secondValue: PropTypes.number,
  additionalValue: PropTypes.number,
  totalValue: PropTypes.number,
  firstDifference: PropTypes.number,
  secondDifference: PropTypes.number,
  additionalDifference: PropTypes.number,
  totalDifference: PropTypes.number,
  firstVariancePercent: PropTypes.number,
  secondVariancePercent: PropTypes.number,
  additionalVariancePercent: PropTypes.number,
  totalVariancePercent: PropTypes.number,
  isUnpublished: PropTypes.bool,
  isPublishedView: PropTypes.bool,
  isWestCoast: PropTypes.bool,
  isCredits: PropTypes.bool,
};

export default ForecastBarGraphWidget;
