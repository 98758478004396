import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@apollo/client";
import {Input} from "antd";
import Fuse from "fuse.js";
import {POS_NL_NABISY_LIST} from "graphql/pos";
import "modules/common/Menu/index.scss";
import React, {useState, useMemo} from "react";
import useRowSelection from "modules/common/Table/useRowSelection";
import TableWidget from "modules/common/Table/TableWidget";
import {
  BasePageLayout,
  BasePageLayoutContent,
} from "modules/common/BasePageLayout";
import {useUserSettings, applyDateFormat} from "providers/userSettings";
import {posNLNabisyColumns} from "./content/posColumns";
import CountryDropdown from "./CountryDropdown";
import {posNLTabData} from "./Tabs";

const PoSNLNabisyDashboard = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const [search, setSearch] = useState("");

  const {data, loading} = useQuery(POS_NL_NABISY_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const nabisyData = useMemo(
    () => data?.getPOSRecordList?.data?.NLD_Nabisy ?? [],
    [data?.getPOSRecordList?.data?.NLD_Nabisy],
  );

  const [rowSelection] = useRowSelection(nabisyData, "posRecordId");

  const keys = [
    "typeOfProduct",
    "partialProof",
    "dateOfReceipt",
    "placeOfReceipt",
    "countryOfOrigin",
    "typeOfBiomass",
  ];

  const fuseOptions = {
    keys,
    threshold: 0.0,
    includeScore: true,
    ignoreLocation: true,
    distance: 100,
  };

  const dataFuse = new Fuse(nabisyData, fuseOptions);
  const items = search
    ? dataFuse.search(search).map(({item}) => item)
    : nabisyData;

  const formatedData = applyDateFormat(
    items,
    ["dateOfIssuance", "dateOfReceipt"],
    dateFormat,
  );

  return (
    <BasePageLayout
      pageTitle="Proof of Sustainability (PoS)"
      rightContent={<CountryDropdown />}
      tabsData={posNLTabData}
    >
      <BasePageLayoutContent>
        <div className="flex flex-col justify-between px-5 py-4 mt-10 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
          <div className="flex flex-col items-start sm:items-center sm:flex-row gap-y-2 sm:gap-x-3">
            {/* <GeneralActionsMenu>
                <DownloadCSVItem
                  data={selectedRows}
                  columns={posPLColumns}
                  filename={fileNames.fileNameTypes.FILE_POS}
                />
              </GeneralActionsMenu> */}
          </div>
          <div
            data-test="container-search-item"
            className="flex items-center gap-x-3"
          >
            <Input
              data-test="table-input-search"
              className="w-56 rounded"
              size="large"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              prefix={<SearchOutlined />}
            />
          </div>
        </div>
        <TableWidget
          columns={posNLNabisyColumns}
          data={formatedData}
          rowKey={(obj) => obj.posId}
          loading={loading}
          rowSelection={rowSelection}
        />
      </BasePageLayoutContent>
    </BasePageLayout>
  );
};

export default PoSNLNabisyDashboard;
