import {uniqBy} from "lodash";
import PropTypes from "prop-types";
import {useEffect, useMemo, useState} from "react";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

import Datepicker from "modules/GlobalMassBalance/components/Datepicker";

const CertificationDetails = ({masterDetails, currentFilterValues}) => {
  const {
    register,
    control,
    formState: {errors},
    watch,
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const certificationSystemsData = useMemo(() => {
    return uniqBy(
      masterDetails?.bioLcGetCertificationSchemeDetails
        ?.CertificationSchemeDetails ?? [],
      "certificateType",
    );
  }, [
    masterDetails?.bioLcGetCertificationSchemeDetails
      ?.CertificationSchemeDetails,
  ]);

  const watchCertificationSystem = watch("certificationSystem");

  const templateItems = useMemo(() => {
    const certDetailsList = [
      ...new Set(
        masterDetails?.bioLcGetCertificationSchemeDetails?.CertificationSchemeDetails?.filter(
          (objCertSystem) =>
            objCertSystem.certificateType === watchCertificationSystem,
        ),
      ),
    ];
    const uniqueTemplates = uniqBy(certDetailsList ?? [], (v) =>
      [v.templateType, v.templateVersionNo].join(),
    );
    return [...new Set(uniqueTemplates)];
  }, [
    watchCertificationSystem,
    masterDetails?.bioLcGetCertificationSchemeDetails
      ?.CertificationSchemeDetails,
  ]);
  const [templateList, settemplateList] = useState(templateItems ?? []);

  useEffect(() => {
    if (currentFilterValues?.mbLocationGroupName === "Tepsa Tarragona") {
      if (certificationSystemsData?.length > 0) {
        setValue("certificationSystem", "ISCC EU");
      }

      if (templateList?.length > 0) {
        setValue("templateType", "ISCC Standard 2.6");
      }
    }
  }, [
    currentFilterValues?.mbLocationGroupName,
    setValue,
    certificationSystemsData,
    templateList,
    watchCertificationSystem,
    currentFilterValues,
  ]);

  useEffect(() => {
    settemplateList(templateItems);
  }, [templateItems]);

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormGroup>
        <Label for="certificationSystem" className="fw-light">
          Certification system
        </Label>
        <Input
          type="select"
          id="certificationSystem"
          data-test="certificationSystem"
          invalid={!!errors.certificationSystem}
          {...computeProps("certificationSystem", {
            required: "Please select certification system",
          })}
          disabled={
            certificationSystemsData?.length === 0 ||
            currentFilterValues?.mbLocationGroupName === "Tepsa Tarragona"
          }
        >
          <option key="none" value="">
            --Select an option--
          </option>
          {certificationSystemsData?.map((cerSystem) => (
            <option
              key={cerSystem.certificateType}
              value={cerSystem.certificateType}
            >
              {cerSystem.certificateType}
            </option>
          ))}
        </Input>
        {errors.certificationSystem && (
          <FormFeedback>{errors.certificationSystem.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="templateType" className="fw-light">
          Template
        </Label>
        <Input
          type="select"
          id="templateType"
          data-test="templateType"
          invalid={!!errors.templateType}
          {...computeProps("templateType", {
            required: "Please select template",
          })}
          disabled={
            templateList?.length === 0 ||
            currentFilterValues?.mbLocationGroupName === "Tepsa Tarragona"
          }
        >
          <option key="none" value="">
            --Select an option--
          </option>
          {templateList?.map((template) => {
            return (
              <option
                key={`${template.templateType} ${
                  template.templateVersionNo ?? ""
                }`}
                value={`${template.templateType} ${
                  template.templateVersionNo ?? ""
                }`}
              >
                {`${template.templateType} ${template.templateVersionNo ?? ""}`}
              </option>
            );
          })}
        </Input>
        {errors.templateType && (
          <FormFeedback>{errors.templateType.message}</FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="issueDate" className="fw-light">
          Date of issuance
        </Label>
        <Datepicker
          id="issueDate"
          name="issueDate"
          {...computeProps("issueDate", {
            required: "Please enter date of issuance",
          })}
          control={control}
          error={errors.issueDate}
          rules={{required: "Please enter date of issuance"}}
          format="d/m/Y"
        />
        {errors.issueDate && (
          <FormFeedback>{errors.issueDate.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};

CertificationDetails.propTypes = {
  masterDetails: PropTypes.object,
  rowData: PropTypes.object,
  currentFilterValues: PropTypes.object,
};

export default CertificationDetails;
