import moment from "moment";
import {client} from "providers/Apollo";
import {convertToNumber} from "providers/userSettings";
import {
  CREATE_MANUAL_ADJUSTMENT,
  DELETE_MANUAL_ADJUSTMENT,
  MANUAL_ADJUSTMENT_EXPORT,
  UPDATE_MANUAL_ADJUSTMENT,
} from "./manualAdjustment";

export const manualAdjustmentExportApi = async (
  fileType,
  year = "",
  selectedRows = [],
) => {
  return client.query({
    query: MANUAL_ADJUSTMENT_EXPORT,
    fetchPolicy: "network-only",
    variables: {
      fileType,
      year,
      selectedRows: selectedRows.map((obj) => obj.id),
    },
  });
};

export const createManualAdjustmentApi = (data, decimalFormat) => {
  // Convert dates to server format
  const serverDateFormat = "YYYY-MM";
  const declaration = moment(data.declaration).format(serverDateFormat);
  const fisc = moment(data.fisc).format(serverDateFormat);
  const calEDQty = convertToNumber(data?.calEDQty, decimalFormat);

  return client.mutate({
    mutation: CREATE_MANUAL_ADJUSTMENT,
    variables: {
      record: {
        ...data,
        declaration,
        fisc,
        calEDQty,
      },
    },
    refetchQueries: ["GetManualAdjustmentQuery"],
  });
};

export const updateManualAdjustmentApi = async (data, decimalFormat) => {
  // Convert dates to server format
  const serverDateFormat = "YYYY-MM";
  const declaration = moment(data.declaration).format(serverDateFormat);
  const fisc = moment(data.fisc).format(serverDateFormat);
  const calEDQty = convertToNumber(data?.calEDQty, decimalFormat);

  return client.mutate({
    mutation: UPDATE_MANUAL_ADJUSTMENT,
    variables: {
      updateRecord: {
        taxLaw: data.taxLaw,
        category: data.category,
        description: data.description,
        mainCustomsOffice: data.mainCustomsOffice,
        gnETSRelevant: data.gnETSRelevant,
        tHGRelevant: data.tHGRelevant,
        uoM: data.uoM,
        calEDQty,
        loadPlant: data.loadPlant,
        commodityCode: data.commodityCode,
        eDGroup: data.eDGroup,
        companyCode: data.companyCode,
        id: data.id,
        declaration,
        fisc,
      },
    },
    refetchQueries: ["GetManualAdjustmentQuery"],
  });
};

export const deleteManualAdjustmentApi = (data) => {
  return client.mutate({
    mutation: DELETE_MANUAL_ADJUSTMENT,
    variables: {
      deleteRecord: {
        id: data.id,
        reason: data.comments,
      },
    },
    refetchQueries: ["GetManualAdjustmentQuery"],
  });
};
