import PropTypes from "prop-types";
import {Nav, TabContent} from "reactstrap";
import {Card} from "modules/common/Card";
import {checkValidArray} from "utils/helpers/checkData";
import {ZoomIn24, ZoomIn32} from "@bphxd/ds-core-react/lib/icons";
import Pane from "./Pane";
import NavigationItem from "./NavigationItem";
import SummaryGraph from "../SummaryGraph";

const Dashboard = ({
  activeTab,
  setActiveTab,
  complianceOpeningData,
  complianceCreditsData,
  complianceDeficitsData,
  complianceClosingData,
  complianceDataOpeningloading,
  complianceDataCreditsloading,
  complianceDataDeficitsloading,
  complianceDataClosingloading,
  mainObligationUnitText,
  onMore,
  onManualAdjustment,
  decimalFormat,
  complianceSchemeColumnsWithUOM,
  summaryGraphDataLoading,
  summaryGraphData,
  country,
  expandGraph,
}) => {
  return (
    <div className="compliance-dashboard">
      <div className="px-6 lg:px-10">
        <Nav tabs onChange={(activeKey) => setActiveTab(activeKey)}>
          <NavigationItem
            tabName="open"
            data={complianceOpeningData}
            isLoading={complianceDataOpeningloading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onMore={onMore}
            mainObligationUnitText={mainObligationUnitText}
            decimalFormat={decimalFormat}
            heading="Opening"
          />
          <NavigationItem
            tabName="credit"
            data={complianceCreditsData}
            isLoading={complianceDataCreditsloading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onMore={onMore}
            mainObligationUnitText={mainObligationUnitText}
            decimalFormat={decimalFormat}
            heading="Credits"
          />
          <NavigationItem
            tabName="deficit"
            data={complianceDeficitsData}
            isLoading={complianceDataDeficitsloading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onMore={onMore}
            mainObligationUnitText={mainObligationUnitText}
            decimalFormat={decimalFormat}
            heading="Deficits"
          />
          <NavigationItem
            tabName="close"
            data={complianceClosingData}
            isLoading={complianceDataClosingloading}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onMore={onMore}
            mainObligationUnitText={mainObligationUnitText}
            decimalFormat={decimalFormat}
            heading="Closing"
          />
        </Nav>
      </div>
      <div className="grid-table pl-5 pr-5 px-6 lg:px-10 bg-gray-100">
        {/* This is here so that TabContent and Graph are aligned */}
        <div className="h4">
          {activeTab === "deficit" ? "Deficits" : "Credits"}{" "}
        </div>
        <div className="grid grid-cols-2 mt-2 lg:grid-cols-3 sm:grid-cols-1 gap-x-5">
          <TabContent activeTab={activeTab} className="col-span-2">
            <Pane
              tabId="open"
              isLoading={complianceDataOpeningloading}
              data={complianceOpeningData}
              columns={complianceSchemeColumnsWithUOM}
              onMore={onMore}
              onManualAdjustment={onManualAdjustment}
              country={country}
            />
            <Pane
              tabId="credit"
              isLoading={complianceDataCreditsloading}
              data={complianceCreditsData}
              columns={complianceSchemeColumnsWithUOM}
              onMore={onMore}
              onManualAdjustment={onManualAdjustment}
              country={country}
            />
            <Pane
              tabId="deficit"
              isLoading={complianceDataDeficitsloading}
              data={complianceDeficitsData}
              columns={complianceSchemeColumnsWithUOM}
              onMore={onMore}
              onManualAdjustment={onManualAdjustment}
              country={country}
            />
            <Pane
              tabId="close"
              isLoading={complianceDataClosingloading}
              data={complianceClosingData}
              columns={complianceSchemeColumnsWithUOM}
              onMore={onMore}
              onManualAdjustment={onManualAdjustment}
              country={country}
            />
          </TabContent>
          <div className="col-span-2 lg:col-span-1">
            <div className="flex bg-white graph-header">
              <span className="mr-auto ml-3 fw-normal">Summary</span>
              <span className="pt-[14px]">
                <ZoomIn24
                  onClick={expandGraph}
                  className="cursor-pointer mr-3"
                />
              </span>
            </div>
            <Card className="summary-graph mb-4">
              <div className="relative w-full">
                <SummaryGraph
                  small
                  loading={summaryGraphDataLoading}
                  dataGraph={checkValidArray(summaryGraphData)}
                  ytickNumber={3}
                  containerHeight={500}
                  isExpandedView={false}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

Dashboard.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  complianceOpeningData: PropTypes.object,
  complianceCreditsData: PropTypes.object,
  complianceDeficitsData: PropTypes.object,
  complianceClosingData: PropTypes.object,
  summaryGraphData: PropTypes.array,
  complianceDataOpeningloading: PropTypes.bool,
  complianceDataCreditsloading: PropTypes.bool,
  complianceDataDeficitsloading: PropTypes.bool,
  complianceDataClosingloading: PropTypes.bool,
  summaryGraphDataLoading: PropTypes.bool,
  mainObligationUnitText: PropTypes.string,
  onMore: PropTypes.func,
  onManualAdjustment: PropTypes.func,
  decimalFormat: PropTypes.string,
  complianceSchemeColumnsWithUOM: PropTypes.array,
  country: PropTypes.string,
  expandGraph: PropTypes.func,
};

export default Dashboard;
