import {gql} from "@apollo/client";

export const SAVE_RULE = gql`
  mutation SaveRule($event: SaveBlockLinksRequest!) {
    bioLcComplianceRulesEngineSaveBlockLinks(event: $event) {
      block
      error
      field
      statusCode
      blockCode
      index
    }
  }
`;

export default {SAVE_RULE};
