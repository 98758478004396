import {COPRO_URL} from "modules/co-processing/constants/coProcessing";
import {TASKS_LABELS} from "modules/co-processing/constants/receipts";

const taskDetails = {
  receipts: {
    documentViewerURL: `${COPRO_URL}/document-viewer`,
    idKey: "cofa_id",
    noTaskMsg: TASKS_LABELS.NO_TASKS_RECEIPTS,
  },
  batches: {
    documentViewerURL: `${COPRO_URL}/batches/document-viewer`,
    idKey: "c14_id",
    noTaskMsg: TASKS_LABELS.NO_TASKS_BATCHES,
  },
  "credit-gen": {
    documentViewerURL: `${COPRO_URL}/credit-generation/document-viewer`,
    idKey: "", // Update this when the ID key for credit_gen is known
    noTaskMsg: TASKS_LABELS.NO_TASKS_CREDIT_GEN,
  },
};

const getDetailsByTask = (task_type) => taskDetails[task_type];

export default getDetailsByTask;
