import {Check24, Remove24, Remove48} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import {useForm, useWatch} from "react-hook-form";
import {INPUT_METRIC} from "modules/co-processing/constants/shipments";
import "./index.scss";
import _ from "lodash";
import {toast} from "react-toastify";
import {COPRO_US_SHIPMENTS_API_SPLIT_BOL} from "graphql/coprocessing/shipments";
import {useLazyQuery} from "@apollo/client";

export function splitBOLIntoParts(bol, shipmentVolume, allocatedVolume) {
  const bolVolume = _.round(bol.truck_lifting_volume, 2) || 0;
  const remainingVolume = _.round(shipmentVolume - allocatedVolume, 2);

  if (Number.isNaN(bolVolume) || Number.isNaN(remainingVolume)) {
    console.error("Invalid volume data");
    return {partA: 0, partB: 0};
  }
  return {
    partA: Math.min(remainingVolume, bolVolume),
    partB: _.round(Math.max(bolVolume - remainingVolume, 0), 2),
  };
}

const SplitBOLModal = ({
  bol,
  onClose,
  onSave,
  allocatedVolume,
  shipmentVolume,
}) => {
  const bolParts = splitBOLIntoParts(bol, shipmentVolume, allocatedVolume);
  const bolVolume = _.round(bol.truck_lifting_volume, 2) || 0;

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    clearErrors,
    setValue,
  } = useForm({defaultValues: bolParts, mode: "onChange"});

  const [volA, volB] = useWatch({control, name: ["partA", "partB"]});
  const partAVolume = parseFloat(volA) || 0;
  const partBVolume = parseFloat(volB) || 0;

  const handlePartChange = (field) => (event) => {
    const {value} = event.target;
    const newVolume = value.replace(/[^0-9.]/g, ""); // Remove non-numeric characters

    if (Number.isNaN(Number(newVolume))) return;

    setValue(field, newVolume);
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [splitBol] = useLazyQuery(COPRO_US_SHIPMENTS_API_SPLIT_BOL, {
    fetchPolicy: "network-only",
  });

  const onSplitBol = async (volume_a, volume_b) => {
    const response = await splitBol({
      variables: {
        request: {
          dtn_bol_layer_id: bol.dtn_bol_layer_id,
          volume_a,
          volume_b,
        },
      },
    });
    const data = response?.data?.bioLcCoproUsShipmentsApi;
    if (data?.error || data?.statusCode !== 200) {
      toast.error(data.error);
      return;
    }
    toast.success("Split successful.");
    onSave();
  };

  const onSaveSplit = async () => {
    const sumOfParts = _.round(partAVolume + partBVolume, 2);

    if (sumOfParts !== bolVolume) {
      ["partA", "partB"].forEach((part) => {
        setError(part, {
          type: "manual",
          message: `Sum of part A and part B do not match BOL volume ${bolVolume}.`,
        });
      });
      return;
    }
    clearErrors();
    onSplitBol(partAVolume, partBVolume);
  };

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  return (
    // z-index is necessary to keep bp-header visible at the top with the fullscreen
    <Modal id="split-bol-modal" size="xl" isOpen contentClassName="rounded-0">
      <ModalHeader
        id="split-bol-modal-header"
        className="text-xl ml-7"
        close={<Remove48 onClick={onClose} />}
      >
        Split BOL
      </ModalHeader>
      <ModalBody id="split-bol-modal-body">
        <div id="split-bol-modal-info" className="h3">
          {`BOL #${bol.outgoing_bol_number} - volume bbl ${formatNumber(
            bol.truck_lifting_volume,
            decimalFormat,
            2,
          )}`}
        </div>
        <div id="split-bol-modal-form">
          <Form id="form-split-bol" onSubmit={handleSubmit(onSaveSplit)}>
            {/* TODO - check metricInput.js */}
            <FormGroup className="mb-10">
              <div className="part-text">Part A</div>
              <div className="part-border"></div>
              <Label> Volume </Label>
              <InputGroup className="w-25">
                <Input
                  type="number"
                  name="partA"
                  step="0.01"
                  {...computeProps("partA", {
                    required: "Split volume is required",
                    valueAsNumber: true,
                    pattern: /^\d+\.\d{0,2}$/,
                    validate: (value) => {
                      if (value < 0) {
                        return "Volume must be positive.";
                      }
                      return true;
                    },
                  })}
                  onChange={handlePartChange("partA")}
                  invalid={!!errors?.partA}
                />
                <div
                  data-test="copro-metric-appended"
                  className="input-group-append"
                >
                  <div className="input-group-text">{INPUT_METRIC}</div>
                </div>
              </InputGroup>
              {errors?.partA && (
                <FormFeedback className="d-block" invalid="true">
                  {errors.partA.message}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup className="mb-10">
              <div className="part-text"> Part B </div>
              <div className="part-border"></div>
              <Label> Volume </Label>
              <InputGroup className="w-25">
                <Input
                  type="number"
                  name="partB"
                  step="0.01"
                  {...computeProps("partB", {
                    required: "This field is required",
                    valueAsNumber: true,
                    pattern: /^\d+\.\d{0,2}$/,
                    validate: (value) => {
                      if (value < 0) {
                        return "Value must be positive.";
                      }
                      return true;
                    },
                  })}
                  onChange={handlePartChange("partB")}
                  invalid={!!errors?.partB}
                />
                <div
                  data-test="copro-metric-appended"
                  className="input-group-append"
                >
                  <div className="input-group-text">{INPUT_METRIC}</div>
                </div>
              </InputGroup>

              {errors?.partB && (
                <FormFeedback className="d-block" invalid="true">
                  {errors.partB.message}
                </FormFeedback>
              )}
            </FormGroup>

            <p>{`Remaining volume: ${
              _.round(shipmentVolume - (allocatedVolume + partAVolume), 2) || 0
            } bbl`}</p>
          </Form>
        </div>
      </ModalBody>
      <div
        className="d-flex justify-content-end m-5"
        id="split-bol-modal-buttons"
      >
        <Button className="btn btn-light rounded-0 mr-2" onClick={onClose}>
          <Remove24 /> Cancel
        </Button>
        <Button type="submit" form="form-split-bol" className="btn rounded-0">
          <Check24 /> Save
        </Button>
      </div>
    </Modal>
  );
};

SplitBOLModal.propTypes = {
  bol: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  allocatedVolume: PropTypes.number,
  shipmentVolume: PropTypes.number,
};

export default SplitBOLModal;
