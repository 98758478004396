import {Input, Label} from "reactstrap";
import {useFormContext, Controller} from "react-hook-form";
import {uniqBy} from "lodash";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {useQuery} from "@apollo/client";
import {TRANSPORTATION_EMISSIONS} from "graphql/config-center/TransportationEmissions.js";
import DatePicker from "modules/common/DatePickerNew";
import {LOCATION_MASTER} from "graphql/config-center/LocationMaster.js";
import PropTypes from "prop-types";
import FormField from "modules/common/FormFieldNew.js";
import dateFunctions from "utils/helpers/dateFunctions";

const TransferDetails = ({data}) => {
  const {
    formState: {errors},
    watch,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {userSettings = {dateFormat: "m/d/Y"}} = useUserSettings();
  const userdateFormat = dateFunctions.convertDateFormattoUser(
    userSettings.dateFormat,
  );

  const {data: modeofTransportationDetails} = useQuery(
    TRANSPORTATION_EMISSIONS,
  );

  const {data: locationDetails} = useQuery(LOCATION_MASTER);

  const watchTransferLocation = watch("transferLocation");

  const motDetailsItems = [
    ...new Set(
      modeofTransportationDetails?.bioLcCoproConfigCenterTransportationEmission.Records.filter(
        (objitem) =>
          objitem.sending_location_code ===
            data?.inboundRecord?.mbLocationCode.toString() &&
          objitem.receiving_location_code === watchTransferLocation,
      ).map((obj) => ({
        mot_name: obj.mot_name,
        transportationEmissionId: obj.transportation_emission_id,
      })),
    ),
  ];

  const motDetails = uniqBy(motDetailsItems, "mot_name");

  const locationItems = [
    ...new Set(
      locationDetails?.bioLcCoproConfigCenterLocationMasterDetails?.Records?.filter(
        (objitem) =>
          objitem.location_type === "Refinery" ||
          objitem.location_type === "Transfer Terminal",
      )?.map((obj) => obj.location_code),
    ),
  ];

  const locations = [
    ...new Set(
      modeofTransportationDetails?.bioLcCoproConfigCenterTransportationEmission?.Records?.filter(
        (objitem) =>
          objitem?.sending_location_code ===
          data?.inboundRecord?.mbLocationCode.toString(),
      ).map((obj) => ({
        location_code: obj.receiving_location_code,
        location_name: obj.receiving_location_name,
      })),
    ),
  ];

  const uniqueLocations = Object.values(
    locations.reduce((arr, obj) => ({...arr, [obj.location_code]: obj}), {}),
  );

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField label="Quantity" errorMessage={errors.quantity?.message}>
        <Controller
          name="quantity"
          defaultValue={data?.quantity || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="number"
              id="quantity"
              data-test="quantity"
              invalid={!!errors.quantity}
            />
          )}
        />
        <Label className="text-sm font-light text-gray-600">{`You have ${formatNumber(
          parseFloat(data?.outboundRecords?.qtyAllocated),
          decimalFormat,
          2,
        )} t available`}</Label>
      </FormField>

      <FormField
        label="Transfer Location"
        errorMessage={errors.transferLocation?.message}
      >
        <Controller
          name="transferLocation"
          defaultValue={data?.transferLocation || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="transferLocation"
              data-test="transferLocation"
              invalid={!!errors.transferLocation}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {uniqueLocations?.map((location) => {
                if (locationItems?.includes(location?.location_code)) {
                  return (
                    <option
                      key={location.location_code}
                      value={location.location_code}
                    >
                      {location.location_name}
                    </option>
                  );
                }
                return null;
              })}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Mode of Transportation"
        errorMessage={errors.modeOfTransportation?.message}
      >
        <Controller
          name="modeOfTransportation"
          defaultValue={data?.modeOfTransportation || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="modeOfTransportation"
              data-test="modeOfTransportation"
              invalid={!!errors.modeOfTransportation}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {motDetails?.map((mot) => (
                <option
                  key={mot.mot_name}
                  value={`${mot.mot_name}#-#${mot.transportationEmissionId}`}
                >
                  {mot.mot_name}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Date of transfer"
        errorMessage={errors.dispatchDate?.message}
      >
        <Controller
          name="dispatchDate"
          render={({field: {ref, onChange, value, disabled}}) => (
            <DatePicker
              data-test="dispatchDate"
              placeholder="Date of transfer"
              id="dispatchDate"
              name="dispatchDate"
              invalid={!!errors.dispatchDate}
              options={{
                allowInput: true,
                mode: "single",
                defaultDate: value,
                userdateFormat,
                maxDate: "today",
                altFormat: userdateFormat,
                onChange: (selectedDates) => onChange(selectedDates[0]),
              }}
            />
          )}
        />
      </FormField>
      <FormField label="Vessel Name" errorMessage={errors.vesselName?.message}>
        <Controller
          name="vesselName"
          defaultValue={data?.vesselName || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="vesselName"
              data-test="vesselName"
              invalid={!!errors.vesselName}
              maxLength={20}
            />
          )}
        />
      </FormField>
    </div>
  );
};

TransferDetails.propTypes = {
  data: PropTypes.object,
};

export default TransferDetails;
