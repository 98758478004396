import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Button} from "reactstrap";
import CustomReactTable from "../CustomReactTable/CustomReactTable";
import "./ConfigurationTable.scss";

const ConfigurationTable = ({columns, data}) => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div className="bp-core config-table-table">
      <div className="filter-bar bg-white py-5 px-4 d-flex border-b">
        <div className="ml-auto">
          {columnFilter.length > 0 && (
            <Button
              className="mr-2"
              color="standard-tertiary rounded-0"
              onClick={() => setColumnFilter([])}
            >
              Clear all
            </Button>
          )}
          <Button
            color="standard-tertiary rounded-0"
            onClick={() => setShowFilter(!showFilter)}
          >
            Filters
            <Filter24 className="btn-icon-suffix" />
          </Button>
        </div>
      </div>

      <div className="bp-component-table">
        <CustomReactTable
          responsive
          columns={columns}
          data={data}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          tableClassName="dashboard-table table-column-sticky header-color"
          showFilter={showFilter}
        />
      </div>
    </div>
  );
};

ConfigurationTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default ConfigurationTable;
