import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import "./index.scss";
import getInputMap from "modules/common/getInputMap";
import React, {useMemo, useState, useEffect, useCallback} from "react";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";
import Button from "modules/common/Button";
import {PlusOutlined} from "@ant-design/icons";
import moment from "moment";

const PoSEditForm = ({data}) => {
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {
    formState: {errors},
    setValue,
    watch,
    getValues,
  } = useFormContext();
  const newRunningBalance = watch("newRunningBalance");
  const watchAdjustedVolumeValue = convertToNumber(
    watch("volumeForEthanol"),
    decimalFormat,
  );
  const posId = watch("euVoluntarySchemePartialProofNumber");
  const adjustedVolumeValue = Number.isNaN(parseFloat(data.volumeForEthanol))
    ? 0
    : parseFloat(data.volumeForEthanol);
  const inputMap = useMemo(() => getInputMap(data, errors), [data, errors]);

  const [landingPosId, setLandingPosId] = useState(posId);
  const initialHistory = useCallback(() => {
    const runningBalanceArray = [];
    newRunningBalance
      ?.toString()
      .replace(/'/g, '"')
      .split("|")
      .map((item) => runningBalanceArray.push(JSON.parse(item)));
    return runningBalanceArray;
  }, [newRunningBalance]);
  const [historyData, setHistoryData] = useState(initialHistory());
  const resetHistoryState = useCallback(() => setHistoryData({}), []);

  const initialBalance = useCallback(() => {
    const sum = initialHistory().reduce(
      (prev, curr) => parseFloat(prev) + parseFloat(curr.quantity),
      0,
    );
    const pageLoadBalance = adjustedVolumeValue - sum;
    return pageLoadBalance;
  }, [adjustedVolumeValue, initialHistory]);
  const [remainingBalance, setRemainingBalance] = useState(initialBalance);
  const [refRemainingBalance, setRefRemainingBalance] =
    useState(initialBalance);
  const reseRemainingBalanceState = useCallback(
    () => setRemainingBalance({}),
    [],
  );
  const resetRefRemainingBalanceState = useCallback(
    () => setHistoryData({}),
    [],
  );
  const recalculateRemainingBalance = useCallback(() => {
    const currentQty = parseFloat(refRemainingBalance);
    const oldQty = adjustedVolumeValue;
    const newQty = watchAdjustedVolumeValue;
    const difference = newQty - oldQty;
    return currentQty + difference;
  }, [refRemainingBalance, adjustedVolumeValue, watchAdjustedVolumeValue]);

  useEffect(() => {
    if (posId !== landingPosId) {
      setLandingPosId(posId);
      resetHistoryState();
      reseRemainingBalanceState();
      resetRefRemainingBalanceState();
      setHistoryData(initialHistory());
      setRemainingBalance(initialBalance());
      setRefRemainingBalance(initialBalance());
    }
    setRemainingBalance(recalculateRemainingBalance);
  }, [
    posId,
    landingPosId,
    decimalFormat,
    initialHistory,
    initialBalance,
    resetHistoryState,
    newRunningBalance,
    setRemainingBalance,
    setRefRemainingBalance,
    reseRemainingBalanceState,
    recalculateRemainingBalance,
    resetRefRemainingBalanceState,
  ]);

  const calculateRemainingBalance = (oldQty, newQty) => {
    const currentQty = refRemainingBalance;
    const finalQty = currentQty + (oldQty - newQty);
    setRemainingBalance(parseFloat(finalQty));
    setRefRemainingBalance(parseFloat(finalQty));
  };

  const setRunningBalance = (histString) => {
    const len = histString.length;
    const lastIndex = histString
      ?.toString()
      .replace(/'/g, '"')
      .lastIndexOf("|");
    if (len - 1 === lastIndex) {
      setValue("newRunningBalance", histString.substring(0, lastIndex));
    } else {
      setValue("newRunningBalance", histString);
    }
  };
  const handleQuantityChange = (e, i) => {
    const temporaryData = historyData;
    const oldQty = Number.isNaN(parseFloat(temporaryData[i].quantity))
      ? 0
      : parseFloat(temporaryData[i].quantity);
    const newQty = Number.isNaN(parseFloat(e.target.value))
      ? 0
      : parseFloat(e.target.value);
    temporaryData[i].quantity = newQty;
    setHistoryData(temporaryData);
    calculateRemainingBalance(oldQty, newQty);
    const histString = historyData.reduce((result, item, index) => {
      const parsedDate = getValues("date" + index).format("MM/DD/YYYY");
      return `${result}{'quantity':"${item.quantity}",'date':"${parsedDate}" }|`;
    }, "");
    setRunningBalance(histString);
  };
  const handleDateChange = (e, i) => {
    const temporaryData = historyData;
    const newDate = moment(e._d, dateFormat);
    temporaryData[i].date = newDate;
    setHistoryData(temporaryData);
    setValue("date" + i, newDate);

    const histString = historyData.reduce((result, item, index) => {
      const parsedDate = getValues("date" + index).format("MM/DD/YYYY");
      return `${result}{'quantity':"${item.quantity}",'date':"${parsedDate}"}|`;
    }, "");
    setRunningBalance(histString);
  };
  const dynamicItems = historyData.map((entry, index) => (
    <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-2">
      {inputMap.dynamicInputNumericFormatted(
        "quantity" + index,
        entry.quantity,
        "Draw" + (index + 1),
        {
          allowNegative: false,
          decimalFormat,
          index,
          handleQuantityChange,
        },
      )}
      {inputMap.dynamicInputDate("date" + index, entry.date, "Date", {
        format: dateFormat,
        disabled: false,
        index,
        handleDateChange,
      })}
    </div>
  ));
  return (
    <div className="pos-uk-edit-form">
      <div className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4">
        {inputMap.input("euVoluntarySchemePartialProofNumber", "PoS ID", {
          disabled: true,
        })}
        {inputMap.inputDate("vesselReceiptDate", "Date of Receipt", {
          format: dateFormat,
          disabled: true,
        })}
        {inputMap.inputDate("issuanceDate", "Issuance Date", {
          format: dateFormat,
          disabled: true,
        })}
        {inputMap.input("placeOfIssuance", "Place of Issuance", {
          disabled: true,
        })}
        {inputMap.input("supplier", "Supplier", {
          disabled: true,
        })}
        {inputMap.input("fuelType", "Type of Product", {
          disabled: true,
        })}
        {inputMap.input("feedstockType", "Type of Raw Material", {
          disabled: true,
        })}
        {inputMap.input("feedstockOrigin", "Feedstock Origin", {
          disabled: true,
        })}
        {inputMap.input("certificateType", "Certificate Type", {
          disabled: true,
        })}
        {inputMap.inputNumericFormatted("quantity", "Quantity [m3]", {
          disabled: true,
          decimals: 3,
          decimalFormat,
        })}
        {inputMap.inputNumericFormatted("ghgEmissions", "GHG Emissions", {
          disabled: true,
          decimalFormat,
        })}
        {inputMap.inputNumericFormatted(
          "outturnVolumeTotalByPosSl",
          "Outturn  Volume Total by PoS (SL)",
          {
            disabled: true,
            decimalFormat,
          },
        )}
        {inputMap.inputNumericFormatted("volumeForEthanol", "Adjusted Volume", {
          decimalFormat,
        })}
        {inputMap.inputNumericFormatted(
          "feedstockAllocationOfOutturnPct",
          "Feedstock Allocation of Outturn %",
          {
            decimals: 0,
            max: 100,
            decimalFormat,
          },
        )}
        {inputMap.inputNumericFormatted(
          "outturnFeedstockVolumeSl",
          "Outturn Feedstock Volume (SL)",
          {
            disabled: true,
            decimalFormat,
          },
        )}
        {inputMap.inputNumericFormatted(
          "remainingFeedstockVolumeSl",
          "Remaining Feedstock Volume (SL)",
          {
            disabled: true,
            decimalFormat,
          },
        )}
        {inputMap.input(
          "productionProcess",
          "Production Process/Additional Info",
        )}
        {inputMap.input("euVoluntaryScheme", "EU Voluntary Scheme", {
          disabled: true,
        })}
        {inputMap.input("quantityUnits", "ISCC PoS Quantity Units", {
          disabled: true,
        })}
        {inputMap.inputSelect(
          "defaultGhgSaving",
          "Default GHG Saving? Default or Non Default",
          {
            disabled: true,
          },
        )}
        {inputMap.inputNumericFormatted("ghgValuePct", "GHG Value %", {
          disabled: true,
          decimals: 3,
          decimalFormat,
        })}
        {inputMap.input(
          "finalBiofuelProduction",
          "The installation where the final biofuel was produced started physical production of biofuels after 5 October 2015",
          {
            disabled: true,
          },
        )}
        {inputMap.input("location", "Location", {disabled: true})}
        {inputMap.inputNumericFormatted("ghgEee", "GHG - Eee", {
          disabled: true,
          decimalFormat,
        })}
        {inputMap.input("contractNumber", "Contract Number", {
          disabled: true,
        })}
        {inputMap.input("deliveringVessel", "Delivering Vessel", {
          disabled: true,
        })}
      </div>
      <div>
        <h4 className="css-u5i4pv py-10">
          Log drawn down volumes based on adjusted volume( todays date )
        </h4>
        <div className="bp-card1 grid gap-2" style={{width: "60%"}}>
          {dynamicItems}
        </div>
      </div>
      {inputMap.inputHidden("newRunningBalance", "", "")}
      <div className="rowC py-6 space-x-2 ">
        <Button
          icon={<PlusOutlined />}
          type="sm1"
          data-test="addition-modal-btn"
          onClick={() => {
            setHistoryData([...historyData, {quantity: "", date: new Date()}]);
          }}
        >
          NEW DRAW
        </Button>
        <span style={{color: "#3A3B3C"}}>
          Remaining Balance : {formatNumber(remainingBalance, decimalFormat)}
        </span>
      </div>
    </div>
  );
};

PoSEditForm.propTypes = {
  data: PropTypes.object,
};

export default PoSEditForm;
