import PropTypes from "prop-types";
import {Down24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Button,
} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {formatNumber, useUserSettings} from "providers/userSettings";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import IconText from "./IconText";

const baseUrl = "/copro-spain/spain/document-manager/incoming-documents";

const getFormattedDecimal = (cell) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (cell.getValue()) {
    return formatNumber(
      parseAndRoundToTwoDecimal(cell.getValue()),
      decimalFormat,
      2,
    );
  }
  return cell.getValue();
};

const renderLink = (id, text, pageNo) => (
  <Link
    to={{
      pathname: `${baseUrl}/${id}`,
      search: `?pageNo=${pageNo}`,
    }}
    className="link-dark"
  >
    <u>{text}</u>
  </Link>
);

const RenderDropdown = ({
  sdNumber,
  stateMgmt,
  inboundDocStatusEuId,
  onMarkAsComplete = () => {},
  pageNo,
}) => {
  const navigate = useNavigate();
  return (
    <UncontrolledDropdown direction="up">
      <DropdownToggle color="standard-quartenary" className="!px-0">
        <Kebab24></Kebab24>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          onClick={() =>
            navigate({
              pathname: `${baseUrl}/${inboundDocStatusEuId}`,
              search: `?pageNo=${pageNo}`,
            })
          }
        >
          View document
        </DropdownItem>
        <DropdownItem
          disabled={stateMgmt !== "ready_for_mb"}
          onClick={() =>
            navigate({
              pathname: `${baseUrl}/${inboundDocStatusEuId}`,
              search: `?pageNo=${pageNo}`,
            })
          }
        >
          Assign to mass balance
        </DropdownItem>
        <DropdownItem
          disabled={stateMgmt !== "additional_info"}
          onClick={() =>
            navigate({
              pathname: `${baseUrl}/${inboundDocStatusEuId}`,
              search: `?pageNo=${pageNo}`,
            })
          }
        >
          Add additional reference
        </DropdownItem>
        <DropdownItem
          disabled={
            stateMgmt === "completed" ||
            stateMgmt !== "additional_info" ||
            stateMgmt === "rejected"
          }
          onClick={() => onMarkAsComplete(sdNumber)}
        >
          Mark as complete
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

RenderDropdown.propTypes = {
  sdNumber: PropTypes.string,
  stateMgmt: PropTypes.string,
  inboundDocStatusEuId: PropTypes.string,
  onMarkAsComplete: PropTypes.func,
  pageNo: PropTypes.number,
};

const getColumns = (dateFormat, decimalFormatNew, onMarkAsComplete) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Down24 /> : <Up24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Required Actions",
      key: "requiredActions",
      accessorKey: "inboundDocStatusEuId",
      cell: ({row, getValue, table}) => (
        <IconText
          status={row.original.stateMgmt}
          link={{
            pathname: `${baseUrl}/${getValue()}`,
            search: `?pageNo=${table.getState()?.pagination?.pageIndex}`,
          }}
        />
      ),
      maxSize: 400,
      visible: true,
      defaultOption: true,
    },
    {
      header: "Certificate ID",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table}) =>
        renderLink(
          row.original.inboundDocStatusEuId,
          getValue(),
          table.getState()?.pagination?.pageIndex,
        ),
      maxSize: 400,
      visible: true,
      defaultOption: true,
    },
    {
      header: "Raw Material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      visible: true,
      defaultOption: true,
    },
    {
      header: "Quantity",
      accessorKey: "feedstockQty",
      key: "feedstockQty",
      size: 110,
      visible: true,
      dataType: "number",
      defaultOption: true,
      cell: (cell) => getFormattedDecimal(cell),
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 200,
      visible: true,
      defaultOption: true,
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      defaultOption: true,
    },

    {
      header: "Mass Balance",
      accessorKey: "mbLocation",
      key: "mbLocation",
      size: 110,
      visible: true,
      defaultOption: true,
      cell: ({getValue, row}) => {
        const value = getValue();
        if (row.original.splitDetails?.length > 0) {
          return (
            <p className="text-black">{`${row.original?.mbLocation ?? ""} ${
              row.original.splitDetails?.length
            }/${row.original.splitDetails?.length}`}</p>
          );
        }
        if (
          value != null &&
          row.original.mbCycle !== null &&
          row.subRows?.length === 0
        ) {
          const qtr = row.original.mbCycle ?? "      ";
          return (
            <Link
              to={`/copro-spain/spain/mass-balance/${
                row.original.mbLocationCode
              }/${encodeURIComponent(value)}/${
                row.original.materialCode
              }/${qtr.substring(4, 6)}/${qtr.substring(0, 4)}`}
              className="link-dark"
            >
              <u>
                {value}, {qtr.substring(4, 6)} {qtr.substring(0, 4)}
              </u>
            </Link>
          );
        }
        return "";
      },
    },
    {
      header: "Date of physical receipt",
      accessorKey: "physicalReceiptDate",
      key: "physicalReceiptDate",
      size: 110,
      visible: false,
    },
    {
      header: "Invoice number",
      accessorKey: "invoiceProviderName",
      key: "invoiceProviderName",
      size: 110,
      visible: false,
    },
    {
      header: "Vessel name",
      accessorKey: "vesselAtHuelva",
      key: "vesselAtHuelva",
      size: 110,
      visible: false,
    },
    {
      header: "GHG total",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: (cell) => getFormattedDecimal(cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {sdNumber, stateMgmt, inboundDocStatusEuId},
        },
        table,
      }) => (
        <RenderDropdown
          sdNumber={sdNumber}
          onMarkAsComplete={onMarkAsComplete}
          stateMgmt={stateMgmt}
          inboundDocStatusEuId={inboundDocStatusEuId}
          pageNo={table.getState()?.pagination?.pageIndex}
        />
      ),
    },
  ];
};

export default getColumns;
