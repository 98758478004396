import {Progress} from "antd";
import PropTypes from "prop-types";
import {useUserSettings, formatNumber} from "providers/userSettings";

const showProgressBarColor = (percentageValue) => {
  const percentage = Number(percentageValue);
  switch (true) {
    case percentage === 100:
      return "green";
    case percentage > 75 && percentage < 100:
      return "orange";
    case percentage <= 75:
      return "red";
    case percentage > 100:
      return "green";
    default:
      return "yellow";
  }
};

const ProgressBar = ({percentage}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  return (
    <div className="justify-between w-[96%] align-middle">
      <Progress
        data-test={`progress-bar-color-${showProgressBarColor(percentage)}`}
        percent={percentage}
        strokeColor={showProgressBarColor(percentage)}
        trailColor="#d2d2d4"
        format={(percent) => `${formatNumber(percent, decimalFormat)}%`}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  percentage: PropTypes.number,
};

export default ProgressBar;
