import DatePicker from "modules/common/DatePickerNew";
import PropTypes from "prop-types";
import React from "react";
import {Remove24} from "@bphxd/ds-core-react/lib/icons";
import "./index.scss";

const ComplianceDatePicker = React.forwardRef(({...props}, ref) => {
  const {isShown} = props;
  const {setIsShown} = props;
  const {showInvalid} = props;
  const {setShowInvalid} = props;

  const datePicker = (
    <DatePicker
      ref={ref}
      {...props}
      invalid={showInvalid && props.invalid}
      className="compliance-datepicker"
    />
  );
  const removeButton = (
    <div
      className="compliance-datepicker-remove"
      onClick={(e) => {
        props.setDate("");
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
      }}
      onKeyDown={() => {}}
    >
      <Remove24 />
    </div>
  );

  let removeDiv = null;
  if (!props.isEmpty && isShown) {
    removeDiv = removeButton;
  }

  return (
    <div
      onKeyDown={() => {}}
      className="compliance-datepicker-container"
      onMouseEnter={() => {
        setIsShown(true);
        setShowInvalid(false);
      }}
      onMouseLeave={() => {
        setIsShown(false);
        setShowInvalid(true);
      }}
      onFocus={() => {}}
      onClick={() => {}}
      onBlur={() => {}}
    >
      {datePicker}
      {removeDiv}
    </div>
  );
});

ComplianceDatePicker.propTypes = {
  isEmpty: PropTypes.bool,
  invalid: PropTypes.bool,
  setDate: PropTypes.func,
  style: PropTypes.object,
  isShown: PropTypes.bool,
  setIsShown: PropTypes.func,
  showInvalid: PropTypes.bool,
  setShowInvalid: PropTypes.func,
};

export default ComplianceDatePicker;
