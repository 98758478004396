import "./index.css";

import {Modal, Spin} from "antd";
import React from "react";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";

import InvoicePreviewContent from "./InvoicePreviewContent";
import {
  getDocumentResponse,
  getQuery,
  getTitle,
  getVariables,
} from "./InvoicePreviewCommon";

const InvoicePreview = ({preview, invoiceDetails, isInvoice}) => {
  const {type, test_result, countryCode} = invoiceDetails;

  const variables = getVariables(
    type,
    invoiceDetails,
    isInvoice,
    countryCode,
    test_result,
  );

  const query = getQuery(type);

  const {data, loading} = useQuery(query, {
    fetchPolicy: "network-only",
    variables,
  });

  const documentResponse = getDocumentResponse(type, data);

  const title = getTitle(type, invoiceDetails, isInvoice, test_result);
  return (
    <div
      key="invoice-preview-container"
      className="flex justify-center mx-3 my-3"
      data-test="cherrypoint-dashboard-invoice-popup"
    >
      <Modal
        width="80%"
        data-test="cherrypoint-dashboard-invoice-popup-modal"
        style={{top: 32}}
        bodyStyle={{height: "80vh"}}
        closable
        onCancel={() => preview(false)}
        visible="true"
        title={
          <div
            data-test="cherrypoint-dashboard-invoice-popup-title"
            className="flex items-center text-2xl font-bold text-black"
          >
            {title}
          </div>
        }
        footer={[
          <div
            key="invoice-preview-footer"
            className="flex flex-row justify-around"
            data-test="cherrypoint-dashboard-invoice-popup-modal-footer"
          >
            <a
              className="my-1"
              disabled={
                loading ||
                (documentResponse?.url === "" &&
                  documentResponse?.url_download === "")
              }
              data-test="invoice-footer-preview-online-button"
              href={documentResponse?.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                disabled={
                  loading ||
                  (documentResponse?.url === "" &&
                    documentResponse?.url_download === "")
                }
                type="button"
                data-test="cherrypoint-dashboard-invoice-popup-modal-footer-button-preview"
                className="px-2 py-2 text-sm font-bold text-center text-black bg-white border-2 border-gray-800 rounded w-36 hover:border-gray-400"
              >
                PREVIEW ONLINE
              </button>
            </a>
            <a
              disabled={
                loading ||
                (documentResponse?.url === "" &&
                  documentResponse?.url_download === "")
              }
              className="my-1"
              data-test="invoice-footer-download-button"
              href={documentResponse?.url_download}
              download
            >
              <button
                disabled={
                  loading ||
                  (documentResponse?.url === "" &&
                    documentResponse?.url_download === "")
                }
                type="button"
                data-test="cherrypoint-dashboard-invoice-popup-modal-footer-button-download"
                className="px-2 py-2 text-sm font-bold text-center text-white rounded hover:opacity-80 bg-bp-green-800 w-36"
              >
                DOWNLOAD
              </button>
            </a>
          </div>,
        ]}
      >
        {loading ? (
          <div
            data-test="loading-content"
            className="flex flex-col items-center justify-center w-full h-full"
          >
            <Spin
              className="flex items-center content-center justify-center "
              spinning="true"
            ></Spin>
          </div>
        ) : (
          <InvoicePreviewContent
            documentResponse={documentResponse}
            invoiceDetails={invoiceDetails}
            loading={loading}
          />
        )}
      </Modal>
    </div>
  );
};

InvoicePreview.propTypes = {
  preview: PropTypes.func,
  invoiceDetails: PropTypes.object.isRequired,
  isInvoice: PropTypes.bool.isRequired,
};

export default InvoicePreview;
