export const calculateVolumes = (parts) => {
  if (parts?.length === 0) {
    return {totalActualVolume: 0, previousAllocatedVol: 0};
  }

  const totalActualVolume = parts[0]?.total_actual_volume || 0;
  const previousAllocatedVol = parts.reduce(
    (acc, {total_allocated_volume}) => acc + (total_allocated_volume || 0),
    0,
  );

  return {totalActualVolume, previousAllocatedVol};
};

export const sumBOLVolumes = (rowSelection, BOLs, allocatedVolume) => {
  return Object.keys(rowSelection).reduce((acc, index) => {
    return acc + BOLs[index].truck_lifting_volume;
  }, allocatedVolume);
};
