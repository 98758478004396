import * as yup from "yup";

export const editionFormSchema = yup
  .object()
  .shape({
    companyCode: yup.string().label("Company code").required(),
    fisc: yup.string().label("Fiscal month year").required(),
    declaration: yup.string().label("Declaration in month").required(),
    eDGroup: yup.string().label("ED group").required(),
    commodityCode: yup.string().label("Commodity code").required(),
    loadPlant: yup.string().label("Load plant").required(),
    calEDQty: yup.string().label("Calc ED qty").required(),
    uoM: yup.string().label("UOM").required(),
    tHGRelevant: yup.boolean().label("THG relevant").required(),
    gnETSRelevant: yup.boolean().label("Gnet relevant").required(),
    mainCustomsOffice: yup.string().label("Main customs office"),
    description: yup.string().label("Description").max(500),
    category: yup.string().label("Category").required(),
    taxLaw: yup.string().label("Tax law").required(),
  })
  .required();

export const deleteFormSchema = yup.object().shape({
  reason: yup.string().label("Comments").max(250),
});
