import {useLazyQuery} from "@apollo/client";
import {DOWNLOAD_CSV_TEMPLATE} from "graphql/file-upload";
import PropTypes from "prop-types";
import {toast} from "react-toastify";

const Instructions = ({items, downloadTemplate}) => {
  const [downloadTemplateQuery, {data: dataRes}] = useLazyQuery(
    DOWNLOAD_CSV_TEMPLATE,
    {
      onCompleted: () => {
        if (
          dataRes !== null &&
          dataRes?.templateDownloader?.body != null &&
          dataRes?.templateDownloader?.body !== undefined
        ) {
          toast.success("Template downloaded successfully");
          document.location.href = dataRes?.templateDownloader?.body;
        } else {
          toast.error("Error downloading file. Please try again later.");
        }
      },
    },
  );

  const onDownloadLatestTemplateClicked = () => {
    downloadTemplateQuery();
  };

  return (
    <div data-test="instructions-comp">
      <div className="flex items-center gap-x-4">
        <h4 data-test="title" className="text-xl font-bold text-indigo-500">
          Instructions
        </h4>
        <div className="w-3/5 border border-gray-100" />
      </div>
      <ul className="mt-4 leading-7 mx-7">
        {items.map((item, i) => (
          <li
            key={i}
            data-test="instructions"
            className="list-disc text-indigo-500"
          >
            {item}
          </li>
        ))}
      </ul>
      {downloadTemplate && (
        <div className="mt-10 ">
          <button
            type="button"
            onClick={onDownloadLatestTemplateClicked}
            data-test="download"
            className="px-4 py-3 font-bold border-2 cursor-pointer text-indigo-500 border-indigo-500 hover:opacity-50"
          >
            DOWNLOAD LATEST TEMPLATE
          </button>
        </div>
      )}
    </div>
  );
};

Instructions.propTypes = {
  items: PropTypes.array.isRequired,
  downloadTemplate: PropTypes.bool,
};

export default Instructions;
