import PropTypes from "prop-types";
import {Alert, Button} from "reactstrap";
import {Check24} from "@bphxd/ds-core-react/lib/icons";
import {BUTTONS} from "modules/co-processing/constants/batches";

const SuccessView = ({onReset, closeModal}) => {
  return (
    <div className="addBatch__success">
      <Alert className="addBatch__success-alert justify-center">
        <Check24 />

        <div> Batch successfully created </div>
      </Alert>
      <span className="addBatch__success-create-another">
        Create another batch or continue to BOL allocation.
      </span>

      <div className="addBatch__btn-group">
        <Button className="addBatch__btn-tertiary" onClick={onReset}>
          {BUTTONS.CREATE_ANOTHER}
        </Button>
        <Button
          className="addBatch__btn-primary"
          onClick={() => {
            onReset();
            closeModal();
          }}
        >
          {BUTTONS.CLOSE}
        </Button>
      </div>
    </div>
  );
};

SuccessView.propTypes = {onReset: PropTypes.func, closeModal: PropTypes.func};

export default SuccessView;
