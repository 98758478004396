import * as LABELS from "constants/ceemasValidationLabels";
import * as URLS from "constants/urls";

const dataTestCeemasMOTValidation = "ceemas-mot-validation";
const dataTestMOTmanualAdjustment = "mot-manual-adjustment";

export const motTabData = [
  {
    label: LABELS.LABEL_TAB_CEEMAS_MOT,
    to: URLS.URL_CEEMAS_VALIDATION,
    "data-test": dataTestCeemasMOTValidation,
  },
  {
    label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
    to: URLS.URL_MANUAL_ADJUSTMENT,
    "data-test": dataTestMOTmanualAdjustment,
  },
];

export const gyMasterTabData = [
  {
    label: LABELS.LABEL_TAB_TAX_PLANT_REGISTRY,
    to: URLS.URL_TAX_PLANT_REGISTRY,
    "data-test": "nets-tax-plant-registry",
  },
  {
    label: LABELS.LABEL_TAB_TAX_NETS_CO2_COSTS,
    to: URLS.URL_NETS_CO2_COST,
    "data-test": "nets-cot2-costs",
  },
  {
    label: LABELS.LABEL_TAB_COPRO_SPAIN,
    to: URLS.URL_COPRO_SPAIN,
    "data-test": "copro-spain-settings",
  },
];

export const motTabDataPlantLevelItems = (filterParamDetails) => {
  const motTabDataPlantLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-plant-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];

  return motTabDataPlantLevel;
};

export const motTabDataEDGroupLevelItems = (filterParamDetails) => {
  const motTabDataEDGroupLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-edgroup-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];
  return motTabDataEDGroupLevel;
};

export const motTabDataBalGroupLevelItems = (filterParamDetails) => {
  const motTabDataBalGroupLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-balancegroup-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];
  return motTabDataBalGroupLevel;
};

export const motTabDataMaterialLevelItems = (filterParamDetails) => {
  const motTabDataMaterialLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-material-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];
  return motTabDataMaterialLevel;
};

export const motTabDataDayLevelItems = (filterParamDetails) => {
  const motTabDataDayLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-day-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];
  return motTabDataDayLevel;
};
export const motTabDataTransactionLevelItems = (filterParamDetails) => {
  const motTabDataTransactionLevel = [
    {
      label: LABELS.LABEL_TAB_CEEMAS_MOT,
      to: `/gy-enablement/ceemas-mot-validation-transaction-level/${encodeURIComponent(
        JSON.stringify({
          company: filterParamDetails?.company,
          complianceyear: filterParamDetails?.complianceyear,
          compliancemonth: filterParamDetails?.compliancemonth,
          ed: filterParamDetails?.ed,
          legalentity: filterParamDetails?.legalentity,
          material: filterParamDetails?.material,
          plant: filterParamDetails?.plant,
          date: filterParamDetails?.date,
          balancegroup: filterParamDetails?.balancegroup,
          motProfile: filterParamDetails?.motProfile,
        }),
      )}`,
      "data-test": dataTestCeemasMOTValidation,
    },
    {
      label: LABELS.LABEL_TAB_MANUAL_ADJUSTMENT,
      to: URLS.URL_MANUAL_ADJUSTMENT,
      "data-test": dataTestMOTmanualAdjustment,
    },
  ];
  return motTabDataTransactionLevel;
};
export default {motTabData, gyMasterTabData};
