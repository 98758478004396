import {Fragment} from "react";
import DealNumberInput from "../components/DealNumberInput";
import {
  MONTHS,
  UPDATE_REASONS,
  dieselKeyValuePairs,
  dieselKeyValuePairsToPublish,
  gasKeyValuePairs,
  gasKeyValuePairsGeneral,
  creditsAdjustmentSummaryLabels,
  FORECAST_TYPE,
} from "../constants/inventory";

/**
 * Converts key from the format of "user_obligated_blended_production_gas" to "Gas Production".
 * Adds Gas or Diesel to differentiate production and exports.
 */
export const measurementKeyToLabel = (givenKey) => {
  if (givenKey in creditsAdjustmentSummaryLabels) {
    return creditsAdjustmentSummaryLabels[givenKey];
  }

  if (givenKey.includes("diesel")) {
    const list = ["Production", "Exports", "Other Volumes Excluded"];
    const dieselFoundKey = Object.keys(dieselKeyValuePairsToPublish).filter(
      (key) => {
        return givenKey.includes(dieselKeyValuePairsToPublish[key]);
      },
    )[0];

    return list.includes(dieselKeyValuePairs[dieselFoundKey])
      ? `Diesel ${dieselKeyValuePairs[dieselFoundKey]}`
      : dieselKeyValuePairs[dieselFoundKey];
  }
  const gasFoundKey = Object.keys(gasKeyValuePairsGeneral).filter((key) => {
    return givenKey.includes(gasKeyValuePairsGeneral[key]);
  })[0];

  return `Gas ${gasKeyValuePairs[gasFoundKey]}`;
};

const formatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  signDisplay: "exceptZero",
});

export const generateAdjustmentSummary = (
  summaryArray,
  calculateAmount = false,
  toPublish = false,
) => {
  let amount = 0;
  const obj = summaryArray.reduce((acc, item) => {
    const valueOfInterest = toPublish ? item.diff : item.value;
    const year = item.year % 100;
    const month = MONTHS[item.month - 1];
    const value = formatter.format(valueOfInterest);
    const key = measurementKeyToLabel(item.key);
    const itemComments = item.itemComments || "";
    const reason = item.reason
      ? item.reason
          .map((reasonItem) => UPDATE_REASONS[reasonItem.toUpperCase()])
          .join(", ")
      : "";

    if (calculateAmount) {
      amount += valueOfInterest;
    }

    const keyName = `${month} '${year}`;
    if (!acc[keyName]) {
      acc[keyName] = [{year, month, key, value, itemComments, reason}];
    } else {
      acc[keyName].push({year, month, key, value, itemComments, reason});
    }
    return acc;
  }, {});

  const commentEntries = Object.entries(obj);

  const resultString = (
    <div className="flex flex-col">
      {commentEntries.map(([month, items], yearIndex) => (
        <span key={month} className="inline-block">
          {`${month} `}
          {items.map((item, itemIndex) => (
            <Fragment key={itemIndex}>
              {`${item.key} ${item.value}${` ${
                item.reason ? item.reason : ""
              }`}`}
              {item.itemComments && item.itemComments.length > 0 ? (
                <>
                  {" - "}
                  <span
                    className="font-bold"
                    data-test="adjustment-summary-item-comment"
                  >
                    {item.itemComments}
                  </span>
                </>
              ) : (
                ""
              )}
              {yearIndex !== commentEntries.length - 1 ||
              itemIndex !== items.length - 1
                ? ", "
                : ""}
            </Fragment>
          ))}
        </span>
      ))}
    </div>
  );

  return {resultString, amount};
};

const sortArrayPayload = (arr) => {
  const sortedArray = arr.bioLcForecastAuditLogService.data.map((item) => {
    const payload = JSON.parse(item.payload);

    payload.sort((a, b) => {
      return a.year === b.year ? a.month - b.month : a.year - b.year;
    });

    return {...item, payload};
  });

  return sortedArray;
};

export const getLogCount = (arr) => arr?.bioLcForecastAuditLogService?.count;

export const savedVersionsTableColumns = [
  {
    title: "Date",
    className: "saved-versions-title",
    dataIndex: "date",
    width: "10%",
    align: "left",
  },
  {
    title: "Amount",
    className: "saved-versions-title",
    dataIndex: "amount",
    width: "5%",
    align: "left",
    render: (value) => {
      return <span>{value.toLocaleString("en-US")}</span>;
    },
  },
  {
    title: "Name",
    className: "saved-versions-title",
    dataIndex: "name",
    width: "10%",
    align: "left",
  },
  {
    title: "Forecast Type",
    className: "saved-versions-title",
    dataIndex: "forecast_type",
    width: "10%",
    align: "left",
  },
  {
    title: "Comments",
    className: "saved-versions-title",
    dataIndex: "comments",
    width: 400,
    align: "left",
  },
];

export const publishedViewsTableColumns = (handleInputChange) => {
  const columns = [
    {
      title: "Date",
      className: "saved-versions-title",
      dataIndex: "date",
      width: "10%",
      align: "left",
    },
    {
      title: "Amount",
      className: "saved-versions-title",
      dataIndex: "amount",
      width: "5%",
      align: "left",
      render: (value) => {
        return <span>{value.toLocaleString("en-US")}</span>;
      },
    },
    {
      title: "Order ID",
      className: "saved-versions-title",
      dataIndex: "name",
      width: "10%",
      align: "left",
    },
    {
      title: "Deal Number",
      className: "saved-versions-title",
      dataIndex: "dealNumber",
      width: "10%",
      align: "left",
      render: (value, record) => {
        return (
          <DealNumberInput
            inputVal={value}
            record={record}
            handleInputChange={handleInputChange}
          />
        );
      },
    },
    {
      title: "Region",
      className: "saved-versions-title",
      dataIndex: "region",
      width: "5%",
      align: "left",
    },
    {
      title: "Program",
      className: "saved-versions-title",
      dataIndex: "program",
      width: "5%",
      align: "left",
    },
    {
      title: "Transaction",
      className: "saved-versions-title",
      dataIndex: "transactionType",
      width: "5%",
      align: "left",
    },
    {
      title: "Forecast Type",
      className: "saved-versions-title",
      dataIndex: "forecast_type",
      width: "10%",
      align: "left",
    },
    {
      title: "Comments",
      className: "saved-versions-title",
      dataIndex: "comments",
      width: 400,
      align: "left",
    },
  ];

  return columns;
};

const generateSaveVersionsData = (givenArr, type) => {
  if (Object.keys(givenArr).length === 0) {
    return [];
  }

  const sortedArray = sortArrayPayload(givenArr);
  const result = sortedArray.map((individualObj, index) => {
    const {
      comments: itemComment,
      payload,
      name,
      dateString,
      timestamp,
      forecast_type,
    } = individualObj;

    const toPublish = type === "Published";

    const {resultString, amount} = generateAdjustmentSummary(
      payload,
      true,
      toPublish,
    );

    // Comments jsx for the table item
    const comments = (
      <>
        {resultString}
        {!itemComment || itemComment.length === 0 ? (
          ""
        ) : (
          <span className="font-bold" data-test="adjustment-summary-comment">
            {itemComment}
          </span>
        )}
      </>
    );

    return {
      key: index + 1,
      name,
      comments,
      amount,
      timestamp,
      forecast_type:
        forecast_type === "rin"
          ? FORECAST_TYPE.CREDITS
          : FORECAST_TYPE.DEFICITS,
      date: dateString,
      ...(toPublish
        ? {
            dealNumber: individualObj.dealNumber,
            region: individualObj.region,
            program: individualObj.program,
            transactionType: individualObj.transactionType,
            error: false,
            uuid: individualObj.uuid,
          }
        : {}),
    };
  });

  return result;
};

export const getGroup = (type) =>
  type === "Saved" ? "logEntry" : "logPublish";

export const getColumns = (type, handleInputChange) =>
  type === "Saved"
    ? savedVersionsTableColumns
    : publishedViewsTableColumns(handleInputChange);

export const getDataSource = (loading, tableData) =>
  loading && !tableData.length ? false : tableData;

export default generateSaveVersionsData;
