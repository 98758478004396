import {gql} from "@apollo/client";

export const TRANSPORTATION_LOSS = gql`
  query bioLcCoproConfigCenterTransportationLossDetails {
    bioLcCoproConfigCenterTransportationLossDetails {
      Records {
        transportation_loss_id
        location_id
        location_code
        location_name
        loss_factor
        uom_code
        valid_from
        valid_to
      }
    }
  }
`;

export default {TRANSPORTATION_LOSS};
