import {
  INPUT_METRIC,
  SPLIT_LABELS,
  SPLIT_SUB_TYPE,
  splitShipmentKeys,
} from "modules/co-processing/constants/shipments";

const splitShipmentFields = {
  SUB_TYPE: {
    key: splitShipmentKeys.SPLIT_SUB_TYPE,
    label: SPLIT_LABELS.SPLIT_SUB_TYPE,
    options: [
      {value: SPLIT_SUB_TYPE.EMPTY, label: "None"},
      {value: SPLIT_SUB_TYPE.LINE_SET, label: "Line set"},
    ],
    format: (value) => value?.replace(/_/g, " ") || value,
    isOptional: true,
    type: "select",
  },
  ACTUAL_VOLUME: {
    isOptional: true,
    key: splitShipmentKeys.ACTUAL_VOLUME,
    addOn: INPUT_METRIC,
    label: "Actual shipment volume",
    format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
    type: "number",
  },
  RINS_QUALIFIED: {
    key: splitShipmentKeys.RINS_QUALIFIED,
    label: SPLIT_LABELS.RINS_QUALIFIED,
    isOptional: false,
    options: [
      {value: "Select an option", label: "Select an option"},
      {value: "False", label: "False"},
      {value: "Partial", label: "Partial"},
      {value: "True", label: "True"},
    ],
    format: (value) => value?.replace(/_/g, " ") || value,
    type: "select",
  },
  RENEWABLE_SPLIT_FLAG: {
    key: splitShipmentKeys.RENEWABLE_SPLIT_FLAG,
    label: "Renewable",
    format: (value) => (value ? "Yes" : "No"),
    info: "Are you sure? Make sure to double check the renewability of this split.",
    type: "switch",
  },
  NOTES: {
    key: splitShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Enter your notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};
export default splitShipmentFields;
