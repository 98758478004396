import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {Alert32} from "@bphxd/ds-core-react/lib/icons";
import {useFormContext} from "react-hook-form";
import moment from "moment";
import {useUserSettings} from "providers/userSettings/context.js";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  FormGroup,
  Label,
  Accordion,
  Input,
  FormFeedback,
} from "reactstrap";

import Datepicker from "../../Datepicker";
import SupplierAndRecipientInfo from "./SupplierAndRecipientInfo";
import GeneralInformation from "./GeneralInformation";
import GHGEmissionInfo from "./GHGEmissionInfo";
import DeclarationFooter from "./DeclarationFooter";

import "../generateCertificate.css";

const GenerateCertificatePageBPMarketing = ({data}) => {
  const {
    formState: {errors},
    register,
    control,
    setValue,
  } = useFormContext();

  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [open, setOpen] = useState([]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const invalidTabs = {
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  };

  useEffect(() => {
    const recipientRecord = data?.locationDetails?.find(
      (obj) => obj.location_code === data?.recipientName,
    );
    const dispatchDate = data?.dispatchDate
      ? moment(data?.dispatchDate).format(dateFormat)
      : "";
    setValue("uniqueNumber", data?.generatedCertificateId);
    setValue("issueDate", new Date(data?.issueDate));
    setValue(
      "declarationStatement",
      `BP Energía España S.A.U. delivered ${
        data?.quantity
      } ${data?.uom.toLowerCase()} of ${
        data?.productType
      } (*) ISCC PLUS certified to ${recipientRecord?.legal_entity}, in ${
        data?.shippingAddress
      } during the month of ${dispatchDate}.`,
    );
  }, [data, setValue, dateFormat]);

  return (
    <div>
      <FormGroup className="pb-6">
        <Label for="declarationStatement" className="fw-light">
          Declaration Statement
        </Label>

        <Input
          {...computeProps("declarationStatement")}
          type="textarea"
          rows="3"
          name="declarationStatement"
          id="declarationStatement"
          data-test="declarationStatement"
          invalid={!!errors.declarationStatement}
          maxLength={250}
        />
      </FormGroup>
      <FormGroup className="pb-6">
        <Label for="uniqueNumber" className="fw-light">
          Unique number of sustainability declaration
        </Label>

        <Input
          {...computeProps("uniqueNumber")}
          type="text"
          id="uniqueNumber"
          name="uniqueNumber"
          invalid={!!errors.uniqueNumber}
          maxLength={100}
          disabled
        />

        <FormFeedback>{errors?.uniqueNumber?.message}</FormFeedback>
      </FormGroup>
      <FormGroup className="pb-6">
        <Label for="issueDate" className="fw-light">
          Date of Issuance
        </Label>

        <Datepicker
          id="issueDate"
          name="issueDate"
          control={control}
          error={errors.issueDate}
          rules={{required: "Please enter date of issuance"}}
          disabled
        />

        <FormFeedback>{errors?.issueDate?.message}</FormFeedback>
      </FormGroup>
      <Accordion open={open} {...{toggle}} flush>
        <AccordionItem>
          <AccordionHeader targetId="supplier">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.supplier && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">
                Supplier and recipient information
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="supplier" className="mb-2 mt-6">
            <SupplierAndRecipientInfo data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="general">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.general && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">General information</div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="general" className="mb-2 mt-6">
            <GeneralInformation countries={data?.countries ?? []} data={data} />
          </AccordionBody>
        </AccordionItem>

        <AccordionItem>
          <AccordionHeader targetId="emission">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.emission && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">
                Greenhouse gas (GHG) emission information
              </div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="emission" className="mb-2 mt-6">
            <GHGEmissionInfo countries={data?.countries ?? []} data={data} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="declaration">
            <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
              {invalidTabs.declaration && (
                <Alert32 className="text-danger accordion-btn-icon-prefix" />
              )}
              <div className="accordion-title">Declaration Footer</div>
            </div>
          </AccordionHeader>
          <AccordionBody accordionId="declaration" className="mb-2 mt-6">
            <DeclarationFooter data={data} />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

GenerateCertificatePageBPMarketing.propTypes = {
  data: PropTypes.object,
};

export default GenerateCertificatePageBPMarketing;
