import {useDroppable} from "@dnd-kit/core";
import PropTypes from "prop-types";
import React from "react";

const DroppableSection = ({id, className, children}) => {
  const {isOver, setNodeRef} = useDroppable({
    id,
  });

  return (
    <div ref={setNodeRef} className={className}>
      <div className="flex flex-col items-center justify-center py-16">
        {children}
      </div>
    </div>
  );
};

DroppableSection.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default DroppableSection;
