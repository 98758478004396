import {KPI} from "modules/forecasting/constants/inventory";

const kpiDataAndLabels = (data, forecastType, region, page) => {
  const kpiSelector = forecastType === "rin" ? "CREDITS" : "DEFICIT";

  const kpiLabel = KPI[kpiSelector][`${page}_PAGE_LABEL`];

  const kpiMessage1 = KPI[kpiSelector][`${page}_VARIANCE_PERCENT`];
  const kpiMessage2 = KPI[kpiSelector][`${page}_VARIANCE_VALUE`];

  const kpiValue =
    region === "wc" && forecastType !== "rin"
      ? data?.bioLcForecastApi?.body?.info?.variance
      : data?.bioLcForecastDataApi?.variance;

  return {kpiLabel, kpiMessage1, kpiMessage2, kpiValue};
};

export default kpiDataAndLabels;
