import * as XLSX from "xlsx";
import {
  feedstockHeaders,
  feedstockContractHeaders,
  feedstockOWBHeaders,
  feedstockReceiptsHeaders,
  feedstockSheets,
} from "../constants/receipts";

const feedstockTypeSheets = {
  sheet_2: feedstockContractHeaders,
  sheet_3: feedstockOWBHeaders,
  sheet_4: feedstockReceiptsHeaders,
};

const areNoneNull = (obj) =>
  Object.keys(obj).length > 0 && !Object.values(obj).includes(null);

const buildExportFn = (data, exportSheets, fileName) => {
  const buildWorksheetValues = (dataObject, headers) => {
    const values = dataObject.map((x) => {
      return Object.fromEntries(
        Object.entries(x).filter(([key]) => {
          return Object.keys(headers).includes(key);
        }),
      );
    });
    return values;
  };

  const sheetsNotNull = areNoneNull(exportSheets);

  const exportExcelData = () => {
    const workbook = XLSX.utils.book_new();

    if (Array.isArray(data) && data?.length > 0) {
      const values = buildWorksheetValues(data, feedstockHeaders);
      const ws = XLSX.utils.json_to_sheet([feedstockHeaders], {
        header: Object.keys(feedstockHeaders),
        skipHeader: true,
        origin: 0,
      });
      XLSX.utils.sheet_add_json(ws, values, {
        header: Object.keys(feedstockHeaders),
        skipHeader: true,
        origin: -1,
      });
      XLSX.utils.book_append_sheet(workbook, ws, feedstockSheets[0]);

      // For sheets 2, 3, 4
      if (sheetsNotNull) {
        Object.keys(exportSheets)
          .filter((item) => item !== "__typename")
          .forEach((obj, index) => {
            const values = buildWorksheetValues(
              exportSheets[obj],
              feedstockTypeSheets[`${obj}`],
            );

            const ws = XLSX.utils.json_to_sheet(
              [feedstockTypeSheets[`${obj}`]],
              {
                header: Object.keys(feedstockTypeSheets[`${obj}`]),
                skipHeader: true,
                origin: 0,
              },
            );
            XLSX.utils.sheet_add_json(ws, values, {
              header: Object.keys(feedstockTypeSheets[`${obj}`]),
              skipHeader: true,
              origin: -1,
            });
            XLSX.utils.book_append_sheet(
              workbook,
              ws,
              feedstockSheets[index + 1],
            );
          });
      }
    } else {
      throw new Error("No data supplied.");
    }
    XLSX.writeFile(workbook, fileName);
  };
  return exportExcelData;
};

export default buildExportFn;
