import * as yup from "yup";

export const SchemaWriteOff = yup
  .object()
  .shape({
    quantity: yup.string().label("Quantity").required(),
    writeOffreason: yup.string().label("Write Off reason").required(),
  })
  .required();

export default SchemaWriteOff;
