import {gql} from "@apollo/client";

export const PUBLISH_RULE = gql`
  mutation PublishRule($event: CreateUpdateRuleVersionRequest!) {
    bioLcComplianceRulesEngineCreateUpdateVersion(event: $event) {
      error
      ruleVersions {
        bvAuditCreatedDatez
        bvAuditChangedDatez
        remarks
        validFrom
        validTo
        ruleVersionId
        version
      }
      statusCode
    }
  }
`;

export default {PUBLISH_RULE};
