import {Close} from "@bphxd/ds-core-react";
import outboundTypes from "constants/allocation";

import {ReversalMutationAPI} from "graphql/MassBalance/Actions/reversalMutation";
import ResponseModal from "modules/GlobalAllocation/components/ResponseModal";
import {actionItems, status} from "modules/GlobalMassBalance/constants";
import {reversalConfig} from "modules/GlobalMassBalance/utils";
import PropTypes from "prop-types";
import {client} from "providers/Apollo";
import {useAppSetting} from "providers/appSetting";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {getDivisionData, getSiteDetails} from "utils/helpers/getAppSetting";

const ReversalModal = ({modal, rowData, divisionCode, currentFilterValues}) => {
  const {appSetting} = useAppSetting();
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const [showModal, setShowModal] = useState(false);
  const [eventData, setEventData] = useState({
    headerText: "",
    bodyTextArr: [],
    actionButtonText: "",
    eventName: "",
    eventType: "",
  });
  const [isFormBody, setIsFormBody] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {headerText, bodyTextArr, actionButtonText} = eventData;

  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseHeader, setResponseHeader] = useState("");
  const [responseBody, setResponseBody] = useState("");
  const [responseFooter, setResponseFooter] = useState([]);
  const [eventId, setEventId] = useState();

  const rowStatus = rowData?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    rowData?.original.inboundRecord?.fromMbLocationGroupId ?? null;

  const finalRowStatus =
    rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null
      ? status.AVAILABLE_FROM_TRANSFER
      : rowStatus;

  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    trigger,
  } = methods;

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const backToMassBalanceButton = useMemo(
    () => ({
      id: 1,
      text: "Back to mass balance",
      buttonClassName: "!text-[#111]",
      action: () => setShowResponseModal(false),
    }),
    [],
  );

  const handleResponse = useCallback(
    (action) => {
      switch (action) {
        case finalRowStatus + actionItems.UNLINK_DOCUMENT:
          setResponseHeader("Purchase cannot be unlinked");
          setResponseBody(
            "An outgoing transaction has already been allocated to a part of this purchase. You need to ensure that the total purchase quantity is available before you can unlink it from the document.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.REMOVE_FROM_BALANCE:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            <>
              The mass balance location you are transferring the balance back to
              is currently closed. You will need to re-open{" "}
              <u>the receiving location&apos;s mass balance</u> before removing
              the allocation.
            </>,
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        case finalRowStatus + actionItems.REMOVE_ALLOCATION:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            <>
              The purchase has been transferred to another mass balance
              location. You need to transfer it back from{" "}
              <u>the receiving location mass balance.</u>
            </>,
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
        default:
          setResponseHeader("Allocation cannot be removed");
          setResponseBody(
            "The mass balance location you are transferring the balance back to is currently closed. You will need to re-open the receiving location's mass balance before removing the allocation.",
          );
          setResponseFooter([backToMassBalanceButton]);
          break;
      }
    },
    [backToMassBalanceButton, finalRowStatus],
  );

  useEffect(() => {
    const outboundType = rowData?.original?.outboundRecords?.outboundType;
    const eventId =
      outboundType === outboundTypes.LOCATION_TRANSFER
        ? rowData?.original?.inboundRecord?.mbInboundQtyEuId
        : rowData?.original?.outboundRecords?.mbOutboundQtyEuId;
    const showFormData = outboundType === outboundTypes.LOCATION_TRANSFER;
    switch (modal) {
      case divisionCode + actionItems.UNLINK_DOCUMENT:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.UNLINK_DOCUMENT],
        );
        handleResponse(finalRowStatus + actionItems.UNLINK_DOCUMENT);
        setEventId(rowData?.original?.inboundRecord?.certificateInboundEuId);
        break;
      case divisionCode + actionItems.REMOVE_FROM_BALANCE:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_FROM_BALANCE],
        );
        setIsFormBody(true);
        setEventId(rowData?.original?.inboundRecord?.mbInboundQtyEuId);
        handleResponse();
        break;
      case divisionCode + actionItems.REMOVE_DOCUMENT:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_DOCUMENT],
        );
        setEventId(rowData?.original?.outboundRecords?.certificateOnboundEuId);
        handleResponse();
        break;
      case divisionCode + actionItems.REMOVE_ALLOCATION:
        setShowModal(true);
        setEventData(
          reversalConfig[
            finalRowStatus + actionItems.REMOVE_ALLOCATION + outboundType
          ],
        );
        setEventId(eventId);
        handleResponse(finalRowStatus + actionItems.REMOVE_ALLOCATION);
        setIsFormBody(showFormData);
        break;
      case divisionCode + actionItems.REMOVE_WRITE_OFF:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_WRITE_OFF],
        );
        setEventId(rowData?.original?.outboundRecords?.mbOutboundQtyEuId);
        handleResponse();
        break;
      case divisionCode + actionItems.REMOVE_RESERVATION:
        setShowModal(true);
        setEventData(
          reversalConfig[finalRowStatus + actionItems.REMOVE_RESERVATION],
        );
        setEventId(rowData?.original?.outboundRecords?.mbOutboundQtyEuId);
        handleResponse();
        break;
      default:
        setShowModal(false);
        break;
    }
  }, [
    divisionCode,
    handleResponse,
    isFormBody,
    modal,
    rowData?.original?.inboundRecord?.certificateInboundEuId,
    rowData?.original?.inboundRecord?.mbInboundQtyEuId,
    rowData?.original?.outboundRecords?.certificateOnboundEuId,
    rowData?.original?.outboundRecords?.mbOutboundQtyEuId,
    rowData?.original?.outboundRecords?.outboundType,
    finalRowStatus,
  ]);

  const onSubmit = async () => {
    setIsLoading(true);
    const {data} = await ReversalMutationAPI({
      event: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
        mbPeriodId: currentFilterValues?.mbPeriodId,
        eventName: eventData.eventName,
        eventType: eventData.eventType,
        eventId,
        reason: isFormBody ? getValues("reason") : undefined,
      },
    });

    const statusCode = data?.bioLcGlobalMassBalanceReversal?.statusCode;

    // Need to check with yuri
    if (statusCode === 400) {
      setShowResponseModal(true);
    }
    if (statusCode === 500) {
      toast.error("An error occurred. Please try again later.");
    }
    if (statusCode === 200) {
      toast.success("Successfully reversed.");
      client.refetchQueries({
        include: [
          "bioLcGetMassBalanceTransactionData",
          "bioLcGetMassBalanceCounts",
        ],
      });
    }

    setShowModal(false);
    setIsLoading(false);
  };

  const modalSize = isFormBody ? "330px" : "330px";
  const textAlignment = isFormBody ? "text-left" : "text-center";

  return (
    <>
      <Modal
        size="sm"
        isOpen={showModal}
        className={`modal-dialog-centered [&>div]:w-[${modalSize}]`}
      >
        {isFormBody && (
          <ModalHeader
            className="!border-b-[1px] !border-gray-200 mb-2 text-xl"
            close={<Close onClick={() => setShowModal(false)} />}
          >
            {headerText}
          </ModalHeader>
        )}
        <ModalBody className={`flex flex-col items-center w-[${modalSize}]`}>
          {!isFormBody && <p className="mb-[2px]">{headerText}</p>}
          <p className={`text-[13px] ${textAlignment} text-gray-800 mb-0`}>
            {bodyTextArr.map((text, index) => (
              <span key={index}>
                {text}
                {index < bodyTextArr.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </span>
            ))}
          </p>
          {isFormBody && (
            <div className="w-full">
              <FormProvider {...methods}>
                <Form>
                  <Label className="mt-6" for="reason">
                    Reason for removal
                  </Label>
                  <Input
                    id="reason"
                    type="textarea"
                    rows="3"
                    {...computeProps("reason", {
                      required: "Reason for removal is required",
                    })}
                    invalid={!!errors.reason}
                  />
                  {errors.reason && (
                    <FormFeedback>{errors.reason.message}</FormFeedback>
                  )}
                </Form>
              </FormProvider>
            </div>
          )}
        </ModalBody>

        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                onClick={() => {
                  trigger().then((isValid) => {
                    if (isValid) {
                      onSubmit();
                    }
                  });
                }}
                disabled={errors.reason || isLoading}
              >
                {isLoading && <Spinner size="sm" className="btn-icon-prefix" />}
                {actionButtonText}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      {showResponseModal && (
        <ResponseModal
          showModal={showResponseModal}
          header={responseHeader}
          body={responseBody}
          footerArr={responseFooter}
        />
      )}
    </>
  );
};

ReversalModal.propTypes = {
  modal: PropTypes.string,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default ReversalModal;
