import {useQuery} from "@apollo/client";
import {CREDITS_AND_DEFICITS_QUERY} from "graphql/forecast-data";
import EmptyData from "modules/forecasting/components/EmptyData";

export default function useCreditsAndDeficits({year, region}) {
  if (!region || !year) return null;

  const {data, loading} = useQuery(CREDITS_AND_DEFICITS_QUERY, {
    fetchPolicy: "cache-and-network",
    variables: {
      region,
      isEast: region === "ec",
      year: parseInt(year, 10),
    },
  });

  if (!data && !loading) return <EmptyData />;

  return {data, loading};
}
