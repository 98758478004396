import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button} from "reactstrap";

import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {isEmpty, startCase} from "lodash";
import CustomizeTable from "modules/GlobalMassBalance/components/CustomizeTable/CustomizeTable";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {getMandateCertificateColumns} from "modules/GlobalMassBalance/utils";
import {useAppSetting} from "providers/appSetting";
import {useUserSettings} from "providers/userSettings";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import MandateFilter from "../components/MandateFilter";
import DocumentTable from "../components/Table";

const MandateCertificate = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [periodName, setPeriodName] = useState();
  const [mbTypeCode, setMbTypeCode] = useState();
  const [locationGroupName, setLocationGroupName] = useState();
  const [balanceGroupName, setBalanceGroupName] = useState();
  const [outboundTypeName, setOutboundTypeName] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();

  const {country, division, location, period, outboundType} = useParams();

  const mbType = mbTypeCode?.toLowerCase();

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: divisionCode, link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },
    {text: "Mandate certificates"},
  ];

  const [defaultValues, setFilterDefaultValues] = useState({});
  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);
  const navigate = useNavigate();

  // Dummy data will be removed once API is ready in this story - 403648
  const transactionData = useCallback(
    () => [
      {
        inboundRecords: [
          {
            physicalReceiptDate: "2021-09-01",
            incomingDocument: "Document 1",
            rawMaterial: "Raw Material 1",
            countryOfOrigin: "Country of Origin 1",
            ghgTotalMj: "GHG Total 1",
            retiredQuantityM3: 1,
          },
        ],
        outboundRecord: {
          quantityOutM3: 1,
          outboundType: "Outbound Type 1",
          recipient: "Recipient 1",
          document: "Document 1",
          status: "AVAILABLE",
        },
      },
      {
        inboundRecords: [
          {
            physicalReceiptDate: "2021-09-01",
            incomingDocument: "Document 1",
            rawMaterial: "Raw Material 1",
            countryOfOrigin: "Country of Origin 1",
            ghgTotalMj: "GHG Total 1",
            retiredQuantityM3: 1,
          },
          {
            physicalReceiptDate: "2021-09-01",
            incomingDocument: "Document 1",
            rawMaterial: "Raw Material 1",
            countryOfOrigin: "Country of Origin 1",
            ghgTotalMj: "GHG Total 1",
            retiredQuantityM3: 1,
          },
        ],
        outboundRecord: {
          quantityOutM3: 1,
          outboundType: "Outbound Type 1",
          recipient: "Recipient 1",
          document: "Document 1",
          status: "AVAILABLE",
        },
      },
    ],
    [],
  );

  const mbTableLoading = false;

  // Dummy data will be removed once API is ready in this story - 403648
  const topFilterData = useCallback(() => {
    return {
      mbLocationGroups: [
        {
          mbLocationGroupId: "a755e66e8abda300257696cf372b1d0a",
          mbLocationGroupName: "Sweden Trader",
        },
      ],
      mbPeriods: [
        {
          mbPeriodId: "c9f083b06d362f88c8806cc331cac220",
          mbPeriodName: "Apr 2024",
          mbPeriodToDate: "2024-04-30",
        },
      ],
      outboundTypes: ["Mandate1 hub"],
    };
  }, []);

  const filterDynamicData = useMemo(() => topFilterData(), [topFilterData]);

  const navigateToAllocation = useCallback(
    (selectedId) => {
      let redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}`;
      if (selectedId) {
        redirectUrl = `/allocation/${country}/${divisionCode}/${mbTypeCode}/${locationGroupName}/${periodName}/${balanceGroupName}?selectedId=${selectedId}`;
      }
      navigate(redirectUrl);
    },
    [
      country,
      divisionCode,
      mbTypeCode,
      locationGroupName,
      periodName,
      balanceGroupName,
      navigate,
    ],
  );

  const tableCols = useMemo(
    () =>
      getMandateCertificateColumns(
        country,
        divisionCode,
        dateFormat,
        decimalFormat,
        navigateToAllocation,
        currentFilterValues,
        siteReferenceData?.siteReferenceId,
        divisionData?.divisionId,
      ),
    [
      country,
      divisionCode,
      dateFormat,
      decimalFormat,
      navigateToAllocation,
      currentFilterValues,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
    ],
  );
  const [columns, setColumns] = useState(tableCols);

  const selectedData = useMemo(() => {
    const selectedLocation = filterDynamicData?.mbLocationGroups?.find(
      (item) => item.mbLocationGroupName === location,
    );
    const selectedPeriod = filterDynamicData?.mbPeriods?.find(
      (item) => item.mbPeriodName === period,
    );
    const selectedOutboundType = filterDynamicData?.outboundTypes?.find(
      (item) => item === outboundType,
    );

    return {
      selectedLocation,
      selectedPeriod,
      selectedOutboundType,
    };
  }, [filterDynamicData, location, period, outboundType]);

  useEffect(() => {
    if (period) {
      setPeriodName(period);
    }
    if (location) {
      setLocationGroupName(location);
    }
    if (outboundType) {
      setOutboundTypeName(outboundType);
    }
  }, [location, outboundType, period]);

  useEffect(() => {
    if (!isEmpty(selectedData)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbLocationGroupId: selectedData?.selectedLocation?.mbLocationGroupId,
          mbPeriodId: selectedData?.selectedPeriod?.mbPeriodId,
          outboundType: selectedData?.selectedOutboundType,
        };
      });

      setCurrentFilterValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbLocationGroupId: selectedData?.selectedLocation?.mbLocationGroupId,
          mbLocationGroupName:
            selectedData?.selectedLocation?.mbLocationGroupName,
          mbPeriodId: selectedData?.selectedPeriod?.mbPeriodId,
          mbPeriodName: selectedData?.selectedPeriod?.mbPeriodName,
          outboundType: selectedData?.selectedOutboundType,
        };
      });
    }
  }, [
    selectedData,
    selectedData?.selectedLocation?.mbLocationGroupId,
    selectedData?.selectedLocation?.mbLocationGroupName,
    selectedData?.selectedOutboundType,
    selectedData?.selectedPeriod?.mbPeriodId,
    selectedData?.selectedPeriod?.mbPeriodName,
  ]);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols]);

  const filteredColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      columns: column.columns.filter(({visible}) => visible),
    }));
  }, [columns]);

  const extractKeys = useCallback((filteredColumns) => {
    return filteredColumns.flatMap((group) =>
      group.columns.map((col) => col.key),
    );
  }, []);

  const updateColumnFilter = useCallback(
    (filteredColumns, columnFilter) => {
      const keys = extractKeys(filteredColumns);
      // Filter the columnFilter to include only items whose id is in the extracted keys
      return columnFilter.filter((filterItem) => keys.includes(filterItem.id));
    },
    [extractKeys],
  );

  // useEffect hook to update the column filter state whenever filteredColumns or updateColumnFilter changes
  useEffect(() => {
    setColumnFilter((prevColumnFilter) => {
      // Update the column filter using the updateColumnFilter function
      const updatedColumnFilter = updateColumnFilter(
        filteredColumns,
        prevColumnFilter,
      );

      // Only update the state if the filters have actually changed
      if (
        JSON.stringify(prevColumnFilter) !== JSON.stringify(updatedColumnFilter)
      ) {
        return updatedColumnFilter; // Return the updated column filter
      }

      // Return the previous state if nothing has changed to avoid re-render
      return prevColumnFilter;
    });
  }, [filteredColumns, updateColumnFilter]);

  const handleFilterSubmit = async () => {
    // This will be completed in this story - 403648
  };

  const retiredQuantity = 0;
  const allocatedQuantity = 0;

  const handleDownloadOrAllocate = async () => {
    // This will be completed in this story - 403648
  };

  const tableData = useMemo(() => transactionData() || [], [transactionData]);

  const footerValue = division.toUpperCase() === DIV_CODE_COPRO ? "MT" : "m³";

  return (
    <Layout title="Mandate certificates" breadcrumbs={breadcrumbItems}>
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <MandateFilter
              currentFilterValues={currentFilterValues}
              filterDynamicData={filterDynamicData}
              handleFilterSubmit={handleFilterSubmit}
            ></MandateFilter>
          </div>
          {divisionCode !== DIV_CODE_COPRO && (
            <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
              <Button
                data-test="allocate-outgoing-button"
                color="standard-tertiary rounded-0"
                type="button"
                onClick={handleDownloadOrAllocate}
              >
                Allocate for customer mandates
              </Button>
            </div>
          )}
        </div>
      </div>
      {/* Customise columns, table, aggregate view */}
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full h-[calc(100vh-490px)] bg-white overflow-y-auto overflow-x-auto">
          <DocumentTable
            data={tableData}
            columns={filteredColumns}
            loading={mbTableLoading}
            showFilter={showFilter}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
        </div>
        <div className="w-full flex items-center h-20 border-t-2 border-t-[#eee] bg-white">
          <div className="w-1/2 text-end mr-4 h-7">
            <span className="text-gray-700 mr-[10px]">Retired quantity</span>
            <span>
              {retiredQuantity} {footerValue}
            </span>
          </div>
          <div className="w-1/2 text-end mr-4 h-7">
            <span className="text-gray-700 mr-[10px]">Allocated quantity</span>
            <span>
              {allocatedQuantity} {footerValue}
            </span>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MandateCertificate;
