import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input} from "reactstrap";
import {COUNTRY_SPAIN} from "constants/countryDetails";
import CheckboxFlag from "../CheckboxNullable";

const ScopeOfCertification = ({status, selectedCountry, isDisabled}) => {
  const {
    register,
    formState: {errors},
    control,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="materialSustainabilityCriteriaFlag" className="fw-normal">
          The raw material complies with the relevant sustainability criteria
          according to Art. 29 (2)-(7) RED II
        </Label>
        <CheckboxFlag
          id="materialSustainabilityCriteriaFlag"
          name="materialSustainabilityCriteriaFlag"
          control={control}
          error={errors.materialSustainabilityCriteriaFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label
          for="agriculturalBiomassIntermediateCropFlag"
          className="fw-normal"
        >
          The agricultural biomass was cultivated as intermediate crop (if
          applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassIntermediateCropFlag"
          name="agriculturalBiomassIntermediateCropFlag"
          control={control}
          error={errors.agriculturalBiomassIntermediateCropFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="agriculturalBiomassLowRiskFlag" className="fw-normal">
          The agricultural biomass additionally fulfills the measures for low
          ILUC risk feedstocks (if applicable)
        </Label>
        <CheckboxFlag
          id="agriculturalBiomassLowRiskFlag"
          name="agriculturalBiomassLowRiskFlag"
          control={control}
          error={errors.agriculturalBiomassLowRiskFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialWasteOrResidueFlag" className="fw-normal">
          The raw material meets the definition of waste or residue according to
          the RED II
        </Label>
        <CheckboxFlag
          id="rawMaterialWasteOrResidueFlag"
          name="rawMaterialWasteOrResidueFlag"
          control={control}
          error={errors.rawMaterialWasteOrResidueFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      {selectedCountry === COUNTRY_SPAIN && (
        <>
          <FormGroup>
            <Label
              for="wasteOrAnimalByProductPermitNumber"
              className="fw-normal"
            >
              If applicable, please specify waste or animal by-product permit
              number
            </Label>
            <Input
              {...computeProps("wasteOrAnimalByProductPermitNumber")}
              type="textarea"
              rows="3"
              id="wasteOrAnimalByProductPermitNumber"
              name="wasteOrAnimalByProductPermitNumber"
              data-test="wasteOrAnimalByProductPermitNumber"
              maxLength={100}
            />
          </FormGroup>

          <FormGroup>
            <Label
              for="supportForFuelProductionOrFuelPrecursorReceivedFlag"
              className="fw-normal"
            >
              Was support for the production of the fuel or fuel precursor
              received?
            </Label>
            <CheckboxFlag
              name="supportForFuelProductionOrFuelPrecursorReceivedFlag"
              id="supportForFuelProductionOrFuelPrecursorReceivedFlag"
              control={control}
              error={errors.supportForFuelProductionOrFuelPrecursorReceivedFlag}
            />
          </FormGroup>

          <FormGroup>
            <Label
              for="ifYesSpecifySupportNatureAndScheme"
              className="fw-normal"
            >
              If yes, please specify support nature and scheme
            </Label>
            <Input
              {...computeProps("ifYesSpecifySupportNatureAndScheme")}
              type="textarea"
              rows="3"
              id="ifYesSpecifySupportNatureAndScheme"
              name="ifYesSpecifySupportNatureAndScheme"
              data-test="ifYesSpecifySupportNatureAndScheme"
              maxLength={100}
            />
          </FormGroup>
        </>
      )}
    </div>
  );
};

ScopeOfCertification.propTypes = {
  status: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  isDisabled: PropTypes.bool,
};
export default ScopeOfCertification;
