const {gql} = require("@apollo/client");

const GENERATE_PDF_SUBSCRIPTION = gql`
  subscription onBiolccertgenpdfcomplete($mbOutboundQtyEuId: String!) {
    onBiolccertgenpdfcomplete(mbOutboundQtyEuId: $mbOutboundQtyEuId) {
      mbOutboundQtyEuId
      statusCode
    }
  }
`;

export default GENERATE_PDF_SUBSCRIPTION;
