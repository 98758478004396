import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const RESERVE = gql`
  mutation Reserve(
    $mbInboundQtyEuId: String!
    $createdBy: String!
    $customerName: String!
    $quantity: Float!
    $siteReferenceId: String!
    $divisionId: String!
  ) {
    bioLcGlobalMassBalanceReserve(
      event: {
        mbInboundQtyEuId: $mbInboundQtyEuId
        customerName: $customerName
        createdBy: $createdBy
        quantity: $quantity
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      error
      statusCode
    }
  }
`;

export const ReserveAPI = (data) =>
  client.mutate({
    mutation: RESERVE,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export const GET_RESERVE_MASTER_DETAILS = gql`
  query getMasterDetailsForReserve {
    bioLcGetBusinessAndLocationDetails {
      businessAndLocationDetails {
        businessPartnerAddress
        businessPartnerCode
        businessPartnerLongName
        businessPartnerId
        businessPartnerShortName
        businessPartnerType
        countryCode
        locationAddress
        locationCode
        locationId
        locationLongName
        locationShortName
        locationType
      }
      error
      statusCode
    }
  }
`;

export default {
  RESERVE,
  ReserveAPI,
};
