import PropTypes from "prop-types";
import TabHeader from "modules/common/Tabs/TabHeader";
import {NavItem, NavLink} from "reactstrap";
import {roundDecimal} from "utils/helpers/uihelper";
import {formatNumber} from "providers/userSettings";

const NavigationItem = ({
  tabName,
  data,
  isLoading,
  activeTab,
  setActiveTab,
  decimalFormat,
  onMore,
  mainObligationUnitText,
  heading,
}) => {
  return (
    <NavItem className="compliance-nav-item">
      <NavLink
        className={activeTab === tabName ? "active" : ""}
        onClick={() => setActiveTab(tabName)}
      >
        <TabHeader
          className="compliance-nav-header"
          Heading={heading}
          subHeading={`${formatNumber(
            roundDecimal(data?.total),
            decimalFormat,
            0,
          )} ${mainObligationUnitText}`}
          loading={isLoading}
          onMore={onMore}
        />
      </NavLink>
    </NavItem>
  );
};

NavigationItem.propTypes = {
  tabName: PropTypes.string,
  data: PropTypes.object,
  isLoading: PropTypes.bool,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  decimalFormat: PropTypes.string,
  onMore: PropTypes.func,
  mainObligationUnitText: PropTypes.string,
  heading: PropTypes.string,
};
export default NavigationItem;
