import React from "react";
import PropTypes from "prop-types";
import {Input, FormGroup, Label} from "reactstrap";
import {useFormContext, Controller} from "react-hook-form";
import FormField from "modules/common/FormFieldNew.js";
import DatePicker from "modules/common/DatePickerNew";
import {useUserSettings} from "providers/userSettings";
import dateFunctions from "utils/helpers/dateFunctions";
import {GET_MASS_BALANCE_ADDRESS_OF_RECEIVING_POINT} from "graphql/saf/MassBalance";
import {useQuery} from "@apollo/client";

const AssignToSaleForm = ({formData, formDataLoading}) => {
  const {
    register,
    watch,
    formState: {errors},
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const dateFormat = dateFunctions.convertDateFormattoUser("d/m/Y");

  const {
    data: addressOfRecipientPoint,
    loading: addressOfRecipientPointLoading,
  } = useQuery(GET_MASS_BALANCE_ADDRESS_OF_RECEIVING_POINT, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      legal_entity: watch("recipient_name"),
    },
    skip: !watch("recipient_name"),
  });

  const modes_of_transportations =
    formData?.bioLcSafMBGetModesOfTransportation?.modes_of_transportations;

  const recipient_names =
    formData?.bioLcSafMBGetNamesOfRecipients?.names_of_recipients;

  const address_receiving =
    addressOfRecipientPoint?.bioLcSafMBGetAddressOfReceivingPoint?.addresses;

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField
        label="Date of issuance"
        errorMessage={errors.date_of_issuance?.message}
        className="p-4"
      >
        <Controller
          name="date_of_issuance"
          render={({field: {onChange, value}}) => (
            <DatePicker
              data-test="date_of_issuance"
              placeholder="Date of issuance"
              id="date_of_issuance"
              name="date_of_issuance"
              invalid={!!errors.date_of_issuance}
              options={{
                allowInput: true,
                mode: "single",
                defaultDate: value,
                dateFormat,
                altFormat: dateFormat,
                onChange: (selectedDates) => onChange(selectedDates[0]),
              }}
            />
          )}
        />
      </FormField>
      <FormField
        label="Mode of transportation"
        errorMessage={errors.mode_of_transportation?.message}
        className="p-4"
      >
        <Controller
          name="mode_of_transportation"
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="mode_of_transportation"
              data-test="mode_of_transportation"
              invalid={!!errors.mode_of_transportation}
              disabled={formDataLoading}
            >
              <option key="none" value="">
                Select
              </option>
              {modes_of_transportations?.map((mode) => (
                <option
                  key={mode.mode_of_transportation}
                  value={mode.mode_of_transportation}
                >
                  {mode.mode_of_transportation}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField
        label="Contract number"
        errorMessage={errors.contract_number?.message}
        className="p-4"
      >
        <Controller
          name="contract_number"
          render={({field}) => (
            <Input
              {...field}
              type="text"
              id="contract_number"
              data-test="contract_number"
              placeholder="Enter"
              invalid={!!errors.contract_number}
              maxLength={50}
            />
          )}
        />
      </FormField>
      <FormField
        label="Quantity m3"
        errorMessage={errors.quantity?.message}
        className="p-4"
      >
        <Controller
          name="quantity"
          render={({field}) => (
            <Input
              {...field}
              type="number"
              id="quantity"
              data-test="quantity"
              placeholder="Enter quantity"
              invalid={!!errors.quantity}
              maxLength={50}
            />
          )}
        />
      </FormField>
      <FormField
        label="Name of recipient"
        errorMessage={errors.recipient_name?.message}
        className="p-4"
      >
        <Controller
          name="recipient_name"
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="recipient_name"
              data-test="recipient_name"
              invalid={!!errors.recipient_name}
              disabled={formDataLoading}
            >
              <option key="none" value="">
                Select
              </option>
              {recipient_names?.map((name) => (
                <option key={name.recipient} value={name.recipient}>
                  {name.recipient}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <div>
        <FormField
          label="Address of receiving point"
          errorMessage={errors.address_receiving?.message}
          className="px-4 pt-4"
        >
          <Controller
            name="address_receiving"
            render={({field}) => (
              <Input
                {...field}
                type="select"
                id="address_receiving"
                data-test="address_receiving"
                invalid={!!errors.address_receiving}
                disabled={
                  formDataLoading ||
                  addressOfRecipientPointLoading ||
                  !watch("recipient_name") ||
                  watch("same_as_address_of_recipient")
                }
              >
                <option key="none" value="">
                  Select
                </option>
                {address_receiving?.map((name) => (
                  <option
                    key={name.receiving_point_address}
                    value={name.receiving_point_address}
                  >
                    {name.receiving_point_address}
                  </option>
                ))}
              </Input>
            )}
          />
        </FormField>
        <FormGroup check inline className="form-check-alt form-check-lg m-4">
          <Input
            {...computeProps("same_as_address_of_recipient")}
            type="checkbox"
            id="same_as_address_of_recipient"
            data-test="same_as_address_of_recipient"
          />

          <Label check for="same_as_address_of_recipient">
            Same as address of recipient
          </Label>
        </FormGroup>
      </div>
    </div>
  );
};

AssignToSaleForm.propTypes = {
  rowData: PropTypes.object,
  formData: PropTypes.object,
  formDataLoading: PropTypes.bool,
};

export default AssignToSaleForm;
