import {gql} from "@apollo/client";
import axios from "axios";
import {client} from "providers/Apollo";

export const UPLOAD_FILE = gql`
  query bioLcCoproEuFileUpload(
    $comment: String!
    $fileName: String!
    $countryCode: String!
  ) {
    bioLcCoproEuFileUpload(
      event: {comment: $comment, countryCode: $countryCode, fileName: $fileName}
    ) {
      preSignedUrl
      statusCode
    }
  }
`;

export const TRIGGER_FILE_PARSE_API = gql`
  query bioLcCoproEuGetFileParserStatus(
    $fileName: String!
    $countryCode: String!
  ) {
    bioLcCoproEuGetFileParserStatus(
      event: {fileName: $fileName, countryCode: $countryCode}
    ) {
      fileName
      fileProcessedStatus
      statusCode
    }
  }
`;

export const uploadFile = async (comment, file, countryCode) => {
  const isResponseSuccess = (fileUploadStatusCode) => {
    switch (fileUploadStatusCode) {
      case 200:
      case 204:
        return true;
      default:
        return false;
    }
  };

  const buildFormData = (fileData, fields) => {
    const fileFormData = new FormData();
    if (fields) {
      Object.entries(fields).forEach(([k, v]) => {
        fileFormData.append(k, v);
      });
      fileFormData.append("file", fileData);
    }
    return fileFormData;
  };

  let statusCode;

  // Allocate s3 bucket
  const {
    data: {bioLcCoproEuFileUpload: responseFileUpload},
  } = await client.query({
    query: UPLOAD_FILE,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      comment,
      fileName: file.name,
      countryCode,
    },
  });

  statusCode = responseFileUpload?.statusCode;
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  // Upload binary file
  const body = JSON.parse(responseFileUpload?.preSignedUrl);
  const formData = buildFormData(file, body.fields);
  const uploadBinaryResponse = await axios.post(body.url, formData);
  statusCode = uploadBinaryResponse.status;
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  const {
    data: {
      bioLcCoproEuGetFileParserStatus: responseBioLcCoproEuGetFileParserStatus,
    },
  } = await client.query({
    query: TRIGGER_FILE_PARSE_API,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      countryCode,
      fileName: file.name,
    },
  });
  statusCode = responseBioLcCoproEuGetFileParserStatus.statusCode;
  if (statusCode === 408) {
    const {
      data: {
        bioLcCoproEuGetFileParserStatus:
          responseBioLcCoproEuGetFileParserRefetchStatus,
      },
    } = await client.query({
      query: TRIGGER_FILE_PARSE_API,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        countryCode,
        fileName: file.name,
      },
    });
    statusCode = responseBioLcCoproEuGetFileParserRefetchStatus.statusCode;
  }
  if (!isResponseSuccess(statusCode)) {
    return statusCode;
  }

  // All good
  return 200;
};
