import React, {memo} from "react";
import PropTypes from "prop-types";
import tw, {styled} from "twin.macro";
import {Input} from "reactstrap";
import {
  useForecastFilter,
  useForecastFilterDispatch,
} from "modules/forecasting/context/ForecastingFilterContext";
import forecastFilterOptions from "./filterOptions";

const FilterList = () => {
  const {forecastFilters = []} = useForecastFilter();

  return (
    <StyledList data-test="filter-items">
      {forecastFilters.map((item) => (
        <FilterItem
          key={item.id}
          item={item}
          options={forecastFilterOptions?.[item.id]}
        />
      ))}
    </StyledList>
  );
};

export default FilterList;

const FilterItem = memo(function FilterItem({item = {}, options = []}) {
  const {id, name, applied, selected} = item;
  const {updateFilter} = useForecastFilterDispatch();

  return (
    <StyledSelectionItem data-test={`${name}-filter`} grow={!(id === "year")}>
      <label>{name}</label>
      <Input
        type="select"
        className="bp-select"
        data-test={`${id}-select`}
        value={selected ?? applied}
        onChange={(e) => {
          const value = e.target?.value;
          updateFilter({id, value});
        }}
      >
        {options?.map((o) => {
          return (
            <option key={o?.value} value={o?.value}>
              {o?.label}
            </option>
          );
        })}
      </Input>
    </StyledSelectionItem>
  );
});

FilterItem.propTypes = {
  item: PropTypes.object,
  options: PropTypes.array,
};

const StyledList = tw.div`lg:basis-5/6 gap-5 grid grid-cols-1 sm:flex flex-wrap`;

const StyledSelectionItem = styled.div`
  ${tw`flex flex-col relative`};

  ${(grow) => (grow ? tw`grow` : tw`grow-0`)};

  label {
    ${tw`text-xs text-[#89888c] text-left uppercase tracking-wide`}
  }
`;
