import React from "react";
import Modal from "modules/common/Modal";
import PropTypes from "prop-types";
import UploadIcon from "assets/images/upload.gif";

export const LoadingModal = ({title, visible}) => {
  return (
    <Modal
      data-test="loading-modal"
      visible={visible}
      destroyOnClose
      width="30%"
      footer={null}
    >
      <div className="h-80 flex justify-center items-center flex-col">
        <img alt="" src={UploadIcon} className="mx-auto sm:mx-0 w-16" />
        <h3 className="font-bold text-gray-800 mt-5">{title}</h3>
      </div>
    </Modal>
  );
};

LoadingModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default LoadingModal;
