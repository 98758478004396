import flagUS from "assets/images/flags/flg_usa_us.png";
import {REGION} from "modules/forecasting/constants/inventory";
import {generateYearsForDropdown} from "modules/forecasting/helpers/dateUtils";

// ------------------------- FILTER DATA ------------------------------ //
const forecastFilterOptions = {
  country: [{value: "us", label: "United States", icon: flagUS}],
  region: [
    {value: "", label: "All"},
    {value: "ec", label: REGION.EAST_COAST},
    {value: "wc", label: REGION.WEST_COAST},
  ],
  compliance: [{value: "rfs", label: "RFS: Renewable Fuel Standards"}],
  forecastType: [
    {value: "", label: "All"},
    {value: "rin", label: "Credits"},
    {value: "rvo", label: "Deficits"},
  ],
  year: generateYearsForDropdown().map((year) => ({value: year, label: year})),
};

export default forecastFilterOptions;
