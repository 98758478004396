import PropTypes from "prop-types";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useLocation, Link} from "react-router-dom";
import Title from "modules/common/Title";
import Tabs from "modules/common/Tabs";
import {Helmet} from "react-helmet";

const DashboardLayout = ({
  pageTitle,
  tabsData,
  children,
  breadcrumbs,
  ...props
}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  return (
    <div {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Breadcrumb className="px-7 pt-4">
        {breadcrumbs?.map((item, index) => (
          <BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
            {item.text === "BioVerse" ? (
              <Link
                to="/"
                data-test="home-button"
                onClick={() => {
                  const countryFeatureData =
                    appSetting?.featureMappingData?.find(
                      (item) => item.countryName === "GLOBAL",
                    );

                  setAppSetting({
                    ...appSetting,
                    currentCountry: "GLOBAL",
                    currentCountryMappingData: countryFeatureData,
                    currentModule: "",
                    currentModuleTitle: "",
                  });
                }}
              >
                {item.text}
              </Link>
            ) : index === breadcrumbs.length - 1 ? (
              <span>{item.text}</span>
            ) : (
              <Link to={item.link}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <Title title={pageTitle} />
      <div className="w-full px-6 my-8 lg:px-10">
        {tabsData ? <Tabs className="my-8" data={tabsData} /> : null}
        {children}
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageTitle: PropTypes.string.isRequired,
  menuTitle: PropTypes.string.isRequired,
  tabsData: PropTypes.arrayOf(PropTypes.object),
  breadcrumbs: PropTypes.array.isRequired,
};

export default DashboardLayout;
