import getFilterValue from "modules/compliance-rules-engine/utils";
import moment from "moment";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import ColumnHighlight from "../ColumnHighlight";
import HighLightedText from "../HighLightedText";
import RenderDropdown from "../RenderDropdown";

const RenderLink = (ruleMasterId, text, pageNo) => {
  const {country} = useParams();
  return (
    <Link
      to={{
        pathname: `/compliance-rules-engine/versions/${ruleMasterId}`,
        search: `?pageNo=${pageNo}`,
      }}
      className="link-dark whitespace-nowrap"
    >
      <u>
        <HighLightedText value={text} />
      </u>
    </Link>
  );
};

const columnsMain = (
  handleEditClick,
  handleDeleteRule,
  dateFormat,
  setDeleteModal,
  setModalContent,
) => [
  {
    header: "Rule name",
    accessorKey: "ruleName",
    key: "ruleName",
    meta: {
      filterVariant: "search",
    },
    cell: ({row, getValue, table, cell}) => {
      const tooltipId = `ruleName${row.id}`;
      const value = cell.getValue();
      const columnFilter = getFilterValue(cell, "ruleName");

      return (
        <div key={row.id}>
          <Link
            to={{
              pathname: `/compliance-rules-engine/versions/${encodeURIComponent(
                row.original.ruleMasterId,
              )}`,
              search: `?pageNo=${table.getState().pagination.pageIndex}`,
            }}
            className="link-dark whitespace-nowrap"
            id={tooltipId}
          >
            <u className="flex">
              <ColumnHighlight
                value={value}
                columnFilter={columnFilter}
                hasEllipsis
                isCertificate
              />
            </u>
          </Link>
          <UncontrolledTooltip placement="bottom" target={tooltipId}>
            {value ?? ""}
          </UncontrolledTooltip>
        </div>
      );
    },
  },
  {
    header: "Country",
    accessorKey: "countryName",
    key: "countryName",
    meta: {
      filterVariant: "select",
    },
  },
  {
    header: "Created",
    accessorKey: "bvAuditCreatedDatez",
    key: "bvAuditCreatedDatez",
    meta: {
      filterVariant: "daterange",
    },
    filterFn: "dateRangeFilterFn",
    cell: ({cell}) => {
      const dateValue = cell.getValue();
      const formattedDate = dateValue
        ? moment(dateValue).format(dateFormat)
        : "";
      return <HighLightedText value={formattedDate} />;
    },
  },
  {
    header: "",
    accessorKey: "actions",
    key: "actions",
    size: 25,
    visible: true,
    enableSorting: false,
    cell: ({
      row: {
        original: {ruleMasterId, countryCode, ruleName, userId},
      },
      table,
    }) => {
      return (
        <RenderDropdown
          ruleMasterId={ruleMasterId}
          countryCode={countryCode}
          ruleName={ruleName}
          handleEditClick={handleEditClick}
          handleDeleteRule={handleDeleteRule}
          userId={userId}
          setDeleteModal={setDeleteModal}
          setModalContent={setModalContent}
        />
      );
    },
  },
];

export default columnsMain;
