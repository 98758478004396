import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const GET_MANUAL_ADJUSTMENT_OPTIONS = gql`
  query getManualAdjustmentOptions {
    getManualAdjustmentOptions {
      body {
        grade
        gradeSubType
        location
        businessUnit
      }
    }
  }
`;

const INSERT_MANUAL_ADJUSTMENT = gql`
  mutation insertManualAdjustment(
    $scenarioNumber: String!
    $complianceStatus: String!
    $grade: String!
    $gradeSubType: String!
    $location: String!
    $volumeGal: Float!
    $businessUnit: String!
    $comment: String!
    $transactionDate: String!
    $modifiedBy: String!
  ) {
    insertManualAdjustment(
      event: {
        scenarioNumber: $scenarioNumber
        complianceStatus: $complianceStatus
        grade: $grade
        gradeSubType: $gradeSubType
        location: $location
        volumeGal: $volumeGal
        businessUnit: $businessUnit
        comment: $comment
        transactionDate: $transactionDate
        modifiedBy: $modifiedBy
      }
    ) {
      body
      statusCode
    }
  }
`;

export const InsertManualAdjustmentAPI = (data) =>
  client.mutate({
    mutation: INSERT_MANUAL_ADJUSTMENT,
    variables: {
      ...data,
    },
    refetchQueries: [
      "GetComplianceSchemeDeficit",
      "getComplianceSchemeGraph",
      "GetComplianceSchemeOpen",
      "GetComplianceSchemeClose",
      "GetComplianceSchemeCredit",
    ],
  });

export default GET_MANUAL_ADJUSTMENT_OPTIONS;
