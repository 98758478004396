import {PUBLISH_VIEW_HEADER} from "modules/forecasting/constants/inventory";

const ToPublishBeyond = () => {
  return (
    <>
      <h2 className="text-3xl font-bold" data-test="to-publish-email-header">
        {PUBLISH_VIEW_HEADER}
      </h2>
      <div className="mt-8 max-w-lg" data-test="to-publish-email-body">
        <div>
          The proposed forecast being submitted is beyond the next month
          forecast range, an email will not be sent to T&S.
        </div>
        <div className="mt-4">
          Click on &quot;Submit&quot; to proceed and publish the forecast to
          BioVerse OR &quot;Cancel&quot; to go back to modify changes.
        </div>
      </div>
    </>
  );
};

export default ToPublishBeyond;
