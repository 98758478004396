import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {COPRO_US_SHIPMENTS_API_REMOVE_SPLIT} from "graphql/coprocessing/shipments";
import {REMOVE_POPOVER, ERRORS} from "../../constants/shipments";
import DeletePopover from "../Shared/DeletePopover";

const RemoveSplitPopover = ({
  showPopover,
  setShowPopover,
  refetchShipments,
  shipmentId,
}) => {
  const [removeSplit, {data: splitData}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_REMOVE_SPLIT,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        shipmentId,
      },
      onCompleted: () => {
        if (splitData?.bioLcCoproUsShipmentsApi?.error) {
          return toast.error(ERRORS.FAILED_REMOVE);
        }
        return refetchShipments();
      },
    },
  );

  const handleDelete = () => {
    removeSplit();
    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleDelete}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget="shipments-remove-button"
      header={REMOVE_POPOVER.HEADER}
      message={REMOVE_POPOVER.MESSAGE}
      cancel={REMOVE_POPOVER.CANCEL}
      confirm={REMOVE_POPOVER.YES}
      idToDelete={shipmentId}
    />
  );
};

RemoveSplitPopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  refetchShipments: PropTypes.func,
  shipmentId: PropTypes.string,
};

export default RemoveSplitPopover;
