import {gql} from "@apollo/client";

export const PRODUCT_DETAILS = gql`
  query bioLcCoproConfigCenterProductDetails {
    bioLcCoproConfigCenterProductDetails {
      Records {
        product_detail_id
        material_id
        material_code
        material_short_name
        issc_desc
        country_id
        valid_from
        valid_to
        country_id
        sap_material_code
      }
    }
  }
`;

export default {PRODUCT_DETAILS};
