import PropTypes from "prop-types";
import {Button, Popover, PopoverBody} from "reactstrap";
import {Remove32} from "@bphxd/ds-core-react/lib/icons";

const DeletePopover = ({
  showPopover,
  setShowPopover,
  header,
  message,
  cancel,
  confirm,
  idToDelete,
  name,
  handleDelete,
  popoverTarget,
}) => {
  return (
    <Popover
      popperClassName="delete-popover"
      innerClassName="delete-popover-inner"
      placement="bottom"
      hideArrow={false}
      isOpen={showPopover}
      target={popoverTarget}
    >
      <PopoverBody>
        <div className="flex flex-row pl-3.5 py-3.5 text-lg border-b border-gray-100 items-center justify-between">
          <span>{header}</span>
          <Remove32
            className="text-gray-300"
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(false);
            }}
          />
        </div>
        <div
          data-test="delete-popover-body"
          className="flex flex-col px-3.5 pt-3.5 pb-20"
        >
          <span className="text-sm">{message}</span>
          <span className="text-2xl text-slate-300">{name ?? idToDelete}</span>
        </div>
        <div className="flex flex-row border-t border-gray-100">
          <div className="flex justify-center border-r border-gray-100 w-1/2">
            <Button
              className="!text-gray-500 active:border-none"
              color=""
              onClick={(e) => {
                e.stopPropagation();
                setShowPopover(false);
              }}
            >
              {cancel}
            </Button>
          </div>
          <div className="flex justify-center w-1/2">
            <Button
              className="active:border-none"
              color=""
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              {confirm}
            </Button>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

DeletePopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  header: PropTypes.string,
  message: PropTypes.string,
  cancel: PropTypes.string,
  confirm: PropTypes.string,
  name: PropTypes.string,
  idToDelete: PropTypes.string,
  handleDelete: PropTypes.func,
  popoverTarget: PropTypes.string,
};

export default DeletePopover;
