import React, {useMemo, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {USER_SETTINGS} from "graphql/userSettings";
import PropTypes from "prop-types";
import {UserSettingsContext} from "./context";

export const UserSettings = ({children}) => {
  const {data: userSettingsData} = useQuery(USER_SETTINGS);
  const [userSettings, setUserSettings] = useState({
    dateFormat: "MM/DD/YYYY",
    decimalFormat: "Imperial Style",
  });

  useEffect(() => {
    const userSettingDetails =
      userSettingsData?.getUserPreference?.userSettings[0];
    if (userSettingDetails) {
      setUserSettings(userSettingDetails);
    }
  }, [userSettingsData]);

  const userSettingsContextValue = useMemo(() => {
    return {userSettings, setUserSettings};
  }, [userSettings, setUserSettings]);

  return (
    <UserSettingsContext.Provider value={userSettingsContextValue}>
      {children}
    </UserSettingsContext.Provider>
  );
};

UserSettings.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserSettings;
