import {useLazyQuery} from "@apollo/client";
import {COPRO_US_RECALL_COMMITTED} from "graphql/coprocessing/shipments";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import DeletePopover from "../Shared/DeletePopover";
import {ERRORS, RECALL_POPOVER} from "./utils";

const RecallPopover = ({
  showPopover,
  setShowPopover,
  batchId,
  refetch = () => {},
  popoverTarget,
}) => {
  const [recallCommitted] = useLazyQuery(COPRO_US_RECALL_COMMITTED, {
    fetchPolicy: "network-only",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsShipmentsApi?.statusCode === 200) {
        refetch();
      } else {
        toast.error(
          result?.bioLcCoproUsShipmentsApi?.error || ERRORS.FAILED_RECALL,
        );
      }
    },
  });

  const handleRecall = () => {
    recallCommitted({
      variables: {
        batchId,
      },
    });

    setShowPopover(false);
  };

  return (
    <DeletePopover
      handleDelete={handleRecall}
      setShowPopover={setShowPopover}
      showPopover={showPopover}
      popoverTarget={popoverTarget}
      header={RECALL_POPOVER.HEADER}
      message={RECALL_POPOVER.MESSAGE}
      cancel={RECALL_POPOVER.CANCEL}
      confirm={RECALL_POPOVER.YES}
    />
  );
};

RecallPopover.propTypes = {
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  batchId: PropTypes.string,
  refetch: PropTypes.func,
  popoverTarget: PropTypes.string,
};

export default RecallPopover;
