import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import "./locale";

const Form = ({
  children,
  schema,
  onSubmit,
  defaultValues,
  mode = "onBlur",
  reValidateMode = "onSubmit",
  ...props
}) => {
  const {handleSubmit, ...formMethods} = useForm({
    mode,
    reValidateMode,
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} {...props}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  mode: PropTypes.string,
  reValidateMode: PropTypes.string,
};

export default Form;
