import {gql} from "@apollo/client";

// Fetch query for Certificate id table on Config center(/configuration-center/certificate-id)
export const CERTIFICATE_ID_GET_QUERY = gql`
  query bioLcGetCertificationDetailsApi {
    bioLcGetCertificationDetailsApi(event: {}) {
      certificationDetailsData {
        countryCode
        divisionCode
        businessPartnerCode
        businessPartnerName
        address
        certificationSchemeCode
        certificationType
        mbTypeCode
        certificateId
        validFrom
        validTo
      }
    }
  }
`;

export default {CERTIFICATE_ID_GET_QUERY};
