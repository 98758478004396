import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import graphAPICommonMethods from "utils/helpers/graphAPICommonMethods";
import {Spin} from "antd";

import {countryData} from "./PoSCountryData";

const redirectPage = (navigate, country) => {
  const navigationMap = {
    UK: "/pos-management/uk",
    NL: "/pos-management/nl",
    PL: "/pos-management/pl",
    LU: "/pos-management/lux",
  };

  navigate(navigationMap[country] || navigationMap.UK);
};

const PoSLandingPage = () => {
  const navigate = useNavigate();
  const [userCountry, setUserCountry] = useState(null);

  useEffect(() => {
    async function fetchGraphAccessToken() {
      const accessTokenGraph = await graphAPICommonMethods.getMSGraphAPIToken();
      const userCountryFromToken =
        await graphAPICommonMethods.getUserCountry(accessTokenGraph);
      setUserCountry(userCountryFromToken ?? "");
    }
    fetchGraphAccessToken();
  }, []);

  const countryCode = countryData?.filter(
    (x) =>
      x.title?.toLowerCase()?.trim() === userCountry?.toLowerCase()?.trim(),
  )?.[0]?.value;

  redirectPage(navigate, countryCode);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Spin />
    </div>
  );
};

export default PoSLandingPage;
