import BatchDetailDropdown from "modules/co-processing/components/Batches/BatchDetailDropdown";
import DetailItem from "modules/co-processing/components/Batches/DetailItem";
import BatchesStatusChip from "modules/co-processing/components/Shared/BatchesStatusChip";
import PropTypes from "prop-types";
import {BATCH_DETAILS_CARD_COLUMNS} from "../../constants/batches";
import BatchProgressIndicator from "../Shared/BatchChecklist/BatchChecklistProgress";
import {batchStatusPropTypes} from "../Shared/BatchChecklist/checkListStatusTypes";

// Story 399833 / Sprint 66 - Comment out emts and state statuses until further notice
const extractStatusDeets = (batchDetails) => ({
  batch_created_status: batchDetails?.batch_created_status,
  shipment_linked_status: batchDetails?.shipment_linked_status,
  c14_linked_status: batchDetails?.c14_linked_status,
  feedstock_allocated_status: batchDetails?.feedstock_allocated_status,
  // emts_submitted_status: batchDetails?.emts_submitted_status,
  // state_submitted_status: batchDetails?.state_submitted_status,
  // state_final_uploaded_status: batchDetails?.state_final_uploaded_status,
  completion_percentage: batchDetails?.completion_percentage,
});

const BatchDetailsCard = ({batchDetails}) => {
  return (
    <div className="flex flex-col w-full">
      <div className="bg-white">
        <div className="flex justify-between items-center px-5 py-3 border-b">
          <BatchesStatusChip value={batchDetails.batch_status} small />
          <BatchProgressIndicator
            popoverId={batchDetails?.batch_id.replace(".", "-")}
            statusDetails={extractStatusDeets(batchDetails?.details)}
          />
          <BatchDetailDropdown batchDetails={batchDetails} expanded />
        </div>
        <div className="grid grid-row-2 px-5 py-6">
          <div className="mb-4">
            <div className="text-sm">{batchDetails.tank_number}</div>
            <div className="text-2xl">{batchDetails.batch_id}</div>
          </div>
          <div className="grid grid-cols-3 gap-4 w-full text-sm">
            {BATCH_DETAILS_CARD_COLUMNS.map((item, index) => (
              <DetailItem
                key={index}
                label={item.label}
                value={batchDetails[item.key] ?? "N/A"}
                format={batchDetails?.[item.key] ? item.format : ""}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BatchDetailsCard.propTypes = {
  batchDetails: PropTypes.shape({
    batch_status: PropTypes.string,
    tank_number: PropTypes.string,
    batch_id: PropTypes.string,
    batch_start_time: PropTypes.string,
    batch_end_time: PropTypes.string,
    operating_condition: PropTypes.string,
    tank_certified_datez: PropTypes.string,
    tank_volume: PropTypes.number,
    c14_test_date: PropTypes.string,
    details: PropTypes.shape(batchStatusPropTypes),
  }),
};

export default BatchDetailsCard;
