import getInTouch from "content/getInTouch";
import GetInTouchBox from "./GetInTouchBox";

const GetInTouch = () => {
  return (
    <div data-test="get-in-touch" className="mt-16">
      <div className="flex items-center gap-x-4">
        <h4
          data-test="get-in-touch-title"
          className="text-xl font-bold text-indigo-500"
        >
          Get in touch
        </h4>
        <div className="w-3/5 border border-gray-100" />
      </div>
      <div
        className="flex flex-col gap-5 mt-6 sm:flex-row"
        data-test="get-in-touch-box"
      >
        {getInTouch
          .filter((item) => item.isVisible)
          .map((item, index) => (
            <GetInTouchBox
              key={index}
              img={item.img}
              title={item.title}
              description={item.description}
            />
          ))}
      </div>
    </div>
  );
};

export default GetInTouch;
