import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const SAF_GET_OUTGOING_DOCUMENTS = gql`
  query bioLcSafGetOutgoingDocs($site_reference_id: String) {
    bioLcSafGetOutgoingDocs(
      event: {
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
        site_reference_id: $site_reference_id
      }
    ) {
      error
      statusCode
      total
      documents {
        certificate_outbound_eu_id
        sd_number
        date_of_issuance
        supplier_name
        supplier_address
        certification_system
        certificate_number
        recipient_name
        recipient_address
        contract_number
        supplier_dispatch_address
        recipient_receipt_address
        material_dispatch_datez
        bio_product_producer
        producer_installation_date
        bio_product_user
        user_installation_date
        product_type
        raw_material_type
        additional_info
        raw_material_origin_country_name
        product_qty
        product_qty_uom
        product_energy_content
        eu_red_compliant_flag
        iscc_compliant_flag
        chain_of_custody_option
        material_sustainability_criteria_flag
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        raw_material_waste_or_residue_flag
        nuts2_region
        default_value_applied_flag
        ghg_eec
        ghg_el
        ghg_ep
        ghg_etd
        ghg_eu
        ghg_esca
        ghg_eccs
        ghg_eccr
        ghg_total
        allocated_heat
        allocated_electricity
        ghg_emission_percentage_in
        status
        recipient_receipt_address_same_flag
        supplier_dispatch_address_same_flag
      }
    }
  }
`;

export const SAF_GET_OUTGOING_DOCUMENTS_SINGLE = gql`
  query OutgoingDocuments(
    $certificate_outbound_eu_id: String
    $site_reference_id: String
    $division_id: String
  ) {
    bioLcSafGetMaterials(event: {}) {
      error
      statusCode
      product_names {
        product_name
      }
      raw_material_names {
        raw_material_name
      }
    }

    bioLcSafGetOutgoingDocFile(
      event: {
        division_id: $division_id
        site_reference_id: $site_reference_id
        certificate_outbound_eu_id: $certificate_outbound_eu_id
      }
    ) {
      error
      statusCode
      download_url
    }

    bioLcSafGetOutgoingDocs(
      event: {
        division_id: $division_id
        site_reference_id: $site_reference_id
        certificate_outbound_eu_id: $certificate_outbound_eu_id
      }
    ) {
      error
      statusCode
      total
      documents {
        certificate_outbound_eu_id
        sd_number
        date_of_issuance
        supplier_name
        supplier_address
        certification_system
        certificate_number
        recipient_name
        recipient_address
        contract_number
        supplier_dispatch_address
        recipient_receipt_address
        material_dispatch_datez
        bio_product_producer
        producer_installation_date
        bio_product_user
        user_installation_date
        product_type
        raw_material_type
        additional_info
        raw_material_origin_country_name
        product_qty
        product_qty_uom
        product_energy_content
        eu_red_compliant_flag
        iscc_compliant_flag
        chain_of_custody_option
        material_sustainability_criteria_flag
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        raw_material_waste_or_residue_flag
        nuts2_region
        default_value_applied_flag
        ghg_eec
        ghg_el
        ghg_ep
        ghg_etd
        ghg_eu
        ghg_esca
        ghg_eccs
        ghg_eccr
        ghg_total
        allocated_heat
        allocated_electricity
        ghg_emission_percentage_in
        status
        recipient_receipt_address_same_flag
        supplier_dispatch_address_same_flag
        iscc_issuer_license
      }
    }
  }
`;

export const SAF_SEND_CERTIFICATE_EMAIL = gql`
  mutation bioLcSafOutgoingCertEmail($event: SafEmailOutgoingDocRequest!) {
    bioLcSafOutgoingCertEmail(event: $event) {
      error
      statusCode
    }
  }
`;

export const SAFSendEmail = async (data) => {
  return client.mutate({
    mutation: SAF_SEND_CERTIFICATE_EMAIL,
    variables: {
      ...data,
    },
  });
};

export default SAF_GET_OUTGOING_DOCUMENTS;
