import {gql} from "@apollo/client";

// Fetch query for Material master table on Config center(/configuration-center/material-master)
export const MATERIAL_MASTER_GET_QUERY = gql`
  query bioLcGetMaterialMasterData {
    bioLcGetMaterialMasterData(event: {}) {
      materialMasterData {
        materialId
        materialShortName
        materialLongName
        materialType
        isscDesc
        sapMaterialCode
        acceptedByCertificationSchemes
      }
    }
  }
`;

export default {MATERIAL_MASTER_GET_QUERY};
