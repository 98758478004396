import PropTypes from "prop-types";
import React from "react";
import {Link} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import "./index.css";

const Layout = ({title, breadcrumbs, subtitle, children, showHR = true}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  return (
    <div className="bg-light-gray-100 pt-5 min-h-[70vh]">
      <Breadcrumb className="px-7">
        {breadcrumbs.map((item, index) => (
          <BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
            {item.text === "BioVerse" ? (
              <Link
                to="/"
                data-test="home-button"
                onClick={() => {
                  const countryFeatureData =
                    appSetting?.featureMappingData?.find(
                      (item) => item.countryName === "GLOBAL",
                    );

                  setAppSetting({
                    ...appSetting,
                    currentCountry: "GLOBAL",
                    currentCountryMappingData: countryFeatureData,
                    currentModule: "",
                    currentModuleTitle: "",
                  });
                }}
              >
                {item.text}
              </Link>
            ) : index === breadcrumbs.length - 1 ? (
              <span>{item.text}</span>
            ) : (
              <Link to={item.link}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <h1 className="text-[32px] text-black fw-light px-7">{title}</h1>
      {subtitle}
      <div className="px-7">{showHR && <hr className="m-0" />}</div>
      {children}
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  breadcrumbs: PropTypes.array.isRequired,
  children: PropTypes.node,
  showHR: PropTypes.bool,
};
export default Layout;
