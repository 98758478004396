import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {ADJUSTMENT_SUMMARY} from "../constants/inventory";
import {generateAdjustmentSummary} from "../helpers/generateSaveVersionsData";

const AdjustmentSummary = ({userEditedValues, toPublish = false}) => {
  const convertToDisplaySummary = (userEditedVals, toPublishFlag) => {
    // For adjustment summary in To Publish modal passing all the data
    if (toPublishFlag) {
      const {resultString} = generateAdjustmentSummary(
        userEditedVals,
        false,
        toPublishFlag,
      );
      return resultString;
    }

    // For adjustment summary on EditForecast page only providing measurements with the adjustment
    // reason included
    const result = [];
    userEditedVals.forEach((obligation) => {
      if ("reason" in obligation && obligation.reason.length) {
        result.push(obligation);
      }
    });

    const {resultString: adjustmentResultString} =
      generateAdjustmentSummary(result);
    return adjustmentResultString;
  };

  const adjustmentSummary = useMemo(
    () => convertToDisplaySummary(userEditedValues, toPublish),
    [userEditedValues, toPublish],
  );

  return (
    <>
      <div
        data-test="adjustment-summary-header"
        className="pt-6 pb-1 add-comments-styles"
      >
        {ADJUSTMENT_SUMMARY}
      </div>
      <div
        data-test="adjustment-summary-value"
        className={`w-full ${toPublish ? "lg:w-full" : "lg:w-2/3"}`}
      >
        {adjustmentSummary}
      </div>
    </>
  );
};

AdjustmentSummary.propTypes = {
  userEditedValues: PropTypes.arrayOf(PropTypes.object).isRequired,
  toPublish: PropTypes.bool,
};

export default AdjustmentSummary;
