import {Select as ASelect} from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const Select = React.forwardRef(
  (
    {
      children,
      className = "",
      options,
      placeholder,
      value,
      showSearch,
      ...props
    },
    ref,
  ) => (
    <ASelect
      showSearch={showSearch}
      filterOption={showSearch}
      onSearch={() => ""}
      ref={ref}
      value={value}
      placeholder={placeholder}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      className={["bp-select", className].join(" ")}
      title={value ? undefined : placeholder}
      options={options}
      {...props}
    >
      {children}
    </ASelect>
  ),
);

Select.Option = ASelect.Option;

Select.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  showSearch: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({value: PropTypes.string, label: PropTypes.string}),
  ),
};

export default Select;
