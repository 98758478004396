import PropTypes from "prop-types";
import React from "react";

import FilterDateRangeField from "./FilterDateRangeField";
import FilterSearchField from "./FilterSearchField";
import FilterSelectField from "./FilterSelectField";

import "./filter.css";

const Filter = ({column, data, isParentKey = true}) => {
  const columnFilterValue = column.getFilterValue();
  const {filterVariant} = column.columnDef.meta ?? {};

  const renderFilterField = () => {
    switch (filterVariant) {
      case "select":
        return (
          <FilterSelectField
            column={column}
            columnFilterValue={columnFilterValue}
            data={data}
            isParentKey={isParentKey}
          />
        );
      case "daterange":
        return (
          <FilterDateRangeField
            column={column}
            columnFilterValue={columnFilterValue}
          />
        );
      default:
        return (
          <FilterSearchField
            column={column}
            columnFilterValue={columnFilterValue}
          />
        );
    }
  };

  return renderFilterField();
};

Filter.propTypes = {
  column: PropTypes.object,
  data: PropTypes.array,
};

export default Filter;
