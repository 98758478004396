export const downloadFile = (fileUrl) => {
  const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
  const a = document.createElement("a");
  a.href = fileUrl;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export default downloadFile;
