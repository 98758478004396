import {useQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import {
  COMPLIANCE_RULES_LANDING_LIST_DISPLAY,
  POPULATE_COUNTRY_DROPDOWN,
} from "graphql/compliance-rules-engine/RulesLandingPageAPI";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import {useEffect, useState} from "react";
import {useFormContext} from "react-hook-form";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {countries} from "../components/countryList";
import FormDropdownWithIcon from "../components/FormDropdown/FormDropdownWithIcon";

const RuleModalDisplay = ({
  showUploadModal,
  setUploadModal,
  onSubmit,
  countryCode,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [dropdownValue, setDropdownValue] = useState(countryCode);

  const {appSetting} = useAppSetting();

  const {
    data: countryData,
    loading: countryLoading,
    error: countryError,
  } = useQuery(POPULATE_COUNTRY_DROPDOWN);

  // fetch country list

  const {
    data: existingRulesData,
    loading: rulesLoading,
    error: rulesError,
  } = useQuery(COMPLIANCE_RULES_LANDING_LIST_DISPLAY, {
    variables: {
      countryCode:
        appSetting?.currentCountryCode === "GO"
          ? "ALL"
          : appSetting?.currentCountryCode,
    },
    skip: appSetting?.currentCountryCode === undefined,
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
    setError,
    setValue,
    trigger,
    clearErrors,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    if (countryCode) {
      setValue("dropdownValue", countryCode);
    }
  }, [countryCode, setValue]);

  // condition to check if the user inputs an existing rule name

  const checkExistingRuleNames = async (existingRulesData, inputValue) => {
    const existingRuleNames =
      existingRulesData?.bioLcComplianceRulesEngineGetRules?.rules?.map(
        (rule) => rule.ruleName,
      ) || [];

    if (existingRuleNames.includes(inputValue)) {
      setError("inputValue", {
        type: "manual",
        message: "This name already exists. Please choose a different name.",
      });
      return false;
    }
    return true;
  };

  // if name is not present in the table, then create one entry

  const onSubmitHandler = async (data) => {
    const isValid = await trigger();
    if (isValid) {
      const isNameValid = await checkExistingRuleNames(
        existingRulesData,
        data.inputValue,
        data.dropdownValue,
      );
      if (isNameValid) {
        await onSubmit({
          inputValue: data.inputValue,
          dropdownValue: data.dropdownValue,
        });
        setUploadModal(false);
      }
    }
  };

  // get country data here

  const countryOptions = countryData
    ? countryData.bioLcGlobNaviGetModuleCatalogue.map((country) => ({
        value: country.countryCode,
        label: country.countryName,
      }))
    : [];

  // rule out the global from country dropdown as its not required

  const filteredCountryOptions = countryOptions.filter(
    (country) =>
      country.label.toLowerCase() !== "global" && country.value !== "GO",
  );

  return (
    <div>
      <Modal
        isOpen={showUploadModal}
        className="modal-dialog-centered"
        style={{width: "351px"}}
      >
        <ModalHeader
          className="!border-b-[1px] !border-gray-200 text-lg"
          close={
            <Close
              onClick={() => {
                setUploadModal(false);
              }}
            />
          }
        >
          New rule
        </ModalHeader>
        <ModalBody className="py-0">
          <form id="upload-form" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="w-full flex flex-col gap-7">
              <span className="text-sm"></span>

              <FormGroup>
                <Label for="inputValue" className="fw-medium text-sm">
                  Rule name
                </Label>
                <Input
                  type="input"
                  placeholder="Enter name"
                  id="inputValue"
                  value={inputValue}
                  maxLength={200}
                  {...computeProps("inputValue", {
                    required: "Please enter rule name",
                  })}
                  invalid={!!errors.inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    setValue("inputValue", e.target.value);
                    clearErrors("inputValue");
                  }}
                />

                {errors.inputValue && (
                  <FormFeedback className="mt-2 !block">
                    {errors.inputValue.message}
                  </FormFeedback>
                )}
              </FormGroup>

              <FormGroup>
                <Label for="inputValue" className="fw-medium text-sm">
                  Country
                </Label>

                <div className="country-dropdown">
                  <FormDropdownWithIcon
                    options={filteredCountryOptions.map((country) =>
                      country.label.toLowerCase(),
                    )}
                    values={filteredCountryOptions.map(
                      (country) => country.value,
                    )}
                    icons={filteredCountryOptions.map(
                      (country) =>
                        countries?.find(
                          (val) =>
                            val.countryName?.toLowerCase() ===
                            country.label.toLowerCase(),
                        )?.img,
                    )}
                    data-test="periodYear"
                    placeholder="Select country"
                    menuClassName="country-dropdown-filter w-full"
                    value={dropdownValue}
                    {...computeProps("dropdownValue", {
                      validate: (value) =>
                        value !== "GO" || "Please select a country",
                    })}
                    invalid={!!errors.dropdownValue}
                    onChange={(selectedOption) => {
                      setDropdownValue(selectedOption);
                      setValue("dropdownValue", selectedOption);
                      clearErrors("dropdownValue");
                    }}
                    style={{boxShadow: "none !important"}} // Added this line
                  />
                </div>
                {errors.dropdownValue && (
                  <FormFeedback className="mt-2 !block">
                    {errors.dropdownValue.message}
                  </FormFeedback>
                )}
              </FormGroup>
              <ModalFooter className="p-0 d-block mt-5">
                <div className="row g-0 m-0 modal-footer-row">
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                      onClick={() => {
                        setUploadModal(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="col-6 d-grid">
                    <Button
                      color="darker-tertiary"
                      size="lg"
                      className="border-0 rounded-0 py-4 bg-transparent text-default"
                      type="submit"
                      disabled={rulesLoading || countryLoading}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

RuleModalDisplay.propTypes = {
  showUploadModal: PropTypes.bool,
  setUploadModal: PropTypes.func,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  countryCode: PropTypes.string,
};

export default RuleModalDisplay;
