import {useLazyQuery} from "@apollo/client";
import ArrowLeft from "assets/images/svg/arrow-left.svg";
import Upload from "assets/images/svg/upload.svg";
import axios from "axios";
import {posCertificateInstructions} from "content/instructions";
import {UPLOAD_POS_FILE} from "graphql/file-upload";
import {POS_FILE_PARSE_TRIGGER} from "graphql/pos";
import {PoSErrorModal} from "modules/common/StatusModals/ErrorModal";
import LoadingModal from "modules/common/StatusModals/LoadingModal";
import SuccessModal from "modules/common/StatusModals/SuccessModal";
import Title from "modules/common/Title";
import {useMemo, useState} from "react";
import {useDropzone} from "react-dropzone";
import {Helmet} from "react-helmet";
import {Link, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Instructions from "../reports/components/UploadDocuments/Instructions";
import Tabs from "./Tabs";

const UploadPOSCertificate = () => {
  const navigate = useNavigate();

  const {country} = useParams();
  let countryCodeValue = "";
  if (country === "uk") {
    countryCodeValue = "gbr";
  } else if (country === "pl") {
    countryCodeValue = "pol";
  } else {
    countryCodeValue = "nld";
  }
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [allowEdit, setAllowEdit] = useState(true);
  const [comment, setComment] = useState("");
  const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
    accept: "application/pdf",
    maxFiles: 1,
  });

  const files = acceptedFiles.map((item, index) => (
    <li data-test="file" className="font-medium" key={index}>
      {item.path}
    </li>
  ));
  const file = acceptedFiles[0];
  const formData = new FormData();
  const previewUrl = useMemo(
    () =>
      acceptedFiles?.length > 0 ? URL.createObjectURL(acceptedFiles[0]) : "",
    [acceptedFiles],
  );

  const onModalCancel = () => setShowSuccessModal(false);

  const onErrorModalCancel = () => setShowErrorModal(false);

  const [triggerParsing, {data}] = useLazyQuery(POS_FILE_PARSE_TRIGGER, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setShowLoadingModal(false);
      if (data?.posDataExtractor?.statusCode === 200) {
        acceptedFiles.length = 0;
        acceptedFiles.splice(0, acceptedFiles.length);
        setComment("");
        setShowSuccessModal(true);
        setIsSubmitDisabled(false);
        setAllowEdit(true);
      } else {
        setShowErrorModal(true);
        setIsSubmitDisabled(false);
        setAllowEdit(true);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const [uploadQuery, {data: dataRes}] = useLazyQuery(UPLOAD_POS_FILE, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      const body = dataRes?.posFileUploader?.data?.url;
      const parsedJsonBody = JSON.parse(body);
      if (parsedJsonBody.fields) {
        Object.entries(parsedJsonBody.fields).forEach(([k, v]) => {
          formData.append(k, v);
        });
        formData.append("file", file);
      }

      axios.post(parsedJsonBody.url, formData).then(
        (result) => {
          if (result.status === 200 || result.status === 204) {
            triggerParsing({
              variables: {
                fileName: file.name,
                country: countryCodeValue,
              },
            });
          } else {
            setShowErrorModal(false);
            setIsSubmitDisabled(false);
            setAllowEdit(true);
          }
        },
        () => {
          setShowErrorModal(false);
          setIsSubmitDisabled(false);
          setAllowEdit(true);
        },
      );
    },
  });

  const onSubmit = async () => {
    setIsSubmitDisabled(true);
    setAllowEdit(false);
    if (file !== null && file !== undefined) {
      setShowLoadingModal(true);
      uploadQuery({
        variables: {
          com: comment.replace(/\n|\r/g, "/"),
          file: file.name,
          countryCode: countryCodeValue,
        },
      });
    } else {
      toast.error("Please select file!");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <>
      <Helmet>
        <title>Upload PoS Certificate - Biofuels Value Chain</title>
      </Helmet>
      <Title title="Proof of Sustainability" />
      <div className="px-6 py-8 lg:px-10">
        <div className="mb-8">
          <Tabs country={country} />
        </div>
        <h1 className="mb-8 text-3xl font-bold text-black">
          Upload PoS Certificate
        </h1>
        <Instructions items={posCertificateInstructions[0].items} />
        <div className="w-full mt-12 sm:w-3/4">
          <form onSubmit={handleSubmit}>
            <div className="flex items-center gap-x-4">
              <h4
                data-test="title"
                className="text-xl font-bold text-indigo-500"
              >
                File uploader
              </h4>
              <div className="w-3/5 border border-gray-100" />
            </div>
            <p data-test="instructions" className="mt-2 text-indigo-500">
              Please upload the supporting documents, if applicable.
            </p>
            <div className="mt-8">
              <label
                data-test="document-label"
                className="text-xs text-gray-400"
                htmlFor="documents"
              >
                DOCUMENT
              </label>
              <div {...getRootProps({className: "dropzone w-full mt-1"})}>
                <input
                  data-test="hidden-file-input"
                  id="documents"
                  {...getInputProps()}
                />
                <div className="flex items-center px-2 py-1 bg-gray-100 border-2 border-dashed gap-x-3">
                  <button
                    type="button"
                    className="flex items-center px-3 py-2 text-sm text-white bg-gray-800 rounded gap-x-2 hover:opacity-50"
                  >
                    <img src={Upload} alt="Upload" className="w-3 h-4" />
                    SELECT FILE
                  </button>
                  <p className="text-sm text-indigo-500">or drop file here</p>
                </div>
              </div>
              <div className="flex justify-between mt-2">
                <label
                  data-test="file-type-label"
                  className="text-xs text-gray-400"
                  htmlFor="documents"
                >
                  File type .pdf
                </label>
              </div>
              <aside className="flex mt-2">
                <ul>{files}</ul>
                <div
                  data-test="file-uploader-preview-continer"
                  hidden={acceptedFiles?.length === 0}
                  className="w-full ml-4 border-2 border-gray-300 rounded-md"
                >
                  <iframe
                    className="m-0.5"
                    key="pos-certificate-preview-content"
                    data-test="file-uploader-preview"
                    width="100%"
                    border="0"
                    height="700"
                    src={previewUrl}
                    title={acceptedFiles?.[0]?.name}
                  />
                </div>
              </aside>
              <div className="mt-4">
                <textarea
                  data-test="comment"
                  className="w-full !outline-none p-3 border border-gray-200 rounded h-36"
                  placeholder="Add comment"
                  value={comment}
                  disabled={!allowEdit}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="flex mt-10 gap-x-6">
                <Link
                  data-test="back-button"
                  to={`/pos-management/upload-documents/${country}`}
                  className="flex items-center justify-between px-4 py-3 font-bold text-indigo-500 border-2 border-indigo-500 rounded w-36 hover:opacity-50"
                >
                  <img src={ArrowLeft} alt="Back" />
                  BACK
                </Link>
                <button
                  data-test="submit-button"
                  type="submit"
                  className={`text-center w-36 px-4 py-3  font-bold text-white rounded ${
                    !(acceptedFiles?.length && comment) ||
                    isSubmitDisabled ||
                    country === "nl"
                      ? "disabled:opacity-50 cursor-not-allowed bg-gray-800"
                      : "hover:opacity-50 bg-bp-green-800"
                  }`}
                  disabled={
                    !(acceptedFiles?.length && comment) ||
                    isSubmitDisabled ||
                    country === "nl"
                  }
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </form>
        </div>
        <SuccessModal
          title="Successfully Imported"
          visible={showSuccessModal}
          onCancel={onModalCancel}
          description="Thank You. Your file was uploaded successfully"
          showCrossArrow={false}
          onBack={() => navigate(`/pos-management/${country}`)}
          onUpload={() => {
            setShowSuccessModal(false);
            open();
          }}
        />
        <PoSErrorModal
          title="Error"
          visible={showErrorModal}
          onCancel={onErrorModalCancel}
        />
        <LoadingModal title="Upload in progress" visible={showLoadingModal} />
      </div>
    </>
  );
};

export default UploadPOSCertificate;
