import {useMemo} from "react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {Input, Table} from "reactstrap";
import PropTypes from "prop-types";
import {formatNumber} from "providers/userSettings";
import "./index.scss";

const buildBulkResetColumns = (allocatedShipments, handleCheckboxChange) => {
  const columns = [
    {
      header: "Bill of lading (BOL)",
      accessorKey: "outgoing_bol_number",
      size: 80,
      meta: {position: "sticky", left: 0},
    },
    {
      header: "Material name",
      accessorKey: "sap_material_name",
      size: 80,
      meta: {position: "sticky", left: 142},
    },
    {
      header: "RINs Qualified",
      accessorKey: "credits_qualified",
      cell: (cell) => {
        const value = cell.getValue();
        return value === "true" || value === true ? "Yes" : "No";
      },
      size: 80,
      meta: {position: "sticky", left: 257},
    },
    {
      header: "Truck lifting volume (gal)",
      accessorKey: "truck_lifting_volume",
      className: "last-pinned-column",
      cell: (cell) => formatNumber(cell.getValue(), "", 2),
      size: 200,
      meta: {position: "sticky", left: 372},
    },
  ];

  Object.keys(allocatedShipments || {}).forEach((shipmentId) => {
    columns.push({
      header: shipmentId,
      accessorKey: shipmentId,
      size: 170,
      cell: ({row}) => (
        <Input
          type="checkbox"
          checked={
            !!allocatedShipments[shipmentId][row.original.dtn_bol_layer_id]
          }
          onChange={() =>
            handleCheckboxChange(
              shipmentId,
              row.original.dtn_bol_layer_id,
              row.original.truck_lifting_volume,
            )
          }
        />
      ),
    });
  });

  return columns;
};

export const sumSelectedVolumes = (
  /** @type {String} */ id,
  /** @type {any[]} */ allocatedShipments,
) => {
  if (!allocatedShipments[id]) return 0;

  const totalVolumeSelected = Object.values(allocatedShipments[id]).reduce(
    (total, volume) => total + volume,
    0,
  );
  return formatNumber(totalVolumeSelected, "", 2);
};

export const getTotalActualVolume = (
  /** @type {String} */ id,
  /** @type {any[]} */ selectedShipments,
) => {
  const shipment = selectedShipments?.find(
    (/** @type {{ shipment_id: String; }} */ s) => s?.shipment_id === id,
  );
  return !shipment ? 0 : formatNumber(shipment.totalActualVolume, "", 2);
};

const BulkAllocationResetTable = ({
  allocationInfo,
  BOLData,
  selectedShipments,
  handleCheckboxChange,
}) => {
  const columns = useMemo(
    () => buildBulkResetColumns(allocationInfo, handleCheckboxChange),
    [allocationInfo, handleCheckboxChange],
  );

  const tableInstance = useReactTable({
    data: BOLData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const renderVolumeSummaryRow = (
    /** @type {string} */ text,
    /** @type {boolean} */ isFirstRow,
  ) => {
    return (
      <tr className={`volume-summary-section ${isFirstRow ? "first-row" : ""}`}>
        <td colSpan={4} className="volume-summary-label">
          {text}
        </td>

        {tableInstance
          .getFlatHeaders()
          ?.slice(4)
          ?.map((header) => (
            <td key={header.id}>
              {isFirstRow
                ? sumSelectedVolumes(header?.id, allocationInfo)
                : getTotalActualVolume(header?.id, selectedShipments)}
            </td>
          ))}
      </tr>
    );
  };

  return (
    <div className="bulk-reset-truck-rack-table">
      <Table borderless>
        <thead>
          <tr>
            {tableInstance?.getFlatHeaders()?.map((header) => {
              return (
                <th
                  key={header.id}
                  style={{
                    left: header.column.columnDef.meta?.left,
                    position: header.column.columnDef?.meta?.position,
                  }}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext(),
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableInstance.getRowModel()?.rows?.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cell.column.columnDef?.className}
                  style={{
                    position: cell.column.columnDef?.meta?.position,
                    left: cell.column.columnDef.meta?.left,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
          {renderVolumeSummaryRow("Volume selected", true)}
          {renderVolumeSummaryRow("Actual shipment volume", false)}
        </tbody>
      </Table>
    </div>
  );
};

BulkAllocationResetTable.propTypes = {
  allocationInfo: PropTypes.object,
  BOLData: PropTypes.arrayOf(
    PropTypes.shape({
      dtn_bol_layer_id: PropTypes.string.isRequired,
      outgoing_bol_number: PropTypes.string,
      sap_material_name: PropTypes.string,
      credits_qualified: PropTypes.string,
      truck_lifting_volume: PropTypes.number,
    }),
  ).isRequired,
  selectedShipments: PropTypes.arrayOf(
    PropTypes.shape({
      shipment_id: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      totalActualVolume: PropTypes.number.isRequired,
      bols: PropTypes.objectOf(PropTypes.number).isRequired,
    }),
  ),
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default BulkAllocationResetTable;
