import {useAccount, useMsal} from "@azure/msal-react";
import {
  POS_LIST,
  POS_PL_LIST,
  clearNotificationApi,
  getNotificationApi,
} from "graphql/pos";
import {client} from "providers/Apollo";
import useGlobalNotification from "providers/GlobalNotification/useGlobalNotification";
import {useCallback, useEffect} from "react";

const useRefreshSession = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {triggerNotification, clearNotification, setRefreshButtonDisabled} =
    useGlobalNotification();

  const setServerNotificationSeen = useCallback(
    async (id) => {
      const {data} = await clearNotificationApi(id, account.username);
      if (data) {
        clearNotification();
      }
    },
    [clearNotification, account.username],
  );

  const getNotification = useCallback(
    async (timer) => {
      const {data} = await getNotificationApi(account.username);
      if (data) {
        if (
          !data?.getNotification?.expectResult &&
          data?.getNotification?.data?.items?.length === 0
        ) {
          // no pending notifications
          if (timer) {
            clearInterval(timer);
          }
          setRefreshButtonDisabled(false);
        } else if (data?.getNotification?.errors?.length > 0) {
          // error in notification
          if (timer) {
            clearInterval(timer);
          }
          setRefreshButtonDisabled(false);
          triggerNotification(
            "error",
            "PoS data refresh failed",
            clearNotification,
          );
        } else if (data?.getNotification?.data?.items?.length > 0) {
          const currentNotification = data?.getNotification?.data?.items?.[0];
          if (currentNotification?.status === "0") {
            // success notification condition
            if (timer) {
              clearInterval(timer);
            }
            setRefreshButtonDisabled(false);
            triggerNotification("success", "PoS data refresh successful", () =>
              setServerNotificationSeen(currentNotification),
            );
            if (currentNotification?.country === "gbr") {
              await client.refetchQueries({
                include: [POS_LIST],
              });
            } else {
              await client.refetchQueries({
                include: [POS_PL_LIST],
              });
            }
          }
        }
      }
    },
    [
      account.username,
      triggerNotification,
      setServerNotificationSeen,
      clearNotification,
      setRefreshButtonDisabled,
    ],
  );

  const checkPendingNotifications = useCallback(async () => {
    const {data} = await getNotificationApi(account.username);
    if (
      !data?.getNotification?.expectResult &&
      data?.getNotification?.data?.items?.length === 0
    ) {
      setRefreshButtonDisabled(false);
    } else {
      setRefreshButtonDisabled(true);
      const timer = setInterval(() => getNotification(timer), 25000);
    }
  }, [getNotification, account.username, setRefreshButtonDisabled]);

  useEffect(() => {
    checkPendingNotifications();
  }, [checkPendingNotifications]);
};

export default useRefreshSession;
