import {createContext, useState, useContext} from "react";
import PropTypes from "prop-types";

export const ForecastingFeatureFlags = createContext({});

export const ForecastingFeatureFlagProvider = ({children}) => {
  const [creditsState] = useState(true);

  return (
    <ForecastingFeatureFlags.Provider value={creditsState}>
      {children}
    </ForecastingFeatureFlags.Provider>
  );
};

export const useFeatureFlag = () => {
  const context = useContext(ForecastingFeatureFlags);

  if (context === undefined) {
    throw new Error(
      "useFeatureFlag must be used inside ForecastingFeatureFlagProvider",
    );
  }

  return context;
};

ForecastingFeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};
