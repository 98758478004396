import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const WRITE_OFF = gql`
  mutation WriteOff(
    $mbInboundQtyEuId: String!
    $reason: String!
    $createdBy: String!
    $quantity: Float!
    $siteReferenceId: String!
    $divisionId: String!
  ) {
    bioLcGlobalMassBalanceDiscardQuantity(
      event: {
        mbInboundQtyEuId: $mbInboundQtyEuId
        reason: $reason
        createdBy: $createdBy
        quantity: $quantity
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        action: "write-off"
      }
    ) {
      error
      statusCode
    }
  }
`;

export const WriteOffAPI = (data) =>
  client.mutate({
    mutation: WRITE_OFF,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export default {
  WRITE_OFF,
  WriteOffAPI,
};
