import PropTypes from "prop-types";
import {Fragment} from "react";
import kpiDataAndLabels from "modules/forecasting/helpers/getKPIData";
import {collapsibleData} from "./CollapsiblePanel";
import Header from "./InventoryPageHeader";
import KPICardSection from "./KPICardSection";

export function mapUnpublishedToKPIs(forecastType, region, data) {
  const {kpiLabel, kpiMessage1, kpiMessage2, kpiValue} = kpiDataAndLabels(
    data,
    forecastType,
    region,
    "UNPUBLISHED",
  );

  const publishedVsForecastVal =
    forecastType === "rin" || region === "ec"
      ? kpiValue?.published_vs_forecast ?? "N/A"
      : kpiValue?.publishedVsForecast ?? "N/A";
  const publishedVsForecastBblVal =
    forecastType === "rin" || region === "ec"
      ? kpiValue?.published_vs_forecast_bbl ?? "N/A"
      : kpiValue?.publishedVsForecastBbl ?? "N/A";

  return {
    label: kpiLabel,
    message1: kpiMessage1,
    message2: kpiMessage2,
    value1: publishedVsForecastVal,
    value2: publishedVsForecastBblVal,
  };
}

const UnpublishedViewByForecastType = ({region, activeForecastType, data}) => {
  return (
    <>
      <div
        className="col-span-full flex flex-col md:flex-row gap-2 pt-4"
        data-test="kpi-group"
      >
        {collapsibleData(activeForecastType).map(({value}) => {
          return (
            <KPICardSection
              {...mapUnpublishedToKPIs(
                value,
                region,
                value === "rin" ? data.credits : data.deficits,
              )}
            />
          );
        })}
      </div>
      {activeForecastType && region ? (
        <Header region={region} forecastType={activeForecastType} />
      ) : null}
    </>
  );
};

UnpublishedViewByForecastType.propTypes = {
  data: PropTypes.shape({
    credits: PropTypes.object,
    deficits: PropTypes.object,
  }),
  region: PropTypes.string,
  activeForecastType: PropTypes.oneOf(["", "rin", "rvo"]).isRequired,
};

export default UnpublishedViewByForecastType;
