import {useState} from "react";
import {Popover, PopoverBody, Progress} from "reactstrap";
import PropTypes from "prop-types";
import {
  TOOLTIP_LABELS,
  METRICS,
} from "modules/co-processing/constants/bulkAdjustment";
import FieldRow from "./FieldRow";

const BulkAdjustmentBatchPopover = ({batch, index}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <Popover
      data-test={`bulk-adjustment-popover-batch-${batch.batch_id}`}
      popperClassName="bulk-adjustment-batch-popover"
      placement="top"
      isOpen={popoverOpen}
      toggle={togglePopover}
      target={`bulk-adjustment-popover-batch-id-${index}`}
    >
      <PopoverBody>
        <div className="pb-4">
          <div
            className="progress-indicator"
            data-test="batch-bulk-adjust-progress"
          >
            <span className="text-[10px]">
              {Math.round(batch?.details?.completion_percentage ?? 0)}
              {TOOLTIP_LABELS.COMPLETE}
            </span>
            <Progress
              className="rounded-pill"
              color="success"
              style={{height: "5px"}}
              value={Math.round(batch?.details?.completion_percentage ?? 0)}
            />
          </div>
        </div>
        <FieldRow
          label={TOOLTIP_LABELS.TANK_NUMBER}
          value={batch?.tank_number}
        />
        <FieldRow
          label={TOOLTIP_LABELS.TOTAL_VOL}
          value={`${batch?.total_volume?.toLocaleString()} ${METRICS.BBL}`}
        />
        <FieldRow
          label={TOOLTIP_LABELS.REMAINING_VOL}
          value={`${batch?.remaining_volume?.toLocaleString()} ${METRICS.BBL}`}
        />
        <FieldRow
          label={TOOLTIP_LABELS.ENDING_INVENTORY}
          value={`${batch?.actual_ending_inventory?.toLocaleString()} ${
            METRICS.BBL
          }`}
        />
      </PopoverBody>
    </Popover>
  );
};

BulkAdjustmentBatchPopover.propTypes = {
  batch: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default BulkAdjustmentBatchPopover;
