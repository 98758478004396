import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

export const TRANSFER = gql`
  mutation Transfer(
    $mbInboundQtyEuId: String!
    $fromLocationGroupId: String!
    $toLocationGroupId: String!
    $mbBalanceGroupId: String!
    $mbBalanceTypeId: String!
    $mbPeriodStatusId: String!
    $modesOfTransport: String!
    $createdBy: String!
    $transferDate: String!
    $transferQuantity: Float!
    $vesselName: String!
    $siteReferenceId: String!
    $divisionId: String!
  ) {
    bioLcGlobalMassBalanceTransfer(
      event: {
        mbInboundQtyEuId: $mbInboundQtyEuId
        fromLocationGroupId: $fromLocationGroupId
        toLocationGroupId: $toLocationGroupId
        mbBalanceGroupId: $mbBalanceGroupId
        mbBalanceTypeId: $mbBalanceTypeId
        mbPeriodStatusId: $mbPeriodStatusId
        modesOfTransport: $modesOfTransport
        createdBy: $createdBy
        transferDate: $transferDate
        transferQuantity: $transferQuantity
        vesselName: $vesselName
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
      }
    ) {
      message

      statusCode
    }
  }
`;

export const TransferAPI = (data) =>
  client.mutate({
    mutation: TRANSFER,
    variables: {
      ...data,
    },
    refetchQueries: [
      "bioLcGetMassBalanceTransactionData",
      "bioLcGetMassBalanceCounts",
    ],
  });

export const GET_TRANSFER_DETAILS = gql`
  query bioLcGetLocationMotDetails(
    $siteReferenceId: String
    $divisionId: String
    $sendingLocationGroupId: String
  ) {
    bioLcGetLocationMotDetails(
      event: {
        siteReferenceId: $siteReferenceId
        divisionId: $divisionId
        sendingLocationGroupId: $sendingLocationGroupId
      }
    ) {
      data {
        locationCode
        locationGroupId
        locationName
        motDetail
      }
      status
      statusCode
    }
  }
`;

export default {
  TRANSFER,
  TransferAPI,
};
