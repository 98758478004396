/* eslint-disable jsx-a11y/click-events-have-key-events */
import {useMemo, useState, useEffect} from "react";
import {Grid24, Columns24} from "@bphxd/ds-core-react/lib/icons";
import Table from "modules/co-processing/common/Table";
import {Nav, NavItem, NavLink, Button} from "reactstrap";
import {useQuery} from "@apollo/client";
import {
  COPRO_US_BATCHES_DETAILS_API,
  GET_READY_BATCH,
} from "graphql/coprocessing/batches";
import {useBatchType} from "modules/co-processing/context/BatchTypeContext";
import BatchDetailsCardView from "modules/co-processing/components/Batches/BatchDetailsCardView";
import {SplitBatchProvider} from "../AddBatch/Context/SplitBatchContext";
import {useRefetch} from "../../context/RefetchContext";
import {
  batchDetailHeaders,
  TABS,
  OPERATIONS,
  BUTTONS,
} from "../../constants/batches";
import buildColumns from "../../helpers/buildColumns";
import AddBatchModal from "../AddBatch/AddBatchModal";
import AllocateBatchModal from "./AllocateBatchModal";
import "./index.scss";

const BatchDetailsViewMode = () => {
  const {batchType, typeHandler} = useBatchType();
  const {setRefetchBatches} = useRefetch();
  const [isTableView, setTableView] = useState(true);
  const [isReadyBatchState, setReadyBatchState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [readyBatches, setReadyBatches] = useState([]);

  const {refetch: refetchReadyBatches} = useQuery(GET_READY_BATCH, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      const response = result?.bioLcCoproUsApi?.body?.ready_batches;
      setReadyBatches(response ?? []);
      setReadyBatchState(response.length > 0);
    },
  });

  const {data, refetch, loading} = useQuery(COPRO_US_BATCHES_DETAILS_API, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      op: OPERATIONS.GET_BATCHES,
      batch_tab: batchType,
      includeDetails: true,
    },
  });

  const refetchBatches = () => {
    refetchReadyBatches();
    refetch();
  };

  useEffect(() => {
    setRefetchBatches(() => refetch);
  }, [setRefetchBatches, refetch]);

  const batchesData = useMemo(() => {
    const dataArray = data?.bioLcCoproUsBatchesApi?.body?.batches;
    if (!Array.isArray(dataArray)) return [];

    return dataArray;
  }, [data]);

  const openModal = () => {
    setModalOpen(true);
  };

  const dataColumns = buildColumns(batchDetailHeaders);

  return (
    <div className="batches-table-container">
      <div className="batches-tab-row pl-7">
        <Nav tabs>
          <NavItem>
            <NavLink
              data-test="batches-nav-link"
              active={batchType === TABS.ACTIVE.toLowerCase()}
              onClick={() => typeHandler(TABS.ACTIVE.toLowerCase())}
            >
              {TABS.ACTIVE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-test="batches-nav-link"
              active={batchType === TABS.SUBMITTED.toLowerCase()}
              onClick={() => typeHandler(TABS.SUBMITTED.toLowerCase())}
            >
              {TABS.SUBMITTED}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-test="batches-nav-link"
              active={batchType === TABS.WATCHLIST.toLowerCase()}
              onClick={() => typeHandler(TABS.WATCHLIST.toLowerCase())}
            >
              {TABS.WATCHLIST}
            </NavLink>
          </NavItem>
        </Nav>
      </div>
      <div
        data-test="batches-details-toggle-container"
        className="batches-table bg-light p-7"
      >
        <div className="flex justify-end pb-6">
          <div
            className="batch-details-actions-buttons flex gap-3 mr-3"
            data-test="batch-details-actions-buttons"
          >
            <Button color="tertiary" className="bg-white rounded-0" disabled>
              {BUTTONS.SUBMIT_BATCHES_FOR_CREDITS}
            </Button>
            <Button
              color="tertiary"
              className="bg-white rounded-0"
              disabled={!isReadyBatchState}
              onClick={() => {
                openModal();
              }}
            >
              {BUTTONS.ALLOCATE_READY_BATCHES}
            </Button>
            <SplitBatchProvider>
              <AddBatchModal refetchBatches={refetch} />
            </SplitBatchProvider>
          </div>
          <Button
            className="bg-white text-gray-800 border-gray-300 p-2 rounded-0"
            active={isTableView}
            onClick={() => setTableView(true)}
          >
            <Columns24 />
          </Button>
          <Button
            className="bg-white text-gray-800 border-gray-300 p-2 rounded-0"
            active={!isTableView}
            onClick={() => setTableView(false)}
          >
            <Grid24 className="text-gray-800" />
          </Button>
        </div>
        {isTableView ? (
          <Table
            columns={dataColumns}
            data={batchesData}
            enableSorting
            loading={loading}
          />
        ) : (
          <BatchDetailsCardView batches={batchesData} />
        )}
        <AllocateBatchModal
          batches={readyBatches}
          setModal={setModalOpen}
          modal={modalOpen}
          refetch={refetchBatches}
        />
      </div>
    </div>
  );
};

export default BatchDetailsViewMode;
