import moment from "moment";
import orderBy from "lodash/orderBy";
import {useQuery} from "@apollo/client";
import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {GET_TANKS} from "graphql/coprocessing/batches";
import {DATE_FORMAT_YYYYMMDD_SLASH} from "constants/dateformats";
import {ADD_BATCH_FORM} from "modules/co-processing/constants/batches";
import {Form} from "../FormElements";

const fieldKeys = ADD_BATCH_FORM.keys;

const TankDetail = ({tank}) => {
  const lastBatchCreatedDate = moment(tank?.latest_date);

  return (
    <Form.Field label={ADD_BATCH_FORM.labels.latestCreateDate}>
      <div className="flex flex-col items-start">
        {tank && lastBatchCreatedDate.isValid() ? (
          <>
            <span>
              {lastBatchCreatedDate.format(DATE_FORMAT_YYYYMMDD_SLASH)}
            </span>
            <span className="text-secondary text-xs/3">
              {lastBatchCreatedDate.fromNow()}
            </span>
          </>
        ) : (
          <span>N/A</span>
        )}
      </div>
    </Form.Field>
  );
};
TankDetail.propTypes = {tank: PropTypes.object};

const TankNumber = () => {
  const {setValue, control} = useFormContext();
  const tankNumber = useWatch({name: fieldKeys.tankNo, control});

  const {data, loading} = useQuery(GET_TANKS, {fetchPolicy: "network-only"});

  const tankOptions = useMemo(() => {
    return orderBy(
      data?.bioLcCoproUsBatchesApi?.body?.tanks ?? [],
      [(tank) => parseInt(tank?.tank_number?.replace("TK", ""), 10)],
      ["asc"], // Sort in ascending
    ).map((tank) => ({
      value: tank?.tank_number,
      label: tank?.tank_number,
      ...tank,
    }));
  }, [data]);

  const selectedTank = useMemo(
    () => tankOptions.find((tankOption) => tankOption.value === tankNumber),
    [tankOptions, tankNumber],
  );

  useEffect(() => {
    if (selectedTank) {
      setValue(fieldKeys.tankDetailId, selectedTank.tank_detail_id);
      setValue(fieldKeys.batchStartTime, selectedTank.latest_end_date); // Default start date value as latest end date
      setValue(fieldKeys.latestEndDate, selectedTank?.latest_end_date); // Used for reference, not included during form submission.
    }
  }, [selectedTank, setValue]);

  return (
    <>
      <div className="flex space-x-6">
        <Form.Select
          label={ADD_BATCH_FORM.labels.tankNo}
          name={ADD_BATCH_FORM.keys.tankNo}
          options={tankOptions}
          isLoading={loading}
        />
        <TankDetail tank={selectedTank} />
      </div>
      <Form.Toggle
        label={ADD_BATCH_FORM.labels.clearHeel}
        name={ADD_BATCH_FORM.keys.clearHeel}
      />
    </>
  );
};

export default TankNumber;
