import {createContext, useContext, useState, useMemo} from "react";
import PropTypes from "prop-types";

const RefetchContext = createContext({refetch: () => {}});

export const RefetchProvider = ({children}) => {
  const [refetchBatches, setRefetchBatches] = useState(() => () => {});

  const refetchContext = useMemo(
    () => ({
      refetchBatches,
      setRefetchBatches,
    }),
    [refetchBatches, setRefetchBatches],
  );

  return (
    <RefetchContext.Provider value={refetchContext}>
      {children}
    </RefetchContext.Provider>
  );
};

RefetchProvider.propTypes = {
  children: PropTypes.node,
};

export const useRefetch = () => {
  const context = useContext(RefetchContext);

  if (context === undefined) {
    throw new Error("useRefetch must be used inside RefetchProvider");
  }

  return context;
};
