import {Close} from "@bphxd/ds-core-react";
import {Down24, Flag24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import {COUNTRY_SPAIN} from "constants/countryDetails";
import {documentStatus} from "constants/docManager";
import {docStatus} from "content/DocStatus";
import moment from "moment";
import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import HighLightedText from "../../HighLightedText";
import StatusChip from "../../StatusChip";

const getFormattedDecimal = (cell) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (cell.getValue()) {
    return formatNumber(
      parseAndRoundToTwoDecimal(cell.getValue()),
      decimalFormat,
      2,
    );
  }
  return cell.getValue();
};

const RenderLink = (
  id,
  text,
  pageNo,
  cell,
  selectedCountry,
  siteReferenceId,
  divisionId,
) => {
  const {country} = useParams();
  return (
    <Link
      to={{
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${id}`,
        search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
      }}
      className="link-dark"
    >
      <u>
        <HighLightedText
          value={text}
          globalFilter={cell.getContext().table.getState().globalFilter}
        />
      </u>
    </Link>
  );
};

const RenderDropdown = ({
  certificateInboundEuId,
  contractNumber,
  mbBalanceGroupName,
  mbLocationGroupName,
  selectedCountry,
  pageNo,
  state,
  handleMarkAsComplete,
  sdNumber,
  siteReferenceId,
  divisionId,

  divisionCode,
  hideLtpButton,
  previousVersionsCount,
  previousVersions,
}) => {
  const navigate = useNavigate();
  const {country} = useParams();
  const [versionModal, setVersionModal] = useState(false);

  const showLinkToPurchaseOption = useMemo(() => {
    const hideLtpButton =
      process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;
    return !(hideLtpButton === "false" && selectedCountry === COUNTRY_SPAIN);
  }, [selectedCountry]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setVersionModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);
  return (
    <>
      <Modal size="sm" isOpen={versionModal} className="modal-dialog-centered">
        <ModalHeader
          className="p-4"
          style={{
            borderBottom: "solid 1px #80808061",
          }}
          close={
            <Close
              onClick={() => {
                setVersionModal(false);
              }}
            />
          }
        >
          Version history
        </ModalHeader>
        <ModalBody
          style={{
            padding: "0px",
          }}
        >
          {previousVersions?.map((prevItem) => (
            <div
              className="flex p-3.5 border-b border-gray-400 cursor-pointer"
              style={{color: "gray"}}
              key={prevItem.linkedCert}
            >
              <div className="mr-1.5">
                <Flag24 color="grey" />
              </div>
              <div
                className="max-w-[calc(100%-38px)]"
                onClick={() =>
                  navigate({
                    pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                      prevItem.linkedCert,
                    )}`,
                    search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                  })
                }
                // This will be removed in future commits
                onKeyDown={() => {}}
              >
                <p className="text-sm mb-0.5 break-words">
                  {prevItem.sdNumber}
                </p>
                <p className="text-xs mb-0">
                  Rejected {prevItem.bvAuditChangedDatez}
                </p>
              </div>
            </div>
          ))}
        </ModalBody>
      </Modal>
      <UncontrolledDropdown
        direction="down"
        className="doc-manager-action-dropdown"
      >
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0 doc-manager-action-dropdown-button"
        >
          <Kebab24></Kebab24>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu" container="body">
          {hideLtpButton === "false" ? (
            <>
              <DropdownItem
                onClick={() =>
                  navigate({
                    pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                      certificateInboundEuId,
                    )}`,
                    search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                  })
                }
              >
                View document
              </DropdownItem>
              <DropdownItem
                disabled={state !== "ready_to_assign"}
                hidden={showLinkToPurchaseOption}
                onClick={() => {
                  navigate({
                    pathname: `/link-to-purchase/${selectedCountry}/${divisionCode}`,
                    search: `?contractNumber=${encodeURIComponent(
                      contractNumber,
                    )}&balanceGroup=${mbBalanceGroupName}&locationGroup=${mbLocationGroupName}`,
                  });
                }}
              >
                Link to purchase
              </DropdownItem>

              <DropdownItem
                onClick={() => setVersionModal(true)}
                disabled={
                  previousVersionsCount === null || previousVersionsCount === 0
                }
              >
                Version history ({previousVersionsCount ?? 0})
              </DropdownItem>
            </>
          ) : (
            <>
              <DropdownItem
                onClick={() =>
                  navigate({
                    pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                      certificateInboundEuId,
                    )}`,
                    search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                  })
                }
              >
                View document
              </DropdownItem>
              <DropdownItem
                disabled={state !== "ready_to_accept"}
                onClick={() =>
                  navigate({
                    pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${encodeURIComponent(
                      certificateInboundEuId,
                    )}`,
                    search: `?pageNo=${pageNo}&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                  })
                }
              >
                Assign to mass balance
              </DropdownItem>
              <DropdownItem
                disabled={state !== "ready_to_assign"}
                onClick={() => handleMarkAsComplete(sdNumber)}
              >
                Mark as complete
              </DropdownItem>
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

RenderDropdown.propTypes = {
  certificateInboundEuId: PropTypes.string,
  contractNumber: PropTypes.string,
  mbBalanceGroupName: PropTypes.string,
  mbLocationGroupName: PropTypes.string,
  selectedCountry: PropTypes.string,
  pageNo: PropTypes.number,
  state: PropTypes.string,
  handleMarkAsComplete: PropTypes.func,
  sdNumber: PropTypes.string,
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
  hideLtpButton: PropTypes.string,
  divisionCode: PropTypes.string,
  previousVersionsCount: PropTypes.number,
  previousVersions: PropTypes.array,
};

const renderStatus = (text, cell) => {
  const {className: defaultClassName, message: defaultMessage} =
    docStatus.failed_to_process;
  const {className = defaultClassName, message = defaultMessage} =
    docStatus[text] || {};

  return (
    <StatusChip
      className={className}
      message={message}
      globalFilter={cell.getContext().table.getState().globalFilter}
    />
  );
};

const getColumnsSpain = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  handleMarkAsComplete,
  siteReferenceId,
  divisionId,
  divisionCode,
  hideLtpButton,
) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Up24 /> : <Down24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      disabled: true,
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      disabled: true,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      disabled: true,
      visible: true,
      cell: ({cell, row}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      disabled: true,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      disabled: true,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity MT",
      accessorKey: "feedstockQty",
      key: "feedstockQty",
      size: 100,
      disabled: true,
      visible: true,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass Balance",
      accessorKey: "mbLocation",
      key: "mbLocation",
      size: 110,
      visible: true,
      defaultOption: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, state}) => {
        return (
          (state === documentStatus.COMPLETED ||
            state === documentStatus.READY_TO_ASSIGN) &&
          `${mbPeriodName} - ${mbLocationGroupName}`
        );
      },
      cell: ({getValue, row, cell}) => {
        if (hideLtpButton !== "false") {
          const value = getValue();

          if (
            row.original.splitDetails?.length > 0 &&
            (row.original.state === documentStatus.COMPLETED ||
              row.original.state === documentStatus.READY_TO_ASSIGN)
          ) {
            return (
              <p className="text-black">{`${row.original?.mbLocation ?? ""} ${
                row.original.splitDetails?.length
              }/${row.original.splitDetails?.length}`}</p>
            );
          }
          if (
            value != null &&
            row.original.mbCycle !== null &&
            row.subRows?.length === 0 &&
            (row.original.state === documentStatus.COMPLETED ||
              row.original.state === documentStatus.READY_TO_ASSIGN)
          ) {
            const qtr = row.original.mbCycle ?? "      ";
            return (
              <Link
                to={`/copro-spain/spain/mass-balance/${
                  row.original.mbLocationCode
                }/${encodeURIComponent(row.original?.mbLocation)}/${
                  row.original.materialCode
                }/${qtr.substring(4, 6)}/${qtr.substring(0, 4)}`}
                className="link-dark"
              >
                <u>
                  {row.original?.mbLocation}, {qtr.substring(4, 6)}{" "}
                  {qtr.substring(0, 4)}
                </u>
              </Link>
            );
          }
          return "";
        }
        if (
          row.original.state === documentStatus.COMPLETED &&
          row.original.mbLocationGroupName &&
          row.original.mbPeriodName
        )
          return (
            <Link
              to={`/mass-balance/${encodeURIComponent(
                selectedCountry,
              )}/${divisionCode}/CPP/${row.original.mbLocationGroupName}/${
                row.original.mbPeriodName
              }/${row.original.mbBalanceGroupName}`}
              className="link-dark"
              state={row.original.mbPeriodId}
            >
              <u>{cell.getValue()}</u>
            </Link>
          );

        return "";
      },
    },
    {
      header: "Date of physical receipt",
      accessorKey: "physicalReceiptDate",
      key: "physicalReceiptDate",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Invoice number",
      accessorKey: "invoiceNumber",
      key: "invoiceNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Vessel name",
      accessorKey: "vesselName",
      key: "vesselName",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },

    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            certificateInboundEuId,
            state,
            sdNumber,
            contractNumber,
            mbLocationGroupName,
            mbBalanceGroupName,
            versionCount,
            versionHistoryData,
          },
        },
        table,
      }) => (
        <RenderDropdown
          certificateInboundEuId={certificateInboundEuId}
          contractNumber={contractNumber}
          mbBalanceGroupName={mbBalanceGroupName}
          mbLocationGroupName={mbLocationGroupName}
          selectedCountry={selectedCountry}
          pageNo={table.getState()?.pagination?.pageIndex}
          state={state}
          handleMarkAsComplete={handleMarkAsComplete}
          sdNumber={sdNumber}
          siteReferenceId={siteReferenceId}
          divisionId={divisionId}
          hideLtpButton={hideLtpButton}
          divisionCode={divisionCode}
          previousVersionsCount={versionCount}
          previousVersions={versionHistoryData}
        />
      ),
    },
  ];
};

export const getColumnsSpainGMB = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
  handleMarkAsComplete,
  siteReferenceId,
  divisionId,
  divisionCode,
  hideLtpButton,
) => {
  return [
    {
      header: "",
      key: "expandCollapse",
      accessorKey: "splitDetails",
      visible: true,
      enableSorting: false,
      size: 20,
      cell: ({row}) => {
        return row.getCanExpand() ? (
          <Button
            onClick={row.getToggleExpandedHandler()}
            color="standard-quartenary"
            className="!px-0"
          >
            {row.getIsExpanded() ? <Up24 /> : <Down24 />}
          </Button>
        ) : (
          ""
        );
      },
    },
    {
      header: "Document",
      accessorKey: "sdNumber",
      key: "sdNumber",
      cell: ({row, getValue, table, cell}) => {
        return RenderLink(
          encodeURIComponent(row.original.certificateInboundEuId),
          getValue(),
          table.getState()?.pagination?.pageIndex,
          cell,
          selectedCountry,
          siteReferenceId,
          divisionId,
        );
      },
      size: 250,
      maxSize: 400,
      visible: true,
    },
    {
      header: "Raw material",
      accessorKey: "rawMaterialType",
      key: "rawMaterialType",
      size: 110,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity MT",
      accessorKey: "feedstockQtyMT",
      key: "feedstockQtyMT",
      size: 100,
      visible: true,
      dataType: "number",
      accessorFn: ({feedstockQtyUom, feedstockQty}) => {
        return feedstockQtyUom === "MT" ? feedstockQty : "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Supplier",
      accessorKey: "supplierName",
      key: "supplierName",
      size: 150,
      visible: true,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Receiving point",
      accessorKey: "recipientReceiptAddress",
      key: "recipientReceiptAddress",
      size: 200,
      visible: true,
      cell: ({cell, row}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Mass Balance",
      accessorKey: "mbLocation",
      key: "mbLocation",
      size: 110,
      visible: true,
      defaultOption: true,
      accessorFn: ({mbPeriodName, mbLocationGroupName, state}) => {
        return (
          (state === documentStatus.COMPLETED ||
            state === documentStatus.READY_TO_ASSIGN) &&
          `${mbPeriodName} - ${mbLocationGroupName}`
        );
      },
      cell: ({getValue, row, cell}) => {
        if (hideLtpButton !== "false") {
          const value = getValue();

          if (
            row.original.splitDetails?.length > 0 &&
            (row.original.state === documentStatus.COMPLETED ||
              row.original.state === documentStatus.READY_TO_ASSIGN)
          ) {
            return (
              <p className="text-black">{`${row.original?.mbLocation ?? ""} ${
                row.original.splitDetails?.length
              }/${row.original.splitDetails?.length}`}</p>
            );
          }
          if (
            value != null &&
            row.original.mbCycle !== null &&
            row.subRows?.length === 0 &&
            (row.original.state === documentStatus.COMPLETED ||
              row.original.state === documentStatus.READY_TO_ASSIGN)
          ) {
            const qtr = row.original.mbCycle ?? "      ";
            return (
              <Link
                to={`/copro-spain/spain/mass-balance/${
                  row.original.mbLocationCode
                }/${encodeURIComponent(row.original?.mbLocation)}/${
                  row.original.materialCode
                }/${qtr.substring(4, 6)}/${qtr.substring(0, 4)}`}
                className="link-dark"
              >
                <u>
                  {row.original?.mbLocation}, {qtr.substring(4, 6)}{" "}
                  {qtr.substring(0, 4)}
                </u>
              </Link>
            );
          }
          return "";
        }
        if (
          row.original.state === documentStatus.COMPLETED &&
          row.original.mbLocationGroupName &&
          row.original.mbPeriodName &&
          row.original.mbBalanceTypeCode
        )
          return (
            <Link
              to={`/mass-balance/${encodeURIComponent(
                selectedCountry,
              )}/${divisionCode}/${row.original.mbBalanceTypeCode}/${
                row.original.mbLocationGroupName
              }/${row.original.mbPeriodName}/${
                row.original.mbBalanceGroupName
              }`}
              className="link-dark"
              state={row.original.mbPeriodId}
            >
              <u>{cell.getValue()}</u>
            </Link>
          );

        return "";
      },
    },
    {
      header: "Issuance date",
      accessorKey: "dateOfIssuance",
      key: "dateOfIssuance",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Upload date",
      accessorKey: "bvAuditCreatedDatez",
      key: "bvAuditCreatedDatez",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Product",
      accessorKey: "productType",
      key: "productType",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Additional information",
      accessorKey: "additionalInfo",
      key: "additionalInfo",
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Country of origin",
      accessorKey: "rawMaterialOriginCountryName",
      key: "rawMaterialOriginCountryName",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Quantity m³",
      accessorKey: "feedstockQtyM3",
      key: "feedstockQtyM3",
      size: 200,
      visible: false,
      dataType: "number",
      accessorFn: ({feedstockQtyUom, feedstockQty}) => {
        return feedstockQtyUom === "M3" ? feedstockQty : "";
      },
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG savings %",
      accessorKey: "ghgEmissionPercentageIn",
      key: "ghgEmissionPercentageIn",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "GHG total gCO2eq/MJ",
      accessorKey: "ghgTotal",
      key: "ghgTotal",
      size: 110,
      visible: false,
      dataType: "number",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={getFormattedDecimal(cell)}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Scheme",
      accessorKey: "certificationSystem",
      key: "certificationSystem",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch address",
      accessorKey: "supplierDispatchAddress",
      key: "supplierDispatchAddress",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Dispatch date",
      accessorKey: "materialDispatchDatez",
      key: "materialDispatchDatez",
      size: 200,
      visible: false,
      filterFn: "dateRangeFilterFn",
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={
              cell.getValue() && moment(cell.getValue()).format(dateFormat)
            }
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Contract number",
      accessorKey: "contractNumber",
      key: "contractNumber",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    // This will be used later
    // {
    //   header: "Document type",
    //   accessorKey: "documentType",
    //   key: "documentType",
    //   size: 200,
    //   visible: false,
    //   cell: ({cell}) => {
    //     return (
    //       <HighLightedText
    //         value={cell.getValue()}
    //         globalFilter={cell.getContext().table.getState().globalFilter}
    //       />
    //     );
    //   },
    // },
    {
      header: "Reason for rejection",
      accessorKey: "rejectionReason",
      key: "rejectionReason",
      size: 200,
      visible: false,
      cell: ({cell}) => {
        return (
          <HighLightedText
            value={cell.getValue()}
            globalFilter={cell.getContext().table.getState().globalFilter}
          />
        );
      },
    },
    {
      header: "Status",
      key: "state",
      disabled: true,
      accessorKey: "state",
      visible: true,
      draggaingDisabled: true,
      cell: ({getValue, cell}) => renderStatus(getValue(), cell),
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({
        row: {
          original: {
            certificateInboundEuId,
            state,
            sdNumber,
            contractNumber,
            mbLocationGroupName,
            mbBalanceGroupName,
            versionCount,
            versionHistoryData,
          },
        },
        table,
      }) => (
        <RenderDropdown
          certificateInboundEuId={certificateInboundEuId}
          contractNumber={contractNumber}
          mbBalanceGroupName={mbBalanceGroupName}
          mbLocationGroupName={mbLocationGroupName}
          selectedCountry={selectedCountry}
          pageNo={table.getState()?.pagination?.pageIndex}
          state={state}
          handleMarkAsComplete={handleMarkAsComplete}
          sdNumber={sdNumber}
          siteReferenceId={siteReferenceId}
          divisionId={divisionId}
          hideLtpButton={hideLtpButton}
          divisionCode={divisionCode}
          previousVersionsCount={versionCount}
          previousVersions={versionHistoryData}
        />
      ),
    },
  ];
};

export default getColumnsSpain;
