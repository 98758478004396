import {gql} from "@apollo/client";

const UPDATE_DOCUMENT = gql`
  mutation updateDocumentMutation($event: CoproEuUpdateInboundRecordRequest!) {
    bioLcCoproEuUpdateInboundRecord(event: $event) {
      statusCode
      message
    }
  }
`;

export default UPDATE_DOCUMENT;
