import {Input as AInput} from "antd";
import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const Input = React.forwardRef(({className = "", ...props}, ref) => {
  return (
    <AInput
      ref={ref}
      className={["bp-input", className].join(" ")}
      {...props}
    />
  );
});

Input.propTypes = {
  className: PropTypes.string,
};

export default Input;
