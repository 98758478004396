import sumByProperty from "modules/forecasting/helpers/sumByProperty";
import {keyToBarCategory} from "modules/forecasting/constants/inventory";
import getVariancePercentage from "./getVariancePercentage";

const divideByThousand = (value) => Math.round(value / 1000, 10);

const convertToBarrels = (value) => {
  if (value == null || value === undefined) return 0;

  return divideByThousand(value);
};

const firstItem = (list) => list?.[0];
const getYear = ({date = {}} = {}) => date?.year ?? "N/A";

const categoryPairs = new Map(Object.entries(keyToBarCategory));

/**
 * Array of keys for Total categories used in Tables
 * [d4_total, d5_total, d6_total, total_gas, gas_total, diesel_total, total_diesel, total_obligation]
 * @type {Array<string>}
 */
const categoryKeys = [...categoryPairs.keys()];

/**
 * Returns an array of category keys used for the bar IDs.
 *  [monthTotal, gas, diesel] OR [monthTotal, d4, d5(only West credits), d6]
 * @return {Array<string>}.
 */
export const getCategoryKeys = (list = []) => {
  return Object.keys(firstItem(list) || {}).reduce(
    (barIds, category) =>
      categoryPairs.has(category)
        ? [...barIds, categoryPairs.get(category)]
        : barIds,
    [],
  );
};

const buildCategoryObj = ({actualValue, systemValue, variance} = {}) =>
  variance
    ? {
        actualValue: convertToBarrels(actualValue),
        systemValue: convertToBarrels(systemValue),
        variance: convertToBarrels(variance),
        variancePercent: getVariancePercentage(
          variance,
          actualValue,
          systemValue,
        ),
      }
    : {
        actualValue: convertToBarrels(actualValue),
        systemValue: convertToBarrels(systemValue),
      };

export const computeGraphData = (pageData = []) =>
  pageData.map(({date, ...monthD}) => {
    const graphPerMonth = categoryKeys.reduce(
      (memo, key) =>
        monthD[key]
          ? {
              ...memo,
              [keyToBarCategory[key]]: {
                ...buildCategoryObj(monthD[key]),
              },
            }
          : memo,
      {},
    );

    return {
      ...graphPerMonth,
      date,
    };
  });

export const pageDataToGraph = (pageData = []) => {
  if (!pageData?.length) return {};

  return {
    categories: getCategoryKeys(pageData),
    graphValues: computeGraphData(pageData),
  };
};

export const computeCreditsYearTotal = (pageData = []) => {
  if (!pageData?.length) return {};

  const {categories, graphValues} = pageDataToGraph(pageData);

  return {
    ...sumByProperty(graphValues, ...categories),
    date: getYear(firstItem(pageData)),
  };
};

const getTotal = (rins) => {
  return Object.keys(rins).reduce((previous, key) => {
    return previous + rins[key];
  }, 0);
};

export const computeCreditsUnpublishedTotal = (pageData = []) => {
  if (!pageData?.length) return [];

  const {graphValues} = pageDataToGraph(pageData);
  const categories = ["d4", "d5", "d6"];

  const rins = sumByProperty(graphValues, ...categories);
  return {
    ...rins,
    monthTotal: getTotal(rins),
    date: getYear(firstItem(pageData)),
  };
};

export default computeGraphData;
