import PropTypes from "prop-types";
import {useState} from "react";
import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import {COPRO_US_RETURN_DISCARDED} from "graphql/coprocessing/shipments";
import {DISCARD_DROPDOWN_OPTIONS} from "modules/co-processing/constants/shipments";
import ShipmentsPermanentDiscardPopover from "./ShipmentsPermanentDiscardPopover";

const DropdownOption = ({label, onClick}) => (
  <DropdownItem onClick={onClick}>
    <span className="pl-3.5">{label}</span>
  </DropdownItem>
);

DropdownOption.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

const DiscardDropdown = ({shipmentDetails, refetchDiscardedShipments}) => {
  const shipmentID = shipmentDetails?.shipment_id;
  const [showPopover, setShowPopover] = useState(false);

  const [returnDiscardedShipment] = useLazyQuery(COPRO_US_RETURN_DISCARDED, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      setTimeout(() => {
        refetchDiscardedShipments();
      }, 1000);
    },
  });

  const onReturnToActive = () => {
    returnDiscardedShipment({
      variables: {
        shipment_id: shipmentID,
      },
    });
  };

  const onDelete = () => {
    setShowPopover(true);
  };

  const dropdownOptionList = [
    {
      label: DISCARD_DROPDOWN_OPTIONS.RETURN_TO_ACTIVE,
      onClick: () => onReturnToActive(),
    },
    {
      label: DISCARD_DROPDOWN_OPTIONS.PERMANENTLY_DELETE,
      onClick: () => onDelete(),
    },
  ];

  return (
    <div className="flex justify-end">
      <UncontrolledDropdown data-test="kebab-menu" direction="up">
        <DropdownToggle
          data-test="discard-kebab-menu"
          color="standard-quartenary"
          className="!px-0"
        >
          <Kebab24
            id="shipments-discarded-kebab-menu"
            data-test="batches-kebab-menu-icon"
          />
        </DropdownToggle>
        <DropdownMenu container="body">
          {dropdownOptionList.map((option, index) => (
            <DropdownOption
              data-test="discard-dropdown-option"
              key={index}
              icon={option.icon}
              label={option.label}
              onClick={option.onClick}
              id="popover-delete"
            />
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      <ShipmentsPermanentDiscardPopover
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        shipmentID={shipmentID}
        refetchDiscardedShipments={refetchDiscardedShipments}
      />
    </div>
  );
};

DiscardDropdown.propTypes = {
  shipmentDetails: PropTypes.object,
  refetchDiscardedShipments: PropTypes.func,
};

export default DiscardDropdown;
