import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster.js";
import RTable from "../components/Table/index.js";
import {MATERIAL_MASTER_COLUMNS} from "../components/Table/Columns.js";

const MaterialMaster = () => {
  const {data, loading} = useQuery(MATERIAL_MASTER);

  return (
    <RTable
      columns={MATERIAL_MASTER_COLUMNS}
      data={loading ? [] : data?.bioLcCoproConfigCenterMaterialMaster.Records}
      tableName="Material master"
    />
  );
};
export default MaterialMaster;
