import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useEffect} from "react";
import {useUserSettings} from "providers/userSettings";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import CheckboxFlag from "../../BinaryFlag";

const GHGEmissionInfo = ({data}) => {
  const {
    register,
    formState: {errors},
    control,
    setValue,
  } = useFormContext();

  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    setValue(
      "ghgEec",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEec),
    );
    setValue(
      "ghgEl",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEl),
    );
    setValue(
      "ghgEp",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEp),
    );
    setValue(
      "ghgEtd",
      data?.rowData?.outboundRecords?.ghgEtd === "DDV"
        ? data?.rowData?.outboundRecords?.ghgEtd
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEtd),
    );
    setValue(
      "ghgEu",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEu),
    );
    setValue(
      "ghgEsca",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEsca),
    );
    setValue(
      "ghgEccs",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccs),
    );
    setValue(
      "ghgEccr",
      parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgEccr),
    );
    setValue(
      "totalDefaultValueRedApplied",
      data?.rowData?.outboundRecords?.ghgTotal === "TDV",
    );
    setValue(
      "ghgTotal",
      data?.rowData?.outboundRecords?.ghgTotal === "DDV" ||
        data?.rowData?.outboundRecords?.ghgTotal === "TDV"
        ? data?.rowData?.outboundRecords?.ghgTotal
        : parseAndRoundToTwoDecimal(data?.rowData?.outboundRecords?.ghgTotal),
    );
  }, [setValue, data, decimalFormat]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="totalDefaultValueRedApplied" className="fw-light">
          Total default value according to RED II applied
        </Label>
        <CheckboxFlag
          id="totalDefaultValueRedApplied"
          name="totalDefaultValueRedApplied"
          control={control}
          error={errors.totalDefaultValueRedApplied}
        />
      </FormGroup>
      <div className="flex flex-row justify-between items-start gap-3">
        <FormGroup>
          <Label for="ghgEec" className="fw-light">
            Eec
          </Label>
          <Input
            type="text"
            id="ghgEec"
            name="ghgEec"
            data-test="ghgEec"
            {...computeProps("ghgEec")}
            invalid={!!errors.ghgEec}
            disabled
          />

          {errors.ghgEec && (
            <FormFeedback>{errors.ghgEec?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEl" className="fw-light">
            El
          </Label>
          <Input
            type="text"
            id="ghgEl"
            name="ghgEl"
            data-test="ghgEl"
            {...computeProps("ghgEl")}
            invalid={!!errors.ghgEl}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEp" className="fw-light">
            Ep
          </Label>
          <Input
            type="text"
            id="ghgEp"
            name="ghgEp"
            data-test="ghgEp"
            {...computeProps("ghgEp")}
            invalid={!!errors.ghgEp}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEtd" className="fw-light">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtd"
            name="ghgEtd"
            data-test="ghgEtd"
            {...computeProps("ghgEtd")}
            invalid={!!errors.ghgEtd}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEu" className="fw-light">
            Eu
          </Label>
          <Input
            type="text"
            id="ghgEu"
            name="ghgEu"
            data-test="ghgEu"
            {...computeProps("ghgEu")}
            invalid={!!errors.ghgEu}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEsca" className="fw-light">
            Esca
          </Label>
          <Input
            type="text"
            id="ghgEsca"
            name="ghgEsca"
            data-test="ghgEsca"
            {...computeProps("ghgEsca")}
            invalid={!!errors.ghgEsca}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccs" className="fw-light">
            Eccs
          </Label>
          <Input
            type="text"
            id="ghgEccs"
            name="ghgEccs"
            data-test="ghgEccs"
            {...computeProps("ghgEccs")}
            invalid={!!errors.ghgEccs}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccr" className="fw-light">
            Eccr
          </Label>
          <Input
            type="text"
            id="ghgEccr"
            name="ghgEccr"
            data-test="ghgEccr"
            {...computeProps("ghgEccr")}
            invalid={!!errors.ghgEccr}
            disabled
          />
        </FormGroup>
      </div>
      <Label className="text-sm text-gray-600">values reflect: gCO2eq/MJ</Label>
      <FormGroup>
        <Label for="ghgTotal" className="fw-light">
          Total GHG emissions from supply and use of the fuel (gCO2eq/MJ)
        </Label>
        <Input
          type="text"
          id="ghgTotal"
          name="ghgTotal"
          data-test="ghgTotal"
          {...computeProps("ghgTotal")}
          invalid={!!errors.ghgTotal}
          disabled
        />
        {errors.ghgTotal && (
          <FormFeedback>{errors.ghgTotal?.message}</FormFeedback>
        )}
      </FormGroup>
      <div className="flex flex-row justify-start items-start gap-3">
        <FormGroup>
          <Label for="ghgAllocatedHeat" className="fw-light">
            Allocated heat
          </Label>
          <Input
            type="number"
            id="ghgAllocatedHeat"
            name="ghgAllocatedHeat"
            data-test="ghgAllocatedHeat"
            {...computeProps("ghgAllocatedHeat")}
            invalid={!!errors.ghgAllocatedHeat}
          />
          <Label className="text-sm text-gray-600">gCO2eq/MJ heat</Label>
          {errors.ghgAllocatedHeat && (
            <FormFeedback>{errors.ghgAllocatedHeat?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgAllocatedElectricity" className="fw-light">
            Allocated electricity
          </Label>
          <Input
            type="number"
            id="ghgAllocatedElectricity"
            name="ghgAllocatedElectricity"
            data-test="ghgAllocatedElectricity"
            {...computeProps("ghgAllocatedElectricity")}
            invalid={!!errors.ghgAllocatedElectricity}
          />
          <Label className="text-sm text-gray-600">gCO2eq/MJ electricity</Label>
          {errors.ghgAllocatedElectricity && (
            <FormFeedback>
              {errors.ghgAllocatedElectricity?.message}
            </FormFeedback>
          )}
        </FormGroup>
      </div>
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  data: PropTypes.object,
};
export default GHGEmissionInfo;
