import PropTypes from "prop-types";

import {COUNTRY_SPAIN} from "constants/countryDetails";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import {sortBy} from "lodash";
import CheckboxFlag from "modules/common/BinaryFlag";
import {useFormContext} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import UnitOfMeasure from "./UnitOfMeasure";

const GeneralInformation = ({
  countries,
  status,
  rawMaterialTypes = [],
  productTypeItems = [],
  selectedCountry,
  isDisabled,
  divisionCode,
}) => {
  const {
    register,
    formState: {errors},
    control,
    watch,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const productTypeItemsSpain = ["Pure Raw material", "Refined Raw Material"];
  const chainOfCustody = ["Mass Balance", "Physical Segregation"];
  const splitDetails = watch("splitDetails");

  const rawMaterialTypesSorted = sortBy(
    rawMaterialTypes,
    "rawMaterialName",
    "asc",
  );

  const unitOfMeasureKey =
    divisionCode === DIV_CODE_SAF ? "productQtyUom" : "feedstockQtyUom";

  const unitOfMeasureValue =
    watch(unitOfMeasureKey) && watch(unitOfMeasureKey) === "M3" ? "m³" : "MT";

  const quantityKey =
    divisionCode === DIV_CODE_SAF ? "productQty" : "feedstockQty";

  return (
    <div className="flex flex-col gap-5 pb-6">
      <FormGroup>
        <Label for="productType" className="fw-normal">
          Type of product
        </Label>
        <Input
          type="select"
          id="productType"
          data-test="productType"
          {...computeProps("productType", {
            required: "Please enter type of product",
          })}
          invalid={!!errors.productType && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>

          {selectedCountry === COUNTRY_SPAIN
            ? productTypeItemsSpain.map((productTypeItem) => (
                <option key={productTypeItem} value={productTypeItem}>
                  {productTypeItem}
                </option>
              ))
            : productTypeItems.map((productTypeItem, index) => (
                <option key={index} value={productTypeItem.productName}>
                  {productTypeItem.productName}
                </option>
              ))}
        </Input>
        {errors.productType && !isDisabled && (
          <FormFeedback>{errors.productType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialType" className="fw-normal">
          Type of raw material
        </Label>

        <Input
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          {...computeProps("rawMaterialType", {
            required: "Please enter type of raw material",
          })}
          invalid={!!errors.rawMaterialType && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>

          {rawMaterialTypesSorted.length > 0 &&
            rawMaterialTypesSorted?.map((p, i) => (
              <option key={i} value={p.rawMaterialName}>
                {p.rawMaterialName}
              </option>
            ))}
        </Input>

        {errors.rawMaterialType && !isDisabled && (
          <FormFeedback>{errors.rawMaterialType.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfo" className="fw-normal">
          Additional information{" "}
          {divisionCode === DIV_CODE_SAF && "(voluntary)"}
        </Label>
        <Input
          type="textarea"
          rows="3"
          id="additionalInfo"
          data-test="additionalInfo"
          {...computeProps("additionalInfo")}
          invalid={!!errors.additionalInfo && !isDisabled}
          maxLength={250}
          disabled={isDisabled}
        />
      </FormGroup>
      {(!splitDetails || splitDetails?.length === 0) && (
        <FormGroup>
          <Label for="rawMaterialOriginCountryName" className="fw-normal">
            Country of origin (of the origin raw material)
          </Label>
          <Input
            type="select"
            id="rawMaterialOriginCountryName"
            data-test="rawMaterialOriginCountryName"
            {...computeProps("rawMaterialOriginCountryName", {
              required: "Please enter country of origin",
            })}
            invalid={!!errors.rawMaterialOriginCountryName && !isDisabled}
            disabled={isDisabled}
          >
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </Input>
          {errors.rawMaterialOriginCountryName && !isDisabled && (
            <FormFeedback>
              {errors.rawMaterialOriginCountryName.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      <FormGroup>
        <Label for={quantityKey} className="fw-normal">
          Quantity {unitOfMeasureValue}
        </Label>
        <Input
          type="number"
          id={quantityKey}
          data-test={quantityKey}
          {...computeProps(quantityKey, {
            required: "Please enter quantity",
            pattern: {
              value: /^(\d{1,10}(\.\d{1,5})?)$/,
              message:
                "Value must be number with (max 10 digits before and 5 digits after decimal point)",
            },
          })}
          rules={{
            validate: (value) =>
              value > 0 ? true : "Please enter a valid quantity",
          }}
          invalid={!!errors[quantityKey] && !isDisabled}
          disabled={isDisabled}
        />

        {errors[quantityKey] && !isDisabled && (
          <FormFeedback>{errors[quantityKey].message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for={unitOfMeasureKey} className="fw-normal">
          Unit of measure
        </Label>
        <UnitOfMeasure
          id={unitOfMeasureKey}
          name={unitOfMeasureKey}
          control={control}
          error={errors[unitOfMeasureKey]}
          disabled={isDisabled}
          rules={{
            validate: (value) =>
              value === null || value === ""
                ? "Please select unit of measure"
                : true,
          }}
        />
      </FormGroup>
      {selectedCountry !== COUNTRY_SPAIN && (
        <FormGroup>
          <Label for="productEnergyContent" className="fw-normal mb-4">
            Energy content (MJ)
          </Label>
          <Input
            type="number"
            id="productEnergyContent"
            data-test="productEnergyContent"
            {...computeProps("productEnergyContent", {
              pattern: {
                value: /^(\d{1,10}(\.\d{1,5})?)$/,
                message:
                  "Value must be number with (max 10 digits before and 5 digits after decimal point)",
              },
            })}
            rules={{
              validate: (value) =>
                isDisabled || value > 0
                  ? true
                  : "Please enter a valid energy content",
            }}
            invalid={!!errors.productEnergyContent && !isDisabled}
            disabled={isDisabled}
          />

          {errors.productEnergyContent && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.productEnergyContent.message}
            </FormFeedback>
          )}
        </FormGroup>
      )}
      <FormGroup>
        <Label for="isccCompliantFlag" className="fw-normal">
          ISCC compliant material{" "}
          {divisionCode === DIV_CODE_SAF && "(voluntary)"}
        </Label>
        <CheckboxFlag
          id="isccCompliantFlag"
          name="isccCompliantFlag"
          control={control}
          error={errors.isccCompliantFlag}
          rules={{
            validate: (value) =>
              value == null ? "Please select an option" : true,
          }}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="euRedCompliantFlag" className="fw-normal">
          EU RED compliant material
        </Label>
        <CheckboxFlag
          id="euRedCompliantFlag"
          name="euRedCompliantFlag"
          control={control}
          error={errors.euRedCompliantFlag}
          rules={{
            validate: (value) =>
              value == null ? "Please select an option" : true,
          }}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="chainOfCustodyOption" className="fw-normal">
          Chain of custody option (voluntary)
        </Label>
        <Input
          type="select"
          id="chainOfCustodyOption"
          data-test="chainOfCustodyOption"
          {...computeProps("chainOfCustodyOption")}
          invalid={!!errors.chainOfCustodyOption && !isDisabled}
          disabled={isDisabled}
        >
          {chainOfCustody.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Input>
      </FormGroup>
    </div>
  );
};
GeneralInformation.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  selectedCountry: PropTypes.string,
  rawMaterialTypes: PropTypes.arrayOf(PropTypes.object),
  productTypeItems: PropTypes.arrayOf(PropTypes.object),
  isDisabled: PropTypes.bool,
  divisionCode: PropTypes.string,
};
export default GeneralInformation;
