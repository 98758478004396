import {LOGIN_REQUEST, msalInstance} from "providers/Msal";

const getPowerBIAPIToken = async () => {
  // Get account (Assuming there is always one)
  const account = msalInstance.getAllAccounts()?.[0];

  // Get Token with Power BI as audience
  const requestObject = {
    ...LOGIN_REQUEST,
    account,
    scopes: [`https://analysis.windows.net/powerbi/api/Report.Read.All`],
  };
  let accessTokenPowerBIAPI = null;
  try {
    // Get the Stored token or a new one refreshing it)
    const {accessToken} = await msalInstance.acquireTokenSilent(requestObject);
    accessTokenPowerBIAPI = accessToken;
  } catch {
    // If refresh token expired or other reasons, do full login  by popup
    const {accessToken} = await msalInstance.acquireTokenPopup(requestObject);
    accessTokenPowerBIAPI = accessToken;
  }
  return accessTokenPowerBIAPI;
};

export default {
  getPowerBIAPIToken,
};
