import PropTypes from "prop-types";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import {formatNumber} from "providers/userSettings";

const DataGridItem = ({data, metric, label, type}) => {
  const getDisplayedValue = () => {
    if (!data) {
      return "";
    }
    switch (type) {
      case "number":
        return `${formatNumber(data, "Imperial Style")} ${metric}`;
      case "date":
        return displayDate(data, metric);
      default:
        return data;
    }
  };

  return (
    <div
      data-test="data-grid-item"
      className="grid grid-cols-2 place-content-between py-3 text-xs border-b border-gray-100"
    >
      <div className="data-grid-item-value">{getDisplayedValue()}</div>
      <div className="text-secondary text-right">{label}</div>
    </div>
  );
};

DataGridItem.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  metric: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
};

export default DataGridItem;
