import PropTypes from "prop-types";
import tw from "twin.macro";
import {TOOLTIP_LABEL} from "../constants/inventory";

const Data = tw.data`text-white`;
const Label = tw.label`break-normal pr-1`;
const DateTitle = tw.div`break-normal tracking-wide pb-0.5`;
const ProductLabel = tw.div`whitespace-nowrap lg:pb-2 pb-1`;
const getToolTipLabels = (dateType) => {
  if (dateType) {
    return {
      forecastLabel: TOOLTIP_LABEL.PREVIOUS_FORECAST,
      actualsLabel: TOOLTIP_LABEL.CURRENT_FORECAST,
    };
  }
  return {
    forecastLabel: TOOLTIP_LABEL.FORECAST,
    actualsLabel: TOOLTIP_LABEL.ACTUALS,
  };
};
const TooltipItem = ({label, value}) => (
  <div className="flex flex-row justify-between text-center gap-0 lg:gap-1">
    <Label>{label}: </Label>
    <Data data-test={`${label}-value`}>{value}</Data>
  </div>
);
TooltipItem.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
};

const ObligationLabel = {
  gas: "gasoline",
  diesel: "diesel",
  total: "total",
};
const VarianceInfoToolTip = ({cellData = {}}) => {
  const {
    displayDate,
    actualValue,
    systemValue,
    type,
    variance,
    variancePercent = "",
    dateType,
  } = cellData;
  const {forecastLabel, actualsLabel} = getToolTipLabels(dateType);
  const obligationLabel = ObligationLabel?.[type]
    ? ObligationLabel[type]
    : type;
  const percentValue = Number.isFinite(variancePercent)
    ? `${variancePercent}`
    : "N/A";

  return (
    <div
      className="text-white p-2 flex flex-col text-left"
      data-test={`tooltip-content-${type}-${displayDate?.split(" ").at(0)}`}
    >
      <DateTitle>{displayDate}</DateTitle>
      <ProductLabel>
        {obligationLabel?.toUpperCase()} {cellData?.fieldName?.toUpperCase()}
      </ProductLabel>
      <TooltipItem
        label={forecastLabel}
        value={systemValue?.toLocaleString()}
      />
      <TooltipItem
        label={actualsLabel}
        value={(actualValue || 0).toLocaleString()}
      />
      <TooltipItem
        label={TOOLTIP_LABEL.VARIANCE}
        value={variance.toLocaleString()}
      />
      <TooltipItem label={`${TOOLTIP_LABEL.VARIANCE} %`} value={percentValue} />
    </div>
  );
};
VarianceInfoToolTip.propTypes = {
  cellData: PropTypes.object,
};

export default VarianceInfoToolTip;
