import {gql} from "@apollo/client";

export const RECONCILIATION_SUMMARY = gql`
  query GetSummaryLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      complianceMonth
      noOfLocations
      reconciledLocations
      reconciliationPercentage
    }
  }
`;

export const TAXED_OUTBOUND_SUMMARY = gql`
  query GetSummaryMonthLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryMonthLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      variance
      transportVolume
      motVolume
      intransitVolume
      femVolume
      ceemasVolume
      complianceMonth
      check
    }
  }
`;

export const RECONCILIATION_SUMMARY_PLANT_LEVEL = gql`
  query GetSummaryPlantLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryPlantLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      noOfEDGroups
      plant
      plantName
      reconciledEDGroups
      reconciliationPercentage
    }
  }
`;

export const TAXED_OUTBOUND_PLANT_LEVEL_SUMMARY = gql`
  query GetOutboundPlantLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundPlantLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      ceemasVolume
      femVolume
      intransitVolume
      motVolume
      plant
      plantName
      variance
      transportVolume
      check
    }
  }
`;

export const RECONCILIATION_SUMMARY_ED_GROUP_LEVEL = gql`
  query GetSummaryEdGroupLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryEdGroupLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      eDGroup
      eDGroupName
      noOfBalanceGroups
      reconciledBalanceGroups
      reconciliationPercentage
    }
  }
`;

export const TAXED_OUTBOUND_ED_GROUP_LEVEL_SUMMARY = gql`
  query GetOutboundEdGroupLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundEdGroupLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      ceemasVolume
      eDGroup
      eDGroupName
      femVolume
      intransitVolume
      motVolume
      transportVolume
      variance
      check
    }
  }
`;

export const RECONCILIATION_SUMMARY_BALANCE_GROUP_LEVEL = gql`
  query GetSummaryBalanceGroupLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryBalanceGroupLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      balanceGroup
      balanceGroupNo
      noOfMaterials
      reconciledMaterials
      reconciliationPercentage
    }
  }
`;

export const TAXED_OUTBOUND_BALANCE_GROUP_LEVEL_SUMMARY = gql`
  query GetOutboundBalanceGroupLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundBalanceGroupLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      balanceGroup
      balanceGroupName
      ceemasVolume
      femVolume
      intransitVolume
      motVolume
      transportVolume
      variance
      check
    }
  }
`;

export const RECONCILIATION_SUMMARY_MATERIAL_LEVEL = gql`
  query GetSummaryMaterialLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getSummaryMaterialLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      material
      materialCode
      noOfDays
      reconciledDays
      reconciliationPercentage
    }
  }
`;

export const TAXED_OUTBOUND_MATERIAL_LEVEL_SUMMARY = gql`
  query GetOutboundMaterialLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundMaterialLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      ceemasVolume
      femVolume
      intransitVolume
      material
      materialName
      transportVolume
      motVolume
      variance
      check
    }
  }
`;

export const TAXED_OUTBOUND_DAY_LEVEL_SUMMARY = gql`
  query GetOutboundDateLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundDateLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      ceemasVolume
      check
      dynamicDate
      femVolume
      intransitVolume
      motVolume
      transportVolume
      variance
    }
  }
`;

export const TAXED_OUTBOUND_TRANSACTION_LEVEL_SUMMARY = gql`
  query GetOutboundLevelRequest(
    $company: String!
    $plant: String!
    $compliancemonth: String!
    $complianceyear: String!
    $ed: String!
    $legalentity: String!
    $material: String!
    $date: String!
    $balancegroup: String!
    $motProfile: String!
  ) {
    getOutboundLevelRequest(
      company: $company
      plant: $plant
      compliancemonth: $compliancemonth
      complianceyear: $complianceyear
      ed: $ed
      legalentity: $legalentity
      material: $material
      dynamicdate: $date
      balancegroup: $balancegroup
      motProfile: $motProfile
    ) {
      ceemasVolume
      check
      disDate
      disMatDoc
      femVolume
      intransitVolume
      loadDate
      loadMatDoc
      motVolume
      outboundDelivery
      transportVolume
      variance
    }
  }
`;

export const SUMMARY_FILTER_OPTIONS = gql`
  query GetFilteredOptionsRequest(
    $year: String!
    $plant: String!
    $month: String!
    $material: String!
    $eDGroup: String!
    $companyCode: String!
    $balanceGroupNumber: String!
    $legalEntity: String!
    $motProfile: String!
  ) {
    getFilteredOptionsRequest(
      year: $year
      plant: $plant
      month: $month
      material: $material
      eDGroup: $eDGroup
      companyCode: $companyCode
      balanceGroupNumber: $balanceGroupNumber
      legalEntity: $legalEntity
      motProfile: $motProfile
    ) {
      year
      month
      material
      eDGroup
      edGroupName
      companyCode
      balanceGroupNumber
      plant
      balanceGroupName
      materialDesc
      plantName
      legalEntity
    }
  }
`;

export default {
  RECONCILIATION_SUMMARY,
  RECONCILIATION_SUMMARY_PLANT_LEVEL,
  TAXED_OUTBOUND_SUMMARY,
};
