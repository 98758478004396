import {gql} from "@apollo/client";

export const MASS_BALANCE_MATERIALS_GET_QUERY = gql`
  query bioLcGetMbBgMaterialData {
    bioLcGetMbBgMaterialData(event: {}) {
      mbBgMaterialData {
        exclude
        materialMasterId
        mbBgId
        mbBgMaterialId
        sapMaterialId
        sapProductHierarchyPrefix
        validFrom
        validTo
      }
    }
  }
`;

export default {MASS_BALANCE_MATERIALS_GET_QUERY};
