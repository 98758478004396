import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import {isEqual} from "lodash";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {Fragment, useCallback, useState, useEffect} from "react";
import {Table} from "reactstrap";
import {useLocation} from "react-router-dom";
import {OUTGOING_COLUMN_IDS} from "./Column";
import "./index.css";
import MoreDetails from "../MoreDetails/MoreDetails";
import RouteDetail from "../RouteDetails/RouteDetail";

const SplitTable = ({
  data,
  costCalcObjectData,
  columns,
  loading,
  pagination,
  onPaginationChange,
  uom,
}) => {
  const [selectedRow, setSelectedRow] = useState("");
  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    getSubRows: (row) => row.original?.outgoingData ?? [],
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, pagination);
      onPaginationChange(newValue);
    },
    initialState: {
      pagination: {
        pageIndex: pagination?.pageIndex,
      },
    },
    autoResetPageIndex: false,
  });

  const location = useLocation();
  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  const [isHighlight, setIsHighlight] = useState(true);

  useEffect(() => {
    if (data && data.length === 0) return () => {};
    const removeHighlightTimeout = setTimeout(
      () => setIsHighlight(false),
      3000,
    );
    return () => {
      clearTimeout(removeHighlightTimeout);
      location.state = null;
    };
  }, [table, data, setIsHighlight, location]);

  const buildRows = useCallback(
    (row, flexRenderItem) => {
      const isCellHighlighted = (value) => {
        if (!value) return false;
        return (
          isEqual(value?.documentNumber, costCalcObjectData?.documentNumber) &&
          isEqual(
            value?.documentItemNumber,
            costCalcObjectData?.documentItemNumber,
          )
        );
      };
      const outgoingData = row.original?.outgoingData ?? [{}];
      const rowSpan = outgoingData?.length;
      return outgoingData.map((record, index) => {
        const rowKey = `${row.id}_${index}`;
        return (
          <tr key={rowKey}>
            {row.getVisibleCells().map((cell) => {
              if (OUTGOING_COLUMN_IDS.includes(cell.column.id)) {
                const {row: rowItem, ...context} = cell.getContext();
                return (
                  <td
                    key={cell.id}
                    style={{
                      verticalAlign: "middle",
                      padding: "10px 15px",
                      backgroundColor:
                        isCellHighlighted(
                          row.original?.moreDetailsData?.slice(-1)[0],
                        ) && isHighlight
                          ? "#e6fff2"
                          : "",
                    }}
                    className={`${
                      selectedRow === rowKey ? "bg-success-subtle" : ""
                    } ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                  >
                    {flexRenderItem(cell.column.columnDef.cell, {
                      ...context,
                      row: {
                        ...rowItem,
                        original: {
                          ...rowItem.original,
                          outgoingData: record,
                          rowSpan,
                        },
                        highlight: () => {
                          setSelectedRow(rowKey);
                        },
                      },
                    })}
                  </td>
                );
              }
              if (!OUTGOING_COLUMN_IDS.includes(cell.column.id)) {
                return (
                  <td
                    key={cell.id}
                    className={`${index > 0 ? "d-none" : ""} ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                    rowSpan={rowSpan}
                    style={{
                      verticalAlign: "middle",
                      padding: "10px 15px",
                      backgroundColor:
                        isCellHighlighted(
                          row.original?.moreDetailsData?.slice(-1)[0],
                        ) && isHighlight
                          ? "#e6fff2"
                          : "",
                    }}
                  >
                    {flexRenderItem(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </td>
                );
              }
              return null;
            })}
          </tr>
        );
      });
    },
    [selectedRow, isHighlight, costCalcObjectData], //
  );
  return (
    <>
      <Table className="bp-core table feedstock-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup, groupIndex) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header?.subHeaders?.length}
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "400",
                    }}
                    className={
                      header.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <SpinnerLoading />
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              {buildRows(row, flexRender)}

              {row.getIsExpanded() && (
                <tr className="">
                  <td
                    colSpan="14"
                    className="expanded more-details-section px-7 py-9"
                  >
                    <div className="flex flex-row w-full">
                      <div className="flex w-1/2">
                        <MoreDetails data={row?.original ?? null} uom={uom} />
                      </div>
                      {row?.original?.type !== "Rebrand" &&
                        row?.original?.type !== "Gain" &&
                        row?.original?.type !== "Loss" && (
                          <div className="flex w-1/2 align-middle text-center">
                            <RouteDetail data={row?.original ?? null} />
                          </div>
                        )}
                    </div>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <div className="pb-[152px] w-full">
        <ReactTablePaginationRow {...paginationProps} />
      </div>
    </>
  );
};
SplitTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  costCalcObjectData: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  onPaginationChange: PropTypes.func,
  pagination: PropTypes.object,
  uom: PropTypes.string,
};
export default SplitTable;
