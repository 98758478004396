import PropTypes from "prop-types";
import {Table} from "antd";

const SummaryTable = ({tableHeading, ...tableProps}) => {
  return (
    <div className="w-full div-summary-table">
      <div className="col-span-2">
        {tableHeading ? (
          <div className="flex flex-col justify-between px-5 py-4 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row">
            <p className="font-bold">{tableHeading}</p>
          </div>
        ) : null}
        <Table pagination={false} scroll={{x: "max-content"}} {...tableProps} />
      </div>
    </div>
  );
};

SummaryTable.propTypes = {
  tableHeading: PropTypes.string.isRequired,
};

export default SummaryTable;
