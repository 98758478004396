import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {
  Alert24,
  Check24,
  Info24,
  Remove24,
  Share24,
} from "@bphxd/ds-core-react/lib/icons";

const STATUSES = {
  attention: {
    icon: <Alert24 className="text-danger" />,
    text: "Requires your attention",
  },
  additional_info: {
    icon: <Info24 className="text-primary" />,
    text: "Add additional information",
  },
  ready_for_mb: {
    icon: <Share24 />,
    text: "Assign to mass balance",
  },
  rejected: {
    icon: <Remove24 className="text-danger" />,
    text: "Document rejected",
  },
  completed: {
    icon: <Check24 className="text-success" />,
    text: "Complete",
  },
};
const IconText = ({status, link}) => {
  const statusMapped = status != null ? STATUSES[status] : null;
  if (statusMapped == null) {
    return null;
  }
  const {icon, text} = statusMapped;
  return (
    <div className="flex mr-4">
      {icon}
      <Link to={link} className="link-dark">
        <u>{text}</u>
      </Link>
    </div>
  );
};

IconText.propTypes = {
  status: PropTypes.string,
  link: PropTypes.string.isRequired,
};

export default IconText;
