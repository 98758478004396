import {gql} from "@apollo/client";

// Fetch query for location master new  table on Config center(/configuration-center/locations)
export const LOCATION_DETAILS_GET_QUERY = gql`
  query bioLcGetLocationDetails {
    bioLcGetLocationDetails {
      locationDetail {
        sapLocationName
        sapLocationId
        sapLocationCode
        locationType
        locationShortName
        locationLongName
        locationId
        locationCode
        divisionName
        divisionId
        divisionCode
        countryName
        countryId
        countryCode
      }
    }
  }
`;

export default {LOCATION_DETAILS_GET_QUERY};
