import {setLocale} from "yup";

// -----------------------------------------------------------
// NOTE: If TYPE_ERROR is different from REQUIRED_ERROR we will face
// an scenario were different errors are shown despite field is empty
// Example 1: submit a pristine number field
// Example 2: Type in a number field, remove chars and submit.
//
// If you find out this is affecting your needs,
// please, raise it with the team.
// -----------------------------------------------------------

const REQUIRED_ERROR = ({label}) => `${label} is required`;
const TYPE_ERROR = REQUIRED_ERROR;
const MAX_ERROR = ({label, max}) => `${label} must be less than ${max}`;
const MIN_ERROR = ({label, min}) => `${label} must be greater than ${min}`;

setLocale({
  mixed: {
    required: REQUIRED_ERROR,
    max: MAX_ERROR,
    min: MIN_ERROR,
    notType: TYPE_ERROR,
  },
  string: {
    required: REQUIRED_ERROR,
    max: MAX_ERROR,
    min: MIN_ERROR,
    notType: TYPE_ERROR,
  },
  number: {
    required: REQUIRED_ERROR,
    max: MAX_ERROR,
    min: MIN_ERROR,
    notType: TYPE_ERROR,
  },
  boolean: {
    required: REQUIRED_ERROR,
    notType: TYPE_ERROR,
  },
});
