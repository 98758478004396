import tw from "twin.macro";

export const StyledHeading1 = tw.h1`font-bold text-5xl`;

export const StyledHeading2 = tw.h2`font-bold text-4xl`;

export const StyledHeading3 = tw.h3`font-bold text-3xl`;

export const StyledHeading4 = tw.h4`font-bold text-2xl`;

export const StyledHeading5 = tw.h5`font-bold text-xl`;

export const StyledHeading6 = tw.h6`text-xl`;
