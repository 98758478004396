import {Input} from "reactstrap";
import {useEffect, useState} from "react";
import {useFormContext, Controller} from "react-hook-form";
import PropTypes from "prop-types";
import FormField from "modules/common/FormFieldNew.js";
import DatePicker from "modules/common/DatePickerNew";
import dateFunctions from "utils/helpers/dateFunctions";
import {useUserSettings} from "providers/userSettings";
import {
  certificationSystemsDataCommon,
  certificationSystemsDataTepsa,
} from "./assigntoSaleMapping";

const CertificationDetails = ({data, rowData}) => {
  const {
    formState: {errors},
    watch,
  } = useFormContext();

  const {userSettings = {dateFormat: "m/d/Y"}} = useUserSettings();
  const dateFormat = dateFunctions.convertDateFormattoUser(
    userSettings.dateFormat,
  );

  const certificationSystemsData =
    rowData?.inboundRecord?.mbLocationCode === 1
      ? certificationSystemsDataTepsa
      : certificationSystemsDataCommon;

  const watchCertificationSystem = watch("certificationSystem");

  // states
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const certSystemSelected = certificationSystemsData?.find(
      (objCertSystem) => objCertSystem.id === watchCertificationSystem,
    );
    setTemplates(certSystemSelected?.templateTypes);
  }, [watchCertificationSystem, certificationSystemsData]);

  return (
    <div className="grid sm:grid-cols-1 text-left md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 gap-y-4 ">
      <FormField
        label="Certification System"
        errorMessage={errors.certificationSystem?.message}
      >
        <Controller
          name="certificationSystem"
          defaultValue={data?.certificationSystem || undefined}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="certificationSystem"
              data-test="certificationSystem"
              invalid={!!errors.certificationSystem}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {certificationSystemsData?.map((cerSystem) => (
                <option key={cerSystem.id} value={cerSystem.id}>
                  {cerSystem.label}
                </option>
              ))}
            </Input>
          )}
        />
      </FormField>
      <FormField label="Template" errorMessage={errors.templateType?.message}>
        <Controller
          name="templateType"
          defaultValue={data?.templateType}
          render={({field}) => (
            <Input
              {...field}
              type="select"
              id="templateType"
              data-test="templateType"
              invalid={!!errors.templateType}
            >
              <option key="none" value="">
                --Select an option--
              </option>
              {templates?.map((template) => {
                return (
                  <option key={template.id} value={template.id}>
                    {template.label}
                  </option>
                );
              })}
            </Input>
          )}
        />
      </FormField>

      <FormField
        label="Date of issuance"
        errorMessage={errors.issueDate?.message}
      >
        <Controller
          name="issueDate"
          render={({field: {ref, onChange, value, disabled}}) => (
            <DatePicker
              data-test="issueDate"
              placeholder="Date of issuance"
              id="issueDate"
              name="issueDate"
              invalid={!!errors.issueDate}
              options={{
                allowInput: true,
                mode: "single",
                defaultDate: value,
                dateFormat,
                altFormat: dateFormat,
                onChange: (selectedDates) => onChange(selectedDates[0]),
              }}
            />
          )}
        />
      </FormField>
    </div>
  );
};

CertificationDetails.propTypes = {
  data: PropTypes.object,
  rowData: PropTypes.object,
};

export default CertificationDetails;
