import {LEGEND, MEASUREMENTS} from "../constants/inventory";

const GasAndDieselLegend = () => (
  <div
    id="forecast-inventory-legend"
    data-test="inventory-legend"
    className="flex col-span-1 items-center"
  >
    <div
      id="forecast-inventory-legend-gas-label"
      className="inventory-legend-label py-2"
    >
      {LEGEND.GAS} {MEASUREMENTS.KILOBARRELS}
    </div>
    <div
      id="forecast-inventory-legend-gas-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-gas"
    ></div>
    <div
      id="forecast-inventory-legend-diesel-label"
      className="inventory-legend-label pl-5 py-2"
    >
      {LEGEND.DIESEL} {MEASUREMENTS.KILOBARRELS}
    </div>
    <div
      id="forecast-inventory-legend-diesel-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-diesel"
    ></div>
    <div
      id="forecast-inventory-legend-total-label"
      className="inventory-legend-label pl-5 py-2"
    >
      {LEGEND.TOTAL} {MEASUREMENTS.KILOBARRELS}
    </div>
    <div
      id="forecast-inventory-legend-total-icon"
      data-test="inventory-legend-color"
      className="inventory-legend-element legend-total"
    ></div>
  </div>
);

export default GasAndDieselLegend;
