const categories = ["gas", "diesel", "d4", "d5", "d6"];

const keyToLabel = {
  renewable_diesel: "RENEWABLE",
  alaska_exports_diesel: "ALASKA EXPORTS",
  marine_shipments_diesel: "MARINE FUEL",
  other_volumes_excluded_gas: "VOLUMES EXCLUDED",
  other_volumes_excluded_diesel: "VOLUMES EXCLUDED",
};

const findCategoryByKey = (productKey = "") =>
  productKey.split("_").find((v) => categories.includes(v));

export default ({key, measurement}) => {
  const subCategoryLabel = keyToLabel?.[key] ? keyToLabel[key] : measurement;

  return {type: findCategoryByKey(key), fieldName: subCategoryLabel};
};
