import {useFormContext, Controller} from "react-hook-form";
import moment from "moment";
import PropTypes from "prop-types";
import {GET_FIELD_OPTIONS} from "graphql/gy-enablement/MOT/manualAdjustment";
import {useQuery} from "@apollo/client";
import {useUserSettings, formatNumber} from "providers/userSettings";
import DatePicker from "modules/common/DatePicker";
import FormField from "modules/common/FormField";
import InputNumeric from "modules/common/lnputNumeric";
import Select from "modules/common/Select";

const {Option} = Select;

const NETSCO2CostsForm = ({data}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const {
    formState: {errors},
  } = useFormContext();

  const {data: optionsDataPayload} = useQuery(GET_FIELD_OPTIONS, {
    fetchPolicy: "network-only",
  });
  const optionsData = optionsDataPayload?.getFieldOptionsAurora?.data;
  const companyCodeOptions = optionsData?.companyCode?.values || [];

  return (
    <div
      className="masterNETSCO2Costs-form"
      data-test="masterNETSCO2Costs-form"
    >
      <FormField
        label="company code"
        errorMessage={errors.companyCode?.message}
      >
        <Controller
          name="companyCode"
          defaultValue={data?.companyCode || undefined}
          render={({field}) => (
            <Select
              {...field}
              placeholder="Company code"
              data-test="masterNETSCO2Costs-form-input-company-code"
              disabled={data?.companyCode}
            >
              {companyCodeOptions.map((value) => (
                <Select.Option value={value}>{value}</Select.Option>
              ))}
            </Select>
          )}
        />
      </FormField>

      <FormField
        label="Compliance Year"
        errorMessage={errors.complianceYear?.message}
      >
        <Controller
          name="complianceYear"
          defaultValue={
            data?.complianceYear ? moment([data.complianceYear]) : ""
          }
          render={({field}) => (
            <DatePicker
              {...field}
              placeholder="Compliance Year"
              picker="year"
              format="YYYY"
              data-test="masterNETSCO2Costs-form-input-complianceYear"
              disabled={data?.complianceYear}
            />
          )}
        />
      </FormField>

      <FormField
        label="Certificate Costs"
        errorMessage={errors.certificateCosts?.message}
      >
        <Controller
          name="certificateCosts"
          defaultValue={formatNumber(data?.certificateCosts, decimalFormat)}
          render={({field}) => (
            <InputNumeric
              {...field}
              placeholder="Certificate Costs"
              decimals={2}
              max={100000000}
              min={0}
              data-test="masterNETSCO2Costs-form-input-certificateCosts"
              decimalFormat={decimalFormat}
            />
          )}
        />
      </FormField>
    </div>
  );
};

NETSCO2CostsForm.propTypes = {
  data: PropTypes.object,
};

export default NETSCO2CostsForm;
