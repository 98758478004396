import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {DndContext} from "@dnd-kit/core";
import {restrictToVerticalAxis} from "@dnd-kit/modifiers";
import PropTypes from "prop-types";
import {useState} from "react";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import DropdownCustomizeItem from "./DropdownCustomizeItem";
import Droppable from "./Droppable";

const CustomizeTable = ({columns, onColumnChange, showApplyButton = true}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleDragEnd = (event) => {
    const {active, over} = event;
    if (!active || !over) {
      return;
    }
    if (active.id === over.id) {
      return;
    }
    const reorderedColumns = [...columns];
    const activeIndex = reorderedColumns.findIndex(
      (c) => c.header === active.id,
    );
    const activeElement = reorderedColumns.splice(activeIndex, 1);
    const overIndex = reorderedColumns.findIndex((c) => c.header === over.id);
    reorderedColumns.splice(overIndex, 0, ...activeElement);
    onColumnChange(reorderedColumns);
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        color="standard-tertiary"
        className="rounded-0 ms-0 customize-col-button"
        caret
      >
        Customise columns
        <Down24 className="btn-icon-suffix" />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-scroll w-80">
        <DndContext
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          {columns.map(
            (column) =>
              column.header !== "" &&
              !column.draggaingDisabled && (
                <Droppable key={column.header} id={column.header}>
                  <DropdownCustomizeItem column={column} />
                </Droppable>
              ),
          )}
        </DndContext>
        {columns.map(
          (column) =>
            column.header !== "" &&
            column.draggaingDisabled && (
              <DropdownCustomizeItem column={column} />
            ),
        )}
        {showApplyButton && (
          <>
            <DropdownItem divider />
            <div className="text-center">
              <Button
                onClick={toggle}
                size="lg"
                color="standard-primary"
                className="rounded-0 ms-3 primary-btn"
              >
                Apply changes
              </Button>
            </div>
          </>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomizeTable.propTypes = {
  columns: PropTypes.array,
  onColumnChange: PropTypes.func,
  showApplyButton: PropTypes.bool,
};

export default CustomizeTable;
