import tw from "twin.macro";
import {useLocation} from "react-router-dom";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import DataView from "modules/co-processing/components/Shipments/DataView";
import BulkAdjustmentDashboard from "modules/co-processing/components/Shipments/BulkAdjustment/BulkAdjustmentDashboard";

const BulkAdjustment = () => {
  const {state} = useLocation();
  const batches = state?.batches;
  const remainingVolume = state?.remaining_volume;

  return (
    <PageLayout>
      <HeaderSection data-test="bulk-adjustment-header">
        <PageTitle>{PAGE_TITLE.SHIPMENTS}</PageTitle>
      </HeaderSection>

      <HorizontalLine />
      <div className="-ml-6">
        <DataView />
      </div>
      <HorizontalLine />

      <div
        data-test="adjustment-section"
        className="flex flex-col space-y-7 mt-10"
      >
        <div className="flex justify-between">
          <div className="text-[26px] pr-3 font-light">Bulk adjustment</div>
        </div>
        <BulkAdjustmentDashboard
          selectedBatches={batches}
          volume={remainingVolume}
        />
      </div>
    </PageLayout>
  );
};

export default BulkAdjustment;

const HeaderSection = tw.div`relative flex flex-col`;
const HorizontalLine = tw.hr`h-px my-8 bg-gray-700 border-0 dark:bg-gray-800`;
const PageTitle = tw.h1`text-[1.8125rem]`;
const PageLayout = tw.div`overflow-x-auto px-10 pt-2 flex flex-col space-y-6`;
