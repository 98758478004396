import {DOWNLOAD} from "graphql/config-center/Download.js";
import {useState} from "react";
import {client} from "providers/Apollo";
import {Button, PopoverHeader, PopoverBody, Popover} from "reactstrap";
import {toast} from "react-toastify";
import "./index.scss";

const DownloadButton = () => {
  const [isDownloading, setDownloading] = useState(false);
  const [showPopover, setShowPopover] = useState(false);

  const downloadFile = (fileUrl) => {
    const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    const a = document.createElement("a");
    a.href = fileUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const onDownload = async () => {
    const {
      data: {bioLcCoproConfigCenterDownload: response},
    } = await client.query({
      query: DOWNLOAD,
      fetchPolicy: "network-only",
    });
    return response;
  };

  const handleDownload = async () => {
    setDownloading(true);
    try {
      const response = await onDownload();
      switch (response?.statusCode) {
        case 400:
        case 500:
          // Show friendly server error message
          setDownloading(false);

          toast.error(response?.errorMessage);
          break;
        default:
          // Theorically succesfully
          setDownloading(false);
          if (response) {
            if (response?.url) {
              downloadFile(response?.url);
            } else {
              toast.error("No data available to export");
            }
          }
      }
    } catch (err) {
      console.error(err);
      // Unexpected graphql error
      setDownloading(false);
    }
  };

  return (
    <>
      <Button
        color="primary"
        type="secondary"
        className="float-right rounded-none"
        id="RequestRevisionButton"
        size="lg"
        onClick={() => setShowPopover(!showPopover)}
      >
        Request revision
      </Button>

      <Popover
        popperClassName="request-revision-popover"
        innerClassName="request-revision-popover-inner"
        placement="bottom"
        hideArrow
        target="RequestRevisionButton"
        isOpen={showPopover}
      >
        <PopoverHeader className="pt-3.5 text-center font-semibold">
          Request revision
        </PopoverHeader>
        <PopoverBody>
          <div className="text-slate-300  text-center border-b px-3 pb-3">
            To request a revision, download the attached document, define your
            amend and send to gbioversedataenablement@bp.com
            <span>
              <br />
              <br />
            </span>
            You will receive a confirmation of revisions within 48 hours.
          </div>
          <div className="flex justify-between p-3">
            <Button
              className="border-0 w-full "
              color="standard-tertiary"
              onClick={handleDownload}
              disabled={isDownloading}
            >
              Continue
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default DownloadButton;
