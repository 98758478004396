import PropTypes from "prop-types";
import {useDraggable} from "@dnd-kit/core";
import {DropdownItem, Label} from "reactstrap";
import {DragHandleBars24} from "@bphxd/ds-core-react/lib/icons";
import {Row} from "antd";
import "./index.css";

const DropdownCustomizeItem = ({column}) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: column.header,
  });
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;
  return (
    <div ref={setNodeRef} style={style} className="customize-table-item">
      <DropdownItem toggle={false}>
        <Row className="gap-2 flex-nowrap w-100" align="middle" {...listeners}>
          {!column.draggaingDisabled && (
            <DragHandleBars24 {...attributes} {...listeners} />
          )}
          {column.draggaingDisabled && <span className="w-6"></span>}
          <div className="form-check-reverse flex-grow-1 !mb-[10px]">
            <Label check>{column.header}</Label>
          </div>
        </Row>
      </DropdownItem>
    </div>
  );
};

DropdownCustomizeItem.propTypes = {
  column: PropTypes.object,
};

export default DropdownCustomizeItem;
