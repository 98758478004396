import PropTypes from "prop-types";
import React from "react";

import HighLightedText from "../HighLightedText";

const StatusChip = ({className, message, globalFilter}) => {
  return (
    <div className="flex items-center">
      <span
        className={`${className} flex items-center whitespace-nowrap h-[27px] px-[10px] py-1 rounded-[3px] uppercase text-[10px] leading-[19px] tracking-[1px] text-[#111]`}
      >
        <HighLightedText value={message} globalFilter={globalFilter} />
      </span>
    </div>
  );
};

StatusChip.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  globalFilter: PropTypes.string,
};

export default StatusChip;
