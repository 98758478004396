import {useEffect, useMemo} from "react";
import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";
import Title from "modules/common/Title";
import {useAppSetting} from "providers/appSetting";
import {HEADER_FORECASTING} from "modules/forecasting/constants/header";
import {LABEL_FORECASTING} from "modules/forecasting/constants/inventory";
import ForecastTabs from "modules/forecasting/components/ForecastTabs";
import ForecastingFilterBar from "modules/forecasting/components/ForecastingFilter";
import ForecastingBreadcrumbs from "modules/forecasting/components/ForecastingBreadcrumbs";
import COUNTRY_CODES, {COUNTRY_NAMES} from "constants/appSettings";

export default () => {
  const {appSetting, setAppSetting} = useAppSetting();

  const countryFeatureData = useMemo(
    () =>
      appSetting?.featureMappingData?.find(
        (item) =>
          item.countryName?.toLowerCase() ===
          COUNTRY_NAMES.UNITED_STATES?.toLowerCase(),
      ),
    [appSetting.featureMappingData],
  );

  useEffect(() => {
    if (appSetting.currentCountry !== COUNTRY_CODES.UNITED_STATES) {
      setAppSetting((prevAppSetting) => ({
        ...prevAppSetting,
        currentCountry: COUNTRY_CODES.UNITED_STATES,
        currentCountryMappingData: countryFeatureData,
      }));
    }
  }, [countryFeatureData, appSetting.currentCountry, setAppSetting]);

  return (
    <div data-test="forecast-root-layout" id="forecasting-container">
      <Helmet>
        <title>{HEADER_FORECASTING}</title>
      </Helmet>
      <ForecastingBreadcrumbs />
      <Title title={LABEL_FORECASTING} />
      <ForecastingFilterBar />
      <ForecastTabs />
      <Outlet />
    </div>
  );
};
