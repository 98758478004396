import {Link, useParams} from "react-router-dom";
import {OUTGOING_DOC_STATUS} from "modules/SAF-dashboard/constants/documents";
import {saf_urls} from "modules/SAF-dashboard/constants/common";
import ActionDropdown from "../ActionDropdown";

const baseUrl = "/saf-sweden/mass-balance";

export const INCOMING_COLUMNS = (
  incomingDocs,
  selectedIncomingDoc,
  setSelectedIncomingDoc,
  setSelectedIncomingDocId,
) => {
  return [
    {
      header: "Incoming",
      columns: [
        {
          header: "Certificate ID",
          accessorKey: "incoming_sd_number",
          visible: true,
          cell: () => {
            return (
              <select
                value={selectedIncomingDoc}
                onChange={(e) => {
                  setSelectedIncomingDoc(e.target.value);
                  const certificate_id = incomingDocs.filter(
                    (item) => item.sd_number === e.target.value,
                  );
                  setSelectedIncomingDocId(
                    certificate_id[0].certificate_inbound_eu_id,
                  );
                }}
              >
                {incomingDocs?.map((doc) => (
                  <option key={doc.sd_number} value={doc.sd_number}>
                    {doc.sd_number}
                  </option>
                ))}
              </select>
            );
          },
        },
      ],
    },
  ];
};

const _renderStatus = (status) => {
  switch (status) {
    case OUTGOING_DOC_STATUS.GENERATED:
      return <div className="flex flex-row gap-[5px]">Generated</div>;
    case OUTGOING_DOC_STATUS.AVAILABLE:
      return <div className="flex flex-row gap-[5px]">Available</div>;
    case OUTGOING_DOC_STATUS.SENT:
      return <div className="flex flex-row gap-[5px]">Sent</div>;
    case OUTGOING_DOC_STATUS.ASSIGNED:
      return <div className="flex flex-row gap-[5px]">Assigned</div>;
    default:
      return status;
  }
};

export const OUTGOING_COLUMNS = () => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Certificate ID",
        accessorKey: "certificate_number",
        cell: ({row}) => {
          const docStatus = row?.original.outgoingRecords?.status;
          const certificate_number =
            row?.original?.outgoingRecords?.certificate_number;
          const certificate_outbound_eu_id =
            row?.original?.outgoingRecords?.certificate_outbound_eu_id;
          const {country} = useParams();
          return (
            <Link
              to={{
                pathname: `${
                  saf_urls[`${country}MassBalance`]
                }/${certificate_outbound_eu_id}`,
                search: "?redirect=MassBalance",
              }}
              className="link-dark"
            >
              <u>
                {docStatus === OUTGOING_DOC_STATUS.ASSIGNED && "Draft"}
                {(docStatus === OUTGOING_DOC_STATUS.SENT ||
                  docStatus === OUTGOING_DOC_STATUS.GENERATED) &&
                  certificate_number}
              </u>
            </Link>
          );
        },
      },
      {
        header: "Status",
        cell: ({row}) => _renderStatus(row?.original.outgoingRecords?.status),
      },
      {
        header: "",
        accessorKey: "actions",
        size: 50,
        cell: ({row, table}) => {
          return <ActionDropdown row={row} table={table} />;
        },
      },
    ],
  },
];

// Format doesn't matter here, since we are only interested in accessor keys
export const OUTGOING_COLUMN_IDS = OUTGOING_COLUMNS()[0].columns.map(
  (col) => col.accessorKey,
);
export const INCOMING_COLUMN_IDS = INCOMING_COLUMNS()[0].columns.map(
  (col) => col.accessorKey,
);
