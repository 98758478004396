export const multiLevelBreadcrumbItems = (filterParamDetails) => {
  const breadcrumbitems = [];
  const paramDetails = JSON.stringify({
    company: filterParamDetails?.company,
    complianceyear: filterParamDetails?.complianceyear,
    compliancemonth: filterParamDetails?.compliancemonth,
    ed: filterParamDetails?.ed,
    legalentity: filterParamDetails.legalentity,
    material: filterParamDetails?.material,
    plant: filterParamDetails?.plant,
    balancegroup: filterParamDetails?.balancegroup,
    motProfile: filterParamDetails?.motProfile,
  });

  if (
    filterParamDetails?.company !== "" &&
    filterParamDetails?.complianceyear !== ""
  ) {
    breadcrumbitems.push({
      linkname: "Month Level",
      link: `/gy-enablement/ceemas-mot-validation/${encodeURIComponent(
        paramDetails,
      )}`,
    });
    if (filterParamDetails?.compliancemonth !== "") {
      breadcrumbitems.push({
        linkname: "Plant Level",
        link: `/gy-enablement/ceemas-mot-validation-plant-level/${encodeURIComponent(
          paramDetails,
        )}`,
      });
      if (filterParamDetails?.plant !== "") {
        breadcrumbitems.push({
          linkname: "ED Group Level",
          link: `/gy-enablement/ceemas-mot-validation-edgroup-level/${encodeURIComponent(
            paramDetails,
          )}`,
        });
        if (filterParamDetails?.ed !== "") {
          breadcrumbitems.push({
            linkname: "Balance Group Level",
            link: `/gy-enablement/ceemas-mot-validation-balancegroup-level/${encodeURIComponent(
              paramDetails,
            )}`,
          });
          if (filterParamDetails?.balancegroup !== "") {
            breadcrumbitems.push({
              linkname: "Material Level",
              link: `/gy-enablement/ceemas-mot-validation-material-level/${encodeURIComponent(
                paramDetails,
              )}`,
            });
            if (filterParamDetails?.material !== "") {
              breadcrumbitems.push({
                linkname: "Day Level",
                link: `/gy-enablement/ceemas-mot-validation-day-level/${encodeURIComponent(
                  paramDetails,
                )}`,
              });
            }
          }
        }
      }
    }
  }

  return breadcrumbitems;
};

export default {multiLevelBreadcrumbItems};
