import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {LOCATION_DETAILS_COLUMNS} from "../components/Table/Columns.js";

const LocationDetails = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => LOCATION_DETAILS_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(LOCATION_DETAILS);

  return (
    <RTable
      columns={columns}
      data={loading ? [] : data?.bioLcCoproConfigCenterLocationDetails.Records}
      tableName="Location details"
    />
  );
};
export default LocationDetails;
