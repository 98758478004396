import {Fragment, useMemo} from "react";
import PropTypes from "prop-types";
import {useFormContext, useWatch} from "react-hook-form";
import {
  ADD_BATCH_FORM,
  STEPS,
  TITLES,
  OP_CONDITIONS,
  COLD_RECIRC_OPEN_NOTIFICATION,
} from "modules/co-processing/constants/batches";
import parseFieldValue from "../parseFieldValue";
import extractBatchIdDetails from "../extractBatchIdDetails";

const OG_BATCH_ID = "og_batch_id";
const formKeys = ADD_BATCH_FORM.keys;
const formLabels = ADD_BATCH_FORM.labels;

const invertedFormKeys = Object.entries(formKeys).reduce(
  (acc, [labelKey, formKey]) => {
    acc[formKey] = labelKey;
    return acc;
  },
  {},
);

const stepFieldsMapping = {
  [STEPS.SECOND]: [formKeys.tankNo, formKeys.clearHeel],
  [STEPS.THIRD]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.splitBatch,
  ],
  [`${STEPS.SAVE}_default`]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.batchStartTime,
    formKeys.batchEndTime,
    formKeys.splitBatch,
    formKeys.batchId,
    formKeys.tankCertifiedDate,
  ],
  [`${STEPS.SAVE}_split`]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    formKeys.splitBatch,
    OG_BATCH_ID,
    formKeys.batchId,
    formKeys.tankCertifiedDate,
  ],
  [`${STEPS.SAVE}_bypassed`]: [
    formKeys.tankNo,
    formKeys.clearHeel,
    formKeys.opCondition,
    OG_BATCH_ID,
    formKeys.batchId,
    formKeys.tankCertifiedDate,
  ],
};

const computeLabelAndValue = (formKey, formValues) => {
  let label = formLabels[invertedFormKeys[formKey] || formKey] || formKey;
  let value = formValues[formKey] ?? null;

  if (formKey === formKeys.batchId && formValues.split_batch) {
    value = formValues?.batch_id;
    label = `Split ${extractBatchIdDetails(value).suffix} batch ID`;
  } else if (formKey === OG_BATCH_ID) {
    label = formLabels.ogBatchId;
    value = formValues.batch_id?.replace(/(?:_[A-Z]|_bypass)/g, ""); //  uppercase letter for splits '_A' OR '_bypass'
  }

  return {label, value: parseFieldValue(formKey, value)};
};

const getStepKey = (formStep, formValues) => {
  const stepKeyMap = {
    split: formValues.split_batch,
    bypassed: formValues.operating_condition === OP_CONDITIONS.BY_PASSED,
    default: true, // Fallback if no other conditions met
  };

  const keySuffix =
    Object.keys(stepKeyMap)?.find((key) => stepKeyMap[key]) || "default";

  return formStep === STEPS.SAVE ? `${formStep}_${keySuffix}` : formStep;
};

const getSummaryFieldsByStep = (formStep, watchedValues) => {
  const fields = stepFieldsMapping[formStep] || [];

  return fields.reduce((summaryInfo, formKey) => {
    const {label, value} = computeLabelAndValue(formKey, watchedValues);

    if (value !== null && value !== "") {
      summaryInfo.push({key: formKey, label, value});
    }
    return summaryInfo;
  }, []);
};

const FormSummary = ({formStep}) => {
  const {getValues} = useFormContext();
  const isValveOpen = useWatch({name: "valveOpen"});

  const formStepKey = useMemo(() => {
    const formValues = getValues();
    return getStepKey(formStep, formValues);
  }, [formStep, getValues]);

  const formData = useWatch();
  const summaryFields = getSummaryFieldsByStep(formStepKey, formData);

  return summaryFields?.length ? (
    <div className="addBatch__summary" data-test="batch-summary">
      <div className="addBatch__summary-title">{TITLES.SUMMARY}</div>
      <div className="addBatch__summary-list">
        {summaryFields.map(({key, label, value}) => (
          <Fragment key={key}>
            <div className="flex justify-between">
              <label className="text-gray-700">{label}</label>
              <span>{value}</span>
            </div>
            {key === "operating_condition" && isValveOpen && (
              <div className="addBatch__summary-notification">
                {COLD_RECIRC_OPEN_NOTIFICATION}
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </div>
  ) : null;
};

FormSummary.propTypes = {formStep: PropTypes.number.isRequired};

export default FormSummary;
