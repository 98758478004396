import PropTypes from "prop-types";

import {useNavigate} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";

const Section = ({
  link,
  img,
  title,
  description,
  module,
  redirectToExternalLink,
  redirectToExternalLinkURL,
}) => {
  const navigate = useNavigate();
  const {appSetting, setAppSetting} = useAppSetting();
  const handleSectionNavigation = (linkItem, moduleName, titleName) => {
    setAppSetting({
      ...appSetting,
      currentModule: moduleName,
      currentModuleTitle: titleName,
    });
    navigate(linkItem);
  };
  return (
    <div data-test={`document-${title}`} className="bg-[#fff]">
      {redirectToExternalLink ? (
        <a
          target="_blank"
          rel="noreferrer"
          href={redirectToExternalLinkURL}
          data-test="external-link"
          className="flex flex-col items-center justify-center h-48 px-4 py-5 text-center border border-gray-200 rounded-md hover:no-underline hover:border-transparent hover:bg-bp-green-50"
        >
          <h3
            data-test="title"
            className="mt-3 text-lg font-bold text-center text-indigo-500"
          >
            {title}
          </h3>
          <p data-test="description" className="hidden mt-1 text-gray-600">
            {description}
          </p>
        </a>
      ) : (
        <div
          data-test="link"
          onClick={() => handleSectionNavigation(link, module, title)}
          onKeyDown={() => handleSectionNavigation(link, module, title)}
          className="flex flex-col items-center justify-center h-48 px-4 py-5 text-left border border-gray-200 rounded-md hover:no-underline hover:border-transparent hover:bg-bp-green-50"
        >
          <h3
            data-test="title"
            className="mt-3 text-lg font-bold text-center text-indigo-500"
          >
            {title}
          </h3>
          <p data-test="description" className="hidden mt-1 text-gray-600">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

Section.propTypes = {
  link: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  redirectToExternalLink: PropTypes.bool,
  redirectToExternalLinkURL: PropTypes.string,
  module: PropTypes.string.isRequired,
};

export default Section;
