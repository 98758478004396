import React, {useMemo} from "react";
import {useQuery} from "@apollo/client";
import {LOCATION_YIELD} from "graphql/config-center/LocationYield.js";
import {useUserSettings} from "providers/userSettings/context.js";
import RTable from "../components/Table/index.js";
import {LOCATION_YIELD_COLUMNS} from "../components/Table/Columns.js";

const LocationYield = () => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const columns = useMemo(
    () => LOCATION_YIELD_COLUMNS(dateFormat),
    [dateFormat],
  );
  const {data, loading} = useQuery(LOCATION_YIELD);

  return (
    <RTable
      columns={columns}
      data={
        loading ? [] : data?.bioLcCoproConfigCenterLocationYieldDetails.Records
      }
      tableName="Location yield details"
    />
  );
};
export default LocationYield;
