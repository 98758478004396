import {formatNumber, useUserSettings} from "providers/userSettings";

export default function parseAndRoundToTwoDecimal(value) {
  const num = parseFloat(value ?? "0");
  return parseFloat((Math.round(num * 100) / 100).toFixed(2));
}

export function parseAndRoundToThreeDecimal(value) {
  const num = parseFloat(value ?? "0");
  const formattedNumber = parseFloat(num.toFixed(3));
  return formattedNumber;
}

export function formatNumberIfExists(
  value,
  decimalFormatNew,
  decimalPoints = 2,
) {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  if (value && decimalPoints === 3) {
    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  if (value && decimalPoints === 2) {
    return formatNumber(parseAndRoundToTwoDecimal(value), decimalFormat, 2);
  }
  return value;
}
