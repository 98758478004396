import {PI_Vision_URL} from "modules/co-processing/constants/coProcessing";

const PIData = () => (
  <div className="h-[521px]">
    <iframe
      className="w-full"
      src={PI_Vision_URL}
      title="PIVisionSRC"
      width="100%"
      height="100%"
    />
  </div>
);

export default PIData;
