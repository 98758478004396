import {Spinner} from "reactstrap";

const SpinnerLoading = () => {
  return (
    <div data-test="task-manager-loading" className="my-8 flex items-baseline ">
      <span className="text-md">Loading</span>
      <div>
        <Spinner
          style={{width: ".25rem", height: ".25rem", marginLeft: ".25rem"}}
          type="grow"
        />{" "}
        <Spinner style={{width: ".25rem", height: ".25rem"}} type="grow" />{" "}
        <Spinner style={{width: ".25rem", height: ".25rem"}} type="grow" />
      </div>
    </div>
  );
};

export default SpinnerLoading;
