import {Close} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {
  Button,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

const ClosingStockTotalAdd = ({
  setShowModalAdd,
  title,
  closeBalance,
  mbPeriodId,
  showModalAdd,
  physicalStock,
  loading,
}) => {
  const methods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });

  const {
    register,
    formState: {errors},
    getValues,
    setValue,
    watch,
  } = methods;

  const watchPhysicalStock = watch("physicalStock");

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    if (physicalStock) {
      setValue("physicalStock", physicalStock);
    }
  }, [physicalStock, setValue]);

  const handleAdd = () => {
    closeBalance({
      variables: {
        physicalStockM3: getValues("physicalStock"),
        mbPeriodId,
      },
    });
  };

  return (
    <Modal size="sm" isOpen={showModalAdd} className="modal-dialog-centered">
      <ModalHeader
        className="!border-b-[1px] !border-gray-200 mb-2 text-xl"
        close={
          <Close
            onClick={() => setShowModalAdd((showModalAdd) => !showModalAdd)}
          />
        }
      >
        {title}
      </ModalHeader>

      <ModalBody className={`flex flex-col items-center w-72 !pt-[17px] `}>
        <p className="text-[13px] font-light">
          In order to close the current mass balance, we require the stock
          inventory quantity. Please enter it below:
        </p>
        <div className="w-full">
          <FormProvider {...methods}>
            <Form>
              <Label for="physicalStock" className="">
                Closing stock total m³
              </Label>
              <Input
                id="physicalStock"
                type="number"
                {...computeProps("physicalStock", {
                  required: "Please enter closing stock total m³",
                })}
                invalid={!!errors.physicalStock}
                placeholder="Enter stock quantity"
              />
              {errors.physicalStock && (
                <FormFeedback>{errors.physicalStock.message}</FormFeedback>
              )}
            </Form>
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
              onClick={() => setShowModalAdd((showModalAdd) => !showModalAdd)}
            >
              Cancel
            </Button>
          </div>
          <div className="col-6 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={handleAdd}
              disabled={
                loading || !!errors.physicalStock || !watchPhysicalStock
              }
            >
              {loading && <Spinner size="sm" className="btn-icon-prefix" />}
              {physicalStock ? "Confirm" : "Add"}
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ClosingStockTotalAdd.propTypes = {
  setShowModalAdd: PropTypes.func.isRequired,
  closeBalance: PropTypes.func.isRequired,
  title: PropTypes.string,
  mbPeriodId: PropTypes.string,
  showModalAdd: PropTypes.bool,
  physicalStock: PropTypes.number,
  loading: PropTypes.bool,
};

export default ClosingStockTotalAdd;
