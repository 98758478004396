/* eslint-disable no-param-reassign */
import flow from "lodash/fp/flow";
import get from "lodash/fp/get";
import omit from "lodash/fp/omit";
import {
  CREDITS_CATEGORIES,
  DEFICITS_CATEGORIES,
} from "modules/forecasting/constants/inventory";
import {
  isAfterCurrMonth,
  isBeforeCurrentMonth,
  showPublishedVariance,
} from "modules/forecasting/helpers/dateUtils";
import {isCreditType} from "modules/forecasting/helpers/forecastFilterUtils";
import {
  filterByKey,
  getProductKey,
  getUPTotalValues,
} from "modules/forecasting/helpers/tableUtils";

const categoriesByType = {
  credits: CREDITS_CATEGORIES,
  deficits: DEFICITS_CATEGORIES,
};

const dropTypeNameProp = omit("__typename");
const getObligationItems = get("obligationItems");
const getObligationValues = flow(get("obligationValues"), dropTypeNameProp);

export const obligationValuesByType = (itemType) =>
  flow(
    getObligationItems,
    (items) => items?.find((item = {}) => item?.type === itemType) ?? {},
    getObligationValues,
    dropTypeNameProp,
  );

// ==================== Data Page Calculations ====================

const actualForMonth = obligationValuesByType("actual");
const forecastedForMonth = obligationValuesByType("forecasted");
const previousForecast = obligationValuesByType("previousForecast");

const getTotalValues = (
  actual,
  forecasted,
  totalType,
  monthData,
  showVariance,
) => {
  return {
    actualValue: (monthData?.[totalType]?.actualValue || 0) + actual,
    systemValue: (monthData?.[totalType]?.systemValue || 0) + forecasted,
    ...(showVariance && {
      variance: (monthData?.[totalType]?.variance || 0) + (actual - forecasted),
    }),
  };
};

/**
 * Returns the month data containing actualValue, systemValue, and variance of each product
 * and the total_obligation, total_gas and total_diesel values.
 *
 * actualValue is the actual value if available, or the system forecasted value if not.
 *
 * systemValue is the system forecasted value.
 *
 * @param {boolean} showVariance - A boolean indicating whether is month should display variance.
 * @param {Object} actuals - The system actuals object.
 * @param {Object} forecasted - The system forecasted object.
 * @returns {Object} The month data object.
 */

export const getMonthData = ({
  showVariance = true,
  actuals = {},
  forecasted = {},
  forecastType = "deficits",
}) => {
  const monthData = {};
  const categoryKeys = categoriesByType?.[forecastType];

  Object.entries(actuals).forEach(([key, actual]) => {
    const actualValue = Math.round(actual);
    const systemValue = forecasted?.[key];
    const variance = actualValue - systemValue || 0;
    const productKey = getProductKey(key);

    monthData[productKey] = {
      actualValue,
      systemValue,
      ...(variance && showVariance && {variance}),
    };

    monthData.total_obligation = getTotalValues(
      actualValue,
      systemValue,
      "total_obligation",
      monthData,
      showVariance,
    );

    categoryKeys?.forEach((catKey) => {
      if (productKey.includes(catKey)) {
        // Table field keys Credits:`d4_total`,`d5_total` `d6_total` || Deficits:`total_gas` & `total_diesel`
        const categoryKey =
          forecastType === "credits" ? `${catKey}_total` : `total_${catKey}`;

        monthData[categoryKey] = getTotalValues(
          actualValue,
          systemValue,
          categoryKey,
          monthData,
          showVariance,
        );
      }
    });
  });

  return monthData;
};

/**
 * Data Page Credits & Deficits displays:
 * values from type: "actual" for Past Months
 * values from type: "forecasted" for Current & Future months.
 */
export const currMonthWithForecasted = ({date = {}, obligationItems = []}) =>
  isBeforeCurrentMonth(date)
    ? actualForMonth({obligationItems})
    : forecastedForMonth({obligationItems});

export const currMonthWithPreviousForecast = ({
  date = {},
  obligationItems = [],
}) =>
  isBeforeCurrentMonth(date)
    ? forecastedForMonth({obligationItems})
    : previousForecast({obligationItems});

// West Data page displays credit type deltas for past & current month.
const showVarianceWestDP = (dateObj, forecastType) =>
  forecastType === "credits" ? isBeforeCurrentMonth(dateObj) : true;

export const transformWestCoastData = (
  responseData = [],
  forecastType = "deficits",
) =>
  responseData?.map((apiItem) => {
    return {
      ...getMonthData({
        actuals: currMonthWithForecasted(apiItem),
        forecasted: isCreditType(forecastType)
          ? forecastedForMonth(apiItem)
          : currMonthWithPreviousForecast(apiItem),
        showVariance: showVarianceWestDP(apiItem?.date, forecastType),
        forecastType,
      }),
      date: apiItem?.date,
    };
  });

export const transformEastCoastData = (
  responseData = [],
  forecastType = "deficits",
) =>
  responseData?.map((apiItem) => ({
    ...getMonthData({
      actuals: currMonthWithForecasted(apiItem),
      forecasted: forecastedForMonth(apiItem),
      forecastType,
    }),
    date: apiItem?.date,
  }));

// ==================== Unpublished Page Calculations ====================

/**
 * Returns the month data containing actualValue, systemValue, and variance of each product
 * and the total_obligation, total_gas and total_diesel values.
 *
 * actualValue is the user saved forecast value if the month is after the current month,
 * otherwise it is the user saved actual value.
 *
 * systemValue is the system actuals value if the month is before the current month,
 * otherwise it is the system forecasted value.
 *
 * @param {boolean} beforeCurrMonth - A boolean indicating whether is before or after the current month.
 * @param {Date} date - The specified month date.
 * @param {Object} actuals - The system actuals object.
 * @param {Object} forecasted - The system forecasted object.
 * @param {Object} savedActuals - The user saved actuals object.
 * @param {Object} savedForecast - The user saved forecast object.
 * @returns {Object} The month data object.
 */
const getMonthUPData = (
  beforeCurrMonth,
  date,
  actuals = {},
  forecasted = {},
  savedActuals = {},
  savedForecast = {},
) => {
  const monthData = {};

  const isAfter = isAfterCurrMonth(date);

  Object.entries(omit("__typename", actuals?.obligationValues)).forEach(
    ([key]) => {
      const actualValue = isAfter
        ? savedForecast?.obligationValues[key]
        : savedActuals?.obligationValues[key];
      const systemValue = Math.round(
        beforeCurrMonth
          ? actuals?.obligationValues[key]
          : forecasted?.obligationValues[key],
      );

      const productKey = getProductKey(key);

      monthData[productKey] = {
        actualValue,
        systemValue,
        variance: systemValue - actualValue,
      };

      monthData.total_obligation = getUPTotalValues(
        actualValue,
        systemValue,
        "total_obligation",
        monthData,
      );

      if (productKey.includes("gas")) {
        monthData.total_gas = getUPTotalValues(
          actualValue,
          systemValue,
          "total_gas",
          monthData,
        );
      }

      if (productKey.includes("diesel")) {
        monthData.total_diesel = getUPTotalValues(
          actualValue,
          systemValue,
          "total_diesel",
          monthData,
        );
      }
    },
  );

  return monthData;
};

/**
 * Transforms a list of obligation data for the East Coast region and returns a new array
 * with unpublished page data for each month.
 *
 * @param {Array} obligationsList - The list of obligation data to transform.
 * @returns {Array} A new array of objects with unpublished page data for each month.
 */
const transformEastCoastUPData = (obligationsList = []) => {
  return obligationsList?.map(({date = {}, obligationItems = []}) => {
    const actuals = filterByKey("actual", obligationItems)[0];
    const forecasted = filterByKey("forecasted", obligationItems)[0];
    const savedActuals = filterByKey("savedActuals", obligationItems)[0];
    const savedForecast = filterByKey("savedForecast", obligationItems)[0];

    const monthData = getMonthUPData(
      isBeforeCurrentMonth(date),
      date,
      actuals,
      forecasted,
      savedActuals,
      savedForecast,
    );

    return {
      date,
      ...monthData,
    };
  });
};

const computeEastCoastUPData = ({responseData}) =>
  flow([transformEastCoastUPData])(responseData);

/**
 * Calculates and returns an array of unpublished page data for the East Coast region.
 *
 * @param {Object} data - The list of object containing monthly measurements.
 * @param {number} monthIndex - The index of the first column to display.
 * @param {number} selectedYear - The selected year on the dropdown.
 * @returns {Array} An array of objects with unpublished page data for each month.
 */
export const getEastCoastUPPageData = (data, selectedYear) => {
  const hasValidData = data?.bioLcForecastDataApi?.body?.length > 0 ?? false;

  if (!hasValidData || !selectedYear) {
    return [];
  }

  const pageData = computeEastCoastUPData({
    responseData: data.bioLcForecastDataApi.body,
  });

  return pageData;
};

// ==================== Published Page Calculations ====================

const publishedActualsForMonth = obligationValuesByType("publishedActuals");
const publishedForecastForMonth = obligationValuesByType("publishedForecast");

export const currMonthPublishedActuals = ({date = {}, obligationItems = []}) =>
  isAfterCurrMonth(date)
    ? publishedForecastForMonth({obligationItems})
    : publishedActualsForMonth({obligationItems});

const shouldDisplayVariance = ({date} = {}) =>
  date?.month ? showPublishedVariance(`${date?.year}-${date?.month}`) : false;

export const transformEastCoastPPData = (
  obligationsList = [],
  forecastType = "deficits",
) =>
  obligationsList?.map((apiItem) => ({
    ...getMonthData({
      actuals: currMonthPublishedActuals(apiItem),
      forecasted: publishedForecastForMonth(apiItem),
      showVariance: shouldDisplayVariance(apiItem),
      forecastType,
    }),
    date: apiItem?.date,
  }));
