import React, {useEffect} from "react";
import {useFormContext, useWatch} from "react-hook-form";
import {dtnShipmentKeys} from "modules/co-processing/constants/shipments";
import ShipmentForm from "../Form";
import {DefaultsByShipmentType} from "../formConfigs";

const ShipmentType = () => {
  const {reset} = useFormContext();
  const shipmentType = useWatch({name: dtnShipmentKeys.SHIPMENT_TYPE});

  useEffect(() => {
    const defaultValues = DefaultsByShipmentType?.[shipmentType];

    reset({
      ...defaultValues,
      [dtnShipmentKeys.SHIPMENT_TYPE]: shipmentType,
    });
  }, [reset, shipmentType]);

  return (
    <div className="row g-4 mb-5" data-test={dtnShipmentKeys.SHIPMENT_TYPE}>
      <h5>Select the shipment type</h5>
      <ShipmentForm.Field fieldName="SHIPMENT_TYPE" />
    </div>
  );
};

export default ShipmentType;
