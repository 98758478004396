import {gql} from "@apollo/client";

export const TRANSPORTATION_EMISSIONS = gql`
  query bioLcCoproConfigCenterTransportationEmissions {
    bioLcCoproConfigCenterTransportationEmission {
      Records {
        transportation_emission_id
        sending_location_id
        sending_location_code
        sending_location_name
        receiving_location_id
        receiving_location_code
        receiving_location_name
        mot_name
        emission_factor
        emission_uom
        valid_from
        valid_to
      }
    }
  }
`;

export default {TRANSPORTATION_EMISSIONS};
