import {useState, useCallback} from "react";

/**
 * Custom hook to control the state of a modal.
 * @param {string} modalId - The unique identifier of the modal.
 * @returns {Object} An object containing:
 * - isModalOpen: A boolean indicating whether the modal is open or not.
 * - toggleModal: Function to toggle the state of the modal.
 * - closeModal: Function to close the modal.
 */

const useModal = (modalId) => {
  const [modals, setModals] = useState({});

  const closeModal = useCallback(() => {
    setModals((prevModals) => {
      if (!prevModals[modalId]) {
        return prevModals; // Return previous state directly if modal already closed
      }

      return {
        ...prevModals,
        [modalId]: false,
      };
    });
  }, [modalId]);

  const toggleModal = useCallback(() => {
    setModals((prevModals) => ({
      ...prevModals,
      [modalId]: !prevModals[modalId], // Directly toggle the boolean value for this modal ID
    }));
  }, [modalId]);

  const isModalOpen = !!modals[modalId];

  return {isModalOpen, toggleModal, closeModal};
};

export default useModal;
