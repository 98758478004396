import PropTypes from "prop-types";
import {Card, CardBody, CardTitle, CardText} from "reactstrap";
import {BATCH_DETAILS_STATUS} from "modules/co-processing/constants/batches";
import {CircleCheck48, SpinnerCircle48} from "@bphxd/ds-core-react/lib/icons";

const BatchStatusCard = ({title, status}) => (
  <div data-test="batch-status-card" className="w-full pr-[19px]">
    <Card className="shadow rounded-6">
      <CardBody className="pb-4">
        <CardTitle className="text-[13px]">{title}</CardTitle>
        <div
          data-test="batch-card-status-icon"
          className="grid grid-col justify-items-center"
        >
          {status === BATCH_DETAILS_STATUS.COMPLETE ? (
            <CircleCheck48
              data-test="check-mark-icon"
              className="text-success"
            />
          ) : (
            <SpinnerCircle48 data-test="spinner-icon" />
          )}
          <CardText>{status}</CardText>
        </div>
      </CardBody>
    </Card>
  </div>
);

BatchStatusCard.propTypes = {
  title: PropTypes.string,
  status: PropTypes.string,
};

export default BatchStatusCard;
