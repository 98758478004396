import {useState} from "react";
import {useUserSettings, formatNumber} from "providers/userSettings";
import {roundDecimal} from "utils/helpers/uihelper";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {Bullet24, Down24, Kebab24, Up24} from "@bphxd/ds-core-react/lib/icons";
import "./index.scss";
import ExpandableTable from "./ExpandableTable";

const onClickKebab = (
  onMore,
  record,
  scenarioType,
  country,
  onManualAdjustment,
) => {
  return (
    <UncontrolledDropdown>
      <div
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <DropdownToggle color="standard-quartenary" className="!px-0" caret>
          <Kebab24 />
        </DropdownToggle>
      </div>
      <DropdownMenu>
        {scenarioType === "Deficit" && country === "USA" && (
          <DropdownItem
            key="manual-adjustment"
            onClick={(e) => {
              e.stopPropagation();
              onManualAdjustment(record.row.original);
            }}
          >
            Add manual adjustment
          </DropdownItem>
        )}
        <DropdownItem
          key="detailed-view"
          onClick={(e) => {
            e.stopPropagation();
            onMore({
              scenarioNumber: record.row.original.scenarioNumber,
              scenarioDescription: record.row.original.scenarioDescription,
            });
          }}
        >
          View detailed transaction
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

const ComplianceScenarioAccordion = ({
  scenarioList,
  columns,
  scenarioType,
  onMore,
  onManualAdjustment,
  title,
  country,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const [opened, setOpened] = useState([]);

  const toggle = (index) => {
    if (opened.includes(index)) {
      setOpened((opened) => {
        return opened.filter((el) => el !== index);
      });
    } else {
      setOpened((opened) => {
        return opened.concat([index]);
      });
    }
  };

  const subColumns = columns.concat({
    key: "operation",
    accessorKey: "operation",
    align: "center",
    header: "",
    enableSorting: false,
    size: 20,
    cell: (record) =>
      onClickKebab(onMore, record, scenarioType, country, onManualAdjustment),
  });

  return (
    <div
      className={(scenarioList?.length ?? 0) === 0 ? "d-none" : "bp-core mb-5"}
    >
      {title ? <div className="h4">{title}</div> : <div></div>}
      {scenarioList?.length > 0 ? (
        scenarioList.map((scenarioGroup, index) => (
          <>
            <div
              className="d-inline-block w-100 scenario-header"
              onClick={() => toggle(index)}
              onKeyDown={() => toggle(index)}
            >
              <span className="d-inline-block w-[54px] pl-[15px]">
                {opened.includes(index) ? (
                  <Up24 className="d-inline" />
                ) : (
                  <Down24 className="d-inline" />
                )}
              </span>
              <Bullet24
                className={"d-inline ml-2 mr-4 " + scenarioType.toLowerCase()}
              />
              <span className="scenario-name-title">
                {scenarioGroup?.groupName}
              </span>
              <span className="scenario-name-title float-right mr-14">
                {`${formatNumber(
                  roundDecimal(scenarioGroup?.groupTotal),
                  decimalFormat,
                  0,
                )}`}
              </span>
            </div>
            <Collapse key={index} isOpen={opened.includes(index)}>
              <Card>
                <CardBody>
                  <ExpandableTable
                    columns={subColumns}
                    data={scenarioGroup?.scenarioTotals}
                  />
                </CardBody>
              </Card>
            </Collapse>
          </>
        ))
      ) : (
        <div className="flex items-center justify-center h-96">
          No data found
        </div>
      )}
    </div>
  );
};

ComplianceScenarioAccordion.propTypes = {
  scenarioList: PropTypes.array,
  columns: PropTypes.array,
  scenarioType: PropTypes.string,
  title: PropTypes.string,
  onMore: PropTypes.func,
  onManualAdjustment: PropTypes.func,
  country: PropTypes.string,
};

export default ComplianceScenarioAccordion;
