import PropTypes from "prop-types";
import {FormFeedback, Input, Label} from "reactstrap";

const LabeledSelect = ({
  id,
  label,
  value,
  onChange,
  options,
  error,
  disabled = false,
}) => {
  return (
    <div className="mb-4">
      <Label className="text-gray-700 text-sm mb-2" htmlFor={id}>
        {label}
      </Label>
      <Input
        type="select"
        id={id}
        value={value}
        onChange={onChange}
        invalid={!!error}
        disabled={disabled}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {error && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
};

LabeledSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.oneOf([null]),
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.oneOf([null]),
      ]),
      setDisable: PropTypes.bool,
    }),
  ),
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default LabeledSelect;
