import PropTypes from "prop-types";
import {Alert24} from "@bphxd/ds-core-react/lib/icons";
import {useNavigate} from "react-router-dom";
import {COPRO_URL} from "modules/co-processing/constants/coProcessing";
import {BANNER_TYPE} from "modules/co-processing/constants/shipments";

const Banner = ({errorState, text, type, id = ""}) => {
  const navigate = useNavigate();
  if (!errorState) return null;

  const navigateToDetailsScreen = () => {
    navigate(`${COPRO_URL}/batches/details`, {
      state: {
        batch_id: id,
      },
    });
  };

  const bannerColors =
    type === BANNER_TYPE.ERROR
      ? {bgColor: "bg-[#ffedf0]", borderColor: "border-[#ff7591]"}
      : {bgColor: "bg-[#fffff0]", borderColor: "border-[#ffee5c]"};

  const content = id ? (
    <span
      onClick={navigateToDetailsScreen}
      className="pl-2 text-blue-600 hover:text-blue-800 cursor-pointer"
      role="button"
      tabIndex="0"
      onKeyDown={(e) => e.key === "Enter" && navigateToDetailsScreen()}
    >
      {text}
    </span>
  ) : (
    <span className="pl-2">{text}</span>
  );
  return (
    <div
      data-test="shipments-error-banner"
      className={`flex items-center ${bannerColors.bgColor} border-2 ${bannerColors.borderColor} p-3 rounded mb-3`}
    >
      <Alert24 />
      {content}
    </div>
  );
};

Banner.propTypes = {
  errorState: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
};

export default Banner;
