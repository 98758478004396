import React from "react";

import {useQuery} from "@apollo/client";
import {GET_TAX_PLANT_REGISTRY_DETAIL} from "graphql/GlobalConfigCenter/TaxPlantRegistry";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {TAX_PLANT_REGISTRY_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {TAX_PLANT_REGISTRY_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const TaxPlantRegistryConfigPage = () => {
  const {data, loading} = useQuery(GET_TAX_PLANT_REGISTRY_DETAIL);

  return (
    <Layout
      title={TAX_PLANT_REGISTRY_CONFIG_PAGE.title}
      description={TAX_PLANT_REGISTRY_CONFIG_PAGE.description}
      tableColumns={TAX_PLANT_REGISTRY_COLUMNS}
      tableData={loading ? [] : data?.getTaxPlantRegistryAurora.data}
    />
  );
};

export default TaxPlantRegistryConfigPage;
