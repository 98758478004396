import axios from "axios";
import {BATCHES} from "graphql/tank-usage";
import LoadingModal from "modules/common/StatusModals/LoadingModal";
import PropTypes from "prop-types";
import {c14UploadIntruction} from "content/instructions";
import {handleQuery} from "providers/ApiHelper";
import {client} from "providers/Apollo";
import {AiFillCloseCircle, AiOutlineClose} from "react-icons/ai";
import {useState, useMemo} from "react";
import {toast} from "react-toastify";
import Button from "DLS/Button";
import Modal from "../Modal";

const PreviewModal = ({
  file,
  submitting = false,
  visible = false,
  onCancel = () => {
    /* do nothing */
  },
  onSubmit = () => {
    /* do nothing */
  },
}) => {
  const previewUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : ""),
    [file],
  );

  return (
    <Modal
      data-test="preview-modal"
      wrapClassName="min-w-fit w-2/3 m-auto"
      visible={visible}
      footer={
        <div className="flex flex-row gap-2 w-full">
          <Button
            type="secondary"
            onClick={onCancel}
            data-test="cancel-button"
            label="Close"
            className="w-full"
          />
          <Button
            disabled={submitting}
            onClick={onSubmit}
            data-test="submit-button"
            label="Submit"
            className="w-full"
          />
        </div>
      }
    >
      <iframe
        className="min-h-[550px] bg-gray-100"
        src={previewUrl}
        width="100%"
        height="100%"
        border="0"
        title="Test"
      />
    </Modal>
  );
};

PreviewModal.propTypes = {
  file: PropTypes.any,
  visible: PropTypes.bool,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default PreviewModal;
