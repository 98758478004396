import Menu from "modules/common/Menu";
import PropTypes from "prop-types";
import {toast} from "react-toastify";
import fileNames from "utils/helpers/uniqueFileName.js";
import * as XLSX from "xlsx";

const LocalXLSXItem = ({data, columns, filename, clearSelection}) => {
  const handleXLSXDownload = () => {
    if (data.length > 0) {
      const headers = [];
      const headings = [];
      const a1 = {};
      columns.forEach((col) => {
        if (col.key !== "operation") {
          headers.push(col.key);
          const {key, title} = col;
          a1[key] = title;
        }
      });
      headings.push(a1);

      const value = data.map((x) => {
        return Object.fromEntries(
          Object.entries(x).filter(([key]) => {
            return headers.includes(key);
          }),
        );
      });

      const workbook = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(headings, {
        header: headers,
        skipHeader: true,
        origin: 0,
      });
      XLSX.utils.sheet_add_json(ws, value, {
        header: headers,
        skipHeader: true,
        origin: -1,
      });
      XLSX.utils.book_append_sheet(workbook, ws, "tab1");
      XLSX.writeFile(workbook, fileNames.uniqueXlxsFile(filename));
    } else {
      toast.error("No data available to export");
    }
    clearSelection?.();
  };
  return (
    <Menu.Item
      key="action-download-xlsx"
      data-test="action-download-xlsx"
      onClick={handleXLSXDownload}
    >
      Export/Download to Excel
    </Menu.Item>
  );
};

LocalXLSXItem.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  filename: PropTypes.string.isRequired,
  clearSelection: PropTypes.func,
};

export default LocalXLSXItem;
