import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {Fragment} from "react";
import {Table} from "reactstrap";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import "./index.css";

const SplitTableAggregated = ({
  data,
  columns,
  loading,
  pagination,
  onPaginationChange,
  uom,
}) => {
  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    getSubRows: (row) => row.original?.outgoingData ?? [],
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, pagination);
      onPaginationChange(newValue);
    },
    initialState: {
      pagination: {
        pageIndex: pagination?.pageIndex,
      },
    },
    autoResetPageIndex: false,
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  return (
    <>
      <Table className="bp-core table feedstock-table-aggregated">
        <thead>
          {table.getHeaderGroups().map((headerGroup, groupIndex) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => {
                return (
                  <Fragment key={header.id}>
                    {header.column.getCanSort() ? (
                      <th
                        key={header.id}
                        colSpan={header?.subHeaders?.length}
                        onClick={header.column.getToggleSortingHandler()}
                        style={{
                          cursor: "pointer",
                          userSelect: "none",
                          width: header.getSize(),
                        }}
                      >
                        <div
                          className={`d-flex align-items-center ${
                            header.getContext().column.columnDef.dataType ===
                              "number" && "justify-content-end"
                          }`}
                        >
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </div>
                          <div className="sorting-element">
                            <div className="position-relative ms-2">
                              <div className="react-table-sort position-absolute top-50 translate-middle-y">
                                <TriangleDown16
                                  className={`position-absolute bottom-0 ${
                                    !header.column.getIsSorted() ||
                                    (header.column.getIsSorted() &&
                                      header.column.getIsSorted() !== "desc")
                                      ? "opacity-20"
                                      : ""
                                  }`}
                                />
                                <TriangleUp16
                                  className={`position-absolute top-0 ${
                                    !header.column.getIsSorted() ||
                                    (header.column.getIsSorted() &&
                                      header.column.getIsSorted() !== "asc")
                                      ? "opacity-20"
                                      : ""
                                  }`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                    ) : (
                      <th
                        // align-top for row selection and row options columns so positioning is same as <td>
                        className="align-top"
                        key={header.id}
                        colSpan={header?.subHeaders?.length}
                        style={{
                          verticalAlign: "middle",
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </th>
                    )}
                  </Fragment>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <SpinnerLoading />
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={`
                     ${
                       cell.getContext().column.columnDef.dataType ===
                         "number" && "text-right"
                     }`}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};
SplitTableAggregated.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onPaginationChange: PropTypes.func,
  pagination: PropTypes.object,
  uom: PropTypes.string,
};
export default SplitTableAggregated;
