import React from "react";
import {UncontrolledTooltip} from "reactstrap";
import PropTypes from "prop-types";
import tw, {css, styled} from "twin.macro";
import displayDateLabel from "utils/helpers/displayDateLabel";
import VarianceInfoToolTip from "modules/forecasting/components/VarianceInfoToolTip";
import ForecastArrowWidget from "modules/forecasting/components/ForecastArrowWidget";

const getTestId = ({date} = {}) =>
  date?.month ? `${date?.month}-month-data` : `${date}-year-data`;

export const valueAccessor = ({category, data = {}}) => {
  const dataByCategory = data[category];

  return dataByCategory?.actualValue !== undefined
    ? dataByCategory.actualValue
    : dataByCategory ?? 0;
};

const getMouseOverData = (category, data) => ({
  ...data?.[category],
  displayDate: data?.date?.year ? displayDateLabel(data.date) : "",
  type: category === "monthTotal" ? "total" : category,
});

const MouseOverWithArrowWidget = ({category, data}) => {
  const {variance, variancePercent} = data?.[category] || {};
  const testId = getTestId(data);
  const tooltipId = `${category}_tooltip_${testId}`;

  return (
    <span
      className="mouse-over-widget-wrapper"
      data-test={`${category}-widget-wrapper`}
    >
      <span id={tooltipId} data-test={`${category}-widget`}>
        <ForecastArrowWidget
          changedValue={variance}
          isVarianceOver4Percent={Math.abs(variancePercent) > 4}
        />
      </span>
      <UncontrolledTooltip placement="top" target={tooltipId}>
        <VarianceInfoToolTip cellData={getMouseOverData(category, data)} />
      </UncontrolledTooltip>
    </span>
  );
};
MouseOverWithArrowWidget.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
};

const BarChartDataSet = ({categories, data = {}}) => (
  <DisplayValuesFlexContainer data-test={getTestId(data)}>
    {categories.map((category) => (
      <React.Fragment key={category}>
        <CategoryData className="category-data" data-test={`${category}-data`}>
          <span>{valueAccessor({category, data})?.toLocaleString()}</span>

          <MouseOverWithArrowWidget data={data} category={category} />
        </CategoryData>
      </React.Fragment>
    ))}
  </DisplayValuesFlexContainer>
);

BarChartDataSet.propTypes = {
  categories: PropTypes.array,
  data: PropTypes.object,
};

export default BarChartDataSet;

const CategoryData = styled.span(() => {
  return [
    css`
      &.category-data {
        position: relative;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        align-items: center;
        min-width: 24px;

        &:not(:first-of-type)::before {
          content: "";
          background-color: black;
          position: absolute;
          width: 0.1em;
          left: -0.3em;

          ${tw`h-3 top-[3px]`};
        }
      }
    `,
  ];
});

const DisplayValuesFlexContainer = tw.div`pt-3 flex h-16 gap-2 whitespace-nowrap`;
