import {useMemo} from "react";
import tw from "twin.macro";
import PropTypes from "prop-types";
import {
  computeCreditsYearTotal,
  computeCreditsUnpublishedTotal,
  getCategoryKeys,
} from "modules/forecasting/helpers/computeGraphData";
import BarByCategories from "./BarByCategories";
import {StyledHeader, BaseGrayWrapper} from "./styles";

export const YearTotalCreditsGraph = ({fullYearData = [], region = null}) => {
  const yearTotalValues = useMemo(
    () =>
      region
        ? computeCreditsUnpublishedTotal(fullYearData)
        : computeCreditsYearTotal(fullYearData),
    [fullYearData, region],
  );

  if (!yearTotalValues) return null;

  return (
    <ChartContainer data-test="year-total-chart">
      <StyledHeader className="pt-4">TOTAL RINS</StyledHeader>

      <BarByCategories
        barKeys={getCategoryKeys(fullYearData)}
        graphDataObj={yearTotalValues}
        renderDateLabel={(year) => year || "N/A"}
      />
    </ChartContainer>
  );
};
YearTotalCreditsGraph.propTypes = {
  fullYearData: PropTypes.array,
  region: PropTypes.string,
};

export default YearTotalCreditsGraph;

const ChartContainer = tw(BaseGrayWrapper)`
  flex flex-col justify-center items-center 
  px-1 shrink 
  md:max-w-[280px] md:min-w-[220px]

`;
