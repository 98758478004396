import React, {useContext} from "react";

export const UserSettingsContext = React.createContext({
  userSettings: {
    dateFormat: "",
    decimalFormat: "",
  },
  setUserSettings: () => {
    /* do nothing */
  },
});

export const useUserSettings = () => useContext(UserSettingsContext);
