import PropTypes from "prop-types";
import {NO_RECORDS} from "modules/co-processing/constants/batches";
import {Card, CardBody} from "reactstrap";

const RecordCard = ({title, testId}) => (
  <div className="mb-8" data-test={testId}>
    <Card className="card-border rounded-6">
      <CardBody>
        <div className="text-[26px]">{title}</div>
        <p className="text-xl pt-7 pb-12">{NO_RECORDS}</p>
      </CardBody>
    </Card>
  </div>
);

RecordCard.propTypes = {
  title: PropTypes.string,
  testId: PropTypes.string,
};

export default RecordCard;
