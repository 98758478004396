import React, {useState} from "react";
import PropTypes from "prop-types";
import {Close} from "@bphxd/ds-core-react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import Form from "modules/common/FormNew";
import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {
  GET_MASS_BALANCE_FORM_DATA,
  SAF_MASS_BALANCE_MUTATION,
} from "graphql/saf/MassBalance";
import {siteRefId} from "modules/SAF-dashboard/constants/common";
import {useParams} from "react-router-dom";
import SchemaAssigntoSale from "./schemaAssignToSale";
import AssignToSaleForm from "./AssignToSaleForm";

const AssignToSaleModal = ({isVisibleModal, rowData}) => {
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const {country} = useParams();

  const {data: formData, loading: formDataLoading} = useQuery(
    GET_MASS_BALANCE_FORM_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const [generateOutgoingDraft, {loading}] = useMutation(
    SAF_MASS_BALANCE_MUTATION,
    {
      onCompleted: ({bioLcSafMBAssign}) => {
        if (bioLcSafMBAssign?.statusCode === 201) {
          toast.success("Draft generated successfully");
          rowData?.outgoingRecords?.refetchOutgoing();
          setModalVisible(false);
        }
        if (bioLcSafMBAssign?.statusCode === 500) {
          toast.error("Failed to assign");
          setModalVisible(false);
        }
      },
    },
  );

  return (
    <Modal size="lg" isOpen={isModalVisible} className="modal-dialog-centered">
      <ModalHeader
        className="p-4"
        style={{
          borderBottom: "solid 1px #80808061",
        }}
        close={
          <Close
            onClick={() => {
              setModalVisible(false);
            }}
          />
        }
      >
        TEMP - Assign to sale
      </ModalHeader>
      <ModalBody className="text-center bg-gray-50">
        <Form
          id="assign-to-sales-form"
          mode="onChange"
          reValidateMode="onChange"
          schema={SchemaAssigntoSale}
          defaultValues={{
            date_of_issuance: new Date(),
          }}
          onSubmit={async (values) => {
            generateOutgoingDraft({
              variables: {
                ...values,
                certificate_inbound_eu_id:
                  rowData?.incomingRecords[0]?.certificate_inbound_eu_id,
                site_reference_id: siteRefId[country],
              },
            });
          }}
        >
          <AssignToSaleForm
            rowData={rowData}
            formData={formData}
            formDataLoading={formDataLoading}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="standard-primary"
          className="primary-btn rounded-none"
          form="assign-to-sales-form"
          disabled={loading}
        >
          {loading && <Spinner size="sm" className="btn-icon-prefix" />} Assign
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AssignToSaleModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
};

export default AssignToSaleModal;
