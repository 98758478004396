import {SHIPMENT_FIELD_KEYS} from "../constants/shipments";

const getValueKey = (keyName) => {
  return keyName === SHIPMENT_FIELD_KEYS.TOTAL_ACTUAL_SHIP_VOL
    ? SHIPMENT_FIELD_KEYS.ACTUAL_SHIP_VOL
    : keyName;
};

const buildAllocationDetails = (prevData, name, value, formKey, formId) => {
  const newValueKey = getValueKey(name);

  if (Array.isArray(value)) {
    const newEntries = value
      .filter(
        (shipment) =>
          shipment &&
          ((typeof shipment === "string" && shipment.trim() !== "") ||
            (typeof shipment === "object" &&
              shipment.shipment_id &&
              shipment.shipment_id.trim() !== "")),
      )
      .map((shipment) => {
        if (typeof shipment === "string") {
          return {[newValueKey]: shipment, [formKey]: formId};
        }
        return {
          [newValueKey]: shipment.shipment_id,
          [formKey]: shipment.batch_id || formId,
        };
      });
    return [
      ...prevData.filter((item) => item?.[formKey] !== formId),
      ...newEntries,
    ];
  }

  // checks for existing entry
  const existingIndex = prevData.findIndex(
    (item) => item?.[newValueKey] !== undefined && item?.[formKey] === formId,
  );
  if (existingIndex > -1) {
    // adds to existing entry
    if (value) {
      const updatedData = [...prevData];
      updatedData[existingIndex] = {
        ...updatedData[existingIndex],
        [newValueKey]: value,
      };
      return updatedData;
    }
    // removes existing entry if user enter empty value
    return prevData.filter((_, index) => index !== existingIndex);
  }
  if (value) {
    return [...prevData, {[newValueKey]: value, [formKey]: formId}];
  }
  return prevData;
};

export default buildAllocationDetails;
