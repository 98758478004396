const {useReducer, useCallback} = require("react");

const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
const TRIGGER_NOTIFICATION = "TRIGGER_NOTIFICATION";

const reducer = (state, {type, payload}) => {
  switch (type) {
    case CLEAR_NOTIFICATION:
      return {
        visible: false,
        type: null,
        text: "",
        onClose: undefined,
      };
    case TRIGGER_NOTIFICATION:
      return {
        visible: true,
        type: payload.type,
        text: payload.text,
        onClose: payload.onClose,
      };
    default:
      throw new Error();
  }
};

const useNotification = (visible = false) => {
  const [state, dispatch] = useReducer(reducer, {
    visible,
    type: null,
    text: "",
    onClose: undefined,
  });

  const clearNotification = useCallback(
    () =>
      dispatch({
        type: CLEAR_NOTIFICATION,
      }),
    [],
  );

  const triggerNotification = useCallback((type, text, onClose) => {
    dispatch({
      type: TRIGGER_NOTIFICATION,
      payload: {type, text, onClose},
    });
  }, []);

  return [triggerNotification, clearNotification, state];
};

export default useNotification;
