import {gql} from "@apollo/client";

export const PRODUCTION_EMISSIONS = gql`
  query bioLcCoproConfigCenterProductEmissions {
    bioLcCoproConfigCenterProductEmission {
      Records {
        product_emission_id
        location_id
        location_code
        location_name
        emission_factor
        emission_uom
        country_id
        valid_from
        valid_to
      }
    }
  }
`;

export default {PRODUCTION_EMISSIONS};
