import Logo from "assets/images/mobile-logo.png";
import {TailUp24} from "@bphxd/ds-core-react/lib/icons";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col gap-y-8 lg:px-10 border-gray-300 bg-transparent h-[88px]">
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-between w-full h-[88px]">
          <div className="flex items-center py-5">
            <div className="flex items-center">
              <img src={Logo} className="w-9 h-12 sm:mx-0" alt="BP Logo" />
            </div>
            <a
              href="/"
              className="inline text-black hover:text-black cursor-pointer ml-4 pl-12 text-sm"
              rel="noreferrer"
            >
              BioVerse
            </a>
            <div className="flex items-center text-gray-700 ml-6 text-xs cursor-none">
              © {currentYear} BP. All rights reserved.
            </div>
            <div className="flex items-center">
              <a
                href="https://forms.office.com/Pages/ResponsePage.aspx?id=LpWA6nak1EKq9FRXhSsPfisTZMYFf0ZAh0P7Dj6z0oFUMTZPVDhGTEtHMURKMkdBNEQ0RUQwQzhVWS4u"
                target="_blank"
                className="inline text-gray-700 hover:text-black cursor-pointer ml-6 text-xs"
                rel="noreferrer"
              >
                Support
              </a>
            </div>
            <div className="flex items-center">
              <a
                href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5MzUzNjg3MDQwMCJ9/all"
                target="_blank"
                className="inline text-gray-700 hover:text-black cursor-pointer ml-6 text-xs"
                rel="noreferrer"
              >
                Feedback
              </a>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="w-10 h-10 rounded-full border-1 flex justify-center items-center relative hover:border-[#404040]"
          data-test="button-scroll-to-top"
          onClick={scrollToTop}
        >
          <TailUp24 style={{color: "gray", fontSize: 30}} />
        </button>
      </div>
    </div>
  );
};

export default Footer;
