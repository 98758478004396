import React from "react";
import PropTypes from "prop-types";
import tw, {css, styled} from "twin.macro";
import displayDateLabel from "utils/helpers/displayDateLabel";
import {pageDataToGraph} from "modules/forecasting/helpers/computeGraphData";
import {
  getPreviousCurrentOrForecastHeader,
  getHighestBarValue,
} from "modules/forecasting/helpers/generateGraphValues";
import BarsByCategory from "./BarByCategories";
import {StyledHeader, BaseGrayWrapper} from "./styles";

const PublishedViewBarChart = ({pageData = []}) => {
  const {categories = [], graphValues = []} = pageDataToGraph(pageData);

  if (!categories?.length || !graphValues?.length) return null;

  return (
    <TwelveColumnFlex data-test="credits-twelve-month-chart">
      {graphValues?.map((monthData, index) => (
        <FlexColumnDiv data-test="month-bar-set" key={index}>
          <StyledHeader>
            {getPreviousCurrentOrForecastHeader(monthData?.date)}
          </StyledHeader>

          <BarsByCategory
            barKeys={categories}
            graphDataObj={monthData}
            maxYValue={getHighestBarValue(graphValues)}
            renderDateLabel={(date) => (
              <label className="pt-4" data-test="date-label">
                {displayDateLabel(date)}
              </label>
            )}
          />
        </FlexColumnDiv>
      ))}
    </TwelveColumnFlex>
  );
};
PublishedViewBarChart.propTypes = {
  pageData: PropTypes.array,
};
export default PublishedViewBarChart;

const FlexColumnDiv = tw.div`flex flex-col items-center py-1`;

const TwelveColumnFlex = styled(BaseGrayWrapper)(() => [
  tw`px-4 flex flex-row grow gap-3 md:gap-4`,
  // hide vertical scrollbar to fix UI flickering when mouse over renders
  tw`overflow-x-auto overflow-y-hidden`,

  css`
    > div {
      flex-basis: 9%;
    }
  `,
]);
