import PropTypes from "prop-types";
import tw, {styled, css} from "twin.macro";
import {formatDisplayValue} from "../CombinedObligatedVolumeTable";

const StyledTd = styled.td(
  ({centered = true}) => css`
    ${tw`border border-gray-300 px-4`}
    ${centered && tw`text-center`}
  `,
);

const StyledTh = tw.th`border border-gray-300 px-4`;

const EmailTableDeficits = ({
  availableMonths,
  columns,
  pricingLabel,
  isTrueUp = false,
}) => {
  return (
    <>
      <div>
        <p className="font-bold" data-test="to-publish-email-table-label">
          Deficits:
        </p>
      </div>
      <table className="table-auto border-collapse mt-4">
        <thead>
          <tr>
            <StyledTh className="w-[160px]">Pricing Window</StyledTh>
            <StyledTh
              className="w-[300px]"
              data-test="to-publish-email-pricing-label"
            >
              {pricingLabel}
            </StyledTh>
            {availableMonths.map((col, idx) => (
              <StyledTh
                className={
                  isTrueUp && idx === availableMonths.length - 1
                    ? "font-bold w-[130px]"
                    : ""
                }
                key={idx}
              >
                {col}
              </StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="">
            <StyledTd>Deficit Swap</StyledTd>
            <StyledTd className="font-bold">Total RVO (BBLS)</StyledTd>
            {columns.map((col, cidx) => (
              <StyledTd
                className={
                  cidx === availableMonths.length - 1 ? "font-bold" : ""
                }
                key={cidx}
                data-test={`to-publish-email-totals-${cidx}`}
              >
                {formatDisplayValue(col)}
              </StyledTd>
            ))}
          </tr>
        </tbody>
      </table>
    </>
  );
};

EmailTableDeficits.propTypes = {
  availableMonths: PropTypes.array,
  columns: PropTypes.array,
  pricingLabel: PropTypes.string,
  isTrueUp: PropTypes.bool,
};

export default EmailTableDeficits;
