import * as yup from "yup";

export const SchemaAssigntoSale = yup
  .object()
  .shape({
    date_of_issuance: yup.string().label("Date of issuance").required(),
    mode_of_transportation: yup
      .string()
      .label("Mode of transportation")
      .required(),
    contract_number: yup.string().label("Contract number").required(),
    quantity: yup.number().label("Quantity m3").required(),
    recipient_name: yup.string().label("Name of recipient").required(),
    address_receiving: yup.string().label("Address of receiving point"),
  })
  .required();

export default SchemaAssigntoSale;
