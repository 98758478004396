import {
  RENEWABLE_SHIPMENT_LIST,
  RENEWABLE_STATUS,
} from "../constants/shipments";

const isNonRenewableShipment = (data) =>
  (data.renewable_shipment_flag === false ||
    data.renewable_shipment_flag === RENEWABLE_STATUS.FALSE) &&
  RENEWABLE_SHIPMENT_LIST.includes(data.shipment_type?.toLowerCase());

export default isNonRenewableShipment;
