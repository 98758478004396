import {gql} from "@apollo/client";

export const GET_AVAILABLE_QTY = gql`
  query GetAvailableQty(
    $currentUom: String
    $quantity: String
    $targetUom: String
  ) {
    bioLcCoproEuMassBalanceGetQuantityUom(
      event: {
        currentUom: $currentUom
        quantity: $quantity
        targetUom: $targetUom
      }
    ) {
      convertedQuantity
    }
  }
`;

export default {GET_AVAILABLE_QTY};
