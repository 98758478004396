import PropTypes from "prop-types";

import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useLocation, Link} from "react-router-dom";

import Tabs from "modules/common/Tabs";
import {Helmet} from "react-helmet";
import Heading from "DLS/Typography/Heading";

export const BasePageLayout = ({
  pageTitle,
  tabsData,
  rightContent,
  children,
  breadcrumbs,
  ...props
}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  return (
    <div {...props}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {/* Sub gray bar */}
      <Breadcrumb className="px-7 pt-4">
        {breadcrumbs?.map((item, index) => (
          <BreadcrumbItem key={index} active={index === breadcrumbs.length - 1}>
            {item.text === "BioVerse" ? (
              <Link
                to="/"
                data-test="home-button"
                onClick={() => {
                  const countryFeatureData =
                    appSetting?.featureMappingData?.find(
                      (item) => item.countryName === "GLOBAL",
                    );

                  setAppSetting({
                    ...appSetting,
                    currentCountry: "GLOBAL",
                    currentCountryMappingData: countryFeatureData,
                    currentModule: "",
                    currentModuleTitle: "",
                  });
                }}
              >
                {item.text}
              </Link>
            ) : index === breadcrumbs.length - 1 ? (
              <span>{item.text}</span>
            ) : (
              <Link to={item.link}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
      <div className="flex items-center justify-between pt-5 px-6 bg-gray-100 h-14 lg:px-10 gap-x-2">
        <Heading level={5} data-test="page-title">
          {pageTitle}
        </Heading>
        {rightContent}
      </div>
      {tabsData ? (
        <div className="w-full bg-gray-50">
          <Tabs className="px-6 py-8 lg:px-10" data={tabsData} />
        </div>
      ) : null}

      {children}
    </div>
  );
};

export const BasePageLayoutContent = ({children}) => (
  <div className="px-6 lg:px-10">{children}</div>
);

BasePageLayout.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  tabsData: PropTypes.arrayOf(PropTypes.object),
  rightContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
};

BasePageLayoutContent.propTypes = {
  children: PropTypes.node.isRequired,
};
