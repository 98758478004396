import {gql} from "@apollo/client";

export const MASS_BALANCE_COUNTS = gql`
  query bioLcGetMassBalanceCounts(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceCounts(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      openingCount
      incomingCount
      outgoingCount
      currentCount
      physicalStock
      periodStatus
      statusCode
      error
    }
  }
`;

export default MASS_BALANCE_COUNTS;
