export const removeUnUsedLocalStorageforLogin = () => {
  const clientIDsUsed = [
    "f2e37541-fdf1-49db-9b71-e8fd74806b4a", // dev
    "8a81a2c2-ab66-4969-9982-397c7b0f0b3c", // devops
    "3c3b7967-b14d-4ba9-ac4b-957d746d3636", // pre-prod
    "d5ee0486-9c0e-4a46-aefb-11f10782d843", // preview
    "efe18aca-2486-42b6-ab83-527ad3238560", // prod
    "f2e37541-fdf1-49db-9b71-e8fd74806b4a", // test
    "081f7ba1-e740-451b-89a1-dac7dec3aeda", // user-test
  ];
  const clientIDsNotUsed = clientIDsUsed.filter(
    (item) => item !== process.env.REACT_APP_AD_CLIENTID,
  );
  // to remove the old tenant localstorages
  const localStorageKeys = Object.keys(localStorage);
  localStorageKeys.forEach((localStorageitem) => {
    if (
      clientIDsNotUsed.some((clientId) => localStorageitem?.includes(clientId))
    ) {
      localStorage.removeItem(localStorageitem);
    }
    if (
      process.env.REACT_APP_AD_AUTHORITY?.includes(
        "ea80952e-a476-42d4-aaf4-5457852b0f7e",
      ) &&
      localStorageitem?.includes("b989f104-d41b-4f40-b814-1a3bfee7dbc7")
    ) {
      localStorage.removeItem(localStorageitem);
    } else if (
      process.env.REACT_APP_AD_AUTHORITY?.includes(
        "b989f104-d41b-4f40-b814-1a3bfee7dbc7",
      ) &&
      localStorageitem?.includes("ea80952e-a476-42d4-aaf4-5457852b0f7e")
    ) {
      localStorage.removeItem(localStorageitem);
    }
  });
};

export default {
  removeUnUsedLocalStorageforLogin,
};
