function transformShipments(shipments) {
  return shipments.map((shipment) => {
    const firstPart = shipment.parts[0];
    return {
      shipment_id: shipment.shipment_id,
      shipment_type: shipment.shipment_type,
      days_to_delete: shipment.days_to_delete,
      shipment_date: shipment.shipment_date,
      shipment_start_date: firstPart?.shipment_start_date,
      shipment_end_date: firstPart?.shipment_end_date,
      total_estimated_volume: shipment.total_estimated_volume,
    };
  });
}

export default transformShipments;
