import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {
  getColumnsCPP,
  getColumnsGF,
  getColumnsSAF,
} from "./components/AllocationTable/Column";

const getColumns = (
  country,
  division,
  dateFormat,
  decimalFormat,
  siteReferenceId,
  divisionId,
) => {
  switch (division) {
    case DIV_CODE_SAF:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
      );
    case DIV_CODE_GF:
      return getColumnsGF(dateFormat, decimalFormat, country);
    case DIV_CODE_COPRO:
      return getColumnsCPP(dateFormat, decimalFormat, country);
    default:
      return getColumnsSAF(
        dateFormat,
        decimalFormat,
        country,
        siteReferenceId,
        divisionId,
      );
  }
};

export default getColumns;
