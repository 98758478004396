import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/client";
import {useForm, FormProvider} from "react-hook-form";
import {
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import {
  Alert32,
  Check24,
  Remove24,
  TailLeft24,
} from "@bphxd/ds-core-react/lib/icons";
import {UPDATE_SAF_DOCUMENT} from "graphql/saf/IncomingDocuments";
import {DOCUMENT_DETAIL_VIEW} from "graphql/docManager/IncomingDocs";
import {useAccount, useMsal} from "@azure/msal-react";
import {toast} from "react-toastify";
import SingleDocumentViewer from "modules/common/SingleDocumentViewer";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {useAppSetting} from "providers/appSetting";
import {DIV_CODE_SAF} from "constants/divisionDetails";

import CheckboxFlag from "../components/CheckboxNullable";
import Layout from "../components/Layout";
import Datepicker from "../components/Datepicker";
import {DDV, TDV} from "../components/utils";
import Subtitle from "../components/view-document/Subtitle";
import "./document-view.css";
import CustomRadioButton from "../../common/CustomRadioButton";
import {DIVISION_ID} from "../constants/documents";
import {saf_urls, siteRefId} from "../constants/common";
import generateBreadcrumbItems from "../utils";
import UnitOfMeasure from "../components/UnitOfMeasure";

const defaultValues = {
  supplier_name: "",
  date_of_issuance: "",
  supplier_address: "",
  certification_system: "",
  certificate_number: "",
  recipient_name: "",
  recipient_address: "",
  contract_number: "",
  supplier_dispatch_address: "",
  recipient_receipt_address: "",
  material_dispatch_datez: "",
  bio_product_producer: "",
  producer_installation_date: "",
  bio_product_user: "",
  user_installation_date: "",
  product_type: "",
  raw_material_type: "",
  additional_info: "",
  raw_material_origin_country_name: "",
  product_qty: "",
  product_qty_uom: "",
  product_energy_content: "",
  eu_red_compliant_flag: "",
  iscc_compliant_flag: "",
  chain_of_custody_option: "",
  default_value_applied_flag: "",
  ghg_eec: "",
  ghg_el: "",
  ghg_ep: "",
  ghg_etd: "",
  ghg_eu: "",
  ghg_esca: "",
  ghg_eccs: "",
  ghg_eccr: "",
  ghg_total: "",
  allocated_heat: "",
  allocated_electricity: "",
  ghg_emission_percentage_in: "",
  material_sustainability_criteria_flag: "",
  agricultural_biomass_intermediate_crop_flag: "",
  agricultural_biomass_low_risk_flag: "",
  raw_material_waste_or_residue_flag: "",
  nuts2_region: "",
  replace_previous_version: false,
  previous_version_sd_number: "",
};

const tabFields = {
  supplier: [
    "supplier_name",
    "supplier_address",
    "certification_system",
    "certificate_number",
    "recipient_name",
    "recipient_address",
    "contract_number",
    "supplier_dispatch_address",
    "recipient_receipt_address",
    "material_dispatch_datez",
    "bio_product_producer",
    "producer_installation_date",
    "bio_product_user",
    "user_installation_date",
  ],
  general: [
    "product_type",
    "raw_material_type",
    "additional_info",
    "raw_material_origin_country_name",
    "product_qty",
    "product_qty_uom",
    "product_energy_content",
    "eu_red_compliant_flag",
    "iscc_compliant_flag",
    "chain_of_custody_option",
  ],
  emission: [
    "default_value_applied_flag",
    "ghg_eec",
    "ghg_el",
    "ghg_ep",
    "ghg_etd",
    "ghg_eu",
    "ghg_esca",
    "ghg_eccs",
    "ghg_eccr",
    "ghg_total",
    "allocated_heat",
    "allocated_electricity",
    "ghg_emission_percentage_in",
    "ghg_no_value",
  ],
  certification: [
    "material_sustainability_criteria_flag",
    "agricultural_biomass_intermediate_crop_flag",
    "agricultural_biomass_low_risk_flag",
    "raw_material_waste_or_residue_flag",
    "nuts2_region",
  ],
  additionalInfo: [
    "replace_previous_version",
    "prevous_ver",
    "balance_group_name",
    "location_group_name",
  ],
};

const rejectReasons = [
  {
    key: "info_missing",
    value: "Required information missing or incorrect",
    message:
      "This certificate will be kept and deactivated. A new certificate needs to be reissued",
  },
  {
    key: "out_of_date",
    value: "Out of date certificate",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
  {key: "invalid", value: "Invalid file", message: "This file will be deleted"},
];

const SAFDocumentViewPage = () => {
  const [open, setOpen] = useState([]);
  const [rejecting, setRejecting] = useState(false);
  const [showPrevVersion, setShowPrevVersion] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [sdNumberOld, setSdNumberOld] = useState(null);
  const [submitAction, setSubmitAction] = useState("");
  const [openTabsOnce, setOpenTabsOnce] = useState(false);

  const navigate = useNavigate();

  const {appSetting} = useAppSetting();

  const {docId, country} = useParams();
  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_SAF);

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const openAccordion = useCallback(
    (id) => {
      if (!open.includes(id)) {
        setOpen([...open, id]);
      }
    },
    [open],
  );
  const {data, loading, refetch} = useQuery(DOCUMENT_DETAIL_VIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      certificateInboundEuId: docId,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const document = data?.bioLcGetIncomingDocDetailsApi?.documentDetails;

  const documentFile = data?.bioLcGetIncomingDocFileApi;

  useEffect(() => {
    setSdNumberOld(document?.sdNumber);
  }, [document?.sdNumber, setSdNumberOld]);
  useEffect(() => {
    setShowPrevVersion(!!document?.previous_version_sd_number);
  }, [document?.previous_version_sd_number]);

  const landingPageKey = country + "DocumentPage";
  const docManagerUrl = saf_urls[landingPageKey];
  const docUrl = saf_urls[landingPageKey] + "/" + docId;

  const optionalItems = [
    {text: "Document manager", link: docManagerUrl},
    {text: "Incoming Documents", link: docManagerUrl},
    {
      text: document?.sd_number ?? docId,
      link: docUrl,
    },
  ];

  const breadcrumbItems = generateBreadcrumbItems(
    country,
    saf_urls,
    optionalItems,
  );

  const renderTitle = (docStatus) => {
    switch (docStatus) {
      default:
        return "Proof of Sustainability (PoS)";
    }
  };

  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });

  const balanceGroupNames = data?.bioLcGetBalanceGroupApi?.balanceGroups || [];
  const locationGroupNames =
    data?.bioLcGetLocationGroupApi?.locationGroups || [];
  const balanceGroup =
    balanceGroupNames.length === 1 ? balanceGroupNames[0]?.balanceGroupId : "";
  const locationGroup =
    locationGroupNames.length === 1
      ? locationGroupNames[0]?.locationGroupId
      : "";

  const values = useMemo(() => {
    if (data && document != null) {
      const {
        sdNumber = "",
        supplierName = "",
        dateOfIssuance = "",
        supplierAddress = "",
        certificationSystem = "",
        certificateNumber = "",
        recipientName = "",
        recipientAddress = "",
        contractNumber = "",
        supplierDispatchAddress = "",
        recipientReceiptAddress = "",
        materialDispatchDatez = "",
        bioProductProducer = "",
        producerInstallationDate = "",
        bioProductUser = "",
        userInstallationDate = "",
        productType = "",
        rawMaterialType = "",
        additionalInfo = "",
        rawMaterialOriginCountryName = "",
        productQty = "",
        productQtyUom = "",
        productEnergyContent = "",
        euRedCompliantFlag = "",
        isccCompliantFlag = "",
        chainOfCustodyOption = "",
        defaultValueAppliedFlag = "",
        ghgEec = 0,
        ghgEl = 0,
        ghgEp = 0,
        ghgEtd = 0,
        ghgEu = 0,
        ghgEsca = 0,
        ghgEccs = 0,
        ghgEccr = 0,
        ghgTotal = "",
        allocatedHeat = "",
        allocatedElectricity = "",
        ghgEmissionPercentageIn = "",
        materialSustainabilityCriteriaFlag = "",
        agriculturalBiomassIntermediateCropFlag = "",
        agriculturalBiomassLowRiskFlag = "",
        rawMaterialWasteOrResidueFlag = "",
        nuts2Region = "",
        previousVersionSdNumber = "",
        bvAuditChangedDatez = "",
        mbBalanceGroupId = "",
        mbLocationGroupId = "",
      } = document;
      const props = {
        sd_number: sdNumber,
        supplier_name: supplierName,
        date_of_issuance: dateOfIssuance,
        supplier_address: supplierAddress,
        certification_system: certificationSystem,
        certificate_number: certificateNumber,
        recipient_name: recipientName,
        recipient_address: recipientAddress,
        contract_number: contractNumber,
        supplier_dispatch_address: supplierDispatchAddress,
        recipient_receipt_address: recipientReceiptAddress,
        material_dispatch_datez: materialDispatchDatez,
        bio_product_producer: bioProductProducer,
        producer_installation_date: producerInstallationDate,
        bio_product_user: bioProductUser,
        user_installation_date: userInstallationDate,
        product_type: productType,
        raw_material_type: rawMaterialType,
        additional_info: additionalInfo,
        raw_material_origin_country_name: rawMaterialOriginCountryName,
        product_qty: productQty,
        product_qty_uom: productQtyUom,
        product_energy_content: productEnergyContent,
        eu_red_compliant_flag: euRedCompliantFlag,
        iscc_compliant_flag: isccCompliantFlag,
        chain_of_custody_option: chainOfCustodyOption,
        default_value_applied_flag: defaultValueAppliedFlag,
        ghg_eec: parseFloat(ghgEec ?? "0"),
        ghg_el: parseFloat(ghgEl ?? "0"),
        ghg_ep: parseFloat(ghgEp ?? "0"),
        ghg_etd: parseFloat(ghgEtd ?? "0"),
        ghg_eu: parseFloat(ghgEu ?? "0"),
        ghg_esca: parseFloat(ghgEsca ?? "0"),
        ghg_eccs: parseFloat(ghgEccs ?? "0"),
        ghg_eccr: parseFloat(ghgEccr ?? "0"),
        ghg_total:
          ghgTotal === TDV || ghgTotal === DDV
            ? ghgTotal
            : parseFloat(ghgTotal ?? "0"),
        allocated_heat: allocatedHeat,
        allocated_electricity: allocatedElectricity,
        ghg_emission_percentage_in: ghgEmissionPercentageIn,
        material_sustainability_criteria_flag:
          materialSustainabilityCriteriaFlag,
        agricultural_biomass_intermediate_crop_flag:
          agriculturalBiomassIntermediateCropFlag,
        agricultural_biomass_low_risk_flag: agriculturalBiomassLowRiskFlag,
        raw_material_waste_or_residue_flag: rawMaterialWasteOrResidueFlag,
        nuts2_region: nuts2Region,
        replace_previous_version: !!previousVersionSdNumber,
        previous_version_sd_number: previousVersionSdNumber,
        bv_audit_changed_datez: bvAuditChangedDatez,
        balance_group_name: mbBalanceGroupId || balanceGroup,
        location_group_name: mbLocationGroupId || locationGroup,
      };
      return props;
    }
    return defaultValues;
  }, [data, document, balanceGroup, locationGroup]);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    values,
    shouldFocusError: true,
  });

  const {
    register,
    control,
    formState: {errors},
    getValues,
    resetField,
    watch,
    trigger,
    setFocus,
    setError,
    clearErrors,
  } = methods;

  const hasErrors = Object.keys(errors).length > 0;

  const ghg_eec_watch = watch("ghg_eec");
  const ghg_el_watch = watch("ghg_el");
  const ghg_ep_watch = watch("ghg_ep");
  const ghg_etd_watch = watch("ghg_etd");
  const ghg_eu_watch = watch("ghg_eu");
  const ghg_esca_watch = watch("ghg_esca");
  const ghg_eccs_watch = watch("ghg_eccs");
  const ghg_eccr_watch = watch("ghg_eccr");

  useEffect(() => {
    const ghgWatches = [
      ghg_eec_watch,
      ghg_el_watch,
      ghg_ep_watch,
      ghg_etd_watch,
      ghg_eu_watch,
      ghg_esca_watch,
      ghg_eccs_watch,
      ghg_eccr_watch,
    ];

    const allZero = ghgWatches.every((item) => parseFloat(item || "0") === 0);

    if (allZero) {
      setError("ghg_no_value", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });
    } else {
      clearErrors("ghg_no_value");
    }
  }, [
    clearErrors,
    ghg_eccr_watch,
    ghg_eccs_watch,
    ghg_eec_watch,
    ghg_el_watch,
    ghg_ep_watch,
    ghg_esca_watch,
    ghg_etd_watch,
    ghg_eu_watch,
    setError,
    watch,
  ]);

  const errorCount = Object.keys(errors).length;

  useEffect(() => {
    if (rejecting && rejectMessage === "") {
      setFocus("rejection_reason");
      trigger("rejection_reason");
    }
  }, [rejectMessage, rejecting, setFocus, trigger]);

  const validate = useCallback(async () => {
    const valid = await trigger();
    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    const tabsRequireAttention = [];
    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        flags[tab] = true;
        tabsRequireAttention.push(tab);
      }
    });
    setInvalidTabs(flags);
    if (tabsRequireAttention.length > 0 && !openTabsOnce) {
      setOpen([...tabsRequireAttention]);
      setOpenTabsOnce(true);
    }
    if (rejectMessage !== "") {
      clearErrors("rejection_reason");
    }
    return valid;
  }, [trigger, openTabsOnce, rejectMessage, errors, clearErrors]);

  useEffect(() => {
    // Make sure we trigger form validation after values change (e.g. after loading) to show errors
    // aka simulating next tick here
    const timeoutId = setTimeout(() => validate(), 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [values, validate, errorCount]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const status = document?.state;

  const isDisabled = status === "ready_to_assign" || status === "rejected";

  const productTypeItems = data?.bioLcGetMaterialDataApi?.productNames || [];

  const rawMaterialTypes =
    data?.bioLcGetMaterialDataApi?.rawMaterialNames || [];

  const chainOfCustody = ["Mass balance", "Physical segregation"];

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };

  const handleRejectMessage = (message) => {
    if (message === "") {
      setRejectMessage("");
    } else {
      setRejectMessage(
        rejectReasons.filter((r) => r.key === message)[0].message,
      );
    }
  };

  const handleErrorMessageClick = (errorValue) => {
    const errValue = errorValue === "ghg_no_value" ? "ghg_eec" : errorValue;
    let match = null;
    const tabs = Object.keys(tabFields);
    for (let index = 0; index < tabs.length; index += 1) {
      const element = tabs[index];
      if (tabFields[element].includes(errValue)) {
        match = element;
        break;
      }
    }
    openAccordion(match);
    setTimeout(() => setFocus(errValue), 150);
  };

  const [updateSafDocumentMutation] = useMutation(UPDATE_SAF_DOCUMENT, {
    onCompleted: ({bioLcUpdateIncomingDocDataApi}) => {
      if (
        bioLcUpdateIncomingDocDataApi?.errors &&
        bioLcUpdateIncomingDocDataApi?.errors?.length > 0
      ) {
        bioLcUpdateIncomingDocDataApi.errors.forEach((element) => {
          resetField(element.field);
          setError(element.field, {
            type: "manual",
            message: element.message,
          });
        });
        handleErrorMessageClick(bioLcUpdateIncomingDocDataApi?.errors[0].field);
        toast.error("Failed to update document");
      } else if (bioLcUpdateIncomingDocDataApi?.error) {
        toast.error(bioLcUpdateIncomingDocDataApi?.error);
      } else {
        refetch({
          certificate_inbound_eu_id: docId,
        });
        toast.success("Document updated successfully");
        navigate({
          pathname: saf_urls[`${country}DocumentPage`],
          search: submitAction === "accept" && `?docId=${docId}`,
        });
      }
      setAcceptModal(false);
      setSubmitLoading(false);
      setRejecting(false);
    },
  });

  const onSubmit = async (userAction) => {
    setSubmitLoading(true);
    setSubmitAction(userAction);
    const {...docData} = getValues();
    const variables =
      userAction === "accept"
        ? {
            event: {
              divisionId: DIVISION_ID,
              siteReferenceId: siteRefId[country],
              userAction,
              userId: account.username,
              certificateInboundEuId: docId,
              replacePreviousVersion: docData.replace_previous_version,
              previousVersionId: docData.previous_version_sd_number,
              documentData: {
                dateOfIssuance: docData.date_of_issuance,
                additionalInfo: docData.additional_info,
                agriculturalBiomassIntermediateCropFlag:
                  docData.agricultural_biomass_intermediate_crop_flag,
                agriculturalBiomassLowRiskFlag:
                  docData.agricultural_biomass_low_risk_flag,
                allocatedElectricity: parseFloat(docData.allocated_electricity),
                allocatedHeat: parseFloat(docData.allocated_heat),
                bioProductProducer: docData.bio_product_producer,
                bioProductUser: docData.bio_product_user,
                certificationSystem: docData.certification_system,
                certificateNumber: docData.certificate_number,
                chainOfCustodyOption: docData.chain_of_custody_option,
                contractNumber: docData.contract_number,
                defaultValueAppliedFlag: docData.default_value_applied_flag,
                euRedCompliantFlag: docData.eu_red_compliant_flag,
                ghgEec: parseFloat(docData.ghg_eec),
                ghgEccr: parseFloat(docData.ghg_eccr),
                ghgEccs: parseFloat(docData.ghg_eccs),
                ghgEl: parseFloat(docData.ghg_el),
                ghgEmissionPercentageIn: parseFloat(
                  docData.ghg_emission_percentage_in,
                ),
                ghgEp: parseFloat(docData.ghg_ep),
                ghgEsca: parseFloat(docData.ghg_esca),
                ghgEtd: parseFloat(docData.ghg_etd),
                ghgEu: parseFloat(docData.ghg_eu),
                ghgTotal: parseFloat(docData.ghg_total),
                isccCompliantFlag: docData.iscc_compliant_flag,
                materialDispatchDatez: docData.material_dispatch_datez,
                materialSustainabilityCriteriaFlag:
                  docData.material_sustainability_criteria_flag,
                nuts2Region: docData.nuts2_region,
                producerInstallationDate: docData.producer_installation_date,
                productEnergyContent: parseFloat(
                  docData.product_energy_content,
                ),
                productQty: parseFloat(docData.product_qty),
                productQtyUom: docData.product_qty_uom,
                productType: docData.product_type,
                rawMaterialOriginCountryName:
                  docData.raw_material_origin_country_name,
                rawMaterialType: docData.raw_material_type,
                rawMaterialWasteOrResidueFlag:
                  docData.raw_material_waste_or_residue_flag,
                recipientAddress: docData.recipient_address,
                recipientName: docData.recipient_name,
                recipientReceiptAddress: docData.recipient_receipt_address,
                sdNumberNew: docData.sd_number,
                sdNumberOld,
                supplierAddress: docData.supplier_address,
                supplierDispatchAddress: docData.supplier_dispatch_address,
                supplierName: docData.supplier_name,
                userInstallationDate: docData.user_installation_date,
                mbBalanceGroupId: docData.balance_group_name,
                mbLocationGroupId: docData.location_group_name,
              },
            },
          }
        : {
            event: {
              divisionId: DIVISION_ID,
              siteReferenceId: siteRefId[country],
              userAction,
              userId: account.username,
              certificateInboundEuId: docId,
              additionalNotes: docData.additional_notes,
              rejectionReason: docData.rejection_reason,
              replacePreviousVersion: docData.replace_previous_version,
              previousVersionId: docData.previous_version_sd_number,
            },
          };
    await updateSafDocumentMutation({
      variables,
    });
  };

  if (
    loading ||
    siteReferenceData?.siteReferenceId === undefined ||
    divisionData?.divisionId === undefined
  ) {
    return (
      <div
        className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100"
        style={{
          height: "calc(100vh - 352px)",
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 saf-document">
      <Modal size="sm" isOpen={acceptModal} className="modal-dialog-centered">
        <ModalBody className="text-center">
          <p className="mb-[2px]">
            {`Confirm ${status === "ready_to_assign" ? "update" : "accept"}`}
          </p>
          <p className="text-[13px] text-gray-800 mb-0">
            {`Are you sure you want to ${
              status === "ready_to_assign" ? "update" : "accept"
            } this document?`}
          </p>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setAcceptModal(false);
                }}
                disabled={submitLoading}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
                onClick={() => onSubmit("accept")}
                disabled={submitLoading}
              >
                {submitLoading && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                {status === "ready_to_assign" ? "Update" : "Accept"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle(status)}
            breadcrumbs={breadcrumbItems}
            subtitle={
              status !== "completed" ? (
                <Subtitle
                  className="px-7"
                  status={status}
                  errors={errors}
                  onClick={(tab) => {
                    handleErrorMessageClick(tab);
                  }}
                  formData={values}
                />
              ) : (
                <span />
              )
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-8">
                  <FormGroup>
                    <Label for="sd_number" className="fw-normal mb-[12px]">
                      Unique number of the PoS
                    </Label>
                    <Input
                      type="text"
                      id="sd_number"
                      {...computeProps("sd_number", {
                        required: "Please enter a sd number",
                      })}
                      invalid={!!errors.sd_number}
                      maxLength={100}
                      disabled={isDisabled}
                    />
                    {errors.sd_number &&
                      errors.sd_number.type !== "required" && (
                        <FormFeedback className="mt-0">
                          {errors.sd_number.message}
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="date_of_issuance" className="fw-normal mb-4">
                      Date of issuance
                    </Label>
                    <Datepicker
                      id="date_of_issuance"
                      name="date_of_issuance"
                      control={control}
                      error={errors.date_of_issuance}
                      rules={{required: "Please enter date of issuance"}}
                      disabled={isDisabled}
                      format="d/m/Y"
                    />
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="supplier"
                        className="flex items-center"
                      >
                        {hasErrors && invalidTabs.supplier && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Supplier and recipient
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="supplier" className="mb-4">
                        <div className="flex flex-col gap-5 w-full">
                          <FormGroup>
                            <Label
                              for="supplier_name"
                              className="fw-normal mb-4"
                            >
                              Name of supplier
                            </Label>
                            <Input
                              type="text"
                              id="supplier_name"
                              {...computeProps("supplier_name", {
                                required: "Please enter name of supplier",
                              })}
                              data-test="supplier_name"
                              invalid={!!errors.supplier_name}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.supplier_name && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_name.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="supplier_address"
                              className="fw-normal mb-4"
                            >
                              Address of supplier
                            </Label>
                            <Input
                              type="text"
                              id="supplier_address"
                              {...computeProps("supplier_address", {
                                required: "Please enter address of supplier",
                              })}
                              data-test="supplier_address"
                              invalid={!!errors.supplier_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.supplier_address && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_address?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="certification_system"
                              className="fw-normal mb-4"
                            >
                              Certification system
                            </Label>
                            <Input
                              type="select"
                              id="certification_system"
                              data-test="certification_system"
                              {...computeProps("certification_system", {
                                required:
                                  "Please select a certification system",
                              })}
                              invalid={!!errors.certification_system}
                              disabled={isDisabled}
                            >
                              <option value="ISCC EU">ISCC EU</option>
                            </Input>
                            {errors.certification_system && (
                              <FormFeedback className="mt-2">
                                {errors.certification_system?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="certificate_number"
                              className="fw-normal mb-4"
                            >
                              Certificate number
                            </Label>
                            <Input
                              type="text"
                              id="certificate_number"
                              data-test="certificate_number"
                              {...computeProps("certificate_number", {
                                required: "Please enter certificate number",
                              })}
                              invalid={!!errors.certificate_number}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.certificate_number ? (
                              <FormFeedback className="mt-2">
                                {errors.certificate_number.message}
                              </FormFeedback>
                            ) : (
                              <span className="text-xs text-gray-700">
                                Valid certificate
                              </span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_name"
                              className="fw-normal mb-4"
                            >
                              Name of recipient
                            </Label>
                            <Input
                              type="text"
                              id="recipient_name"
                              data-test="recipient_name"
                              {...computeProps("recipient_name", {
                                required: "Please enter name of recipient",
                              })}
                              invalid={!!errors.recipient_name}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.recipient_name && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_name.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_address"
                              className="fw-normal mb-4"
                            >
                              Address of recipient
                            </Label>
                            <Input
                              type="text"
                              id="recipient_address"
                              data-test="recipient_address"
                              {...computeProps("recipient_address", {
                                required: "Please enter address of recipient",
                              })}
                              invalid={!!errors.recipient_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.recipient_address && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="contract_number"
                              className="fw-normal mb-4"
                            >
                              Contract number
                            </Label>
                            <Input
                              type="text"
                              id="contract_number"
                              data-test="contract_number"
                              {...computeProps("contract_number", {
                                required: "Please enter contact number",
                              })}
                              invalid={!!errors.contract_number}
                              maxLength={50}
                              disabled={isDisabled}
                            />
                            {errors.contract_number && (
                              <FormFeedback className="mt-2">
                                {errors.contract_number.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="supplier_dispatch_address"
                              className="fw-normal mb-4"
                            >
                              Address of dispatch
                            </Label>
                            <Input
                              type="text"
                              id="supplier_dispatch_address"
                              data-test="supplier_dispatch_address"
                              {...computeProps("supplier_dispatch_address", {
                                required: "Please enter address of dispatch",
                              })}
                              invalid={!!errors.supplier_dispatch_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.supplier_dispatch_address && (
                              <FormFeedback className="mt-2">
                                {errors.supplier_dispatch_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="recipient_receipt_address"
                              className="fw-normal mb-4"
                            >
                              Address of receipt/receiving point of the
                              sustainable material
                            </Label>
                            <Input
                              type="text"
                              id="recipient_receipt_address"
                              data-test="recipient_receipt_address"
                              {...computeProps("recipient_receipt_address", {
                                required:
                                  "Please enter address of receipt/receiving point of the sustainable material",
                              })}
                              invalid={!!errors.recipient_receipt_address}
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.recipient_receipt_address && (
                              <FormFeedback className="mt-2">
                                {errors.recipient_receipt_address.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="material_dispatch_datez"
                              className="fw-normal mb-4"
                            >
                              Date of dispatch
                            </Label>
                            <Datepicker
                              id="material_dispatch_datez"
                              name="material_dispatch_datez"
                              control={control}
                              error={errors.material_dispatch_datez}
                              rules={{
                                required: "Please enter date of dispatch",
                              }}
                              format="d/m/Y"
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <div className="flex">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_producer"
                                className="fw-normal mb-4"
                              >
                                Producer of biofuel/bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_producer"
                                    data-test="bio_product_producer"
                                    {...computeProps("bio_product_producer")}
                                    invalid={!!errors.bio_product_producer}
                                    disabled={isDisabled}
                                  />
                                  <Label check for="bio_product_producer">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="producer_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-21px]">
                                <Datepicker
                                  id="producer_installation_date"
                                  name="producer_installation_date"
                                  control={control}
                                  error={errors.producer_installation_date}
                                  disabled={isDisabled}
                                  format="d/m/Y"
                                />
                              </span>
                            </FormGroup>
                          </div>
                          <div className="flex">
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="bio_product_user"
                                className="fw-normal mb-4"
                              >
                                User of bioliquid/biomass fuel
                              </Label>
                              <div className="h-full flex">
                                <FormGroup
                                  check
                                  inline
                                  className="form-check-alt form-check-lg !mb-0"
                                >
                                  <Input
                                    type="checkbox"
                                    id="bio_product_user"
                                    data-test="bio_product_user"
                                    {...computeProps("bio_product_user")}
                                    invalid={!!errors.bio_product_user}
                                    disabled={isDisabled}
                                  />
                                  <Label check for="bio_product_user">
                                    Yes
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                            <FormGroup className="flex flex-col flex-1">
                              <Label
                                for="user_installation_date"
                                className="fw-normal"
                              >
                                Date of installation
                              </Label>
                              <span className="mt-[-21px]">
                                <Datepicker
                                  id="user_installation_date"
                                  name="user_installation_date"
                                  control={control}
                                  error={errors.producer_installation_date}
                                  disabled={isDisabled}
                                  format="d/m/Y"
                                />
                              </span>
                            </FormGroup>
                          </div>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader
                        targetId="general"
                        className="flex items-center"
                      >
                        {hasErrors && invalidTabs.general && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          General information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="general" className="mb-4">
                        <div className="flex flex-col gap-5 w-full">
                          <FormGroup>
                            <Label
                              for="product_type"
                              className="fw-normal mb-4"
                            >
                              Type of product
                            </Label>
                            <Input
                              type="select"
                              id="product_type"
                              data-test="product_type"
                              {...computeProps("product_type", {
                                required: "Please enter type of product",
                              })}
                              invalid={!!errors.product_type}
                              maxLength={100}
                              disabled={isDisabled}
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>
                              {productTypeItems.map(
                                (productTypeItem, index) => (
                                  <option
                                    key={index}
                                    value={productTypeItem.productName}
                                  >
                                    {productTypeItem.productName}
                                  </option>
                                ),
                              )}
                            </Input>
                            {errors.product_type && (
                              <FormFeedback className="mt-2">
                                {errors.product_type.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_type"
                              className="fw-normal mb-4"
                            >
                              Type of raw material
                            </Label>

                            <Input
                              type="select"
                              id="raw_material_type"
                              data-test="raw_material_type"
                              {...computeProps("raw_material_type", {
                                required: "Please enter type of raw material",
                              })}
                              invalid={!!errors.raw_material_type}
                              maxLength={100}
                              disabled={isDisabled}
                              placeholder="Please choose from list"
                            >
                              <option value="">Please choose from list</option>

                              {rawMaterialTypes.length > 0 &&
                                rawMaterialTypes.map((p, i) => (
                                  <option key={i} value={p.rawMaterialName}>
                                    {p.rawMaterialName}
                                  </option>
                                ))}
                            </Input>

                            {errors.raw_material_type && (
                              <FormFeedback className="mt-2">
                                {errors.raw_material_type.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="additional_info"
                              className="fw-normal mb-4"
                            >
                              Additional information (voluntary)
                            </Label>
                            <Input
                              type="textarea"
                              rows="3"
                              id="additional_info"
                              data-test="additional_info"
                              {...computeProps("additional_info")}
                              invalid={!!errors.additional_info}
                              maxLength={250}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_origin_country_name"
                              className="fw-normal mb-4"
                            >
                              Country of origin of the origin raw material
                            </Label>
                            <Input
                              type="text"
                              id="raw_material_origin_country_name"
                              data-test="raw_material_origin_country_name"
                              {...computeProps(
                                "raw_material_origin_country_name",
                                {
                                  required: "Please enter contact number",
                                },
                              )}
                              invalid={
                                !!errors.raw_material_origin_country_name
                              }
                              maxLength={100}
                              disabled={isDisabled}
                            />
                            {errors.raw_material_origin_country_name && (
                              <FormFeedback className="mt-2">
                                {
                                  errors.raw_material_origin_country_name
                                    .message
                                }
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="product_qty" className="fw-normal mb-4">
                              Quantity
                            </Label>
                            <Input
                              type="number"
                              id="product_qty"
                              data-test="product_qty"
                              {...computeProps("product_qty", {
                                required: "Please enter quantity",
                                pattern: {
                                  value: /^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point)",
                                },
                              })}
                              rules={{
                                validate: (value) =>
                                  value > 0
                                    ? true
                                    : "Please enter a valid quantity",
                              }}
                              invalid={!!errors.product_qty}
                              disabled={isDisabled}
                            />

                            {errors.product_qty && (
                              <FormFeedback className="mt-2">
                                {errors.product_qty.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="product_qty_uom" className="fw-normal">
                              Unit of measure
                            </Label>
                            <UnitOfMeasure
                              id="product_qty_uom"
                              name="product_qty_uom"
                              control={control}
                              error={errors.product_qty_uom}
                              disabled={isDisabled}
                              rules={{
                                validate: (value) =>
                                  value === null || value === ""
                                    ? "Please select unit of measure"
                                    : true,
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="product_energy_content"
                              className="fw-normal mb-4"
                            >
                              Energy content (MJ)
                            </Label>
                            <Input
                              type="number"
                              id="product_energy_content"
                              data-test="product_energy_content"
                              {...computeProps("product_energy_content", {
                                required: "Please enter quantity",
                              })}
                              rules={{
                                validate: (value) =>
                                  value > 0
                                    ? true
                                    : "Please enter a valid quantity",
                              }}
                              invalid={!!errors.product_energy_content}
                              disabled={isDisabled}
                            />

                            {errors.product_energy_content && (
                              <FormFeedback className="mt-2">
                                {errors.product_energy_content.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup className="flex flex-col">
                            <Label
                              for="eu_red_compliant_flag"
                              className="fw-normal"
                            >
                              EU RED Compliant material
                            </Label>
                            <FormGroup
                              check
                              inline
                              className="form-check-alt form-check-lg !mb-0"
                            >
                              <Input
                                type="checkbox"
                                id="eu_red_compliant_flag"
                                data-test="eu_red_compliant_flag"
                                {...computeProps("eu_red_compliant_flag", {
                                  required:
                                    !isDisabled &&
                                    "EU RED Compliant material is required.",
                                })}
                                invalid={!!errors.eu_red_compliant_flag}
                                disabled={isDisabled}
                              />
                              <Label for="eu_red_compliant_flag">Yes</Label>
                            </FormGroup>
                            {errors.eu_red_compliant_flag && (
                              <FormFeedback className="mt-2 !block">
                                {errors.eu_red_compliant_flag.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup className="flex flex-col">
                            <Label
                              for="iscc_compliant_flag"
                              className="fw-normal"
                            >
                              ISCC Compliant material (voluntary)
                            </Label>
                            <FormGroup
                              check
                              inline
                              className="form-check-alt form-check-lg !mb-0"
                            >
                              <Input
                                type="checkbox"
                                id="iscc_compliant_flag"
                                data-test="iscc_compliant_flag"
                                {...computeProps("iscc_compliant_flag")}
                                invalid={!!errors.iscc_compliant_flag}
                                disabled={isDisabled}
                              />
                              <Label for="iscc_compliant_flag">Yes</Label>
                            </FormGroup>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="chain_of_custody_option"
                              className="fw-normal mb-4"
                            >
                              Chain of custody option (voluntary)
                            </Label>
                            <Input
                              type="select"
                              id="chain_of_custody_option"
                              data-test="chain_of_custody_option"
                              {...computeProps("chain_of_custody_option")}
                              invalid={!!errors.chain_of_custody_option}
                              disabled={isDisabled}
                            >
                              <option value="">Please select</option>
                              {chainOfCustody.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader targetId="certification">
                        {hasErrors && invalidTabs.certification && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Scope of certification of raw material
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-4"
                      >
                        <div className="flex flex-col gap-5 w-full">
                          <FormGroup>
                            <Label
                              for="material_sustainability_criteria_flag"
                              className="fw-normal"
                            >
                              The raw material complies with the relevant
                              sustainability criteria according to Art. 29 (2) -
                              (7) RED II
                            </Label>
                            <CheckboxFlag
                              id="material_sustainability_criteria_flag"
                              name="material_sustainability_criteria_flag"
                              control={control}
                              error={
                                errors.material_sustainability_criteria_flag
                              }
                              disabled={isDisabled}
                              rules={{
                                validate: (value) => {
                                  return (
                                    value !== null || "Please select an option"
                                  );
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="agricultural_biomass_intermediate_crop_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass was cultivated as
                              intermediate crop (if applicable)
                            </Label>
                            <CheckboxFlag
                              id="agricultural_biomass_intermediate_crop_flag"
                              name="agricultural_biomass_intermediate_crop_flag"
                              control={control}
                              error={
                                errors.agricultural_biomass_intermediate_crop_flag
                              }
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="agricultural_biomass_low_risk_flag"
                              className="fw-normal mb-4"
                            >
                              The agricultural biomass additionally fulfills the
                              measures for low ILUC risk feedstocks (if
                              applicable)
                            </Label>
                            <CheckboxFlag
                              id="agricultural_biomass_low_risk_flag"
                              name="agricultural_biomass_low_risk_flag"
                              control={control}
                              error={errors.agricultural_biomass_low_risk_flag}
                              disabled={isDisabled}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="raw_material_waste_or_residue_flag"
                              className="fw-normal mb-4"
                            >
                              The raw material meets the definition of waste or
                              residue according to the RED II
                            </Label>
                            <CheckboxFlag
                              id="raw_material_waste_or_residue_flag"
                              name="raw_material_waste_or_residue_flag"
                              control={control}
                              error={errors.raw_material_waste_or_residue_flag}
                              disabled={isDisabled}
                              rules={{
                                validate: (value) => {
                                  return (
                                    value !== null || "Please select an option"
                                  );
                                },
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="nuts2_region"
                              className="fw-normal mb-4"
                            >
                              If applicable, please specify NUTS 2 region
                            </Label>
                            <Input
                              type="text"
                              id="nuts2_region"
                              data-test="nuts2_region"
                              {...computeProps("nuts2_region")}
                              invalid={!!errors.nuts2_region}
                              disabled={isDisabled}
                              maxLength={100}
                            />
                            {errors.nuts2_region && (
                              <FormFeedback className="mt-2">
                                {errors.nuts2_region.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader targetId="emission">
                        {hasErrors && invalidTabs.emission && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Greenhouse gas (GHG) emission information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="emission" className="mb-4">
                        <div className="flex flex-col gap-5 w-full">
                          <FormGroup>
                            <Label
                              for="default_value_applied_flag"
                              className="fw-normal mb-4"
                            >
                              Total default value according to RED II applied
                            </Label>
                            <CheckboxFlag
                              id="default_value_applied_flag"
                              name="default_value_applied_flag"
                              control={control}
                              error={errors.default_value_applied_flag}
                              disabled={isDisabled}
                              rules={{
                                validate: (value) => {
                                  return (
                                    value !== null || "Please select an option"
                                  );
                                },
                              }}
                            />
                          </FormGroup>
                          <div className="flex flex-row justify-between items-start gap-3">
                            <FormGroup>
                              <Label for="ghg_eec" className="fw-normal">
                                Eec
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eec"
                                data-test="ghg_eec"
                                {...computeProps("ghg_eec", numberInputPattern)}
                                invalid={!!errors.ghg_eec}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eec && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eec?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_el" className="fw-normal">
                                El
                              </Label>
                              <Input
                                type="number"
                                id="ghg_el"
                                data-test="ghg_el"
                                {...computeProps("ghg_el", numberInputPattern)}
                                invalid={!!errors.ghg_el}
                                disabled={isDisabled}
                              />
                              {errors.ghg_el && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_el?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_ep" className="fw-normal">
                                Ep
                              </Label>
                              <Input
                                type="number"
                                id="ghg_ep"
                                data-test="ghg_ep"
                                {...computeProps("ghg_ep", numberInputPattern)}
                                invalid={!!errors.ghg_ep}
                                disabled={isDisabled}
                              />
                              {errors.ghg_ep && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_ep?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_etd" className="fw-normal">
                                Etd
                              </Label>
                              <Input
                                type="text"
                                id="ghg_etd"
                                data-test="ghg_etd"
                                {...computeProps("ghg_etd", {
                                  pattern: {
                                    value: /^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                    message:
                                      "Value must be number with (max 10 digits before and 5 digits after decimal point) or DDV",
                                  },
                                })}
                                invalid={!!errors.ghg_etd}
                                disabled={isDisabled}
                              />
                              {errors.ghg_etd && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_etd?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eu" className="fw-normal">
                                Eu
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eu"
                                data-test="ghg_eu"
                                {...computeProps("ghg_eu", numberInputPattern)}
                                invalid={!!errors.ghg_eu}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eu && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eu?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_esca" className="fw-normal">
                                Esca
                              </Label>
                              <Input
                                type="number"
                                id="ghg_esca"
                                data-test="ghg_esca"
                                {...computeProps(
                                  "ghg_esca",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_esca}
                                disabled={isDisabled}
                              />
                              {errors.ghg_esca && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_esca?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccs" className="fw-normal">
                                Eccs
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eccs"
                                data-test="ghg_eccs"
                                {...computeProps(
                                  "ghg_eccs",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_eccs}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eccs && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eccs?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                            <FormGroup>
                              <Label for="ghg_eccr" className="fw-normal">
                                Eccr
                              </Label>
                              <Input
                                type="number"
                                id="ghg_eccr"
                                data-test="ghg_eccr"
                                {...computeProps(
                                  "ghg_eccr",
                                  numberInputPattern,
                                )}
                                invalid={!!errors.ghg_eccr}
                                disabled={isDisabled}
                              />
                              {errors.ghg_eccr && (
                                <FormFeedback className="mt-2">
                                  {errors.ghg_eccr?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          </div>
                          {errors.ghg_no_value && (
                            <FormFeedback className="!mt-[-27px] !block">
                              {errors.ghg_no_value.message}
                            </FormFeedback>
                          )}
                          <FormGroup>
                            <Label for="ghg_total" className="fw-normal mb-4">
                              Total GHG emissions (gCO2eq/MJ)
                            </Label>
                            <Input
                              type="text"
                              id="ghg_total"
                              data-test="ghg_total"
                              {...computeProps("ghg_total", {
                                required: "Please enter total GHG emissions",
                                validate: {
                                  someValue: (value) => {
                                    return (
                                      [
                                        /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                      ].every((pattern) =>
                                        pattern.test(value),
                                      ) ||
                                      "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV"
                                    );
                                  },
                                  notZero: (value) => {
                                    return (
                                      [/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/].every(
                                        (pattern) => pattern.test(value),
                                      ) ||
                                      "GHG total must be greater than zero."
                                    );
                                  },
                                },
                              })}
                              invalid={!!errors.ghg_total}
                              disabled={isDisabled}
                            />
                            {errors.ghg_total && (
                              <FormFeedback className="mt-2">
                                {errors.ghg_total?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="allocated_heat"
                              className="fw-normal mb-4"
                            >
                              Allocated heat (gCO2eq/MJ heat)
                            </Label>
                            <Input
                              type="text"
                              id="allocated_heat"
                              data-test="allocated_heat"
                              {...computeProps("allocated_heat", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                              })}
                              invalid={!!errors.allocated_heat}
                              disabled={isDisabled}
                            />
                            {errors.allocated_heat && (
                              <FormFeedback className="mt-2">
                                {errors.allocated_heat?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="allocated_electricity"
                              className="fw-normal mb-4"
                            >
                              Allocated electricity (gCO2eq/MJ electricity)
                            </Label>
                            <Input
                              type="text"
                              id="allocated_electricity"
                              data-test="allocated_electricity"
                              {...computeProps("allocated_electricity", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                              })}
                              invalid={!!errors.allocated_electricity}
                              disabled={isDisabled}
                            />
                            {errors.allocated_electricity && (
                              <FormFeedback className="mt-2">
                                {errors.allocated_electricity?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="ghg_emission_percentage_in"
                              className="fw-normal mb-4"
                            >
                              GHG emission saving (%)
                            </Label>
                            <Input
                              type="text"
                              id="ghg_emission_percentage_in"
                              data-test="ghg_emission_percentage_in"
                              {...computeProps("ghg_emission_percentage_in", {
                                pattern: {
                                  value:
                                    /^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                                  message:
                                    "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV",
                                },
                                required: "Please enter GHG emission savings",
                              })}
                              invalid={!!errors.ghg_emission_percentage_in}
                              disabled={isDisabled}
                            />
                            <span className="text-xs text-gray-700">
                              Biofuels for transport
                            </span>
                            {errors.ghg_emission_percentage_in && (
                              <FormFeedback className="mt-2">
                                {errors.ghg_emission_percentage_in?.message}
                              </FormFeedback>
                            )}
                          </FormGroup>
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader targetId="additionalInfo">
                        {hasErrors && invalidTabs.additionalInfo && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          About this document
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-4"
                      >
                        <div className="flex flex-col gap-5 w-full">
                          <FormGroup>
                            <Label
                              for="location_group_name"
                              className="fw-normal mb-4"
                            >
                              Location
                            </Label>
                            <Input
                              type="select"
                              id="location_group_name"
                              data-test="location_group_name"
                              {...computeProps("location_group_name", {
                                required:
                                  "Please select the location group based on the receiving point",
                              })}
                              invalid={!!errors.location_group_name}
                              disabled={
                                status === "rejected" ||
                                locationGroupNames.length === 1
                              }
                            >
                              <option value="">Please select</option>
                              {locationGroupNames.map((item) => (
                                <option
                                  key={item?.locationGroupId}
                                  value={item?.locationGroupId}
                                >
                                  {item?.locationGroupName}
                                </option>
                              ))}
                            </Input>
                            {errors.location_group_name ? (
                              <FormFeedback className="mt-2">
                                {errors.location_group_name?.message}
                              </FormFeedback>
                            ) : (
                              <span className="text-xs text-gray-700">
                                Location group identified based on the receiving
                                point
                              </span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="balance_group_name"
                              className="fw-normal mb-4"
                            >
                              Balance
                            </Label>
                            <Input
                              type="select"
                              id="balance_group_name"
                              data-test="balance_group_name"
                              {...computeProps("balance_group_name", {
                                required:
                                  "Please select the balance group based on the material",
                              })}
                              invalid={!!errors.balance_group_name}
                              disabled={
                                status === "rejected" ||
                                balanceGroupNames.length === 1
                              }
                            >
                              <option value="">Please select</option>
                              {balanceGroupNames.map((item) => (
                                <option
                                  key={item?.balanceGroupId}
                                  value={item?.balanceGroupId}
                                >
                                  {item?.balanceGroupName}
                                </option>
                              ))}
                            </Input>
                            {errors.balance_group_name ? (
                              <FormFeedback className="mt-2">
                                {errors.balance_group_name?.message}
                              </FormFeedback>
                            ) : (
                              <span className="text-xs text-gray-700">
                                Balance group identified based on the material
                              </span>
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label
                              for="replace_previous_version"
                              className="fw-normal mb-4"
                            >
                              Does this document replace any previous version?
                            </Label>
                            <CustomRadioButton
                              type="radio"
                              control={control}
                              name="replace_previous_version"
                              error={errors.replace_previous_version}
                              disabled={status === "rejected"}
                              selectedValue={setShowPrevVersion}
                            />
                          </FormGroup>
                          {showPrevVersion && (
                            <FormGroup>
                              <Label
                                for="previous_version_sd_number"
                                className="fw-normal mb-4"
                              >
                                Select a previous version
                              </Label>
                              <Input
                                type="select"
                                id="previous_version_sd_number"
                                data-test="previous_version_sd_number"
                                {...computeProps("previous_version_sd_number", {
                                  required:
                                    "Please select a certification system",
                                })}
                                invalid={!!errors.previous_version_sd_number}
                                disabled={status === "rejected"}
                              >
                                <option value="">
                                  Please select a previous version
                                </option>
                                {data?.bioLcGetVersioningDocsApi?.documents?.map(
                                  (doc) => (
                                    <option
                                      key={doc.sdNumber}
                                      value={doc.sdNumber}
                                    >
                                      {doc.sdNumber}
                                    </option>
                                  ),
                                )}
                              </Input>
                              {errors.previous_version_sd_number && (
                                <FormFeedback className="mt-2">
                                  {errors.previous_version_sd_number?.message}
                                </FormFeedback>
                              )}
                            </FormGroup>
                          )}
                        </div>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    {status === "rejected" && (
                      <Button
                        color={
                          status === "rejected"
                            ? "tertiary"
                            : "standard-primary"
                        }
                        className={
                          status === "rejected"
                            ? "show rounded-none"
                            : "primary-btn rounded-none"
                        }
                        type="button"
                        onClick={() =>
                          navigate({
                            pathname: saf_urls[`${country}DocumentPage`],
                          })
                        }
                      >
                        <TailLeft24 className="btn-icon-prefix" />
                        Back
                      </Button>
                    )}
                    {status !== "rejected" && (
                      <>
                        <Button
                          color="tertiary"
                          className="show btn-bg-br-gray"
                          style={{
                            borderRadius: "0px",
                          }}
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                          }}
                          disabled={rejecting}
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Reject
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary btn-bg-br-black"
                          style={{
                            borderRadius: "0px",
                          }}
                          onClick={() => {
                            setAcceptModal(true);
                          }}
                          disabled={
                            Object.keys(errors).length !== 0 || rejecting
                          }
                        >
                          <Check24 className="btn-icon-prefix" />
                          {status === "ready_to_assign" ? "Update" : "Accept"}
                        </Button>
                      </>
                    )}
                  </div>
                  {rejecting && (
                    <>
                      <FormGroup>
                        <Label
                          for="rejection_reason"
                          className="fw-normal mb-4"
                        >
                          Why are you rejecting?
                        </Label>

                        <Input
                          type="select"
                          id="rejection_reason"
                          data-test="rejection_reason"
                          {...computeProps("rejection_reason", {
                            required: "Please select reject reason",
                          })}
                          invalid={!!errors.rejection_reason}
                          maxLength={100}
                          placeholder="Please select a reason"
                          onChange={(e) => {
                            handleRejectMessage(e.target.value);
                          }}
                        >
                          <option value="">Please select a reason</option>

                          {rejectReasons.length > 0 &&
                            rejectReasons.map((p) => (
                              <option key={p.key} value={p.key}>
                                {p.value}
                              </option>
                            ))}
                        </Input>

                        {errors.rejection_reason && (
                          <FormFeedback className="mt-2">
                            {errors.rejection_reason.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      <FormGroup>
                        <Label
                          for="additional_notes"
                          className="fw-normal mb-4"
                        >
                          Additional notes (Optional)
                        </Label>
                        <Input
                          type="textarea"
                          rows="3"
                          id="additional_notes"
                          data-test="additional_notes"
                          {...computeProps("additional_notes")}
                          invalid={!!errors.additional_notes}
                          maxLength={250}
                        />
                      </FormGroup>
                      <span className="mt-[-40px]">{rejectMessage}</span>
                      <div className="flex flex-row justify-end items-center gap-4">
                        <Button
                          color="tertiary"
                          className="show btn-bg-br-gray"
                          style={{
                            borderRadius: "0px",
                          }}
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                            clearErrors("rejection_reason");
                          }}
                          disabled={!rejecting}
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary btn-bg-br-black"
                          style={{
                            borderRadius: "0px",
                          }}
                          onClick={() => onSubmit("reject")}
                          disabled={errors.rejection_reason}
                        >
                          {submitLoading ? (
                            <Spinner size="sm" className="btn-icon-prefix" />
                          ) : (
                            <Check24 className="btn-icon-prefix" />
                          )}
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer document={documentFile} />
      </div>
    </div>
  );
};

export default SAFDocumentViewPage;
