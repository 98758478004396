import {ReactTable} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const RTable = ({columns, data, tableName}) => {
  return (
    <div>
      <div
        className="flex flex-col justify-between px-4 py-4 mt-8 bg-[#fff] border-b border-b-neutral-200 gap-y-2 lg:flex-row text-[13px] font-bold"
        data-test="table-header"
      >
        {tableName}
      </div>
      <div className="bp-core">
        <div className="bp-component-table">
          <ReactTable
            showPaginationTop={false}
            responsive
            columns={columns}
            data={data}
            tableClassName="dashboard-table table-column-sticky header-color"
          />
        </div>
      </div>
    </div>
  );
};

RTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  tableName: PropTypes.string.isRequired,
};

export default RTable;
