import {gql} from "@apollo/client";

export const RULE_VERSION_TEST = gql`
  query ExecuteVersionTestQuery($ruleVersionId: String) {
    bioLcComplianceRulesEngineTestExecute(
      event: {ruleVersionId: $ruleVersionId}
    ) {
      schema {
        column
        type
      }
      statusCode
      error
      executionResultDownloadLink
    }
  }
`;

export default {RULE_VERSION_TEST};
