import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import PropTypes from "prop-types";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const UploadErrorModal = ({fileName, countryCode}) => {
  const isProcessing = false;
  const [isModalVisible, setModalVisible] = useState(true);
  const navigate = useNavigate();

  return (
    <div>
      <Modal
        size="md"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader className="border-b-2 border-gray-200 mb-2 text-xl">
          Document not recognised
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <div>
            <span>
              <br></br>
              <p>It appears this document is not one can currently read.</p>
              <br></br>
              <p>
                We’ll add to our backlog for future instances, but for now you
                will need to enter content manually.
              </p>
            </span>
          </div>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 opacity-80 opacity-100-hover bg-transparent"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 py-4 bg-transparent text-default"
                form="upload-form"
                type="submit"
                disabled={isProcessing}
                onClick={() =>
                  navigate(
                    `/copro-spain/spain/document-manager/incoming-documents/manual/${encodeURIComponent(
                      fileName,
                    )}/${encodeURIComponent(countryCode)}`,
                  )
                }
              >
                {isProcessing && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                Enter manually
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

UploadErrorModal.propTypes = {
  fileName: PropTypes.string,
  countryCode: PropTypes.string,
};

export default UploadErrorModal;
