import tw from "twin.macro";

export const StyledHeader = tw.div`whitespace-nowrap justify-center items-center h-3 pb-8`;

export const BaseGrayWrapper = tw.div`
  py-4 bg-gray-50 
  border border-solid rounded

  [box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47)]
`;
