import moment from "moment";
import DetailedModal from "modules/common/Table/ActionsMenu/DetailedModal";
import {updateManualAdjustmentApi} from "graphql/gy-enablement/MOT/manualAdjustmentHelpers";
import {formatNumber} from "providers/userSettings";
import {editionFormSchema} from "../schemas/MOTManualAdjustment";
import ManualAdjustmentForm from "../components/MOT/ManualAdjustmentForm";
import {stringSorter, dateSorter, numberSorter} from "../../common/Table";

export const getmotManualAdjustmentColumns = (decimalFormat) => {
  const motManualAdjustmentColumns = [
    {
      title: "Company Code",
      dataIndex: "companyCode",
      key: "companyCode",
      width: 150,
      inputType: "input",
      ...stringSorter("companyCode"),
    },
    {
      title: "Fiscal Month Year",
      dataIndex: "fisc",
      key: "fisc",
      width: 150,
      inputType: "date",
      dateFormat: "MMM YYYY",
      render: (text) => moment(text).format("MMM YYYY"),
      ...dateSorter("fisc"),
    },
    {
      title: "Declaration In Month",
      dataIndex: "declaration",
      key: "declaration",
      width: 150,
      inputType: "date",
      dateFormat: "MMM YYYY",
      render: (text) => moment(text).format("MMM YYYY"),
      ...dateSorter("declaration"),
    },
    {
      title: "ED Group",
      dataIndex: "eDGroupName",
      key: "eDGroupName",
      width: 150,
      inputType: "input",
      ...stringSorter("eDGroupName"),
    },
    {
      title: "Commodity Code",
      dataIndex: "commodityCode",
      key: "commodityCode",
      width: 150,
      inputType: "number",
      ...numberSorter("commodityCode"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: "Load Plant",
      dataIndex: "loadPlantName",
      key: "loadPlantName",
      width: 150,
      inputType: "input",
      ...stringSorter("loadPlantName"),
    },
    {
      title: "Calc ED Qty",
      dataIndex: "calEDQty",
      key: "calEDQty",
      width: 150,
      inputType: "number",
      ...numberSorter("calEDQty"),
      render: (text) => formatNumber(text, decimalFormat),
    },
    {
      title: "Tax Law",
      dataIndex: "taxLaw",
      key: "taxLaw",
      width: 150,
      inputType: "input",
      ...stringSorter("taxLaw"),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 50,
      selected: true,
      allowEdit: false,
      showInPreview: false,
      inputType: "input",
      render: (_, record, index, currentDataSource) => {
        return (
          <DetailedModal
            tableState={{data: currentDataSource}}
            schema={editionFormSchema}
            mode="edit-row"
            record={record}
            formContent={<ManualAdjustmentForm data={record} />}
            onSubmit={async (values) => {
              const {
                data: {updateManualAdjustmentAurora: response},
              } = await updateManualAdjustmentApi(
                {id: record.id, ...values},
                decimalFormat,
              );
              return response;
            }}
            getModalTitle={(data) => `Edit Following Item`}
            getDataWithDates={(data) => ({
              ...data,
              declaration:
                data?.declaration && data?.declaration !== ""
                  ? moment(data.declaration, "YYYY-MM")
                  : "",
              fisc:
                data?.fisc && data?.fisc !== ""
                  ? moment(data.fisc, "YYYY-MM")
                  : "",
            })}
          />
        );
      },
    },
  ];

  return motManualAdjustmentColumns;
};

export const motManualAdjustmentExportColumns = [
  {
    title: "Company Code",
    dataIndex: "companyCode",
    key: "companyCode",
  },
  {
    title: "Fiscal Month Year",
    dataIndex: "fisc",
    key: "fisc",
  },
  {
    title: "Declaration In Month",
    dataIndex: "declaration",
    key: "declaration",
  },
  {
    title: "ED Group",
    dataIndex: "eDGroupName",
    key: "eDGroupName",
    ...stringSorter("eDGroupName"),
  },
  {
    title: "Commodity Code",
    dataIndex: "commodityCode",
    key: "commodityCode",
  },
  {
    title: "Load Plant",
    dataIndex: "loadPlantName",
    key: "loadPlantName",
  },
  {
    title: "Calc ED Qty",
    dataIndex: "calEDQty",
    key: "calEDQty",
  },
  {
    title: "UoM",
    dataIndex: "uoM",
    key: "uoM",
  },
  {
    title: "THG Relevant",
    dataIndex: "tHGRelevant",
    key: "tHGRelevant",
  },
  {
    title: "GnETS Relevant",
    dataIndex: "gnETSRelevant",
    key: "gnETSRelevant",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Tax Law",
    dataIndex: "taxLaw",
    key: "taxLaw",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Main Customs Office",
    dataIndex: "mainCustomsOffice",
    key: "mainCustomsOffice",
  },
];

export default motManualAdjustmentExportColumns;
