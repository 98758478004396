import moment from "moment";

const getFormattedDate = (cell, dateFormat) => {
  if (cell.getValue() === "-") {
    return cell.getValue();
  }
  return moment(cell.getValue(), "YYYY-MM-DD").format(dateFormat);
};

export const LOCATION_MASTER_COLUMNS = [
  {
    header: "Location name",
    accessorKey: "location_name",
    key: "location_name",
  },
  {
    header: "Legal entity",
    accessorKey: "legal_entity",
    key: "legal_entity",
  },
  {
    header: "Location description",
    accessorKey: "location_desc",
    key: "location_desc",
  },
  {
    header: "Location type",
    accessorKey: "location_type",
    key: "location_type",
  },
  {
    header: "Country code",
    accessorKey: "country_code",
    key: "country_code",
  },
];

export const LOCATION_DETAILS_COLUMNS = (dateFormat) => [
  {
    header: "Location name",
    accessorKey: "location_name",
    key: "location_name",
  },
  {
    header: "Mass balance cycle",
    accessorKey: "mass_balance_cycle",
    key: "mass_balance_cycle",
  },
  {
    header: "Certificate ID ISCC EU",
    accessorKey: "certificate_id_iscc_eu",
    key: "certificate_id_iscc_eu",
  },
  {
    header: "Certificate ID ISCC Plus",
    accessorKey: "certificate_id_iscc_plus",
    key: "certificate_id_iscc_plus",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "SAP Plant Reference",
    accessorKey: "sap_location_code",
    key: "sap_location_code",
  },
];

export const PRODUCT_DETAILS_COLUMNS = (dateFormat) => [
  {
    header: "Material short name",
    accessorKey: "material_short_name",
    key: "material_short_name",
  },
  {
    header: "Material long name",
    accessorKey: "material_long_name",
    key: "material_long_name",
  },
  {
    header: "ISSC description",
    accessorKey: "issc_desc",
    key: "issc_desc",
  },
  {
    header: "SAP Material Reference",
    accessorKey: "sap_material_code",
    key: "sap_material_code",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];
export const PRODUCT_DDV_DETAILS_COLUMNS = (dateFormat) => [
  {
    header: "Material short name",
    accessorKey: "materialShortName",
    key: "materialShortName",
  },
  {
    header: "Default value",
    accessorKey: "defaultValue",
    key: "defaultValue",
  },
  {
    header: "UoM Code",
    accessorKey: "uomCode",
    key: "uomCode",
  },
  {
    header: "Default Value Type",
    accessorKey: "defaultValueType",
    key: "defaultValueType",
  },
  {
    header: "Valid from",
    accessorKey: "validFrom",
    key: "validFrom",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "validTo",
    key: "validTo",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const PRODUCTION_BASELINE_EMISSIONS_COLUMNS = (dateFormat) => [
  {
    header: "Material short name",
    accessorKey: "material_short_name",
    key: "material_short_name",
  },
  {
    header: "Baseline emission",
    accessorKey: "baseline_emission",
    key: "baseline_emission",
  },
  {
    header: "Unit of measure",
    accessorKey: "uom_code",
    key: "uom_code",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const TRANSPORTATION_EMISSIONS_COLUMNS = (dateFormat) => [
  {
    header: "Sending location name",
    accessorKey: "sending_location_name",
    key: "sending_location_name",
  },
  {
    header: "Receiving location name",
    accessorKey: "receiving_location_name",
    key: "receiving_location_name",
  },
  {
    header: "Mode of transportation",
    accessorKey: "mot_name",
    key: "mot_name",
  },
  {
    header: "Emission factor",
    accessorKey: "emission_factor",
    key: "emission_factor",
  },
  {
    header: "Emission unit of measure",
    accessorKey: "emission_uom",
    key: "emission_uom",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const PRODUCTION_EMISSIONS_COLUMNS = (dateFormat) => [
  {
    header: "Location name",
    accessorKey: "location_name",
    key: "location_name",
  },
  {
    header: "Emission factor",
    accessorKey: "emission_factor",
    key: "emission_factor",
  },
  {
    header: "Emission UoM",
    accessorKey: "emission_uom",
    key: "emission_uom",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const LOCATION_YIELD_COLUMNS = (dateFormat) => [
  {
    header: "Location name",
    accessorKey: "location_name",
    key: "location_name",
  },
  {
    header: "Processing unit",
    accessorKey: "processing_unit_code",
    key: "processing_unit_code",
  },
  {
    header: "Material short name",
    accessorKey: "material_short_name",
    key: "material_short_name",
  },
  {
    header: "Yield type",
    accessorKey: "yield_type",
    key: "yield_type",
  },
  {
    header: "Yield ratio",
    accessorKey: "yield_ratio",
    key: "yield_ratio",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const MATERIAL_MASTER_COLUMNS = [
  {
    header: "Material short name",
    accessorKey: "material_short_name",
    key: "material_long_name",
  },
  {
    header: "Material long name",
    accessorKey: "material_long_name",
    key: "material_long_name",
  },
  {
    header: "Material type",
    accessorKey: "material_type",
    key: "material_type",
  },
  {
    header: "Material group",
    accessorKey: "material_group",
    key: "material_group",
  },
];

export const UOM_CONVERSION_COLUMNS = (dateFormat) => [
  {
    header: "Conversion type",
    accessorKey: "conversion_type",
    key: "conversion_type",
  },
  {
    header: "Factor",
    accessorKey: "factor",
    key: "factor",
  },
  {
    header: "Numerator",
    accessorKey: "numerator",
    key: "numerator",
  },
  {
    header: "Denominator",
    accessorKey: "denominator",
    key: "denominator",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];

export const TRANSPORTATION_LOSS_COLUMNS = (dateFormat) => [
  {
    header: "Location name",
    accessorKey: "location_name",
    key: "location_name",
  },
  {
    header: "Loss factor",
    accessorKey: "loss_factor",
    key: "loss_factor",
  },
  {
    header: "Unit of measure",
    accessorKey: "uom_code",
    key: "uom_code",
  },
  {
    header: "Valid from",
    accessorKey: "valid_from",
    key: "valid_from",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
  {
    header: "Valid to",
    accessorKey: "valid_to",
    key: "valid_to",
    cell: (cell) => getFormattedDate(cell, dateFormat),
  },
];
