function displayDateLabel(forecastDate) {
  const date = new Date();
  // needed because subtracting a month from a Date
  // doesn't return correctly on the 31st of the month
  date.setDate(1);
  date.setFullYear(forecastDate.year);
  date.setMonth(forecastDate.month - 1);

  const formattedDate = date.toLocaleString("en-US", {
    month: "short",
    year: "2-digit",
  });
  const [month, year] = formattedDate.split(" ");

  return `${month.toLocaleUpperCase()} ‘${year}`;
}

export default displayDateLabel;
