import React from "react";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import ArrowRight from "../../../assets/images/svg/arrow-right.svg";
import ErrorSettings from "../../../assets/images/svg/error-settings.svg";
import Error from "../../../assets/images/svg/error.svg";

import "./index.scss";

const CommonErrorPage = ({error, resetErrorBoundary}) => {
  toast.dismiss();
  const navigate = useNavigate();
  const handleHomeClick = () => {
    if (error) {
      resetErrorBoundary();
    }
    navigate("/");
  };
  return (
    <>
      <Helmet>
        <title>Something went wrong!</title>
      </Helmet>
      <div className="bp-error-page-not-found">
        <div className="bp-error-page-not-found-content-details">
          <div className="bp-error-page-not-found-content">
            <img src={Error} className="w-10 h-10" alt="Error"></img>
            <br></br>

            <label data-test="error-page-header" className="label-header-error">
              Oops! Something went wrong
            </label>
            <br></br>
            <p
              data-test="error-page-description"
              className="error-content-link"
            >
              If you need you can always{" "}
              <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5MzUzNjg3MDQwMCJ9/all">
                Contact Us
              </a>
              . Otherwise click below to get back!
            </p>
            <div className="bp-button-container">
              <button
                type="button"
                data-test="bp-error-page-home-button"
                onClick={handleHomeClick}
                className="link-home-button"
              >
                GO HOME
                <img src={ArrowRight} alt="GO HOME" />
              </button>
            </div>
          </div>
        </div>
        <div className="bp-error-page-not-found-content-image">
          <img src={ErrorSettings} alt="Error Settings" />
        </div>
      </div>
    </>
  );
};

CommonErrorPage.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func,
};

export default CommonErrorPage;
