import UK from "assets/images/svg/uk.svg";
import Netherlands from "assets/images/svg/netherlands.svg";
import Poland from "assets/images/svg/poland-flag.png";
import Luxembourg from "assets/images/svg/luxembourg-flag.png";

export const countryData = [
  {
    title: "United Kingdom",
    icon: UK,
    value: "UK",
  },
  // {
  //   title: "Netherlands",
  //   icon: Netherlands,
  //   value: "NL",
  // },
  {
    title: "Poland",
    icon: Poland,
    value: "PL",
  },
  // {
  //   title: "Luxembourg",
  //   icon: Luxembourg,
  //   value: "LU",
  // },
];

export default {countryData};
