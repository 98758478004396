import PropTypes from "prop-types";

export const checklistStatusPropTypes = {
  batch_created_status: PropTypes.number,
  shipment_linked_status: PropTypes.number,
  c14_linked_status: PropTypes.number,
  feedstock_allocated_status: PropTypes.number,
  // emts_submitted_status: PropTypes.number,
  // state_submitted_status: PropTypes.number,
  // state_final_uploaded_status: PropTypes.number,
};

export const batchStatusPropTypes = {
  ...checklistStatusPropTypes,
  completion_percentage: PropTypes.number,
};
