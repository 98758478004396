import React from "react";

import {useQuery} from "@apollo/client";
import {TRANSPORTATION_EMISSIONS_GET_QUERY} from "graphql/GlobalConfigCenter/TransportEmissions";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {TRANSPORTATION_EMISSIONS_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {TRANSPORTATION_EMISSIONS_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";

const TransportationEmissionsConfigPage = () => {
  const {data, loading} = useQuery(TRANSPORTATION_EMISSIONS_GET_QUERY);

  return (
    <Layout
      title={TRANSPORTATION_EMISSIONS_CONFIG_PAGE.title}
      description={TRANSPORTATION_EMISSIONS_CONFIG_PAGE.description}
      tableColumns={TRANSPORTATION_EMISSIONS_COLUMNS}
      tableData={
        loading
          ? []
          : data?.bioLcGetTransportationEmissionApi.transportationEmissionData
      }
    />
  );
};

export default TransportationEmissionsConfigPage;
