import TabIcon from "modules/common/Svg/Tab";
import {NavLink} from "react-router-dom";
import PropTypes from "prop-types";
import "./index.scss";

const Tabs = ({className = "", data}) => {
  return (
    <div role="tablist" className={["bp-tabs", className].join(" ")}>
      {data?.map(({label, ...itemProps}) => (
        <NavLink key={label} role="tab" end {...itemProps}>
          <TabIcon fill="inherit" />
          {label}
        </NavLink>
      ))}
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Tabs;
