import {useLazyQuery, useQuery} from "@apollo/client";
import {
  COPRO_US_SHIPMENTS_API_EDIT_MULTI_SHIPMENT_ID,
  COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
  COPRO_US_SHIPMENTS_API_SHIPMENT_ID,
} from "graphql/coprocessing/shipments";
import LoadingSpinner from "modules/co-processing/components/Shared/LoadingSpinner";
import DetailField from "modules/co-processing/components/ShipmentDetails/DetailField";
import {ERRORS} from "modules/co-processing/constants/coProcessing";
import {
  BUTTONS,
  DETAIL_HEADERS,
  MULTI_MODAL_FIELDS,
  SHIPMENT_SUMMARY_FIELDS_BODY,
  SHIPMENT_SUMMARY_FIELDS_HEADER,
  SHIPMENT_SUMMARY_LABELS,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import shipmentDataFormatter from "modules/co-processing/helpers/shipmentDataFormatter";
import PropTypes from "prop-types";
import {useMemo, useState} from "react";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import EditShipmentModal from "./EditModal";
import EditModalMulti from "./EditModalMulti";
import RemoveSplitPopover from "./RemoveSplitPopover";

const ShipmentSummary = ({id}) => {
  const [modal, setModal] = useState(false);
  const [modalMulti, setModalMulti] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [updateDetailsMulti, setUpdateDetailsMulti] = useState({});
  const [showPopover, setShowPopover] = useState(false);

  const {
    data: res,
    loading,
    refetch,
  } = useQuery(COPRO_US_SHIPMENTS_API_SHIPMENT_ID, {
    variables: {shipmentId: id},
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [editShipment, {data: shipmentData}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (shipmentData?.bioLcCoproUsShipmentsApi?.error) {
          return toast.error(ERRORS.FAILED_EDIT);
        }
        return refetch();
      },
    },
  );

  const [editShipmentMulti, {data: shipmentDataMulti}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_EDIT_MULTI_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (shipmentDataMulti?.bioLcCoproUsShipmentsApi?.error) {
          return toast.error(ERRORS.FAILED_EDIT);
        }
        return refetch();
      },
    },
  );

  const data = useMemo(
    () => res?.bioLcCoproUsShipmentsApi?.body?.shipment_details,
    [res],
  );

  const hasRemoveSplitButton = useMemo(() => {
    const hasParts = data?.parts?.length > 1;

    return (
      hasParts &&
      [SHIPMENT_TYPE.PIPELINE, SHIPMENT_TYPE.VESSEL].includes(
        data?.shipment_type,
      )
    );
  }, [data]);

  const hasCommittedShipment = useMemo(() => {
    return data?.parts?.some((part) => part?.shipment_status === "Committed");
  }, [data]);

  const formattedDetails = useMemo(() => shipmentDataFormatter(data), [data]);

  const labelIndex = useMemo(
    () => (data?.shipment_type === SHIPMENT_TYPE.TRUCK_RACK ? [0, 6] : [2, 5]),
    [data],
  );

  const updatedFieldsHeader = useMemo(() => {
    if (data?.shipment_type !== SHIPMENT_TYPE.TRUCK_RACK) {
      return SHIPMENT_SUMMARY_FIELDS_HEADER.map((field) => {
        if (field.key === "shipment_date") {
          return [
            field,
            {
              label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_START_TIME,
              key: "shipment_start_date",
              isEditable: true,
              dataType: "dateTime",
            },
            {
              label: SHIPMENT_SUMMARY_LABELS.SHIPMENT_END_TIME,
              key: "shipment_end_date",
              isEditable: true,
              dataType: "dateTime",
            },
          ];
        }
        return field;
      }).flat();
    }
    return SHIPMENT_SUMMARY_FIELDS_HEADER;
  }, [data]);

  const saveDetail = (allocated_id, label, value) => {
    const variables = {
      shipmentId: id,
      update_field: {
        allocated_shipment_id: allocated_id,
        field: label,
        value,
      },
    };
    editShipment({variables});
  };

  const saveDetailMulti = (details) => {
    const variables = {
      shipmentId: id,
      update_field_list: details,
    };

    editShipmentMulti({variables});
  };

  const openModal = (itemDetails, allocated_id, value, maxValue) => {
    setUpdateDetails(() => ({
      ...itemDetails,
      shipmentType: data?.shipment_type,
      allocated_id,
      value,
      maxValue,
    }));
    setModal(true);
  };

  const openModalMulti = (part) => {
    setUpdateDetailsMulti(part);
    setModalMulti(true);
  };

  const renderDetailFields = (fields, part) =>
    fields.map((item, index) => {
      if (MULTI_MODAL_FIELDS.includes(item.key)) {
        return (
          <DetailField
            key={index}
            indexKey={index}
            item={item}
            part={part}
            openModal={openModalMulti}
          />
        );
      }

      return (
        <DetailField
          key={index}
          indexKey={index}
          item={item}
          part={part}
          openModal={openModal}
        />
      );
    });

  const onRemove = () => {
    setShowPopover(true);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {formattedDetails?.map((part, index) => {
            return (
              <div
                data-test="shipment-summary-card"
                className="bg-white p-5 mb-8 border rounded-lg w-2/3"
                key={index}
              >
                <div
                  data-test="shipment-summary-header"
                  className="flex flex-row justify-between mb-4"
                >
                  <div>
                    <div className="font-medium text-xl pb-3">
                      {part.shipment_id}
                    </div>
                    <div className="text-gray-600 font-light text-lg">
                      {DETAIL_HEADERS.SHIPMENT_SUMMARY}
                    </div>
                  </div>
                  <div>
                    {hasRemoveSplitButton && index === 0 && (
                      <Button
                        className="pb-2 rounded-1 bg-white text-black"
                        id="shipments-remove-button"
                        data-test="copro-remove-splits-btn"
                        onClick={() => onRemove()}
                        disabled={hasCommittedShipment}
                      >
                        {BUTTONS.REMOVE_SPLIT}
                      </Button>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 p-5">
                  {renderDetailFields(updatedFieldsHeader, part)}
                </div>
                <hr />
                <div data-test="shipment-summary-part-details">
                  <div className="grid grid-cols-3 gap-4">
                    {renderDetailFields(
                      SHIPMENT_SUMMARY_FIELDS_BODY.slice(
                        labelIndex[0],
                        labelIndex[1],
                      ),
                      part,
                    )}
                  </div>
                  <div className="flex justify-between">
                    {renderDetailFields(
                      SHIPMENT_SUMMARY_FIELDS_BODY.slice(6, 7),
                      part,
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <EditShipmentModal
        details={updateDetails}
        setModal={setModal}
        modal={modal}
        saveDetail={saveDetail}
      />
      <EditModalMulti
        details={updateDetailsMulti}
        setModal={setModalMulti}
        modal={modalMulti}
        saveDetail={saveDetailMulti}
      />
      {hasRemoveSplitButton && (
        <RemoveSplitPopover
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          refetchShipments={refetch}
          shipmentId={id}
        />
      )}
    </div>
  );
};

ShipmentSummary.propTypes = {
  id: PropTypes.string,
};

export default ShipmentSummary;
