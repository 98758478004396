import moment from "moment";
import * as DATEFORMATS from "constants/dateformats";

const getCurrentDate = () => {
  const dd = String(new Date().getDate()).padStart(2, "0");
  const mm = String(new Date().getMonth() + 1).padStart(2, "0"); // January is 0
  const yyyy = new Date().getFullYear();

  const today = yyyy + "/" + mm + "/" + dd;
  return moment(today, DATEFORMATS.DATE_FORMAT_YYYYMMDD_SLASH);
};

const convertDateFormat_YYYY_MM_DD = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_YYYYMMDD_SLASH,
  );
  return formattedDate;
};

const convertDateTimeFormat_MM_DD_YYYY_hh_mm_A = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_MMDDYYYY_hh_mm_A,
  );
  return formattedDate;
};

const convertDateFormat_MM_DD_YYYY = (date) => {
  const formattedDate = moment(date).format(
    DATEFORMATS.DATE_FORMAT_MMDDYYYY_SLASH,
  );
  return formattedDate;
};

const isDateMoreThanOneYear = (date) => {
  const currentDate = moment().add(-1, "M");
  const oldDate = moment(date);

  const diff = currentDate.diff(oldDate, "months", true);
  return diff > 12 || diff < 0;
};

const convertDateFormattoUser = (dateFormat) => {
  const dateFormatMap = {
    "MM/DD/YYYY": "m/d/Y",
    "DD/MM/YYYY": "d/m/Y",
    "YYYY/MM/DD": "Y/m/d",
  };
  return dateFormatMap[dateFormat] ?? dateFormat;
};

export const formatDatePickerDate = (date) =>
  moment(date).format("YYYY-MM-DD HH:MM:SS+HH:MM");

export default {
  isDateMoreThanOneYear,
  getCurrentDate,
  convertDateFormat_YYYY_MM_DD,
  convertDateFormat_MM_DD_YYYY,
  convertDateTimeFormat_MM_DD_YYYY_hh_mm_A,
  convertDateFormattoUser,
  formatDatePickerDate,
};
