import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import "./index.scss";
import getInputMap from "modules/common/getInputMap";
import React, {useMemo} from "react";
import {useUserSettings} from "providers/userSettings";

const PoSNLNabisyEditForm = ({data}) => {
  const {
    userSettings: {dateFormat},
  } = useUserSettings();
  const {
    formState: {errors},
  } = useFormContext();
  const inputMap = useMemo(() => getInputMap(data, errors), [data, errors]);

  return (
    <div
      data-test="postEdit-form"
      className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-x-4 gap-y-4"
    >
      {inputMap.input("partialProof", "Partial Proof", {disabled: true})}
      {inputMap.inputDate("dateOfIssuance", "Date of issuance", {
        disabled: true,
        format: dateFormat,
      })}
      {inputMap.inputDate("dateOfReceipt", "Date of receipt", {
        disabled: true,
        format: dateFormat,
      })}
      {inputMap.input("placeOfReceipt", "Place of receipt", {
        disabled: true,
      })}
      {inputMap.input("typeOfProduct", "Type of Product", {disabled: true})}
      {inputMap.input("typeOfBiomass", "Type of Biomass", {disabled: true})}
      {inputMap.input("countryOfOrigin", "Country of Origin", {disabled: true})}
      {inputMap.inputNumeric("quantity", "Quantity (m³)", {
        disabled: true,
        decimals: 3,
      })}
      {inputMap.inputNumeric("energyContentMj", "Energy Content (MJ)", {
        disabled: true,
      })}
      {inputMap.inputSelect(
        "producedFromResidueWaste",
        "Produced from residues/waste not arising from Agriculture, Forestry, Fisheries or Aquaculture",
        {disabled: true},
      )}
      {inputMap.inputSelect(
        "arisingFromAgriculture",
        "Arising from Agriculture, Forestry, Fishery or aquaculture",
        {disabled: true},
      )}
      {inputMap.inputSelect(
        "biomassConforms_4_5",
        "Biomass conforms to 4 - 5 BioSt-NachV / Biokraft-NachV",
        {disabled: true},
      )}
      {inputMap.input("ghgValue", "GHG", {disabled: true})}
      {inputMap.input("ghgEec", "GHG - EeC", {disabled: true})}
      {inputMap.input("ghgEl", "GHG - El", {disabled: true})}
      {inputMap.input("ghgEp", "GHG - Ep", {disabled: true})}
      {inputMap.input("ghgEtd", "GHG - Etd", {disabled: true})}
      {inputMap.input("ghgEu", "GHG - Eu", {disabled: true})}
      {inputMap.input("ghgEcsa", "GHG - Ecsa", {disabled: true})}
      {inputMap.input("ghgEccs", "GHG - Eccs", {disabled: true})}
      {inputMap.input("ghgEccr", "GHG - Eccr", {disabled: true})}
      {inputMap.input("ghgEee", "GHG - Eee", {
        disabled: true,
      })}
      {inputMap.inputDate("dateOfOperation", "Date of operation", {
        disabled: true,
        format: dateFormat,
      })}
    </div>
  );
};

PoSNLNabisyEditForm.propTypes = {
  data: PropTypes.object,
};

export default PoSNLNabisyEditForm;
