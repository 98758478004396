import PropTypes from "prop-types";

import {FilterBar} from "modules/common/FilterBar";
import history from "providers/routerHistory";
import Filter from "modules/common/Filter";
import CeemasValidationFilterContent from "./CeemasValidationFilterContent";

const CeemasValidationFilter = ({onFilter, data, loading, defaultValues}) => {
  const handleClear = () => {
    history.push(`/gy-enablement/ceemas-mot-validation/:filterDetails`);
  };
  return (
    <FilterBar>
      <Filter
        defaultValues={defaultValues}
        onClear={handleClear}
        onFilter={onFilter}
        showExpand
      >
        <CeemasValidationFilterContent
          defaultValues={defaultValues}
          dataItems={data}
          dataItemsloading={loading}
        />
      </Filter>
    </FilterBar>
  );
};

CeemasValidationFilter.propTypes = {
  onFilter: PropTypes.func,
  data: PropTypes.object,
  loading: PropTypes.bool,
  defaultValues: PropTypes.object,
};

export default CeemasValidationFilter;
