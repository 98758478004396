import {gql} from "@apollo/client";

export const PRODUCTION_BASELINE_EMISSIONS = gql`
  query bioLcCoproConfigCenterProductBaselineEmissionDetails {
    bioLcCoproConfigCenterProductBaselineEmissionDetails {
      Records {
        product_baseline_emission_id
        material_id
        material_code
        material_short_name
        baseline_emission
        uom_code
        valid_from
        valid_to
      }
    }
  }
`;

export default {PRODUCTION_BASELINE_EMISSIONS};
