import {useQuery} from "@apollo/client";
import moment from "moment";

import {
  GET_COMPLIANCE_FILTER,
  GET_COMPLIANCE_OPENING,
  GET_COMPLIANCE_CLOSING,
  GET_COMPLIANCE_CREDITS,
  GET_COMPLIANCE_DEFICITS,
  GET_COMPLIANCE_UOM,
  GET_COMPLIANCE_SCHEME_GRAPH,
} from "graphql/gy-enablement/compliance/massBalance";
import {FilterBar} from "modules/common/FilterBar";
import {roundDecimal} from "utils/helpers/uihelper";
import {useCallback, useEffect, useMemo, useReducer, useState} from "react";
import {useUserSettings, formatNumber} from "providers/userSettings";
import complianceFilterSchema from "modules/compliance/schemas/complianceFilterSchema";
import {checkValidArray, checkValidParameter} from "utils/helpers/checkData";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import Filter from "./Filter/Filter";
import FilterContent from "./Filter/FilterContent";
import filterReducer, {INITIAL_STATE, ACTIONS} from "./Filter/filterReducer";
import DetailedViewModal from "./DetailedView/DetailedViewModal";
import Dashboard from "./Dashboard";
import ManualAdjustmentModal from "./ManualAdjustment/ManualAdjustmentModal";
import SummaryGraph from "./SummaryGraph";

const currentYear = new Date().getFullYear().toString();

const defaultFilter = {
  complianceScheme: "RTFO",
  country: "UK",
  complianceType: "",
  complianceFromDate: "",
  complianceToDate: "",
  bioType: "",
  subType: "",
  complianceName: "",
  // Removed temporarily (Not sure if will comeback)
  // complianceUnitOfMeasure: "",
  legalEntity: "",
  complianceYear: currentYear,
};

const ComplianceMassBalance = () => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [showManualAdjustment, setShowManualAdjustment] = useState(false);
  const [isGraphModalVisible, setGraphModalVisible] = useState(false);
  const [scenarioDetail, setScenarioDetail] = useState(null);
  const [activeTab, setActiveTab] = useState("close");
  const [state, dispatch] = useReducer(filterReducer, INITIAL_STATE);
  const [currentFilter, setCurrentFilter] = useState({...defaultFilter});
  const onLoadPayload = useMemo(() => ({...defaultFilter}), []);
  const {data: filterData, loading: filterDataLoading} = useQuery(
    GET_COMPLIANCE_FILTER,
    {
      notifyOnNetworkStatusChange: true,
      // Note: This parameters should be backend optional.
      variables: {
        record: {
          country: ["UK", "US"],
          complianceType: "",
          complianceScheme: ["RTFO", "RFS"],
          compliancePeriod: "",
          bioType: "",
          subType: "",
          complianceName: "",
          // Removed temporarily (Not sure if will comeback)
          // complianceUnitOfMeasure: "",
          legalEntity: "",
        },
      },
    },
  );

  const {
    data: summaryDataGraph,
    loading: summaryDataGraphLoading,
    refetch: refetchsummaryDataGraph,
  } = useQuery(GET_COMPLIANCE_SCHEME_GRAPH, {
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  const {
    data: complianceDataOpening,
    loading: complianceDataOpeningloading,
    refetch: refetchComplianceDataOpening,
  } = useQuery(GET_COMPLIANCE_OPENING, {
    notifyOnNetworkStatusChange: true,
    // Note: This parameters should be backend optional.
    variables: onLoadPayload,
  });

  const {
    data: complianceDataClosing,
    loading: complianceDataClosingloading,
    refetch: refetchComplianceDataClosing,
  } = useQuery(GET_COMPLIANCE_CLOSING, {
    notifyOnNetworkStatusChange: true,
    // Note: This parameters should be backend optional.
    variables: onLoadPayload,
  });
  const {
    data: complianceDataCredits,
    loading: complianceDataCreditsloading,
    refetch: refetchComplianceDataCredits,
  } = useQuery(GET_COMPLIANCE_CREDITS, {
    notifyOnNetworkStatusChange: true,
    // Note: This parameters should be backend optional.
    variables: onLoadPayload,
  });

  const {
    data: complianceDataDeficits,
    loading: complianceDataDeficitsloading,
    refetch: refetchComplianceDataDeficits,
  } = useQuery(GET_COMPLIANCE_DEFICITS, {
    notifyOnNetworkStatusChange: true,
    // Note: This parameters should be backend optional.
    variables: onLoadPayload,
  });

  const {
    data: complianceSchemeUnitMeasurelist,
    refetch: refetchComplianceSchemeUnitMeasures,
  } = useQuery(GET_COMPLIANCE_UOM, {
    notifyOnNetworkStatusChange: true,
    variables: onLoadPayload,
  });

  // create the table columns from UOM API response Start
  const complianceSchemeColumnsWithUOM = [];
  complianceSchemeColumnsWithUOM.push(
    {
      header: "Scenario number",
      id: "scenarioNumber",
      key: "scenarioNumber",
      accessorKey: "scenarioNumber",
      align: "left",
      size: 200,
    },
    {
      header: "Scenario description",
      id: "scenarioDescription",
      key: "scenarioDescription",
      accessorKey: "scenarioDescription",
      align: "left",
      size: 500,
    },
  );

  const mainObligationUnit =
    complianceSchemeUnitMeasurelist?.getComplianceSchemeUomV2?.obligationUnit;
  const country = mainObligationUnit === "RTFC/dRTFC" ? "UK" : "USA";
  const mainObligationUnitText =
    mainObligationUnit && mainObligationUnit !== ""
      ? ` - ${mainObligationUnit}`
      : "";

  let ticketsUom = "";
  let quantityUom = "";
  complianceSchemeUnitMeasurelist?.getComplianceSchemeUomV2?.unitOfMeasures?.forEach(
    (item) => {
      let uomValue = item.unitOfMeasureValue;
      let showOnlyUomValue = false;
      if (item.unitOfMeasure === "Tickets") {
        ticketsUom = item.unitOfMeasureValue;
      }

      if (item.unitOfMeasure === "Volume") {
        if (currentFilter.uom && country === "USA") {
          quantityUom = currentFilter.uom;
          uomValue = quantityUom;
        } else {
          quantityUom = item.unitOfMeasureValue;
        }
      }

      if (item.unitOfMeasure === "RINs") {
        ticketsUom = item.unitOfMeasureValue;
        showOnlyUomValue = true;
      }

      complianceSchemeColumnsWithUOM.push({
        header: showOnlyUomValue
          ? item.unitOfMeasureValue
          : uomValue && uomValue !== ""
          ? `${item.unitOfMeasure} - ${uomValue}`
          : `${item.unitOfMeasure}`,
        dataIndex: item.unitOfMeasureKey,
        id: item.unitOfMeasureKey,
        key: item.unitOfMeasureKey,
        accessorKey: item.unitOfMeasureKey,
        cell: (cell) =>
          formatNumber(roundDecimal(cell.getValue()), decimalFormat, 0),
        align: "right",
        sortingFn: "alphanumeric",
        size: 60,
      });
    },
  );
  // create the table columns from UOM API response End

  const summaryGraphData = summaryDataGraph?.getComplianceSchemeGraphV2;
  const complianceOpeningData =
    complianceDataOpening?.getComplianceSchemeOpenV2;
  const complianceClosingData =
    complianceDataClosing?.getComplianceSchemeCloseV2;
  const complianceCreditsData =
    complianceDataCredits?.getComplianceSchemeCreditV2;
  const complianceDeficitsData =
    complianceDataDeficits?.getComplianceSchemeDeficitV2;

  const filterDataObject = useMemo(
    () => filterData?.getComplianceFilterV2,
    [filterData?.getComplianceFilterV2],
  );

  useEffect(() => {
    // When data come, reset filter.
    if (filterDataObject) {
      dispatch({
        type: ACTIONS.CLEAR_FILTERS,
        payload: {data: filterDataObject},
      });
    }
  }, [filterDataObject]);

  const handleFilterChange = useCallback((filterName, filterValue) => {
    dispatch({
      type: ACTIONS.CHANGE_FILTER,
      payload: {name: filterName, value: filterValue},
    });
  }, []);

  const [startDatePlaceholder, setStartDatePlaceholder] =
    useState("Start date");
  const [endDatePlaceholder, setEndDatePlaceholder] = useState("End date");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [isStartDateValid, setStartDateValid] = useState(true);
  const [isEndDateValid, setEndDateValid] = useState(true);

  const clearDates = useCallback(() => {
    setStartDatePlaceholder("Start date");
    setEndDatePlaceholder("End date");
    setStartDate("");
    setEndDate("");
    setStartDateValid(true);
    setEndDateValid(true);
  }, [setStartDatePlaceholder, setEndDatePlaceholder]);

  const handleClear = useCallback(() => {
    dispatch({
      type: ACTIONS.CLEAR_FILTERS,
      payload: {data: filterDataObject},
    });
    refetchsummaryDataGraph(onLoadPayload);
    refetchComplianceDataClosing(onLoadPayload);
    refetchComplianceDataCredits(onLoadPayload);
    refetchComplianceDataDeficits(onLoadPayload);
    refetchComplianceDataOpening(onLoadPayload);
    clearDates();
  }, [
    filterDataObject,
    onLoadPayload,
    refetchsummaryDataGraph,
    refetchComplianceDataClosing,
    refetchComplianceDataCredits,
    refetchComplianceDataDeficits,
    refetchComplianceDataOpening,
    clearDates,
  ]);

  const handleOnFilter = useCallback(
    (values) => {
      let compliancePeriodValue = "";
      if (
        values.compliancePeriod !== undefined &&
        values.compliancePeriod !== "" &&
        values.compliancePeriod !== null
      ) {
        compliancePeriodValue =
          values.compliancePeriod?.length > 4
            ? moment(values.compliancePeriod, "DD/MM/YYYY").format("YYYY")
            : values.compliancePeriod;
      }
      const payload = {
        country: checkValidParameter(values.country),
        complianceScheme: checkValidParameter(values.complianceScheme),
        complianceName: checkValidParameter(values.complianceName),
        complianceType: checkValidParameter(values.complianceType),
        bioType: checkValidParameter(values.bioType),
        subType: checkValidParameter(values.subType),
        complianceFromDate:
          values.startDate !== undefined &&
          values.startDate !== null &&
          values.startDate !== ""
            ? moment(values.startDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : "",
        complianceToDate:
          values.endDate !== undefined &&
          values.endDate !== null &&
          values.endDate !== ""
            ? moment(values.endDate, "DD-MM-YYYY").format("YYYY-MM-DD")
            : "",
        legalEntity: checkValidParameter(values.legalEntity),
        complianceYear: compliancePeriodValue,
        uom: values.uom,
      };
      setCurrentFilter({...payload});
      refetchsummaryDataGraph(payload);
      refetchComplianceDataOpening(payload);
      refetchComplianceDataCredits(payload);
      refetchComplianceDataDeficits(payload);
      refetchComplianceDataClosing(payload);
      refetchComplianceSchemeUnitMeasures({
        complianceScheme: values.complianceScheme || "",
      });
    },
    [
      refetchsummaryDataGraph,
      refetchComplianceDataOpening,
      refetchComplianceDataCredits,
      refetchComplianceDataDeficits,
      refetchComplianceDataClosing,
      refetchComplianceSchemeUnitMeasures,
    ],
  );
  const onMore = (rec) => {
    setShowDetailedView(true);
    setScenarioDetail({...rec});
  };

  const onManualAdjustment = (rec) => {
    setShowManualAdjustment(true);
    setScenarioDetail({...rec});
  };

  const tabToHeaderMapping = {
    close: "Closing",
    deficit: "Deficits",
    credit: "Credits",
    open: "Opening",
  };

  const filterContent = useMemo(
    () => (
      <FilterContent
        dataSource={state.dataSource}
        options={state.options}
        onFilterChange={handleFilterChange}
        endDatePlaceholder={endDatePlaceholder}
        startDatePlaceholder={startDatePlaceholder}
        setEndDatePlaceholder={setEndDatePlaceholder}
        setStartDatePlaceholder={setStartDatePlaceholder}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        isStartDateValid={isStartDateValid}
        setStartDateValid={setStartDateValid}
        isEndDateValid={isEndDateValid}
        setEndDateValid={setEndDateValid}
        onFilter={handleOnFilter}
        onClear={handleClear}
        isDisabled={!isStartDateValid && !isEndDateValid}
      />
    ),
    [
      state,
      handleFilterChange,
      endDatePlaceholder,
      startDatePlaceholder,
      setEndDatePlaceholder,
      setStartDatePlaceholder,
      startDate,
      endDate,
      setStartDate,
      setEndDate,
      isStartDateValid,
      setStartDateValid,
      isEndDateValid,
      setEndDateValid,
      handleOnFilter,
      handleClear,
    ],
  );

  return (
    <>
      <div id="compliance-filter">
        <FilterBar>
          <Filter
            defaultValues={{
              country: "UK",
              complianceScheme: "RTFO",
              compliancePeriod: currentYear,
              subType: "",
              legalEntity: "",
              bioType: "",
              dateRange: [],
              uom: "Gallons",
            }}
            onFilter={handleOnFilter}
            onClear={handleClear}
            schema={complianceFilterSchema}
            isDisabled={!isStartDateValid && !isEndDateValid}
          >
            {filterContent}
          </Filter>
        </FilterBar>
      </div>
      <div className="my-5 px-6 lg:px-10 flex h4">
        <div
          data-test="title-compliance-scheme"
          className="mr-auto title-compliance-scheme"
        >
          {complianceOpeningData?.complianceScheme != null
            ? `${complianceOpeningData?.complianceScheme} - ${complianceOpeningData?.complianceSchemeName}`
            : ""}
        </div>
        <Button
          className="all-transactions-button rounded-0"
          onClick={() =>
            onMore({scenarioDescription: tabToHeaderMapping[activeTab]})
          }
        >
          View all transactions
        </Button>
      </div>

      <div className="">
        <Dashboard
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          complianceOpeningData={complianceOpeningData}
          complianceCreditsData={complianceCreditsData}
          complianceDeficitsData={complianceDeficitsData}
          complianceClosingData={complianceClosingData}
          complianceDataOpeningloading={complianceDataOpeningloading}
          complianceDataCreditsloading={complianceDataCreditsloading}
          complianceDataDeficitsloading={complianceDataDeficitsloading}
          complianceDataClosingloading={complianceDataClosingloading}
          mainObligationUnitText={mainObligationUnitText}
          summaryGraphData={summaryGraphData}
          summaryGraphDataLoading={summaryDataGraphLoading}
          onMore={onMore}
          onManualAdjustment={onManualAdjustment}
          decimalFormat={decimalFormat}
          complianceSchemeColumnsWithUOM={complianceSchemeColumnsWithUOM}
          country={country}
          expandGraph={() => setGraphModalVisible(true)}
        />
        {showDetailedView && (
          <DetailedViewModal
            title="Detail view"
            visible={showDetailedView}
            data={scenarioDetail}
            filters={currentFilter}
            activeTab={activeTab}
            onCancel={() => setShowDetailedView(false)}
            country={country}
            ticketsUom={ticketsUom}
            quantityUom={quantityUom}
            options={state.options}
          ></DetailedViewModal>
        )}
        {showManualAdjustment && (
          <ManualAdjustmentModal
            visible={showManualAdjustment}
            onCancel={() => setShowManualAdjustment(false)}
            data={scenarioDetail}
          />
        )}
        <Modal
          data-test="graphset-modal"
          size="xl"
          isOpen={isGraphModalVisible}
          onCancel={() => setGraphModalVisible(!isGraphModalVisible)}
          destroyOnClose
          closable
        >
          <ModalHeader
            className="border-b-2 border-gray-200 mb-2 text-xl"
            close={
              <Close
                onClick={() => setGraphModalVisible(!isGraphModalVisible)}
              />
            }
          >
            View Details
          </ModalHeader>
          <ModalBody>
            <SummaryGraph
              loading={summaryDataGraphLoading}
              dataGraph={checkValidArray(summaryGraphData)}
              opening={complianceOpeningData?.total}
              closing={complianceClosingData?.total}
              ytickNumber={10}
              containerHeight={800}
              isExpandedView
            />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default ComplianceMassBalance;
