import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {LOCATION_MASTER} from "graphql/config-center/LocationMaster.js";
import RTable from "../components/Table/index.js";
import {LOCATION_MASTER_COLUMNS} from "../components/Table/Columns.js";

const LocationMaster = () => {
  const {data, loading} = useQuery(LOCATION_MASTER);

  return (
    <RTable
      columns={LOCATION_MASTER_COLUMNS}
      data={
        loading ? [] : data?.bioLcCoproConfigCenterLocationMasterDetails.Records
      }
      tableName="Location master"
    />
  );
};
export default LocationMaster;
