import Chat from "assets/images/chat-icon.png";
import Idea from "assets/images/svg/idea.svg";
import Support from "assets/images/support-icon.png";
import chatbot from "assets/images/chatbot-small.png";

const getInTouch = [
  {
    id: 1,
    img: Chat,
    title: "Comments / Feedback",
    description: (
      <div data-test="description" className="px-4 mt-1">
        Your comments and feedback are extremely valuable. Help us improve{" "}
        <a
          href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5MzUzNjg3MDQwMCJ9/all"
          target="_blank"
          className="inline text-blue-500 cursor-pointer"
          rel="noreferrer"
        >
          BioVerse{" "}
        </a>
        by sending them to us.
      </div>
    ),
    isVisible: true,
  },
  {
    id: 2,
    img: Idea,
    title: "Suggestion / Ideas",
    description:
      "Do you have an idea for a new module to host in the Portal? Let us know!",
    isVisible: false,
  },
  {
    id: 3,
    img: Support,
    title: "Support / Help",
    description: (
      <div data-test="support-description" className="px-4 mt-1">
        Facing issues with the Portal? <br />
        <a
          href="https://forms.office.com/Pages/ResponsePage.aspx?id=LpWA6nak1EKq9FRXhSsPfisTZMYFf0ZAh0P7Dj6z0oFUMTZPVDhGTEtHMURKMkdBNEQ0RUQwQzhVWS4u"
          target="_blank"
          className="inline text-blue-500 cursor-pointer"
          rel="noreferrer"
        >
          Click here{" "}
        </a>
        to submit a ticket and we will help at our earliest.
      </div>
    ),

    isVisible: true,
  },
];

export default getInTouch;
