import PropTypes from "prop-types";
import React from "react";
import {Breadcrumb, BreadcrumbItem, Button} from "reactstrap";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useLocation, Link} from "react-router-dom";
import {Left24} from "@bphxd/ds-core-react/lib/icons";
import "./index.css";

const Layout = ({title, breadcrumbs, subtitle, children, settingsContent}) => {
  const {appSetting, setAppSetting} = useAppSetting();
  const navigate = useNavigate();
  const location = useLocation();
  const {costCalcObject} = location.state || {};
  const DEFAULT_BACK_TO_COST_CALC_COMPONENT_HEADER = "Back to cost calculation";

  return (
    <div className="bg-light-gray-100 pt-5 min-h-[70vh]">
      <div className="flex flex-row justify-between">
        <div>
          <Breadcrumb className="px-7">
            {breadcrumbs.map((item, index) => (
              <BreadcrumbItem
                key={index}
                active={index === breadcrumbs.length - 1}
              >
                {item.text === "BioVerse" ? (
                  <Link
                    to="/"
                    data-test="home-button"
                    onClick={() => {
                      const countryFeatureData =
                        appSetting?.featureMappingData?.find(
                          (item) => item.countryName === "GLOBAL",
                        );

                      setAppSetting({
                        ...appSetting,
                        currentCountry: "GLOBAL",
                        currentCountryMappingData: countryFeatureData,
                        currentModule: "",
                        currentModuleTitle: "",
                      });
                    }}
                  >
                    {item.text}
                  </Link>
                ) : index === breadcrumbs.length - 1 ? (
                  <span>{item.text}</span>
                ) : (
                  <Link to={item.link}>{item.text}</Link>
                )}
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          {costCalcObject && (
            <div style={{marginRight: "1rem"}}>
              <Button
                data-test="copro-fifo-cost-calc-back-button"
                id="costCalcBackButton"
                name="costCalcBackButton"
                color="standard-outline rounded-0"
                className="cost-calc-back-button"
                onClick={() => {
                  navigate("/copro-spain/spain/cost-calculation/", {
                    state: {
                      periodMonthReceived: costCalcObject?.costCalcMonth,
                      periodYearReceived: costCalcObject?.costCalcYear,
                      materialShortNameReceived:
                        costCalcObject?.costCalcFeedstockMaterialShortName,
                      currencyUnitReceived:
                        costCalcObject?.costCalcCurrencyUnit,
                      unitRateReceived: costCalcObject?.costCalcUnitRate,
                    },
                  });
                }}
              >
                <Left24 className="mr-[10px]" />
                {DEFAULT_BACK_TO_COST_CALC_COMPONENT_HEADER}
              </Button>
            </div>
          )}
          <h1 className="text-[32px] text-black fw-light pb-[20px] px-7">
            {title}
          </h1>
          {subtitle}
        </div>
        {settingsContent}
      </div>
      <div className="px-7">
        <hr className="border-1 mb-6" />
      </div>
      {children}
    </div>
  );
};

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node,
  breadcrumbs: PropTypes.array.isRequired,
  children: PropTypes.node,
  settingsContent: PropTypes.node,
};
export default Layout;
