const uniqueCsvFile = (filename) => {
  return filename + new Date().toJSON().slice(0, 19) + ".csv";
};

const uniqueXlxsFile = (filename) => {
  return filename + new Date().toJSON().slice(0, 19) + ".xlsx";
};

const fileNameTypes = {
  FILE_CHERRYPOINT_REFINERY: "Cherrypoint_Details_",
  FILE_BIOFEEDSTOCK: "Biofeedstock_Details_",
  FILE_BATCHES: "Batches_Details_",
  FILE_SHIPMENTS: "Shipment_Details_",
  FILE_MOT_VALIDATION_REPORT: "mot_validation_report_",
  FILE_TRUCK_RACK: "Truck_Rack_Details_",
  FILE_LCFS: "LCFS_Details_",
  FILE_INVENTORY: "Inventory_Details_",
  FILE_MASTER_TAX_PLANT_REGISTRY: "Master_Tax_Plant_Registry_",
  FILE_MASTER_CO2_COSTS_REPORT: "master_CO2_Costs_report_",
  FILE_EMTS: "EMTS_Details_",
  FILE_POS: "POS_Details_",
  FILE_POS_PL: "POS_PL_Details_",
};

export default {
  uniqueCsvFile,
  uniqueXlxsFile,
  fileNameTypes,
};
