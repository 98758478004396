import PropTypes from "prop-types";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {useEffect, useMemo, useState} from "react";

import {useAccount, useMsal} from "@azure/msal-react";

import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {useAppSetting} from "providers/appSetting";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {FormGroup, FormText, Input, Label} from "reactstrap";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import {
  countryNames,
  MB_QUANTITY_UNIT_KG,
  MB_QUANTITY_UNIT_MJ,
  status,
} from "../constants";

const InboundDetail = ({data}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const [unitToBeDisplayed, setUnitToBeDisplayed] =
    useState(MB_QUANTITY_UNIT_MJ);

  const {division} = useParams();
  const divisionCode = division.toUpperCase();

  useEffect(() => {
    if (divisionCode && divisionCode === DIV_CODE_COPRO) {
      setUnitToBeDisplayed(MB_QUANTITY_UNIT_KG);
    }
  }, [divisionCode]);

  const [feedstockValue, setFeedstockValue] = useState(0);
  const [feedstockEditMode, setFeedstockEditMode] = useState(false);

  const inboundRecordData = useMemo(() => {
    const {inboundRecord} = data;

    const inRec = {...inboundRecord};
    let calculationSteps = inRec.calculationSteps ?? "None";
    if (calculationSteps === "None") {
      inRec.calculationSteps = {};
      return inRec;
    }
    calculationSteps = JSON.parse(calculationSteps.replaceAll("'", '"'));
    inRec.calculationSteps =
      calculationSteps[inRec.unitToBeDisplayed ?? "kg CO2eq/dry-ton"];
    return inRec;
  }, [data]);

  const isEditable =
    data?.outboundRecords.length === 1 &&
    data?.outboundRecords[0].status === status.AVAILABLE;

  const handleTransportLossChange = async () => {
    if (feedstockValue > 0) {
      if (
        feedstockValue > inboundRecordData.qtyProcessedIn ||
        feedstockValue > inboundRecordData.qtyProcessedOut
      ) {
        toast.error(
          "Tranportation loss cannot be greater than quantity processed",
        );
      } else {
        setFeedstockEditMode(false);
        // This will be used in 396159
        // const {data: response} = await UpdateFeedstockAPI({
        //   event: {
        //     inboundEuQtyId: inboundRecordData.inboundEuQtyId,
        //     transportationLoss: feedstockValue,
        //     changedBy: account.username,
        //   },
        // });
        // if (response?.bioLcMassBalanceTransportationLoss?.statusCode !== 200) {
        //   toast.error(response?.bioLcMassBalanceTransportationLoss?.message);
        // }
      }
    }
  };

  function numberFormatter(value) {
    if (Number.isInteger(value) && value !== 0) {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
    }
    if (value === 0 || value === "0.0") {
      return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 0);
    }

    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  return (
    <div className="flex flex-col h-[260px] justify-start items-stretch w-full gap-4">
      <div className="flex flex-row justify-between items-center h-[2.2rem]"></div>
      <div className="flex flex-row justify-between items-start gap-2">
        <FormGroup>
          <Label for="ghgEecIn" className="text-[12px] mb-0">
            Eec
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEecIn"
            data-test="ghgEecIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEec)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgElIn" className="text-[12px] mb-0">
            El
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgElIn"
            data-test="ghgElIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEl)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEpIn" className="text-[12px] mb-0">
            Ep
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEpIn"
            data-test="ghgEpIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEp)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEtdIn" className="text-[12px] mb-0">
            Etd
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEtdIn"
            data-test="ghgEtdIn"
            readOnly
            value={
              inboundRecordData.ghgEtd === "DDV"
                ? inboundRecordData.ghgEtd
                : numberFormatter(inboundRecordData.ghgEtd)
            }
          />
          {inboundRecordData.calculationSteps?.ghg_etd ?? ""}
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">+</div>
        <FormGroup>
          <Label for="ghgEuIn" className="text-[12px] mb-0">
            Eu
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEuIn"
            data-test="ghgEuIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEu)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEscaIn" className="text-[12px] mb-0">
            Esca
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEscaIn"
            data-test="ghgEscaIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEsca)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccsIn" className="text-[12px] mb-0">
            Eccs
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEccsIn"
            data-test="ghgEccsIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEccs)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">-</div>
        <FormGroup>
          <Label for="ghgEccrIn" className="text-[12px] mb-0">
            Eccr
          </Label>
          <Input
            className="bg-white !text-[#111111a3] text-right min-w-[80px]  max-w-[80px]"
            type="text"
            id="ghgEccrIn"
            data-test="ghgEccrIn"
            readOnly
            value={numberFormatter(inboundRecordData.ghgEccr)}
          />
        </FormGroup>
        <div className="h-[calc(100%-9px)] flex flex-row items-center">=</div>
        <FormGroup>
          <Label for="ghgTotalIn" className="text-[12px] mb-0">
            Total
          </Label>
          <Input
            className="min-w-[80px]  max-w-[80px] bg-white !text-[#111111a3] text-right"
            type="text"
            id="ghgTotalIn"
            data-test="ghgTotalIn"
            readOnly
            value={
              inboundRecordData.ghgTotal === "DDV" ||
              inboundRecordData.ghgTotal === "TDV"
                ? inboundRecordData.ghgTotal
                : numberFormatter(inboundRecordData.ghgTotal)
            }
          />
          <div className="text-right">
            <FormText>{unitToBeDisplayed}</FormText>
          </div>
        </FormGroup>
      </div>
      {countryNames.UNITED_KINGDOM === appSetting.currentCountry && (
        <div className="grid grid-cols-3 gap-4">
          <div className="flex flex-col">
            <span className="">Feedstock allocation %</span>
            <div className="flex flex-row ">
              <Input
                className="bg-white !text-[#111111a3] text-right  max-w-[150px]"
                type="number"
                id="feedstockPercent"
                data-test="feedstockPercent"
                onChange={(e) => setFeedstockValue(e.target.value)}
                disabled={!feedstockEditMode}
                defaultValue={numberFormatter(
                  inboundRecordData.feedstockPercent,
                )}
              />
              {/* This will be used in next sprint with this user story 391870 */}
              {/* {!feedstockEditMode && (
              <Button
                size="sm"
                color="standard-tertiary bg-white"
                className="rounded-0 btn-standard-secondary bg-white"
                onClick={() => setFeedstockEditMode(true)}
                disabled={!isEditable}
              >
                Edit
              </Button>
            )}
            {feedstockEditMode && (
              <Button
                size="sm"
                color="standard-tertiary bg-white"
                className="rounded-0 btn-standard-secondary  bg-white"
                onClick={() => handleTransportLossChange()}
              >
                Apply
              </Button>
            )} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

InboundDetail.propTypes = {
  data: PropTypes.object,
};

export default InboundDetail;
