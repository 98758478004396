import {Spinner} from "reactstrap";

const LoadingSpinner = () => {
  return (
    <div data-test="copro-form-loading" className="flex mx-auto">
      <Spinner
        style={{width: "6rem", height: "6rem"}}
        className="flex mx-auto"
      />
    </div>
  );
};

export default LoadingSpinner;
