import PropTypes from "prop-types";
import React, {useEffect} from "react";

import {Label} from "reactstrap";
import {useFormContext} from "react-hook-form";
import RadioButton from "./RadioButton";

const ReportSettingForm = ({defaultValues}) => {
  const {control, setValue} = useFormContext();

  const materialSAPCode = "Material + SAP code";
  const materialNCCode = "Material + Commodity code";

  const uomTonnes = "mt - metric tonne";
  const uomCmeters = "m3 - cubic meter";

  useEffect(() => {
    setValue("materialView", defaultValues?.materialView === "sap_code");
    setValue("uom", defaultValues?.uom === "t");
  }, [setValue, defaultValues.materialView, defaultValues?.uom]);

  return (
    <div className=" flex flex-col items-start pb-8">
      <Label className="text-sm pt-6 pb-4 m-0">Material view</Label>
      <RadioButton
        type="radio"
        control={control}
        name="materialView"
        yesLabel={materialSAPCode}
        noLabel={materialNCCode}
        onClick={(value) => alert(value)}
      />
      <Label className="text-sm pt-7 pb-4 m-0">Unit of measure</Label>
      <RadioButton
        type="radio"
        control={control}
        name="uom"
        yesLabel={uomTonnes}
        noLabel={uomCmeters}
        onClick={(value) => alert(value)}
      />
    </div>
  );
};

ReportSettingForm.propTypes = {
  defaultValues: PropTypes.object,
};
export default ReportSettingForm;
