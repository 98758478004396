import {gql} from "@apollo/client";

// Fetch query for Business Partner table on Config center(/configuration-center/mb-balance-group)
export const BUSINESS_PARTNER_ADDRESS_GET_QUERY = gql`
  query bioLcGetBusinessPartnerAddress {
    bioLcGetBusinessPartnerAddress {
      businessPartnerAddress {
        addressDescription
        addressId
        businessPartnerId
        businessPartnerName
        businessPartnerType
        locationCode
        locationId
        locationLongName
        locationShortName
      }
    }
  }
`;

export default {BUSINESS_PARTNER_ADDRESS_GET_QUERY};
