import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import {
  useSelectedDate,
  useDateActions,
} from "modules/forecasting/context/ForecastingDateContext";
import ForecastBarGraphWidget from "./ForecastBarGraphWidget";
import isGeneratedForecast from "../helpers/generatedForecastCheck";
import {
  isCurrentDate,
  getDisplayLabel,
  showPreviousCurrentOrForecastLabel,
  getMaxValue,
  getForecastTypeCategory,
} from "../helpers/generateGraphValues";
import {isBeforeSpecifiedYear} from "../helpers/dateUtils";

const DATA_FORECAST_START_YEAR = "2020";

const ForecastGraphSet = ({
  unpublishedGraphData = [],
  graphData = [],
  fullYearData = [],
  isCredits = false,
  isWestCoast = false,
}) => {
  const {onPrevious, onNext} = useDateActions();
  const {monthIndex} = useSelectedDate();
  const [graphMaxValue, setGraphMaxValue] = useState(0);
  const [forecastData, setForecastData] = useState([]);

  useEffect(() => {
    if (unpublishedGraphData.length === 0 && graphData.length) {
      setForecastData(graphData);
    }
    if (graphData.length === 0 && unpublishedGraphData.length) {
      setForecastData(unpublishedGraphData);
    }
  }, [graphData, unpublishedGraphData]);

  useEffect(() => {
    if (fullYearData.length === 0) return;

    setGraphMaxValue(getMaxValue(fullYearData, false, isCredits));
  }, [fullYearData, isCredits]);

  const getTotalDifference = (forecast, index) => {
    if (unpublishedGraphData?.[index]) {
      return (
        unpublishedGraphData[index][getForecastTypeCategory(0, isCredits)]
          .variance +
        unpublishedGraphData[index][getForecastTypeCategory(1, isCredits)]
          .variance +
        (isWestCoast
          ? unpublishedGraphData[index][getForecastTypeCategory(2, isCredits)]
              .variance
          : 0)
      );
    }
    if (isGeneratedForecast(forecast.date)) {
      return 0;
    }

    return forecast.monthTotal.variance;
  };

  return (
    <div
      data-test="inventory-bar-graph-wrapper"
      className="overflow-x-auto inventory-graph-set bg-gray-50 flex grow col-span-full grid-cols-6 justify-between"
    >
      <div
        data-test="inventory-graph-back-arrow-wrapper"
        className="self-end pl-2"
      >
        <button
          type="button"
          data-test="inventory-graph-back-arrow"
          onClick={() => onPrevious()}
          disabled={monthIndex < 1}
        >
          <LeftOutlined
            className={`mb-4 ${monthIndex < 1 && "disabled-arrow-btn"}`}
          />
        </button>
      </div>

      {graphData &&
        Array.isArray(graphData) &&
        graphData.map((forecast, index) => (
          <div
            data-test={`inventory-bar-graph-forecast-${index}`}
            key={`forecast-${index}`}
            className="inventory-graph-element max-w-[15rem] 2xl:max-w-[15rem] flex flex-col items-center"
          >
            <div
              data-test={`inventory-bar-forecast-header-${index}`}
              className={`inventory-label current-label-space ${showPreviousCurrentOrForecastLabel(
                index,
                forecast,
              )}`}
            >
              {getDisplayLabel(forecast.date)}
            </div>
            <ForecastBarGraphWidget
              current={isCurrentDate(forecast)}
              date={forecast.date}
              graphMaxValue={graphMaxValue}
              isCredits={isCredits}
              isWestCoast={isWestCoast}
              firstValue={
                forecast[getForecastTypeCategory(0, isCredits)]?.actualValue ||
                0
              }
              secondValue={
                forecast[getForecastTypeCategory(1, isCredits)]?.actualValue ||
                0
              }
              additionalValue={
                forecast[getForecastTypeCategory(2, isCredits)]?.actualValue ||
                0
              }
              totalValue={forecast.monthTotal.actualValue}
              firstDifference={
                isGeneratedForecast(forecast.date) ||
                isBeforeSpecifiedYear(forecast.date, DATA_FORECAST_START_YEAR)
                  ? 0
                  : forecast[getForecastTypeCategory(0, isCredits)]?.variance
              }
              secondDifference={
                isGeneratedForecast(forecast.date) ||
                isBeforeSpecifiedYear(forecast.date, DATA_FORECAST_START_YEAR)
                  ? 0
                  : forecast[getForecastTypeCategory(1, isCredits)]?.variance
              }
              additionalDifference={
                isGeneratedForecast(forecast.date) ||
                isBeforeSpecifiedYear(forecast.date, DATA_FORECAST_START_YEAR)
                  ? 0
                  : forecast[getForecastTypeCategory(2, isCredits)]?.variance
              }
              totalDifference={
                isGeneratedForecast(forecast.date) ||
                isBeforeSpecifiedYear(forecast.date, DATA_FORECAST_START_YEAR)
                  ? 0
                  : forecast.monthTotal.variance
              }
              firstVariancePercent={
                forecast?.[getForecastTypeCategory(0, isCredits)]
                  ?.variancePercent || 0
              }
              secondVariancePercent={
                forecast?.[getForecastTypeCategory(1, isCredits)]
                  ?.variancePercent || 0
              }
              additionalVariancePercent={
                forecast?.[getForecastTypeCategory(2, isCredits)]
                  ?.variancePercent || 0
              }
              totalVariancePercent={forecast?.monthTotal?.variancePercent || 0}
            />
          </div>
        ))}

      {unpublishedGraphData &&
        Array.isArray(unpublishedGraphData) &&
        unpublishedGraphData.map((monthData, index) => (
          <div
            data-test={`inventory-bar-graph-forecast-${index}`}
            key={`forecast-${index}`}
            className="inventory-graph-element max-w-[15rem] 2xl:max-w-[15rem] flex flex-col items-center"
          >
            <div
              data-test={`inventory-bar-forecast-header-${index}`}
              className={`inventory-label current-label-space ${showPreviousCurrentOrForecastLabel(
                index,
                monthData,
              )}`}
            >
              {getDisplayLabel(monthData.date)}
            </div>
            <ForecastBarGraphWidget
              current={isCurrentDate(monthData)}
              date={monthData.date}
              graphMaxValue={graphMaxValue}
              firstValue={
                monthData[getForecastTypeCategory(0, isCredits)]?.actualValue ??
                0
              }
              secondValue={
                monthData[getForecastTypeCategory(1, isCredits)]?.actualValue ??
                0
              }
              additionalValue={
                monthData[getForecastTypeCategory(2, isCredits)]?.actualValue ??
                0
              }
              totalValue={monthData.monthTotal.actualValue}
              firstDifference={
                monthData[getForecastTypeCategory(0, isCredits)]?.variance || 0
              }
              secondDifference={
                monthData[getForecastTypeCategory(1, isCredits)]?.variance || 0
              }
              additionalDifference={
                monthData[getForecastTypeCategory(2, isCredits)]?.variance || 0
              }
              totalDifference={getTotalDifference(monthData, index)}
              firstVariancePercent={
                monthData?.[getForecastTypeCategory(0, isCredits)]
                  ?.variancePercentage || 0
              }
              secondVariancePercent={
                monthData?.[getForecastTypeCategory(1, isCredits)]
                  ?.variancePercentage || 0
              }
              additionalVariancePercent={
                monthData?.[getForecastTypeCategory(2, isCredits)]
                  ?.variancePercentage || 0
              }
              totalVariancePercent={
                monthData?.monthTotal?.variancePercentage || 0
              }
              isUnpublished
              isWestCoast={isWestCoast}
            />
          </div>
        ))}
      <div
        data-test="inventory-graph-forward-arrow-wrapper"
        className="self-end pr-2"
      >
        <button
          type="button"
          data-test="inventory-graph-forward-arrow"
          onClick={() => onNext()}
          // disable next button when the last item in the Forecast array is displayed at the end of the graph/table column,
          disabled={forecastData[forecastData.length - 1]?.date?.month === 12}
        >
          <RightOutlined
            className={`mb-4 ${
              forecastData[forecastData.length - 1]?.date?.month === 12 &&
              "disabled-arrow-btn"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

ForecastGraphSet.propTypes = {
  graphData: PropTypes.array,
  fullYearData: PropTypes.array,
  unpublishedGraphData: PropTypes.arrayOf(
    PropTypes.shape({
      gas: PropTypes.shape({value: PropTypes.number, date: PropTypes.string}),
      diesel: PropTypes.shape({
        value: PropTypes.number,
        date: PropTypes.string,
      }),
    }),
  ),
  isCredits: PropTypes.bool,
  isWestCoast: PropTypes.bool,
};

export default ForecastGraphSet;
