import {useState} from "react";
import PropTypes from "prop-types";
import {ReactSelectOverrides} from "@bphxd/ds-core-react";
import CreatableSelect from "react-select/creatable";
import {useController} from "react-hook-form";
import {FormGroup, FormFeedback, Label} from "reactstrap";
import {MESSAGES} from "modules/co-processing/constants/creditGen";

const {ClearIndicator, MultiValueContainer, MultiValueLabel, MultiValueRemove} =
  ReactSelectOverrides;

const BP_EMAIL_PATTERN = /.*@bp\.com$/;
const DELIMITER_REGEX = /[\s,;]+/;

const isValidEmail = (email) =>
  BP_EMAIL_PATTERN.test(String(email).toLowerCase());

const invalidEmailFormatMsg = `${MESSAGES.INVALID_EMAIL_FORMAT} Please include a valid domain: @bp.com.`;

const EmailInputField = ({control, name, label, clearErrors, setError}) => {
  const {
    field: {onChange, value},
    fieldState: {error},
  } = useController({
    name,
    control,
    rules: {
      required: `${label} email required`,
      validate: {
        emailFormat: (emails) =>
          emails.every(isValidEmail) || invalidEmailFormatMsg,
      },
    },
    defaultValue: [],
  });

  const [emailValue, setEmailValue] = useState("");

  const handleInputChange = (newValue, meta) => {
    if (meta.action === "input-change") {
      setEmailValue(newValue);
      clearErrors(name);
    }
  };

  const handleKeyDown = (event) => {
    if (!emailValue) return;

    if (event.key === "Enter" || DELIMITER_REGEX.test(event.key)) {
      event.preventDefault();

      if (isValidEmail(emailValue)) {
        onChange([...value, emailValue]); // Update form value with new email
        setEmailValue(""); // Clear input after adding the email
        clearErrors(name);
      } else {
        setError(name, {
          type: "custom",
          message: invalidEmailFormatMsg,
        });
      }
    }
  };

  const handleEmailChange = (newValue, meta) => {
    if (meta.action === "remove-value" || meta.action === "pop-value") {
      onChange(newValue.map((item) => item.value)); // Update form value after removing an email
    }
  };

  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <CreatableSelect
        classNamePrefix="react-select"
        components={{
          MultiValueContainer,
          MultiValueLabel,
          MultiValueRemove,
          ClearIndicator,
          DropdownIndicator: null,
        }}
        onChange={handleEmailChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        inputValue={emailValue}
        value={value?.map((e) => ({label: e, value: e}))}
        isClearable={false}
        isMulti
        menuIsOpen={false}
        placeholder="Recipients..."
      />
      {error && (
        <FormFeedback style={{display: "block"}}>{error?.message}</FormFeedback>
      )}
    </FormGroup>
  );
};
EmailInputField.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  clearErrors: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default EmailInputField;
