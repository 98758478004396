import {useState} from "react";
import PropTypes from "prop-types";
import {useUserSettings, formatNumber} from "providers/userSettings";

import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import _ from "lodash";
import TooltipContent from "./TooltipContent";
import "./index.scss";

/* Styles */
/* Notes: StyledComponent was not working well on all Rechart components */
const axisColor = "#7b7b7b";
const axisLabelColor = "#a3a3a3";
const actualizedCreditsColor = "#2e7300";
const commitedCreditsColor = "#88bc00";
const actualizedDeficitsColor = "#fb7300";
const commitedDeficitsColor = "#fed200";
const cartesianGridColor = "#f0f0f0";
const lineColor = "#304ffe";
const tooltipBackgroundColor = "#232323";

const legendStyle = {
  paddingTop: "50px",
  paddingLeft: "55px",
  height: 100,
  fontSize: "12px",
};
const axisLineStyle = {stroke: axisColor};
const axisTickStyle = {fontSize: 10, fill: axisLabelColor};
const tooltipStyle = {
  background: tooltipBackgroundColor,
  borderRadius: "5px",
  padding: "13px 20px 18px",
  color: "white",
  fontSize: "14px",
  boxShadow: `${tooltipBackgroundColor} 5px 5px 20px`,
};

const SummaryGraph = ({
  dataGraph,
  loading,
  ytickNumber,
  containerHeight,
  isExpandedView,
}) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  const [selectedDotPos, setSelectedDotPos] = useState(0);
  const [selectedDotPayload, setSelectedDotPayload] = useState();

  const renderLegend = ({payload}) => {
    const credits = payload.filter((entry, _) =>
      entry.dataKey.startsWith("credit"),
    );
    const deficits = payload.filter((entry, _) =>
      entry.dataKey.startsWith("deficit"),
    );

    const listComponent = (title, elements) => (
      <ul>
        <span className="legend-text text-right d-inline-block mr-1">
          {title}
        </span>
        {elements.map((entry, index) => {
          const value = entry.value.split(" ")[0];
          return (
            <li
              key={`item-${index}`}
              className="legend-arial d-inline-block mx-2"
            >
              {value !== "Forecasted" ? (
                <div
                  className="legend-icon mr-1"
                  style={{
                    backgroundColor: entry.color,
                  }}
                ></div>
              ) : (
                <div
                  className="legend-icon mr-1"
                  style={{
                    border: "solid 2px",
                    borderColor: entry.payload.children[0].props.stroke,
                  }}
                ></div>
              )}
              {value}
            </li>
          );
        })}
      </ul>
    );
    return (
      <div className="flex">
        <div className="legend-info-first">
          {listComponent("Credits:", credits)}
          {listComponent("Deficits:", deficits)}
        </div>
        <div
          className="legend-icon ml-2 mr-1"
          style={{
            backgroundColor: lineColor,
          }}
        ></div>
        Net
      </div>
    );
  };

  const renderDot = (data) => {
    const {key, dataKey, index, payload, value, ...svgProps} = data;
    setSelectedDotPos(svgProps.cy);
    setSelectedDotPayload(payload);
    return <circle {...svgProps} />;
  };

  const maxCreditActualized = _.maxBy(
    dataGraph,
    "creditActualizedVolume",
  )?.creditActualizedVolume;
  const maxCreditCommitted = _.maxBy(
    dataGraph,
    "creditCommittedVolume",
  )?.creditCommittedVolume;
  const minDeficitActualized = _.minBy(
    dataGraph,
    "deficitActualizedVolume",
  )?.deficitActualizedVolume;
  const minDeficitCommitted = _.minBy(
    dataGraph,
    "deficitCommittedVolume",
  )?.deficitCommittedVolume;

  const minNetSum = _.minBy(dataGraph, "volume")?.volume;
  const maxNetSum = _.maxBy(dataGraph, "volume")?.volume;

  const greaterNetSum = _.max([Math.abs(minNetSum), Math.abs(maxNetSum)]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (dataGraph && dataGraph?.length === 0) {
    return <div>No data found</div>;
  }

  /* Domain generation to center the bars on Y axis */
  const totalCredit = maxCreditActualized + maxCreditCommitted;
  const totalDeficit =
    Math.abs(minDeficitActualized) + Math.abs(minDeficitCommitted);
  const greaterBar = _.max([totalCredit, totalDeficit]);
  const greaterValue = _.max([1.5 * greaterBar, greaterNetSum]);

  const YAxisDomain = [-greaterValue, greaterValue];

  /* Ticks generation */
  const positiveTicks = _.rangeRight(
    0,
    greaterValue + 1,
    greaterValue / ytickNumber,
  ).map((num) => Math.round(num));
  const negativeTicks = _.rangeRight(
    -greaterValue,
    0 + 1,
    greaterValue / ytickNumber,
  ).map((num) => Math.round(num));
  const ticks = _.uniq([...positiveTicks, ...negativeTicks]);

  const intervalsExpandableView = dataGraph?.length > 25 ? 2 : 0;
  const intervalsNonExpandableView = dataGraph?.length > 12 ? 3 : 0;

  return (
    <ResponsiveContainer width="100%" height={containerHeight}>
      <ComposedChart
        barSize={26}
        data={dataGraph}
        stackOffset="sign"
        margin={{left: 12, top: 30, bottom: 30}}
        style={{stroke: "#fff"}}
      >
        <CartesianGrid stroke={cartesianGridColor} />
        <Bar
          name="Actualized Deficits"
          dataKey="deficitActualizedVolume"
          fill={actualizedDeficitsColor}
          stackId="stack"
        />
        <Bar
          name="Committed Deficits"
          dataKey="deficitCommittedVolume"
          fill={commitedDeficitsColor}
          stackId="stack"
        />
        <Bar
          name="Forecasted Deficits"
          dataKey="deficitForecastVolume"
          fill="#ffffff"
          stackId="stack"
        >
          {dataGraph.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              stroke={actualizedDeficitsColor}
              strokeWidth={3}
            />
          ))}
        </Bar>
        <Bar
          name="Actualized Credits"
          dataKey="creditActualizedVolume"
          fill={actualizedCreditsColor}
          stackId="stack"
        />
        <Bar
          name="Committed Credits"
          dataKey="creditCommittedVolume"
          fill={commitedCreditsColor}
          stackId="stack"
        />
        <Bar
          name="Forecasted Crredits"
          dataKey="creditForecastVolume"
          fill="#ffffff"
          stackId="stack"
        >
          {dataGraph.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              stroke={actualizedCreditsColor}
              strokeWidth={3}
            />
          ))}
        </Bar>

        <XAxis
          dataKey="timePeriod"
          tick={axisTickStyle}
          tickSize={3}
          axisLine={axisLineStyle}
          // This is a feature where you can set the interval of the ticks depending of how many bars we have,
          // Design department have agreed to this rule.
          interval={
            isExpandedView
              ? intervalsExpandableView
              : intervalsNonExpandableView
          }
        />

        <ReferenceLine y={0} stroke="black" strokeWidth={1} />

        <YAxis
          fill={axisColor}
          tick={axisTickStyle}
          axisLine={axisLineStyle}
          domain={YAxisDomain}
          ticks={ticks}
          tickSize={3}
          tickFormatter={(v) => formatNumber(v, decimalFormat, 0)}
        />
        <Line
          name="Net Position"
          strokeWidth={2}
          type="linear"
          dataKey="volume"
          dot={{fill: lineColor}}
          stroke={lineColor}
          activeDot={renderDot}
        />
        <Tooltip
          position={{y: selectedDotPos}}
          wrapperStyle={tooltipStyle}
          content={<TooltipContent selectedPayload={selectedDotPayload} />}
        />
        <text
          y="100%"
          dy={-135}
          dx={70}
          className="credits-deficits-text"
          textAnchor="left"
        >
          Deficits
        </text>
        <text
          y="0%"
          dy={15}
          dx={70}
          className="credits-deficits-text"
          textAnchor="left"
        >
          Credits
        </text>
        <Legend
          iconType="rect"
          wrapperStyle={legendStyle}
          content={renderLegend}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

SummaryGraph.propTypes = {
  dataGraph: PropTypes.array,
  loading: PropTypes.bool,
  ytickNumber: PropTypes.number,
  containerHeight: PropTypes.number,
  isExpandedView: PropTypes.bool,
};

export default SummaryGraph;
