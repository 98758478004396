import {gql} from "@apollo/client";

export const SAF_INCOMING_AND_OUTGOING_COUNT = gql`
  query bioLcGetInOutDocsCount($siteReferenceId: String, $divisionId: String) {
    bioLcGetInOutDocsCount(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      error
      statusCode
      inboundCount
      outboundCount
    }
  }
`;

export default SAF_INCOMING_AND_OUTGOING_COUNT;
