import React from "react";
import {useQuery} from "@apollo/client";
import tw from "twin.macro";
import {useActiveFilters} from "modules/forecasting/context/ForecastingFilterContext";
import {FORECAST_TOTAL_DATA} from "graphql/forecast-data";
import {
  isCreditType as isCredits,
  isDeficitType as isDeficits,
} from "modules/forecasting/helpers/forecastFilterUtils";
import {mapPublishedToKPIs} from "modules/forecasting/components/PublishedViewByForecastType";
import TotalCharts from "modules/forecasting/components/GraphSection/TotalCharts";
import ViewByForecastType from "modules/forecasting/components/ViewByForecastType";
import PublishedViewBarChart from "modules/forecasting/components/GraphSection/PublishedViewBarChart";
import EmptyData from "modules/forecasting/components/EmptyData";
import InventoryWrapper from "modules/forecasting/components/InventoryWrapper";
import KPICardSection from "modules/forecasting/components/KPICardSection";
import GasAndDieselLegend from "modules/forecasting/components/GasAndDieselLegends";
import RinLegend from "modules/forecasting/components/RinLegend";
import TotalValuesTable from "./TotalValuesTable";
import {transformApiData} from "./utils";

const LandingPage = () => {
  const {year} = useActiveFilters() ?? {};
  const {data, loading} = useQuery(FORECAST_TOTAL_DATA, {
    fetchPolicy: "cache-and-network",
    variables: {year: parseInt(year, 10)},
  });

  if (!data && !loading) return <EmptyData />;

  return (
    <InventoryWrapper loading={loading}>
      <ViewByForecastType
        data={data}
        transformerFn={transformApiData}
        renderKPI={({state, forecastTypes}) =>
          forecastTypes.map((forecastType) => (
            <KPICardSection
              key={forecastType}
              {...mapPublishedToKPIs({
                forecastType,
                variance: state?.[forecastType].variance,
              })}
            />
          ))
        }
      >
        {({forecastTypeName, pageData}) => {
          return (
            <React.Fragment key={forecastTypeName}>
              <ChartAndTableSet>
                <Legends>
                  {
                    {
                      [isCredits(forecastTypeName)]: <RinLegend isWestCoast />,
                      [isDeficits(forecastTypeName)]: <GasAndDieselLegend />,
                    }.true
                  }
                </Legends>

                <ChartsBlock>
                  <TotalCharts
                    forecastType={forecastTypeName}
                    data={pageData}
                  />

                  <PublishedViewBarChart pageData={pageData ?? []} />
                </ChartsBlock>

                <TotalValuesTable
                  forecastType={forecastTypeName}
                  loading={loading}
                  pageData={pageData}
                />
              </ChartAndTableSet>
            </React.Fragment>
          );
        }}
      </ViewByForecastType>
    </InventoryWrapper>
  );
};

export default LandingPage;

const ChartAndTableSet = tw.div`col-span-full`;
const ChartsBlock = tw.div`flex flex-row gap-2`;
const Legends = tw.div`flex flex-row pl-3`;
