import {gql} from "@apollo/client";
import {client} from "providers/Apollo";
import {convertToNumber} from "providers/userSettings";
import {FETCH_POLICY_NETWORK_ONLY} from "constants/config";

export const POS_LIST = gql`
  query PosList(
    $page: Int
    $perPage: Int
    $fuelType: [String]
    $feedstockType: [String]
    $location: [String]
  ) {
    getPOSRecordList(
      event: {
        page: $page
        perPage: $perPage
        fuelType: $fuelType
        feedstockType: $feedstockType
        location: $location
        countryCode: "GBR"
        certificateType: "ISCC"
      }
    ) {
      data {
        GBR_ISCC {
          certificateNumber
          certificateType
          posId
          contractNumber
          defaultGhgSaving
          deliveringVessel
          euVoluntaryScheme
          euVoluntarySchemePartialProofNumber
          feedstockAllocationOfOutturnPct
          feedstockType
          feedstockOrigin
          finalBiofuelProduction
          fuelType
          location
          issuanceDate
          ghgValuePct
          ghgEmissions
          outturnFeedstockVolumeSl
          pdfFileName
          outturnVolumeTotalByPosSl
          placeOfIssuance
          productionProcess
          quantity
          quantityUnits
          remainingFeedstockVolumeSl
          uploadDate
          vesselReceiptDate
          volumeForEthanol
          runningBalance
          newRunningBalance
          volumeDrawn
          certificateType
          supplier
        }
      }
      statusCode
      totalCount
    }
  }
`;

export const POS_LUX_LIST = gql`
  query PoSLuxList($page: Int, $perPage: Int) {
    getPOSRecordList(
      event: {page: $page, perPage: $perPage, countryCode: "LUX"}
    ) {
      data {
        LUX {
          posId
          posNumber
          dateOfDispatch
          location
          databaseReference
          volumeM3
          ghgIntensity
          ghgEmission
          countryCode
          countryOfOrigin
          typeOfRawMaterial
          typeOfProduct
          recepientAddress
          notes
          ghgVolume
          ghgForTheDay
          certificateType
          pdfFileName
          uploadDate
          certificateNumber
          contractNumber
        }
      }
      statusCode
      totalCount
    }
  }
`;

export const POS_FILTER_LIST = gql`
  query PosFilterList {
    getPOSFilterList(event: {certificateType: "ISCC", countryCode: "GBR"}) {
      data {
        GBR_ISCC {
          fuelType {
            title
            feedstockType {
              location
              title
            }
          }
        }
      }
      errors {
        code
        message
      }
      message
      statusCode
      totalCount
    }
  }
`;

export const POS_PL_FILTER_LIST = gql`
  query PosFilterList {
    getPOSFilterList(event: {countryCode: "POL"}) {
      data {
        POL {
          certificateType {
            title
            supplier {
              title
              fuelType {
                title
                feedstockType
              }
            }
          }
        }
      }
      errors {
        code
        message
      }
      message
      statusCode
      totalCount
    }
  }
`;

export const POS_NL_ISCC_LIST = gql`
  query PosNLISCCList($page: Int, $perPage: Int) {
    getPOSRecordList(
      event: {
        page: $page
        perPage: $perPage
        countryCode: "NLD"
        certificateType: "ISCC"
      }
    ) {
      data {
        NLD_ISCC {
          art29Redii
          certificateType
          comments
          contractNumber
          countryOfOrigin
          cultivatedIntermediateCrop
          dateOfDispatchMaterial
          dateOfIssuance
          dateOfOperation
          energyContentMj
          euRedCompliant
          ghgEccr
          ghgEccs
          ghgEcsa
          ghgEec
          ghgEee
          ghgEl
          ghgEp
          ghgEtd
          ghgEu
          ghgValue
          ilucRiskFeedstock
          meetsDefinitionWasteResidue
          pdfFileName
          posId
          quantity
          recipient
          recipientAddress
          uniquePosNumber
          typeOfRawMaterial
          typeOfProduct
          uploadDate
        }
      }
      statusCode
      totalCount
    }
  }
`;

export const POS_NL_NABISY_LIST = gql`
  query PosNLNabisyList($page: Int, $perPage: Int) {
    getPOSRecordList(
      event: {
        page: $page
        perPage: $perPage
        countryCode: "NLD"
        certificateType: "Nabisy"
      }
    ) {
      data {
        NLD_Nabisy {
          arisingFromAgriculture
          biomassConforms_4_5
          countryOfOrigin
          comments
          certificateType
          dateOfIssuance
          dateOfOperation
          energyContentMj
          dateOfReceipt
          ghgEccr
          ghgEccs
          ghgEcsa
          ghgEec
          ghgEee
          ghgEp
          ghgEl
          ghgEtd
          ghgEu
          ghgValue
          partialProof
          pdfFileName
          placeOfReceipt
          posId
          producedFromResidueWaste
          quantity
          typeOfBiomass
          typeOfProduct
          uploadDate
        }
      }
      statusCode
      totalCount
    }
  }
`;

export const POS_FILE_PARSE_TRIGGER = gql`
  query posFileTrigger($fileName: String!, $country: String!) {
    posDataExtractor(event: {fileName: $fileName, countryCode: $country}) {
      data {
        url
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const EDIT_POS_UK_RECORD = gql`
  mutation editPOSUKRecord($data: UpdatePOSRecordRequest!) {
    updatePOSRecord(event: $data) {
      data {
        certificateType
        certificateNumber
        comments
        contractNumber
        defaultGhgSaving
        deliveringVessel
        euVoluntaryScheme
        euVoluntarySchemePartialProofNumber
        feedstockAllocationOfOutturnPct
        feedstockOrigin
        feedstockType
        finalBiofuelProduction
        fuelType
        ghgEmissions
        ghgValuePct
        issuanceDate
        outturnFeedstockVolumeSl
        location
        outturnVolumeTotalByPosSl
        pdfFileName
        placeOfIssuance
        posId
        productionProcess
        quantity
        quantityUnits
        remainingFeedstockVolumeSl
        uploadDate
        vesselReceiptDate
        volumeForEthanol
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const EDIT_POS_PL_RECORD = gql`
  mutation editPOSPLRecord($data: UpdatePOSRecordRequest!) {
    updatePOSRecord(event: $data) {
      data {
        notes
        posId
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const EDIT_POS_LUX_RECORD = gql`
  mutation editPOSLUXRecord($data: UpdatePOSRecordRequest!) {
    updatePOSRecord(event: $data) {
      data {
        location
        notes
        posId
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const POS_DOWNLOAD_FILE = gql`
  query posFilePreview($countryCode: String!, $fileName: String!) {
    posFileDownloadURL(
      event: {countryCode: $countryCode, fileName: $fileName}
    ) {
      statusCode
      url
      url_download
    }
  }
`;

export const POS_EXPORT = gql`
  query posExportData(
    $countryCode: String!
    $selectedRow: [String]
    $Year: String
    $dateformat: String
  ) {
    posDataExport(
      event: {
        countryCode: $countryCode
        selectedRow: $selectedRow
        Year: $Year
        dateformat: $dateformat
      }
    ) {
      data {
        url
      }
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const updatePosUKISCCRecord = async (values, decimalFormat) => {
  const newModifiedState = Object.fromEntries(
    Object.entries(values).filter(([key, value]) => value !== ""),
  );
  const volumeForEthanol = convertToNumber(
    newModifiedState.volumeForEthanol ?? null,
    decimalFormat,
  );
  const feedstockAllocationOfOutturnPct = convertToNumber(
    newModifiedState.feedstockAllocationOfOutturnPct ?? null,
    decimalFormat,
  );
  const runningBalance = convertToNumber(
    newModifiedState.runningBalance ?? null,
    decimalFormat,
  );
  const request = {
    certificateType: "ISCC",
    countryCode: "GBR",
    data: {
      GBR_ISCC: {
        posId: newModifiedState.posId,
        productionProcess: newModifiedState.productionProcess ?? null,
        volumeForEthanol: volumeForEthanol === 0 ? null : volumeForEthanol,
        runningBalance: runningBalance === 0 ? null : runningBalance,
        newRunningBalance: newModifiedState.newRunningBalance,
        feedstockAllocationOfOutturnPct:
          feedstockAllocationOfOutturnPct === 0
            ? null
            : feedstockAllocationOfOutturnPct,
      },
    },
  };
  return client.mutate({
    mutation: EDIT_POS_UK_RECORD,
    variables: {
      data: request,
    },
    refetchQueries: ["PosList"],
  });
};

export const updatePosPlRecord = async ({posId, notes}) => {
  const request = {
    countryCode: "POL",
    data: {
      POL: {
        posId,
        notes,
      },
    },
  };
  return client.mutate({
    mutation: EDIT_POS_PL_RECORD,
    variables: {
      data: request,
    },
    refetchQueries: ["getPOSPLList"],
  });
};

export const updatePosLuxRecord = async ({
  posId,
  location,
  databaseReference,
  notes,
}) => {
  const request = {
    countryCode: "LUX",
    data: {
      LUX: {
        posId,
        location,
        databaseReference,
        notes,
      },
    },
  };

  return client.mutate({
    mutation: EDIT_POS_LUX_RECORD,
    variables: {
      data: request,
    },
    refetchQueries: ["PoSLuxList"],
  });
};

export const downloadExportFile = async (
  year,
  selectedRow,
  countryCode,
  dateformat,
) => {
  let rows = [];
  if (selectedRow) {
    rows = selectedRow.map((obj) => obj.posId);
  } else {
    rows = null;
  }

  return client.query({
    query: POS_EXPORT,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    variables: {
      countryCode,
      selectedRow: rows,
      Year: year,
      dateformat,
    },
  });
};

export const POS_PL_LIST = gql`
  query getPOSPLList(
    $page: Int
    $perPage: Int
    $certificateType: String
    $supplier: [String]
    $fuelType: [String]
    $feedstockType: [String]
  ) {
    getPOSRecordList(
      event: {
        page: $page
        perPage: $perPage
        countryCode: "POL"
        certificateType: $certificateType
        supplier: $supplier
        fuelType: $fuelType
        feedstockType: $feedstockType
      }
    ) {
      data {
        POL {
          b100
          certificateNumber
          certificateType
          countryCode
          countryOfOrigin
          dateOfIssuance
          emissionGCO2eqMJ
          ghgEmissionSaving
          monthNumber
          placeOfIssuance
          receiptDate
          notes
          pdfFileName
          posId
          quantity
          supplier
          type
          typeOfProduct
          typeOfRawMaterial
          uniqueNumberOfSustainabilityDeclaration
          weight
          uploadDate
        }
      }
      statusCode
      totalCount
    }
  }
`;

export const POS_SHAREPOINT_EXTRACTOR = gql`
  query posSharePointExtractor($countryCode: String!, $userEmail: String!) {
    posSharePointExtractor(
      event: {countryCode: $countryCode, userEmail: $userEmail}
    ) {
      atLeastOnePdf
      errors {
        code
        message
      }
      message
      statusCode
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query getNotificationEvent($emailId: String!) {
    getNotification(event: {userEmail: $emailId}) {
      data {
        items {
          create_date_time
          created_by
          id
          message
          status
          update_date_time
          updated_by
          user_email
          country
        }
      }
      errors {
        code
        message
      }
      expectResult
      statusCode
    }
  }
`;

export const getNotificationApi = async (emailId) => {
  return client.query({
    query: GET_NOTIFICATION,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    variables: {
      emailId,
    },
  });
};

export const CLEAR_NOTIFICATION = gql`
  query clearNotification($id: String!, $userEmail: String!) {
    inactivateNotification(
      event: {id: $id, status: "inactive", userEmail: $userEmail}
    ) {
      errors {
        code
        message
      }
      statusCode
    }
  }
`;

export const clearNotificationApi = async (res, username) => {
  return client.query({
    query: CLEAR_NOTIFICATION,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    variables: {
      id: res?.id ?? "",
      userEmail: username,
    },
  });
};

export const posSharepointExtractorApi = async (countryCode, userEmail) => {
  return client.query({
    query: POS_SHAREPOINT_EXTRACTOR,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
    variables: {
      countryCode,
      userEmail,
    },
  });
};

export default {
  POS_LIST,
  POS_NL_ISCC_LIST,
  POS_FILE_PARSE_TRIGGER,
  POS_NL_NABISY_LIST,
  POS_FILTER_LIST,
  POS_PL_LIST,
  POS_DOWNLOAD_FILE,
  POS_SHAREPOINT_EXTRACTOR,
  GET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  POS_LUX_LIST,
};
