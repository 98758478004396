export const MB_BALANCE_GROUP_CONFIG_PAGE = {
  title: "Balance group",
  description: "Different mass balances groups",
};

export const MB_BALANCE_TYPE_CONFIG_PAGE = {
  title: "Mass Balance Type",
  description:
    "Types of mass balance available, trader, trader with storage and co-processing",
};

export const MATERIAL_MASTER_CONFIG_PAGE = {
  title: "Material master",
  description:
    "Names and codes to be used for different products and raw materials globally",
};

export const MB_LG_LOCATION_CONFIG_PAGE = {
  title: "Mass balance location group",
  description: "Locaions that are part of different mass balance",
};

export const COUNTRY_CODE_CONFIG_PAGE = {
  title: "Country code",
  description: "Codes to be used for countries globally",
};

export const PROCESSING_UNIT_CONFIG_PAGE = {
  title: "Processing units",
  description: "All processing units recognised within all locations",
};

export const MASS_BALANCE_MATERIALS_CONFIG_PAGE = {
  title: "Mass balance materials",
  description:
    "Materials that are accepted within different mass balance groups",
};
export const BUSINESS_PARTNER_CONFIG_PAGE = {
  title: "Business partner",
  description: "Suppliers / vendors, customers and legal entities",
};

export const BUSINESS_PARTNER_ADDRESS_CONFIG_PAGE = {
  title: "Address",
  description:
    "Address reckonised by the system, mapped to business partners and / or locations",
};

export const LOCATION_DETAILS_CONFIG_PAGE = {
  title: "Locations",
  description:
    "All BioVerse locations in different countries/divisions will be categorized as refineries, legel entities, terminals, airports, or storage",
};

export const MB_CONFIG_PAGE = {
  title: "Mass balance configuration",
  description: "Configuration of different mass balances",
};

export const TRANSPORTATION_EMISSIONS_CONFIG_PAGE = {
  title: "Transport Emissions",
  description:
    "Emissions to be added for transportation between two locations for different transportation methods",
};

export const PRODUCT_BASELINE_EMISSION_DETAIL_CONFIG_PAGE = {
  title: "Product baseline emissions",
  description:
    "Baseline emission for product to be used in different regions/divisions",
};

export const CERTIFICATE_SCHEME_CONFIG_PAGE = {
  title: "Certificate scheme",
  description: "All certification schemes recognised within BioVerse",
};

export const LOCATION_YIELD_DETAILS_CONFIG_PAGE = {
  title: "Location yield details",
  description:
    "Yield ratios to be used at different refineries (and processing units if needed) for different yield types e.g SNS Vs ISCC",
};

export const NATIONAL_REGISTRY_CONFIG_PAGE = {
  title: "National registry",
  description:
    "All national registry codes, for current countries and divisions in BioVerse",
};

export const PRODUCT_DEFAULT_VALUES_CONFIG_PAGE = {
  title: "Product default values",
  description:
    "TDVs and DDV for materials to be used in different regions / divisons",
};

export const COUNTRY_VARIATION_CONFIG_PAGE = {
  title: "Country code variations",
  description: "Other code variations to be used for countries globally",
};

export const PRODUCTION_EMISSION_NEW_CONFIG_PAGE = {
  title: "Product emissions",
  description:
    "Emission factors to be included for production at various refineries (and processing units, if necessary)",
};

export const CERTIFICATE_SCHEME_VARIATION_CONFIG_PAGE = {
  title: "Certification scheme variations",
  description: "All certification scheme variations recognised within BioVerse",
};

export const TAX_PLANT_REGISTRY_CONFIG_PAGE = {
  title: "Tax plant registry",
  description:
    "The tax plant registry configuration table applies only to Germany",
};

export const CERTIFICATE_ID_CONFIG_PAGE = {
  title: "Certificate ID",
  description:
    "Certificate ID numbers to be used in different locations when generating certificates for sales",
};
