const getVariancePercentage = (variance, value, differenceValue) => {
  let res;
  switch (true) {
    case variance < 0 && !value:
      res = -100;
      break;
    case variance && !value:
      res = 100;
      break;
    case !variance:
      res = 0;
      break;
    case differenceValue === value:
      res = 0;
      break;
    case variance && value:
      res = Math.round((variance / value) * 100);
      break;
    default:
      res = Math.round((variance / value) * 100);
      break;
  }
  return res;
};

export default getVariancePercentage;
