import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  Add24,
  Download24,
  Filter24,
  Link24,
  Remove24,
  TriangleRightSolid24,
} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {
  COUNTRY_NORWAY,
  COUNTRY_POLAND,
  COUNTRY_SPAIN,
  COUNTRY_SWEDEN,
  COUNTRY_UK,
} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {
  ExportAnnualAPI,
  ExportSelectedRowsAPI,
} from "graphql/docManager/ExportIncomingDetails";
import {ALL_INCOMING_DOCUMENTS} from "graphql/docManager/incomingDocuments";
import {
  DOC_STATUS_TRACKER,
  uploadFile,
} from "graphql/docManager/uploadDocument";
import MARK_AS_COMPLETE from "graphql/es-co-processing/MarkAsComplete";
import {orderBy, uniqBy} from "lodash";
import {
  DOC_STATUS,
  POLLING_FIRST_TRIGGER_IN_SECONDS,
  POLLING_INTERVAL_IN_SECONDS,
} from "modules/DocManager/constants";
import moment from "moment";
import process from "process";
import {useUserSettings} from "providers/userSettings";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

import {useAppSetting} from "providers/appSetting/context.js";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {downloadFile} from "utils/helpers/downloadFile";
import {
  getDivisionData,
  getSiteDetails,
  setCountryModuleDetails,
} from "utils/helpers/getAppSetting.js";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import DocManagerFilter from "../Filters/DocManagerFilter";
import UploadDocuments from "../UploadDocuments";
import PreviewDocumentModal from "../UploadDocuments/PreviewDocumentModal";
import getColumnsPoland from "./Columns/ColumnsPoland";
import getColumnsSAF from "./Columns/ColumnsSAF";
import getColumnsSpain, {getColumnsSpainGMB} from "./Columns/ColumnsSpain";
import getColumnsUK from "./Columns/ColumnsUK";

import DocumentTable from "../Table/DocumentTable";
import ResponseModal from "../UploadDocuments/ResponseModal";

import NotificationBar from "./NotificationBar";

import "../../pages/styles.scss";

const IncomingDocumentComponent = () => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [rowSelection, setRowSelection] = useState({});
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const [isMarkAsCompleteOpen, setIsMarkAsCompleteOpen] = useState(false);
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageIndexNumber = searchParams.get("pageNo") ?? 0;

  setCountryModuleDetails(countryName, "documentManager");
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);

  const [pageIndex, setPageIndex] = useState(pageIndexNumber);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  const [showFilter, setShowFilter] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [receivingPointList, setReceivingPointList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [isStillUploading, setIsStillUploading] = useState(false);

  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [supplierName, setSupplierName] = useState(undefined);
  const [receivingPointName, setReceivingPointName] = useState(undefined);
  const [productName, setProductName] = useState(undefined);
  const [materialName, setMaterialName] = useState(undefined);
  const [statusName, setStatusName] = useState(undefined);
  const [originName, setOriginName] = useState(undefined);

  const [sortedData, setSortedData] = useState(undefined);

  const {country: selectedCountry} = useParams();

  const siteReferenceId = siteReferenceData?.siteReferenceId;

  const sortByItem = searchParams.get("sortBy");

  const navigate = useNavigate();

  const divisionData = useMemo(() => {
    if (appSetting?.divisionData) {
      switch (selectedCountry) {
        case COUNTRY_SPAIN:
          return getDivisionData(DIV_CODE_COPRO);
        case COUNTRY_POLAND:
          return getDivisionData(DIV_CODE_GF);
        case COUNTRY_UK:
          return getDivisionData(DIV_CODE_GF);
        case COUNTRY_SWEDEN:
          return getDivisionData(DIV_CODE_SAF);
        case COUNTRY_NORWAY:
          return getDivisionData(DIV_CODE_SAF);
        default:
          return getDivisionData(DIV_CODE_GF);
      }
    }
    return null;
  }, [selectedCountry, appSetting?.divisionData]);

  const divisionId = divisionData?.divisionId;

  const {
    data: incomingRecordsData,
    loading,
    refetch,
  } = useQuery(ALL_INCOMING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {siteReferenceId, divisionId},
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const [markAsComplete] = useMutation(MARK_AS_COMPLETE, {
    onCompleted: ({bioLcCoproEuMarkAsComplete}) => {
      setIsMarkAsCompleteOpen(false);
      if (bioLcCoproEuMarkAsComplete?.statusCode === 200) {
        toast.success(
          bioLcCoproEuMarkAsComplete?.message ??
            "Document successfully marked as completed",
        );
        refetch();
      } else {
        toast.error(
          bioLcCoproEuMarkAsComplete?.message ?? "Failed to mark as completed",
        );
      }
    },
  });

  const handleMarkAsComplete = useCallback(
    (sdNumber) => {
      setIsMarkAsCompleteOpen(true);
      markAsComplete({
        variables: {
          event: {
            changedBy: account?.username,
            sdNumber,
            userAction: "mark_complete",
            siteReferenceId: siteReferenceData?.siteReferenceId,
            divisionId: divisionData?.divisionId,
          },
        },
      });
    },
    [
      account?.username,
      markAsComplete,
      siteReferenceData?.siteReferenceId,
      divisionData?.divisionId,
    ],
  );

  const incomingResponseData =
    incomingRecordsData &&
    incomingRecordsData?.bioLcGetIncomingDocsApi?.documents;

  const divisionDataList =
    incomingRecordsData &&
    incomingRecordsData?.bioLcGetDivisionData?.divisionData;

  useEffect(() => {
    if (incomingResponseData) {
      const supplierItems = incomingResponseData
        ?.filter(
          (x) =>
            x.supplierName !== undefined &&
            x.supplierName !== null &&
            x.supplierName !== "",
        )
        ?.map((a) => a.supplierName);
      const uniqSuppliers = uniqBy(supplierItems ?? []);
      const sortedSupplierList = orderBy(
        uniqSuppliers,
        [(supplier) => supplier.toLowerCase()],
        ["asc"],
      );
      setSupplierList(sortedSupplierList);
      const receivingPointItems = incomingResponseData
        ?.filter(
          (x) =>
            x.recipientReceiptAddress !== undefined &&
            x.recipientReceiptAddress !== null &&
            x.recipientReceiptAddress !== "",
        )
        ?.map((a) => a.recipientReceiptAddress);

      const uniqreceivingPointItems = uniqBy(receivingPointItems ?? []);
      const sortedreceivingPointItemsList = orderBy(
        uniqreceivingPointItems,
        [(recname) => recname.toLowerCase()],
        ["asc"],
      );
      setReceivingPointList(sortedreceivingPointItemsList);

      const productTypeItems = incomingResponseData
        ?.filter(
          (x) =>
            x.productType !== undefined &&
            x.productType !== null &&
            x.productType !== "",
        )
        ?.map((a) => a.productType);

      const uniqproductTypeItems = uniqBy(productTypeItems ?? []);
      const sortedproductTypeItemsList = orderBy(
        uniqproductTypeItems,
        [(prodname) => prodname.toLowerCase()],
        ["asc"],
      );
      setProductList(sortedproductTypeItemsList);

      const rawMaterialTypeItems = incomingResponseData
        ?.filter(
          (x) =>
            x.rawMaterialType !== undefined &&
            x.rawMaterialType !== null &&
            x.rawMaterialType !== "",
        )
        ?.map((a) => a.rawMaterialType);

      const uniqueMaterialTypeItems = uniqBy(rawMaterialTypeItems ?? []);
      const sortedMaterialTypes = orderBy(
        uniqueMaterialTypeItems,
        [(matName) => matName.toLowerCase()],
        ["asc"],
      );
      setMaterialList(sortedMaterialTypes);

      const statusItems = incomingResponseData
        ?.filter(
          (x) => x.state !== undefined && x.state !== null && x.state !== "",
        )
        ?.map((a) => ({
          value: a.state,
          label: DOC_STATUS[a.state],
        }));
      const uniqstatusItems = uniqBy(statusItems ?? [], "value");
      const sortedstatusItemsList = orderBy(
        uniqstatusItems,
        [(prodname) => prodname.value.toLowerCase()],
        ["asc"],
      );
      setStatusList(sortedstatusItemsList);
      const originItems = incomingResponseData
        ?.filter(
          (x) =>
            x.countryCode !== undefined &&
            x.countryCode !== null &&
            x.countryCode !== "",
        )
        ?.map((a) => a.countryCode);
      const uniqoriginItems = uniqBy(originItems ?? []);
      const sortedoriginItemsList = orderBy(
        uniqoriginItems,
        [(orgname) => orgname.toLowerCase()],
        ["asc"],
      );
      setOriginList(sortedoriginItemsList);
    }
  }, [
    incomingResponseData,
    setSupplierList,
    setReceivingPointList,
    setProductList,
    setStatusList,
    setOriginList,
  ]);

  useEffect(() => {
    if (incomingResponseData) {
      const incomingDetails = incomingResponseData ?? [];

      // include parent details into all split details
      const unsortedData = incomingDetails.map(({splitDetails, ...rest}) => ({
        ...rest,
        splitDetails: splitDetails?.map((split) => ({
          ...split,
          state: rest.state,
          sdNumber: split.sdNumber,
          certificateInboundEuId: rest.certificateInboundEuId,
          certificateNumber: rest.certificateNumber,
          rawMaterialType: rest.rawMaterialType,
          supplierName: rest.supplierName,
          recipientReceiptAddress: rest.recipientReceiptAddress,
          productType: rest.productType,
          balance: rest.balance,
          ghgTotal: rest.ghgTotal,
          countryCode: rest.countryCode,
          certificationSystem: rest.certificationSystem,
          supplierDispatchAddress: rest.supplierDispatchAddress,
          feedstockIssueDatez: rest.feedstockIssueDatez,
          ghgEmissionPercentageIn: rest.ghgEmissionPercentageIn,
          bvAuditCreatedDatez: rest.bvAuditCreatedDatez,
          mbLocation: rest.mbLocation,
          mbLocationCode: rest.mbLocationCode,
          materialCode: rest.materialCode,
          mbcycle: rest.mbcycle,
          contractNumber: rest.contractNumber,
        })),
      }));

      setSortedData(unsortedData);
    }
  }, [incomingResponseData]);

  const tableCols = useMemo(() => {
    const hideLtpButton =
      process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;
    switch (selectedCountry) {
      case COUNTRY_SPAIN:
        if (hideLtpButton === "false") {
          return getColumnsSpainGMB(
            dateFormat,
            decimalFormat,
            selectedCountry,
            handleMarkAsComplete,
            siteReferenceId,
            divisionId,
            divisionData?.divisionCode,
            process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY,
          );
        }
        return getColumnsSpain(
          dateFormat,
          decimalFormat,
          selectedCountry,
          handleMarkAsComplete,
          siteReferenceId,
          divisionId,
          divisionData?.divisionCode,
          process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY,
        );

      case COUNTRY_POLAND:
        return getColumnsPoland(
          dateFormat,
          decimalFormat,
          selectedCountry,
          siteReferenceId,
          divisionId,
          divisionData?.divisionCode,
        );
      case COUNTRY_UK:
        return getColumnsUK(
          dateFormat,
          decimalFormat,
          selectedCountry,
          siteReferenceId,
          divisionId,
          divisionData?.divisionCode,
        );
      case COUNTRY_NORWAY:
      case COUNTRY_SWEDEN:
        return getColumnsSAF(
          dateFormat,
          decimalFormat,
          selectedCountry,
          siteReferenceId,
          divisionId,
          divisionData?.divisionCode,
        );
      default:
        return getColumnsUK(
          dateFormat,
          decimalFormat,
          selectedCountry,
          siteReferenceId,
          divisionId,
          divisionData?.divisionCode,
        );
    }
  }, [
    dateFormat,
    decimalFormat,
    selectedCountry,
    handleMarkAsComplete,
    siteReferenceId,
    divisionId,
    divisionData?.divisionCode,
  ]);

  const [columns, setColumns] = useState(tableCols);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const uniqYears = useMemo(() => {
    const years = sortedData?.map((item) => {
      return (
        item.feedstockIssueDatez &&
        new Date(moment(item.feedstockIssueDatez))?.getFullYear()
      );
    });
    const uniqueYears = [
      ...new Set(
        years?.filter(
          (year) => year !== undefined && !Number.isNaN(year) && year !== "",
        ),
      ),
    ];
    return uniqueYears?.sort((a, b) => b - a);
  }, [sortedData]);

  const handleFilterSubmit = (data) => {
    setRowSelection({});
    if (data) {
      setSupplierName(data.supplierName);
      setReceivingPointName(data.recipientReceiptAddress);
      setProductName(data.productType);
      setStatusName(data.state);
      setOriginName(data.countryCode);
      setMaterialName(data.rawMaterialType);
      const convertedObjects = Object.entries(data)
        .filter(([, value]) => value !== undefined && value !== "")
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setSupplierName(undefined);
      setReceivingPointName(undefined);
      setProductName(undefined);
      setStatusName(undefined);
      setOriginName(undefined);
      setMaterialName(undefined);
    }
  };

  const [pollingTracker, {data: trackerData, stopPolling, called}] =
    useLazyQuery(DOC_STATUS_TRACKER, {
      pollInterval: POLLING_INTERVAL_IN_SECONDS,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const handleTracking = async () => {
      if (trackerData && trackerData.bioLcUploadIncomingDocStatusTrackerApi) {
        const {statusCode} = trackerData.bioLcUploadIncomingDocStatusTrackerApi;
        switch (statusCode) {
          case 400: // Duplicate document
            setIsUploading(false);
            setIsDuplicate(true);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
          case 500: // Textract failed
            setIsUploading(false);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
          case 201: // Successfully parsed
            setIsUploading(false);
            setIsUploadSuccessful(true);
            setIsStillUploading(false);
            stopPolling();
            refetch();

            break;
          case 202: // still uploading
            setIsUploading(false);
            setErrorModalVisible(false);
            setIsStillUploading(true);

            break;
          default:
            setIsUploading(false);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
        }
      }
    };

    handleTracking();
  }, [trackerData, stopPolling, refetch]);

  useEffect(() => {
    let pollingTimeout;
    if (called) {
      pollingTimeout = setTimeout(() => {
        // After polling 5 times, if the result is still in progress, then terminate the polling manually.
        if (
          trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode ===
          202
        ) {
          setIsUploading(false);
          setErrorModalVisible(false);
          // setIsStillUploading(true);
          stopPolling();
        }
      }, 0);
    }
    return () => clearTimeout(pollingTimeout);
  }, [
    called,
    stopPolling,
    trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode,
  ]);

  const handleUpload = async () => {
    if (uploadFileData !== null && uploadFileData !== undefined) {
      setPageIndex(0);
      setIsUploading(true);
      setShowResponse(true);
      try {
        const uploadStatusCode = await uploadFile(
          uploadFileData,
          siteReferenceId,
          selectedDivision,
        );
        if (uploadStatusCode === 200) {
          // First polling will start after 30 seconds of uploading the PDF.
          setTimeout(async () => {
            pollingTracker({
              variables: {
                docName: uploadFileData.name,
                siteReferenceId,
                divisionId: selectedDivision,
              },
            });
          }, POLLING_FIRST_TRIGGER_IN_SECONDS);
        } else {
          setIsUploading(false);
          setErrorModalVisible(true);
        }
      } catch (err) {
        console.error(err);
        // Unexpected error
      }
    }
    return false;
  };

  const handleExportSelected = async (selectedRows) => {
    const selectedRecords = Object.keys(selectedRows)
      .map((index) => Number(index))
      .map((index) => sortedData[index]);

    const selectedIds = selectedRecords.map(
      ({certificateInboundEuId}) => certificateInboundEuId,
    );

    const response = await ExportSelectedRowsAPI({
      divisionId,
      siteReferenceId,
      certificateInboundEuIds: selectedIds,
    });

    if (
      response?.data?.bioLcGetIncomingDocsExportApi &&
      response?.data?.bioLcGetIncomingDocsExportApi?.statusCode === 200
    ) {
      downloadFile(response?.data?.bioLcGetIncomingDocsExportApi?.downloadUrl);
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  const handleAnnualExport = async (year) => {
    const response = await ExportAnnualAPI({
      divisionId,
      siteReferenceId,
      year,
    });
    if (
      response?.data?.bioLcGetIncomingDocsExportYearApi &&
      response?.data?.bioLcGetIncomingDocsExportYearApi?.statusCode === 200
    ) {
      downloadFile(
        response?.data?.bioLcGetIncomingDocsExportYearApi?.downloadUrl,
      );
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  const showLinkToPurchaseButton = useMemo(() => {
    const hideLtpButton =
      process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;
    if (
      (hideLtpButton === "false" && selectedCountry === COUNTRY_SPAIN) ||
      selectedCountry === COUNTRY_SWEDEN ||
      selectedCountry === COUNTRY_NORWAY
    ) {
      return false;
    }
    return true;
  }, [selectedCountry]);

  const LinkToPurchaseURL = useMemo(() => {
    switch (selectedCountry) {
      case COUNTRY_SPAIN:
        return `/link-to-purchase/${selectedCountry}/${divisionData?.divisionCode}`;
      case COUNTRY_SWEDEN:
        return "/saf/sweden/link-to-purchase";
      case COUNTRY_NORWAY:
        return "/saf/norway/link-to-purchase";
      default:
        return "";
    }
  }, [divisionData?.divisionCode, selectedCountry]);

  const showTaskManager = useMemo(() => {
    switch (selectedCountry) {
      case COUNTRY_SWEDEN:
        return false;
      case COUNTRY_NORWAY:
        return false;
      case COUNTRY_SPAIN:
        return false;
      case COUNTRY_UK:
        return true;
      default:
        return true;
    }
  }, [selectedCountry]);

  return (
    <>
      <Modal
        size="sm"
        isOpen={isMarkAsCompleteOpen}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="flex flex-row pl-4 justify-start items-center gap-4">
            <Spinner />
            <span>Processing...</span>
          </div>
        </ModalBody>
      </Modal>
      <div className="flex flex-col justify-stretch w-full items-start ">
        <div className=" flex flex-row justify-end w-full my-5 gap-x-3 items-center">
          {showTaskManager && (
            <div>
              <NotificationBar
                siteReferenceId={siteReferenceId}
                divisionId={divisionId}
                selectedCountry={selectedCountry}
              />
            </div>
          )}
          <div className="ltp-nav">
            <Button
              color="tertiary"
              outline
              type="secondary"
              size="md"
              className="show link-btn rounded-0"
              hidden={showLinkToPurchaseButton}
              onClick={() => {
                navigate({
                  pathname: LinkToPurchaseURL,
                });
              }}
            >
              Link to purchase
              <Link24 className="btn-icon-suffix" />
            </Button>
          </div>
          <div>
            <Button
              color="primary"
              type="secondary"
              className="float-right rounded-0 text-gray-400"
              id="addDocumentButton"
              size="md"
              onClick={() => {
                setUploadModal(!showUploadModal);
              }}
            >
              Add document
              <Add24 className="btn-icon-suffix" />
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-stretch w-full items-start ">
        <div className=" flex flex-row justify-between w-full px-4 py-5 bg-white">
          <div className="flex flex-none flex-row items-center ">
            <div className="mr-2">
              <CustomizeTable
                columns={columns}
                onColumnChange={(cols) => setColumns(cols)}
                showApplyButton={false}
              />
            </div>
            {selectedCountry !== COUNTRY_SPAIN && (
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle color="standard-tertiary rounded-0" caret>
                    Export
                    <Download24 className="btn-icon-suffix" />
                  </DropdownToggle>
                  <DropdownMenu tag="ul">
                    <li>
                      <DropdownItem
                        href="#"
                        onClick={async () => handleExportSelected(rowSelection)}
                        disabled={Object.keys(rowSelection)?.length === 0}
                      >{`Export selected (${
                        Object.keys(rowSelection)?.length
                      })`}</DropdownItem>
                    </li>

                    <UncontrolledDropdown direction="end" tag="li">
                      <DropdownToggle
                        tag="button"
                        caret
                        className="dropdown-item"
                      >
                        Export annual data
                        <div className="dropdown-suffix x5-me-n1">
                          <TriangleRightSolid24 />
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        {uniqYears?.map((year) => (
                          <DropdownItem
                            onClick={async () => handleAnnualExport(year)}
                          >
                            <Download24 className="btn-icon-prefix" />
                            <span className="">{year}</span>
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            )}
          </div>
          <div className="flex flex-none flex-row items-center ">
            <div className="mr-2">
              <Button
                data-test="doc-manager-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filter
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
            <div>
              <InputGroup className="input-group-merge" size="">
                <Input
                  className="form-control-appended"
                  placeholder="Search"
                  value={filtering}
                  onChange={(e) => {
                    setFiltering(e.target.value);
                    setPageIndex(0);
                  }}
                />
                <div className="input-group-append">
                  <InputGroupText>
                    {filtering ? (
                      <Remove24
                        className="cursor-pointer"
                        onClick={() => {
                          setFiltering("");
                          setPageIndex(0);
                        }}
                      />
                    ) : (
                      <SvgSearch24 />
                    )}
                  </InputGroupText>
                </div>
              </InputGroup>
            </div>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
            <div className=" flex flex-row w-full my-6">
              <DocManagerFilter
                supplierList={supplierList}
                receivingPointList={receivingPointList}
                productList={productList}
                materialList={materialList}
                statusList={statusList}
                originList={originList}
                supplierName={supplierName}
                productName={productName}
                materialName={materialName}
                receivingPointName={receivingPointName}
                statusName={statusName}
                originName={originName}
                setColumnFilter={setColumnFilter}
                setShowFilter={setShowFilter}
                onSubmit={handleFilterSubmit}
                selectedCountry={selectedCountry}
                setPageIndex={setPageIndex}
              />
            </div>
          </div>
        )}

        <div className="border-secondary border-top w-full pb-40 min-h-[500px]">
          <DocumentTable
            columns={filteredColumns}
            data={sortedData ?? []}
            className="incoming-docs-table-docmanager"
            highlightedRows={[]}
            loading={loading}
            filtering={filtering?.trim()}
            setFiltering={setFiltering}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            type="Incoming"
            enableRowSelection={selectedCountry !== COUNTRY_SPAIN}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pageIndex={pageIndex}
          />
          {showUploadModal && (
            <UploadDocuments
              showUploadModal={showUploadModal}
              setUploadModal={setUploadModal}
              setUploadFile={setUploadFileData}
              setShowPreview={setShowPreview}
              handleUpload={handleUpload}
              divisionData={divisionDataList}
              setSelectedDivision={setSelectedDivision}
              selectedDivision={selectedDivision}
            />
          )}

          {showPreview && (
            <PreviewDocumentModal
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              uploadFile={uploadFileData}
              setIsUploading={setIsUploading}
              handleUpload={handleUpload}
              setShowResponse={setShowResponse}
            />
          )}

          {showResponse && (
            <ResponseModal
              showResponse={showResponse}
              setShowResponse={setShowResponse}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isErrorModalVisible={isErrorModalVisible}
              setErrorModalVisible={setErrorModalVisible}
              setUploadModal={setUploadModal}
              bodyClassNames={
                isErrorModalVisible || isUploadSuccessful || isStillUploading
                  ? "h-40 pt-0"
                  : "h-48"
              }
              isUploadSuccessful={isUploadSuccessful}
              setIsUploadSuccessful={setIsUploadSuccessful}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
              isStillUploading={isStillUploading}
              setIsStillUploading={setIsStillUploading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IncomingDocumentComponent;
