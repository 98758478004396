import {useQuery} from "@apollo/client";
import {MASS_BALANCE_MATERIALS_GET_QUERY} from "graphql/GlobalConfigCenter/MassBalanceMaterials";
import Layout from "modules/GlobalConfigCenter/components/Layout/Layout";
import {MASS_BALANCE_MATERIALS_COLUMNS} from "modules/GlobalConfigCenter/constants/Columns";
import {MASS_BALANCE_MATERIALS_CONFIG_PAGE} from "modules/GlobalConfigCenter/constants/ConfigurationPages";
import React from "react";

const MassBalanceMaterialsConfigPage = () => {
  const {data, loading} = useQuery(MASS_BALANCE_MATERIALS_GET_QUERY);
  return (
    <Layout
      title={MASS_BALANCE_MATERIALS_CONFIG_PAGE.title}
      isGlobal
      description={MASS_BALANCE_MATERIALS_CONFIG_PAGE.description}
      tableColumns={MASS_BALANCE_MATERIALS_COLUMNS}
      tableData={loading ? [] : data?.bioLcGetMbBgMaterialData.mbBgMaterialData}
    />
  );
};
export default MassBalanceMaterialsConfigPage;
