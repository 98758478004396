import PropTypes from "prop-types";
import {Controller, useFormContext} from "react-hook-form";
import FormField from "../FormField";
import Select from "../Select";

const SelectFilter = ({label, name, formFieldProps, ...props}) => {
  const {control} = useFormContext();

  return (
    <FormField label={label} {...formFieldProps}>
      <Controller
        control={control}
        name={name}
        render={({field}) => (
          <Select {...field} maxTagCount="responsive" {...props} />
        )}
      />
    </FormField>
  );
};

SelectFilter.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  formFieldProps: PropTypes.object,
};

export default SelectFilter;
