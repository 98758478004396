import {
  CANCEL,
  CONFIRM,
  ERRORS,
  PUBLISH_VIEW,
  SUCCESS_SAVE,
  ADD_COMMENTS,
  PLACEHOLDER_TEXT,
  keyValuePairsDiff,
  CREDITS_CATEGORIES,
  DEFICITS_CATEGORIES,
  keyValuePairsDiffCredits,
  toPublishModalLabels,
  PUBLISH_VIEW_HEADER,
} from "modules/forecasting/constants/inventory";
import {Button} from "reactstrap";
import {useEffect, useState, useMemo, useCallback, Fragment} from "react";
import {useLazyQuery} from "@apollo/client";
import {USER_PUBLISH_DATA} from "graphql/forecast-data";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import {isEmpty, isEqual} from "lodash";
import {
  diffReducer,
  diffsByObligationType,
  calculateToPublishTotal,
  wcDataConverter,
  diffsByMeasurements,
  getValuesToPublish,
  diffExistForCurrentMonth,
} from "modules/forecasting/helpers/generateToPublishTableData";
import ToPublishEmailPreview from "./ToPublishEmailPreview/ToPublishEmailPreview";
import ToPublishModalTable from "./ToPublishModalTable";
import {
  findCurrentMonth,
  currentMonth,
  currentYearMonth,
  isCurrentYear,
  formatMonthName,
} from "../helpers/dateUtils";
import ToPublishTotalObligations from "./ToPublishTotalObligations";
import {history} from "../../../providers/routerHistory";
import AdjustmentSummary from "./AdjustmentSummary";
import ValueTypeDropdown from "./ValueTypeDropdown";
import {useForecastFilter} from "../context/ForecastingFilterContext";
import {combineData} from "../helpers/generateToPublishTableData";

const getKeyValuePairs = (region) => {
  return {...keyValuePairsDiff[region], ...keyValuePairsDiffCredits[region]};
};

const ToPublishModal = ({account, creditsData, deficitsData}) => {
  const {
    appliedFilters: {region = "", year, forecastType},
  } = useForecastFilter();

  const [showModal, setShowModal] = useState(false);
  const [totalObligations, setTotalObligations] = useState([]);
  const [checkboxState, setCheckboxState] = useState({});
  const [dataToSave, setDataToSave] = useState({});
  const [comments, setComments] = useState("");
  const [dropdownValue, setDropdownValue] = useState("forecast");
  const [obligationsData, setObligationsData] = useState([]);
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [currentMonthLabel, setCurrentMonthLabel] = useState("");
  const [keysByMonthState, setKeysByMonthState] = useState({});
  const [showEmailModal, setShowEmailModal] = useState(false);

  const getCheckboxState = useCallback(
    (obligations, selectedDropdownValue) => {
      const keyCategories = [
        ...[...CREDITS_CATEGORIES].sort(),
        ...DEFICITS_CATEGORIES,
      ];

      const diffs = diffReducer(
        obligations,
        selectedDropdownValue,
        forecastType,
      );

      const diffsByCategory = keyCategories.reduce((acc, category) => {
        acc[category] = diffsByObligationType(category, diffs);
        return acc;
      }, {});

      if (region === "ec") {
        delete diffsByCategory.d5;
      }

      setKeysByMonthState(diffsByCategory);

      const keys = getKeyValuePairs(region);

      return diffsByMeasurements(diffs, region, keys);
    },
    [region, forecastType],
  );

  // useEffect generates initial checked checkboxState object based on available keys
  useEffect(() => {
    if (
      deficitsData?.bioLcForecastApi ||
      deficitsData?.bioLcForecastDataApi ||
      creditsData?.bioLcForecastDataApi
    ) {
      // if region is wc, use wcDataConverter to convert data to match ec data structure
      const deficitsObligations =
        region === "wc"
          ? wcDataConverter(deficitsData)
          : deficitsData?.bioLcForecastDataApi?.body;
      const creditsObligations = creditsData?.bioLcForecastDataApi?.body;

      const combined = combineData(deficitsObligations, creditsObligations);

      setObligationsData(combined);

      const checkboxStateToUpdate = getCheckboxState(combined, "forecast");

      setCheckboxState(checkboxStateToUpdate);

      if (isCurrentYear(year)) {
        setDisplayDropdown(diffExistForCurrentMonth(combined, year));
        setCurrentMonthLabel(formatMonthName({month: currentMonth}));
      }
    }
  }, [region, getCheckboxState, year, forecastType, deficitsData, creditsData]);

  // updates checkboxState when dropdownValue changes
  useMemo(() => {
    if (isCurrentYear(year) && Object.keys(obligationsData).length) {
      const checkboxStateToUpdate = getCheckboxState(
        obligationsData,
        dropdownValue,
      );

      const checkboxStateCurrentMonth = checkboxStateToUpdate[currentYearMonth];

      setCheckboxState((prevState) => {
        if (!isEqual(checkboxStateToUpdate, prevState)) {
          if (!checkboxStateCurrentMonth) {
            const stateWithoutCurrentMonth = Object.fromEntries(
              Object.entries(prevState).filter(
                ([key]) => key !== currentYearMonth,
              ),
            );
            return {...stateWithoutCurrentMonth};
          }

          return {
            ...prevState,
            [currentYearMonth]: checkboxStateCurrentMonth,
          };
        }
        return prevState;
      });
    }
  }, [obligationsData, dropdownValue, getCheckboxState, year]);

  useEffect(() => {
    if (
      Object.values(keysByMonthState).every((arr) => arr.length > 0) &&
      obligationsData &&
      Object.keys(obligationsData).length &&
      checkboxState !== {}
    ) {
      const totalObligationsData = Object.entries(keysByMonthState).reduce(
        (acc, [key, value]) => {
          acc[key] = calculateToPublishTotal(
            value,
            checkboxState,
            getKeyValuePairs(region)[key],
          );
          return acc;
        },
        {},
      );

      setTotalObligations(totalObligationsData);

      const valuesToPublish = getValuesToPublish(
        obligationsData,
        dropdownValue,
        checkboxState,
      );

      setDataToSave({
        toPublish: true,
        values: valuesToPublish,
        ...(findCurrentMonth(valuesToPublish) && {
          update_type: dropdownValue,
        }),
      });
    } else {
      setDataToSave({});
      setTotalObligations({});
    }
  }, [
    checkboxState,
    region,
    dropdownValue,
    obligationsData,
    keysByMonthState,
    forecastType,
  ]);

  // Function to handle the checkbox state for individual cells
  const handleChangeIndividualCheckbox = (item, record) => {
    const {dataIndex} = item;
    const {measurement} = record;

    setCheckboxState((prevState) => {
      return {
        ...prevState,
        [dataIndex]: {
          ...prevState[dataIndex],
          [measurement]: !prevState[dataIndex][measurement],
        },
      };
    });
  };

  // Function to handle the checkbox state for the Months columns
  const handleChangeMonthCheckbox = (checked, item, obligation) => {
    const {dataIndex} = item;

    // keeps only the keys that are available in the current table for the current obligation
    const availableKeys = Object.keys(
      getKeyValuePairs(region)[obligation.toLowerCase()],
    ).filter((key) => key in item);

    setCheckboxState((prevState) => {
      const updatedColumn = Object.keys(prevState[dataIndex]).reduce(
        (acc, key) => {
          if (availableKeys.includes(key)) {
            acc[key] = checked;
          }
          return acc;
        },
        {},
      );

      return {
        ...prevState,
        [dataIndex]: {...prevState[dataIndex], ...updatedColumn},
      };
    });
  };

  const onCancel = () => {
    setShowModal(false);
    setShowEmailModal(false);
    setComments("");
  };

  const [submitQuery, {data: dataRes, error: errorsRes, loading: saveLoading}] =
    useLazyQuery(USER_PUBLISH_DATA, {
      fetchPolicy: "network-only",
      onCompleted: () => {
        if (errorsRes !== undefined || errorsRes?.length > 0) {
          toast.error(ERRORS.FAILED_UPDATED);
        } else if (dataRes != null) {
          toast.success(SUCCESS_SAVE);
          onCancel();
          history.push(`/forecasting/${region}-forecast-published`);
        }
      },
    });

  const handleConfirm = () => {
    submitQuery({
      fetchPolicy: "network-only",
      variables: {
        ...dataToSave,
        comments,
        region,
        userInfo: account.username,
      },
    });
  };

  const handleShowEmailModal = () => {
    setShowEmailModal(true);
  };

  const setUpdateType = (typeofUpdate) => {
    setDropdownValue(typeofUpdate);
    setDataToSave({...dataToSave, update_type: typeofUpdate});
  };

  const getPublishedLabels = (key) => toPublishModalLabels[key];

  const displayTotalObligations = ({key, data, region}) => {
    const CREDITS_CATEGORIES_BY_REGION =
      region === "ec"
        ? CREDITS_CATEGORIES.filter((cat) => cat !== "d5")
        : CREDITS_CATEGORIES;
    const category =
      key === "total_deficits"
        ? DEFICITS_CATEGORIES
        : CREDITS_CATEGORIES_BY_REGION;

    if (!isEmpty(data)) {
      return !category.every((cat) => data[cat].every(isEmpty));
    }

    return false;
  };

  const handleModalDisplay = () => {
    if (showEmailModal) {
      return (
        <ToPublishEmailPreview
          data={dataToSave}
          obligations={obligationsData}
          comments={comments}
          region={region}
          setShowEmailModal={setShowEmailModal}
          handleConfirm={handleConfirm}
          saveLoading={saveLoading}
        />
      );
    }

    return (
      <div className="flex flex-col mt-8 m-4 mx-6">
        <h2 className="text-3xl font-bold">{PUBLISH_VIEW_HEADER}</h2>
        <div className="inline-block">
          <div className="my-2" style={{maxWidth: "fit-content"}}>
            <span>
              The months selected below will be included in your published view.
              Unchecked months will be saved for a future publish. This is the
              net change of the volume adjustments previously saved versus
              volumes already published.
            </span>
          </div>
        </div>
        <div className="to-publish-table my-2 pt-5">
          {Object.entries(toPublishModalLabels).map(([key, value]) => {
            if (
              !isEmpty(obligationsData) &&
              (key === "deficits_label" || key === "credits_label")
            ) {
              return (
                <h2 className="text-2xl font-bold mb-4" key={key}>
                  {value}
                </h2>
              );
            }
            if (key in keysByMonthState) {
              return (
                <Fragment key={key}>
                  <ToPublishModalTable
                    monthData={keysByMonthState[key]}
                    obligation={key}
                    keyValuePairs={getKeyValuePairs(region)[key]}
                    checkboxState={checkboxState}
                    handleChange={handleChangeIndividualCheckbox}
                    handleChangeMonth={handleChangeMonthCheckbox}
                    label={value}
                  />
                  <div className="mt-8" />
                </Fragment>
              );
            }
            if (key === "total_deficits" || key === "total_credits") {
              return displayTotalObligations({
                key,
                data: totalObligations,
                region,
              }) ? (
                <Fragment key={key}>
                  <ToPublishTotalObligations
                    monthData={Object.values(keysByMonthState)[0]}
                    totalObligations={totalObligations}
                    label={getPublishedLabels(key)}
                    forecastType={key.split("_")[1]}
                    totalKey={key}
                  />
                  <div className="mt-8" />
                </Fragment>
              ) : null;
            }
            return null;
          })}
        </div>
        {displayDropdown && (
          <ValueTypeDropdown
            dropdownValue={dropdownValue}
            currentMonth={currentMonthLabel}
            onTypeChange={setUpdateType}
          />
        )}

        <div className="col-span-full mb-4">
          <div className="mt-2" style={{maxWidth: "fit-content"}}>
            <AdjustmentSummary
              userEditedValues={dataToSave.values || []}
              toPublish
            />
          </div>
          <div className="pt-6 pb-1 add-comments-styles">{ADD_COMMENTS}</div>
          <div className="w-full h-36">
            <textarea
              data-test="to-publish-textarea"
              className="w-full h-full px-2 border border-gray-300 border-solid rounded"
              value={comments}
              style={{resize: "none"}}
              onChange={(e) => setComments(e.target.value)}
              placeholder={PLACEHOLDER_TEXT.ADD_COMMENTS}
              maxLength="1000"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="px-5 py-2 my-1 text-lg text-center text-blue-500 underline hover:text-blue-500"
            onClick={() => onCancel()}
            key="publish-forecast-button-cancel"
            data-test="publish-forecast-button-cancel"
            type="button"
          >
            {CANCEL}
          </button>

          <Button
            className="ml-4 w-1/3 sm:w-1/4 max-w-xs px-3 py-2 my-1 text-sm font-bold text-center uppercase rounded hover:opacity-8 border border-indigo-500 enabled:bg-white text-indigo-500 disabled:bg-gray-300 disabled:text-white disabled:border-none"
            type="button"
            disabled={
              Object.keys(totalObligations).length === 0 ||
              Object.values(totalObligations).every((objArray) =>
                objArray.every((obj) => Object.keys(obj).length === 0),
              ) ||
              saveLoading
            }
            onClick={() => handleShowEmailModal()}
            key="publish-forecast-button-confirm"
            data-test="publish-forecast-button-confirm"
          >
            {CONFIRM}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <button
        className="ml-auto px-3.5 py-3 my-1 text-sm font-bold text-center uppercase rounded hover:opacity-8 border-2 border-slate-600 bg-white text-indigo-500"
        type="button"
        onClick={() => setShowModal(true)}
        key="publish-forecast-button-open"
        data-test="publish-forecast-button-open"
      >
        {PUBLISH_VIEW}
      </button>

      {showModal ? (
        <div className="fixed inset-0 overflow-y-auto to-publish-modal-bg">
          <div
            className="fixed w-full h-full bg-black opacity-40"
            onClick={() => onCancel()}
            onKeyDown={() => onCancel()}
          ></div>
          <div className="flex m-0 sm:m-4 items-center min-h-screen px-4 py-8">
            <div
              className="relative p-0 sm:p-4 mx-auto bg-white shadow-lg to-publish-modal-table-holder"
              data-test="to-publish-modal"
            >
              {handleModalDisplay()}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

ToPublishModal.propTypes = {
  account: PropTypes.object.isRequired,
  creditsData: PropTypes.object,
  deficitsData: PropTypes.object,
};

export default ToPublishModal;
