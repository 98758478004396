import {MODULE_NAMES} from "constants/moduleDetails";
import {useAppSetting} from "providers/appSetting";

export const getSiteDetails = (countryId) => {
  const {appSetting} = useAppSetting();
  const siteData = appSetting?.siteData;
  const country_id = String(countryId);
  const siteReferenceData = siteData.find(
    (site) => site.countryId === country_id,
  );
  return siteReferenceData;
};

export const getDivisionDetailsById = (divisionId) => {
  const {appSetting} = useAppSetting();
  const divisionData = appSetting?.divisionData;
  const filtereddivision = divisionData.find(
    (data) => data.divisionId === divisionId,
  );
  return filtereddivision;
};

export const getDivisionData = (divisionCode) => {
  const {appSetting} = useAppSetting();
  const divisionData = appSetting?.divisionData;
  const filtereddivision = divisionData.find(
    (data) => data.divisionCode === divisionCode,
  );

  return filtereddivision;
};

export const setCountryDetails = async (countryName) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    appSetting?.currentCountry?.toLowerCase() === "global" &&
    appSetting?.featureMappingData &&
    countryName?.length > 0
  ) {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === countryName.toLowerCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: countryName?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
      currentCountryCode: countryFeatureData?.countryCode,
    });
  }
};

export const setCountryModuleDetails = async (countryName, moduleName) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    appSetting?.currentModule?.toLowerCase() !== moduleName?.toLowerCase() &&
    appSetting?.featureMappingData &&
    moduleName?.length > 0
  ) {
    setAppSetting({
      ...appSetting,
      currentModule: moduleName,
      currentModuleTitle: MODULE_NAMES[moduleName],
    });
  }
  if (
    appSetting?.currentCountry?.toLowerCase() === "global" &&
    appSetting?.featureMappingData &&
    countryName?.length > 0
  ) {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === countryName.toLowerCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: countryName?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
      currentCountryCode: countryFeatureData?.countryCode,
    });
  }
};

export const setGlobalCountry = async () => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (appSetting?.currentCountry?.toLowerCase() !== "global") {
    setAppSetting({
      ...appSetting,
      currentSite: "GLOBAL",
      currentCountry: "GLOBAL",
      currentCountryMappingData: null,
    });
  }
};

export default {getDivisionData, getSiteDetails};
