import PropTypes from "prop-types";
import React from "react";
import {Spinner} from "reactstrap";

const SingleDocumentViewer = ({document, isOutgoing = false}) => {
  return (
    <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
      {document?.downloadUrl === undefined ? (
        isOutgoing ? (
          <div className="flex flex-col pt-[100px] px-[220px] w-full text-center">
            <div className="mb-[20px]">
              <Spinner style={{border: "outset"}} />
            </div>

            <p className="mb-[12px]">Document preview is being created</p>
            <p className="text-[13px] text-[#111111a3]">
              It will be available shortly. Please refresh this page if needed.
            </p>
          </div>
        ) : (
          <div className="flex flex-col p-5 w-full text-center">
            <span className="fs-4 text-danger">Unable to load file</span>
            <code className="fs-6 text-danger">File not found</code>
          </div>
        )
      ) : (
        <iframe
          className="w-full min-h-screen"
          title="View document"
          border="0"
          src={document?.downloadUrl}
        ></iframe>
      )}
    </div>
  );
};

SingleDocumentViewer.propTypes = {
  document: PropTypes.object,
  isOutgoing: PropTypes.bool,
};

export default SingleDocumentViewer;
