import {gql} from "@apollo/client";

// Fetch query for Location yield details table on Config center(/configuration-center/location-yield-details)
export const LOCATION_YIELD_DETAILS_GET_QUERY = gql`
  query bioLcGetLocationYieldDetailsApi {
    bioLcGetLocationYieldDetailsApi(event: {}) {
      locationYieldDetailsData {
        countryCode
        divisionCode
        locationShortName
        materialShortName
        processingUnitCode
        validFrom
        validTo
        yieldRatio
        yieldType
      }
    }
  }
`;

export default {LOCATION_YIELD_DETAILS_GET_QUERY};
