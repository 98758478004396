import {gql} from "@apollo/client";
import {client} from "providers/Apollo";

const EXPORT_SELECTED_DETAILS = gql`
  query ExportSelectedRows(
    $divisionId: String!
    $siteReferenceId: String!
    $certificateInboundEuIds: [String]
  ) {
    bioLcGetIncomingDocsExportApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        certificateInboundEuIds: $certificateInboundEuIds
      }
    ) {
      downloadUrl
      error
      statusCode
    }
  }
`;

const EXPORT_ANNUAL_DETAILS = gql`
  query ExportAnnualDetails(
    $divisionId: String!
    $siteReferenceId: String!
    $year: Int!
  ) {
    bioLcGetIncomingDocsExportYearApi(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        year: $year
      }
    ) {
      downloadUrl
      statusCode
      error
    }
  }
`;

export const ExportAnnualAPI = (data) =>
  client.query({
    query: EXPORT_ANNUAL_DETAILS,
    variables: {
      ...data,
    },
    fetchPolicy: "no-cache",
  });

export const ExportSelectedRowsAPI = (data) =>
  client.query({
    query: EXPORT_SELECTED_DETAILS,
    variables: {
      ...data,
    },
    fetchPolicy: "no-cache",
  });

export default {ExportSelectedRowsAPI, ExportAnnualAPI};
