import PropTypes from "prop-types";
import {useState} from "react";
import {Button, ModalBody, Modal, ModalHeader, Label} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import {UPDATE_BATCH} from "graphql/coprocessing/batches";
import {
  BATCH_UPDATE_MODAL,
  BATCH_SUMMARY_LABELS,
} from "modules/co-processing/constants/batches";
import {Close, Datepicker} from "@bphxd/ds-core-react";
import {toast} from "react-toastify";
import {BUTTONS} from "modules/co-processing/constants/shipments";
import "./index.scss";

const EditTankCertifiedModal = ({
  details,
  modal,
  setModal,
  refetch,
  onClose,
}) => {
  const [detailValue, setDetailValue] = useState(details?.tank_certified_datez);

  const renderFormField = () => {
    return (
      <Datepicker
        name="tank_certified_datez"
        required
        className="datePicker"
        options={{
          allowInput: false,
          altFormat: "m/j/Y h:i K",
          dateFormat: "Z",
          defaultDate: detailValue,
          enableTime: true,
          minuteIncrement: 1,
          onClose: (selectedDates, dateStr) => {
            setDetailValue(dateStr);
          },
        }}
      />
    );
  };

  const [updateBatch] = useLazyQuery(UPDATE_BATCH, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        toast.success(BATCH_UPDATE_MODAL.UPDATE_SUCCESS);
        refetch();
        onClose();
      } else {
        toast.error(
          `"${BATCH_UPDATE_MODAL.UPDATE_FAILURE} - ${result?.bioLcCoproUsBatchesApi?.error}"`,
        );
      }
    },
  });

  const close = () => {
    setModal(false);
  };

  const onSaveDetail = (batchResourceId, tankCertifiedDatez) => {
    updateBatch({
      variables: {
        batch_resource_id: batchResourceId,
        updates: [
          {
            column: "tank_certified_datez",
            value: tankCertifiedDatez,
          },
        ],
      },
    });

    close();
  };

  const label = BATCH_SUMMARY_LABELS.TANK_CERTIFIED;

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={modal}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={() => close()} />}
        >{`Edit ${label.toLowerCase()}`}</ModalHeader>
        <ModalBody className="p-0">
          <div className="px-4 pt-6">
            <Label className="text-gray-700 text-sm mb-2" for={label}>
              {label}
            </Label>
            {renderFormField()}
          </div>
          <div className="flex flex-row w-full border border-t mt-32">
            <Button
              className="w-1/2 !text-gray-400"
              color=""
              onClick={() => close()}
            >
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 disabled:border-0"
              color=""
              disabled={detailValue == null || detailValue.trim().length === 0}
              onClick={() =>
                onSaveDetail(details?.batch_resource_id, detailValue)
              }
            >
              {BUTTONS.SAVE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditTankCertifiedModal.propTypes = {
  details: PropTypes.object,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
};

export default EditTankCertifiedModal;
