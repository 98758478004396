/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import {Card, CardTitle, CardBody} from "reactstrap";
import moment from "moment";
import europeFlag from "assets/copro-cost-calc-images/flag_of_europe.png";
import usFlag from "assets/copro-cost-calc-images/flag_of_usa.png";
import arrowRight from "assets/copro-cost-calc-images/arrow_right.png";
import CostParametersCurrencyComponent from "./CostParametersCurrencyComponent";

import "../index.css";
import {
  DEFAULT_EUROPEAN_CENTRAL_BANK,
  DEFAULT_EUR_CURRENCY_HEADER,
  DEFAULT_USD_CURRENCY_HEADER,
} from "./PlattDefaultHeaders";

const BankContent = ({selectedMonth, selectedYear, monthlyAverageData}) => {
  const exchangeRatesData = monthlyAverageData?.exchangeRate;
  const ulsdData = monthlyAverageData?.ulsd;
  const gocmhData = monthlyAverageData?.gocmh;

  const getColorCode = (value) => {
    const valueNumber = parseFloat(value);
    if (valueNumber > 0) return "#0C6636";
    if (valueNumber < 0) return "#9E3232";
    return "";
  };

  return (
    <div className="w-[100%] max-w-[450px] pr-8 pb-[31px]">
      <ul className="flex flex-row justify-between p-0 mb-2">
        <li className="font-normal text-[13px] !tracking-tight cost-calc-platt-header">
          {DEFAULT_EUROPEAN_CENTRAL_BANK}
        </li>
        <li
          className="font-light text-[13px] !tracking-tight cost-calc-platt-header"
          style={{marginRight: "32px", marginLeft: "15px"}}
        >
          Monthly average: {moment(selectedMonth, "MM").format("MMMM")}{" "}
          {selectedYear}
        </li>
      </ul>
      <Card className="bank_card">
        <CardBody className="flex flex-row justify-between !pt-[15px] !pb-[10px]">
          <div className="flex flex-row">
            <img
              className="flag_of_europe"
              src={europeFlag}
              alt="Europe Flag"
            />
            <img className="flag_of_us" src={usFlag} alt="US Flag" />
          </div>
          <div className="flex flex-row">
            <CardTitle className="!fw-300 !text-sm pt-1 !tracking-wider">
              {DEFAULT_EUR_CURRENCY_HEADER}
            </CardTitle>
            <img
              className="arrow_right"
              src={arrowRight}
              alt="Right Pointed Arrow"
            />
            <CardTitle className="!fw-300 !text-sm pt-1 !tracking-wider">
              {DEFAULT_USD_CURRENCY_HEADER}
            </CardTitle>
          </div>
          <div className="flex flex-col items-end">
            <CardTitle className="!fw-300 !text-sm !tracking-wider">
              {exchangeRatesData?.average}
            </CardTitle>
            <CardTitle
              className="!fw-300 !text-[13px] !tracking-tight"
              style={{color: getColorCode(exchangeRatesData?.difference)}}
            >
              {exchangeRatesData?.difference}
            </CardTitle>
          </div>
        </CardBody>
      </Card>
      <CostParametersCurrencyComponent
        selectedMonth={selectedMonth || ""}
        selectedYear={selectedYear || ""}
        ulsdData={ulsdData || []}
        gocmhData={gocmhData || []}
      />
    </div>
  );
};

BankContent.propTypes = {
  selectedMonth: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  monthlyAverageData: PropTypes.array.isRequired,
};

export default BankContent;
