import PropTypes from "prop-types";
import DataGridItem from "./DataGridItem";

const DataGrid = ({data, title, dataColumn}) => (
  <div data-test="data-grid">
    <div className="text-xl pb-6">{title}</div>
    <div data-test="data-grid-column">
      {dataColumn.map((item, index) => (
        <DataGridItem
          key={index}
          data={data?.[item.key] ?? ""}
          metric={item.metric ?? ""}
          label={item.label}
          type={item.type}
        />
      ))}
    </div>
  </div>
);

DataGrid.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  dataColumn: PropTypes.array,
};

export default DataGrid;
