import moment from "moment";

export const numberSorter = (dataIndex) => ({
  sorter: (a, b) => a[dataIndex] - b[dataIndex],
  sortDirections: ["ascend", "descend"],
});

export const dateSorter = (dataIndex) => ({
  sorter: (a, b) => moment(a[dataIndex]).unix() - moment(b[dataIndex]).unix(),
  sortDirections: ["ascend", "descend"],
});

export const stringSorter = (dataIndex) => ({
  sorter: (a, b) => a[dataIndex]?.trim().localeCompare(b[dataIndex]?.trim()),
  sortDirections: ["ascend", "descend"],
});

export const stringSorterExt = (dataIndex) => ({
  sorter: (a, b) => {
    if (a[dataIndex] === null) {
      return 1;
    }
    if (b[dataIndex] === null) {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "N/A") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "N/A") {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "") {
      return -1;
    }
    return a?.[dataIndex]
      ?.toString()
      .localeCompare(b?.[dataIndex]?.toString(), "en", {
        ignorePunctuation: true,
      });
  },
  sortDirections: ["ascend", "descend"],
});

export const numberSorterExt = (dataIndex) => ({
  sorter: (a, b) => {
    if (a[dataIndex] === null) {
      return 1;
    }
    if (b[dataIndex] === null) {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "N/A") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "N/A") {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "") {
      return -1;
    }
    return a[dataIndex] - b[dataIndex];
  },
  sortDirections: ["ascend", "descend"],
});

export const dateSorterExt = (dataIndex, dateFormat = "MM/DD/YYYY") => ({
  sorter: (a, b) => {
    if (a[dataIndex] === null) {
      return 1;
    }
    if (b[dataIndex] === null) {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "N/A") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "N/A") {
      return -1;
    }
    if (a[dataIndex] !== null && a[dataIndex] === "") {
      return 1;
    }
    if (b[dataIndex] !== null && b[dataIndex] === "") {
      return -1;
    }
    return (
      moment(a[dataIndex], dateFormat).unix() -
      moment(b[dataIndex], dateFormat).unix()
    );
  },
  sortDirections: ["ascend", "descend"],
});

export const singleDateSort = (column, dateFormat) => {
  const sortMap = {
    date: {
      ...column,
      ...dateSorterExt(column.dataIndex, dateFormat),
    },
    dateTime: {
      ...column,
      ...dateSorterExt(column.dataIndex, dateFormat + "hh:mm A"),
    },
  };

  return sortMap[column.inputType] || column;
};

export const sortDefaultCondition = (
  a,
  b,
  columnKey,
  order,
  type,
  dateFormat = "MM/DD/YYYY",
) => {
  const columnOrder = order === "ascend" ? 1 : -1;
  const columnOrderRev = order === "ascend" ? -1 : 1;
  if (a[columnKey] === null) {
    return columnOrder;
  }
  if (b[columnKey] === null) {
    return columnOrderRev;
  }
  if (a[columnKey] !== null && a[columnKey] === "N/A") {
    return columnOrder;
  }
  if (b[columnKey] !== null && b[columnKey] === "N/A") {
    return columnOrderRev;
  }
  if (a[columnKey] !== null && a[columnKey] === "") {
    return columnOrder;
  }
  if (b[columnKey] !== null && b[columnKey] === "") {
    return columnOrderRev;
  }

  if (type === "number") {
    return order === "ascend"
      ? a[columnKey] - b[columnKey]
      : b[columnKey] - a[columnKey];
  }
  if (type === "input") {
    return order === "ascend"
      ? a?.[columnKey]?.localeCompare(b?.[columnKey], "en", {
          ignorePunctuation: true,
        })
      : b?.[columnKey]?.localeCompare(a?.[columnKey], "en", {
          ignorePunctuation: true,
        });
  }
  if (type === "date" || type === "dateTime") {
    return order === "ascend"
      ? moment(a[columnKey], dateFormat).unix() -
          moment(b[columnKey], dateFormat).unix()
      : moment(b[columnKey], dateFormat).unix() -
          moment(a[columnKey], dateFormat).unix();
  }

  return 0;
};
