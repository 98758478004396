import {gql} from "@apollo/client";

// Fetch query for tax plant registry table on Config center(/configuration-center/tax-plant-registry)
export const GET_TAX_PLANT_REGISTRY_DETAIL = gql`
  query GetTaxPlantRegistryQueryAurora {
    getTaxPlantRegistryAurora {
      data {
        id
        companyCode
        eDGroup
        eDGroupName
        legalEntity
        mot
        plant
        registrationNumber
        taxLiability
        plantName
        mainCustomsOffice
      }
      statusCode
      errors {
        code
        message
      }
    }
  }
`;

export default {GET_TAX_PLANT_REGISTRY_DETAIL};
