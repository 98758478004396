import barrelConversions from "utils/helpers/barrelConversions";
import moment from "moment";

const removeCommas = (str) => str.replace(/,/g, "");

export const formatYYYY = (yearStr) => moment(yearStr).format("YYYY");

export const isCurrentYear = (dateString) =>
  moment(dateString).format("YYYY") === moment().format("YYYY");

const formatToNumber = (value) =>
  !!value && typeof value === "string"
    ? parseInt(removeCommas(value), 10)
    : value;

const formatGraphDisplayValue = (v) =>
  barrelConversions.displayDividedValue(formatToNumber(v));

const getUnpublishedArrowDifference = (currentValue, dataValue) => {
  const difference =
    parseInt(dataValue.replaceAll(",", ""), 10) -
    parseInt(currentValue.replaceAll(",", ""), 10);
  return difference;
};

const isDateKeyYYYY_MM = (key) =>
  /^(?:[0-9]{2})?[0-9]{2}_(1[0-2]|0?[1-9])$/.test(key);

export const getGraphDeficitsValues = (tableRows = []) => {
  const gasTotal = tableRows.find((item) => item.key === "total_gas");
  const dieselTotal = tableRows.find(({key}) => key === "total_diesel");
  const monthlyTotalObligations = tableRows.find(
    ({key}) => key === "total_obligation",
  );

  return Object.keys(gasTotal || {})
    .filter(isDateKeyYYYY_MM)
    .map((month) => {
      const [yearStr = "", monthStr = ""] = month.split("_");
      const date = {year: Number(yearStr), month: Number(monthStr)};
      const gasSavedValues =
        gasTotal?.updatedForecast?.[month] ?? (gasTotal?.[month] || 0);
      const gasValuesFromDataPage = gasTotal[`${month}-dataPageValue`];
      const gasVariance = gasValuesFromDataPage - gasSavedValues;
      const gasVariancePercent =
        gasVariance !== 0
          ? Math.round((gasValuesFromDataPage / gasSavedValues - 1) * 100)
          : 0;

      const dieselSavedValues =
        dieselTotal?.updatedForecast?.[month] ?? (dieselTotal?.[month] || 0);
      const dieselValuesFromDataPage = dieselTotal?.[`${month}-dataPageValue`];
      const dieselVariance = dieselValuesFromDataPage - dieselSavedValues;
      const dieselVariancePercent =
        dieselVariance !== 0
          ? Math.round((dieselValuesFromDataPage / dieselSavedValues - 1) * 100)
          : 0;

      const totalSavedValues = gasSavedValues + dieselSavedValues;
      const totalMonthVariance = gasVariance + dieselVariance;
      const totalVariancePercent = Math.round(
        (monthlyTotalObligations[`${month}-dataPageValue`] / totalSavedValues -
          1) *
          100,
      );

      return {
        date,
        gas: {
          actualValue: formatGraphDisplayValue(gasSavedValues),
          systemValue: formatGraphDisplayValue(gasValuesFromDataPage),
          variance: formatGraphDisplayValue(gasVariance) || 0,
          variancePercentage: gasVariancePercent,
          date: month,
        },
        diesel: {
          actualValue: formatGraphDisplayValue(dieselSavedValues),
          systemValue: formatGraphDisplayValue(dieselValuesFromDataPage),
          variance: formatGraphDisplayValue(dieselVariance) || 0,
          variancePercentage: dieselVariancePercent,
          date: month,
        },
        monthTotal: {
          actualValue: formatGraphDisplayValue(totalSavedValues),
          systemValue: formatGraphDisplayValue(
            monthlyTotalObligations[`${month}-dataPageValue`],
          ),
          variance: formatGraphDisplayValue(totalMonthVariance) || 0,
          variancePercentage: totalVariancePercent,
        },
      };
    });
};

export const getGraphCreditsValues = (tableRows = [], isWestCoast = false) => {
  const d4Total = tableRows.find((item) => item.key === "d4_total");
  const d5Total = tableRows.find((item) => item.key === "d5_total");
  const d6Total = tableRows.find((item) => item.key === "d6_total");
  const monthlyTotalObligations = tableRows.find(
    ({key}) => key === "total_obligation",
  );

  return Object.keys(d4Total || {})
    .filter(isDateKeyYYYY_MM)
    .map((month) => {
      const [yearStr = "", monthStr = ""] = month.split("_");
      const date = {year: Number(yearStr), month: Number(monthStr)};
      const d4SavedValues =
        d4Total?.updatedForecast?.[month] ?? (d4Total?.[month] || 0);
      const d4ValuesFromDataPage = d4Total[`${month}-dataPageValue`];
      const d4Variance = d4ValuesFromDataPage - d4SavedValues;
      const d4VariancePercent =
        d4Variance !== 0
          ? Math.round((d4ValuesFromDataPage / d4SavedValues - 1) * 100)
          : 0;

      const d5SavedValues =
        d5Total?.updatedForecast?.[month] ?? (d5Total?.[month] || 0);
      const d5ValuesFromDataPage = d5Total?.[`${month}-dataPageValue`];
      const d5Variance = d5ValuesFromDataPage - d5SavedValues;
      const d5VariancePercent =
        d5Variance !== 0
          ? Math.round((d5ValuesFromDataPage / d5SavedValues - 1) * 100)
          : 0;

      const d6SavedValues =
        d6Total?.updatedForecast?.[month] ?? (d6Total?.[month] || 0);
      const d6ValuesFromDataPage = d6Total?.[`${month}-dataPageValue`];
      const d6Variance = d6ValuesFromDataPage - d6SavedValues;
      const d6VariancePercent =
        d5Variance !== 0
          ? Math.round((d6ValuesFromDataPage / d6SavedValues - 1) * 100)
          : 0;

      const totalSavedValues =
        d4SavedValues + d6SavedValues + (isWestCoast ? d5SavedValues : 0);
      const totalMonthVariance =
        d4Variance + d6Variance + (isWestCoast ? d5Variance : 0);
      const totalVariancePercent = Math.round(
        (monthlyTotalObligations[`${month}-dataPageValue`] / totalSavedValues -
          1) *
          100,
      );

      return {
        date,
        d4: {
          actualValue: formatGraphDisplayValue(d4SavedValues),
          systemValue: formatGraphDisplayValue(d4ValuesFromDataPage),
          variance: formatGraphDisplayValue(d4Variance) || 0,
          variancePercentage: d4VariancePercent,
          date: month,
        },
        ...(isWestCoast && {
          d5: {
            actualValue: formatGraphDisplayValue(d5SavedValues),
            systemValue: formatGraphDisplayValue(d5ValuesFromDataPage),
            variance: formatGraphDisplayValue(d5Variance) || 0,
            variancePercentage: d5VariancePercent,
            date: month,
          },
        }),
        d6: {
          actualValue: formatGraphDisplayValue(d6SavedValues),
          systemValue: formatGraphDisplayValue(d6ValuesFromDataPage),
          variance: formatGraphDisplayValue(d6Variance) || 0,
          variancePercentage: d6VariancePercent,
          date: month,
        },
        monthTotal: {
          actualValue: formatGraphDisplayValue(totalSavedValues),
          systemValue: formatGraphDisplayValue(
            monthlyTotalObligations[`${month}-dataPageValue`],
          ),
          variance: formatGraphDisplayValue(totalMonthVariance) || 0,
          variancePercentage: totalVariancePercent,
        },
      };
    });
};

export const getFullYearData = (forecastType, tableData) => {
  if (forecastType === "rvo") {
    return tableData.length ? getGraphDeficitsValues(tableData) : [];
  }
  return tableData.length ? getGraphCreditsValues(tableData) : [];
};
