import React, {useEffect, useState} from "react";
import {useLocation, Link, useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {DropdownToggle, DropdownMenu, DropdownItem, Dropdown} from "reactstrap";

import "./styles.css";

const NUMBER_OF_TASKS_TO_BE_DISPLAYED = 3;

const TaskComponent = ({item, targetStates, selectedCountry}) => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const baseUrl = `/document-manager/incoming/${selectedCountry}/detailedView`;
  const toggle = () => setShow((prevState) => !prevState);

  const navigate = useNavigate();

  const headers = {
    ready_to_accept: "Documents ready to accept",
    requires_attention: "Documents require your attention",
    ready_to_assign: "Documents ready to assign",
    failed_to_process: "Documents failed to process",
  };

  const initialDisplayItems = item.taskItems?.slice(
    0,
    NUMBER_OF_TASKS_TO_BE_DISPLAYED,
  );

  function renderTaskContent(taskDoc) {
    if (item.header !== targetStates.FAILED_TO_PROCESS) {
      return (
        <Link
          to={{
            pathname: `${baseUrl}/${taskDoc.certificateInboundEuId}`,
          }}
          className="link-dark"
        >
          {taskDoc.id}
        </Link>
      );
    }
    if (taskDoc.id && taskDoc.id !== "None") {
      return taskDoc.id;
    }
    return "No document ID";
  }

  if (item.taskItems === null) return null;
  if (item.total === 0) return null;

  return (
    <div className="flex mr-0 justify-items-between" key={item.id}>
      <Dropdown
        onMouseEnter={toggle}
        onMouseLeave={toggle}
        onClick={toggle}
        isOpen={show}
        toggle={toggle}
        className="flex flex-start border-0 border-transparent bg-transparent p-3"
      >
        <div className="pt-3">{item.icon}</div>
        <DropdownToggle
          className="flex-start border-0 border-transparent p-2 bg-transparent"
          key={item.key}
          onClick={() => {
            if (item.state !== targetStates.FAILED_TO_PROCESS) {
              navigate({
                pathname: `${baseUrl}`,
                search: `?sortBy=${item.state}`,
              });
            }
          }}
        >
          <div className="flex flex-col items-start flex-wrap">
            <div className="drop-down-name">{item.text}</div>
            <div className="drop-down-latest-document">
              {item.latestDocument}
            </div>
          </div>
        </DropdownToggle>

        <DropdownMenu className="w-[410px] drop-down h-auto" container="body">
          <DropdownItem header className="drop-down-header DropdnMenuItem pl-3">
            <h5>{headers[item.header]}</h5>
          </DropdownItem>
          {initialDisplayItems?.map((document) => {
            return (
              <>
                <DropdownItem divider />
                <DropdownItem
                  tag="div"
                  className="!pl-[20px] !pr-[20px]"
                  style={{
                    pointerEvents:
                      item.header === targetStates.FAILED_TO_PROCESS
                        ? "none"
                        : "initial",
                  }}
                >
                  <div className="flex w-full">
                    <div className="w-[10%]">{item.icon}</div>
                    <div className="w-[70%] text-wrap text-left mr-[10px]">
                      {renderTaskContent(document)}
                    </div>
                    <div className="w-[20%] text-wrap">
                      {document.modifiedLast}
                    </div>
                  </div>
                </DropdownItem>
              </>
            );
          })}
          {item.taskItems?.length >= NUMBER_OF_TASKS_TO_BE_DISPLAYED &&
            item.header !== targetStates.FAILED_TO_PROCESS && (
              <DropdownItem className="">
                <Link
                  to={{
                    pathname: `${baseUrl}`,
                    search: `?sortBy=${item.state}`,
                  }}
                  className="link-dark view_all_link text-[15px] !underline"
                >
                  View all {headers[item.header]}
                </Link>
              </DropdownItem>
            )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

TaskComponent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    header: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    taskItems: PropTypes.array,
    latestDocument: PropTypes.string,
    total: PropTypes.number,
    key: PropTypes.number,
    state: PropTypes.string,
  }).isRequired,
  targetStates: PropTypes.array.isRequired,
  selectedCountry: PropTypes.string.isRequired,
};

export default TaskComponent;
