import {gql} from "@apollo/client";

// Fetch query for MB Balance type table on Config center(/configuration-center/mb-balance-type)
export const MB_BALANCE_TYPE_GET_QUERY = gql`
  query bioLcGetMbBalanceTypeApi {
    bioLcGetMbBalanceTypeApi {
      mbBalanceTypeData {
        mbBalanceTypeId
        mbBalanceTypeName
      }
    }
  }
`;

export default {MB_BALANCE_TYPE_GET_QUERY};
