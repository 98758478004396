import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {useState, useEffect} from "react";
import {
  EMAIL_PREVIEW_HEADER,
  CANCEL,
  SUBMIT,
  PREV,
  NEXT,
  SAVING,
} from "../../constants/inventory";
import {
  getPageCount,
  isAllForecastBeyondNextMonth,
  separateByType,
} from "./toPublishData";
import ToPublishEmailTables from "./ToPublishEmailTables";
import ToPublishBeyond from "./ToPublishBeyond";

const ToPublishEmailPreview = ({
  data,
  obligations,
  comments,
  region,
  setShowEmailModal,
  handleConfirm,
  saveLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [dataByType, setDataByType] = useState({});
  const [updateType, setUpdateType] = useState("forecast");
  const [monthsByType, setMonthsByType] = useState({});
  const [isBeyond, setIsBeyond] = useState(false);

  useEffect(() => {
    const {dataSeparated, trueUpMonths, forecastMonths} = separateByType(
      data,
      region,
    );

    const pages = getPageCount({
      data: dataSeparated,
      updateType: data.update_type,
    });

    const isBeyondResult = isAllForecastBeyondNextMonth({
      dataByType: dataSeparated,
      updateType: data.update_type,
    });

    setDataByType(dataSeparated);
    setMonthsByType({
      trueUpMonths,
      forecastMonths,
    });
    setPageCount(pages);
    setIsBeyond(isBeyondResult);
    setUpdateType(data.update_type);
  }, [data, region]);

  const onCancel = () => {
    setShowEmailModal(false);
  };

  const getPublishText = () => (!saveLoading ? SUBMIT : SAVING);

  return (
    <div className="flex flex-col mt-8 m-4 mx-6">
      {isBeyond ? (
        <ToPublishBeyond />
      ) : (
        <>
          <h2
            className="text-3xl font-bold"
            data-test="to-publish-email-header"
          >
            {EMAIL_PREVIEW_HEADER}
          </h2>
          <ToPublishEmailTables
            dataByType={dataByType}
            obligations={obligations}
            monthsByType={monthsByType}
            pageCount={pageCount}
            currentPage={currentPage}
            region={region}
            updateType={updateType}
            comments={comments}
          />
        </>
      )}

      <div className="flex justify-between mt-8">
        <button
          className="px-5 py-2 my-1 text-lg text-center text-blue-500 underline hover:text-blue-500"
          onClick={() => onCancel()}
          key="publish-forecast-button-email-cancel"
          data-test="publish-forecast-button-email-cancel"
          type="button"
        >
          {CANCEL}
        </button>
        {pageCount === 2 && (
          <div className="flex justify-center gap-3">
            <Button
              className="ml-4 w-1/2 sm:w-1/4 max-w-xs px-3 py-2 my-1 text-sm font-bold text-center uppercase rounded hover:opacity-8 border border-indigo-500 enabled:bg-white text-indigo-500 disabled:bg-gray-300 disabled:text-white disabled:border-none"
              style={{minWidth: "100px"}}
              type="button"
              color="light"
              disabled={currentPage === 1}
              onClick={() => setCurrentPage(1)}
              key="publish-forecast-button-email-prev"
              data-test="publish-forecast-button-email-prev"
            >
              {PREV}
            </Button>
            <Button
              className="ml-4 w-1/2 sm:w-1/4 max-w-xs px-3 py-2 my-1 text-sm font-bold text-center uppercase rounded hover:opacity-8 border border-indigo-500 enabled:bg-white text-indigo-500 disabled:bg-gray-300 disabled:text-white disabled:border-none"
              style={{minWidth: "100px"}}
              type="button"
              disabled={currentPage === 2}
              onClick={() => setCurrentPage(2)}
              key="publish-forecast-button-email-next"
              data-test="publish-forecast-button-email-next"
            >
              {NEXT}
            </Button>
          </div>
        )}
        <Button
          className="py-2 px-8 border border-secondary text-center font-bold disabled:bg-gray-300 disabled:text-white disabled:border-none"
          color="secondary"
          disabled={saveLoading || (currentPage === 1 && pageCount === 2)}
          onClick={() => handleConfirm()}
          key="publish-forecast-button-email-submit"
          data-test="publish-forecast-button-email-submit"
          outline
        >
          {getPublishText()}
        </Button>
      </div>
    </div>
  );
};

ToPublishEmailPreview.propTypes = {
  data: PropTypes.object,
  obligations: PropTypes.array,
  comments: PropTypes.string,
  region: PropTypes.string,
  setShowEmailModal: PropTypes.func,
  handleConfirm: PropTypes.func,
  saveLoading: PropTypes.bool,
};

export default ToPublishEmailPreview;
