export const statusData = [
  {
    id: "ASSIGNED AND SOLD",
    label: "Assigned to sale",
  },
  {
    id: "AVAILABLE",
    label: "Available",
  },

  {
    id: "TRANSFERRED",
    label: "Transferred",
  },
  {
    id: "WRITTEN OFF",
    label: "Written off",
  },
  {
    id: "ASSIGNED AND SENT",
    label: "Assigned & sent",
  },
  {
    id: "CARRIED OVER",
    label: "Carried over",
  },
];

export default {statusData};
