import "./index.scss";
import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";
import PropTypes from "prop-types";

const MOTValidationReportFilter = ({
  batchActionsContent,
  setSearch,
  search,
}) => {
  return (
    <div
      data-test="mot-report-filter"
      className="flex flex-col justify-between px-4 py-4 mt-5 bg-gray-200 border border-b-0 border-gray-300 rounded-t-lg gap-y-2 lg:flex-row"
    >
      <div
        data-test="mot-report-filter-header"
        className="flex flex-col items-start sm:items-center sm:flex-row gap-y-2 sm:gap-x-3"
      >
        {batchActionsContent}
      </div>
      <div className="flex items-center gap-x-3">
        <Input
          data-test="mot-report-filter-header-search"
          className="w-56 rounded"
          size="large"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
        />
      </div>
    </div>
  );
};

MOTValidationReportFilter.propTypes = {
  batchActionsContent: PropTypes.node,
  setSearch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default MOTValidationReportFilter;
