import {ReactTablePaginationRow} from "@bphxd/ds-core-react/lib/components/tables/react-table/ReactTablePaginationRow";
import {Remove24} from "@bphxd/ds-core-react/lib/icons";
import {
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import PropTypes from "prop-types";
import SpinnerLoading from "modules/common/SpinnerLoading";
import {Fragment, useCallback, useState} from "react";
import {Button, Table} from "reactstrap";
import {INCOMING_COLUMN_IDS, OUTGOING_COLUMN_IDS} from "./Column";
import "./index.css";
import InboundDetail from "../InboundDetail";
import OutboundDetail from "../OutboundDetail";

const SplitTable = ({
  data,
  columns,
  loading,
  pagination,
  onPaginationChange,
}) => {
  const [selectedRow, setSelectedRow] = useState("");
  const table = useReactTable({
    data,
    columns,
    state: {
      pagination,
    },
    getSubRows: (row) => row.original?.outboundRecords ?? [],
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onPaginationChange: (updaterFunction) => {
      const newValue = functionalUpdate(updaterFunction, pagination);
      onPaginationChange(newValue);
    },
    initialState: {
      pagination: {
        pageIndex: pagination?.pageIndex,
      },
    },
    autoResetPageIndex: false,
  });

  const paginationProps = {
    rowCount: table.getPrePaginationRowModel().rows.length,
    pageLength: table.getRowModel().rows.length, // number of items on the current page
    canPreviousPage: table.getCanPreviousPage(),
    canNextPage: table.getCanNextPage(),
    pageCount: table.getPageCount(), // total number of pages
    gotoPage: table.setPageIndex,
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    setPageSize: table.setPageSize,
    pageIndex: table.getState().pagination.pageIndex,
    pageSize: table.getState().pagination.pageSize, // user setting from Select input
    showFirstAndLast: false,
    showPageInput: false,
    fontSize: "md", // 'sm' or 'lg' (default is md)
  };

  const buildRows = useCallback(
    (row, flexRenderItem) => {
      const outboundRecords = row.original?.outboundRecords ?? [{}];
      const rowSpan = outboundRecords.length;
      return outboundRecords.map((record, index) => {
        const rowKey = `${row.id}_${index}`;
        return (
          <tr key={rowKey}>
            {row.getVisibleCells().map((cell) => {
              if (INCOMING_COLUMN_IDS.includes(cell.column.id)) {
                return (
                  <td
                    key={cell.id}
                    className={`${index > 0 ? "d-none" : ""} ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                    rowSpan={rowSpan}
                    style={{verticalAlign: "middle", padding: "10px 15px"}}
                  >
                    {flexRenderItem(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </td>
                );
              }
              if (!INCOMING_COLUMN_IDS.includes(cell.column.id)) {
                const {row: rowItem, ...context} = cell.getContext();
                return (
                  <td
                    key={cell.id}
                    style={{verticalAlign: "middle", padding: "10px 15px"}}
                    className={`${
                      selectedRow === rowKey ? "bg-success-subtle" : ""
                    } ${
                      cell.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }`}
                  >
                    {flexRenderItem(cell.column.columnDef.cell, {
                      ...context,
                      row: {
                        ...rowItem,
                        original: {
                          ...rowItem.original,
                          outboundRecords: record,
                          rowSpan,
                        },
                        highlight: () => {
                          setSelectedRow(rowKey);
                        },
                      },
                    })}
                  </td>
                );
              }
              return null;
            })}
          </tr>
        );
      });
    },
    [selectedRow],
  );
  return (
    <>
      <Table className="bp-core table mass-balance-table">
        <thead>
          {table.getHeaderGroups().map((headerGroup, groupIndex) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => {
                let groupColspan = 1;
                if (groupIndex === 0) {
                  groupColspan =
                    headerIndex === 0
                      ? (INCOMING_COLUMN_IDS?.length ?? 0) - 2
                      : (OUTGOING_COLUMN_IDS?.length ?? 0) + 1;
                }
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan > 1 ? groupColspan : header.colSpan}
                    style={{
                      verticalAlign: "middle",
                      padding: "10px 15px",
                    }}
                    className={
                      header.getContext().column.columnDef.dataType ===
                        "number" && "text-right"
                    }
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="100%">
                <div className="flex items-center justify-center ">
                  <SpinnerLoading />
                </div>
              </td>
            </tr>
          )}
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              {buildRows(row, flexRender)}
              {row.getIsExpanded() && (
                <tr>
                  <td
                    colSpan={INCOMING_COLUMN_IDS.length - 2}
                    className="expanded bg-success-subtle"
                  >
                    <InboundDetail data={row.original} />
                  </td>
                  <td
                    colSpan={OUTGOING_COLUMN_IDS.length}
                    className="expanded expanded-outbound bg-success-subtle"
                  >
                    <OutboundDetail
                      data={row.original}
                      rowId={row.id}
                      subRowId={
                        selectedRow === 0
                          ? 0
                          : parseInt(selectedRow.split("_")[1], 10)
                      }
                      onSelectionChange={setSelectedRow}
                    />
                  </td>
                  <td className="expanded expanded-action bg-success-subtle text-center">
                    <Button
                      color="standard-quartenary"
                      onClick={() => {
                        setSelectedRow("");
                        setTimeout(() => row.getToggleExpandedHandler()(), 100);
                      }}
                      className="!px-0"
                    >
                      <Remove24 />
                    </Button>
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
      <ReactTablePaginationRow {...paginationProps} />
    </>
  );
};
SplitTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onPaginationChange: PropTypes.func,
  pagination: PropTypes.object,
};
export default SplitTable;
