import {gql} from "@apollo/client";

export const DASHBOARD_LIST_API = gql`
  query bioLcGetMassBalanceDashboardCounts(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceDashboardCounts(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      data {
        mbBalanceGroupId
        mbBalanceGroupName
        mbLocationGroupId
        mbLocationGroupName
        mbBalanceTypeId
        mbBalanceTypeCode
        mbPeriodStatusId
        mbPeriodStatusName
        mbPeriodStatus
        mbPeriodValidityFlag
        openingCount
        incomingCount
        outgoingCount
        currentCount
        physicalStockM3
        physicalStockMT
      }
    }
  }
`;

export default DASHBOARD_LIST_API;
