import {pick} from "lodash";
import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import * as XLSX from "xlsx";
import {
  MODAL_BODY,
  MODAL_BUTTON,
  MODAL_TITLE,
} from "../../constants/RequestRevisionModal";

const RequestRevisionModal = ({
  modal,
  setModal,
  title,
  tableData,
  tableColumns,
}) => {
  const headers = useMemo(() => {
    return tableColumns.map((column) => column.header);
  }, [tableColumns]);
  const data = useMemo(() => {
    const accessor_key_list = tableColumns.map((column) => column.accessorKey);
    return tableData.map((row) => {
      return pick(row, accessor_key_list);
    });
  }, [tableData, tableColumns]);

  const toggle = () => {
    setModal(!modal);
  };
  const handleDownloadRevision = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(worksheet, [headers], {origin: "A1"});
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Revision Data");
    XLSX.writeFile(workbook, `global-config-center-${title}-revision.xlsx`, {
      compression: true,
    });
    setModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      size="sm"
      className="d-flex modal-dialog-centered"
    >
      <ModalHeader className="m-auto text-md p-0 pt-5">
        <span className="fw-normal">{MODAL_TITLE}</span>
      </ModalHeader>

      <ModalBody className="p-0 px-4">
        <p className="text-center text-xs fw-light">
          {MODAL_BODY.line1}
          <br />
          <br />
          {MODAL_BODY.line2}
        </p>
      </ModalBody>

      <ModalFooter className="p-0">
        <Button
          color="tertiary"
          onClick={handleDownloadRevision}
          className="text-sm"
          block
        >
          {MODAL_BUTTON.primaryAction}
        </Button>
      </ModalFooter>

      <ModalFooter className="p-0">
        <Button
          color="tertiary"
          onClick={toggle}
          className="text-sm fw-light"
          block
        >
          {MODAL_BUTTON.secondaryAction}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RequestRevisionModal.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tableData: PropTypes.array.isRequired,
  tableColumns: PropTypes.array.isRequired,
};

export default RequestRevisionModal;
