import PropTypes from "prop-types";
import {Bioverse as bioverseConfig} from "content/navigations";
import {useEffect, useMemo} from "react";

import {useNavigate} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {isEmpty} from "lodash";

const SectionCountry = ({
  itemKey,
  country,
  module,
  uniqueLocations,
  locationLoading,
}) => {
  const navigate = useNavigate();
  const {appSetting, setAppSetting} = useAppSetting();

  // title is the country here
  const bioverseMenu = useMemo(
    () => bioverseConfig(country, uniqueLocations, locationLoading),
    [country, uniqueLocations, locationLoading],
  );

  const handleSectionNavigation = async (country) => {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName === country?.toUpperCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: country?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
    });
    if (bioverseMenu.length > 0 && !isEmpty(module)) {
      const getLink = bioverseMenu?.filter(
        (item) => item.isVisible && item?.module === module,
      );
      navigate(getLink && getLink[0]?.link);
    }
  };

  return (
    <div data-test={`document-${country}`} className="bg-[#fff]">
      {country && (
        <div
          data-test={`link-${itemKey}`}
          onClick={() => handleSectionNavigation(country)}
          onKeyDown={() => handleSectionNavigation(country)}
          className="flex flex-col items-center justify-center h-48 px-4 py-5 text-left border border-gray-200 rounded-md hover:no-underline hover:border-transparent hover:bg-bp-green-50"
        >
          <h3
            data-test="title"
            className="mt-3 text-lg font-bold text-center text-indigo-500 capitalize"
          >
            {country}
          </h3>
        </div>
      )}
    </div>
  );
};

SectionCountry.propTypes = {
  itemKey: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  module: PropTypes.string.isRequired,
  uniqueLocations: PropTypes.array.isRequired,
  locationLoading: PropTypes.bool.isRequired,
};

export default SectionCountry;
