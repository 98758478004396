import Select from "modules/common/Select";

export const handlePercent = (value) => {
  if (value !== undefined && value !== null) {
    return value.includes("%") ? (
      <label>{value}</label>
    ) : (
      <label>{`${value}%`}</label>
    );
  }
  return <label>{value}</label>;
};

export const handleNACondition = (value) => {
  return value !== undefined && value !== null && value !== "" ? (
    <label>{`${value}`}</label>
  ) : (
    <label>N/A</label>
  );
};

export const displayDecimalValue = (value, numberOfDecimal = 2) => {
  return value !== undefined && value !== null
    ? parseFloat(value).toFixed(numberOfDecimal)
    : "";
};

export const handleExecutionTimeoutPopup = (error, setError) => {
  if (
    error !== undefined &&
    error !== null &&
    error?.message === "Timeout exceeded"
  ) {
    setError(true);
  } else {
    setError(false);
  }
};

export const roundDecimal = (value) => {
  return value !== undefined &&
    value !== null &&
    value !== "None" &&
    value !== ""
    ? Math.round(value)
    : 0;
};

export const formatDecimal = (value, decimalPoints = 3) => {
  return value !== undefined &&
    value !== null &&
    value !== "None" &&
    value !== "" &&
    value !== "NA" &&
    value !== "N/A"
    ? value.toLocaleString(undefined, {minimumFractionDigits: decimalPoints})
    : "0";
};

export const selectOption = (value, label) => {
  return <Select.Option value={value}>{label}</Select.Option>;
};

export default {
  handlePercent,
  displayDecimalValue,
  handleExecutionTimeoutPopup,
  handleNACondition,
};
