import {Steps, Step} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";

const STEPS = ["Step 1", "Step 2", "Step 3", "Step 4"];

const StepIndicator = ({currentStep = 1}) => {
  if (currentStep > 4) return null;

  return (
    <Steps
      className="addBatch__stepper p-3 p-lg-5 mb-3 mb-lg-4"
      data-test="stepper"
      activeStep={currentStep - 1}
      size="sm"
    >
      {STEPS.map((step) => (
        <Step key={step} label={step} />
      ))}
    </Steps>
  );
};
export default StepIndicator;

StepIndicator.propTypes = {
  currentStep: PropTypes.oneOf([1, 2, 3, 4, 5]),
};
