import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {useState} from "react";
import AssignToSaleModal from "./AssignToSale";
import {MASS_BALANCE_MODAL, OUTGOING_DOC_STATUS} from "../constants/documents";

const ActionDropdown = ({row, table}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");

  const ACTIONS = [
    {
      name: MASS_BALANCE_MODAL.ASSIGNED_TO_SALE,
      id: "assign-to-sale",
      action: () => {
        setActionModalVisible(MASS_BALANCE_MODAL.ASSIGNED_TO_SALE);
      },
      isDisabled:
        row?.original?.outgoingRecords?.status !==
        OUTGOING_DOC_STATUS.AVAILABLE,
    },
  ];

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu>
          {ACTIONS.map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.id}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <AssignToSaleModal
          isVisibleModal={
            actionModalVisible === MASS_BALANCE_MODAL.ASSIGNED_TO_SALE
          }
          rowData={row.original}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
};

export default ActionDropdown;
