import {useQuery} from "@apollo/client";
import {Close} from "@bphxd/ds-core-react";
import GENERATE_CERTIFICATE_MODAL_DATA from "graphql/MassBalance/GenerateCertificateModalData";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useAppSetting} from "providers/appSetting";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import AssignToSaleForm from "./GenerateCertificateForm";
import GenerateCertificateFormSchema from "./generateCertificateFormSchema";

const GenerateCertificateModal = ({
  isVisibleModal,
  rowData,
  divisionCode,
  currentFilterValues,
}) => {
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);

  const {country} = useParams();
  const navigate = useNavigate();

  const {appSetting} = useAppSetting();
  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);

  const mbOutboundQtyEuId =
    rowData?.original?.outboundRecords?.mbOutboundQtyEuId;

  const type = currentFilterValues?.mbBalanceTypeCode;
  const location = currentFilterValues?.mbLocationGroupName;
  const balance = currentFilterValues?.mbBalanceGroupName;
  const period = currentFilterValues?.mbPeriodName;

  const {data: formData, loading: formDataLoading} = useQuery(
    GENERATE_CERTIFICATE_MODAL_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbOutboundQtyEuId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined ||
        isVisibleModal === false,
    },
  );

  return (
    <Modal
      size="lg"
      isOpen={isModalVisible}
      className="modal-dialog-centered justify-center [&>div]:w-[666px]"
    >
      <ModalHeader
        className="p-4"
        style={{
          borderBottom: "solid 1px #80808061",
        }}
        close={
          <Close
            onClick={() => {
              setModalVisible(false);
            }}
          />
        }
      >
        Additional information
      </ModalHeader>
      <ModalBody className="text-center bg-gray-50">
        {formDataLoading ? (
          <div className="h-[360px] flex items-center justify-center">
            <Spinner size="md" />
          </div>
        ) : (
          <Form
            id="assign-to-sales-form"
            mode="onChange"
            reValidateMode="onChange"
            schema={GenerateCertificateFormSchema}
            onSubmit={async (values) => {
              navigate({
                pathname: `/document-manager/outgoing/${country}/${divisionCode}/${mbOutboundQtyEuId}`,
                search: `?doi=${values.dateOfIssuance}&mot=${values.modeOfTransportation}&rname=${values.recipientName}&cnumber=${values.contractNumber}&addr=${values.addressOfRecipient}&addrp=${values.addressOfReceivingPoint}&type=${type}&location=${location}&balance=${balance}&period=${period}`,
              });
            }}
          >
            <AssignToSaleForm rowData={rowData} formData={formData} />
          </Form>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="primary"
          className="rounded-0 text-gray-400"
          form="assign-to-sales-form"
          disabled={formDataLoading}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

GenerateCertificateModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
  divisionCode: PropTypes.string,
  currentFilterValues: PropTypes.object,
};

export default GenerateCertificateModal;
