import PropTypes from "prop-types";
import moment from "moment";
import {Card, CardTitle, CardBody} from "reactstrap";

import "../index.css";
import {
  DEFAULT_GAS_OIL_HEADER,
  DEFAULT_S_AND_P_GLOBAL_PLATTS_HEADER,
  DEFAULT_ULSD_HEADER,
} from "./PlattDefaultHeaders";

const CostParametersCurrencyComponent = ({
  selectedMonth,
  selectedYear,
  ulsdData,
  gocmhData,
}) => {
  const getColorCode = (value) => {
    const valueNumber = parseFloat(value);
    if (valueNumber > 0) return "#0C6636";
    if (valueNumber < 0) return "#9E3232";
    return "";
  };

  return (
    <div>
      {" "}
      <div className="w-[100%] max-w-[450px] pr-8 pb-[31px]">
        <ul className="flex flex-row justify-between p-0 mb-2">
          <li className="font-normal text-[13px] !tracking-tight cost-calc-platt-header">
            {DEFAULT_S_AND_P_GLOBAL_PLATTS_HEADER}
          </li>
          <li className="font-light text-[13px] !tracking-tight cost-calc-platt-header">
            Monthly average: {moment(selectedMonth, "MM").format("MMMM")}{" "}
            {selectedYear}
          </li>
        </ul>

        {/* card 1 */}
        <Card style={{borderRadius: "8px"}}>
          <CardBody className="flex flex-row justify-between !pt-[15px] !pb-[10px]">
            <div>
              <CardTitle className="!fw-300 !text-sm pt-1 !tracking-wider">
                {DEFAULT_GAS_OIL_HEADER}
              </CardTitle>
            </div>
            <div className="flex flex-row">
              <CardTitle className="!fw-300 !text-sm pt-1 !tracking-wider">
                {gocmhData?.label}
              </CardTitle>
            </div>
            <div className="flex flex-col items-end">
              <CardTitle className="!fw-300 !text-sm !tracking-wider">
                {gocmhData?.average}
              </CardTitle>
              <CardTitle
                className="!fw-300 !text-[13px] !tracking-tight"
                style={{color: getColorCode(gocmhData?.difference)}}
              >
                {gocmhData?.difference}
              </CardTitle>
            </div>
          </CardBody>
        </Card>
      </div>
      {/* card 2 */}
      <div className="w-[100%] max-w-[450px] pr-8">
        <Card style={{borderRadius: "8px"}}>
          <CardBody className="flex flex-row justify-between !pt-[15px] !pb-[10px]">
            <div>
              <CardTitle className="!fw-300 !text-sm pt-1 !tracking-widest">
                {DEFAULT_ULSD_HEADER}
              </CardTitle>
            </div>
            <div className="flex flex-row">
              <CardTitle className="!fw-300 !text-sm pt-1 !tracking-widest">
                {ulsdData?.label}
              </CardTitle>
            </div>
            <div className="flex flex-col items-end">
              <CardTitle className="!fw-300 !text-sm !tracking-widest">
                {ulsdData?.average}
              </CardTitle>
              <CardTitle
                className="!fw-300 !text-[13px] !tracking-tight"
                style={{color: getColorCode(ulsdData?.difference)}}
              >
                {ulsdData?.difference}
              </CardTitle>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

CostParametersCurrencyComponent.propTypes = {
  selectedMonth: PropTypes.string.isRequired,
  selectedYear: PropTypes.string.isRequired,
  ulsdData: PropTypes.array.isRequired,
  gocmhData: PropTypes.array.isRequired,
};

export default CostParametersCurrencyComponent;
