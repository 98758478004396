import {gql} from "@apollo/client";

// Fetch query for Transportation emissions table on Config center(/configuration-center/transport-emissions)
export const TRANSPORTATION_EMISSIONS_GET_QUERY = gql`
  query bioLcGetTransportationEmissionApi {
    bioLcGetTransportationEmissionApi(event: {}) {
      transportationEmissionData {
        countryCode
        divisionCode
        emissionFactor
        emissionUom
        motName
        receivingLocationCode
        receivingLocationName
        sendingLocationCode
        sendingLocationName
        validFrom
        validTo
      }
    }
  }
`;

export default {TRANSPORTATION_EMISSIONS_GET_QUERY};
