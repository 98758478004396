import {isNumberAvailable} from "modules/GlobalMassBalance/utils";
import {Link} from "react-router-dom";
import {parseAndRoundToThreeDecimal} from "utils/numberUtil";
import ActionDropdown from "./ActionDropdown";

const mbListColumnsSAF = (selectedCountry, divisionCode) => {
  return [
    {
      header: "Location",
      accessorKey: "mbLocationGroupName",
      key: "mbLocationGroupName",
      visible: true,
      meta: {
        filterVariant: "select",
      },
      accessorFn: ({mbLocationGroupName}) => {
        return mbLocationGroupName || "";
      },
    },
    {
      header: "Period",
      accessorKey: "mbPeriodStatusName",
      key: "mbPeriodStatusName",
      visible: true,
      meta: {
        filterVariant: "select",
      },
      accessorFn: ({mbPeriodStatusName}) => {
        return mbPeriodStatusName || "";
      },
    },
    {
      header: "Opening position m³",
      accessorKey: "openingCount",
      key: "openingCount",
      visible: true,
      dataType: "number",
      accessorFn: ({openingCount}) => {
        return isNumberAvailable(openingCount)
          ? parseAndRoundToThreeDecimal(openingCount).toString()
          : "";
      },
    },
    {
      header: "Closing position m³",
      accessorKey: "currentCount",
      key: "currentCount",
      visible: true,
      dataType: "number",
      accessorFn: ({currentCount}) => {
        return isNumberAvailable(currentCount)
          ? parseAndRoundToThreeDecimal(currentCount).toString()
          : "";
      },
    },
    {
      header: "Physical closing stock m³",
      accessorKey: "physicalStockM3",
      key: "physicalStockM3",
      visible: true,
      dataType: "number",
      accessorFn: ({physicalStockM3}) => {
        return isNumberAvailable(physicalStockM3)
          ? parseAndRoundToThreeDecimal(physicalStockM3).toString()
          : "";
      },
    },
    {
      header: "Division",
      accessorKey: "division",
      key: "division",
      visible: false,
      meta: {
        filterVariant: "select",
      },
      accessorFn: () => {
        return divisionCode || "";
      },
    },
    {
      header: "Balance",
      accessorKey: "mbBalanceGroupName",
      key: "mbBalanceGroupName",
      visible: false,
      meta: {
        filterVariant: "select",
      },
      accessorFn: ({mbBalanceGroupName}) => {
        return mbBalanceGroupName || "";
      },
    },
    {
      header: "Incoming m³",
      accessorKey: "incomingCount",
      key: "incomingCount",
      visible: false,
      dataType: "number",
      accessorFn: ({incomingCount}) => {
        return isNumberAvailable(incomingCount)
          ? parseAndRoundToThreeDecimal(incomingCount).toString()
          : "";
      },
    },
    {
      header: "Outgoing m³",
      accessorKey: "outgoingCount",
      key: "outgoingCount",
      visible: false,
      dataType: "number",
      accessorFn: ({outgoingCount}) => {
        return isNumberAvailable(outgoingCount)
          ? parseAndRoundToThreeDecimal(outgoingCount).toString()
          : "";
      },
    },
    {
      header: "Mass balance",
      accessorKey: "massBalance",
      key: "massBalance",
      size: 200,
      disabled: true,
      visible: true,
      accessorFn: ({
        mbPeriodStatusName,
        mbLocationGroupName,
        mbBalanceGroupName,
      }) => {
        return `${mbPeriodStatusName} - ${mbLocationGroupName} - ${mbBalanceGroupName}`;
      },
      cell: ({
        row: {
          original: {
            mbBalanceTypeCode,
            mbBalanceGroupName,
            mbLocationGroupName,
            mbPeriodStatusName,
          },
        },
        cell,
      }) => {
        return (
          <Link
            to={`/mass-balance/${encodeURIComponent(
              selectedCountry,
            )}/${divisionCode}/${mbBalanceTypeCode}/${mbLocationGroupName}/${mbPeriodStatusName}/${mbBalanceGroupName}`}
            className="link-dark !underline"
          >
            {cell.getValue()}
          </Link>
        );
      },
    },
    {
      header: "Status",
      key: "mbPeriodStatus",
      accessorKey: "mbPeriodStatus",
      disabled: true,
      visible: true,
      draggaingDisabled: true,
      meta: {
        filterVariant: "select",
      },
      cell: ({getValue, row: {mbPeriodValidityFlag}}) => {
        return (
          <div className="flex">
            <span
              className={`text-[10px] px-[10px] py-[4px] rounded-[3px] mr-[10px] ${
                getValue() === "OPEN" ? "bg-[#ecfafe]" : "bg-[#ededed]"
              } `}
            >
              {getValue()}
            </span>
            {mbPeriodValidityFlag && (
              <span className="text-[10px] px-[10px] py-[4px] rounded-[3px] bg-[#ffedf0]">
                ERROR
              </span>
            )}
          </div>
        );
      },
    },
    {
      header: "",
      accessorKey: "actions",
      key: "actions",
      size: 25,
      visible: true,
      enableSorting: false,
      cell: ({row}) => {
        return <ActionDropdown row={row} />;
      },
    },
  ];
};

export default mbListColumnsSAF;
