export const RULE_EDITOR_LABELS = {
  BLOCKS: "Blocks",
  DATA: "Data",
  SELECT_DATA: "Select data",
  WRITE_DATA: "Write data",
  CONVERT_UNITS: "Convert units",
  VOLUME_TO_ENERGY: "Volume to energy",
  ENERGY_TO_EMISSION: "Energy to actual emission",
  CALCULATE: "Calculate",
  CALCULATION: "Calculation",
  EMISSIONS: "Emissions",
  CONVERSION: "Conversion",
};

export default RULE_EDITOR_LABELS;
