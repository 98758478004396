import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {useEffect} from "react";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";

const DeclarationFooter = ({data}) => {
  const {
    register,
    formState: {errors},
    setValue,
  } = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  useEffect(() => {
    setValue(
      "bioProductSpec",
      `(*): from Co-processed oil to be used for replacement of jet fuel, under mass balance basis (mass determination).`,
    );
    setValue(
      "rawMaterialCatSpec",
      `(**) : The raw material meets the definition of waste or residues, i.e. was not intentionally produced and modified, or contaminated, or discarded, to meet the definition of waste or residues (applicable to waste and residues and products produced from those).`,
    );
    setValue(
      "general",
      `"Biofuel is certified to the ISCC PLUS standard according to a mass balance approach and complies with ISCC PLUS sustainability, traceability and GHG requirements. BP Oil España sustainability processes have been certified by Control Union as part of ISCC verification and Spanish National certification schemes.
      Traceability: all bio raw material is ISCC compliant, meaning the whole supply chain is certified from point of origin, ensuring traceability of chain of custody. 
      GHG emission values in gCO2eq/MJ have been calculated according to EU Directive 2018/2001 and ISCC PLUS methodology. GHG emission calculation includes all relevant emissions from biomass production, conversion processes, and transport and distribution. GHG reference value of 89 gCO2eq/MJ for conventional fuel (Jet A1) has been determined by ICAO as baseline life cycle emissions values for aviation fuel."`,
    );
  }, [data, setValue]);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup>
        <Label for="bioProductSpec" className="fw-light">
          Bio Product Specification
        </Label>

        <Input
          {...computeProps("bioProductSpec")}
          type="textarea"
          rows="3"
          name="bioProductSpec"
          id="bioProductSpec"
          data-test="bioProductSpec"
          invalid={!!errors.bioProductSpec}
          maxLength={250}
        />
        {errors.bioProductSpec && (
          <FormFeedback>{errors.bioProductSpec.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialCatSpec" className="fw-light">
          Raw material category specification
        </Label>

        <Input
          {...computeProps("rawMaterialCatSpec")}
          type="textarea"
          rows="3"
          name="rawMaterialCatSpec"
          id="rawMaterialCatSpec"
          data-test="rawMaterialCatSpec"
          invalid={!!errors.rawMaterialCatSpec}
          maxLength={250}
        />
        {errors.rawMaterialCatSpec && (
          <FormFeedback>{errors.rawMaterialCatSpec.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="general" className="fw-light">
          General
        </Label>

        <Input
          {...computeProps("general")}
          type="textarea"
          rows="10"
          name="general"
          id="general"
          data-test="general"
          invalid={!!errors.general}
          maxLength={1000}
        />
        {errors.general && (
          <FormFeedback>{errors.general.message}</FormFeedback>
        )}
      </FormGroup>
    </div>
  );
};
DeclarationFooter.propTypes = {
  data: PropTypes.object,
};
export default DeclarationFooter;
