import {
  Add24,
  CircleClose24,
  DoubleArrowRight24,
  Email24,
  Swap24,
} from "@bphxd/ds-core-react/lib/icons";
import {isEmpty} from "lodash";
import moment from "moment";
import {formatNumber, useUserSettings} from "providers/userSettings";
import {UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import parseAndRoundToTwoDecimal, {
  parseAndRoundToThreeDecimal,
} from "utils/numberUtil";
import ActionDropdown from "../ActionDropdown";

let indexId = 0;
const formatNumberIfExists = (value, decimalFormatNew) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  if (value) {
    return value === "TDV" || value === "DDV"
      ? value
      : formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }
  return value;
};

export const INCOMING_COLUMNS = (
  dateFormat,
  decimalFormat,
  siteReferenceId,
  divisionId,
  selectedCountry,
) => {
  return [
    {
      header: "Incoming",
      columns: [
        {
          header: "Date of physical receipt",
          accessorKey: "physicalReceiptDate",
          visible: false,
          accessorFn: (row) => {
            return moment(
              new Date(row?.original.inboundRecord?.physicalReceiptDate),
            ).format(dateFormat);
          },
          cell: ({row}) => {
            return moment(
              new Date(row?.original.inboundRecord?.physicalReceiptDate),
            ).format(dateFormat);
          },
        },
        {
          header: "Month of receipt",
          accessorKey: "physicalReceiptDate",
          visible: true,
          accessorFn: (row) => {
            return moment(
              new Date(row?.original.inboundRecord?.physicalReceiptDate),
            ).format("MMMM 'YY");
          },
          cell: ({row}) => {
            return (
              <div className="whitespace-nowrap">
                {moment(
                  new Date(row?.original.inboundRecord?.physicalReceiptDate),
                ).format("MMMM 'YY")}
              </div>
            );
          },
        },
        {
          header: "Certificate ID (in)",
          accessorKey: "inboundDocStatusId",
          visible: true,
          cell: ({row}) => {
            return (
              <Link
                to={{
                  pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${row?.original.inboundRecord?.certificateInboundEuId}`,
                  search: `?pageNo=0&siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
              >
                <u>{row?.original.inboundRecord?.sdNumber}</u>
              </Link>
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                Quantity processed<span>{"\u00A0"}</span>(in)
                <span>{"\u00A0"}</span>t
              </div>
            );
          },
          dataType: "number",
          accessorKey: "qtyProcessedIn",
          visible: true,
          accessorFn: (row) => {
            return formatNumberIfExists(
              row?.inboundRecord?.qtyProcessedIn,
              decimalFormat,
            );
          },
          cell: ({row}) => {
            return formatNumberIfExists(
              row?.original.inboundRecord?.qtyProcessedIn,
              decimalFormat,
            );
          },
        },
        {
          header: "GHG emissions (in) gCO2eq/MJ",
          accessorKey: "ghgEmissionIn",
          visible: true,
          dataType: "number",
          accessorFn: (row) => {
            return formatNumberIfExists(
              row?.inboundRecord?.ghgEmissionIn,
              decimalFormat,
            );
          },
          cell: ({row}) => {
            return formatNumberIfExists(
              row?.original.inboundRecord?.ghgEmissionIn,
              decimalFormat,
            );
          },
        },
        {
          header: () => {
            return (
              <div>
                Quantity processed<span>{"\u00A0"}</span>(out)
                <span>{"\u00A0"}</span>t
              </div>
            );
          },
          accessorKey: "qtyProcessedOut",
          visible: true,
          dataType: "number",
          accessorFn: (row) => {
            return formatNumberIfExists(
              row?.inboundRecord?.qtyProcessedOut,
              decimalFormat,
            );
          },
          cell: ({row}) => {
            return formatNumberIfExists(
              row?.original.inboundRecord?.qtyProcessedOut,
              decimalFormat,
            );
          },
        },
      ],
    },
  ];
};

const _renderStatus = (status, fromLocationCode) => {
  switch (status) {
    case "CARRY FORWARD":
      if (fromLocationCode === null) {
        return (
          <div className="flex flex-row gap-[5px]">
            <DoubleArrowRight24 className="d-inline-block" />
            Available
          </div>
        );
      }
      return (
        <div className="flex flex-row gap-[5px]">
          <Add24 className="d-inline-block" />
          Available
        </div>
      );
    case "AVAILABLE":
      return (
        <div className="flex flex-row gap-[5px]">
          <Add24 className="d-inline-block" />
          Available
        </div>
      );
    case "WRITTEN OFF":
      return (
        <div className="flex flex-row gap-[5px]">
          <CircleClose24 className="d-inline-block" />
          Written off
        </div>
      );
    case "TRANSFERRED":
      return (
        <div className="flex flex-row gap-[5px]">
          <Swap24 className="d-inline-block" />
          Transferred
        </div>
      );
    case "ASSIGNED AND SENT":
      return (
        <div className="flex flex-row gap-[5px]">
          <Email24 className="d-inline-block" />
          Assigned & sent
        </div>
      );
    case "ASSIGNED AND SOLD":
      return (
        <div className="flex flex-row gap-[5px]">
          <Email24 className="d-inline-block" />
          Assigned to sale
        </div>
      );
    case "CARRIED OVER":
      return <div className="flex flex-row gap-[5px]">Carried over</div>;
    default:
      return status;
  }
};

export const OUTGOING_COLUMNS = (decimalFormat, loading) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: () => {
          return (
            <div>
              Quantity allocated<span>{"\u00A0"}</span>t
            </div>
          );
        },
        accessorKey: "qtyAllocated",
        dataType: "number",
        cell: ({row}) => {
          const val = formatNumberIfExists(
            row?.original.outboundRecords?.qtyAllocated,
            decimalFormat,
          );
          const text =
            row?.original.outboundRecords?.productStatus === "AVAILABLE" ||
            row?.original.outboundRecords?.productStatus === "CARRY FORWARD" ? (
              <strong>{val}</strong>
            ) : (
              val
            );
          indexId += 1;
          const id = indexId;
          return (
            <div>
              <div id={`qtyAllocatedVal${row.id}${id}`}>{text}</div>
              {!loading && (
                <UncontrolledTooltip
                  placement="right"
                  target={`qtyAllocatedVal${row.id}${id}`}
                  autohide
                >
                  {formatNumberIfExists(
                    row?.original.outboundRecords?.quantityInM3,
                    decimalFormat,
                  )}{" "}
                  m<sup>3</sup>
                </UncontrolledTooltip>
              )}
            </div>
          );
        },
      },
      {
        header: "Certificate ID (out)",
        accessorKey: "certificateNumber",
        cell: (cell) => {
          return (
            <Link
              to="/copro-spain/spain/document-manager/outgoing-documents/view"
              state={{
                certificateNumber:
                  cell.row?.original?.outboundRecords?.certificateNumber,
                certificatePdfUrl:
                  cell.row?.original?.outboundRecords?.certificateUrlPdf,
              }}
              className="link-dark"
            >
              <u>{cell.row?.original?.outboundRecords?.certificateNumber}</u>
            </Link>
          );
        },
      },
      {
        header: "GHG emissions (out) gCO2eq/MJ",
        dataType: "number",
        accessorFn: (row) => {
          return formatNumberIfExists(
            row?.outboundRecords?.ghgEmissionOut,
            decimalFormat,
          );
        },
        cell: ({row}) => {
          return formatNumberIfExists(
            row?.original.outboundRecords?.ghgEmissionOut,
            decimalFormat,
          );
        },
      },
      {
        header: () => {
          return (
            <div>
              GHG savings potential<span>{"\u00A0"}</span>out
              <span>{"\u00A0"}</span>%
            </div>
          );
        },
        accessorKey: "ghgSavingPotential",
        dataType: "number",
        accessorFn: (row) => {
          return formatNumberIfExists(
            row?.outboundRecords?.ghgSavingPotential,
            decimalFormat,
          );
        },
        cell: ({row}) => {
          const id = `tooltip-${Math.random().toString(36).substring(2, 9)}`;
          return (
            <div>
              <div id={`ghgSavingPotential${row.id}${id}`}>
                {formatNumberIfExists(
                  row?.original.outboundRecords?.ghgSavingPotential,
                  decimalFormat,
                )}
              </div>
              {!loading && (
                <UncontrolledTooltip
                  placement="right"
                  target={`ghgSavingPotential${row.id}${id}`}
                  autohide
                >
                  {isEmpty(
                    row?.original.outboundRecords?.savingPotentialTooltip,
                  )
                    ? "Not available"
                    : row?.original.outboundRecords?.savingPotentialTooltip}
                </UncontrolledTooltip>
              )}
            </div>
          );
        },
      },
      {
        header: "Status",
        cell: ({row}) =>
          _renderStatus(
            row?.original.outboundRecords?.productStatus,
            row?.original.inboundRecord?.fromLocationCode,
          ),
      },
      {
        header: "",
        accessorKey: "actions",
        size: 50,
        cell: ({row, table}) => {
          return <ActionDropdown row={row} table={table} />;
        },
      },
    ],
  },
];

// Format doesn't matter here, since we are only interested in accessor keys
export const OUTGOING_COLUMN_IDS = OUTGOING_COLUMNS("0.00")[0].columns.map(
  (col) => col.accessorKey,
);
export const INCOMING_COLUMN_IDS = INCOMING_COLUMNS(
  "DD/MM/YYYY",
)[0].columns.map((col) => col.accessorKey);
