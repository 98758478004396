import {useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  Alert24,
  Info24,
  CircleClose24,
  Checklist24,
} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {GET_NOTIFICATION} from "graphql/docManager/GetNotification";
import Spinner from "modules/common/Spinner";

import TaskComponent from "../TaskComponent/TaskComponent";

const REQUIRE_ATTENTION_MESSAGES = {
  NO_DOCUMENT: "No documents require your attention",
  ONE_DOCUMENT: "1 document requires your attention",
  MANY_DOCUMENTS: "documents require your attention",
};

const READY_TO_ACCEPT_MESSAGES = {
  NO_DOCUMENT: "No documents ready to accept",
  ONE_DOCUMENT: "1 document is ready to accept",
  MANY_DOCUMENTS: "documents ready to accept",
};

const READY_TO_ASSIGN_MESSAGES = {
  NO_DOCUMENT: "No documents ready to assign",
  ONE_DOCUMENT: "1 document is ready to assign",
  MANY_DOCUMENTS: "documents ready to assign",
};

const FAILED_TO_PROCESS_MESSAGES = {
  NO_DOCUMENT: "No documents failed to process",
  ONE_DOCUMENT: "1 document is failed to process",
  MANY_DOCUMENTS: "documents failed to process",
};

const NotificationBar = ({siteReferenceId, divisionId, selectedCountry}) => {
  const {country} = useParams();

  const {data, loading} = useQuery(GET_NOTIFICATION, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: {siteReferenceId, divisionId},
    skip: siteReferenceId === undefined || divisionId === undefined,
  });

  const records = data?.bioLcIncomingTaskManagerApi?.data;
  const filteredRecords = (targetState) =>
    records?.filter((record) => record.state === targetState);

  const allTasksDone = !loading && records?.every((obj) => obj.total === 0);

  const targetStates = {
    REQUIRE_ATTENTION: "requires_attention",
    READY_TO_ASSIGN: "ready_to_assign",
    READY_TO_ACCEPT: "ready_to_accept",
    FAILED_TO_PROCESS: "failed_to_process",
  };

  const requireAttentionTotal =
    data && records && filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total;
  const readyToAccept =
    data && records && filteredRecords(targetStates.READY_TO_ACCEPT)[0].total;
  const readyToAssign =
    data && records && filteredRecords(targetStates.READY_TO_ASSIGN)[0].total;
  const failedToProcess =
    data && records && filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total;

  // Generate messages for task notification for different types of status
  const generateMessage = (total, MESSAGE) => {
    let message;
    if (total === 0) {
      message = MESSAGE.NO_DOCUMENT;
    } else if (total === 1) {
      message = MESSAGE.ONE_DOCUMENT;
    } else {
      message = `${total} ${MESSAGE.MANY_DOCUMENTS}`;
    }
    return message;
  };

  const landingPage = `/document-manager/incoming/${selectedCountry}/detailedView`;

  /* Task notification data  
   commented some portions as it is not needed at the moment as we are not showing it here */
  const taskNotification = data &&
    records && [
      // {
      //   id: 1,
      //   text: generateMessage(
      //     requireAttentionTotal,
      //     REQUIRE_ATTENTION_MESSAGES,
      //   ),
      //   link: landingPage,
      //   icon: <Alert24 color="#e64949" />,
      //   taskItems: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].documents,
      //   header: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].state,
      //   latestDocument: filteredRecords(targetStates.REQUIRE_ATTENTION)[0]
      //     .latestDocument,
      //   total: filteredRecords(targetStates.REQUIRE_ATTENTION)[0].total,
      //   state: targetStates.REQUIRE_ATTENTION,
      // },
      // {
      //   id: 2,
      //   text: generateMessage(readyToAccept, READY_TO_ACCEPT_MESSAGES),
      //   link: landingPage,
      //   icon: <Info24 color="#ffc000" />,
      //   taskItems: filteredRecords(targetStates.READY_TO_ACCEPT)[0].documents,
      //   header: filteredRecords(targetStates.READY_TO_ACCEPT)[0].state,
      //   latestDocument: filteredRecords(targetStates.READY_TO_ACCEPT)[0]
      //     .latestDocument,
      //   total: filteredRecords(targetStates.READY_TO_ACCEPT)[0].total,
      //   state: targetStates.READY_TO_ACCEPT,
      // },
      // {
      //   id: 3,
      //   text: generateMessage(readyToAssign, READY_TO_ASSIGN_MESSAGES),
      //   link: landingPage,
      //   icon: <Info24 color="#218dcc" />,
      //   taskItems: filteredRecords(targetStates.READY_TO_ASSIGN)[0].documents,
      //   header: filteredRecords(targetStates.READY_TO_ASSIGN)[0].state,
      //   latestDocument: filteredRecords(targetStates.READY_TO_ASSIGN)[0]
      //     .latestDocument,
      //   total: filteredRecords(targetStates.READY_TO_ASSIGN)[0].total,
      //   state: targetStates.READY_TO_ASSIGN,
      // },
      {
        id: 4,
        text: generateMessage(failedToProcess, FAILED_TO_PROCESS_MESSAGES),
        link: landingPage,
        icon: <CircleClose24 color="#111111" />,
        taskItems: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].documents,
        header: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].state,
        latestDocument: filteredRecords(targetStates.FAILED_TO_PROCESS)[0]
          .latestDocument,
        total: filteredRecords(targetStates.FAILED_TO_PROCESS)[0].total,
        state: targetStates.FAILED_TO_PROCESS,
      },
    ];

  // Code to loop through the taskNotification array and display the text and link
  const taskDisplay =
    !loading && data && records
      ? taskNotification.map((item) => {
          return (
            <TaskComponent
              item={item}
              key={item.id}
              targetStates={targetStates}
              selectedCountry={selectedCountry}
            />
          );
        })
      : "";

  const taskDisplayBar =
    !loading && !allTasksDone ? (
      <div className="flex flex-row flex-wrap flex-1">{taskDisplay}</div>
    ) : (
      !loading && (
        <div className="flex flex-row">
          <div className="pt-0">
            <Checklist24 className="text-gray-900" />
          </div>
          <div className="drop-down-name">
            All done! You have no outstanding tasks
          </div>
        </div>
      )
    );

  return loading ? <Spinner /> : taskDisplayBar;
};

NotificationBar.propTypes = {
  siteReferenceId: PropTypes.string,
  divisionId: PropTypes.string,
  selectedCountry: PropTypes.string,
};

export default NotificationBar;
