import PropTypes from "prop-types";
import ApiCSVItem from "./ApiCSVItem";
import LocalCSVItem from "./LocalCSVItem";

const DownloadCSVItem = ({data, columns, filename, onDownload}) => {
  const component = onDownload ? (
    <ApiCSVItem {...{data, onDownload}} />
  ) : (
    <LocalCSVItem {...{data, columns, filename}} />
  );

  return component;
};

DownloadCSVItem.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array,
  filename: PropTypes.string,
  onDownload: PropTypes.func,
};

export default DownloadCSVItem;
