export const tabFields = {
  supplier: [
    "dateOfIssuance",
    "supplierName",
    "supplierAddress",
    "supplierDispatchAddress",
    "certificationSystem",
    "certificateNumber",
    "materialDispatchDatez",
    "recipientName",
    "recipientAddress",
    "recipientReceiptAddress",
    "contractNumber",
  ],
  general: [
    "productType",
    "rawMaterialType",
    "additionalInfo",
    "rawMaterialOriginCountryName",
    "feedstockQty",
    "feedstockQtyUom",
    "isccCompliantFlag",
    "euRedCompliantFlag",
    "chainOfCustodyOption",
    "productEnergyContent",
  ],
  emission: [
    "ghgEec",
    "ghgEl",
    "ghgEp",
    "ghgEtd",
    "ghgEu",
    "ghgEsca",
    "ghgEccs",
    "ghgEccr",
    "ghgTotal",
    "motFromRefinery",
    "motToRefinery",
    "motDistanceKm",
    "physicalReceiptDate",
    "splitDetails",
    "ghgNoValue",
  ],
  certification: [
    "materialSustainabilityCriteriaFlag",
    "agriculturalBiomassIntermediateCropFlag",
    "agriculturalBiomassLowRiskFlag",
    "rawMaterialWasteOrResidueFlag",
    "wasteOrAnimalByProductPermitNumber",
    "supportForFuelProductionOrFuelPrecursorReceivedFlag",
    "ifYesSpecifySupportNatureAndScheme",
  ],
  additionalInfo: [
    "emissionForTransport",
    "mbLocation",
    "invoiceNumber",
    "vesselName",
    "observationNotes",
    "mbBalanceGroupId",
    "mbLocationGroupId",
  ],
};

export const rejectReasons = [
  {
    key: "info_missing",
    value: "Required information missing or incorrect",
    message:
      "This certificate will be kept and deactivated. A new certificate needs to be reissued",
  },
  {
    key: "out_of_date",
    value: "Out of date certificate",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
  {key: "invalid", value: "Invalid file", message: "This file will be deleted"},
  {
    key: "other",
    value: "Other",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
];

export default {tabFields, rejectReasons};
