import {useEffect, useState} from "react";
import PropTypes from "prop-types";

const dialInputPatterns = [
  // For North America
  {
    regionList: ["WC", "EC"],
    regexPattern: /^U[0-9]{2}[a-zA-Z0-9]*:?[a-zA-Z0-9]*$/,
  },
  // Potential expansion of other regions - pattern starts A for Asian based deals and
  // E for European based deals
];

const DealNumberInput = ({record, handleInputChange}) => {
  const [value, setValue] = useState(record.dealNumber);

  useEffect(() => {
    if (record.value !== undefined && record.value !== value) {
      setValue(record.value);
    } else if (record.value === undefined && record.dealNumber !== value) {
      setValue(record.dealNumber);
    }
  }, [record, value]);

  const selectedRegion = dialInputPatterns.find((regionData) =>
    regionData.regionList.includes(record.region),
  );

  const handleChange = (event) => {
    const valueErrorObject = {value: event.target.value, error: record.error};
    setValue(valueErrorObject.value);

    if (
      valueErrorObject.value === "" ||
      (valueErrorObject.value.length <= 25 &&
        selectedRegion.regexPattern.test(valueErrorObject.value))
    ) {
      valueErrorObject.error = false;
    } else {
      valueErrorObject.error = true;
    }

    handleInputChange({...record, ...valueErrorObject});
  };

  return (
    <input
      data-test="input-deal-number-cell"
      className={`focus:outline-none editable-cell-input h-6 w-40 ${
        record.error && "error"
      }`}
      style={{
        borderRadius: "5px",
        border: "1px solid #89888c",
        paddingLeft: "0.25rem",
      }}
      type="text"
      spellCheck="false"
      value={value}
      onChange={handleChange}
      maxLength={25}
    />
  );
};

DealNumberInput.propTypes = {
  record: PropTypes.object,
  handleInputChange: PropTypes.func,
};

export default DealNumberInput;
