const generateBreadcrumbItems = (country, safUrls, optionalItems = []) => {
  const commonItems = [
    {text: "BioVerse", link: "/"},
    {
      text: `SAF ${country.charAt(0).toUpperCase() + country.slice(1)}`,
      link: safUrls[`${country}LandingPage`],
    },
  ];

  const allItems = commonItems.concat(optionalItems);

  return allItems.map((item) => {
    return {
      text: item.text,
      link: item.link,
    };
  });
};

// Generate messages for task notification for different types of status
export const generateMessage = (total, MESSAGE) => {
  let message;
  if (total === 0) {
    message = MESSAGE.NO_DOCUMENT;
  } else if (total === 1) {
    message = MESSAGE.ONE_DOCUMENT;
  } else {
    message = `${total} ${MESSAGE.MANY_DOCUMENTS}`;
  }
  return message;
};

export const convertToDate = (inputString) => {
  const quarterMap = {
    Q1: "01-01",
    Q2: "04-01",
    Q3: "07-01",
    Q4: "10-01",
  };

  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const [part1, year] = inputString.split(" ");

  let dateString;
  if (quarterMap[part1]) {
    dateString = `${year}-${quarterMap[part1]}`;
  } else if (monthMap[part1]) {
    dateString = `${year}-${monthMap[part1]}-01`;
  } else {
    return null;
  }

  return new Date(dateString);
};

export default generateBreadcrumbItems;
