import {gql} from "@apollo/client";

export const COPRO_EU_GET_NOTIFICATION = gql`
  query GetEuNotification($siteReferenceId: String, $divisionId: String) {
    bioLcCoproEuGetNotification(
      event: {siteReferenceId: $siteReferenceId, divisionId: $divisionId}
    ) {
      readyToAccept
      requiresAttention
      readyToAssign
    }
  }
`;
export default COPRO_EU_GET_NOTIFICATION;
