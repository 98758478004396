import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input} from "reactstrap";

const SustainabilityCriteria = ({data}) => {
  const {register} = useFormContext();

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="flex flex-col gap-4 pb-6">
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("isccCompliantFlag")}
          type="checkbox"
          id="isccCompliantFlag"
          data-test="isccCompliantFlag"
        />

        <Label check for="isccCompliantFlag">
          ISCC compliant
        </Label>
      </FormGroup>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("rawMaterialSustainabilityCriteriaFlag")}
          type="checkbox"
          id="rawMaterialSustainabilityCriteriaFlag"
          data-test="rawMaterialSustainabilityCriteriaFlag"
        />

        <Label check for="rawMaterialSustainabilityCriteriaFlag">
          The raw material (bio) complies with the sustainability criteria
          according to the ISCC requirements as laid document(s) 202
          “Sustainability Requirements”.
        </Label>
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("rawMaterialMeetsDefCriteriaFlag")}
          type="checkbox"
          id="rawMaterialMeetsDefCriteriaFlag"
          data-test="rawMaterialMeetsDefCriteriaFlag"
        />

        <Label check for="rawMaterialMeetsDefCriteriaFlag">
          The raw material (circular/bio-circular) meets the definition of waste
          or residues, i.e. was not intentionally produced and modified, or
          contaminated, or discarded, to meet the definition of waste or
          residues (applicable to waste and residues and products produced from
          those).
        </Label>
      </FormGroup>
    </div>
  );
};
SustainabilityCriteria.propTypes = {
  data: PropTypes.object,
};
export default SustainabilityCriteria;
