import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {useState, useEffect} from "react";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Close} from "@bphxd/ds-core-react";
import Form from "modules/common/FormNew";
import PropTypes from "prop-types";
import {useMsal, useAccount} from "@azure/msal-react";
import {getCertificateDetailsAPI} from "graphql/es-co-processing/mass-balance/generateCertificate";
import dateFunctions from "utils/helpers/dateFunctions";
import {SchemaAssigntoSale} from "./schemaAssignToSale";
import StepsAssignToSale from "./StepsAssignToSale";
import CertificationDetails from "./CertificationDetails";
import RecipientDetails from "./RecipientDetails";
import ProductDetails from "./ProductDetails";

const AssignToSaleModal = ({isVisibleModal, rowData}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const navigate = useNavigate();

  const [isModalVisible, setModalVisible] = useState(isVisibleModal);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  return (
    <div>
      <Modal
        size="lg"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Assign to sale
        </ModalHeader>
        <ModalBody className="text-center pt-0">
          <Form
            id="assign-to-sales-form"
            schema={SchemaAssigntoSale}
            onSubmit={async (values) => {
              const params = {
                ...values,
                inboundEuQtyId: rowData?.inboundRecord?.inboundEuQtyId,
                sdNumber: rowData?.inboundRecord?.sdNumber,
                createdBy: account.username,
                rowData,
              };
              const {data: dataCert} = await getCertificateDetailsAPI({
                locationCode: rowData?.inboundRecord?.mbLocationCode,
                certificationSystem: values?.certificationSystem,
                templateType: values?.templateType,
                vesselName: values?.vesselName,
                dispatchDate: dateFunctions.formatDatePickerDate(
                  values?.dispatchDate,
                ),
                inboundEuQtyId: rowData?.inboundRecord?.inboundEuQtyId,
                motRecipient: values?.motRecipient,
                sdNumber: rowData?.inboundRecord?.sdNumber,
                issueDate: dateFunctions.formatDatePickerDate(
                  values?.issueDate,
                ),
              });
              if (
                dataCert?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                  ?.statusCode === 500
              ) {
                toast.error(
                  dataCert?.bioLcCoproEuMassBalanceGetAutoPopulatedData
                    ?.message,
                );
              } else {
                navigate(
                  `/copro-spain/spain/document-manager/generate-certificate`,
                  {
                    state: params,
                  },
                );
              }
            }}
          >
            <StepsAssignToSale
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
            {activeStep === 0 && <CertificationDetails rowData={rowData} />}
            {activeStep === 1 && <ProductDetails rowData={rowData} />}
            {activeStep === 2 && <RecipientDetails rowData={rowData} />}
          </Form>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row m-5 text-center modal-footer-row justify-end gap-3 ">
            {activeStep !== 0 && (
              <Button
                className="border-black !w-[90px]"
                color="standard-tertiary"
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
            )}
            {activeStep !== 2 && (
              <Button
                className="modal-button-assigntoSale border-black !w-[90px]"
                color=""
                onClick={() => {
                  setActiveStep(activeStep + 1);
                }}
              >
                Continue
              </Button>
            )}
            {activeStep === 2 && (
              <Button
                className="modal-button-assigntoSale border-black !w-[160px]"
                color=""
                form="assign-to-sales-form"
                type="submit"
              >
                Finalize Certificate
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

AssignToSaleModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
};

export default AssignToSaleModal;
