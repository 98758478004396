import _ from "lodash";

export const ACTIONS = {
  CLEAR_FILTERS: "CLEAR_FILTERS",
  CHANGE_FILTER: "CHANGE_FILTER",
};

export const INITIAL_STATE = {
  dataSource: [],
  options: {
    country: [],
    complianceScheme: [],
    complianceType: [],
    compliancePeriod: [],
    bioType: [],
    subType: [],
    complianceName: [],
    // Removed temporarily (Not sure if will comeback)
    // complianceUnitOfMeasure: [],
    legalEntity: [],
  },
  currentValues: {},
};

const extractUniqueValuesByKey = (array, key, filterValues = {}) => {
  const {compliancePeriod, ...filters} = filterValues;
  const isComplianceFilterEmpty = _.isEmpty(compliancePeriod);
  return _.chain(array)
    .filter(filters)
    .filter((item) =>
      isComplianceFilterEmpty
        ? true
        : item.compliancePeriod?.includes(filterValues.compliancePeriod),
    )
    .uniqBy(key)
    .map(key)
    .compact() // Remove empty strings
    .value();
};

const reducer = (state, {type, payload}) => {
  switch (type) {
    case ACTIONS.CLEAR_FILTERS: {
      // Find unique values per filter key and set state
      const {data: dataSource} = payload;
      const keys = Object.keys(INITIAL_STATE.options);
      const optionsResult = {};

      keys.forEach((key) => {
        optionsResult[key] = extractUniqueValuesByKey(dataSource, key);
      });

      // Set data source, and default options
      return {
        ...state,
        dataSource,
        options: {...state.options, ...optionsResult},
      };
    }

    case ACTIONS.CHANGE_FILTER: {
      const {name, value} = payload;
      const {dataSource, currentValues} = state;
      const optionsResult = {};

      // Extract default options
      const newCurrentValues = _.omitBy(
        {...currentValues, [name]: value},
        _.isUndefined,
      );

      const keys = Object.keys(INITIAL_STATE.options);
      keys.forEach((key) => {
        if (key === "country") return;
        optionsResult[key] = extractUniqueValuesByKey(
          dataSource,
          key,
          _.omit(newCurrentValues, [key]), // For each filter, never include himself in the filter
        );
      });

      // Force clear of bioType, subType and legalEntity on country change.
      if (
        name === "country" &&
        (currentValues.bioType ||
          currentValues.subType ||
          currentValues.legalEntity)
      ) {
        const optionsResult = {
          bioType: [""],
          subType: [""],
          legalEntity: [""],
        };
        return {
          ...state,
          options: {...state.options, ...optionsResult},
          currentValues: newCurrentValues,
        };
      }

      // Set default options for those not changed
      return {
        ...state,
        options: {...state.options, ...optionsResult},
        currentValues: newCurrentValues,
      };
    }

    default:
      return state;
  }
};

export default reducer;
